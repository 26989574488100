export interface LockPrincipalLanguage {
    reintent: string;
    titleModal: string;
    titleModalto: string;
    selectReasons: string;
    sorryReason: string;
    close: string;
    locking: string;
    lock: string;
    addDescription: string;
    msgValidReason: string;
    msgValidDescription: string;


}

export const LockPrincipalEnglish: LockPrincipalLanguage = {
    reintent: 'Reintent',
    titleModal: 'Lock Principal',
    titleModalto: 'Lock to ',
    selectReasons: 'Select Lock Reasons:',
    sorryReason: 'Sorry something happened loading the Reasons list, please check your connection and try again.',
    close: 'Close',
    lock: 'Lock',
    locking: 'Locking',
    addDescription: 'Add a description',
    msgValidReason: 'Please select a blocking reason.',
    msgValidDescription: 'Please enter the lock description.'


}
export const LockPrincipalSpanish: LockPrincipalLanguage = {
    reintent: 'Reintentar',
    titleModal: 'Bloquear Fiado',
    titleModalto: 'Bloquear a ',
    selectReasons: 'Seleccionar motivos de bloqueo:',
    sorryReason: 'Lo sentimos algo ha ocurrido al cargar la lista de Motivos, revise su conexión e intente de nuevo.',
    close: 'Cerrar',
    locking: 'Bloqueando',
    lock: 'Bloquear',
    addDescription: 'Agrega una descripción',
    msgValidReason: 'Por favor, selecciona una razón de bloqueo.',
    msgValidDescription: 'Por favor, ingresa la descripción de bloqueo.'

}
