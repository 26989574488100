import React, {Component} from "react";
import {RouteComponentProps} from "react-router-dom";

import {
    AlphanumericInput, Link as LinkLiberty, Table, TableCell, TableRow
} from "@lmig/lmds-react";
import './ReviewLote.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import {BreadCumb} from "../../../components/public/breadcumb/BreadCumb";
import {ListGarantiaModel} from "../../../models/garantias/ListGarantiaModel";
import {PaginacionModel} from "../../../models/public/PaginacionModel";
import {formatCurrency, initPaginacion, InputChange, loadPaginationList, validRoles} from "../../../class/CommonClass";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import {SpinnerLoading} from "../../../components/public/spinner-loading/SpinnerLoading";
import {Pagination} from "react-bootstrap";
import {NegativeAlert} from "../../../components/public/negative-alert/NegativeAlert";
import {StatusRowWarranty} from "../../../components/garantias/status-row-warranty/StatusRowWarranty";
import {ExpedientIdentification} from "../../../components/garantias/expedient-identification/ExpedientIdentification";
import {RootStore} from "../../../Store";
import {connect} from "react-redux";
import {DefaultStateI} from "../../../reducer/LanguageReducer";
import {InfoPrincipal} from "../../../components/garantias/info-principal/InfoPrincipal";
import {UserModel} from "../../../models/public/UserModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {AcordionTruncate} from "../../../components/garantias/acordion-truncate/AcordionTruncate";
import {FaComments} from "react-icons/fa";
import ComentariosFiado from "../../../components/garantias/comentarios-fiado/ComentariosFiado";
import $ from "jquery";


interface Params {
    idFiado: string;
    idLote: string;
}

interface LocationState {
    ownerName: string;
}

// Static Context is available when you use Static Router*
interface SC {
    statusCode?: number;
}
interface WarrantyReviewLoteProps{
    language: DefaultStateI
}

class ReviewLote extends Component<WarrantyReviewLoteProps & RouteComponentProps<Params, SC, LocationState>, ReviewLoteState>{
    public localStorageClass = new LocalStorageClass();

    constructor(props: any) {
        super(props);
        document.title = "Revisión de Garantías Lote " + this.props.match.params.idLote
        this.state = {
            listGarantias: [],
            loadingGarantias: true,
            errorListGarantias: false,
            paginacion: initPaginacion,
            search: '',
            listPaginacion:[],
            showObligors: false,
            PERFIL:  this.localStorageClass.getProfile(),
            openComentario: false,
            idFiado: parseInt(this.props.match.params.idFiado),
            idLote: parseInt(this.props.match.params.idLote)

        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }
    render(): JSX.Element {
        return <>
         <NavBar/>
            <div className="container-fluid" >
               
                <div id="warranty-review-lote">

                    <BreadCumb title={this.props.language.language?.reviewLote.title!}
                               listBreadcumbs={[
                                   {title: this.props.language.language?.reviewLote.home!, url:'/garantias/'},
                                   {title: this.props.language.language?.reviewLote.title!, url: '/garantias/revision/'},
                                   {title: this.props.language.language?.reviewLote.fiado! + ' ' + this.props.match.params.idFiado, url:this.props.location.pathname}]}/>

                    <div className="row">
                        {(validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) ||
                            validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA)) ?
                            (<div className="col-md-12 text-right"
                                  onClick={e =>
                                      this.setState({openComentario: !this.state.openComentario})}>
                                <b className="comments-review-lote" >
                                    {this.props.language.language?.principalDetail.comments} &nbsp;
                                    <FaComments /></b><br/>
                            </div>) : null
                        }

                        <div className="col-md-12 " id="seccion-review-lote">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <ExpedientIdentification
                                                idPrincipal={this.state.idFiado}
                                                showButtons={false}
                                            />
                                        </div>
                                        <div className="col-md-8 ">
                                            <InfoPrincipal
                                                idPrincipal={this.state.idFiado}
                                                label={this.props.language.language?.reviewLote.fiado!}
                                                openObligors={false}
                                                addOS={false}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-7">
                                    <br/>
                                    <AlphanumericInput
                                        className="float-right w-100"
                                        id="input--searchWarranties"
                                        labelVisual={this.props.language.language?.reviewLote.search!}
                                        name="search"
                                        onChange={this.handleInputChange}
                                        value={this.state.search}
                                    />
                                </div>

                            </div>

                        </div>


                        <div className="col-md-3" id="comentarios-review-lote">
                            <ComentariosFiado idFiado={this.state.idFiado}
                                              openComentario={this.state.openComentario}/>

                        </div>

                        {this.state.loadingGarantias?(
                            <div className="col-12">
                                <SpinnerLoading text={this.props.language.language?.tableWarranty.loadingWarranties}/>
                            </div>
                        ):(!this.state.errorListGarantias)? (
                                <div id="table-warranties" className="col-12">

                                    {/*data table*/}
                                    <Table tableName={this.props.language.language?.principalDetail.tableLoans}>
                                        <thead>
                                        <TableRow className="text-center">
                                            <TableCell dataHeader={this.props.language.language?.tableWarranty.invoice} type="colHead">
                                                {this.props.language.language?.tableWarranty.invoice}
                                            </TableCell>
                                            <TableCell dataHeader={this.props.language.language?.tableWarranty.warranty}  type="colHead">
                                                {this.props.language.language?.tableWarranty.warranty}
                                            </TableCell>
                                            <TableCell dataHeader={this.props.language.language?.tableWarranty.typeWarranty} type="colHead">
                                                {this.props.language.language?.tableWarranty.typeWarranty}
                                            </TableCell>
                                            <TableCell dataHeader={this.props.language.language?.tableWarranty.valueWarranty} type="colHead">
                                                {this.props.language.language?.tableWarranty.valueWarranty}
                                            </TableCell>
                                            <TableCell dataHeader={this.props.language.language?.tableWarranty.amountWeighted} type="colHead">
                                                {this.props.language.language?.tableWarranty.amountWeighted}
                                            </TableCell>

                                            <TableCell dataHeader={this.props.language.language?.tableWarranty.dateCapture} type="colHead">
                                                {this.props.language.language?.tableWarranty.dateCapture}
                                            </TableCell>
                                            <TableCell dataHeader={this.props.language.language?.tableWarranty.vigency} type="colHead">
                                                {this.props.language.language?.tableWarranty.vigency}
                                            </TableCell>
                                            <TableCell dataHeader={this.props.language.language?.tableWarranty.propietary} type="colHead">
                                                {this.props.language.language?.tableWarranty.propietary}
                                            </TableCell>
                                            <TableCell dataHeader={this.props.language.language?.tableWarranty.relevantDescription} type="colHead">
                                                {this.props.language.language?.tableWarranty.relevantDescription}
                                            </TableCell>
                                            <TableCell dataHeader={this.props.language.language?.tableWarranty.status} type="colHead">
                                                {this.props.language.language?.tableWarranty.status}
                                            </TableCell>
                                        </TableRow>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.listGarantias.map((i, index) =>
                                                (     <TableRow key={i.idGarantia}>
                                                        <TableCell dataHeader={this.props.language.language?.tableWarranty.invoice} type="rowHead">
                                                            {i.folio}{(i.version> 0)? '-' + i.version: null}
                                                        </TableCell>
                                                        <TableCell dataHeader={this.props.language.language?.tableWarranty.warranty} className="desc-gar">
                                                            {i.descripcionClave}
                                                        </TableCell>
                                                        <TableCell dataHeader={this.props.language.language?.tableWarranty.typeWarranty}>
                                                            {i.descripcionTipoUso}
                                                        </TableCell>
                                                        <TableCell dataHeader={this.props.language.language?.tableWarranty.valueWarranty}>
                                                            {formatCurrency(i.monto)}
                                                        </TableCell>
                                                        <TableCell dataHeader={this.props.language.language?.tableWarranty.amountWeighted} className="text-right">
                                                            {formatCurrency(i.montoPonderado)}
                                                        </TableCell>
                                                        <TableCell dataHeader={this.props.language.language?.tableWarranty.dateCapture}
                                                                   className="capture-gar">
                                                            {i.fechaCaptura}
                                                        </TableCell>
                                                        <TableCell dataHeader={this.props.language.language?.tableWarranty.vigency}

                                                                   className="vigency-gar"
                                                        >
                                                            {i.fechaInicial}<br/> {i.fechaFinal}
                                                        </TableCell>
                                                        <TableCell dataHeader={this.props.language.language?.tableWarranty.propietary}>
                                                            {i.propietario}
                                                        </TableCell>

                                                        <TableCell dataHeader={this.props.language.language?.tableWarranty.relevantDescription}
                                                                   className="desc-rel">
                                                            {(i.descRelevante)? <AcordionTruncate length={50} text={i.descRelevante!}/> : null}

                                                        </TableCell>

                                                        <TableCell dataHeader={this.props.language.language?.tableWarranty.status} className="estatus">

                                                            <StatusRowWarranty
                                                                idWarranty={i.idGarantia}
                                                                folio={i.folio}
                                                                version={i.version}
                                                                idPrincipal={i.idCliente}
                                                                idLote={this.state.idLote}
                                                                estadoVigencia={i.estadoVigencia}
                                                                descEstadoVigencia={i.descEstadoVigencia}
                                                                revisar={true}
                                                                returnDelete={del => {
                                                                    if (del){
                                                                        this.clickGoToPage(this.state.paginacion.currentPage);
                                                                    }
                                                                }}
                                                            />

                                                        </TableCell>

                                                    </TableRow>
                                                ))
                                        }

                                        </tbody>
                                    </Table>
                                    {/*paginacionPendients*/}
                                    <div className="row pagination-sdgf" >
                                        <div className="col-md-5 show-registers">
                                            Mostrando registros del&nbsp;
                                            {(this.state.paginacion.currentPage * this.state.paginacion.size)- (this.state.paginacion.size-1)} al&nbsp;
                                            {(this.state.paginacion.currentPage * this.state.paginacion.size)} de un total de&nbsp;
                                            {this.state.paginacion.totalItems} registros.
                                        </div>
                                        <div className="col-md-7 ">
                                            <Pagination className="float-md-right" id="pagination-table-warranty">
                                                <Pagination.Prev
                                                    onClick={()=>{
                                                        this.clickGoToPage((this.state.listPaginacion[0])-1)
                                                    }}
                                                />
                                                {
                                                    this.state.listPaginacion.map((i, index) =>
                                                        ( <Pagination.Item
                                                                key={i}
                                                                active={i === this.state.paginacion.currentPage}
                                                                onClick={()=>{
                                                                    this.clickGoToPage(i)
                                                                }}>
                                                                {i}
                                                            </Pagination.Item>

                                                        ))
                                                }
                                                <Pagination.Next
                                                    onClick={()=>{
                                                        this.clickGoToPage(this.state.listPaginacion[this.state.listPaginacion.length-1]+1)
                                                    }}
                                                />

                                            </Pagination>

                                        </div>

                                    </div>
                                </div>):
                            <div className="col-12">
                                <NegativeAlert
                                    textAlert={this.props.language.language?.tableWarranty.errorWarranties!}/>
                            </div>
                        }

                    </div>





                </div>
            </div>
        </>
    }

    componentDidMount() {
        if (validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR)){
            this.props.history.push('/401');
        }
        this.getWarrantiesLote( this.state.idLote, this.state.paginacion, this.state.search);
        if (validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) ||
            validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR)

        ){
            this.setState({showObligors: true});
        }


        let infoPrincipal = $('#seccion-review-lote').width();

        let show = false;
        $(".comments-review-lote").click(function(){
            console.log(infoPrincipal)


            $( "#comentarios-review-lote" ).toggle({duration:"slow",
                start(animation: JQuery.Animation<HTMLElement>) {
                    if (!show){
                        show = true;
                        $( '#seccion-review-lote').removeClass( "col-md-12" );
                        $( '#seccion-review-lote').addClass( "col-md-9" );

                        $('#warranty-review-lote').css({ "padding-left": "3%"});

                        $('#warranty-review-lote').css({ "padding-right": "0px"});


                    }else {
                        show = false
                        $( '#seccion-review-lote').addClass( "col-md-12" );
                        $( '#seccion-review-lote').removeClass( "col-md-9" );
                        $('#warranty-review-lote').css({ "padding-left": "7%"});
                        $('#warranty-review-lote').css({ "padding-right": "7%"});
                    }

                },

            });

        });




    }

    async getWarrantiesLote (idLote: number, paginacion: PaginacionModel, search: string| null){
        this.setState({loadingGarantias: true, errorListGarantias: false});
        if (search == ''){search = null}
        try {
            const res = await garantiasService.getGarantiasLote(idLote,paginacion, search );
            const response: {paginacion: PaginacionModel, elementos: ListGarantiaModel[]} = res.data;
            response.paginacion.size = paginacion.size;
            this.setState({
                paginacion: response.paginacion,
                loadingGarantias: false,
                listGarantias: response.elementos,
                listPaginacion: loadPaginationList(res.data.paginacion)})


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                this.setState({loadingGarantias: false, errorListGarantias: true});

            }
        }
    }

    public handleInputChange (e: InputChange)  {
        const value = e.target.value;

        console.log(e.target.value);
        if (e.target.name=='search'){
            this.setState({search:value,listGarantias:[]})

            if (value.length >= 1){
                //setListGarantias([])
                this.getWarrantiesLote( this.state.idLote, this.state.paginacion, value);
            }else if (value.length ==0){
                //setListGarantias([])
                this.getWarrantiesLote( this.state.idLote, this.state.paginacion, null);
            }

        }

    }

    private clickGoToPage (page: number) {
        const totalPages: number = this.state.paginacion.totalPages;
        if (page>=1 &&  page <= totalPages){
            const auxPaginacion = this.state.paginacion;
            auxPaginacion.currentPage = page;
            this.setState({paginacion: auxPaginacion})
            this.getWarrantiesLote( this.state.idLote, this.state.paginacion, this.state.search);
        }

    }
}

const mapStateToProps = (state: RootStore)  => ({
    language: state.language
});

export default connect(mapStateToProps) (ReviewLote);

interface ReviewLoteState {
    listGarantias: ListGarantiaModel [];
    loadingGarantias:  boolean;
    errorListGarantias: boolean;
    paginacion: PaginacionModel;
    listPaginacion: number[];
    search: string;
    showObligors: boolean;
    PERFIL: UserModel;
    openComentario: boolean;
    idFiado: number;
    idLote: number
}


