import React, {createRef, KeyboardEventHandler, useEffect, useRef, useState} from 'react';
import './VerificationInput.scss';
import {InputChange} from "../../../class/CommonClass";
interface VerificationInputProps {
    value: string;
    name: string;
    type: 'number' | 'text';
    onValueChange: (value: string, name: string ) => void;
}

/*
Implementation JSX
<VerificationInput
    value={''}
    name="code"
    type="text" // text or number
    onValueChange={ (value, name) => {
        console.log('Verification code: ' + value);
    }}
/>
*/


export const VerificationInput: React.FC<VerificationInputProps> = ({value,name,type, onValueChange}: VerificationInputProps) => {

    const [codeArray, setCodeArray] = useState<string []>([]);

    const inputRef = useRef<HTMLInputElement []>([]);

    const [currentPos, setCurrentPos] = useState<number>(0);

    const  nDigits: number = 6;


    useEffect(()=> {
        setCodeArray([])
        setCurrentPos(0)
        if (value.length <= nDigits){
            let auxArray: string [] = [];

            for (let i = 0; i<nDigits; i++){
                const digit = value.substring(i, i+1)
                auxArray.push(digit);
            }

            setCodeArray(auxArray);
        }
    }, [value]);

    const  handleInputChange =  (e: InputChange) => {
        //  console.log(codeArray);
        const  index = parseInt(e.target.name);
        const  value = e.target.value;
       // console.log('indice ' + index);
       // console.log('value  ' + value);
        const auxArray = [...codeArray];
        switch (type) {

            case "number":
                if (!isNaN(Number(value))){  // validamos si lo que esta escribiendo es un numero válido
                    auxArray[index] = value;
                    //console.log(auxArray)
                    setCodeArray([...auxArray])

                    const next = inputRef.current[index +1];
                    if (next) {
                        setCurrentPos(index+1)
                        next.focus()
                    }
                }
                break;
            case "text":
                auxArray[index] = value;
                //console.log(auxArray)
                setCodeArray([...auxArray])

                const next = inputRef.current[index +1];
                if (next) {
                    setCurrentPos(index+1)
                    next.focus()
                }
                break;

        }





            // regresar el código que estamos regresando
            returnCode(auxArray)


    }

    const handleKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
       // console.log(event.key);
        if (event.key == 'Backspace'){ // manupular el borrado
            const prevPos = currentPos-1;
            const prev = inputRef.current[prevPos];
            if (prev) {
                const auxArray = [...codeArray];
                auxArray[prevPos] = '';
                setCodeArray([...auxArray])
                setCurrentPos(prevPos)
                prev.focus()
                returnCode(auxArray)
            }

        }
    };

    const returnCode = (codeArray: string []) => {
        let newCode = '';
       // console.log('return code Array ');
      //  console.log(codeArray);
        for (const str of codeArray){
                newCode+= str;
        }
        //console.log(parseFloat(newCode));
        onValueChange!(newCode, name);

    }

    return (
        <>

            <div className="row" id="verification-code">
                {
                    codeArray.map( (digit, index) =>
                        <>

                                <input
                                    value={digit}
                                    type="text"
                                    maxLength={1}
                                    name={index.toString()}
                                    onChange={handleInputChange}
                                    onKeyUp={handleKeyUp}
                                    autoComplete={"off"}
                                    onFocus={ () => {
                                        setCurrentPos(index)
                                    }}
                                    ref={elRefs => inputRef.current![index] = elRefs as HTMLInputElement}
                                    className=" form-control col"/>


                            {  (((index+1)%3)== 0) && index!= codeArray.length-1 ? (
                               <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                            ): null}
                        </>
                    )
                }
            </div>

        </>

    );
};

