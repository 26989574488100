import { Component } from 'react';
import './FindClient.scss';
import {
    Button,
    IconAddItem,
    Modal,
    Link as LinkLiberty
} from "@lmig/lmds-react";
import Autocomplete from 'react-autocomplete';
import * as engagementService from '../../../services/compromisos/EngagementsService';
import { Cliente } from '../../../models/compromisos/Cliente';
import { RootStore } from '../../../Store';
import { connect } from 'react-redux';
import { DefaultStateI } from '../../../reducer/LanguageReducer';
import { Link } from "react-router-dom";
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import { validRoles } from '../../../class/CommonClass';
import { UserModel } from "../../../models/public/UserModel";




class FindClient extends Component<FindProps, FindState> {


    public idFiado: string;
    public idAgente: string;
    public busqueda: string;

    public localStorageClass = new LocalStorageClass();

    constructor(props: any) {
        super(props);
        this.idFiado = '';
        this.idAgente = '';
        this.busqueda = '';


        const PERFIL = this.localStorageClass.getProfile();

        this.state = {
            openModalClose: false,
            clientes: [],
            listClientOption: [],
            val: '',
            PERFIL: PERFIL,
            cliente: {
                idCliente: 0,
                razonSocial: '',
                rfc: '',
                agente: '',
                idAgente: 0
            }

        }
        this.handleInputChangeSelect = this.handleInputChangeSelect.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleOnMenu = this.handleOnMenu.bind(this)
    }

    componentDidMount() {

        //console.log("Recibo valor del agente seleccionado: " + this.props.idAgente)

        if (validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE)) {

            this.idAgente = this.state.PERFIL.clave!
        }

        this.getClientAlls(this.idAgente, 'a');

    }


    render(): JSX.Element {


        return <>
            <div className={"expedient-warranty"}>

                <a className="open-expedient text-center" onClick={() => {
                    this.setState({ openModalClose: true })
                }}>
                    <Button variant="primary"
                        className="float-right w-100">
                        <IconAddItem size="16" /> {this.props.language.language?.findClient.nuevoCompromiso}
                    </Button>

                </a>

                <Modal
                    isOpen={this.state.openModalClose}
                    size="large"
                    modalStyle="standard"
                    title={this.props.language.language?.findClient.tituloModal}
                    className="modal1"
                    closeFunc={() => {
                        this.setState({ openModalClose: false })
                    }}>

                    <div className="autocomplete-wrapper-cliente" >
                        <Autocomplete

                            value={this.state.val}
                            items={this.state.listClientOption}
                            getItemValue={item => item.nombre + item.rfc + item.idCliente}
                            shouldItemRender={this.renderClientName}
                            renderMenu={item => (
                                <div className="dropdown">
                                    {item}
                                </div>
                            )}
                            renderItem={(item, isHighlighted) =>
                                <div className={`item ${isHighlighted ? 'selected-item' : 'title'}`} >

                                    {`${item.nombre}-- ${item.rfc} `}
                                </div>
                            }
                            onMenuVisibilityChange={this.handleOnMenu}
                            //onChange={(event, val) => this.setState({ val }) }
                            onChange={this.handleInputChange}
                            //onSelect={val => this.setState({ val }) }  
                            onSelect={this.handleInputChangeSelect}

                        />

                    </div>

                    <br />
                    <br />

                    <div className="select-fiados">

                        <Link to={'/compromisos/crear-compromiso/' + this.idFiado} >
                            <LinkLiberty
                                variant="standalone"
                                className="btn-aceptar">
                                {this.props.language.language?.findClient.aceptar}
                            </LinkLiberty>
                        </Link>
                    </div>

                </Modal>
            </div>

        </>
    }


    async getClientAlls(idAgente: string, busqueda: string): Promise<void> {

        if (validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE)) {

            idAgente = this.state.PERFIL.clave!
        }


        console.log("Valores: " + idAgente + " busqueda: " + busqueda)

        const res = await engagementService.getClientAlls(idAgente, busqueda);
        this.setState({ clientes: res.data });
        console.log("Encuentra Todos los Clientes del Agente: " + idAgente);
        this.clientData();
    }


    renderClientName(state: { nombre: string, rfc: string }, val: string) {

        const nombreCompleto = state.nombre + '--' + state.rfc

        return (

            (nombreCompleto.toLowerCase().indexOf(val.toLowerCase()) !== -1)

        );
    }

    clientData() {
        let listaClientesAuxiliar: ClientOption[];
        listaClientesAuxiliar = []

        for (const condition of this.state.clientes) {

            listaClientesAuxiliar.push({ nombre: condition.razonSocial, rfc: condition.rfc, idCliente: condition.idCliente.toString() })
        }
        this.setState({ listClientOption: listaClientesAuxiliar })

        return [
            this.state.listClientOption,
        ]
    }

    handleInputChangeSelect(value: string, item: ClientOption): void {

        this.setState({ val: item.nombre })
        this.idFiado = item.idCliente;
        console.log("Se selecciona Fiado con ID: " + this.idFiado + " en FindClient")

    }


    handleInputChange(e: React.ChangeEvent<HTMLInputElement>, value: string): void {

        this.setState({ val: value })

        //Mandar clave
        //Perfil Agente --> la clave del que esta logueado
        //Perfil Ejecutivo --> puede ser null ó si selecciona Agente es el idAgente no clave
        //Promotor --> clave del promotor logueado lo  mando al endpoint de Agentes

        if (validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE)) {

            this.idAgente = this.state.PERFIL.clave!
        }

        if (validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA)) {
            this.idAgente = this.props.idAgente
        }

        this.getClientAlls(this.idAgente, this.state.val);

    }

    handleOnMenu(isOpen: boolean): void {

        if (validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE)) {

            this.idAgente = this.state.PERFIL.clave!
        }

        if (validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA)) {

            this.idAgente = this.props.idAgente
        }

        if (isOpen == true) {
            const busqueda = 'a'
            this.getClientAlls(this.idAgente, busqueda);
        }

    }




}
const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(FindClient);


interface FindProps {

    id: string;
    qualify: boolean;
    language: DefaultStateI;
    idAgente: string;

}

interface IListState {
    //cliente: Cliente[],

}

interface FindState {

    clientes: Cliente[],
    cliente: Cliente,
    listClientOption: ClientOption[],
    openModalClose?: boolean,
    val: string,
    PERFIL: UserModel
}

interface ClientOption {
    nombre: string;
    rfc: string;
    idCliente: string;
}

