import React, {FormEvent, useEffect, useRef, useState} from "react";
import './RecoverPsw.scss';
import {
    AlphanumericInput,
    Button,
    IconLockClosed,
    IconMyAccount, Link as LinkLiberty
} from "@lmig/lmds-react";
import NavBarLogin from "../../../components/public/nav-bar-login/NavBarLogin";
import userProfile from '../../../assets/images/public/login/user.png';
import * as generalService from "../../../services/api-gral/ApiGralService";
import { CodigoRespuesta } from "../../../models/compromisos/CodigoRespuesta";
import {InputChange} from "../../../class/CommonClass";
import {SpinnerLoading} from "../../../components/public/spinner-loading/SpinnerLoading";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {Link, useHistory} from "react-router-dom";
import {NegativeAlert} from "../../../components/public/negative-alert/NegativeAlert";
import hidden from '../../../assets/images/public/login/hidden.png';
import show from '../../../assets/images/public/login/show.png';
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";

const RecoverPsw = () => {
    const languageState = useSelector((state: RootStore) => state.language.language?.recoverPsw);

    const localStorageClass = new LocalStorageClass();

    const history = useHistory();

    const [usename, setUsername] = useState<string>('');

    const [newPassword, setNewPassword] = useState<string>('');

    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const [sending, setSending] = useState(false);

    const [errorSignin, setErrorSing] = useState(false);

    const [showErrorSubmit, setShowErrorSubmit] = useState<boolean>(false);

    const [showPsw, setShowPsw] = useState(false);

    const textInputUserName = useRef<HTMLInputElement | any>(null);

    const [steepsForm, setSteepsForm] = useState<number>(1);

    const [codigoAcceso, setCodigoAcceso] = useState<string>('');

    const [validaCodigoRespuesta, setRespuesta] = useState<CodigoRespuesta>({
        codigo: 0, 
        descripcion: "" 
    });



    useEffect(() => {
        document.title = "Login"
    });

    const getCodigoConfirmacion = async () => {

        const res = await generalService.getCodigoConfirmacion(usename, "")
        //setCodigoAcceso(res.data)

        console.log("CODIGO::: " + JSON.stringify( codigoAcceso) + " PARA EL USUARIO::: " + usename)
        
    }

    const postValidaCodigoConfirmacion = async () => {

        console.log("SE VALIDA EL CODIGO DEL USERNAME: " + usename + " CON EL NUEVO PASS: " + newPassword)
        const res = await generalService.postValidaCodigo(usename, parseInt(codigoAcceso) ,  newPassword, tipoOperacion.PASS)
        setRespuesta(res.data)

        console.log(JSON.stringify(validaCodigoRespuesta))
    }

    const handleInputChange = (e: InputChange) => {
        const name = e.target.name;
        const value = e.target.value

        switch (name) {
            case 'username':
                setUsername(value)
                console.log("USERNAME::: " + usename)
                break;
            default:
                //
                break;

        }
        // setLogin({ ...login, [e.target.name]: e.target.value });
    }

    const handleSubmit1 = async (e: FormEvent<HTMLFormElement>) => {
        setShowErrorSubmit(false);
        setErrorSing(false);
        e.preventDefault();
        if (validateFormLogin()) {
            //setSending(true);
            setSteepsForm(2)
            /*     try {
                     const res =await apiTokenService.postSignin(login);
                     setSending(false);
                     await localStorageClass.setSomeString(localStorageClass.PERFIL, JSON.stringify(res.data));
                     history.push('/polizasrh')
     
                 } catch (error) {
                     const err = error as AxiosError
     
     
                     if (err.response) {
                         if (err.response.status == 401){
                             setSending(false);
                             setErrorSing(true);
                         }else{
                             setSending(false);
                             setShowErrorSubmit(true);
                             console.log(err.response.status)
                             console.log(err.response.data)
                         }
     
                     }
                 }*/
        }

    };

    const handleSubmit2 = async (e: FormEvent<HTMLFormElement>) => {
        setSteepsForm(3)
      
        /* setShowErrorSubmit(false);
         setErrorSing(false);
         e.preventDefault();
         if (validateFormLogin()) {
             setSending(true);
             setSteepsForm(2)
             /!*     try {
                      const res =await apiTokenService.postSignin(login);
                      setSending(false);
                      await localStorageClass.setSomeString(localStorageClass.PERFIL, JSON.stringify(res.data));
                      history.push('/polizasrh')
 
                  } catch (error) {
                      const err = error as AxiosError
 
 
                      if (err.response) {
                          if (err.response.status == 401){
                              setSending(false);
                              setErrorSing(true);
                          }else{
                              setSending(false);
                              setShowErrorSubmit(true);
                              console.log(err.response.status)
                              console.log(err.response.data)
                          }
 
                      }
                  }*!/
         }*/

    };

    const validateFormLogin = (): boolean => {
        return true;
    }

    return (
        <>
            <NavBarLogin />

            <div className="container-fluid" id="recuperar">


                <div className="row container-login">
                    <div className="col-md-4" />
                    <div className="col-md-4 card" id="form-login">
                        <div className="row">
                            <div className="col-4"></div>
                            <div className="col-4 div-profile">
                                <img src={userProfile}
                                    className="img-fluid
                                                img-thumbnail
                                                d-block
                                                mx-auto"
                                    id="img-profile" />
                            </div>
                            <div className="col-4 "></div>
                        </div>
                        {(sending) ? <>
                            <h3 className="text-center">
                                Enviando

                            </h3>
                            <SpinnerLoading />
                        </> : <>
                            <h5 className="text-center">

                            </h5>

                            {(steepsForm == 1) ? (

                                <form onSubmit={handleSubmit1} className="col-12">

                                    <h5 className="text-center">
                                        ¿Tienes problemas para entrar?
                                    </h5>
                                    <p className="text-center info-recover">
                                        Introduce tu nombre de usuario <br />
                                        y te enviaremos a tu correo un código<br />
                                        para que vuelvas a entrar a tu cuenta.
                                    </p>
                                    <div className="row input-group">

                                        <div className="col-md-2 ">
                                            <IconMyAccount
                                                size="24"
                                                className="d-none
                                                    d-sm-none
                                                    d-md-block
                                                    d-lg-block
                                                    d-xl-block
                                                    mx-auto "
                                            />
                                        </div>
                                        <div className="col-md-10 ">
                                            <AlphanumericInput labelVisual={languageState?.username}
                                                className="input-user-name"
                                                autoComplete="off"
                                                ref={textInputUserName}
                                                autoFocus={true}
                                                name="username"
                                                onChange={handleInputChange}
                                                value={usename}
                                                type="text" />
                                        </div>
                                    </div>

                                    {/*      <div className="row input-group">

                                    <div className="col-md-2">
                                        <IconLockClosed
                                            size="24"
                                            className="d-none
                                                    d-sm-none
                                                    d-md-block
                                                    d-lg-block
                                                    d-xl-block
                                                    mx-auto "
                                        />
                                    </div>
                                    <div className="col-md-10">
                                        <div className="row">
                                            <div className="col-10">
                                                <AlphanumericInput labelVisual={languageState?.password}
                                                                   className="input-user-name"
                                                                   name="password"
                                                                   onChange={handleInputChange}
                                                                   value={newPassword}
                                                                   type={showPsw?'text': 'password'} />
                                            </div>
                                            <div className="col-2">
                                                {(showPsw)?
                                                    <a
                                                        id="ico-show-psw"
                                                        onClick={()=>{
                                                            setShowPsw(false)
                                                        }} >
                                                        <img src={show} className="img-fluid d-block mx-auto"/>

                                                    </a>:
                                                    <a
                                                        id="ico-hidden-psw"
                                                        onClick={()=>{
                                                            setShowPsw(true)
                                                        }} >
                                                        <img src={hidden} className="img-fluid d-block mx-auto"/>

                                                    </a>
                                                }


                                            </div>
                                        </div>

                                    </div>

                                </div>*/}


                                    {(errorSignin) ?
                                        <div className="col-12 text-center">
                                            <br />
                                            <NegativeAlert textAlert={languageState?.errorUserPsw!} />
                                            <br />
                                        </div> : null}

                                    <div className="row buttons-navigation">
                                        <div className="col-md-8"></div>
                                        <div className="col-md-12 col-sm-12 col-12">
                                            <Button onClick={() => {
                                                getCodigoConfirmacion()}}
                                                type="submit"
                                                variant="primary"
                                                dynamicWidth
                                                className="btn-login w-100">
                                                Solicitar
                                            </Button>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <hr />
                                    <div className="col-12 text-center">
                                        <Link to="/login" >
                                            <LinkLiberty variant="inline">
                                                Volver al Inicio de sesión
                                            </LinkLiberty>
                                        </Link>
                                    </div>


                                </form>
                            ) : null}

                            {(steepsForm == 2) ? (
                                <form onSubmit={handleSubmit2} className="col-12">
                                    <h5 className="text-center">
                                        Código de Acceso
                                    </h5>
                                    <p className="text-center info-recover">
                                        Introduce el código que se envió a tu correo <br />

                                    </p>

                                    <div className="row input-group">

                                        <div className="col-md-2">
                                            <IconLockClosed
                                                size="24"
                                                className="d-none
                                                    d-sm-none
                                                    d-md-block
                                                    d-lg-block
                                                    d-xl-block
                                                    mx-auto "
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-10">
                                                    <AlphanumericInput labelVisual={"Código de Acceso"}
                                                        className="input-user-name"
                                                        name="password"
                                                       
                                                        onChange={handleInputChange}
                                                        value={codigoAcceso}
                                                        type={showPsw ? 'text' : 'password'} />
                                                </div>
                                                <div className="col-2">
                                                    {(showPsw) ?
                                                        <a
                                                            id="ico-show-psw"
                                                            onClick={() => {
                                                                setShowPsw(false)
                                                            }} >
                                                            <img src={show} className="img-fluid d-block mx-auto" />

                                                        </a> :
                                                        <a
                                                            id="ico-hidden-psw"
                                                            onClick={() => {
                                                                setShowPsw(true)
                                                            }} >
                                                            <img src={hidden} className="img-fluid d-block mx-auto" />

                                                        </a>
                                                    }


                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                    {(errorSignin) ?
                                        <div className="col-12 text-center">
                                            <br />
                                            <NegativeAlert textAlert={languageState?.errorUserPsw!} />
                                            <br />
                                        </div> : null}

                                    <div className="row buttons-navigation">
                                        <div className="col-md-12">
                                            <Button dynamicWidth

                                                type="submit"
                                                variant="primary"
                                                className="w-100">
                                                Validar
                                            </Button>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <hr />
                                    <div className="col-12 text-center">
                                        <Link to="/login" >
                                            <LinkLiberty variant="inline">
                                                Volver al Inicio de sesión
                                            </LinkLiberty>
                                        </Link>
                                    </div>


                                </form>
                            ) : null}


                            {(steepsForm == 3) ? (
                                <form  className="col-12">
                                    <h5 className="text-center">
                                        Nueva Contraseña
                                    </h5>
                                    <p className="text-center info-recover">
                                        Introduce la contraseña para actualizar <br />

                                    </p>

                                    <div className="row input-group">

                                        <div className="col-md-2">
                                            <IconLockClosed
                                                size="24"
                                                className="d-none
                                                    d-sm-none
                                                    d-md-block
                                                    d-lg-block
                                                    d-xl-block
                                                    mx-auto "
                                            />
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-10">
                                                    <AlphanumericInput labelVisual={"Contraseña"}
                                                        className="input-user-name"
                                                        name="password"
                                                        //onChange={handleInputChange}
                                                        value={newPassword}
                                                        type={showPsw ? 'text' : 'password'} />
                                                </div>
                                                <div className="col-2">
                                                    {(showPsw) ?
                                                        <a
                                                            id="ico-show-psw"
                                                            onClick={() => {
                                                                setShowPsw(false)
                                                            }} >
                                                            <img src={show} className="img-fluid d-block mx-auto" />

                                                        </a> :
                                                        <a
                                                            id="ico-hidden-psw"
                                                            onClick={() => {
                                                                setShowPsw(true)
                                                            }} >
                                                            <img src={hidden} className="img-fluid d-block mx-auto" />

                                                        </a>
                                                    }


                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                    {(errorSignin) ?
                                        <div className="col-12 text-center">
                                            <br />
                                            <NegativeAlert textAlert={languageState?.errorUserPsw!} />
                                            <br />
                                        </div> : null}
                                    <div className="row buttons-navigation">
                                        <div className="col-md-8"></div>
                                        <div className="col-md-4">
                                            <Button dynamicWidth   
                                                onClick={() => {
                                                            postValidaCodigoConfirmacion()}}
                                                type="submit"
                                                variant="primary"
                                                className="btn-login w-100">
                                                Actualizar
                                            </Button>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <hr />
                                    <div className="col-12 text-center">
                                        <Link to="/login" >
                                            <LinkLiberty variant="inline">
                                                Volver al Inicio de sesión
                                            </LinkLiberty>
                                        </Link>
                                    </div>


                                </form>
                            ) : null}

                        </>}


                    </div>
                    <div className="col-md-4" />
                </div>

            </div>
        </>


    );
};

export default RecoverPsw;

export enum tipoOperacion {
    PASS = 1,
    EMAIL = 2

}