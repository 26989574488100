import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { AlphanumericInput } from "@lmig/lmds-react";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import './Engagements.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import "react-circular-progressbar/dist/styles.css";
import TableEngagementsAuthorize from "../../../components/compromisos/table-enegagements-authorize/TableEngagementsAuthorize";
import { validRoles } from "../../../class/CommonClass";
import { UserModel } from "../../../models/public/UserModel";
import TableEngagementsCheck from "../../../components/compromisos/table-engagements-check/TableEngagementsCheck";
import { RootStore } from "../../../Store";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { connect } from "react-redux";  



class CheckEngagements extends Component<IListProps & RouteComponentProps, IListState >{

    public localStorageClass = new LocalStorageClass();

    constructor(props: any) {

        super(props);
        document.title = 'Cumplimientos'

        this.state = {            
            PERFIL: this.localStorageClass.getProfile()
        
        }
    }


    render(): JSX.Element{
        
        return <>
                <NavBar />
            <div className="container-fluid ">

            

                    {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) ||
                     validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO) ||
                     validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) // solo el ejecutivo de garantías
                        ? (
                         <div className="waranty-container">
                         <div className="row">
                             <div className="col-md-12">
                                <h2 className={"title-seccion"}>
                                {this.props.language.language?.checkEngagements.titulo} <br /><br />
                                </h2>
                             </div>


                            <div className="col-12">
                                <TableEngagementsCheck language={this.props.language} />
                            </div>
                        
                         </div>
                     
                        </div>
                    ) : null}
            </div>
        </>
    }
}

interface IListProps {
    language: DefaultStateI

}

interface IListState {
    PERFIL: UserModel;  
    
} 



const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(CheckEngagements);
