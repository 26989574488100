import {LanguageModel} from "./SelectLanguageActionTypes";
import {languageWarrantiesEnglish} from "../views/garantias/warranties/WarrantiesLanguage";
import {SelectLanguageEnglish} from "../components/public/select-language/SelectLanguageModel";
import {SelectAgentLanguageEnglish} from "../components/garantias/select-type-user/SelectTypeUserLanguageModel";
import {EngagmentsLanguageEnglish} from "../views/compromisos/engagements/EngagmentsLanguageModel";
import {AuthorizeEngagementsLanguageEnglish} from "../components/compromisos/authorization/AuthorizeEngagementsLanguageModel";
import {SelectFiadoLanguageEnglish} from "../components/public/select-type/SelectFiadoLanguageModel";
import { FindClientLanguageEnglish } from "../components/compromisos/find-client/FindClientLanguageModel";
import {
    WarrantyLanguageEnglish
} from "../views/garantias/warranty/WarrantyLanguageModel";
import {ExpedientIdentificationLanguageEnglish} from "../components/garantias/expedient-identification/ExpedientIdentificationLanguageModel";
import { CreateEngagmentsLanguageEnglish } from "../views/compromisos/create-engagements/CreateEngagementLanguageModel";
import { TableEngagmentsLanguageEnglish } from "../components/compromisos/table-engagements/TableEngagementsLanguage";
import { CheckEngagmentsLanguageEnglish } from "../views/compromisos/engagements/CheckEngagemetsLanguageModel";
import { AutoEngagmentsLanguageEnglish } from "../views/compromisos/engagements/AutoEngagementsLanguageModel";
import { NavBarLanguageEnglish } from "../components/public/nav-bar/NavBarLanguageModel";
import {WarrantiesReviewLanguageEnglish} from "../views/garantias/warranties-review/WarrantiesReviewLanguage";
import {PrincipalDetailLanguageEnglish} from "../views/garantias/principal-detail/PrincipalDetailLanguage";
import {TableWarrantyLanguageEnglish} from "../components/garantias/table-loans-principal/TableLoansPrincipalLangugage";
import {InfoPrincipalLanguageEnglish} from "../components/garantias/info-principal/InfoPrincipalLanguage";
import {ReviewLoteLanguageEnglish, ReviewLoteLanguageModel} from "../views/garantias/review-lote/ReviewLoteLanguage";
import {
    StatisticsWarrantiesLanguageEnglish
} from "../views/garantias/statistics/StatisticsWarrantiesLanguge";
import {StatusRowWarrantyLanguageEnglish} from "../components/garantias/status-row-warranty/StatusRowWarrantyLanguage";
import {
    AuthorizationAmountWarrantyEnglish
} from "../components/garantias/authorization-amount-warranty/AuthorizationAmountWarrantyLanguage";
import {
    AuthorizationDocWarrantyLanguageEnglish
} from "../components/garantias/authorization-doc-warranty/AuthorizationDocWarrantyLanguage";
import {AddObligorLanguageEnglish} from "../components/garantias/add-obligor/AddObligorLanguage";
import {
    WarrantyAuthorizationLanguageEnglish
} from "../views/garantias/warranty-review/WarrantyReviewLanguage";
import { AuthorizationDictumWarrantyLanguageEnglish
} from "../components/garantias/authorization-dictum-warranty/AuthorizationDictumWarrantyLanguage";
import {BreadCumbPrevLanguageEnglish} from "../components/public/breadcumb-prev/BreadCumbPrevLanguage";
import {ObligorsDetailLanguageEnglish} from "../views/garantias/obligor-details/ObligorsDetailsLanguage";
import {LockPrincipalEnglish} from "../components/garantias/lock-principal/LockPrincipalLanguage";
import {ModalFileMaxUploadLanguageEnglish} from "../components/public/modal-file-max-upload/ModalFileMaxUploadLanguage";
import {
    AuthorizationPercentWarrantyEnglish
} from "../components/garantias/authorization-percent-warranty/AuthorizationPercentWarrantyLanguage";
import { CreatequeryLanguageEnglish } from "../views/buro-credito/create-query/CreatequeryLanguage";
import { CreateCreditBureauLanguageEnglish } from "../views/buro-credito/credit-bureau/CreditbureauLanguage";
import {HomeLanguageEnglish} from "../views/public/home/HomeLanguage";
import {NavBarPublicLanguageEnglish} from "../components/public/nav-bar-public/NavBarPublicLanguage";
import { CreatefiltrarLanguageEnglish } from "../views/buro-credito/filtrar/filtrar";
import {LoginLanguageEnglish} from "../views/public/login/LoginLanguage";
import { languagePolizasIngles } from "../views/rh/polizas-rh/PolizasRHLanguage";
import {ReviewDocsLanguageEnglish} from "../views/garantias/review-docs/ReviewDocsLanguage";
import {RecoverPswLanguageEnglish} from "../views/public/recover-psw/RecoverPswLanguage";
import {AuthorizationDateWarrantyEnglish} from "../components/garantias/authorization-vigency-warranty/AuthorizationVigencyWarrantyLanguage";
import {languageFormCreateEngagmentsEnglish} from "../components/compromisos/form-create-engagments/FormCreateEngagmentsLanguage";
import {ConfigAccountLanguageEnglish} from "../views/buro-credito/Configuracion/ConfigCuentaLanguage";
import { languageDepostitosGarantiasIngles } from "../views/tesoreria/depositos-garantia/DepositosGarantiasLanguage";
import { languageReporteGarantiasIngles } from "../views/reportes/garantias-vencidas/ReporteGarantiasLanguage";
import { languageMsgFirmaIngles } from "../views/tesoreria/msg-firma/MsgFirmaLanguage";
import {UnlockPrincipalEnglish} from "../components/garantias/unlock-principal/UnlockPrincipalLanguage";
import {LockCommissionPrincipalEnglish} from "../components/garantias/lock-commission-principal/LockCommissionPrincipalLanguage";
import {UnockCommissionPrincipalEnglish} from "../components/garantias/unlock-commission-principal/UnLockCommissionPrincipalLanguage";
import { RequestsLanguageEnglish } from "../views/buro-credito/requests/Requests";
import {CreateWarrantyLanguageEnglish} from "../components/garantias/create-warranty/CreateWarrantyLanguage";
import {
    SelectUserEngagementsLanguageEnglish
} from "../components/compromisos/select-user-engagements/SelectUserEngagementsLanguage";
import {reporteExpedienteEnglish} from "../views/garantias/reporte-expediente/ReporteExpedienteLanguage";
import {ComentariosFiadoLanguageEnglish} from "../components/garantias/comentarios-fiado/ComentariosFiadoLanguage";
import {ResumeGarantiasLanguageEnglish} from "../components/garantias/resume-garantias/ResumeGarantiasLanguage";
import {
    StatusRowPrincipalLanguageEnglish
} from "../components/garantias/status-row-principal/StatusRowPrincipalLanguage";
import {ModalDeleteOsLanguageEnglish} from "../components/garantias/modal-delete-os/ModalDeleteOsLanguage";
import {InfoPrincipalLockEnglish} from "../components/garantias/info-principal-lock/InfoPrincipalLockLanguage";
import { AuditoriasLanguageEnglish } from "../views/buro-credito/auditoria/AuditoriasLanguage";
import { ViewAuditoriasLanguageEnglish } from "../views/buro-credito/viewAditoria/ViewAuditoriasLanguage";
import { CrearAuditoriaLanguageEnglish } from "../views/buro-credito/crear-auditoria/CrearAuditoriaLanguage";
import {LineaDeAfianzamientoEnglish} from "../components/garantias/linea-de-afianzamiento/LineaDeAfianzamientoLanguage";
import {SelectProductosLineaEnglish} from "../components/garantias/select-productos-linea/SelectProductosLineaLanguage";
import { WarrantyHistoryLanguageEnglish } from "../views/garantias/warranties-history/WarrantyHistoryLanguage";
import { HistoryReviewLanguageModelLanguageEnglish } from "../views/garantias/history-review/HistoryReviewLanguage";
import {WarrantyComparisionLanguageEnglish} from "../views/garantias/warranty-comparison/WarrantyComparisonLanguage";


export const languageObjectEnglish: LanguageModel ={
    saludo: 'Welcome',
    // vistas
    warranties: languageWarrantiesEnglish,
    warranty: WarrantyLanguageEnglish,
    warrantiesReview: WarrantiesReviewLanguageEnglish,
    principalDetail: PrincipalDetailLanguageEnglish,
    reviewLote: ReviewLoteLanguageEnglish,
    statisticsWarranty: StatisticsWarrantiesLanguageEnglish,
    warrantyAuthorization: WarrantyAuthorizationLanguageEnglish,
    obligorsDetail: ObligorsDetailLanguageEnglish,
    creditbureau:CreateCreditBureauLanguageEnglish,
    createquery:CreatequeryLanguageEnglish,
    homeLanguage: HomeLanguageEnglish,
    createfiltrar:CreatefiltrarLanguageEnglish,
    login: LoginLanguageEnglish,
    polizasRH: languagePolizasIngles,
    recoverPsw:  RecoverPswLanguageEnglish,
    configAccount: ConfigAccountLanguageEnglish,
    depositosGarantias : languageDepostitosGarantiasIngles,
    reporteGarantias : languageReporteGarantiasIngles,
    msgFirma : languageMsgFirmaIngles,
    request:RequestsLanguageEnglish,
    auditorias:AuditoriasLanguageEnglish,
    ViewAudi:ViewAuditoriasLanguageEnglish,
    CreateAudi:CrearAuditoriaLanguageEnglish,
    WarrantyHistoryLanguage: WarrantyHistoryLanguageEnglish,
    WarrantyComparisonLanguage: WarrantyComparisionLanguageEnglish,
    HistoryReviewLanguage: HistoryReviewLanguageModelLanguageEnglish,
    // componentes
    selectLanguage: SelectLanguageEnglish,
    selectAgent: SelectAgentLanguageEnglish,
    engagments: EngagmentsLanguageEnglish,
    findClient: FindClientLanguageEnglish,
    createEngagement: CreateEngagmentsLanguageEnglish,
    authorizeEngagements: AuthorizeEngagementsLanguageEnglish,
    selectFiado: SelectFiadoLanguageEnglish,
    expedientIdentificaction: ExpedientIdentificationLanguageEnglish,
    tableEngagements: TableEngagmentsLanguageEnglish,
    checkEngagements: CheckEngagmentsLanguageEnglish,
    autoEngagements: AutoEngagmentsLanguageEnglish,
    navBar: NavBarLanguageEnglish,
    tableWarranty: TableWarrantyLanguageEnglish,
    infoPrincipal: InfoPrincipalLanguageEnglish,
    statusRowWarranty: StatusRowWarrantyLanguageEnglish,
    authorizationAmountWarranty: AuthorizationAmountWarrantyEnglish,
    authorizationPercentWarranty: AuthorizationPercentWarrantyEnglish,
    authorizationDocWarranty: AuthorizationDocWarrantyLanguageEnglish,
    addObligor: AddObligorLanguageEnglish,
    authorizationDictumWarranty: AuthorizationDictumWarrantyLanguageEnglish,
    BreadCumbPrev: BreadCumbPrevLanguageEnglish,
    lockPrincipal: LockPrincipalEnglish,
    unlockPrincipal: UnlockPrincipalEnglish,
    modalFileMaxUpload: ModalFileMaxUploadLanguageEnglish,
    navBarPublic: NavBarPublicLanguageEnglish,
    reviewDocs: ReviewDocsLanguageEnglish,
    authorizationDateWarrantyLanguage: AuthorizationDateWarrantyEnglish,
    formCreateEngagmentsLanguage: languageFormCreateEngagmentsEnglish,
    lockCommissionPrincipal: LockCommissionPrincipalEnglish,
    unLockCommissionPrincipal: UnockCommissionPrincipalEnglish,
    createWarranty: CreateWarrantyLanguageEnglish,
    selectUserEngagments: SelectUserEngagementsLanguageEnglish,
    reporteExpediente:  reporteExpedienteEnglish,
    comentariosFiado: ComentariosFiadoLanguageEnglish,
    resumeGarantias: ResumeGarantiasLanguageEnglish,
    statusRowPrincipal: StatusRowPrincipalLanguageEnglish,
    modalDeleteOs: ModalDeleteOsLanguageEnglish,
    infoPrincipalLock: InfoPrincipalLockEnglish,
    lineaCreditoLanguage: LineaDeAfianzamientoEnglish,
    selectProductosLinea: SelectProductosLineaEnglish
}