import React, { useEffect, useState } from 'react';
import { Accordion, Card, ModalBody, ModalFooter, Nav, Navbar } from 'react-bootstrap';
import './NavBar.scss';
import logo from '../../../assets/ico/PNG/LibertyFianzas_BLUE_RGB.png';
import womanImg from '../../../assets/ico/Woman_at_Desk8.png';

import {
    IconMenu,
    IconClose,
    IconBell,
    Modal, GridRow, GridCol, Button,
    IconHelp, Heading,
} from '@lmig/lmds-react'
import { useHistory } from "react-router-dom";
import SelectLanguage from "../select-language/SelectLanguage";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import { UserModel } from "../../../models/public/UserModel";
import { validRoles } from "../../../class/CommonClass";
import environment from "../../../environments/environment";
import Dropdown from 'react-bootstrap/Dropdown';
import { getActionSpecials } from "../../../actions/SpecialActions";
import * as apiTokenService from "../../../services/api-session/ApiSession";
import * as apiService from '../../../services/api-gral/ApiGralService';
import { AxiosError } from "axios";
import { getPerfilEjeGar } from "../../../services/api-gral/ApiGralService";
import { AccessBuro } from '../../buro-credito/AccessBuro';
import { NavLF, FooterLF } from "@lmig/lf-react-components/src/";
import '@lmig/lf-react-components/src/index.css'

const URLVIDEO = environment.URLVIDEO

const NavBarOld = () => {
    const dispatch = useDispatch();

    const localStorageClass = new LocalStorageClass();

    const history = useHistory();

    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    // side bar
    const [openSideBar, setOpenSideBar] = useState(false);
    // modales
    const [openModalClose, setOpenModalClose] = useState(false);

    const [openModalVideo, setOpenModalVideo] = useState(false);

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [isOpen, setIsOpen] = useState(false);

    const [visibleReporteExpediente, setVisibleReporteExpediente] = useState(false);

    useEffect(() => {

        //si no hay un token guardado
        if (!PERFIL.accessToken) {
            // redireccionar
            redirectLogin()

        } else {
            // si hay token hacer las acciones correspondientes
            // obtener los permisos especiales
            obtainActionsSpecials();
            validToken(PERFIL.accessToken);


        }

    }, [history.location.pathname]);

    const validToken = async (token: string) => {
        try {
            const res = await apiTokenService.getValidaToken(PERFIL.accessToken);
            //   console.log(res.data)
            validOficina()


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                if (err.response.status == 401) {
                    setIsOpen(true);
                }


            }
        }
    }

    const validOficina = async () => {
        setVisibleReporteExpediente(false)
        if (validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS)) {
            try {
                const res = await apiService.getPerfilEjeGar();

                if (res.data.oficina == 'OFICINA CENTRAL LIBERTY FIANZAS') {
                    setVisibleReporteExpediente(true);
                }


            } catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    setVisibleReporteExpediente(false);
                }
            }
        }

    }

    const open = () => {
        if (!openSideBar) {
            setOpenSideBar(true);
            // @ts-ignore
            document.getElementById('mySidenav').style.width = '250px';
            // @ts-ignore
            document.getElementById('coverNav').style.width = '100%';
        } else {
            close()
        }

    };

    const close = () => {
        setOpenSideBar(false);
        // @ts-ignore
        document.getElementById('mySidenav').style.width = '0';
        // @ts-ignore
        document.getElementById('coverNav').style.width = '0';


    };

    const closeSession = () => {
        localStorage.removeItem(localStorageClass.PERFIL);
        redirectLogin();

    }

    const redirectLogin = () => {
        const URLLOGIN = environment.URLLOGIN
        if (environment.env == "dev") {
            history.push('/login')
        }
        else if (environment.env == "test") {
            window.location.replace(URLLOGIN);
        }
        else if (environment.env == "prod") {
            window.location.replace(URLLOGIN);
        }


    }

    const menuSeccion = (pathname: string) => {
        // console.log(pathname)
        let seccion = '';
        for (let i = 1; i < pathname.length; i++) {
            const letra = pathname.substring(i, i + 1);
            if (letra != '/') {
                seccion += letra;
            } else {
                break;
            }
        }
        return seccion;
    }

    const obtainActionsSpecials = async () => {
        await dispatch(getActionSpecials());

    }

    return (
        <>

            <div id="nav-bar">

                <Navbar fixed="top">
                    <Navbar.Brand>
                        {
                            validRoles(PERFIL.roles, localStorageClass.FUNCIONARIO) ||
                                validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA) ||
                                validRoles(PERFIL.roles, localStorageClass.AGENTE) ||
                                validRoles(PERFIL.roles, localStorageClass.PROMOTOR) ||
                                validRoles(PERFIL.roles, localStorageClass.BURO) ||
                                validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS) ||
                                validRoles(PERFIL.roles, localStorageClass.USUARIOCONTABLE) ||
                                validRoles(PERFIL.roles, localStorageClass.CAJERO) ||
                                validRoles(PERFIL.roles, localStorageClass.AUDITOR) ||
                                validRoles(PERFIL.roles, localStorageClass.AUXILIAR_JURIDICO)

                                ? (

                                    <>
                                        <a id="toogle-ico" onClick={open}>
                                            {
                                                !openSideBar ?
                                                    <IconMenu
                                                        title={languageState.language?.navBar.openMenu}
                                                        size={{
                                                            base: '32',
                                                            md: '24',
                                                            xl: '24'
                                                        }}
                                                        color="blue" />
                                                    :
                                                    <IconClose
                                                        title={languageState.language?.navBar.closeMenu}
                                                        size={{
                                                            base: '32',
                                                            md: '24',
                                                            xl: '24'
                                                        }}
                                                        color="blue" />
                                            }

                                        </a></>) : null}

                        <img
                            src={logo}
                            width="112" height="53.3"
                            onClick={open}
                            className="d-inline-block align-top ico-liberty"
                            alt="Logo Liberty Fianzas"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Nav className="me-auto">
                            {(environment.env != "prod") ?
                                <>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="Primary" id="dropdown-basic">
                                            {PERFIL.username}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>

                                            <Dropdown.Item onClick={() => { history.push("/cuenta") }
                                            }> Cuenta
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </> :
                                <div className="col-3 icons-nav">
                                    {PERFIL.username}
                                </div>

                            }
                        </Nav>
                        <Navbar.Text className="">
                            <div className="row">
                                <div className="col-3">
                                    <SelectLanguage />
                                </div>
                                {(menuSeccion(history.location.pathname) == 'compromisos') ? (
                                    <div className="col-3 icons-nav">
                                        <IconHelp
                                            className="bg-white"
                                            onClick={() => {
                                                setOpenModalVideo(true)
                                            }}
                                            title={languageState.language?.navBar.emails}
                                            size={{
                                                base: '32',
                                                md: '32',
                                                xl: '48'
                                            }} />
                                    </div>
                                ) : <div className="col-3 icons-nav">
                                    <IconBell
                                        className="bg-white"
                                        title={languageState.language?.navBar.notifications!}
                                        size={{
                                            base: '32',
                                            md: '32',
                                            lg: '32',
                                            xl: '48'
                                        }} />
                                </div>}


                                <div className="col-3 icons-nav">
                                    <IconClose
                                        className="bg-white"
                                        title={languageState.language?.navBar.closeSession!}
                                        onClick={() => setOpenModalClose(true)}
                                        size={{
                                            base: '32',
                                            md: '32',
                                            xl: '48'
                                        }} />
                                </div>

                            </div>

                        </Navbar.Text>
                    </Navbar.Collapse>
                </Navbar>

                <div id="mySidenav" className="panel-group bg-light side-nav">
                    <Accordion defaultActiveKey={menuSeccion(history.location.pathname)}>
                        {(validRoles(PERFIL.roles, localStorageClass.BURO)) ?
                            <>

                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="buro-credito">
                                        {languageState.language?.navBar.panelHeader3}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="buro-credito">
                                        <Card.Body>
                                            <ul>
                                                {
                                                    validRoles(PERFIL.roles, localStorageClass.BURO) ? (
                                                        <li onClick={() => {
                                                            history.push('/buro-credito')
                                                            close()
                                                        }}>
                                                            {languageState.language?.navBar.titulo6!}
                                                        </li>

                                                    ) : null}

                                                {
                                                    validRoles(PERFIL.roles, localStorageClass.BURO) ? (
                                                        <li onClick={() => {
                                                            history.push('/buro-credito/Configuracion-cuenta')
                                                            close()
                                                        }}>
                                                            {languageState.language?.navBar.titulo8!}
                                                        </li>

                                                    ) : null}

                                                {
                                                    validRoles(PERFIL.roles, localStorageClass.BURO) ? (
                                                        <AccessBuro close={function (): void {
                                                        }} />

                                                    ) : null}



                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                            </> : null
                        }
                        {(environment.env != "prod"
                            && validRoles(PERFIL.roles, localStorageClass.AGENTE)
                            //  && validRoles(PERFIL.roles, localStorageClass.USUARIOCONTABLE)
                        ) ?
                            <>

                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="buro-credito-agent">
                                        {languageState.language?.navBar.panelHeader3}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="buro-credito-agent">
                                        <Card.Body>
                                            <ul>
                                                {(environment.env != "prod" &&
                                                    validRoles(PERFIL.roles, localStorageClass.AGENTE)) ? (
                                                    <li onClick={() => {
                                                        history.push('/buro-credito/Solicitudes')
                                                        close()
                                                    }}>
                                                        {languageState.language?.navBar.titulo6!}
                                                    </li>) : null}
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </> : null
                        }
                    </Accordion>

                    <Accordion defaultActiveKey={menuSeccion(history.location.pathname)}>
                        {(
                            validRoles(PERFIL.roles, localStorageClass.FUNCIONARIO) ||
                            validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA) ||
                            validRoles(PERFIL.roles, localStorageClass.AGENTE) ||
                            validRoles(PERFIL.roles, localStorageClass.PROMOTOR) ||
                            validRoles(PERFIL.roles, localStorageClass.BURO) ||
                            validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS)

                        ) ? <>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="compromisos">
                                    {languageState.language?.navBar.panelHeader2}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="compromisos">
                                    <Card.Body>
                                        <ul> {
                                            validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS) ||
                                                validRoles(PERFIL.roles, localStorageClass.FUNCIONARIO) ||
                                                validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA) ||
                                                validRoles(PERFIL.roles, localStorageClass.AGENTE) ||
                                                validRoles(PERFIL.roles, localStorageClass.PROMOTOR) ||
                                                validRoles(PERFIL.roles, localStorageClass.BURO) ? (

                                                <li onClick={() => {
                                                    history.push('/compromisos')
                                                    close()
                                                }}>
                                                    {languageState.language?.navBar.titulo1}
                                                </li>) : null}
                                            {

                                                validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS) ||
                                                    validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA) ? (
                                                    <li onClick={() => {
                                                        history.push('/compromisos/cumplimientos')
                                                        close()
                                                    }}>
                                                        {languageState.language?.navBar.titulo3}
                                                    </li>) : null}
                                            {
                                                validRoles(PERFIL.roles, localStorageClass.FUNCIONARIO) ||
                                                    validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA) ||
                                                    validRoles(PERFIL.roles, localStorageClass.BURO) ? (
                                                    <li onClick={() => {
                                                        history.push('/compromisos/autorizaciones')
                                                        close()
                                                    }}>
                                                        {languageState.language?.navBar.titulo2}
                                                    </li>) : null}

                                        </ul>

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </> : null}
                    </Accordion>

                    <Accordion defaultActiveKey={menuSeccion(history.location.pathname)}>

                        {(
                            validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS)
                            || validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA)
                            || validRoles(PERFIL.roles, localStorageClass.AGENTE)
                            || validRoles(PERFIL.roles, localStorageClass.PROMOTOR)
                            || validRoles(PERFIL.roles, localStorageClass.AUDITOR)
                        ) ?
                            <>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="garantias">
                                        {languageState.language?.navBar.panelHeader1}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="garantias">
                                        <Card.Body>
                                            <ul>
                                                {(validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS)
                                                    //  ||  validRoles(PERFIL.roles, localStorageClass.AGENTE)
                                                    || validRoles(PERFIL.roles, localStorageClass.PROMOTOR)
                                                    || validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA)
                                                    || validRoles(PERFIL.roles, localStorageClass.AUDITOR)) ? (
                                                    <li onClick={() => {
                                                        history.push('/garantias/')
                                                        close()
                                                    }}>
                                                        {languageState.language?.navBar.titulo4!}
                                                    </li>
                                                ) : null}


                                                {validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS) ||
                                                    (validRoles(PERFIL.roles, localStorageClass.AUDITOR) &&
                                                        !validRoles(PERFIL.roles, localStorageClass.AGENTE) &&
                                                        !validRoles(PERFIL.roles, localStorageClass.PROMOTOR)
                                                    )

                                                    ? (
                                                        <li onClick={() => {
                                                            history.push('/garantias/revision/')
                                                            close()
                                                        }}>
                                                            {languageState.language?.navBar.titulo5!}
                                                        </li>) : null}
                                                {(environment.env == "dev" &&
                                                    validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS) ||
                                                    // validRoles(PERFIL.roles, localStorageClass.AGENTE) ||
                                                    validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA) ||
                                                    validRoles(PERFIL.roles, localStorageClass.AUDITOR)
                                                ) ? (
                                                    <li onClick={() => {
                                                        history.push('/reporte-garantias')
                                                        close()
                                                    }}>
                                                        {languageState.language?.navBar.titulo11!}
                                                    </li>) : null}



                                                {(validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS) ||
                                                    (validRoles(PERFIL.roles, localStorageClass.AUDITOR) && !validRoles(PERFIL.roles, localStorageClass.AGENTE)) &&
                                                    (validRoles(PERFIL.roles, localStorageClass.AUDITOR) && !validRoles(PERFIL.roles, localStorageClass.PROMOTOR))

                                                ) ?
                                                    <li onClick={() => {
                                                        history.push('/garantias/reporte-expediente/')
                                                        close()
                                                    }}>
                                                        {languageState.language?.navBar.titulo12!}
                                                    </li>
                                                    : null
                                                }



                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                            </> : null
                        }

                    </Accordion>

                    <Accordion defaultActiveKey={menuSeccion(history.location.pathname)}>
                        {(environment.env != "prod"
                            //  && validRoles(PERFIL.roles, localStorageClass.USUARIOCONTABLE)
                        ) ?
                            <>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="polizasrh">
                                        {languageState.language?.navBar.panelHeader4}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="polizasrh">
                                        <Card.Body>
                                            <ul>
                                                <li onClick={() => {
                                                    history.push('/rh/polizas')
                                                    close()
                                                }}>
                                                    {languageState.language?.navBar.titulo7!}
                                                </li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </> : null
                        }
                    </Accordion>
                    <Accordion defaultActiveKey={menuSeccion(history.location.pathname)}>
                        {(
                            validRoles(PERFIL.roles, localStorageClass.CAJERO)
                            //  && validRoles(PERFIL.roles, localStorageClass.USUARIOCONTABLE)
                        ) ?
                            <>

                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="tesoreria">
                                        languageState.language?.navBar.panelHeader4Tesoreria
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="tesoreria">
                                        <Card.Body>
                                            <ul>
                                                {(
                                                    validRoles(PERFIL.roles, localStorageClass.CAJERO)) ? (
                                                    <li onClick={() => {
                                                        history.push('/tesoreria')
                                                        close()
                                                    }}>
                                                        Recibo De Caja
                                                    </li>) : null}
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </> : null
                        }
                    </Accordion>

                    <Accordion defaultActiveKey={menuSeccion(history.location.pathname)}>
                        {(
                            validRoles(PERFIL.roles, localStorageClass.AUXILIAR_JURIDICO)
                            && environment.env != "prod"
                        ) ?
                            <>

                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="reclamacion">
                                        languageState.language?.navBar.panelHeader4Reclamaciones
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="reclamacion">
                                        <Card.Body>
                                            <ul>
                                                {(environment.env != "prod" &&
                                                    validRoles(PERFIL.roles, localStorageClass.AUXILIAR_JURIDICO)) ? (
                                                    <li onClick={() => {
                                                        history.push('/bajas-reclamaciones')
                                                        close()
                                                    }}>
                                                        Bajas de reclamaciones
                                                    </li>) : null}
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </> : null
                        }
                    </Accordion>


                </div>

                <div id="coverNav" onClick={close} className="background-nav"></div>

               
                <Modal
                    isOpen={openModalClose}
                    size="small"
                    modalStyle="standard"
                    title={languageState.language?.navBar.cerrarSesion}
                    closeFunc={() => setOpenModalClose(false)}>
                    <p>{languageState.language?.navBar.leyenda} </p>
                    <GridRow gutterSize="fixed-16" gutters>
                        <GridCol base={12} md={6}>
                            <Button dynamicWidth onClick={() => setOpenModalClose(false)}>
                                {languageState.language?.navBar.cancelar}
                            </Button>
                        </GridCol>
                        <GridCol base={12} md={6}>
                            <Button onClick={closeSession} dynamicWidth variant="primary">
                                {languageState.language?.navBar.aceptar}
                            </Button>
                        </GridCol>
                    </GridRow>
                </Modal>

                <Modal
                    isOpen={openModalVideo}
                    size="large"
                    modalStyle="standard"
                    className="modal-sesion"
                    //title={`VIDEO`}
                    closeFunc={() => setOpenModalVideo(false)}>
                    <div id='video'>
                        <div className="embed-responsive embed-responsive-16by9">
                            <video className="embed-responsive-item" controls >
                                <source src={URLVIDEO + "Compromisos.mp4"} type="video/mp4" />
                            </video>
                        </div>
                    </div>


                </Modal>

                <Modal isOpen={isOpen}
                    mandatory={true}
                    className="modal-sesion"
                    size="medium">
                    <ModalBody>
                        <Heading tag="span" type="h3-light" className="text-center">
                            {languageState.language?.navBar.sessionExpired}
                        </Heading><br /><br />
                        <h5 className="text-center">
                            {languageState.language?.navBar.askContinue}<br />
                        </h5>
                        <h5 className="text-center">
                            {languageState.language?.navBar.pleaseLogin}
                        </h5>
                        <img
                            src={womanImg}
                            className="img-fluid mx-auto d-block "
                            alt="Logo Liberty Fianzas"
                        />

                    </ModalBody>
                    <ModalFooter>

                        <div className="col">

                        </div>
                        <div className="col">
                            <Button
                                className="w-100"
                                variant="primary" onClick={() => { closeSession() }}>
                                Aceptar
                            </Button>
                        </div>
                        <div className="col">

                        </div>


                    </ModalFooter>
                </Modal>

            </div>
        </>


    );
};

export default NavBarOld;