import React, { Component} from "react";
import {RouteComponentProps} from "react-router-dom";
import {
    AlphanumericInput,
    Badge,
    Button,
    IconLockClosed,
    IconLockOpen,
    IconSearch,
} from "@lmig/lmds-react";
import {Card} from "react-bootstrap";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import './VentanaMantenimiento.scss';
import 'react-circular-progressbar/dist/styles.css';
import {RootStore} from "../../../Store";
import {connect} from "react-redux";
import {DefaultStateI} from "../../../reducer/LanguageReducer";
import {formatCurrency, initPaginacion, InputChange} from "../../../class/CommonClass";
import {AxiosError} from "axios";
import * as supportService from "../../../services/support/SupportService";
import {PaginacionModel} from "../../../models/public/PaginacionModel";
import {FiadoModel} from "../../../models/garantias/FiadoModel";
import {NegativeAlert} from "../../../components/public/negative-alert/NegativeAlert";
import {SolvenciaStateEnum} from "../../../models/garantias/SolvenciaStateEnum";
import {SpinnerLoading} from "../../../components/public/spinner-loading/SpinnerLoading";
import SelectLanguage from "../../../components/public/select-language/SelectLanguage";
import {GarantiasMantenimiento} from "../../../components/support/garantias-mantenimiento/GarantiasMantenimiento";
import {ExpedienteMantenimiento} from "../../../components/support/expediente-mantenimiento/ExpedienteMantenimiento";

interface VentanaMantenimientoProps {
    language: DefaultStateI
}

class VentanaMantenimiento extends Component<VentanaMantenimientoProps & RouteComponentProps , VentanaMantenimientoState>{

    public localStorageClass = new LocalStorageClass();

    constructor(props: any) {
        super(props);
        document.title = "Mantenimiento"

        this.state = {
            listFiados: [],
            paginacion: initPaginacion,
            loadingFiados: false,
            errorFiados: false,
            search: '',
            disabledSearch: false,
            searching: true,
            fiado: null
        }


        this.handleScroll = this.handleScroll.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onSelectFiado = this.onSelectFiado.bind(this);
    }

    render() {

        return <div onScroll={this.handleScroll}>

            <div className="container-fluid " id="ventana-mantenimiento">
                <div className="row" id="search">
                    <div className="col-md-4 col-lg-6 h3 ">
                        Mantenimiento
                        <div className="d-none">
                            <SelectLanguage />
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                        <AlphanumericInput
                            className="float-right w-100"
                            labelVisual="Buscar Fiados"
                            name="search"
                            autoComplete="off"
                            autoFocus={true}
                            onChange={this.handleInputChange}
                            onKeyDown={this.onKeyDown}
                            disabled={this.state.loadingFiados}
                            value={this.state.search}
                        />
                    </div>

                    <div className="col-md-4 col-lg-2">
                        <Button  variant="primary"
                                 className="float-right w-100"
                                 id="btn-search-principals"
                                 disabled={this.state.loadingFiados}
                                 onClick={()=> {this.serchPrincipals()}}>
                            <IconSearch size="16" />&nbsp; Buscar
                        </Button>
                    </div>
                </div>

                {(this.state.searching)?
                    <div className="mantenimiento-container list-fiados">

                        {(this.state.errorFiados)? (
                                <div className="col-12">
                                    <NegativeAlert
                                        textAlert={this.props.language.language?.warranties.errorFiados!}/>
                                </div>):
                            <div className="col-12">
                                {this.state.listFiados.map((i,index) =>
                                    (
                                        <Card key={index}
                                              onClick={()=>{
                                                  this.onSelectFiado(index)
                                              }}
                                              border={
                                            `${!i.cliente.estadoBloqueoCliente && i.cliente.estado==SolvenciaStateEnum.conSolvencia ? "success" : ""}` +
                                            `${!i.cliente.estadoBloqueoCliente && i.cliente.estado==SolvenciaStateEnum.sinSolvencia ? "danger" : ""}` +
                                            `${!i.cliente.estadoBloqueoCliente && i.cliente.estado==SolvenciaStateEnum.porVencer ? "warning" : ""}`+
                                            `${i.cliente.estadoBloqueoCliente ? "danger" : ""}`
                                        } >
                                            <Card.Header className="text-right ">

                                                {(i.cliente.estado==SolvenciaStateEnum.conSolvencia)?(<span className="badge badge-pill badge-success">{this.props.language.language?.warranties.withSolv!}</span>):null}
                                                {(i.cliente.estado==SolvenciaStateEnum.sinSolvencia)?(<span className="badge badge-pill badge-danger">{this.props.language.language?.warranties.withOutSolv!} </span>):null}
                                                {(i.cliente.estado==SolvenciaStateEnum.porVencer)?(<span className="badge badge-pill badge-success">{this.props.language.language?.warranties.toExpire!} </span>):null}

                                            </Card.Header>
                                            <Card.Body >
                                                <Card.Text className="item-warranty">
                                                    <div className="row">
                                                        <div className="col-md-2 icon-warranty">

                                                            {(!i.cliente.estadoBloqueoCliente)?(

                                                                    <>
                                                                        <IconLockOpen
                                                                            size="48"
                                                                            color="positive"
                                                                            className="d-block mx-auto lock-open"
                                                                            title={this.props.language.language?.warranties.lockPrincipal! +
                                                                                ' ' + i.cliente.razonSocial}

                                                                        />


                                                                    </>

                                                                ):
                                                                <>
                                                                    <IconLockClosed
                                                                        color="error"
                                                                        size="48"
                                                                        className="d-block mx-auto"
                                                                        title={this.props.language.language?.warranties.lockedPrincipal} />

                                                                </>
                                                            }



                                                        </div>
                                                        <div className="col-md-10">

                                                            <div className="row">
                                                                <div className="col-md-8">
                                                                    <h4>
                                                                        {i.cliente.razonSocial}
                                                                    </h4>
                                                                    <h4 className="font-weight-bold">
                                                                        {i.cliente.rfc}
                                                                    </h4>
                                                                    <br/>
                                                                </div>
                                                                <div className="col-md-4"/>

                                                                <div className="col-md-12 elementos">
                                                                    <div className="row">
                                                                        {i.elementos.map((j,index2) =>
                                                                            (   <div key={index2} className="col-md-4">
                                                                                    {j.descripcion} <br/>
                                                                                    <span className="amount">{formatCurrency(j.monto)}</span>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 estatus">
                                                                    <div className="row">
                                                                        {i.estatus.map((j,index2) =>
                                                                            (     <div key={index2} className="col-md-3">
                                                                                    {j.idEstado == 2 ? (
                                                                                        <Badge className="float-left" highlightType="informative">
                                                                                            {j.numGarantia + ' ' + j.estado }
                                                                                        </Badge>
                                                                                    ): null}
                                                                                    {j.idEstado == 3 ? (
                                                                                        <Badge className="float-left" highlightType="positive">
                                                                                            {j.numGarantia + ' ' + j.estado }
                                                                                        </Badge>
                                                                                    ): null}
                                                                                    {j.idEstado == 6 ? (
                                                                                        <Badge className="float-left" highlightType="caution">
                                                                                            {j.numGarantia + ' ' + j.estado }
                                                                                        </Badge>
                                                                                    ): null}

                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 see-details" >

                                                                    <div className="name-agent float-right">{i.cliente.propietario}</div>


                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    ))
                                }
                                {(this.state.listFiados.length == 0 && !this.state.loadingFiados) ? (
                                    <div className="col-12">
                                        <br/><br/>
                                        <h3>
                                            Hola bienvenid@s<br/>
                                        </h3>
                                        <h5>
                                            {this.props.language.language?.warranties.noResults!}
                                        </h5>
                                        <br/><br/><br/>
                                    </div>
                                ): null}
                            </div>
                        }
                        {this.state.loadingFiados ? (
                            <div className="col-12">
                                <SpinnerLoading text={this.props.language.language?.warranties.loadingFiados!}/>
                            </div>): null
                        }

                    </div> :
                    <div className="mantenimiento-container principal-selected">

                        <div className="row">
                            <div className="col-md-12">
                                <h4>Fiado: {this.state.fiado?.cliente.razonSocial}</h4>
                                <h5>RFC: {this.state.fiado?.cliente.rfc}</h5>
                            </div>
                            <div className="col-md-5">
                                <b>Expediente de Identificación</b>
                                <ExpedienteMantenimiento idPrincipal={this.state.fiado?.cliente.idCliente!} />
                            </div>

                            <div className="col-md-7">
                                <b>Garantías del Fiado</b>
                                <GarantiasMantenimiento
                                    idPrincipal={this.state.fiado?.cliente.idCliente!}/>


                            </div>

                        </div>
                    </div>
                }






            </div>

        </div>
    }

    componentDidMount(){
        window.addEventListener("scroll", this.handleScroll);
      //  this.getClients(initPaginacion,'', true);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    public onKeyDown (event: React.KeyboardEvent<HTMLDivElement>){
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.serchPrincipals()
        }
    }

    public async getClients (paginacion: PaginacionModel,
                             search: string,
                             initFiados: boolean) {
        // cargando fiados
        if (initFiados){
            this.setState({listFiados:[], loadingFiados:true, searching: true});
        }else {
            this.setState({loadingFiados:true, searching: true});

        }

        console.log('search' + search);
        if (search == ''){search = 'all'}
        try {
            const res = await supportService.getClientesBusqueda(paginacion,search);
            //  console.log(res.data);
            const response: {paginacion: PaginacionModel, detalle: FiadoModel[]} = res.data;
            response.paginacion.size = paginacion.size;
            // agregar los nuevos registros sin borrar los anteriores
            const auxListFiados = this.state.listFiados;

            for (const fiado of response.detalle){
                auxListFiados.push(fiado);
            }
            console.log(this.state.search);
            // validar que lo que estamos buscando sea lo que estamos seteando
            if (this.state.search == search || search == 'all' ){
                this.setState({
                    loadingFiados:false,
                    paginacion:response.paginacion,
                    listFiados:auxListFiados});
            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                this.setState({loadingFiados:false, searching: false, errorFiados:true});

            }
        }
    }

    public loadInfiniteScroll(bottom: boolean){
        if (bottom){
            console.log('bottom reached')
            //hacer las operaciones de la paginación
            const  auxPaginacion = this.state.paginacion;
            const nextPage = (auxPaginacion.currentPage+1);
            console.log('paginacion actual ' + auxPaginacion.currentPage);
            console.log('paginacion siguiente ' + nextPage);
            if (nextPage <= auxPaginacion.totalPages){
                if (!this.state.loadingFiados && this.state.searching){
                    auxPaginacion.currentPage = nextPage
                    this.getClients( auxPaginacion, this.state.search, false);
                }

            }

        }else {
            // console.log('not at bottom')
        }

    }

    public handleScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        //  console.log('windowBottom ' + windowBottom + ' >= docHeight: ' + docHeight);
        if ( Math.round(windowBottom) >= docHeight-1) {
            //    console.log('bottom reached')
            this.loadInfiniteScroll(true)
        } else {
            this.loadInfiniteScroll(false)
        }
    }

    public handleInputChange (e: InputChange): void{
        const value = e.target.value;
        // console.log(e.target.value);
        if (e.target.name == 'search'){
            this.setState({search:value}) // iniciar los valores
        }
    }

    private serchPrincipals() {
        // incializar la paginación si se va a buscar
        this.setState({paginacion: initPaginacion})
        this.getClients(initPaginacion, this.state.search, true);

    }

    public onSelectFiado(index: number){
        this.setState({
            fiado:this.state.listFiados[index],
            searching: false,
            search: '',
        })
    }




}

const mapStateToProps = (state: RootStore)  => ({
    language: state.language
});


export default connect(mapStateToProps) (VentanaMantenimiento);

interface VentanaMantenimientoState {
    listFiados: FiadoModel[];
    paginacion: PaginacionModel;
    loadingFiados:  boolean;
    errorFiados: boolean;
    search: string;
    disabledSearch: boolean;
    searching: boolean;
    fiado: FiadoModel | null;
}




