
export interface ResumeGarantiasLanguageModel {
    show: string;
    showing: string;
    to: string;
    of: string;
    entries: string;
    registers: string;
    loadingResume: string;
    errorResume: string;
    rol: string;
    rolFiado: string;
    fiadoBloqueado: string
    rolOs: string;
    osBloqueado: string
    nameResume: string;
    garantias: string;
    totalAfianzado: string;
    montoAfianzadoCubierto: string;
    montoAfianzadoPendientes: string;
    montoAfianzadoCubiertoTerceros: string;
    garantiasDisponibles: string;
    acciones: string;
    total: string;
    fileReporteCumulo: string;
    downloadReportecumulo: string;
    errDownloadinReporte: string;
}

export const ResumeGarantiasLanguageEnglish: ResumeGarantiasLanguageModel = {
    show: 'Show',
    showing: 'Showing',
    to: 'to',
    of :'of',
    entries: 'entries',
    registers: 'entries',
    loadingResume: 'Loading Resume',
    errorResume: 'Sorry something happened while loading Resume, please check your connection and try again.',
    rol: 'Role',
    rolFiado: 'Principal',
    fiadoBloqueado: 'Principal Locked',
    rolOs: 'Obligor Solidarity',
    osBloqueado: 'Obligor Locked',
    nameResume:  'Name',
    garantias: 'Weighted Loans',
    totalAfianzado:  'Total secured',
    montoAfianzadoCubierto:  'Total Secured Covered',
    montoAfianzadoPendientes: 'Total Secured Outstanding',
    montoAfianzadoCubiertoTerceros: 'Total Secured Third Parties',
    garantiasDisponibles:  'Loans Available',
    acciones: 'Actions',
    total: 'Total',
    fileReporteCumulo: 'Accumulation of Responsibilities Report',
    downloadReportecumulo: 'Download Accumulation of Responsibilities Report',
    errDownloadinReporte: 'Sorry, something happened while downloading the Report'

}
export const ResumeGarantiasLanguageSpanish: ResumeGarantiasLanguageModel ={
    showing: 'Mostrando registros del',
    to: 'al',
    of: 'de un total de',
    entries: 'registros',
    show: 'Mostrar',
    registers: 'Registros',
    loadingResume: 'Cargando Resumen',
    errorResume: 'Lo sentimos algo ha ocurrido al cargar el Resumen, revise su conexión e intente de nuevo',
    rol: 'Rol',
    rolFiado: 'Fiado',
    fiadoBloqueado: 'Fiado Bloqueado',
    rolOs: 'Obligado Solidario',
    osBloqueado: 'Obligado Solidario Bloqueado',
    nameResume:  'Nombre',
    garantias:  'Garantías Ponderadas',
    totalAfianzado:  'Total Afianzado',
    montoAfianzadoCubierto: 'Total Afianzado Cubierto',
    montoAfianzadoPendientes: 'Total Afianzado Pendientes',
    montoAfianzadoCubiertoTerceros: 'Total Afianzado Cubierto Terceros',
    garantiasDisponibles:  'Garantías Disponibles',
    acciones: 'Acciones',
    total: 'Total',
    fileReporteCumulo: 'Reporte de Cúmulo de Responsabilidades',
    downloadReportecumulo: 'Descargar Reporte de Cúmulo de Responsabilidades',
    errDownloadinReporte: 'Lo sentimos, ocurrió un error al descargar el Reporte '

}