import { Component } from 'react';
import { Cliente } from '../../../models/compromisos/Cliente';
import './InfoEngagement.scss';
import * as engagementService from '../../../services/compromisos/EngagementsService';
import { RouteComponentProps } from 'react-router-dom';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import { UserModel } from '../../../models/public/UserModel';
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { RootStore } from "../../../Store";
import { connect } from "react-redux";



interface InfoEngagementsProps {

    id: number;
    language: DefaultStateI
}



interface SteepsModel {

    cliente: Cliente,
    PERFIL: UserModel;

}

class InfoEngagements extends Component<InfoEngagementsProps, SteepsModel> {

    public idFiado = this.props.id;
    public localStorageClass = new LocalStorageClass();

    constructor(props: any) {
        super(props);

        const PERFIL = this.localStorageClass.getProfile();

        this.state = {
            PERFIL: PERFIL,
            cliente: {
                idCliente: 0,
                razonSocial: '',
                rfc: '',
                agente: '',
                idAgente: 0
            }

        }

    }

    componentDidMount() {

        this.getClientInfo();

    }

    render() {

        return <>
            <div className="col-md-12" id="info-engagements">

                <br />
                    <h3 className='infoPequeño'> {this.state.cliente.razonSocial} </h3>
                    <h6 className='infoPequeño'> {this.state.cliente.rfc} </h6>
                    <h6 className='infoPequeño'>{this.props.language.language?.findClient.agente}: {this.state.cliente.agente}</h6>        
            </div>
        </>
    }


    async getClientInfo(): Promise<void> {
  
        const res = await engagementService.getClient(this.idFiado, 0);
        this.setState({ cliente: res.data });
    }

}

const mapStateToProps = (state: RootStore) => ({
    language: state.language
});

export default connect(mapStateToProps)(InfoEngagements);





