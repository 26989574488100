import React, {Fragment, useEffect} from "react";
import {Spinner} from "react-bootstrap";
import './SpinnerLoading.scss';
interface Props {
    text?:string;
}
export const SpinnerLoading: React.FC<Props> = ({text}) => {

    return <>
        <div id="spinner-loading" >
            <br/>
            {text?(
                <>
                    <br/>
                    <h5 className="text-muted">{text}</h5><br/>
                </>
            ): null}

            <Spinner animation="border"
                     role="status"
                     variant="warning">

            </Spinner>
            <br/><br/>
        </div>

    </>;
};

