import React, { useEffect, useState} from 'react';
import './ExpedienteSelect.scss';
import { Select, SelectOption,
} from "@lmig/lmds-react";
import { useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {AxiosError} from "axios";
import { InputChange} from "../../../class/CommonClass";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {ExpedienteCatalogo} from "../../../models/garantias/ExpedienteCatalogo";
import {elementosSubCatalogo, ExpedienteSubCatalogo} from "../../../models/garantias/ExpedienteSubCatalogo";
import {GarantiaModel} from "../../../models/garantias/GarantiaModel";
import {initialGarantiaState} from "../create-warranty/CreateWarranty";


interface ExpedienteSelectProps {
    idCatalogo: number | null;
    idSubCatalogo: number | null;
    value: string | null;
    disabled: boolean;
    idGarantia: number;
    onValueChange?: (catalogo: number, subCatalogo: number, value?: number ) => void;
    /*{
    // para dos selects o dos niveles
        "idDetalleDoc": null,
        "inputType": "Select",
        "idCatalogo": 21, // se queda en 21
        "idSubCatalogo": 3, // ratings
        "descripcion": "Calificadora",
        "valor": 17 // valor
    },
    // para el caso solo un nivel
    {
        "idDetalleDoc": null,
        "inputType": "Select",
        "idCatalogo": 21, // se queda en 21
        "idSubCatalogo": 3, // Am best
        "descripcion": "Calificadora",
        "valor": null // valor
    }*/
}

export const ExpedienteSelect: React.FC<ExpedienteSelectProps> = (
    {idCatalogo,
        idSubCatalogo,
        value,
        disabled,
        idGarantia,
        onValueChange
    }:ExpedienteSelectProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    const initSubCatalogo = {
        nombre: '',
        elementos: []
    }

    const [listCatalogoExpediente, setListCatalogoExpediente] = useState<ExpedienteCatalogo[]>([]);

    const [listSubCatalogo, setListSubCatalogo] = useState<ExpedienteSubCatalogo>(initSubCatalogo);

    const [subCatalogoSelect, setSubCatalogoSelect] = useState<number>( 0);

    const [valueSelect, setValueSelect] = useState<string>('');

    const [porcentajePonderacion, setPorcentajePonderacion] = useState<number>(0)

    const [garantia, setGarantia] = useState<GarantiaModel>(initialGarantiaState);

    useEffect(() => {
        if (idCatalogo != null){
            setSubCatalogoSelect(0);
            getCatalogoExpedient(idCatalogo)
        }

        if (idGarantia != 0){
            console.log('traer el detalle de la garantía: ' + idGarantia);
            getWarrantyDetail(idGarantia);
        }


    },[idCatalogo, idGarantia]);


    // traer el detalle de una garantia
    const getWarrantyDetail = async (idWarranty: number) => {

        try {
            const res = await garantiasService.getGarantiaDetail(idWarranty);
            const auxGarantiaObject: GarantiaModel = res.data;
            setGarantia(auxGarantiaObject);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    const getCatalogoExpedient = async (idCatalogo: number) => {

        try {
            const res = await garantiasService.getCatalogoExpediente(idCatalogo);
            const auxListCatalogo = res.data;
            setListCatalogoExpediente(auxListCatalogo);
            if (value != null){ // si no he seleccionado una calificación  buscar el valor seleccionado en el catalogo
                // que al principio aprezca selecciona un a calificación

                for (let  i = 0; i< auxListCatalogo!.length; i++){
                    if (idSubCatalogo! == auxListCatalogo![i].idEntrada) {
                      //  si tiene elementos el subcatalogo
                        if (auxListCatalogo![i].subCatalogo.elementos.length > 0) {
                            setSubCatalogoSelect(auxListCatalogo![i].idEntrada);
                            const subCatalogo = auxListCatalogo![i].subCatalogo
                            setListSubCatalogo(subCatalogo);
                            for (const sub of subCatalogo.elementos){
                                if (parseInt(value) == sub.idEntrada){
                                    setValueSelect(String(sub.idEntrada));
                                }
                            }


                            break;
                        }
                    }
                }
            }


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }

    }

    const selectCatalogo = (e: InputChange) => {
        const idCatalogo = e.target.value; // subcatalogo
        const auxListCatalogo: ExpedienteCatalogo[] = [...listCatalogoExpediente];

        // vamos a buscar la entrada en los catalogos
        for (let  i = 0; i< auxListCatalogo!.length; i++) {
            if (idCatalogo == auxListCatalogo![i].idEntrada) {
                // si tiene elementos el subcatalogo
                if (auxListCatalogo![i].subCatalogo.elementos.length > 0) {
                    setListSubCatalogo(auxListCatalogo![i].subCatalogo)

                    setSubCatalogoSelect(idCatalogo)
                    onValueChange!(idCatalogo!,idCatalogo);
                    break;
                }
            }
        }
    }

    const selectValue = (e: InputChange) => {
        const value = e.target.value;
        if (value){
            setValueSelect(value);

            const auxlistCatalogoExpediente: ExpedienteCatalogo[] = [...listCatalogoExpediente];

            const foundSubCatalogo = auxlistCatalogoExpediente.find((expedienteCatalogo) => expedienteCatalogo.idEntrada == subCatalogoSelect);

            const foundValue = foundSubCatalogo!.subCatalogo.elementos.find((elemento) => elemento.idEntrada == value);

            let ponderacionAux = 0;


            console.log(foundValue)

            if (foundValue){
                ponderacionAux = foundValue.ponderacion;
                console.log(foundValue)
            }



            if (ponderacionAux == 0){
                onValueChange!(idCatalogo!,subCatalogoSelect,value);
            }else {
                setPorcentajePonderacion(ponderacionAux);
                onValueChange!(idCatalogo!,subCatalogoSelect,value);
                const garantiaAux = garantia;
                console.log('garantia monto ' + garantiaAux.monto+ ' * ' + ponderacionAux);

                const montoPonderadoAux = garantia.monto * ponderacionAux;
                garantiaAux.porcentajePonderacion = ponderacionAux;
                garantiaAux.montoPonderado = montoPonderadoAux;
                updateWarranty(garantiaAux);
            }
        }else {
            setPorcentajePonderacion(0);
        }

    }

    const updateWarranty = async (garantia: GarantiaModel) => {
        try {
            const res = await garantiasService.putGarantia(garantia, false);
            setGarantia(res.data)

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    return (
        <>
            <div id="expedient-select">

                <Select
                    name="catalogo"
                    isRequired
                    labelA11y={languageState.language?.warranty.selectOption!}
                    labelVisual={languageState.language?.warranty.selectOption!}
                    onChange={selectCatalogo}
                    value={subCatalogoSelect.toString()}
                    disabled={disabled}>

                    {
                        listCatalogoExpediente.map((expediente, indexExpe) =>
                            (
                                    <SelectOption
                                        value={expediente.idEntrada!.toString()}
                                        key={indexExpe}>
                                        {expediente.informacion.map((infoExpe, indexinfoExpe)=>
                                            (infoExpe.descripcion + ' ')
                                        )
                                        }
                                    </SelectOption>

                            )
                        )
                    }
                </Select>


                {
                    // select de subcatalogo
                    (listSubCatalogo.elementos.length > 0 &&
                        <Select
                            id=""
                            isRequired
                            labelA11y={listSubCatalogo.nombre}
                            labelVisual={listSubCatalogo.nombre}
                            value={String(valueSelect)}
                            disabled={disabled}
                            onChange={selectValue}
                            placeholderOption={listSubCatalogo.nombre}
                        >
                            {
                                listSubCatalogo.elementos.map((iSubCatalogo, indexSub) =>
                                    (
                                        <SelectOption value={iSubCatalogo.idEntrada!.toString()} key={indexSub}>

                                            {iSubCatalogo.informacion.map((infoExpe, indexinfoExpe)=>
                                                ( infoExpe.descripcion + ' ')
                                            )
                                            }
                                        </SelectOption>
                                    )
                                )
                            }
                        </Select>
                    )
                }

                {(catalogoCalificacion(idCatalogo!) && valueSelect  )?(

                        <div className="row">

                            <div className="col-md-12">
                                <label className="label-input">{languageState.language?.warranty.weightedAmount!}:</label>
                                <input type="text"
                                       className="input-sdgf"
                                       name="montoPonderado"
                                       value={garantia.montoPonderado}
                                       disabled={true}/>

                            </div>
                        </div>
                ) : null
                }

            </div>
        </>
    );
};

export const catalogoCalificacion = (idCatalogo: number) => {

    if (idCatalogo ==  21 || idCatalogo == 29){
        return true;
    }
    return false;

}
