

export interface StatusRowPrincipalLanguage {
    createWarranty: string;
    addOs: string;
    interview:string;
    expedientIdentification:string;
    lockClient:string;
    lockOs:string;
    unlockCliente: string;
    unlockOs: string;
    lockComission: string;
    unlockComission: string;
    deleteOs: string;
}

export const StatusRowPrincipalLanguageEnglish: StatusRowPrincipalLanguage = {
    createWarranty: 'Create Collateral',
    addOs: 'Add Obligor',
    interview: 'Interview',
    expedientIdentification: 'Identification File',
    lockClient: 'Lock Principal',
    lockOs: 'Lock O.S',
    unlockCliente: 'Unlock Principal',
    unlockOs: 'Unlock O.S.',
    lockComission: 'Block Commission',
    unlockComission: 'Unlock commission',
    deleteOs: 'Delete Os'
}
export const StatusRowPrincipalLanguageSpanish: StatusRowPrincipalLanguage = {
    createWarranty: 'Crear Garantía',
    addOs: 'Agregar OS',
    interview: 'Entrevista',
    expedientIdentification: 'Expediente de Identificación',
    lockClient: 'Bloquear Fiado',
    lockOs: 'Bloquear O.S',
    unlockCliente: 'Desbloquear Fiado',
    unlockOs: 'Desbloquear Obligado Solidario',
    lockComission: 'Bloquear Comisión',
    unlockComission: 'Desbloquear Comisión',
    deleteOs: 'Eliminar O.S'
}
