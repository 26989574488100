export interface EngagmentsLanguageModel {
    
    tituloFuncionario:string
    titulo: string
    labelToogle: string
    searchAgent: string;
    seleccionaAgente: string
    seleccionaEjecutivo: string
    apoyo:string
    bloqueo:string
    opcionFiltrar: string
    opcionFiltrar1: string
    opcionFiltrar2: string
    quitarFiltro: string
    titulo2: string
    titulo3: string
    titulo4: string
    buscar: string
    
}

export const EngagmentsLanguageEnglish: EngagmentsLanguageModel ={
    
    
    titulo: 'Compromises',
    tituloFuncionario: 'Compromises Management',
    labelToogle: 'Direct Client',
    searchAgent: 'Search Agent',
    seleccionaAgente: 'Search an Agent or Broker',
    seleccionaEjecutivo: 'Select an Executive',
    apoyo: 'Help',
    bloqueo: 'Block',
    opcionFiltrar: 'Filter', 
    opcionFiltrar1: 'Filter by Help',
    opcionFiltrar2: 'Filter by Block',
    quitarFiltro: 'Quit',
    titulo2: 'Expired Compromises',
    titulo3: 'Compromises Near To Expire',
    titulo4: 'To Authorize',
    buscar: 'Search',

   
}


export const EngagmentsLanguageSpanish: EngagmentsLanguageModel ={
    
    
    titulo: 'Compromisos',
    tituloFuncionario: 'Administración de Compromisos',
    labelToogle: 'Cliente Directo',
    searchAgent: 'Buscar Agente',
    seleccionaAgente: 'Buscar un Agente',
    seleccionaEjecutivo: 'Selecciona un Ejecutivo',
    apoyo: 'APOYO',
    bloqueo: 'BLOQUEO',
    opcionFiltrar: 'Filtrar',
    opcionFiltrar1: 'Filtrar por Apoyo',
    opcionFiltrar2: 'Filtrar por Bloqueo',
    quitarFiltro: 'Quitar Filtro',
    titulo2: 'Compromisos Vencidos',
    titulo3: 'Compromisos Por Vencer',
    titulo4: 'Por Autorizar',
    buscar: 'Buscar',
    

}