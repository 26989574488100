import {LanguageModel} from "./SelectLanguageActionTypes";
import {languageWarrantiesSpanish} from "../views/garantias/warranties/WarrantiesLanguage";
import {SelectLanguageSpanish} from "../components/public/select-language/SelectLanguageModel";
import {SelectAgentLanguageSpanish} from "../components/garantias/select-type-user/SelectTypeUserLanguageModel";
import {EngagmentsLanguageSpanish} from "../views/compromisos/engagements/EngagmentsLanguageModel";
import {AuthorizeEngagementsLanguageSpanish} from "../components/compromisos/authorization/AuthorizeEngagementsLanguageModel";
import {SelectFiadoLanguageSpanish} from "../components/public/select-type/SelectFiadoLanguageModel";
import { FindClientLanguageSpanish } from "../components/compromisos/find-client/FindClientLanguageModel";

import {WarrantyLanguageSpanish} from "../views/garantias/warranty/WarrantyLanguageModel";
import {ExpedientIdentificationLanguageSpanish} from "../components/garantias/expedient-identification/ExpedientIdentificationLanguageModel";
import { CreateEngagmentsLanguageSpanish } from "../views/compromisos/create-engagements/CreateEngagementLanguageModel";
import { TableEngagmentsLanguageSpanish } from "../components/compromisos/table-engagements/TableEngagementsLanguage";
import { CheckEngagmentsLanguageSpanish } from "../views/compromisos/engagements/CheckEngagemetsLanguageModel";
import { AutoEngagmentsLanguageSpanish } from "../views/compromisos/engagements/AutoEngagementsLanguageModel";
import { NavBarLanguageSpanish } from "../components/public/nav-bar/NavBarLanguageModel";
import {WarrantiesReviewLanguageSpanish} from "../views/garantias/warranties-review/WarrantiesReviewLanguage";
import {PrincipalDetailLanguageSpanish} from "../views/garantias/principal-detail/PrincipalDetailLanguage";
import {TableWarrantyLanguageSpanish} from "../components/garantias/table-loans-principal/TableLoansPrincipalLangugage";
import {InfoPrincipalLanguageSpanish} from "../components/garantias/info-principal/InfoPrincipalLanguage";
import {ReviewLoteLanguageModel, ReviewLoteLanguageSpanish} from "../views/garantias/review-lote/ReviewLoteLanguage";
import {
    StatisticsWarrantiesLanguageSpanish
} from "../views/garantias/statistics/StatisticsWarrantiesLanguge";
import {StatusRowWarrantyLanguageSpanish} from "../components/garantias/status-row-warranty/StatusRowWarrantyLanguage";
import {
    AuthorizationAmountWarrantySpanish
} from "../components/garantias/authorization-amount-warranty/AuthorizationAmountWarrantyLanguage";
import {
    AuthorizationDocWarrantyLanguageSpanish
} from "../components/garantias/authorization-doc-warranty/AuthorizationDocWarrantyLanguage";
import {AddObligorLanguageSpanish} from "../components/garantias/add-obligor/AddObligorLanguage";
import { AuthorizationDictumWarrantyLanguageSpanish
} from "../components/garantias/authorization-dictum-warranty/AuthorizationDictumWarrantyLanguage";
import {
    BreadCumbPrevLanguageSpanish
} from "../components/public/breadcumb-prev/BreadCumbPrevLanguage";
import {ObligorsDetailLanguageSpanish} from "../views/garantias/obligor-details/ObligorsDetailsLanguage";
import {LockPrincipalSpanish} from "../components/garantias/lock-principal/LockPrincipalLanguage";
import {ModalFileMaxUploadLanguageSpanish} from "../components/public/modal-file-max-upload/ModalFileMaxUploadLanguage";
import {
    AuthorizationPercentWarrantySpanish
} from "../components/garantias/authorization-percent-warranty/AuthorizationPercentWarrantyLanguage";
import { CreatequeryLanguageSpanish } from "../views/buro-credito/create-query/CreatequeryLanguage";
import { CreateCreditBureauLanguageSpanish } from "../views/buro-credito/credit-bureau/CreditbureauLanguage";
import {NavBarPublicLanguageSpanish} from "../components/public/nav-bar-public/NavBarPublicLanguage";
import { CreatefiltrarLanguageSpanish } from "../views/buro-credito/filtrar/filtrar";
import {HomeLanguageSpanish} from "../views/public/home/HomeLanguage";
import {LoginLanguageSpanish} from "../views/public/login/LoginLanguage";
import { languagePolizasEspaniol } from "../views/rh/polizas-rh/PolizasRHLanguage";
import {ReviewDocsLanguageSpanish} from "../views/garantias/review-docs/ReviewDocsLanguage";
import {RecoverPswLanguageSpanish} from "../views/public/recover-psw/RecoverPswLanguage";
import {
    AuthorizationDateWarrantySpanish
} from "../components/garantias/authorization-vigency-warranty/AuthorizationVigencyWarrantyLanguage";
import {ConfigAccountLanguageSpanish} from "../views/buro-credito/Configuracion/ConfigCuentaLanguage";
import {
    languageFormCreateEngagmentsSpanish
} from "../components/compromisos/form-create-engagments/FormCreateEngagmentsLanguage";
import { languageDepostitosGarantiasEspaniol } from "../views/tesoreria/depositos-garantia/DepositosGarantiasLanguage";
import { languageReporteGarantiasEspaniol } from "../views/reportes/garantias-vencidas/ReporteGarantiasLanguage";
import { languageMsgFirmaEspaniol } from "../views/tesoreria/msg-firma/MsgFirmaLanguage";
import {UnlockPrincipalSpanish} from "../components/garantias/unlock-principal/UnlockPrincipalLanguage";
import {LockCommissionPrincipalSpanish} from "../components/garantias/lock-commission-principal/LockCommissionPrincipalLanguage";
import {UnLockCommissionPrincipalSpanish} from "../components/garantias/unlock-commission-principal/UnLockCommissionPrincipalLanguage";
import { RequestsLanguageSpanish } from "../views/buro-credito/requests/Requests";
import {CreateWarrantyLanguageSpanish} from "../components/garantias/create-warranty/CreateWarrantyLanguage";
import {
    SelectUserEngagementsLanguageSpanish
} from "../components/compromisos/select-user-engagements/SelectUserEngagementsLanguage";
import {reporteExpedienteSpanish} from "../views/garantias/reporte-expediente/ReporteExpedienteLanguage";
import {ComentariosFiadoLanguageSpanish} from "../components/garantias/comentarios-fiado/ComentariosFiadoLanguage";
import {ResumeGarantiasLanguageSpanish} from "../components/garantias/resume-garantias/ResumeGarantiasLanguage";
import {
    StatusRowPrincipalLanguageSpanish
} from "../components/garantias/status-row-principal/StatusRowPrincipalLanguage";
import {ModalDeleteOsLanguageSpanish} from "../components/garantias/modal-delete-os/ModalDeleteOsLanguage";
import {InfoPrincipalLockSpanish} from "../components/garantias/info-principal-lock/InfoPrincipalLockLanguage";
import { AuditoriasLanguageSpanish } from "../views/buro-credito/auditoria/AuditoriasLanguage";
import { ViewAuditoriasLanguageSpanish } from "../views/buro-credito/viewAditoria/ViewAuditoriasLanguage";
import { CrearAuditoriaLanguageSpanish } from "../views/buro-credito/crear-auditoria/CrearAuditoriaLanguage";
import {LineaDeAfianzamientoSpanish} from "../components/garantias/linea-de-afianzamiento/LineaDeAfianzamientoLanguage";
import {SelectProductosLineaSpanish} from "../components/garantias/select-productos-linea/SelectProductosLineaLanguage";
import { WarrantyHistoryLanguageSpanish } from "../views/garantias/warranties-history/WarrantyHistoryLanguage";
import { HistoryReviewLanguageModelLanguageSpanish } from "../views/garantias/history-review/HistoryReviewLanguage";
import {WarrantyComparisionLanguageSpanish} from "../views/garantias/warranty-comparison/WarrantyComparisonLanguage";
import { WarrantyAuthorizationLanguageSpanish } from "../views/garantias/warranty-review/WarrantyReviewLanguage";


export const languageObjectSpanish: LanguageModel = {
    saludo: 'Bienvenido',
    // vistas
    warranties: languageWarrantiesSpanish,
    warranty: WarrantyLanguageSpanish,
    warrantiesReview: WarrantiesReviewLanguageSpanish,
    principalDetail: PrincipalDetailLanguageSpanish,
    reviewLote: ReviewLoteLanguageSpanish,
    statisticsWarranty: StatisticsWarrantiesLanguageSpanish,
    warrantyAuthorization: WarrantyAuthorizationLanguageSpanish,
    obligorsDetail: ObligorsDetailLanguageSpanish,
    creditbureau:CreateCreditBureauLanguageSpanish,
    createquery:CreatequeryLanguageSpanish,
    homeLanguage: HomeLanguageSpanish,
    createfiltrar:CreatefiltrarLanguageSpanish,
    login: LoginLanguageSpanish,
    polizasRH: languagePolizasEspaniol,
    recoverPsw: RecoverPswLanguageSpanish,
    configAccount: ConfigAccountLanguageSpanish,
    depositosGarantias:languageDepostitosGarantiasEspaniol,
    reporteGarantias:languageReporteGarantiasEspaniol,
    msgFirma : languageMsgFirmaEspaniol,
    request:RequestsLanguageSpanish,
    auditorias:AuditoriasLanguageSpanish,
    ViewAudi:ViewAuditoriasLanguageSpanish,
    CreateAudi:CrearAuditoriaLanguageSpanish,
    WarrantyHistoryLanguage: WarrantyHistoryLanguageSpanish,
    WarrantyComparisonLanguage: WarrantyComparisionLanguageSpanish,
    HistoryReviewLanguage: HistoryReviewLanguageModelLanguageSpanish,
    // componentes
    selectLanguage: SelectLanguageSpanish,
    selectAgent: SelectAgentLanguageSpanish,
    engagments: EngagmentsLanguageSpanish,
    findClient: FindClientLanguageSpanish,
    createEngagement: CreateEngagmentsLanguageSpanish,
    authorizeEngagements: AuthorizeEngagementsLanguageSpanish,
    selectFiado: SelectFiadoLanguageSpanish,
    expedientIdentificaction: ExpedientIdentificationLanguageSpanish,
    tableEngagements: TableEngagmentsLanguageSpanish,
    checkEngagements: CheckEngagmentsLanguageSpanish,
    autoEngagements: AutoEngagmentsLanguageSpanish,
    navBar: NavBarLanguageSpanish,
    tableWarranty: TableWarrantyLanguageSpanish,
    infoPrincipal: InfoPrincipalLanguageSpanish,
    statusRowWarranty: StatusRowWarrantyLanguageSpanish,
    authorizationAmountWarranty: AuthorizationAmountWarrantySpanish,
    authorizationPercentWarranty: AuthorizationPercentWarrantySpanish,
    authorizationDocWarranty: AuthorizationDocWarrantyLanguageSpanish,
    addObligor: AddObligorLanguageSpanish,
    authorizationDictumWarranty:  AuthorizationDictumWarrantyLanguageSpanish,
    BreadCumbPrev: BreadCumbPrevLanguageSpanish,
    lockPrincipal: LockPrincipalSpanish,
    unlockPrincipal: UnlockPrincipalSpanish,
    modalFileMaxUpload: ModalFileMaxUploadLanguageSpanish,
    navBarPublic: NavBarPublicLanguageSpanish,
    reviewDocs: ReviewDocsLanguageSpanish,
    authorizationDateWarrantyLanguage: AuthorizationDateWarrantySpanish,
    formCreateEngagmentsLanguage: languageFormCreateEngagmentsSpanish,
    lockCommissionPrincipal: LockCommissionPrincipalSpanish,
    unLockCommissionPrincipal: UnLockCommissionPrincipalSpanish,
    createWarranty: CreateWarrantyLanguageSpanish,
    selectUserEngagments: SelectUserEngagementsLanguageSpanish,
    reporteExpediente: reporteExpedienteSpanish,
    comentariosFiado: ComentariosFiadoLanguageSpanish,
    resumeGarantias: ResumeGarantiasLanguageSpanish,
    statusRowPrincipal: StatusRowPrincipalLanguageSpanish,
    modalDeleteOs: ModalDeleteOsLanguageSpanish,
    infoPrincipalLock: InfoPrincipalLockSpanish,
    lineaCreditoLanguage: LineaDeAfianzamientoSpanish,
    selectProductosLinea:  SelectProductosLineaSpanish
}