import React, {ChangeEvent, useEffect, useState} from "react";
import "./SelectUserEngagements.scss";
import {Toggle} from "@lmig/lmds-react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import * as engagementService from "../../../services/compromisos/EngagementsService";
import {validRoles} from "../../../class/CommonClass";
import {UserModel} from "../../../models/public/UserModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {ItemModel} from "../../../models/public/ItemModel";
import {SearchAutocomplete} from "../../public/search-autocomplete/SearchAutocomplete";
import {TypeProfileEngagmentsEnum} from "../../../models/compromisos/TypeProfileEngagmentsEnum";
import {UserEngagementsSelected} from "../../../models/compromisos/UserEngagementsSelected";


export interface TypeProfileCompromisos {
    id: number,
    tipo: TypeProfileEngagmentsEnum
}

interface SelectAgentsProps {
    userSelected: (user: UserEngagementsSelected) => void;
}

export const SelectUserEngagements: React.FC<SelectAgentsProps>  = ({userSelected} ) => {

    // redux
    const languageState = useSelector((state: RootStore) => state.language.language?.selectUserEngagments);

    const  localStorageClass = new LocalStorageClass();

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [isClientDirect, setIsClientDirect] = useState<boolean>(false);

    const [idEjecutivo, setIdEjecutivo] = useState<number | null>(0);

    const [idAgente, setIdAgente] = useState<number | null>(0);

    const [idPromotor, setIdPromotor] = useState<number>(0);

    const [idFuncionario, setIdFuncionario] = useState<number>(0);

    const [idBuro, setIdBuro] = useState<number>(0);

    const [itemsAgentes, setItemsAgentes] = useState<ItemModel[]>([]);

    const [itemsEjecutivos, setItemsEjecutivos] = useState<ItemModel[]>([]);


    useEffect(() => {

    },[]);



    const handleInputChangeToggle = (e: ChangeEvent<HTMLInputElement> | any, isChecked?: boolean | any) => {
        const  auxIsDirecto = isClientDirect? false: true;
        setIsClientDirect(auxIsDirecto);
        userSelected({
                idEjecutivoCuetaYGar:idEjecutivo,
                idAgente: idAgente,
                clienteDirecto: auxIsDirecto});

    }

    const getAgentesByEjecutivo = async (idEjecutivo: number, busqueda: string) => {
        const res = await engagementService.getAgentes(busqueda, null, idEjecutivo);
        // vaciar a lista de gentes en item para que se muestre el select
        let auxItems: ItemModel [] = []
        for (const agente of res.data){
            auxItems.push({id: agente.idAgente, name: agente.nombre})
        }
        setItemsAgentes(auxItems);
    }

    const getEjecutivos = async (busqueda: string) => {
        const res = await engagementService.getEjecutivos(busqueda);
        // vaciar a lista de gentes en item para que se muestre el select
        let auxItems: ItemModel [] = []
        for (const ejecutivo of res.data){
            auxItems.push({id: ejecutivo.idEjecutivo, name: ejecutivo.nombre})
        }
        setItemsEjecutivos(auxItems);
    }

    const onSearchAgente = (search: string) => {
        if (search.length >= 2){
            const found = itemsAgentes.find((item) => item.name === search);
            if (!found){
                setIdAgente(0);
                // toca recargar y traer los datos del puro ejecutivo
            }
            // obtener el ejecutivo
            let idEjecutivoAux = 0;
            if (validRoles(PERFIL.roles,localStorageClass.EJECUTIVOCUENTA)){
                idEjecutivoAux = parseInt(PERFIL.clave!)
            }else {
                idEjecutivoAux = idEjecutivo!
            }

            getAgentesByEjecutivo(idEjecutivoAux, search)
        }

    }

    const onSearchEjecutivo = (search: string) => {
        if (search.length >= 2){
            const found = itemsEjecutivos.find((item) => item.name === search);
            if (!found){
                setIdEjecutivo(0);
                // toca recargar y traer los datos del puro ejecutivo
            }
            getEjecutivos(search);
        }

    }

    const onSelectAgente = (itemSelected: ItemModel) => {
        setIdAgente(itemSelected.id!);
      //  console.log('se ha seleccionado un item' + itemSelected)
        userSelected({
            idEjecutivoCuetaYGar: null,
            idAgente: itemSelected.id!,
            clienteDirecto: isClientDirect});
    }

    const onClearAgentes = () => {
        setIdAgente(null);
        setItemsAgentes([]);

        userSelected({
            idEjecutivoCuetaYGar: idEjecutivo,
            idAgente: null,
            clienteDirecto: isClientDirect});


    }

    // ejecutivos
    const onSelectEjecutivo = (itemSelected: ItemModel) => {
        setIdEjecutivo(itemSelected.id!);
        userSelected({
            idEjecutivoCuetaYGar: idEjecutivo,
            idAgente: null,
            clienteDirecto: isClientDirect});
        //  console.log('se ha seleccionado un item' + itemSelected)

    }

    const onClearEjecutivos = () => {
      setIdEjecutivo(null);
      setIdAgente(null);
      setItemsEjecutivos([]);
      setItemsAgentes([]);

    }



    return (
        <div className="row" id="select-user-engagements">
            <br />
            {(validRoles(PERFIL.roles,localStorageClass.EJECUTIVOCUENTA) ||
                validRoles(PERFIL.roles, localStorageClass.PROMOTOR) ||
                validRoles(PERFIL.roles, localStorageClass.FUNCIONARIO))?
                <div className="col-md-3">
                    <Toggle
                        labelVisual={languageState?.labelToogle}
                        onChange={handleInputChangeToggle}
                        defaultChecked={isClientDirect}
                        checked={isClientDirect}
                    />
                </div>: null

            }

            {(!isClientDirect)?(
                <>
                    {(validRoles(PERFIL.roles,localStorageClass.FUNCIONARIO))?
                        <div className="col-md-4" >
                            <SearchAutocomplete
                                items={itemsEjecutivos}
                                placeHolder={languageState?.searchExecutive!}
                                onSelect={onSelectEjecutivo}
                                onSearch={onSearchEjecutivo}
                                onClear={onClearEjecutivos}
                            />

                        </div>: null
                    }

                    { (validRoles(PERFIL.roles,localStorageClass.EJECUTIVOCUENTA) ||
                        idEjecutivo )?(
                        <div className="col-md-4" id="select-agents">
                            <SearchAutocomplete
                                items={itemsAgentes}
                                placeHolder={languageState?.searchAgent!}
                                onSelect={onSelectAgente}
                                onSearch={onSearchAgente}
                                onClear={onClearAgentes}
                            />

                        </div>
                    ):null}

                    <div className="col-md-1">
                        {/*    IdEje: {idEjecutivo}<br/>
                IdAgen: {idAgente}*/}

                    </div>

                </>
            ): null}





        </div>
    )
}

