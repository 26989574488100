import React, { useEffect, useState} from 'react';
import flagMexico from '../../../assets/images/public/nav/flagMexico-modified.png';
import flagUnitedStates from '../../../assets/images/public/nav/flagUnitedSatates-modified.png';
import './SelectLanguage.scss';

import {Button, Modal} from "@lmig/lmds-react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {getLanguage} from "../../../actions/SelectLanguageActions";
import {LanguageModel} from "../../../models/public/LanguageModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {initListLanguages} from "../../../class/CommonClass";
import {useHistory} from "react-router-dom";
interface SelectLanguageProps {

}

export const SelectLanguage: React.FC<SelectLanguageProps> = () => {

    const localStorageClass = new LocalStorageClass();
    const history = useHistory();

    const dispatch = useDispatch();
    // redux
    const languageState = useSelector((state: RootStore) => state.language);
    // modales
    const [openModalClose, setOpenModalClose] = useState(false);

    const [listLanguages, setlistLanguages] = useState<LanguageModel[]>([])

    const [selectLanguage, setSelectLanguage] =  useState<number>();

    useEffect(() => {

        let initialSatelistlan: LanguageModel[] = initListLanguages;

        let localStorageLanguageSelect: LanguageModel;

        if (localStorageClass.getSomeString(localStorageClass.LANGUAGESELECT)){

            localStorageLanguageSelect = JSON.parse(localStorageClass.getSomeString(localStorageClass.LANGUAGESELECT));

            for (let i of initialSatelistlan){

                if (i.idLenguaje == localStorageLanguageSelect.idLenguaje){
                    i.checked = true;
                    setSelectLanguage(localStorageLanguageSelect.idLenguaje);
                    dispatch(getLanguage(i.idLenguaje));
                    break;
                }
            }
        }else{
            // en caso de no encontrar ningun lenguaje seleccionado en local storage hay que seleccionar por defaul español

            if (initialSatelistlan.length>0){
                for (let i of initialSatelistlan){

                    if (i.idLenguaje == languageOptions.spanish){
                        i.checked = true;
                        setSelectLanguage(i.idLenguaje);
                        dispatch(getLanguage(i.idLenguaje));
                        localStorageClass.setSomeString(localStorageClass.LANGUAGESELECT,JSON.stringify(i));
                        break;
                    }
                }
            }

        }


        setlistLanguages(initialSatelistlan)



    },[]);

    // This function will be triggered when a radio button is selected
    const radioHandlerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log('select language')
        const index = parseInt(event.target.id);
        const languageSelect: LanguageModel = listLanguages![index];
        const value = parseInt(event.target.value);
        console.log(languageSelect)

        if (languageSelect.idLenguaje == languageOptions.spanish ||
            languageSelect.idLenguaje == languageOptions.english){
            setSelectLanguage(languageSelect.idLenguaje);
            setOpenModalClose(false);
            localStorageClass.setSomeString(localStorageClass.LANGUAGESELECT, JSON.stringify(languageSelect));
           // history.push('/cargando-idioma?url=' + history.location.pathname );
            dispatch(getLanguage(languageSelect.idLenguaje))
            window.location.replace(history.location.pathname);


        }
    };

    return (
        <div id="select-language">

            <a className={"flags"}
               onClick={() => setOpenModalClose(true)}>

                {selectLanguage == languageOptions.spanish ? (
                    <img src={flagMexico} className={"img-circle"}  />
                ): null}

                {selectLanguage == languageOptions.english ? (
                    <img src={flagUnitedStates} className={"img-circle "} />
                ): null}

            </a>



            <Modal
                isOpen={openModalClose}
                size="medium"
                modalStyle="standard"
                title={languageState.language?.selectLanguage.titleModal}
                closeFunc={() => setOpenModalClose(false)}
            >

                <div className="modal-language">
                    <fieldset>
                        {/* <legend>Por favor selecciona un idioma</legend>*/}
                        {listLanguages?.map((i, index) => (
                            <div key={index} className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="languages"
                                    value={i.lang}
                                    id={String(index)}
                                    defaultChecked={i.checked}
                                    onChange={radioHandlerChange}
                                />
                                <label className="form-check-label" htmlFor={'language-'+i.idLenguaje}>
                                    <img src={i.image} className="img-responsive img-circle "/>
                                    {i.descripcion}
                                </label>
                            </div>
                        ))}




                    </fieldset>


                </div>


            </Modal>

        </div>
    );
};


export enum languageOptions {
    spanish = 1,
    english = 2
}
export default SelectLanguage;