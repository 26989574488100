import React, {useEffect, useState} from 'react';
import {Pagination} from 'react-bootstrap';
import {
    AlphanumericInput,  Button,
    IconSearch,  Modal, Select, SelectOption,
    Table, TableCell, TableRow,
} from "@lmig/lmds-react";
import './GarantiasMantenimiento.scss';
import {ListGarantiaModel} from "../../../models/garantias/ListGarantiaModel";
import {PaginacionModel} from "../../../models/public/PaginacionModel";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import {AxiosError} from "axios";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {
    formatCurrency,
    formatValue,
    initPaginacion,
    InputChange,
    loadPaginationList
} from "../../../class/CommonClass";
import { FiltersSearchWarantyModel} from "../../../models/garantias/EstatusGarantiaEnum";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import {InfoObligadoSolidario} from "../../../models/garantias/InfoObligadoSolidario";
import * as supportService from "../../../services/support/SupportService";
import {DocumentosGarantiaMantenimiento} from "../documentos-garantia-mantenimiento/DocumentosGarantiaMantenimiento";



interface GarantiasMantenimientoProps {
    idPrincipal: number
    validWarranty?: boolean;
}

export const GarantiasMantenimiento: React.FC<GarantiasMantenimientoProps> = ({ idPrincipal, validWarranty}: GarantiasMantenimientoProps)=> {

    // redux
    const languageState = useSelector((state: RootStore) => state.language);


    const [listGarantias, setListGarantias] = useState<ListGarantiaModel []>([]);
    const [loadingGarantias, setLoadingGarantias] = useState<boolean>(true);
    const [errorGarantias, setErrorGarantias] = useState<boolean>(false);
    const [paginacion, setPaginacion] = useState<PaginacionModel>(initPaginacion);
    const [listPaginacion, setListPaginacion] = useState<number[]>([]);
    const [listObligors, setListObligors] = useState<InfoObligadoSolidario[]>([]);

    const initFilter: FiltersSearchWarantyModel ={
        pendiente: false,
        revision: false,
        vencida: false,
        vigente: false,
        showLoansPrincipal: false
    }

    const [search, setSearch] = useState<string>('');

    const [filterSearch, setfilterSearch] = useState<FiltersSearchWarantyModel>(initFilter);

    const [openModalFilters, setOpenModalFilters] = useState<boolean>(false);

    const [selectLoans, setSearchOS] = useState<string>('');

    const [loadingObligors, setLoadingObligors] = useState<boolean>(false);

    const [errorObligors, setErrorObligors] = useState<boolean>(false);

    const [selectedObligors, setSelectedObligors] = useState<InfoObligadoSolidario[]>([]);


    const [listObligorsClient, setListObligorsClient] = useState<InfoObligadoSolidario[]>([]);

    const [openModalDocs, setOpenModalDocs] = useState<boolean>(false);

    const [garantiaSelected, setGarantiaSelected] = useState<ListGarantiaModel | null>(null);


    useEffect(() => {
        // traer la informaciòn del usuario
        getWarranties(idPrincipal, paginacion, search);
    },[idPrincipal]);


    const getWarranties = async (idPrincipal: number,
                                 paginacion: PaginacionModel,
                                 search: string | null,) => {
        setGarantiaSelected(null);
        setLoadingGarantias(true);
        setErrorGarantias(false)
        if (search == ''){search = null}
        try {
            const res = await supportService.getGarantias(idPrincipal,paginacion, search );
            const response: {paginacion: PaginacionModel, elementos: ListGarantiaModel[]} = res.data;
            response.paginacion.size = paginacion.size;
            setListPaginacion(loadPaginationList(response.paginacion));
            setLoadingGarantias(false);
            setPaginacion(response.paginacion);
            setListGarantias(response.elementos)



        }
        catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingGarantias(false);
                setErrorGarantias(true)

            }
        }
    };

    const clickGoToPage = (page: number) => {
        const totalPages: number = paginacion.totalPages;
        if (page>=1 &&  page <= totalPages){
            const auxPaginacion = paginacion;
            auxPaginacion.currentPage = page;
            setPaginacion(auxPaginacion);
            getWarranties(idPrincipal, auxPaginacion, search);
        }

    }

    const handleInputChangeSelect = (e: InputChange) => {
        let auxPaginacion = paginacion;
        auxPaginacion.size = formatValue(e.target.value)
        auxPaginacion.currentPage = 1;
        getWarranties(idPrincipal, auxPaginacion, search);
        setPaginacion(auxPaginacion);

    }

    const handleInputChange = (e: InputChange) => {
        let value = e.target.value;
        setSearch(value)
    }

    const onClickSearch = () => {
        if (search.length >= 1){
            getWarranties(idPrincipal, initPaginacion, search);
        }
    }
    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            if (search.length >= 1){
                getWarranties(idPrincipal, initPaginacion, search);
            }
        }
    }


    return (
        <div id="garantias-mantenimiento">
            {/*mostrar N entradas*/}

            <div className="row seccion-botons  " id="nShow-n-entries">

                <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                    <Select
                        isRequired
                        name="nEntries"
                        labelA11y=""
                        labelVisual={languageState.language?.tableWarranty.show + ' '+ String(paginacion.size) + ' ' + languageState.language?.tableWarranty.registers}
                        value={String(paginacion.size)}
                        onChange={handleInputChangeSelect}
                        placeholderOption="">
                        <SelectOption value="10">
                            10
                        </SelectOption>
                        <SelectOption value="25">
                            25
                        </SelectOption>
                        <SelectOption value="50">
                            50
                        </SelectOption>
                        <SelectOption value="100">
                            100
                        </SelectOption>
                    </Select>

                </div>
                <div className="col-12 col-sm-12 col-md-2 col-lg-4"/>

                <div className="col-12 col-sm-12 col-md-10 col-lg-5">
                    <AlphanumericInput
                        className="float-right w-100"
                        labelVisual={languageState.language?.tableWarranty.search!}
                        labelA11y={languageState.language?.tableWarranty.search!}
                        name="search"
                        disabled={loadingGarantias}
                        onChange={handleInputChange}
                        onKeyDown={onKeyDown}
                        value={search}
                    />
                </div>

                <div className="col-12 col-sm-12 col-md-2 col-lg-1">
                    <Button  variant="primary"
                             className=""
                             disabled={loadingGarantias}
                             onClick={()=> {onClickSearch()}}>
                        <IconSearch size="16" />
                    </Button>
                </div>



                <div className="col-12 col-sm-12 col-md-3"></div>

                <div className="col-12 col-sm-12 col-md-9">

                </div>



            </div>


            {(garantiaSelected!= null)?
                <>

                    <div className="col-md-12 text-right">

                        <a
                            onClick={()=>{
                                setGarantiaSelected(null);
                            }}

                            className="text-primary"
                        >
                            Regresar
                        </a>
                    </div>
                    Garantía: &nbsp;
                    <b>{garantiaSelected.folio}{(garantiaSelected.version> 0)? '-' + garantiaSelected.version: null}</b>
                    <br/>
                    <DocumentosGarantiaMantenimiento idGarantia={garantiaSelected.idGarantia!} />

                </>
                :
                <>

                    {loadingGarantias? (
                            <div className="w-100">
                                <SpinnerLoading
                                    text={languageState.language?.tableWarranty.loadingWarranties}/>
                            </div>
                        ):
                        (errorGarantias)? (
                                <div className="col-md-12">
                                    <NegativeAlert
                                        textAlert={languageState.language?.tableWarranty.errorWarranties!}/>
                                </div>):

                            <div className="col-md-12 card">


                                {/*data table*/}
                                <Table tableName={languageState.language?.principalDetail.tableLoans}>
                                    <thead>
                                    <TableRow className="text-center">
                                        <TableCell dataHeader={languageState.language?.tableWarranty.invoice} type="colHead">
                                            {languageState.language?.tableWarranty.invoice}
                                        </TableCell>
                                        <TableCell dataHeader={languageState.language?.tableWarranty.warranty} type="colHead">
                                            <div className="warranty-name">
                                                {languageState.language?.tableWarranty.warranty}
                                            </div>

                                        </TableCell>
                                        <TableCell dataHeader={languageState.language?.tableWarranty.typeWarranty} type="colHead">
                                            {languageState.language?.tableWarranty.typeWarranty}
                                        </TableCell>
                                        <TableCell dataHeader={languageState.language?.tableWarranty.amountWeighted} type="colHead">
                                            {languageState.language?.tableWarranty.amountWeighted}
                                        </TableCell>
                                        <TableCell dataHeader={languageState.language?.tableWarranty.propietary} type="colHead">
                                            {languageState.language?.tableWarranty.propietary}
                                        </TableCell>
                                        <TableCell dataHeader={languageState.language?.tableWarranty.dateStart} type="colHead">
                                            {languageState.language?.tableWarranty.dateStart}
                                        </TableCell>
                                        <TableCell dataHeader={languageState.language?.tableWarranty.dateEnd} type="colHead">
                                            {languageState.language?.tableWarranty.dateEnd}
                                        </TableCell>

                                    </TableRow>
                                    </thead>
                                    <tbody>
                                    {
                                        listGarantias.map((i, index) =>
                                            (     <TableRow key={index}
                                                            onClick={()=>{
                                                                setGarantiaSelected(i);
                                                            }}
                                                >
                                                    <TableCell dataHeader={languageState.language?.tableWarranty.invoice} type="rowHead">
                                                        {i.folio}{(i.version> 0)? '-' + i.version: null}
                                                    </TableCell>
                                                    <TableCell dataHeader={languageState.language?.tableWarranty.warranty} className="desc-gar">
                                                        {i.descripcionClave}
                                                    </TableCell>
                                                    <TableCell dataHeader={languageState.language?.tableWarranty.typeWarranty}>
                                                        {i.descripcionTipoUso}
                                                    </TableCell>
                                                    <TableCell dataHeader={languageState.language?.tableWarranty.amountWeighted} className="">
                                                        {formatCurrency(i.monto)}
                                                    </TableCell>

                                                    <TableCell dataHeader={languageState.language?.tableWarranty.propietary}>
                                                        {i.propietario}
                                                    </TableCell>
                                                    <TableCell dataHeader={languageState.language?.tableWarranty.dateStart}>
                                                        {i.fechaInicial}
                                                    </TableCell>
                                                    <TableCell dataHeader={languageState.language?.tableWarranty.dateEnd}
                                                               className="vigency-gar">
                                                        {i.fechaFinal}
                                                    </TableCell>

                                                </TableRow>
                                            ))
                                    }

                                    </tbody>
                                </Table>

                                <div className="row pagination-sdgf">
                                    <div className="col-md-5 show-registers">
                                        {languageState.language?.tableWarranty.showing}&nbsp;
                                        {(paginacion.currentPage * paginacion.size)- (paginacion.size-1)}  {languageState.language?.tableWarranty.to}&nbsp;
                                        {(paginacion.currentPage * paginacion.size)}  {languageState.language?.tableWarranty.of}&nbsp;
                                        {paginacion.totalItems}  {languageState.language?.tableWarranty.entries}.
                                    </div>
                                    <div className="col-md-7 ">
                                        <Pagination className="float-md-right" id="pagination-table-warranty">
                                            <Pagination.Prev
                                                onClick={()=>{
                                                    clickGoToPage((listPaginacion[0])-1)
                                                }}
                                            />
                                            {
                                                listPaginacion.map((i, index) =>
                                                    ( <Pagination.Item
                                                            key={i}
                                                            active={i === paginacion.currentPage}
                                                            onClick={()=>{
                                                                clickGoToPage(i)
                                                            }}>
                                                            {i}
                                                        </Pagination.Item>

                                                    ))
                                            }
                                            <Pagination.Next
                                                onClick={()=>{
                                                    clickGoToPage(listPaginacion[listPaginacion.length-1]+1)
                                                }}
                                            />

                                        </Pagination>

                                    </div>

                                </div>


                            </div>

                    }
                </>

            }



            {/*modal se ha sobrepasado el tamaño documento*/}
            <Modal
                isOpen={openModalDocs}
                size="large"
                modalStyle="standard"
                title="Subir Documentos de Garantía"
                closeFunc={() => {
                    setOpenModalDocs(false);
                }}>



                <div className="row">


                    <div className="col-md-8">
                        <br/>
                    </div>
                    <div className="col-md-4">
                        <br/>
                        <Button dynamicWidth
                                variant="secondary"
                                onClick={() => {
                                    setOpenModalDocs(false);
                                }}>
                           Cerrar
                        </Button>
                    </div>

                </div>

            </Modal>

        </div>
    );
};