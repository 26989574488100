import React, { useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {CalificacionDocModel} from "../../../models/garantias/CalificacionDocModel";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import {Dropdown} from "react-bootstrap";
import {Button, IconInfo, IconThumbsDown, IconThumbsUp, Modal} from "@lmig/lmds-react";
import './AuthorizationDocWarranty.scss';
import {MotivoRechazoModel} from "../../../models/garantias/MotivoRechazoModel";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import {InformativeAlert} from "../../public/informative-alert/InformativeAlert";
import {PositiveAlert} from "../../public/positive-alert/PositiveAlert";
import {ConditionOptionModel} from "../../../models/public/ConditionOptionModel";
import Select, {MultiValue} from "react-select";
import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";
import {validRoles} from "../../../class/CommonClass";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {UserModel} from "../../../models/public/UserModel";

interface QuoteDocExpedientProps {
    idDocumento: number | null,
    idTipoDocumento: number;
    idWarranty: number;
    returnDocStatus?: (statusDoc: number) => void;
}

export const AuthorizationDocWarranty: React.FC<QuoteDocExpedientProps> = ({idDocumento,idTipoDocumento, idWarranty, returnDocStatus}:QuoteDocExpedientProps)=> {

    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    const localStorageClass = new LocalStorageClass();

    const initExpedient : CalificacionDocModel = {
        idDocumento: 0,
        estadoDocumento: statesQuoteDoc.pendiente,
        descripcionEstado: '',
        rechazo: []
    }

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [quoteExpedient, seQuoteExpedient] = useState<CalificacionDocModel>(initExpedient);

    const [openModalRejection, setOpenModalRejection] = useState<boolean>(false);

    const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);

    const [errorQuoteReason, setErrorQuoteReason] = useState<boolean>(false);

    const [listReasons, setListReasons] = useState<MotivoRechazoModel []>([]);

    const [loadingReasons, setLoadingReasons] = useState<boolean>(true);

    const [errorReaseons, setErrorReaseons] = useState<boolean>(true);

    const [sendingAuthorization, setSendingAuthorization] = useState<boolean>(false);

    const [listConditionalOption, setListConditionalOption] = useState<ConditionOptionModel[]>([]);

    const [selectedReasons, setSelectedReasons] = useState<MotivoRechazoModel []>([]);

    const [selectReasonsPlease, setSelectReasonsPlease] = useState<boolean>(false);

    const [visibleQuoteButton, setVisibleQuoteButton] = useState<boolean>(false);

    useEffect(() => {

        if (idWarranty){
            getDocQuote(idDocumento);
            if (validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS)) {
                setVisibleQuoteButton(true);
            }
        }

    },[]);

    const getDocQuote =  async (idDoc: number | null) => {
        if (idDoc){
            try {
                const res = await garantiasService.getCalifExpediente(idDoc);
                const quoteDoc: CalificacionDocModel = res.data
                if (quoteDoc.descripcionEstado==null){
                    quoteDoc.descripcionEstado = '';
                }
               seQuoteExpedient(quoteDoc)


            } catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    console.log(err.response.status)
                    console.log(err.response.data)

                }
            }
        }else {


            seQuoteExpedient(initExpedient)
        }

    }

    const quoteDocExpedient = (quoteAmount: number) => {
        if (idDocumento){
            switch (quoteAmount){
                case statesQuoteDoc.aprobado:
                    setOpenModalConfirm(true);
                    break
                case statesQuoteDoc.rechazado:
                    openModalReject()
                    break;
            }
        }

    }

    const openModalReject = async () => {
        setOpenModalRejection(true);
        setLoadingReasons(true);
        setErrorReaseons(false);
        try {
            const res = await garantiasService.getMotivosRechazoGarantia(idWarranty, idTipoDocumento);
            setListReasons(res.data);
            formatList(res.data);
            setLoadingReasons(false);
            setErrorReaseons(false);
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setErrorReaseons(true);
                setLoadingReasons(false);

            }
        }
    }

    const putCalifiExpediente =  async (estadoDocumento: number, idDocumento: number, motivos?: MotivoRechazoModel[]) => {
        setLoadingReasons(true);
        setErrorQuoteReason(false);
        setSendingAuthorization(true);
        try {
            const res = await garantiasService.putCalifiExpediente(estadoDocumento,idDocumento, motivos!);
            setLoadingReasons(false);
            setErrorQuoteReason(false);
            const quoteDoc : CalificacionDocModel = res.data;
            seQuoteExpedient(quoteDoc);
            setOpenModalRejection(false);
            setOpenModalConfirm(false);
            setSendingAuthorization(false);
            returnDocStatus!(quoteDoc.estadoDocumento)


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingReasons(false);
                setErrorQuoteReason(true);
                setSendingAuthorization(false);

            }
        }
    }

    const formatList = (rejectList: MotivoRechazoModel[]) => {
        let  listaCondicionalAuxiliar: ConditionOptionModel[] = [];

        for(const i of rejectList){

            listaCondicionalAuxiliar.push({
                name: '',
                value: i.idMotivo,
                label: i.descripcion,
                status: 0})
        }
        setListConditionalOption(listaCondicionalAuxiliar);
    }

    const handleInputChangeSelect = (e: MultiValue<ConditionOptionModel>) => {

        console.log(e)
        const auxselectedReasons: MotivoRechazoModel [] = []
        for (const i of e){
            auxselectedReasons.push({idMotivo: i.value, descripcion: i.label})
        }
        setSelectedReasons(auxselectedReasons);
    }

    return (
        <>

            <div className="col-md-2 col-sm-12">
                <div id="authorization-coc-warranty">
                    {(visibleQuoteButton)?
                     <Dropdown>
                            <Dropdown.Toggle variant="light" className="dropdown-more-options" id="dropdown-basic-2">
                                { quoteExpedient.estadoDocumento == statesQuoteDoc.enCaptura ||
                                quoteExpedient.estadoDocumento == statesQuoteDoc.enRevision ? (
                                    <IconThumbsUp
                                        color="gray"
                                        size="16"
                                        title={quoteExpedient.descripcionEstado!}/>
                                ): null}
                                {quoteExpedient.estadoDocumento == statesQuoteDoc.aprobado ? (
                                    <IconThumbsUp
                                        color="informative"
                                        size="16"
                                        title={quoteExpedient.descripcionEstado!}/>
                                ): null}
                                { quoteExpedient.estadoDocumento == statesQuoteDoc.rechazado ? (
                                    <IconThumbsDown
                                        color="negative"
                                        size="16"
                                        title={quoteExpedient.descripcionEstado!}/>
                                ): null}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>

                                {quoteExpedient.estadoDocumento == statesQuoteDoc.pendiente ||
                                quoteExpedient.estadoDocumento == statesQuoteDoc.enCaptura ||
                                quoteExpedient.estadoDocumento == statesQuoteDoc.enRevision ? (
                                    <>
                                        <Dropdown.Item
                                            onClick={() =>{
                                                quoteDocExpedient(statesQuoteDoc.aprobado)
                                            }}
                                        >
                                            <IconThumbsUp
                                                color="informative" size="16" />

                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() =>{
                                                setSelectReasonsPlease(false);
                                                quoteDocExpedient(statesQuoteDoc.rechazado)
                                            }}
                                        >
                                            <IconThumbsDown
                                                color="negative" size="16"/>

                                        </Dropdown.Item>
                                    </>
                                ): null}
                                { quoteExpedient.estadoDocumento== statesQuoteDoc.aprobado ? (
                                    <>
                                        <Dropdown.Item
                                            onClick={() =>{
                                                setSelectReasonsPlease(false);
                                                quoteDocExpedient(statesQuoteDoc.rechazado)
                                            }}
                                        >
                                            <IconThumbsDown
                                                color="negative" size="16"/>

                                        </Dropdown.Item>
                                    </>
                                ): null}
                                { quoteExpedient.estadoDocumento== statesQuoteDoc.rechazado ? (
                                    <>
                                        <Dropdown.Item
                                            onClick={() =>{
                                                quoteDocExpedient(statesQuoteDoc.aprobado)
                                            }}
                                        >

                                            <IconThumbsUp
                                                color="informative" size="16"/>

                                        </Dropdown.Item>

                                    </>
                                ): null}

                            </Dropdown.Menu>
                        </Dropdown>:
                        <Button variant="secondary">
                            { (quoteExpedient.estadoDocumento == statesQuoteDoc.pendiente ||
                                quoteExpedient.estadoDocumento == statesQuoteDoc.enCaptura ||
                                quoteExpedient.estadoDocumento == statesQuoteDoc.enRevision)? (
                                <IconThumbsUp
                                    color="gray"
                                    size="16"
                                    title={quoteExpedient.descripcionEstado!}/>
                            ): null}
                            {quoteExpedient.estadoDocumento == statesQuoteDoc.aprobado? (
                                <IconThumbsUp
                                    color="informative"
                                    size="16"
                                    title={quoteExpedient.descripcionEstado!}/>
                            ): null}
                            { quoteExpedient.estadoDocumento ==  statesQuoteDoc.rechazado ? (
                                <IconThumbsDown
                                    color="negative"
                                    size="16"
                                    title={quoteExpedient.descripcionEstado!}/>
                            ): null}
                        </Button>
                    }
                </div>
            </div>
            {idDocumento?(
                <div className="col-md-12">
                    <br/>
                    {quoteExpedient.estadoDocumento == statesQuoteDoc.enCaptura ? (
                        <InformativeAlert textAlert={quoteExpedient.descripcionEstado!}/>
                    ): null}
                    {quoteExpedient.estadoDocumento == statesQuoteDoc.enRevision ? (
                        <InformativeAlert textAlert={quoteExpedient.descripcionEstado!}/>
                    ): null}

                    {quoteExpedient.estadoDocumento == statesQuoteDoc.rechazado ? (
                        <>
                            <NegativeAlert textAlert={quoteExpedient.descripcionEstado!+ ': '}/>
                            {quoteExpedient.rechazo.map((i,index) =>
                                (
                                    <>
                                        <span className="badge badge-pill badge-light">
                                             <IconInfo
                                                 color="error"
                                                 size="16"
                                                 title={i.descripcion}/> &nbsp;
                                            {i.descripcion}</span> &nbsp;
                                    </>
                                ))
                            }
                        </>
                    ): null}
                    {quoteExpedient.estadoDocumento == statesQuoteDoc.aprobado ? (
                        <PositiveAlert textAlert={quoteExpedient.descripcionEstado!}/>
                    ): null}

                </div>
            ): null}


            {/*modal rechazar documento*/}
            <Modal
                isOpen={openModalRejection}
                size="medium"
                modalStyle="standard"
                title={''}
                closeFunc={() => {setOpenModalRejection(false)}}
                id="modal-rechazo-authorization-amount-warranty">
                {loadingReasons?(
                        <div className="col-12">
                            <SpinnerLoading />
                        </div>
                    ):
                    (!errorReaseons)? (
                        <div className="col-12 list-reject">
                                <h4>{languageState.language?.authorizationDocWarranty.selectReason}</h4><br/>

                                <Select
                                    placeholder={languageState.language?.authorizationDocWarranty.selectReason}
                                    isMulti
                                    name="blocks"
                                    options={listConditionalOption}
                                    onChange={handleInputChangeSelect}
                                />

                                {(selectReasonsPlease)?<NegativeAlert textAlert={languageState.language?.authorizationDocWarranty.seleactAReason!}/>:null}
                                {listReasons.length==0? (
                                    <div className="col-12">
                                        <br/><br/>
                                        <h5>{languageState.language?.authorizationDocWarranty.reintent!}</h5>
                                    </div>
                                ): null}


                            </div>
                        ):
                        <div className="col-12">
                            <NegativeAlert
                                textAlert={languageState.language?.authorizationDocWarranty.sorryReason!}/>
                        </div>
                }

                <div className="row">
                    <div className="col-md-6 col-12">
                        <Button
                            dynamicWidth
                            variant="secondary"
                            onClick={()=>{
                                setOpenModalRejection(false);
                            }}>
                            {languageState.language?.authorizationDocWarranty.close!}
                        </Button>
                    </div>
                    <div className="col-md-6 col-12">
                        {!sendingAuthorization ? (
                                <Button
                                    dynamicWidth
                                    variant="primary"
                                    onClick={()=>{
                                        if (selectedReasons.length>0){
                                            putCalifiExpediente(statesQuoteDoc.rechazado, idDocumento!, selectedReasons)
                                        }else{

                                            setSelectReasonsPlease(true);
                                        }
                                    }}>
                                    {languageState.language?.authorizationDocWarranty.save!}
                                </Button>
                            ):
                            <ButtonSpinner label={languageState.language?.authorizationAmountWarranty.saving!}/>
                        }

                    </div>

                </div>
            </Modal>
            {/*modal confirmar autorización*/}
            <Modal
                isOpen={openModalConfirm}
                size="medium"
                modalStyle="standard"
                title={languageState.language?.authorizationDocWarranty.authorizeWarranty!}
                closeFunc={() => {setOpenModalConfirm(false)}}
                id="modal-comfirm">
                <h5>
                    {languageState.language?.authorizationDocWarranty.areYouSure!}
                </h5>
                <div className="row">

                    <div className="col-md-6">
                        <br/>
                        <Button dynamicWidth
                                variant="secondary"
                                onClick={() => {setOpenModalConfirm(false)}}>
                            {languageState.language?.authorizationDocWarranty.cancel!}
                        </Button>
                    </div>
                    <div className="col-md-6">
                        <br/>
                        {!sendingAuthorization ? (
                                <Button dynamicWidth
                                        variant="primary"
                                        onClick={() => { putCalifiExpediente(statesQuoteDoc.aprobado, idDocumento!)}}>
                                    {languageState.language?.authorizationDocWarranty.authorize!}
                                </Button>
                            ):
                            <ButtonSpinner label={languageState.language?.authorizationDocWarranty.authorizing!}/>
                        }
                    </div>

                </div>


            </Modal>

        </>
    );
};


export enum statesQuoteDoc {
    pendiente = 0,
    enCaptura = 1,
    enRevision = 2,
    aprobado = 3,
    rechazado = 4,
    guardadoDW = 5
}






