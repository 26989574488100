import axios from "axios";
import { LocalStorageClass } from "../../class/LocalStorageClass";
import environment from "../../environments/environment";
import { PaginacionModel } from "../../models/public/PaginacionModel";
import { DepositoModel } from "../../models/tesoreria/DepositoModel";
import { ReferenciaModel } from "../../models/tesoreria/ReferenciaModel";
import { EstadisticasModel } from "../../models/tesoreria/EstadisticasModel";
import { InfoUserModel } from "../../models/tesoreria/InfoUserModel";
import { InfoDocumentoModel } from "../../models/tesoreria/InfoDocumentoModel";

const API = environment.APIRECIBOCAJA;
const API2 = environment.APIFIRMADIGITAL;
const localStorageClass = new LocalStorageClass();

const header = {
    Accept: "application/json",
    'x-api-key': environment.APIKEYRECIBOCAJA,
    "env": environment.env,
    Authorization: localStorageClass.getProfileToken(),
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode()
};

const headerFirma = {
    Accept: "application/json",
    'x-api-key': environment.APIKEYFIRMADIGITAL,
    "env": environment.env,
    Authorization: localStorageClass.getProfileToken(),
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode()
};

const headersFormData = {
    Accept: "application/json",
    'X-Api-Key': environment.APIKEYRECIBOCAJA,
    'Content-Type': 'multipart/form-data',
    "env": environment.env,
    //mode: 'no-cors',
    Authorization: localStorageClass.getProfileToken(),
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode()
};

const headersVnd = {
    Accept: 'application/vnd.ms-excel',
    'X-Api-Key': environment.APIKEYRECIBOCAJA,
    "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "env": environment.env,
    Authorization: localStorageClass.getProfileToken(),
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode()
}

const updateHeader = () => {
    header.Authorization = localStorageClass.getProfileToken();
    header.idLenguaje = localStorageClass.getLanguageId();
    header.lang = localStorageClass.getLanguageCode();

    headersFormData.Authorization = localStorageClass.getProfileToken();
    headersFormData.idLenguaje = localStorageClass.getLanguageId();
    headersFormData.lang = localStorageClass.getLanguageCode();

    headersVnd.Authorization = localStorageClass.getProfileToken();
    headersVnd.idLenguaje = localStorageClass.getLanguageId();
    headersVnd.lang = localStorageClass.getLanguageCode();

    headerFirma.Authorization = localStorageClass.getProfileToken();
    headerFirma.idLenguaje = localStorageClass.getLanguageId();
    headerFirma.lang = localStorageClass.getLanguageCode();
}


export const getDepositos = async (fechaFinal: string,
    fechainicial: string,
    filtro: number[],
    page: number,
    queryin: string,
    size: number) => {
    updateHeader();
    let auxQueryin = ``;
    if (queryin !== '') {
        auxQueryin = `&queryin=${queryin}`;
    }
    return await axios.get<{ paginacion: PaginacionModel, referencia: DepositoModel[] }>(`${API}/getReferencias?estatus=${filtro}&fechaFin=${fechaFinal}&fechaInicio=${fechainicial}&page=${page}${auxQueryin}&size=${size}`, { headers: header });
};


export const getDetalleRecibo = async (queryin: string) => {
    updateHeader();
    return await axios.get<{ referencia: ReferenciaModel[] }>(`${API}/getDetalleRecibo/${queryin}`, { headers: header });
};

//Carga de archivo Excel
export const postFile = async (formData: FormData, user: string) => {
    updateHeader();
    return await axios.post<any>(`${API}/uploadExcel/${user}`, formData, { headers: headersFormData });
};

//Actualiza el pago aplicado por referencia
//se comenta version 1
/*export const putPago = async (idDeposito: number, idPrendaria: number) => {
    updateHeader();
    return await axios.put<{ codigo: number, message: string }>(`${API}/recibo/aplicarPago/${idDeposito}/${idPrendaria}`, { headers: header });
};*/

export const putPago = async (idDeposito: number, listIdPrendaria: number[], userName: string) => {
    updateHeader();
    return await axios.put<{ codigo: number, message: string }>(`${API}/aplicarPago/${idDeposito}?usuario=${userName}`, listIdPrendaria, { headers: header });
};



export const getReporte = async () => {
    updateHeader();
    return await axios.get(`${API}/report`, { responseType: 'arraybuffer', headers: headersVnd });
};


export const getEstadisticas = async () => {
    updateHeader();
    return await axios.get<EstadisticasModel[]>(`${API}/getPorcentajeGraficas`, { headers: header });
};

export const putCambioEstatus = async (idReferencia: number[], estatus: number) => {
    updateHeader();
    return await axios.put<{ codigo: number, message: string }>(`${API}/aplicaProspectoInversion/${estatus}`, idReferencia, { headers: header });
};
export const getInfoUsuario = async (user: string) => {
    updateHeader();
    return await axios.get<InfoUserModel>(`${API}/getUsuarios/${user}`, { headers: header });
};

export const getCoordenadas = async (idCor: number) => {
    updateHeader();
    return await axios.get<{ CorY: number, corX: number, descripcion: string, idCoordenada: number }>(`${API}/getGeneracionCoordenadas/${idCor}`, { headers: header });
};


export const putIdDocumento = async (idDocumento: string, idPrendaria: string) => {
    updateHeader();
    return await axios.put<{ codigo: number, message: string }>(`${API}/registraDocumento/${idDocumento}?idPrendaria=${idPrendaria}`, null, { headers: header });

};
/*export const getRecibo = async (referencia: string) => {
    updateHeader();
    return await axios.get<{ base64: string, nombre: string, estatus: number }>(`${API}/getRecibo/${referencia}`, { headers: header });
};*/

export const getReciboCaja = async (idDeposito: number) => {
    updateHeader();
    return await axios.get<{ name: string, documentLink: string }>(`${API}/getReciboCaja/${idDeposito}`, { headers: header });
};
//se comenta respecto al firmado de documento
export const getUrlFirmaRecibo = async (reciboBase64: string, URLredireccion: string, usuario: InfoUserModel, corX: number, corY: number, idPrendara: string, ref: string) => {
    const objJson = {
        "asunto": "Firmar el Documento",
        "documentoBase64": `${reciboBase64}`,
        "firmantes": [
            {
                "apellido": `${usuario.aPaterno} ${usuario.aMaterno}`,
                "correo": `${usuario.correo}`,
                "firmas": [
                    {
                        "escala": "regular",
                        "pagina": "1",
                        "x": `${corX}`,
                        "y": `${corY}`
                    }
                ],
                "nombre": `${usuario.nombre}`,
                "orden": 1,
                "tipo": `enlace`
            }
        ],
        "nombreDocumento": "Recibo de caja",
        "redireccion": `${URLredireccion}/confirmacion-firma?ref=${ref}&id=${idPrendara}`
    }
    updateHeader();
    return await axios.post(`${API2}/documentos`, objJson, { headers: headerFirma });

};
export const getReciboFirmado = async (idDocumento: number) => {
    updateHeader();
    return await axios.get(`${API2}/documentos/base64/${idDocumento}`, { headers: headerFirma });
};
export const getInfoDocumento = async (idDocumento: number) => {
    updateHeader();
    return await axios.get<InfoDocumentoModel>(`${API2}/documentos/${idDocumento}`, { headers: headerFirma });
};