export interface CreateEngagemetLanguageModel {
    
  
    titulo: string
    tipoLabel: string
    seleccionaTipo:string
    condicionesLabel:string 
    bloqueosLabel: string
    seleccionaCondicion: string
    seleccionaBloqueos: string
    producto:string
    monto:string
    fiadoLabel:string
    limitAmount: string
    email:string
    fecha: string
    motivo: string
    regresar: string
    continuar: string
    leyenda: string
    aceptaTerminos: string
    leyenda2: string
    finalizar: string
    rechazar: string
    autorizar: string
    operacionExitosa: string
    aceptar: string
    enviar: string
    compromiso: string
    aceptacion: string
    revision: string
    dictamen: string
    redactaDictamen: string
    nombreDestinatario: string
    correoDestinatario: string
    preguntaAutoriza: string
    preguntaRevertirCumplimiento: string
    preguntaCumplimiento: string
    preguntaRechaza: string
    preguntaConfirmaCumplido: string
    seleccionaFecha: string
    notificacionResponsableOficina: string
    notificacionResponsableOficina2: string
    cancelar: string
    nombreDocumentoCreacion: string
    leyenda3: string
    leyenda4: string
    continuarCodigo: string
    usuario: string

    //DETALLES
    nombreDocumentoDetalles: string
    nombreAgente: string
    nombreRepresentante: string
    sinDictamen: string

    //RENOVACION
    nombreDocumentoRenovacion: string
    nuevaFecha:string
    historial: string
    historialTituloModal1: string
    historialTituloModal2: string

    //REVOCACION
    nombreDocumentoRevocacion: string
    revocar: string
    preguntaConfirmaRevocacion: string

    //AUTORIZACION
    nombreDocumentoAutorizacion: string

    nombreDocumentoRevertirCumplimiento: string

    nombreDocumentoCumplimientoConcepto: string

    marcarCumplido: string
    revertirConcepto: string
    selectOption: string;
    
}

export const CreateEngagmentsLanguageEnglish: CreateEngagemetLanguageModel ={
    
    
    titulo: 'Create Compromise', 
    tipoLabel: 'Type',
    seleccionaTipo: 'Select a Type of Compromise',
    condicionesLabel: 'Authorization Conditions',
    bloqueosLabel: 'Active Blocks', 
    seleccionaCondicion: 'Select a Condition',
    seleccionaBloqueos: 'Select a Blocks',
    producto: 'Product',
    monto: 'Amount',
    fiadoLabel: 'Principals Compromise',
    email: 'Principal E-mail',
    fecha: 'Due Date',
    motivo: 'Subject',
    regresar: 'Back',
    continuar: 'Continue',
    leyenda: '',
    aceptaTerminos: 'Accept Terms and Conditions',
    leyenda2: 'E-mail with the request has been sent to the Principal',
    finalizar: 'Finish',
    rechazar: 'Reject',
    autorizar: 'Authorize',
    operacionExitosa:'Valid Transaction',
    aceptar: 'OK', 
    cancelar: 'Cancel',
    compromiso: 'Compromise',
    aceptacion: 'Acceptance',
    revision: 'Review',
    dictamen: 'Resolution',
    redactaDictamen: 'Resolution Description',
    nombreDocumentoDetalles: 'Compromise Detail',
    nombreDocumentoRenovacion: 'Compromise Renewal',
    nombreDocumentoRevocacion: 'Compromise Revocation',
    nombreDocumentoCreacion: 'Create Compromise',
    nombreDocumentoAutorizacion: 'Authorize Compromise',
    nombreAgente: 'Agents Name',
    nombreRepresentante: 'Principals Name',
    sinDictamen: 'This Compromise is still waiting for a resolution',
    nuevaFecha: 'New Due Date',
    historial: 'Historical',
    historialTituloModal1: 'Principals History',
    historialTituloModal2: 'Compromise History',
    nombreDestinatario: 'To:',
    correoDestinatario: 'Email Address:',
    preguntaAutoriza: 'Are you sure you want to authorize the compromise?',
    preguntaRechaza: 'Are you sure you want to reject the compromise?',
    preguntaConfirmaCumplido: 'Are you sure you want to complete the compromise?',
    revocar: 'Revocation',
    preguntaConfirmaRevocacion: 'Are you sure you want to revoce the compromise?',
    leyenda3: '',
    leyenda4: 'I hereby agree that I will register pending information according with the deadline provided by the Surety. In case I do not fulfil the above obligation, I agree and recognize that the future issuance of bonds for my client will be suspended as long as I do not conclude the required register.',
    notificacionResponsableOficina: "Compromise number ",
    notificacionResponsableOficina2: " Please check with your Officer to authorize ",
    seleccionaFecha: "Please select a date ",
    nombreDocumentoRevertirCumplimiento: "Revert Check",
    nombreDocumentoCumplimientoConcepto: "Check Concept",
    preguntaCumplimiento: "Are you sure you want to check the concept?",
    marcarCumplido: "Checked",
    revertirConcepto: "Revert Checked",
    preguntaRevertirCumplimiento: "Are you sure you want to revert the concept",
    limitAmount: " ",
    continuarCodigo: "Next",
    enviar: "Send a new code",
    usuario: "Username",
    selectOption: 'Select an option '

}


export const CreateEngagmentsLanguageSpanish: CreateEngagemetLanguageModel ={
    
    
    titulo: 'Crear Compromiso',
    tipoLabel: 'Tipo',
    seleccionaTipo: 'Selecciona un Tipo de Compromiso',
    condicionesLabel: 'Condiciones de Autorizacion',
    bloqueosLabel: 'Bloqueos Activos',
    seleccionaCondicion: 'Selecciona una Condición',
    seleccionaBloqueos: 'Selecciona un Bloqueos',
    producto: 'Producto',
    monto: 'Monto',
    fiadoLabel: 'Compromiso con Fiado',
    email: 'Correo Electrónico',
    fecha: 'Fecha de Cumplimiento',
    motivo: 'Motivo',
    regresar: 'Regresar',
    continuar: 'Continuar',
    cancelar: 'Cancelar',
    leyenda: '',
    aceptaTerminos: 'Acepta Términos',
    leyenda2: 'Se ha enviado la solicitud de aceptación al fiado a su cuenta de correo',
    finalizar: 'Finalizar',
    rechazar: 'Rechazar',
    autorizar: 'Autorizar',
    operacionExitosa:'Operación Exitosa',
    aceptar: 'Aceptar',
    compromiso: 'Compromiso',
    aceptacion: 'Aceptación',
    revision: 'Revisión',
    dictamen: 'Dictamen',
    redactaDictamen: 'Redacta tu Dictamen',
    nombreDocumentoDetalles: 'Detalle de Compromiso',
    nombreDocumentoRenovacion: 'Renovación de Compromiso',
    nombreDocumentoRevocacion: 'Revocación de Compromiso',
    nombreDocumentoCreacion: 'Creacion de Compromiso',
    nombreDocumentoAutorizacion: 'Autorización de Compromiso',
    nombreAgente: 'Nombre del Agente',
    nombreRepresentante: 'Nombre del Representante',
    sinDictamen: 'El Compromiso aún no cuenta con Dictamen',
    nuevaFecha: 'Nueva Fecha de Compromiso',
    historial: 'Historial',
    historialTituloModal1: 'Historial del Cliente',
    historialTituloModal2: 'Historial del Compromiso',
    nombreDestinatario: 'Nombre',
    correoDestinatario: 'Correo Electrónico',
    preguntaAutoriza: '¿Estas seguro que deseas autorizar el compromiso?',
    preguntaRechaza: '¿Estas seguro que deseas rechazar el compromiso?',
    preguntaConfirmaCumplido: '¿Estas seguro que deseas marcar como cumplido el compromiso?',
    revocar: 'Revocar',
    preguntaConfirmaRevocacion: '¿Estas seguro que deseas revocar el compromiso?',
    leyenda3: 'El plazo del compromiso excede los 3 meses. Por favor espera su autorización',
    leyenda4: 'Acepto que realizaré el registro de la información pendiente en el plazo acordado con la Afianzadora. En el supuesto de que no se realice lo anterior acepto y reconozco que la emisión futura de pólizas a cargo de mi cliente quedará suspendida, hasta concluir con el registro en mención.',
    notificacionResponsableOficina: "Compromiso número ",
    notificacionResponsableOficina2: " Favor de contactar a su Responsable de Oficina para autorización ",
    seleccionaFecha: "Selecciona la fecha de cumplimiento",
    nombreDocumentoRevertirCumplimiento: "Revertir Cumplimiento",
    nombreDocumentoCumplimientoConcepto: "Cumplimiento de Concepto",
    preguntaCumplimiento: "¿Estas seguro que deseas marcar el concepto como cumplido?",
    marcarCumplido: "Marcar Cumplimiento",
    revertirConcepto: "Revertir Cumplimiento",
    preguntaRevertirCumplimiento: "¿Estas seguro que deseas revertir el cumplimiento del concepto?",
    limitAmount: "Excede el limite permitido",
    continuarCodigo: "Continuar",
    enviar: "Enviar un nuevo código",
    usuario: "Usuario",
    selectOption: 'Selecciona una opción'

}