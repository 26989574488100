import {Dispatch} from "redux";
import {
    SELECT_LANGUAGE_ENGLISH,
    SelectLanguageDispatchTypes,
    SELECT_LANGUAGE_SPANISH
} from "./SelectLanguageActionTypes";
import {languageOptions} from '../components/public/select-language/SelectLanguage';
import {languageObjectSpanish} from "./Spanish";
import {languageObjectEnglish} from "./English";

export const getLanguage = (idLanguage: number) => async (dispatch: Dispatch<SelectLanguageDispatchTypes>) => {

    if (idLanguage === languageOptions.spanish){
        dispatch({
            type: SELECT_LANGUAGE_SPANISH,
            payload: languageObjectSpanish
        })
    }
    if (idLanguage === languageOptions.english){
        dispatch({
            type: SELECT_LANGUAGE_ENGLISH,
            payload: languageObjectEnglish
        })
    }

};

