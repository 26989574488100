
import React, { ChangeEvent, Children, Component } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { DefaultStateI } from '../../../../reducer/LanguageReducer';
import { RootStore } from '../../../../Store';
import NavBar from '../../../../components/public/nav-bar/NavBar';
import Form from '@lmig/lmds-react/Form/Form';
import { LocalStorageClass } from '../../../../class/LocalStorageClass';
import Select from '@lmig/lmds-react/Form/Select/Select';
import SelectOption from '@lmig/lmds-react/Form/Select/SelectOption';
import { Alert, AlphanumericInput, Badge, Button, GridCol, GridRow, Heading, IconDownload, IconUpload, Modal, Show, Table, TableCell, TableRow } from '@lmig/lmds-react';
import './EditarRechazadas.scss'
import { BreadCumb } from '../../../../components/public/breadcumb/BreadCumb';
import { PipeLineIcon } from '../../../../components/public/pipeline-steps/PipelineIcon';
import Dropzone from 'react-dropzone';
import { Entidad } from '../../../../models/buro-credito/entidad';
import { AxiosError } from 'axios';
import * as getCreditbureau from "../../../../services/buro-credito/CreditbureauService"
import * as apiS3Services from "../../../../services/api-s3/ApiS3Services";
import { ConsultaBuro } from '../../../../models/buro-credito/ConsultaBuro';
import { DelegacionMunicipio, estado } from '../../../../models/buro-credito/cp';
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { Fisicas } from '../../../../models/buro-credito/Fisicas';
import { Morales } from '../../../../models/buro-credito/Morales';
import { PaginacionModel } from '../../../../models/public/PaginacionModel';
import { initPaginacion, loadPaginationList, validFilepdf, validRoles } from '../../../../class/CommonClass';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { SpinnerLoading } from '../../../../components/public/spinner-loading/SpinnerLoading';
import Autocomplete from 'react-autocomplete';
import { UserModel } from '../../../../models/public/UserModel';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { PDFMoralesAgent } from '../../../../components/buro-credito/PDFMoralesAgent';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import { GoChevronRight } from 'react-icons/go';
import { PDFFileAgent } from '../../../../components/buro-credito/PDFFileAgent';

export type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>;

interface CreatequeryProps {
    language: DefaultStateI
}


class EditarRechazadas extends Component<CreatequeryProps & RouteComponentProps, CreatequeryState> {

    public localStorageClass = new LocalStorageClass();
    public select = document.getElementById('Persona');
    public idAgente = "";
    public archivo = "";
    public initConsult: ConsultaBuro = {
        nombre: '',
        tipoEntidad: '',
        tipoPersona: '',
        rfc: '',
        segundoNombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        fechaNacimiento: '',
        nombreAgente: '',
        sexo: '',
        codigoPostal: '',
        calle: '',
        colonia: '',
        delegacionMunicipio: '',
        idDireccion: '',
        numexterior: '',
        folio: '',
        estado: '',
        estadoRepublica: '',
        numinterior: '',
        telefono: '',
        estadoDomicilio: '',
        razonSocial: '',
        representante: '',
        codigoBuro:''

    }
    options: any;
    valido: boolean | undefined;

    constructor(props: any) {
        super(props);
        const est: estado = {

            estado: "",
            codigoBuro:"",
            delegacionMunicipio: [
                {
                    nombre: "",
                    
                }
            ]
        };
        this.state = {
            estadoCaptura: 1,
            idWarranty: 0,
            loadingWarrantyDetail: false,
            errorWarrantyDetail: false,
            select: '',
            PERFIL: this.localStorageClass.getProfile(),
            status: '0',
            validador: 1,
            isOpen: true,
            isClose: false,
            pdfOpen:false,
            entidad: [],
            listFiadoOption: [],
            datovalid: '',
            consultObject: this.initConsult,
            cp: '',
            estados: est,
            valido: true,
            show: true,
            folio: '',
            datofisica: [],
            condicion: false,
            Consul_buro: false,
            datoMoral: [],
            mensajeF: '',
            mensajeM: '',
            Loading: true,
            formData: new FormData,
            FormFisicas: new FormData,
            validafile: 0,
            paginacion: initPaginacion,
            loadingConsult: true,
            listPaginacion: [],
            consulta: true,
            datoCaptura: [],
            datoConsul: [],
            search: '',
            tipopersona: '',
            table: 0,
            alerta: 0,
            value: '',
            alerta1: 0,
            alerta2: 0,
            boton: true,
            prueba:{
                apellidoPaterno: '',
                apellidoMaterno: '',
                calle:'',
                folio:''
            },
            variable: {
                folio: '',
                nombre: '',
                tipoEntidad: '',
                tipoPersona: '',
                rfc: '',
                estadoRepublica: '',
                segundoNombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                fechaNacimiento: '',
                sexo: '',
                colonia: '',
                idDireccion:'',
                codigoPostal: '',
                calle: '',
                estado: '',
                numexterior: '',
                delegacionMunicipio: '',
                numinterior: '',
                telefono: '',
                estadoDomicilio: '',
                razonSocial: '',
                representante: '',
                solicitante: '',
                codigoBuro: ''
            }
        }


        this.NextPage = this.NextPage.bind(this);
        this.PrevPage = this.PrevPage.bind(this);
        this.Return = this.Return.bind(this);
        //this.handleinputChange = this.handleinputChange.bind(this);
        this.seleccion = this.seleccion.bind(this);
        this.Submit = this.Submit.bind(this);
        this.Cerrar = this.Cerrar.bind(this);
        this.getpdf = this.getpdf.bind(this);
        this.statusChange = this.statusChange.bind(this);
        this.PdfVis = this.PdfVis.bind(this);
        this.getDatos = this.getDatos.bind(this);
    }

    render(): JSX.Element {
        return <>
           <NavBar />
            <div className="container-fluid warranty" id="create-query">
             
                <div className="create">

                    <BreadCumb title={this.props.language.language?.createquery.credittitle!}
                        listBreadcumbs={[
                            { title: this.props.language.language?.createquery.Home!, url: '/buro-credito/Solicitudes' },
                            { title: this.props.language.language?.createquery.Create!, url: '/buro-credito/Solicitudes/Editar_consulta' }
                        ]} />

                    <div className="row" id="header-create">

                        <div className="container">

                            <div className="Pipeline col-md-12 col-sm-12" id='pipeline'>
                                <PipeLineIcon
                                    listSteps={[this.props.language.language?.createquery.pipeline1!,
                                    this.props.language.language?.createquery.pipeline2!,
                                    this.props.language.language?.createquery.pipeline4!]}
                                    currentStep={this.state.estadoCaptura! - 1} />


                            </div>
                        </div>


                        <div className="Formulario" id='formulario'>



                            <Form id="form-query" >
                                <div className="col-md-12" id='consult'>
                                    {this.state.estadoCaptura == 1 ? (
                                        <>
                                            <div className="col-md-12" id='consult'>
                                                <h3>{[this.props.language.language?.createquery.data!]}</h3>
                                                <hr />

                                                <div className='col-12'>
                                                    <p>{[this.props.language.language?.createquery.entity!]}</p>
                                                    <Select
                                                        id="select--default"
                                                        isRequired
                                                        placeholderOption="Prueba"
                                                        labelVisual={`${this.state.variable.tipoEntidad} `}
                                                        disabled

                                                    >

                                                    </Select>
                                                    <br />
                                                    <p>{[this.props.language.language?.createquery.person!]}</p>
                                                    <Select
                                                        id="Persona"
                                                        name='persona'
                                                        isRequired
                                                        labelVisual={this.state.variable.tipoPersona}
                                                        disabled

                                                    >


                                                    </Select>
                                                </div>
                                                <br />
                                                <>

                                                    <div className="col-md-12" id='consult'>
                                                        <h3>{[this.props.language.language?.createquery.persondata!]}</h3>
                                                        <hr />


                                                        <div className='col-12'>
                                                            {this.state.variable.tipoPersona == 'Fisica' &&
                                                                <>
                                                                    <p>RFC</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual
                                                                        name='rfc'
                                                                        value={this.state.variable.rfc}
                                                                        onChange={this.handleinputChange}
                                                                        onBlur={this.handleinputChange} />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.name!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual
                                                                        name='nombre'
                                                                        value={this.state.variable.nombre}
                                                                        onChange={this.handleinputChange} />


                                                                    <p>{[this.props.language.language?.createquery.second!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        name='segundoNombre'
                                                                        labelVisual
                                                                        value={this.state.variable.segundoNombre}
                                                                        onChange={this.handleinputChange} />

                                                                    <p>{[this.props.language.language?.createquery.last!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        name='apellidoPaterno'
                                                                        labelVisual
                                                                        value={this.state.variable.apellidoPaterno}
                                                                        onChange={this.handleinputChange} />

                                                                    <p>{[this.props.language.language?.createquery.mother!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        name='apellidoMaterno'
                                                                        labelVisual
                                                                        value={this.state.variable.apellidoMaterno}
                                                                        onChange={this.handleinputChange} />

                                                                    <p>{[this.props.language.language?.createquery.date!]}</p>
                                                                    <input
                                                                        type='date'
                                                                        id="fechaNacimiento"
                                                                        name='fechaNacimiento'
                                                                        className='form-control'
                                                                        min="1936-01-01" max="2005-12-31"
                                                                        value={this.state.variable.fechaNacimiento}
                                                                        onChange={this.handleinputChange}
                                                                        onBlur={this.handleinputChange}

                                                                    />


                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.telefono!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual
                                                                        name='telefono'
                                                                        value={this.state.variable.telefono}
                                                                        onChange={this.handleinputChange} />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.sex!]}</p>
                                                                    <Select
                                                                        id="sexo"
                                                                        name='sexo'
                                                                        labelVisual={this.props.language.language?.createquery.select!}
                                                                        value={this.state.variable.sexo}
                                                                        onChange={this.handleinputChange}
                                                                        onBlur={this.handleinputChange}
                                                                    >
                                                                        <SelectOption value="M">
                                                                            Hombre
                                                                        </SelectOption>
                                                                        <SelectOption value="F">
                                                                            Mujer
                                                                        </SelectOption>
                                                                    </Select>
                                                                </>}

                                                            {this.state.variable.tipoPersona == 'Moral' &&
                                                                <>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        name='rfc'
                                                                        labelVisual
                                                                        value={this.state.variable.rfc}
                                                                        onChange={this.handleinputChange} />
                                                                    <br />

                                                                    <p>{[this.props.language.language?.createquery.razonsocial!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        name='razonSocial'
                                                                        labelVisual
                                                                        value={this.state.variable.razonSocial}
                                                                        onChange={this.handleinputChange}
                                                                    />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.representante!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        name='representante'
                                                                        labelVisual
                                                                        value={this.state.variable.representante}
                                                                        onChange={this.handleinputChange} />
                                                                    <br />
                                                                    <p>RFC</p>


                                                                    <p>{[this.props.language.language?.createquery.telefono!]}</p>
                                                                    <AlphanumericInput
                                                                        name='telefono'
                                                                        id="Alphanumeric--default"
                                                                        labelVisual
                                                                        value={this.state.variable.telefono}
                                                                        onChange={this.handleinputChange} />

                                                                </>}
                                                        </div>
                                                    </div><br /><div className="col-md-12" id='consult'>
                                                        <h3>{[this.props.language.language?.createquery.residence!]}</h3>
                                                        <hr />


                                                        <div className='col-12'>

                                                            <p>C.P</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='codigoPostal'
                                                                labelVisual
                                                                value={this.state.variable.codigoPostal}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} />




                                                            <p>{[this.props.language.language?.createquery.state!]}</p>

                                                            <input
                                                                id="estadoDomicilio"
                                                                name='estadoDomicilio'
                                                                className='form-control'
                                                                onChange={this.handleinputChange}
                                                                placeholder={this.state.variable.estadoDomicilio}
                                                                value={this.state.estados?.estado}
                                                                disabled />


                                                            <p>{[this.props.language.language?.createquery.town!]}</p>
                                                            <Select
                                                                id="delegacionMunicipio"
                                                                name='delegacionMunicipio'
                                                                onChange={this.handleinputChange}
                                                                labelVisual
                                                                value={this.state.variable.delegacionMunicipio}

                                                            >

                                                                <SelectOption value="0">
                                                                    {this.state.variable.delegacionMunicipio}
                                                                </SelectOption>
                                                                {this.state.estados.delegacionMunicipio.map((i: DelegacionMunicipio, index: number) => (
                                                                    <SelectOption value={i.nombre.toString()} key={index}>
                                                                        {i.nombre}
                                                                    </SelectOption>
                                                                ))}


                                                            </Select>

                                                           
                                                            <p>{[this.props.language.language?.createquery.street!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='calle'
                                                                labelVisual
                                                                value={this.state.variable.calle}
                                                                onChange={this.handleinputChange} />

                                                            <p>{[this.props.language.language?.createquery.number!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='numexterior'
                                                                labelVisual
                                                                value={this.state.variable.numexterior}
                                                                onChange={this.handleinputChange} />

                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.numint!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='numinterior'
                                                                labelVisual
                                                                value={this.state.variable.numinterior}
                                                                onChange={this.handleinputChange} />
                                                            <GridRow gutterSize="fixed-16" gutters>

                                                                <GridCol base={12} md={12}>
                                                                    <Button dynamicWidth variant="primary" onClick={this.NextPage}>
                                                                        {this.props.language.language?.createquery.NextPage!}
                                                                    </Button>
                                                                </GridCol>
                                                            </GridRow>
                                                        </div>

                                                    </div></>

                                            </div>



                                        </>) : null}
                                    {this.state.estadoCaptura == 2 ? (
                                        <>
                                            <div className="row">
                                                <div className="col-md-6" >
                                                    <div className="card" id='cards'>
                                                        <div className="card-body text-center">
                                                            <h3 className="card-title">{this.props.language.language?.createquery.format!}</h3><br /><br />
                                                            {this.state.consultObject.tipoPersona == 'Fisica' &&
                                                                <>
                                                                    <div className="icon-wrapper">
                                                                        {this.state.condicion == true &&
                                                                            <>
                                                                                <PDFDownloadLink document={<PDFFileAgent />} fileName="AutorizacionparasolicitarInformacionCrediticia">
                                                                                    {({ loading }) =>
                                                                                        loading ? (
                                                                                            ''
                                                                                        ) : (
                                                                                            <IconDownload size="64" color='gray' />
                                                                                        )
                                                                                    }
                                                                                </PDFDownloadLink>
                                                                            </>}
                                                                    </div>
                                                                </>}

                                                            {this.state.consultObject.tipoPersona == 'Moral' &&
                                                                <>
                                                                    <div className="icon-wrapper">
                                                                        {this.state.condicion == true &&
                                                                            <>
                                                                                <PDFDownloadLink document={<PDFMoralesAgent />} fileName="AutorizacionparasolicitarInformacionCrediticia">
                                                                                    {({ loading }) =>
                                                                                        loading ? (
                                                                                            ''
                                                                                        ) : (
                                                                                            <IconDownload size="64" color='gray' />
                                                                                        )
                                                                                    }
                                                                                </PDFDownloadLink>
                                                                            </>}
                                                                    </div>
                                                                </>}
                                                            {this.props.language.language?.createquery.downl!}
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card" id='cards'>
                                                        <div className="file-upload" id='image-upload-wrap'>

                                                            <input
                                                                type="file"
                                                                id='file-upload-input'
                                                                accept='application/pdf'
                                                                onChange={this.handleChange} />
                                                            <div className="card-body text-center">
                                                                <h3 className="card-title">{this.props.language.language?.createquery.auto!}</h3> <br /><br />

                                                                <div className="text-information">
                                                                    <IconUpload size="64" color='gray' /><br />
                                                                    {this.props.language.language?.createquery.arrastra!} <br />
                                                                    {this.props.language.language?.createquery.file!}

                                                                    {this.state.consulta == false &&
                                                                        <div className='col-12' id='alert'>
                                                                            <Alert id='alert_up' highlightType="positive">
                                                                                {this.props.language.language?.createquery.alert_up!}
                                                                            </Alert>
                                                                        </div>}
                                                                </div>
                                                            </div><br />
                                                            <Button className='btn-archivo' size="small" variant="primary" onClick={this.PdfVis} hidden={this.state.show}>
                                                                Visualizar archivo
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <Modal isOpen={this.state.pdfOpen} size="large"
                                                        className='Modal-pdf'
                                                        closeFunc={() => {
                                                            this.setState({ pdfOpen: false });
                                                        }}
                                                    >

                                                        <iframe className="iframe-pdf" src={this.archivo} allowFullScreen ></iframe>


                                                    </Modal>
                                                </div>
                                            </div>

                                            <br /><br />
                                            <GridRow gutterSize="fixed-16" gutters>
                                                <GridCol base={12} md={6}>
                                                    <Button dynamicWidth onClick={this.PrevPage}>
                                                        {this.props.language.language?.createquery.PrevPage!}
                                                    </Button>
                                                </GridCol>
                                                <GridCol base={12} md={6}>
                                                    <Button dynamicWidth variant="primary" onClick={this.Submit} disabled={this.state.consulta} >
                                                        {this.props.language.language?.createquery.NextPage!}
                                                    </Button>
                                                </GridCol>
                                            </GridRow>
                                        </>

                                    ) : null}
                                    {this.state.estadoCaptura == 3 ? (
                                        <>

                                            <div className="col-12" >
                                                <div className="card" id='cards'>
                                                    <div className="card-body text-center"><br /><br />
                                                        <br />
                                                        <div className="icon-wrapper">
                                                            <BsFillCheckCircleFill size="64" color='green' /><br /><br />
                                                        </div>
                                                        <h4 className="card-title">Datos enviados a revision</h4>

                                                        <br />
                                                    </div>
                                                </div>
                                            </div>

                                            <br /><br />
                                            <div className="boton" >
                                                <GridRow gutterSize="fixed-16" gutters>
                                                    <GridCol base={12} md={6}>
                                                        <Button dynamicWidth onClick={this.PrevPage}>
                                                            {this.props.language.language?.createquery.PrevPage!}
                                                        </Button>
                                                    </GridCol>
                                                    <GridCol base={12} md={6}>
                                                        <Button dynamicWidth variant="primary" onClick={this.Return}>
                                                            {this.props.language.language?.createquery.finalize!}
                                                        </Button>
                                                    </GridCol>
                                                </GridRow>

                                            </div>


                                        </>

                                    ) : null}
                                </div>
                            </Form>



                        </div>

                    </div>
                </div>
            </div >
        </>


    }




    saveWarranty() {
        throw new Error('Method not implemented.');

    }

    public Submit(e: InputChange) {
        e.preventDefault();

        const { estadoCaptura } = this.state
        this.setState({ estadoCaptura: estadoCaptura + 1 })
        this.postFileValidacion(this.state.formData);
        console.log(this.state.consultObject)
    }

    public NextPage() {
        const { estadoCaptura } = this.state
        this.postActualizar();
        this.setState({status:'0'})
        console.log('estatus para pendientes'+ this.state.status)
        this.setState({ estadoCaptura: estadoCaptura + 1 })
    }

    public PrevPage() {
        const { estadoCaptura } = this.state

        this.setState({ estadoCaptura: estadoCaptura - 1 })

    }

    public Return() {
        this.props.history.push('/buro-credito/Solicitudes');
    }




    componentDidMount() {
        this.getCredit();
        this.getDatos();
    }







    renderFiado(state: { nombre: string }, value: string) {
        const nombreCompleto = state.nombre
        return (
            (nombreCompleto.toLowerCase().indexOf(value.toLowerCase()) !== -1)

        );
    }

    Cerrar() {
        this.setState({ isOpen: false })

    }

    public PdfVis() {
        this.setState({ pdfOpen: true })

    }

    public handleinputChange = async (e: InputChange,) => {
        e.persist();
        await this.setState(preveState => ({
            variable: { ...this.state.variable, [e.target.name]: e.target.value }

        }))
        this.state.variable.solicitante = this.state.PERFIL.username;

        console.log(this.state.variable)
        this.getEstado();
    }
    public seleccion(folio: string) {
        this.setState({
            variable: {
                folio: this.state.variable.folio,
                nombre: this.state.variable.nombre,
                tipoEntidad: this.state.variable.nombre,
                tipoPersona: this.state.variable.nombre,
                rfc: this.state.variable.nombre,
                estadoRepublica: this.state.variable.estadoRepublica,
                segundoNombre: this.state.variable.segundoNombre,
                apellidoPaterno: this.state.variable.apellidoPaterno,
                apellidoMaterno: this.state.variable.apellidoMaterno,
                fechaNacimiento: this.state.variable.fechaNacimiento,
                sexo: this.state.variable.sexo,
                idDireccion: this.state.variable.colonia,
                codigoPostal: this.state.variable.codigoPostal,
                calle: this.state.variable.calle,
                colonia:this.state.variable.colonia,
                estado: this.state.variable.estado,
                numexterior: this.state.variable.numexterior,
                delegacionMunicipio: this.state.variable.delegacionMunicipio,
                numinterior: this.state.variable.numinterior,
                telefono: this.state.variable.telefono,
                estadoDomicilio: this.state.variable.estadoDomicilio,
                razonSocial: this.state.variable.razonSocial,
                representante: this.state.variable.representante,
                codigoBuro:this.state.variable.codigoBuro


            }
        })
        
        

    }

    public async postActualizar() {
        try {
            delete this.state.variable.comentario;
            this.state.variable.tipoEntidad = '1';
            this.state.variable.tipoPersona = '1';
            this.state.variable.idDireccion = this.state.variable.colonia;
            console.log(this.state.variable)
            const res = await getCreditbureau.putActualizar(this.state.variable)
            this.alert();
            this.setState({status:'0'})
            console.log(this.state.status + 'estutos cuando falta archivo')
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
        this.statusChange();
        this.getpdf();
    }

    public async getEstado() {
        try {

            const res = await getCreditbureau.getestado(this.state.variable.codigoPostal);
            if (res.data?.estado) {
                this.setState({ estados: res.data })
                console.log(this.state.estados)
            }



        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    public getDatos(): void {

        var storedata = localStorage.getItem('FolioConsulta');
        if (storedata == null) {
            console.log('vacio')
        } else {
            var data = storedata.replace(/['"]+/g, '')
            this.setState({ folio: data })


        }
    }

    public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (validFilepdf(e)) {
            const formDataAuxiliar = this.state.formData
            formDataAuxiliar.append('archivo', e.target.files![0]);
            formDataAuxiliar.append('carpeta', 'buro');
            formDataAuxiliar.append('nombreArchivo', this.state.folio + '.pdf');
            this.setState({ formData: formDataAuxiliar })
            this.setState({ consulta: false })

            this.setState({ show: false })
            var prueba = (window.URL).createObjectURL(e.target.files![0])
            console.log(prueba)
            this.archivo = prueba;

        } else {
        }
        console.log(this.state.folio)


    }

    public async postFileValidacion(formData: FormData) {

        try {
            this.setState({status:'1'})
            console.log(this.state.status + 'estutos cuando suben archivo')
            const res = await apiS3Services.postFile(formData)
            const arrayFile = res.data
            console.log(arrayFile);
            this.setState({ status: '1' })


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                this.setState({ status: '0' })
            }
        }
        this.statusChange();
    }

    public async statusChange() {
       

       console.log('antes de enviar archivo'+ this.state.status)
        var storedata = localStorage.getItem('FolioConsulta');
        if (storedata == null) {
            console.log('vacio')
        } else {
            var data = storedata.replace(/['"]+/g, '')
            var dt = data.toString();
            const res = await getCreditbureau.putStatusAgent(this.state.status, dt)

        }
    }


    async getCredit(): Promise<void> {
        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                const res = await getCreditbureau.getConsul(dt);
                const dat = Object.values(res.data)
                console.log(dat)
                if (dat.length == 0) {
                    this.setState({ condicion: false })
                } else {

                    this.setState({ condicion: true })
                    this.setState({ variable: res.data })
                }
            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }


    async getpdf(): Promise<void> {
        try {
            
               
                const res = await getCreditbureau.getConsul(this.state.variable.folio!);
                const dat = Object.values(res.data)
                console.log(dat)
                if (dat.length == 0) {
                    this.setState({ condicion: false })
                } else {

                    this.setState({ condicion: true })
                    this.setState({ consultObject: res.data })
                }
            

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }








    public alert() {
        Swal.fire(
            'Datos guardados correctamente!',
            '',
            'success'
        )
    }














}



const mapStateToProps = (state: RootStore) => ({
    language: state.language
});

export default connect(mapStateToProps)(EditarRechazadas);


interface CreatequeryState {

    estadoCaptura: number;
    idWarranty: number,
    loadingWarrantyDetail: boolean,
    errorWarrantyDetail: boolean,
    select: string,
    status: string,
    validador: number,
    entidad: Entidad[],
    datovalid: string,
    consultObject: ConsultaBuro,
    cp: string,
    estados: estado,
    valido: boolean,
    FormFisicas: FormData,
    show: boolean,
    datofisica: Fisicas[],
    datoMoral: Morales[],
    Loading: boolean,
    formData: FormData,
    validafile: number,
    paginacion: PaginacionModel,
    loadingConsult: boolean,
    listPaginacion: number[],
    consulta: boolean,
    folio: string,
    datoCaptura: ConsultaBuro[],
    datoConsul: ConsultaBuro[],
    search: string,
    tipopersona: string,
    alerta: number,
    alerta1: number,
    alerta2: number,
    boton: boolean,
    variable: ConsultaBuro,
    Consul_buro: Boolean,
    table: number,
    mensajeF: string,
    mensajeM: string,
    condicion: boolean,
    value: string,
    PERFIL: UserModel,
    listFiadoOption: FiadoOption[],
    isOpen: boolean,
    isClose: boolean,
    prueba:{},
    pdfOpen:boolean


}
interface FiadoOption {
    nombre: string;
    rfc: string;
}


function setProductName(value: any) {
    throw new Error('Function not implemented.');
}

function searchProducts(value: any) {
    throw new Error('Function not implemented.');
}

