import React, { Component } from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import {
  Select,
  SelectOption,
  TableCell,
  TableRow,
  Table,
  Checkbox,
  Button,
  IconInfo,
} from "@lmig/lmds-react";
import "../warranty/Warranty.scss";
import "./WarrantyHistory.scss";
import NavBar from "../../../components/public/nav-bar/NavBar";
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import {
  formatCurrency,
  formatValue,
  initPaginacion,
  InputChange,
  loadPaginationList, validRoles,
} from "../../../class/CommonClass";
import { UserModel } from "../../../models/public/UserModel";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import * as apiService from "../../../services/garantias/GarantiasService";
import { AxiosError } from "axios";
import { ExpedientIdentification } from "../../../components/garantias/expedient-identification/ExpedientIdentification";
import { InfoPrincipal } from "../../../components/garantias/info-principal/InfoPrincipal";
import { BreadCumbPrev } from "../../../components/public/breadcumb-prev/BreadCumbPrev";
import { Pagination } from "react-bootstrap";
import { HistorialModel } from "../../../models/garantias/HistorialModel";
import { PaginacionModel } from "../../../models/public/PaginacionModel";
import { PillButton } from "@lmig/lmds-react-pill-button";
import { SpinnerLoading } from "../../../components/public/spinner-loading/SpinnerLoading";
import ReactPaginate from "react-paginate";

interface WarrantyHistoryProps {
  language: DefaultStateI;
}

class WarrantyHistory extends Component<
  WarrantyHistoryProps & RouteComponentProps,
  WarrantyHistoryState
> {
  public localStorageClass = new LocalStorageClass();

  constructor(props: any) {
    super(props);

    document.title = "Historial Garantía ";
    this.state = {
      idFiado: 0,
      idFolio: 0,
      PERFIL: this.localStorageClass.getProfile(),
      listHistorial: [],
      selectedCheckboxes: [],
      paginacion: initPaginacion,
      listPaginacion: [],
      loadingHistory: false,
    };
  }

  render() {
    return (
      <>
          <NavBar />
        <div className="container-fluid" id="warranty-history">
      
          <div className="">
            <BreadCumbPrev
              title={
                this.props.language.language?.WarrantyHistoryLanguage.title!
              }
            />
            <div className="row" id="header-history">
              <div className="col-md-12 " id="seccion-warranty-history">
                <div className="row">
                  <div className="col-md-5">
                    <div className="row">
                      {(validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) ||
                          validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR)) &&
                      validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR) ?
                          <></> :
                          <div className="col-md-4">
                            <ExpedientIdentification
                                idPrincipal={this.state.idFiado}
                                showButtons={true}
                            />
                          </div>
                      }

                      <div className="col-md-8 ">
                        <InfoPrincipal
                          idPrincipal={this.state.idFiado}
                          label={
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.loan!
                          }
                          openObligors={false}
                          addOS={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5"></div>
                  <div className="col-md-2"></div>
                  <div className="col-md-12">
                    <br />
                    <h5>
                      {
                        this.props.language.language?.WarrantyHistoryLanguage
                          .title!
                      }{" "}
                      {this.state.idFolio}
                    </h5>
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-2 ">
                <Select
                  isRequired
                  name="nEntries"
                  labelA11y=""
                  labelVisual={
                    this.props.language.language?.WarrantyHistoryLanguage
                      .show! +
                    " " +
                    String(this.state.paginacion.size) +
                    " " +
                    this.props.language.language?.WarrantyHistoryLanguage
                      .records!
                  }
                  value={String(this.state.paginacion.size)}
                  onChange={(e) => this.handleInputChangeSelect(e)}
                  placeholderOption=""
                >
                  <SelectOption value="10">10</SelectOption>
                  <SelectOption value="25">25</SelectOption>
                  <SelectOption value="50">50</SelectOption>
                  <SelectOption value="100">100</SelectOption>
                </Select>
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-lg-10 ">
                <PillButton
                  size="small"
                  className="float-right"
                  variant="primary"
                  disabled={this.state.selectedCheckboxes.length < 2}
                  onClick={() => {
                    this.warrantieComparison();
                  }}
                >
                  {
                    this.props.language.language?.WarrantyHistoryLanguage
                      .compare!
                  }
                </PillButton>
              </div>
              {this.state.loadingHistory ? (
                <div className="col-12">
                  <SpinnerLoading text={this.props.language.language?.WarrantyHistoryLanguage
                      .loadingHistory!} />
                </div>
              ) : (
                <div className="col-md-12 ">
                  {/*data table*/}
                  <Table className="">
                    <thead>
                      <TableRow className="text-center">
                        <TableCell
                          dataHeader={
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.version
                          }
                          type="colHead"
                        >
                          {
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.version
                          }
                        </TableCell>
                        <TableCell
                          dataHeader={
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.warranty
                          }
                          type="colHead"
                        >
                          {
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.warranty
                          }
                        </TableCell>
                        <TableCell
                          dataHeader={
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.type
                          }
                          type="colHead"
                        >
                          {
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.type
                          }
                        </TableCell>
                        <TableCell
                          dataHeader={
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.amount
                          }
                          type="colHead"
                        >
                          {
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.amount
                          }
                        </TableCell>
                        <TableCell
                          dataHeader={
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.dateCapture
                          }
                          type="colHead"
                        >
                          {
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.dateCapture
                          }
                        </TableCell>
                        <TableCell
                          dataHeader={
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.validity
                          }
                          type="colHead"
                        >
                          {
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.validity
                          }
                        </TableCell>
                        <TableCell
                          dataHeader={
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.captured
                          }
                          type="colHead"
                        >
                          {
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.captured
                          }
                        </TableCell>
                        <TableCell
                          dataHeader={
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.authorized
                          }
                          type="colHead"
                        >
                          {
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.authorized
                          }
                        </TableCell>
                        <TableCell
                          dataHeader={
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.changes
                          }
                          type="colHead"
                        >
                          {
                            this.props.language.language
                              ?.WarrantyHistoryLanguage.changes
                          }
                        </TableCell>
                        <TableCell
                          dataHeader="Action"
                          type="colHead"
                        ></TableCell>
                      </TableRow>
                    </thead>
                    <tbody>
                      {this.state.listHistorial.map((i, index) => (
                        <TableRow key={i.idGarantia}>
                          <TableCell
                            dataHeader={
                              this.props.language.language
                                ?.WarrantyHistoryLanguage.version
                            }
                            type="rowHead"
                          >
                            {
                              <Checkbox
                                id={`checkbox-${index}`}
                                labelVisual={`${i.folio}-${i.version}`}
                                value={`${i.folio}`}
                                onChange={() =>
                                  this.handleCheckboxChange(i.idGarantia)
                                }
                                checked={this.state.selectedCheckboxes.includes(
                                  i.idGarantia
                                )}
                              />
                            }
                          </TableCell>
                          <TableCell
                            dataHeader={
                              this.props.language.language
                                ?.WarrantyHistoryLanguage.warranty
                            }
                          >
                            {i.folio}
                          </TableCell>
                          <TableCell
                            dataHeader={
                              this.props.language.language
                                ?.WarrantyHistoryLanguage.type
                            }
                          >
                            {i.tipoUso}
                          </TableCell>
                          <TableCell
                            dataHeader={
                              this.props.language.language
                                ?.WarrantyHistoryLanguage.amount
                            }
                          >
                            {formatCurrency(i.monto)}
                          </TableCell>
                          <TableCell
                            dataHeader={
                              this.props.language.language
                                ?.WarrantyHistoryLanguage.dateCapture
                            }
                          >
                            {i.fechaCaptura}
                          </TableCell>
                          <TableCell
                            dataHeader={
                              this.props.language.language
                                ?.WarrantyHistoryLanguage.validity
                            }
                          >
                            {i.fechaInicial} <br /> {i.fechaFinal}
                          </TableCell>
                          <TableCell
                            dataHeader={
                              this.props.language.language
                                ?.WarrantyHistoryLanguage.captured
                            }
                          >
                            {i.usuarioCapturo}
                          </TableCell>
                          <TableCell
                            dataHeader={
                              this.props.language.language
                                ?.WarrantyHistoryLanguage.authorized
                            }
                          >
                            {i.usuarioAutorizo}
                          </TableCell>
                          <TableCell
                            dataHeader={
                              this.props.language.language
                                ?.WarrantyHistoryLanguage.changes
                            }
                          >
                            {i.cambios}
                          </TableCell>
                          <TableCell dataHeader="Action">

                            <Link
                                to={"/garantias/revision/fiado/"+ this.state.idFiado+"/garantia/" +i.idGarantia+"?read=true"}>
                              <Button
                                  size={"small"} variant={"primary"}>
                                  <IconInfo
                                      className=""
                                      title="Add"
                                      size={"16"}
                                  />
                              </Button>
                            </Link>




                          </TableCell>
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
            {!this.state.loadingHistory &&
            this.state.listHistorial.length > 0 ? (
              <div className="row pagination-sdgf">
                <div className="col-md-5 show-registers">
                  {
                    this.props.language.language?.WarrantyHistoryLanguage
                      .showing
                  }
                  &nbsp;
                  {this.state.paginacion.currentPage *
                    this.state.paginacion.size -
                    (this.state.paginacion.size - 1)}{" "}
                  {
                    this.props.language.language?.WarrantyHistoryLanguage
                      .showingTo
                  }
                  &nbsp;
                  {this.state.paginacion.currentPage *
                    this.state.paginacion.size}{" "}
                  {
                    this.props.language.language?.WarrantyHistoryLanguage
                      .showingTotal
                  }
                  &nbsp;
                  {this.state.paginacion.totalItems}{" "}
                  {
                    this.props.language.language?.WarrantyHistoryLanguage
                      .records
                  }
                </div>
                <div className="col-md-7 ">
                  <Pagination
                    className="float-md-right"
                    id="pagination-table-warranty"
                  >
                    <Pagination.Prev
                      onClick={() => {
                        this.clickGoToPage(this.state.listPaginacion[0] - 1);
                      }}
                    />
                    {this.state.listPaginacion.map((i, index) => (
                      <Pagination.Item
                        key={i}
                        active={i === this.state.paginacion.currentPage}
                        onClick={() => {
                          this.clickGoToPage(i);
                        }}
                      >
                        {i}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => {
                        this.clickGoToPage(
                          this.state.listPaginacion[
                            this.state.listPaginacion.length - 1
                          ] + 1
                        );
                      }}
                    />
                  </Pagination>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);
    const idFiado = urlParams.get("fiado");
    const idFolio = urlParams.get("folio");
    if (idFiado !== null && idFolio !== null) {
      this.setState({
        idFiado: parseInt(idFiado),
        idFolio: parseInt(idFolio),
      });
      this.getHistorial(parseInt(idFolio), 1, 10);
    }
  }

  handleCheckboxChange = (id: number) => {
    const { selectedCheckboxes } = this.state;

    if (selectedCheckboxes.includes(id)) {
      // Si la casilla ya está seleccionada, la deseleccionamos
      this.setState({
        selectedCheckboxes: selectedCheckboxes.filter(
          (idChec) => idChec !== id
        ),
      });
    } else if (selectedCheckboxes.length < 2) {
      // Si aún no se han seleccionado dos casillas, la seleccionamos
      this.setState({
        selectedCheckboxes: [...selectedCheckboxes, id],
      });
    }
  };
  handleInputChangeSelect(e: InputChange) {
    var auxPaginacion = this.state.paginacion;
    auxPaginacion.size = formatValue(e.target.value);
    auxPaginacion.currentPage = 1;
    this.setState({
      paginacion: auxPaginacion,
    });
    this.getHistorial(this.state.idFolio, 1, auxPaginacion.size);
  }

  async getHistorial(folio: number, page: number, size: number) {
    this.setState({
      loadingHistory: true,
    });
    try {
      const response = await apiService.getHistorial(folio, page, size);
      response.data.paginacion.size = this.state.paginacion.size;
      this.setState({
        listHistorial: response.data.elementos,
        paginacion: response.data.paginacion,
        loadingHistory: false,
        listPaginacion: loadPaginationList(response.data.paginacion),
      });
      console.log(response.data.paginacion.size);
    } catch (e) {
      this.setState({
        listHistorial: [],
        loadingHistory: false,
      });
      const error = e as AxiosError;

      if (error.response) {
        console.log(error.response.status);
        console.log(error.response.data);
      }
    }
  }

  warrantieComparison() {
    const selectedCheckboxes = this.state.selectedCheckboxes;
    this.setState({
      selectedCheckboxes: [],
    });
    window.open(
      `/garantias/historial/comparacion?idFolio=${this.state.idFolio}&garantia1=${selectedCheckboxes[0]}&garantia2=${selectedCheckboxes[1]}`,
      "_self"
    );
  }
  clickGoToPage(page: number) {
    const totalPages: number = this.state.paginacion.totalPages;
    if (
      page >= 1 &&
      page <= totalPages &&
      page !== this.state.paginacion.currentPage
    ) {
      const auxPaginacion = this.state.paginacion;
      auxPaginacion.currentPage = page;
      this.setState({
        paginacion: auxPaginacion,
      });
      this.getHistorial(this.state.idFiado, page, this.state.paginacion.size);
    }
  }


}

interface WarrantyHistoryState {
  idFiado: number;
  idFolio: number;
  PERFIL: UserModel;
  listHistorial: HistorialModel[];
  selectedCheckboxes: number[];
  paginacion: PaginacionModel;
  listPaginacion: number[];
  loadingHistory: boolean;
}
const mapStateToProps = (state: RootStore) => ({
  language: state.language,
});

export default connect(mapStateToProps)(WarrantyHistory);
