export interface ComentariosFiadoLanguage {
    writeComment: string;
    send: string;
    sending: String;
    noComment: string;
}

export const ComentariosFiadoLanguageEnglish: ComentariosFiadoLanguage = {
    writeComment: 'Write a comment',
    send: 'Send',
    sending: 'Sending',
    noComment : 'No comment.',
}
export const ComentariosFiadoLanguageSpanish: ComentariosFiadoLanguage = {
    writeComment: 'Escribe un comentario',
    send: 'Enviar',
    sending: 'Enviando',
    noComment : 'Sin comentarios.',
}