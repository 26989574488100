import React, { useState } from 'react';
import './AcordionTruncate.scss';
import { Card, Accordion } from "react-bootstrap";
import { IconAccordionCaretUp } from "@lmig/lmds-react";
//import CardHeader from "react-bootstrap/esm/CardHeader";
interface AcordionTruncateProps {
    length: number;
    text: string;
}


export const AcordionTruncate: React.FC<AcordionTruncateProps> = ({ length, text }: AcordionTruncateProps) => {

    //const [visible, setVisible] = useState<boolean>(true);
    const [activeKey, setActiveKey] = useState<string>('0');
    const trunkText = (str: string) => {
        // If the length of str is less than or equal to num
        // just return str--don't truncate it.
        if (str.length <= length) { return str; }
        // Return str truncated with '...' concatenated to the end of str.
        return str.slice(0, length) + '...';
    }
    return (
        <>
            <Accordion activeKey={activeKey} onSelect={e => setActiveKey(e!)}>
                <Card  id="acordion-truncate">
                    {(activeKey === '0') ?
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                            {trunkText(text)}
                        </Accordion.Toggle> : null}
                    {(activeKey === '1') ?
                        <Accordion.Collapse eventKey="1">
                            <Card.Body onClick={() => { setActiveKey('0') }}>
                                <div className="text-right text-primary">
                                    <IconAccordionCaretUp color="informative" />
                                </div>
                                {text}
                            </Card.Body>
                        </Accordion.Collapse> : null}
                </Card>
            </Accordion>
        </>
    );
};







