import { Component } from "react";
import './AuthorizeEngagements.scss';
import InfoEngagement from "../../../components/compromisos/info-engagement/InfoEngagement";
import { AlphanumericInput, Button, Form, List, ListItem, Modal } from "@lmig/lmds-react";
import { RouteComponentProps } from "react-router-dom";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import * as engagementService from '../../../services/compromisos/EngagementsService';
import { Compromiso } from "../../../models/compromisos/Compromiso";
import { AxiosError } from "axios";
import { formatCurrency, formatValue, InputChange } from "../../../class/CommonClass";
import { CompromisoOption } from "../../../models/compromisos/CompromisoOption";
import { AceptaCompromisoFiado } from "../../../models/compromisos/AceptaCompromisoFiado";
import NavBarInvitado from "../../public/nav-bar/NavBarInvitado";
import { NegativeAlert } from "../../public/negative-alert/NegativeAlert";
import { ElementosCompromiso } from "../../../models/compromisos/ElementosCompromiso";
import Info from "@lmig/lmds-react/icons/Info";
import SuccessAlert from "@lmig/lmds-react/icons/SuccessAlert";



interface ISection {
    id: number;
}

interface Params {
    idFiado: string;
    idCompromiso: string;
}

interface LocationState {
    ownerName: string;
}

// Static Context is available when you use Static Router*
interface SC {
    statusCode?: number;
}

interface BlockOption {
    value: number;
    label: string;
}


interface ConditionOption {
    value: number;
    label: string;
}


class AuthorizeEngagementsClient extends Component<ISection & RouteComponentProps<Params, SC, LocationState>, AuthorizaState> {

    public idFiado = 0;
    public idCompromiso = 0;
    public today: string;
    public last: string;
    public localStorage = new LocalStorageClass();
    public initialEngagementStateAcepta: AceptaCompromisoFiado = {
        dictamen: '',
        fechaComprometida: '',
        idAccion: 0,
        idCompromiso: 0,
        motivo: '',
        nombreRep: '',
        usuario: ''
    }

    public initialEngagementState: Compromiso = {
        bloqueos: [],
        conFiado: 0,
        condiciones: [],
        datosFiado: {
            correo: '',
            fechaAceptaCompr: '',
            idCompromiso: 0,
            nombreRep: ''
        },
        dictamen: '',
        fechaComprometida: '',
        fechaCompromisoCumplido: '',
        fechaSolicitud: '',

        idCompromiso: 0,
        idFiado: 0,
        idSolicitante: '',
        monto: 0,
        motivo: '',
        numRenovaciones: 0,
        producto: '',
        statusCompromiso: 0,
        tipoBloqueo: 0,
        tipoCompromiso: 1,
        tipoCumplimiento: 0,
        tipoSolicitante: 0
    }

    public initialMsg: Messages = {
        msgTipo: '',
        msgCondicion: '',
        msgBloqueo: '',
        msgProducto: '',
        msgMonto: '',
        msgCorreo: '',
        msgFecha: '',
        msgMotivo: ''
    }

    constructor(props: any) {
        super(props);
        this.idFiado = parseInt(this.props.match.params.idFiado);
        this.idCompromiso = parseInt(this.props.match.params.idCompromiso);
        document.title = 'Aceptación del Compromiso'
        this.today = '';
        this.last = '';

        this.state = {
            compromisoDetails: [],
            count: 0,
            mensajesAlerta: this.initialMsg,
            PERFIL: this.localStorage.getSomeString(this.localStorage.PERFIL),
            compromiso: this.initialEngagementState,
            authorizeEngagementObject: this.initialEngagementStateAcepta,
            openModalClose: false,
            ConditionOption: [],
            BlockOption: [],


        }

        const date = new Date().getDate();
        const month = new Date().getMonth() + 1;
        const year = new Date().getFullYear();
        let today = "";
        let last = "";
        const monthA = new Date().getMonth() + 6;
        let yearA = year;


        if (month < 10 && date < 10) {
            const monthAux = "0" + month
            const dateAux = "0" + date
            today = year + "-" + monthAux + "-" + dateAux
            this.today = today
        }

        if (month < 10 && date >= 10) {
            const monthAux = "0" + month
            today = year + "-" + monthAux + "-" + date
            this.today = today
        }

        if (month >= 10 && date >= 10) {
            today = year + "-" + month + "-" + date
            this.today = today
        }


        if (monthA < 10 && date < 10) {
            const monthAux = "0" + monthA
            const dateAux = "0" + date
            last = yearA + "-" + monthAux + "-" + dateAux
            this.last = last
        }

        if (monthA < 10 && date >= 10) {
            const monthAux = "0" + monthA
            last = yearA + "-" + monthAux + "-" + date
            this.last = last
        }

        if (monthA >= 10 && date >= 10) {
            last = yearA + "-" + monthA + "-" + date
            this.last = last
        }


        console.log("Fecha minima: " + this.today)
        console.log("Fecha máxima: " + this.last)

        this.handleInputChangeSelect = this.handleInputChangeSelect.bind(this)
        this.handleInputChangeDate = this.handleInputChangeDate.bind(this)
        this.handleInputChangeNomRepre = this.handleInputChangeNomRepre.bind(this)
        this.handleClose = this.handleClose.bind(this)

    }

    componentDidMount() {

        this.getClientAut();
    }

    render() {
        return <>
            <div className="container-fluid" id="authorize-engagements">
                <NavBarInvitado />
                <div className="create-engagement">
                    <div className="row">

                        <div className="col-md-24 ">
                            <InfoEngagement id={this.idFiado} />
                        </div>

                        <div className="col-md-12">
                            <Form id="form-liberty">
                                <div className="row">

                                    <div className="col-md-12">

                                        <><label className="label-input">Tipo</label>
                                            <AlphanumericInput
                                                className="float-right w-100"
                                                id="input--labelAsHeading"
                                                labelVisual={""}
                                                value={this.state.compromiso.descTipoCompromiso}
                                                disabled />
                                        </>
                                    </div>


                                    {(this.state.compromiso.tipoCompromiso == 1) ?

                                        <div className="col-md-12">
                                            <label className="label-input">Condiciones de Autorización</label>
                                            <List compact className="condition-input">
                                                {this.state.ConditionOption.map((condition, index) =>
                                                    <ListItem>{condition.label}</ListItem>
                                                )}
                                            </List>
                                        </div> : null}

                                    {(this.state.compromiso.tipoCompromiso == 2) ?

                                        <div className="col-md-12">
                                            <label className="label-input">Bloqueos Activos</label>
                                            <List compact className="condition-input">
                                                {this.state.BlockOption.map((bloq, index) =>
                                                    <ListItem>{bloq.label}</ListItem>
                                                )}
                                            </List>
                                        </div> : null}

                                    <div className="col-md-12">
                                        <label className="label-input">Producto</label>
                                        <AlphanumericInput
                                            className="float-right w-100"
                                            id="input--labelAsHeading"
                                            labelVisual=""
                                            value={`${this.state.compromiso.producto}`} disabled />
                                    </div>

                                    <div className="col-md-12">
                                        <label className="label-input">Monto</label>
                                        <AlphanumericInput
                                            className="float-right w-100"
                                            id="input--labelAsHeading"
                                            labelVisual=""
                                            value={formatCurrency(this.state.compromiso.monto)} disabled />
                                    </div>


                                    <div className="col-md-12">
                                        <label className="label-input">Motivo</label>
                                        <textarea name="motivo" disabled
                                            value={` ${this.state.compromiso.motivo}`}
                                            onChange={this.handleInputChangeSelect}
                                            className="condition-input" rows={4} cols={55} />
                                    </div>


                                    <div className="col-md-6">
                                        <label className="label-input">Fecha Compromiso</label>
                                        <input
                                            type="date"
                                            name="fechaComprometida"
                                            className={"input-sdgf"}
                                            id="fechaComprometida"
                                            min={this.today}
                                            max={this.last}
                                            onChange={this.handleInputChangeDate} />
                                    </div>
                                    <div className="col-md-7">
                                        <NegativeAlert textAlert={this.state.mensajesAlerta.msgFecha} />
                                    </div>

                                    <div className="col-md-12">
                                        <label className="label-input">Nombre de Representante</label>
                                        <AlphanumericInput name="nombreRep"
                                            className="float-right w-100"
                                            id="input--labelAsHeading"
                                            labelVisual=""
                                            onBlur={this.handleInputChangeNomRepre} />
                                    </div>

                                    <div className="col-md-12"> </div>
                                    <br />
                                    <div className="col-md-12">
                                        <div className="row buttons-navigation">
                                            <div className="col-md-12">
                                                <Button variant="primary"
                                                    
                                                    onClick={() => this.validaFormulario()
                                                     
                                                    } >
                                                    Continuar
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <Modal
                                            isOpen={this.state.openModalClose}
                                            size="medium"
                                            modalStyle="standard"
                                            //title="Fiado"

                                            className="modal1"
                                            closeFunc={() => {
                                                this.setState({ openModalClose: false })
                                            }}>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="col-md-4 alert">
                                                        <SuccessAlert
                                                            size="64"
                                                            color="positive">
                                                        </SuccessAlert>
                                                    </div>

                                                    <h2 className="texto">  Operación Exitosa</h2>

                                                    <br />
                                                    <Button
                                                        href="https://www.libertyfianzas.com/"
                                                        variant="primary"
                                                        className="btn-ok">
                                                      Aceptar
                                                    </Button>
                                                </div>
                                            </div>
                                        </Modal>

                                    <Modal
                                        isOpen={this.state.openModalConfirmacion}
                                        size="medium"
                                        modalStyle="standard"
                                        id="modal4"
                                        closeFunc={() => {
                                            this.setState({ openModalConfirmacion: false })
                                        }}>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="col-md-4 info">
                                                    <Info
                                                        size="64"
                                                        color="informative">
                                                    </Info>
                                                </div>

                                                <h2 className="texto"> ¿Estas seguro que deseas aceptar el compromiso? </h2>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <br />
                                                        <Button onClick={() => {
                                                            this.setState({ openModalConfirmacion: false })
                                                        }}
                                                            variant="secondary"
                                                            className="btn-cancel">
                                                            Cancelar
                                                        </Button>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <br />
                                                        <Button
                                                            onClick={() => this.postAceptaFiado()}
                                                            //href={"/solicitudExitosa"}
                                                            variant="primary">
                                                            Aceptar
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    next(): void {
        this.setState({ count: this.state.count! + 1 })
    }

    back(): void {
        this.setState({ count: this.state.count! - 1 })
    }

    validaFormulario(): boolean {

        this.setState({ mensajesAlerta: this.initialMsg })
        const leyendaFecha = 'Por favor selecciona una Fecha'

        if (this.state.authorizeEngagementObject.fechaComprometida == "") {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgFecha: formatValue(leyendaFecha) }
            }))

            return false
        }
        this.setState({ openModalConfirmacion: true })
        this.setState({ openModalClose: false })
        return true
    }

    async postAceptaFiado(): Promise<void> {
        if (!this.validaFormulario()) {

            console.log("No cuenta con información en el formulario")

        } else {

            try {

                if (this.state.authorizeEngagementObject.fechaComprometida != null) {

                    const res = await engagementService.postAceptaFiado(this.state.authorizeEngagementObject);

                    // actualizar el objeto compromiso con el nuevo estatus
                    this.setState({ authorizeEngagementObject: res.data })

                    
                    console.log('Aceptacion Exitosa')
                    this.setState({ openModalConfirmacion: false })
                    this.setState({ openModalClose: true })
                    console.log(this.state.authorizeEngagementObject)
                    console.log("MENSAJE:: " + this.state.mensajesAlerta.msgFecha)

                }


            } catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    console.log("Error:" + err.response.status)
                    console.log("Error:" + err.response.data)

                }
            }
        }
    }

    handleInputChangeSelect(e: InputChange): void {

        const name = e.target.name;
        const value = e.target.value;
        console.log(name + ': ' + value)
        this.setState(prevState => ({
            authorizeEngagementObject: { ...prevState.authorizeEngagementObject, [name]: formatValue(value) }
        }))

    }

    handleInputChangeDate(e: InputChange): void {

        this.state.authorizeEngagementObject.idAccion = 7
        this.state.authorizeEngagementObject.idCompromiso = this.idCompromiso

        const value = e.target.value;

        const date = value
        const day = date?.substring(8, 10)
        const month = date?.substring(5, 7)
        const year = date?.substring(0, 4)
        const mesActual = new Date().getMonth() + 1;


        if (mesActual < 10) {
            const mesActualAuxiliar = "0" + mesActual
            //console.log("MES ACTUAL: " + mesActualAuxiliar)
        }

        if (month > 12) {
            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgFecha: formatValue("Fecha No Válida") }
            }))
            console.log("MES MAYOR A 12= " + month)
        }

        if (month < mesActual) {
            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgFecha: formatValue("Fecha No Válida") }
            }))
        } else {
            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgFecha: formatValue("") }
            }))
            this.setState(prevState => ({
                authorizeEngagementObject: { ...prevState.authorizeEngagementObject, fechaComprometida: day + '/' + month + '/' + year }
            }))
        }

    }

    handleInputChangeNomRepre(e: InputChange): void {


        const name = e.target.value;
        const value = e.target.value;

        this.setState(prevState => ({
            authorizeEngagementObject: { ...prevState.authorizeEngagementObject, nombreRep: value }
        }))

        console.log("Nombre de Representante: " + this.state.authorizeEngagementObject.nombreRep)

    }



    handleClose(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>): void {

        console.log("Se cierra la ventana")
        window.close();
    }

    async getClientAut(): Promise<void> {

        let externo = 1;

        const res = await engagementService.getClientAut(externo, this.idCompromiso);
        this.setState({ compromiso: res.data });
        console.log("Se obtiene el Compromiso con el Id: " + this.idCompromiso);
        console.log(this.state.compromiso)

        let listaAuxiliarB: BlockOption[];
        listaAuxiliarB = []
        let listaAuxiliarC: ConditionOption[];
        listaAuxiliarC = []

        let tipo: string;
        tipo = ''



        if (this.state.compromiso.tipoCompromiso == tiposCompromiso.PORAPOYO) {

            tipo = 'Apoyo'
        } else if (this.state.compromiso.tipoCompromiso == tiposCompromiso.BLOQUEO) {
            tipo = 'Bloqueo'
        }

        this.state.compromiso.descTipoCompromiso = tipo

        for (const valor of this.state.compromiso.bloqueos) {
            listaAuxiliarB.push({ value: valor.idBloqueo, label: valor.descripcion })
        }

        for (const valor of this.state.compromiso.condiciones) {
            listaAuxiliarC.push({ value: valor.idCondicion, label: valor.descripcion })
        }



        console.log("Listas Auxiliares: ")
        this.setState({ ConditionOption: listaAuxiliarC })
        console.log("Condiciones: ")
        console.log(this.state.ConditionOption)
        this.setState({ BlockOption: listaAuxiliarB })
        console.log("Bloqueos: ")
        console.log(this.state.BlockOption)


    }
}




export default AuthorizeEngagementsClient;

interface AuthorizaState {
    count?: number;
    PERFIL: string;
    compromiso: Compromiso;
    authorizeEngagementObject: AceptaCompromisoFiado;
    openModalClose: boolean;
    openModalConfirmacion? : boolean;
    compromisoDetails: ElementosCompromiso[];
    ConditionOption: ConditionOption[];
    BlockOption: BlockOption[];
    mensajesAlerta: Messages;

}

interface Messages {
    msgTipo: string;
    msgCondicion: string;
    msgBloqueo: string;
    msgProducto: string;
    msgMonto: string;
    msgCorreo: string;
    msgFecha: string;
    msgMotivo: string;
}

export enum tiposCompromiso {

    PORAPOYO = 1,
    BLOQUEO = 2,

}
