import {
    AddObligorLanguageEnglish,
    AddObligorLanguageSpanish
} from "../../../components/garantias/add-obligor/AddObligorLanguage";

export interface WarrantyLanguageModel {
    title: string;
    title2: string;
    principal: string;
    pipeline1: string;
    pipeline2: string;
    pipeline3: string;
    pipeline4: string;
    selectOption: string;
    selectKey: string;
    maximumAmount: string
    selectCurrency: string;
    selectPercent: string;
    recommendPercent: string;
    msgAnyPercent : string;
    amount: string;
    weightedAmount: string;
    enterAmount: string;
    limitAmount: string;
    dateStart: string;
    dateEnd: string;
    //validaciones
    valTypeUseExclusive: string;
    valDirFianza: string;
    valDirInclusion: string;
    valselectKey: string;
    valMaxAmount: string;
    valselectCurrency: string;
    valenterAmount: string;
    valdateDate: string;
    valdatePercent: string;
    warrantyNoSaved: string;
    pleaseUpload: string;
    enterInfoPlease: string;
    notUploadFile: string;
    notEnterInfo: string;
    btnContinue: string;
    btnReturn: string;
    btnSending: string;
    errorCollateral: string;
    loadingDoc: string;
    mandatoryDoc: string;
    inReview: string;
    inCapture: string;
    approved: string;
    rejected: string;
    minDateInit: string;
    maxDateInit: string;
    noValidDateInit: string;
    minDateEnd: string;
    maxDateEnd: string;
    noValidDateEnd: string;
    noResults: string;
    results: string;
    uploadFiles: string;
    deleteFile: string;
    watchFile: string;
    enterAmountDoc: string;
    notFoundDocs: string;
    loadingPropietary: string;
    errorLoadPropietary: string;
    sorryNotFoundDocs: string;
    labelReview1: string;
    labelReview2: string;
    confirmSend: string;
    areYouSureSend: string;
    confirmButton: string;
    cancelButton: string;
    reintent: string;
    errSustitution: string;
    errAutorizacion: string;
    warrantyAutorizated:string;
    msgErrAutorizacion: string;
    close: string;





/*
    noResults: string;
    results: string;
    selectKey: string;
    selectOption: string;
    maximumAmount: string;
    selectCurrency: string;
    enterAmount: string;
    limitAmount: string;
    selectPercent: string;
    recommendPercent: string;
    weightedAmount: string;
    dateStart: string;
    dateEnd: string;
    errorCollateral: string;
    btnSending: string;
    btnContinue: string;
    iCantSave: string;
    iCantUpdate: string;
    warrantyNoSaved: string;
    //validaciones
    valTypeUseExclusive: string;
    valDirFianza: string;
    valDirInclusion: string;
    valselectKey: string;
    valMaxAmount: string;
    valselectCurrency: string;
    valenterAmount: string;
    valdateDate: string;
    valdatePercent: string;
    noValidDateInit: string;
    noValidDateEnd: string;
    sorryNotsaveDir: string;
    minDateInit: string;
    maxDateInit: string;
    minDateEnd: string;
    maxDateEnd: string;

    */

}

export const WarrantyLanguageEnglish: WarrantyLanguageModel ={
    title: 'Create Collateral',
    title2: 'Edit Collateral',
    principal: 'Principal',
    pipeline1: 'Collateral',
    pipeline2: 'Documentation',
    pipeline3:'Revision',
    pipeline4: 'Dictum',
    selectOption: 'Select an Option',
    selectKey: 'Select the type of Loan',
    maximumAmount: 'Maximum amount',
    selectCurrency: 'Select a Currency',
    selectPercent: 'Weighting percentage',
    recommendPercent:  'recommended',
    msgAnyPercent: 'Any percentage that is not recommended will be subject to approval.',
    amount: 'Amount',
    weightedAmount: 'Weighted amount',
    enterAmount: 'Enter an Amount.',
    limitAmount: 'The maximum limit has been exceeded',
    dateStart: 'Date Start',
    dateEnd: 'Date End',
    valTypeUseExclusive: 'Select a obligor-details, please',
    valDirFianza: 'Enter a valid bond',
    valDirInclusion: 'Please enter a valid include',
    valselectKey: 'Select a the type of Loan.',
    valMaxAmount: 'Enter the maximum amount',
    valselectCurrency: 'Select a Currency please.',
    valenterAmount: 'Enter a Valid Amount please.',
    valdateDate: 'Please enter a Valid Date.',
    valdatePercent: 'Please enter a Valid Percent.',
    warrantyNoSaved: 'Sorry, the Loans could not be Saved.',
    pleaseUpload: 'Please upload a file',
    enterInfoPlease: 'Please enter the information of',
    notUploadFile: 'File not loaded',
    notEnterInfo: 'Information was not entered',
    btnContinue: 'Continue',
    btnReturn: 'Back',
    btnSending: 'Sending',
    errorCollateral: 'Sorry something happened while loading Collaterals, please check your connection and try again.',
    loadingDoc: 'Loading documentation',
    mandatoryDoc: '*Mandatory Document',
    inReview: 'In Review',
    inCapture: 'On Capture',
    approved: 'Approved',
    rejected: 'Rejected',
    minDateInit: 'The start date cannot be less than today.',
    maxDateInit: 'The Start Date cannot be greater than the End Date, please enter it again.',
    noValidDateInit: 'The Start date is not valid, please enter it again.',
    minDateEnd: 'The end date cannot be less than or equal to the start date.',
    maxDateEnd: '',
    noValidDateEnd: 'The Start Date is not valid, please enter it again.',
    noResults: AddObligorLanguageEnglish.noResults,
    results: AddObligorLanguageEnglish.results,
    uploadFiles:  'Upload PDF File',
    deleteFile: 'Delete File',
    watchFile: 'View File',
    enterAmountDoc: 'Enter an Amount, please.',
    notFoundDocs: 'No documents attached',
    loadingPropietary: 'Loading, Owner of the Loan',
    errorLoadPropietary: 'We\'re sorry, something has happened while loading the Loan Owner, please check your connection and try again.',
    sorryNotFoundDocs: 'Sorry, No documents found.',
    labelReview1: 'Your guarantee is under review with folio',
    labelReview2: ', in this section will be able to follow up.',
    confirmSend: 'Confirm',
    areYouSureSend: 'Are you sure you want to send these documents?',
    confirmButton: 'Confirm',
    cancelButton: 'Cancel',
    reintent: 'Reintent',
    errSustitution: 'Substitution failed',
    errAutorizacion: 'Authorization',
    warrantyAutorizated: 'Are you sure you want to send these documents, will the Loans be authorized?',
    msgErrAutorizacion: 'It does not have the authority to authorize this weighting percentage',
    close: 'Close'

}
export const WarrantyLanguageSpanish: WarrantyLanguageModel ={
    title: 'Crear Garantía',
    title2: 'Editar Garantía',
    pipeline1: 'Garantía',
    pipeline2: 'Documentación',
    pipeline3:'Revisión',
    pipeline4: 'Dictamen',
    principal: 'Fiado',
    selectOption: 'Selecciona una Opción',
    selectKey: 'Selecciona el tipo de garantía',
    maximumAmount: 'Importe máximo',
    selectCurrency: 'Selecciona una Moneda',
    selectPercent: 'Porcentaje de ponderación',
    recommendPercent: 'recomendado',
    msgAnyPercent: 'Cualquier porcentaje que no sea el recomendado será sujeto a aprobación',
    amount: 'Monto',
    weightedAmount: 'Monto ponderado',
    enterAmount: 'Ingresa un Monto',
    limitAmount: 'Se ha superado el límite máximo',
    dateStart: 'Fecha Inicial',
    dateEnd: 'Fecha Final',
    valTypeUseExclusive: 'Selecciona un obligado a solidario, por favor',
    valDirFianza: 'Introduce una fianza válida',
    valDirInclusion: 'Introduce una inclusión válida',
    valselectKey: 'Selecciona un tipo de garantía.',
    valMaxAmount: 'Ingresa el importe máximo',
    valselectCurrency: 'Selecciona una Moneda por favor.',
    valenterAmount: 'Ingresa un Monto Válido por favor.',
    valdateDate: 'Ingresa una Fecha Válida por favor.',
    valdatePercent: 'Ingresa un porcentaje Válido por favor.',
    warrantyNoSaved: 'Lo sentimos, no se ha podido Guardar la Garantía.',
    pleaseUpload: 'Por favor sube el archivo de',
    enterInfoPlease: 'Ingresa por favor la información de',
    notUploadFile: 'No se cargó el archivo de',
    notEnterInfo: 'No fue ingresada la información de',
    btnContinue: 'Continuar',
    btnReturn: 'Regresar',
    btnSending: 'Enviando',
    errorCollateral: 'Lo sentimos algo ha ocurrido al cargar la Garantía, revise su conexión e intente de nuevo.',
    loadingDoc: 'Cargando documentación',
    mandatoryDoc: '*Documento Obligatorio',
    inReview: 'En Revisión',
    inCapture: 'En Captura',
    approved: 'Aprobado',
    rejected: 'Rechazado',
    minDateInit: 'La Fecha Inicial no puede ser menor al día de hoy.',
    maxDateInit: 'La Fecha Inicial no puede ser mayor a la Fecha Final, por favor ingrésala nuevamente.',
    noValidDateInit: 'La Fecha Inicial no válida, por favor ingrésala nuevamente.',
    minDateEnd: 'La fecha Final no puede ser menor o igual a la Fecha Inicial.',
    maxDateEnd: '',
    noValidDateEnd: 'La Fecha Inicial no válida, por favor ingrésala nuevamente.',
    noResults: AddObligorLanguageSpanish.noResults,
    results: AddObligorLanguageSpanish.results,
    uploadFiles: 'Cargar Archivo PDF',
    deleteFile: 'Borrar Archivo',
    watchFile: 'Ver Archivo',
    enterAmountDoc: 'Ingresa un Monto, por favor.',
    notFoundDocs: 'No se adjuntó ningún documento',
    loadingPropietary: 'Cargando, Propietaria de La Garantía',
    errorLoadPropietary: 'Lo sentimos algo, ha ocurrido al cargar el Propietario de La Garantía, revise su conexión e intente de nuevo.',
    sorryNotFoundDocs: 'Lo sentimos, No se encontraron documentos.',
    labelReview1: 'Su garantía se encuentra en revisión con el folio ',
    labelReview2: ', en esta sección podrá darle seguimiento.',
    confirmSend: 'Confirmar',
    areYouSureSend: '¿Estás seguro de que quieres enviar estos documentos',
    confirmButton: 'Confirmar',
    cancelButton: 'Cancelar',
    reintent: 'reintentar',
    errSustitution: 'Error en la sustitución',
    errAutorizacion: 'Autorización ',
    warrantyAutorizated: '¿Estás seguro de que quieres enviar estos documentos, la Garantía será autorizada?',
    msgErrAutorizacion:'No tiene facultades para autorizar este porcentaje de ponderación',
    close: 'Cerrar'
}