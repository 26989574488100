import React, { useEffect, useState} from 'react';
import { Spinner} from 'react-bootstrap';
import {Link as LinkLiberty, Modal,} from "@lmig/lmds-react";
import * as apiService from "../../../services/api-gral/ApiGralService";
import { useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {AxiosError} from "axios";
import {EntrevistaIframeModel} from "../../../models/public/EntrevistaIframeModel";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import {openNewWindow} from "../../../class/CommonClass";
interface InterviewProps {
    idPrincipal: number;
    textInterview: string;

}

export const Interview: React.FC<InterviewProps> = ({idPrincipal, textInterview}:InterviewProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    const [interview, setInterview] = useState<EntrevistaIframeModel>({url: '', mensaje: ''});

    const [loadingInterview, setLoadingInterview] = useState<boolean>(false);

    const [errorInterview, setErrorInterview] = useState<boolean>(false);


    const getClientInverview = async (idPrincipal: number) => {
        setLoadingInterview(true);
        setErrorInterview(false)
        try {
            const res = await apiService.getObtenerEntrevista(idPrincipal);
            setInterview(res.data);
            setLoadingInterview(false);
            openNewWindow(res.data.url!);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                setLoadingInterview(false);
                setErrorInterview(false);
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }


    useEffect(() => {

    },[idPrincipal]);


    return (
        <>
            {loadingInterview?(
                    <>
                        <LinkLiberty variant="standalone"
                                     className="">
                            <Spinner animation="border"
                                     role="status"
                                     size="sm"
                                     variant="info">
                            </Spinner> {languageState.language?.infoPrincipal.loadingInterview!}
                        </LinkLiberty>
                    </>
                ):
                (!errorInterview)? (
                        <span
                            onClick={()=> {
                                getClientInverview(idPrincipal)
                            }}>
                            {textInterview}
                        </span>):
                    <div className="col-12">
                        <NegativeAlert textAlert={languageState.language?.infoPrincipal.errorInterview!}/>
                    </div>
            }

        </>
    );
};