import React from 'react';
import './TextAlert.scss';
import {Alert} from "@lmig/lmds-react";
interface TextAlertProps {
    textAlert: string
}

export const TextAlert: React.FC<TextAlertProps> = ({textAlert }: TextAlertProps) => {

    return (
        <>
            {textAlert?(
                <Alert highlightType="neutral" >
                    {textAlert}<br/>
                </Alert>
            ): null}

        </>

    );
};

