export interface filtrarLanguageModel {
    title1: string,
    title2:string,
    title3:string,
    title4:string,
    title5:string,
    title6:string,
    alert:string,
    enprocess:string,
    vigent:string,
    vencid:string,
    consult:string

}

export const CreatefiltrarLanguageEnglish: filtrarLanguageModel ={
  
    title1:'In Process',
    title2:'Active',
    title3:'Overdue',
    title4:'In Review',
    title5:'Authorized',
    title6:'Rejected',
    alert:'No queries available',
    enprocess:'In process',
    vigent:'In force',
    vencid:'Expired',
    consult:'Consult'
    
}


export const CreatefiltrarLanguageSpanish: filtrarLanguageModel ={
    title1:'En Proceso',
    title2:'Vigente',
    title3:'Vencida',
    title4:'En revisión',
    title5:'Autorizadas',
    title6:'Rechazadas',
    alert:'No hay consultas disponibles',
    enprocess:'En proceso',
    vigent:'Vigentes',
    vencid:'Venvidas',
    consult:'Consultar'
}