import {
  AlphanumericInput,
  Form,
  GridCol,
  GridRow,
  IconButton,
  IconCheckmark,
  IconClose,
  IconEsign,
} from "@lmig/lmds-react";
import React, { FC, useEffect, useState } from "react";
import {validRoles } from "../../../class/CommonClass";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import { UserModel } from "../../../models/public/UserModel";

interface IEditarDescripcionProps {
  idDocument: number;
  texto: string;
  handleUpd: (value: string) => void;
}


const EditarDescripcion: FC<IEditarDescripcionProps> = ({
  idDocument,
  texto,
  handleUpd,
}) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(texto);
  const [error, setError] = useState(false);
  const localStorageClass = new LocalStorageClass();
  const PERFIL = (localStorageClass.getProfile() as UserModel);

  useEffect(() => {
    if (texto === null) setValue("N/A");
    setValue(texto);
  }, []);

  const onchange = () => {
    setEdit(!edit);
    setValue(texto)
  };

  const onUpdate = async () => {
    if (texto !== value && value !== null) {
      if (handleUpd) handleUpd(value);
    }
    if (value === null) {
      setError(true);
    } else {
      setEdit(!edit);
    }
  };

  return (
    <div>
      <Form>
        {!edit && (
          <GridRow gutters>
            <GridCol base={3}>
              <div>{value}</div>
            </GridCol>
            <GridCol base={3}>
              {(validRoles(PERFIL.roles,localStorageClass.EJECUTIVOCUENTA) || validRoles(PERFIL.roles,localStorageClass.EJECUTIVODEGARANTIAS))  &&
              <IconButton onClick={onchange} background="teal">
                <IconEsign size="16" />
              </IconButton>}
            </GridCol>
          </GridRow>
        )}
        {edit && (
          <GridRow gutters>
            <GridCol base={6}>
              <AlphanumericInput
                value={value}
                labelVisual={"Descripcion"}
                onChange={(e) => setValue(e.currentTarget.value)}
                highlightType={error && !value ? "error" : undefined}
                maxLength={100}
              />
            </GridCol>
            <GridCol base={1}>
              <IconButton onClick={onUpdate}>
                <IconCheckmark size="16" />
              </IconButton>
            </GridCol>
            <GridCol base={1}>
              <IconButton onClick={onchange}>
                <IconClose size="16" />
              </IconButton>
            </GridCol>
          </GridRow>
        )}
      </Form>
    </div>
  );
};

export default EditarDescripcion;
