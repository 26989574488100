import { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import {
    IconMyAccount,
    IconSeeMore,
    Table, TableCell, TableRow
} from "@lmig/lmds-react";
import ReactTooltip from 'react-tooltip';
import Info from '@lmig/lmds-react/icons/Info';
import * as engagementService from '../../../services/compromisos/EngagementsService';
import { HistorialCompromiso } from '../../../models/compromisos/HistorialCompromiso';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import { UserModel } from '../../../models/public/UserModel';
import { RootStore } from "../../../Store";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { connect } from "react-redux";
import { CompromisoOption } from '../../../models/compromisos/CompromisoOption';
import { formatCurrency } from '../../../class/CommonClass';

interface TableEngagementsProps {

    idFiado: number;
    language: DefaultStateI

}

interface SteepsModel {
    openModalClose?: boolean;
    listCompromisosHistorialFiado: HistorialCompromiso[];
    listaC: HistorialCompromiso[]; 
    PERFIL: UserModel;

}

class TableHistoryEngagementsByFiado extends Component<TableEngagementsProps, SteepsModel> {

    
    public idFiado= this.props.idFiado
    public localStorageClass = new LocalStorageClass();


    constructor(props: any) {
        super(props);

        const PERFIL = this.localStorageClass.getProfile();

        this.state = {
            PERFIL: PERFIL,
            openModalClose: false,
            listCompromisosHistorialFiado:[],
            listaC:[]  

        }

    }

    componentDidMount() {

        this.getHistorialFiado();
        
    }

    render(): JSX.Element {

        return (
            <>
               <Table id="demo-table" >
                <thead>
                    {this.state.listaC.length != 0 ? (
                        <><br />
                            <TableRow>
                                
                                <TableCell dataHeader="K" type="colHead" > {this.props.language.language?.tableEngagements.folio} </TableCell>
                                <TableCell dataHeader="C" type="colHead"> {this.props.language.language?.tableEngagements.tipo} </TableCell>
                                <TableCell dataHeader="D" type="colHead"> {this.props.language.language?.tableEngagements.producto} </TableCell>
                                <TableCell dataHeader="E" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.monto} </TableCell>
                                <TableCell dataHeader="E" type="colHead"> {this.props.language.language?.tableEngagements.condiciones} </TableCell>
                                <TableCell dataHeader="F" type="colHead"> {this.props.language.language?.tableEngagements.motivo} </TableCell>
                                <TableCell dataHeader="G" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.fechaCreacion} </TableCell>
                                <TableCell dataHeader="H" type="colHead"> {this.props.language.language?.tableEngagements.renovaciones} </TableCell>
                                <TableCell dataHeader="I" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.estatus} </TableCell>
                                <TableCell dataHeader="J" type="colHead">  </TableCell>
                            </TableRow>  </>
                    ) : <><br />
                        <TableRow>
                            <TableCell dataHeader="A" type="colHead">  </TableCell>
                            <TableCell dataHeader="C" type="colHead">  </TableCell>
                            <TableCell dataHeader="D" type="colHead">  </TableCell>
                            <TableCell dataHeader="E" type="colHead">  </TableCell>
                            <TableCell dataHeader="E" type="colHead">  </TableCell>
                            <TableCell dataHeader="F" type="colHead">  </TableCell>
                            <TableCell dataHeader="G" type="colHead">  </TableCell>
                            <TableCell dataHeader="H" type="colHead">  </TableCell>
                            <TableCell dataHeader="I" type="colHead">  </TableCell>
                            <TableCell dataHeader="J" type="colHead">  </TableCell>
                        </TableRow>  </>}
                </thead>
                <tbody>
                    {this.state.listaC.length == 0 ? (
                        <><br />
                            <TableRow>
                                <TableCell dataHeader="" type="colHead">  </TableCell>
                                <TableCell dataHeader="" type="colHead">  </TableCell>
                                <TableCell dataHeader="" type="colHead">  </TableCell>
                                <TableCell dataHeader="" type="colHead">  </TableCell>
                                <TableCell dataHeader="" type="colHead">  </TableCell>
                                <TableCell dataHeader="" type="colHead">  </TableCell>
                                <TableCell dataHeader="" type="colHead" className="extrasE"> {this.props.language.language?.tableEngagements.sinRegistros} </TableCell>
                                <TableCell dataHeader="" type="colHead">  </TableCell>
                                <TableCell dataHeader="" type="colHead">  </TableCell>
                                <TableCell dataHeader="" type="colHead">  </TableCell>
                                <TableCell dataHeader="" type="colHead">  </TableCell>
                                <TableCell dataHeader="" type="colHead">  </TableCell>
                                <TableCell dataHeader="" type="colHead">  </TableCell>
                            </TableRow>
                        </>
                    ) : null}
                    {this.state.listaC.map((i, index) =>
                        <TableRow>

                           
                            <TableCell dataHeader="K" type="rowHead" className="extras"> {i.idCompromiso} </TableCell>
                            <TableCell dataHeader="Tipo"> {i.tipoCompromiso} </TableCell>
                            <TableCell dataHeader="Producto" >

                                <Info title=" " data-tip={i.producto} data-for='getContent' size="16" className="prod"> </Info>
                                <ReactTooltip id='getContent' className='custom-color-no-arrow'
                                    getContent={(dataTip) => `${this.props.language.language?.tableEngagements.producto}: ${dataTip}`} />

                            </TableCell>
                            <TableCell dataHeader="Monto" className="extras">{formatCurrency(i.monto)}   </TableCell>
                            <TableCell dataHeader="Condiciones">

                                {i.condiciones == null ? (
                                    <>
                                        <Info title=" " data-tip={i.bloqueos.map((j, index) => j.descripcion)} data-for='getContent1' size="16" className="cond"> </Info>
                                        <ReactTooltip id='getContent1' className='custom-color-no-arrow' getContent={(dataTip) => `${this.props.language.language?.tableEngagements.bloqueos}: ${dataTip} `}/></>
                                ) : null}

                                {i.condiciones != null ? (
                                    <>
                                        <Info title=" " data-tip={i.condiciones.map((j, index) => j.descripcion)} data-for='getContent2' size="16" className="cond"> </Info>
                                        <ReactTooltip id='getContent2' className='custom-color-no-arrow' getContent={(dataTip) => `${this.props.language.language?.tableEngagements.condiciones}: ${dataTip}`} /></>
                                ) : null}

                            </TableCell>
                            <TableCell dataHeader="Motivo">

                                <Info title=" " data-tip={i.motivo} data-for='getContent3' size="16" className="prod"> </Info>
                                <ReactTooltip id='getContent3' className='custom-color-no-arrow' getContent={(dataTip) => ` ${this.props.language.language?.tableEngagements.motivo}: ${dataTip} `}/>

                            </TableCell>
                           
                            {i.statusCompromiso == 'Por Aceptar Fiado' ? (
                            <TableCell dataHeader="Fecha" className="extras"> --/--/----  </TableCell> 
                            ): 
                            <TableCell dataHeader="Fecha" className="extras" > {i.fechaSolicitud}  </TableCell> } 

                            <TableCell dataHeader="Renovacion" className="extras" > {i.numRenovaciones}  </TableCell>
                            <TableCell dataHeader="Estatus" className="extras">
                                <div>
                                  
                                          <div className="extras">
                                          <text >{i.statusCompromiso} </text>
                                      </div>
                                       
                                  
                                </div>
                            </TableCell>
                            <TableCell dataHeader="Renovacion" className="extras" > </TableCell>
                          
                        </TableRow>)}
                </tbody>
            </Table>
            </>
        );
    }


    async getHistorialFiado(): Promise<void> {

        const res = await engagementService.getHistorialFiado(this.idFiado);
        this.setState({ listCompromisosHistorialFiado: res.data });
        console.log("Se obtiene el Compromiso con el ID de Fiado en la tabla: " + this.idFiado);
        
        let listaAuxiliar: HistorialCompromiso[];
        listaAuxiliar = []
        for (const atributo of this.state.listCompromisosHistorialFiado) {


            let estatus: string;
            estatus = ''



            if (atributo.statusCompromiso == '1') {
                estatus = 'Por Autorizar'
              
            } else if (atributo.statusCompromiso == '2') {
                estatus = 'Vigente'
            } else if (atributo.statusCompromiso == '3') {
                estatus = 'Vencido'
            } else if (atributo.statusCompromiso == '4') {
                estatus = 'Rechazado'
            } else if (atributo.statusCompromiso == '5') {
                estatus = 'Revocado'
            } else if (atributo.statusCompromiso == '6') {
                estatus = 'Cumplido'
            } else if (atributo.statusCompromiso == '7') {
                estatus = 'Por Aceptar Fiado'
            } else if (atributo.statusCompromiso == '8') {
                estatus = 'Rechazado Por Fiado'
            }

            if (atributo.tipoCompromiso == '1') {

                listaAuxiliar.push({
                    bloqueos: atributo.bloqueos, condiciones: [], fechaComprometida: atributo.fechaComprometida,
                    fechaSolicitud: atributo.fechaSolicitud, fiado: atributo.fiado, idCompromiso: atributo.idCompromiso, idFiado: atributo.idFiado,
                    monto: atributo.monto, motivo: atributo.motivo, numRenovaciones: atributo.numRenovaciones, producto: atributo.producto,
                    tipoCompromiso: "Apoyo",  rfc: atributo.rfc, statusCompromiso: estatus
                })

                //console.log("Fecha:" + atributo.fechaComprometida)

            } else if (atributo.tipoCompromiso == '2') {

                listaAuxiliar.push({
                    bloqueos: atributo.bloqueos, condiciones: [], fechaComprometida: atributo.fechaComprometida,
                    fechaSolicitud: atributo.fechaSolicitud, fiado: atributo.fiado, idCompromiso: atributo.idCompromiso, idFiado: atributo.idFiado,
                    monto: atributo.monto, motivo: atributo.motivo, numRenovaciones: atributo.numRenovaciones, producto: atributo.producto,
                    tipoCompromiso: "Bloqueo", rfc: atributo.rfc, statusCompromiso: estatus
                })

                //console.log("Fecha:" + atributo.fechaComprometida)

            }
            
        }
        //console.log(listaAuxiliar)
        this.setState({ listaC: listaAuxiliar })

        
        
        

    }


   
}

const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(TableHistoryEngagementsByFiado);


