import React, {} from "react";
import {Link, useHistory} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";
import './BreadCumbPrev.scss';
import {IconLinkCaretLeft} from "@lmig/lmds-react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";

export interface BreadCumbListModel {
    title: string,
    url: string
}
interface BreadCumbPrevProps {
    title: string
}
export const BreadCumbPrev: React.FC<BreadCumbPrevProps> = ({ title}:BreadCumbPrevProps) => {
    const history = useHistory();
    // redux
    const languageState = useSelector((state: RootStore) => state.language.language?.BreadCumbPrev);
    const redirect = () => {
        history.goBack()
    }

    return <>
        <div className="row" id="bread-cumb">
            <div className="col-md-12">
                <h3 className="title-seccion">
                    {title}<br/>
                </h3>
                {

                    <Breadcrumb>
                        <Breadcrumb.Item >
                                        <span onClick={() => {
                                            redirect()
                                        }}>
                                          <IconLinkCaretLeft color="blue" className="ico-back"/> {languageState?.prev}
                                        </span>

                        </Breadcrumb.Item>

                    </Breadcrumb>

                }

            </div>
        </div>
    </>;
};

