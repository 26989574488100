export interface InfoPrincipalLockLanguage {
    reintent: string;
    titleModal: string;
    titleModalto: string;
    selectReasons: string;
    sorryReason: string;
    close: string;
    saving: string;
    save: string;
    addDescription: string;
    lockPrincipal: string;
    loadingLocked: string;
    textLocked: string;
    descLocked: string;
    imSorryLocked: string;
    errToUnlock: string;
    otherReasonsLocked: string;
    msgValidReason: string;
    msgValidDescription: string;
}

export const InfoPrincipalLockEnglish: InfoPrincipalLockLanguage = {
    reintent: 'Reintent',
    titleModal: 'Unlock Principal',
    titleModalto: 'Unlock Principal',
    selectReasons: 'Select Unlock Reasons:',
    sorryReason: 'Sorry something happened loading the Reasons list, please check your connection and try again.',
    close: 'Close',
    save: 'Save',
    saving: 'Saving',
    addDescription: 'Add a description',
    lockPrincipal: 'Principal Blocked',
    loadingLocked: 'Loading Reason for blocking',
    textLocked: 'The principal has been blocked by',
    descLocked: 'Lock Description',
    imSorryLocked: 'Sorry something happened while loading Blocked Reason, please check your connection and try again:',
    errToUnlock: 'Error unlocking trust',
    otherReasonsLocked: 'Other blocking reasons',
    msgValidReason: 'Please select an unlock reason.',
    msgValidDescription: 'Please enter the unlock description.'
}
export const InfoPrincipalLockSpanish: InfoPrincipalLockLanguage = {
    reintent: 'Reintentar',
    titleModal: 'Desbloquear Fiado',
    titleModalto: 'Desbloquear al Fiado',
    selectReasons: 'Seleccionar motivos de desbloqueo:',
    sorryReason: 'Lo sentimos algo ha ocurrido al cargar la lista de Motivos, revise su conexión e intente de nuevo.',
    close: 'Cerrar',
    saving: 'Guardando',
    save: 'Guardar',
    addDescription: 'Agrega una descripción',
    lockPrincipal: 'Fiado Bloqueado',
    loadingLocked: 'Cargando Motivo de bloqueo',
    textLocked: 'El fiado ha sido bloqueado por',
    descLocked: 'Descripción del bloqueo',
    imSorryLocked: 'Lo sentimos algo, ha ocurrido al cargar el Motivo de bloqueo, revise su conexión e intente de nuevo.:',
    errToUnlock: 'Error al desbloquear el fiado',
    otherReasonsLocked: 'Otros motivos de bloqueo',
    msgValidReason: 'Por favor, selecciona una razón de desbloqueo.',
    msgValidDescription: 'Por favor, ingresa la descripción de desbloqueo.'
}
