export interface WarrantyReviewLanguage {
    title: string;
    loan: string;
    loadingCollateral: string;
    errorCollateral: string;
    loadingDoc: string;
    quoteAmount: string;
    seeFile: string;
    quoteVigency: string;
    quotePercent:string;
}

export const WarrantyAuthorizationLanguageEnglish: WarrantyReviewLanguage ={
    title: 'Review of Collateral',
    loan: 'Loan',
    loadingCollateral: 'Loading Collateral',
    errorCollateral: 'Sorry something happened while loading Warranty, check your connection and try again.',
    loadingDoc: 'Loading documentation',
    quoteAmount: 'Rate the amount of the loan, please.',
    seeFile: 'See File',
    quoteVigency: 'Rate the vigency of the loan, please.',
    quotePercent: 'Rate the weighting percentage, please'

}
export const WarrantyAuthorizationLanguageSpanish: WarrantyReviewLanguage ={
    title: 'Revisión de Garantías',
    loan: 'Fiado',
    loadingCollateral: 'Cargando Garantía',
    errorCollateral: 'Lo sentimos algo ha ocurrido al cargar la Garantía, revise su conexión e intente de nuevo.',
    loadingDoc: 'Cargando documentación',
    quoteAmount: 'Califica el monto de la garantía, por favor.',
    seeFile: 'Ver Archivo',
    quoteVigency: 'Califica la vigencía de la garantía, por favor.',
    quotePercent: 'Califique el porcentaje de ponderación, por favor'
}