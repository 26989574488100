import axios from 'axios';
import environment from '../../environments/environment'
import {Estadisticas} from '../../models/compromisos/Estadisticas';
import {Bloqueos} from '../../models/compromisos/Bloqueos';
import {Condiciones} from '../../models/compromisos/Condiciones';
import {Compromiso} from '../../models/compromisos/Compromiso';
import {TipoCompromiso} from '../../models/compromisos/TipoCompromiso';
import {Cliente} from '../../models/compromisos/Cliente';
import {Producto} from '../../models/compromisos/Producto';
import {AceptaCompromisoFiado} from '../../models/compromisos/AceptaCompromisoFiado';
import {AutorizaCompromiso} from '../../models/compromisos/AutorizaCompromiso';
import {CodigoRespuesta} from '../../models/compromisos/CodigoRespuesta';
import {Historial} from '../../models/compromisos/Historial';
import {Funcionarios} from '../../models/compromisos/Funcionarios';
import {Agente} from '../../models/compromisos/Agente';
import {Ejecutivo} from '../../models/compromisos/Ejecutivo';
import {LocalStorageClass} from '../../class/LocalStorageClass';
import {HistorialCompromiso} from '../../models/compromisos/HistorialCompromiso';
import {CompromisoPOST} from '../../models/compromisos/CompromisoPOST';
import {PaginacionModel} from '../../models/public/PaginacionModel';
import {ElementosCompromiso} from '../../models/compromisos/ElementosCompromiso';
import {Destinatario} from '../../models/compromisos/Destinatario';
import {GarantiaCompromisoModel} from '../../models/compromisos/GarantiaCompromisoModel';
import {NumeroCompromisos} from '../../models/compromisos/NumeroCompromisos';
import {Correo} from '../../models/compromisos/Correo';
import {CumpleConcepto} from '../../models/compromisos/CumpleConcepto';
import {TiposDeCompromisoEnum} from "../../models/compromisos/TiposDeCompromisoEnum";


const  API = environment.APICOMPROMISO
const  APIMENSAJES = environment.APIMENSAJES

const localStorageClass = new LocalStorageClass();

const  headersJson = {
    Accept: 'application/json',  
    'X-API-KEY': environment.APIKEYCOMPROMISOS,
    Authorization: localStorageClass.getProfileToken(),
    'token': localStorageClass.getProfileToken(),
    env: environment.env,
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode()
}

const  headersJsonMessages = {
    Accept: 'application/json',
    'X-API-KEY': environment.APIKEYMENSAJES,
    Authorization: localStorageClass.getProfileToken(),
    'token': localStorageClass.getProfileToken(),
    env: environment.env,
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode()
}
const updateHeader = () => {
    headersJson.Authorization = localStorageClass.getProfileToken();
    headersJson.token = localStorageClass.getProfileToken();
    headersJson.idLenguaje = localStorageClass.getLanguageId();
    headersJson.lang = localStorageClass.getLanguageCode();
}


//OBTIENE LOS VALORES DE LAS ESTADISTICAS QUE SE MUESTRAN EN LA PÀGINA INICIAL FILTRADAS
export const getEstadisticas = async (idEjecutivoCuentaYGar: number | null, idAgente: number| null, clienteDirecto: boolean ) => {
    updateHeader();

    let queryParams= '';
    if (idEjecutivoCuentaYGar){
        queryParams += '&idEjecutivoCuentaYGar='+ idEjecutivoCuentaYGar
    }else if (idAgente){
        queryParams += '&idAgente='+ idAgente
    }

    queryParams += '&clienteDirecto='+ clienteDirecto


    return await axios.get<Estadisticas>(`${API}/estadisticas/?${queryParams}`, { headers: headersJson });

   // return await axios.get<Estadisticas>(API + '/estadisticas?', { headers: headersJson });

    //https://lf-sgf-compromisos-api-impl-test.us-east-1.np.paas.lmig.com/lf-compromisos-api/1.0.0/
    // estadisticas?idFuncionario=18
    //
};

//OBTIENE LOS BLOQUEOS ACTIVOS DE UN FIADO QUE SE MUESTRAN EN EL FORMULARIO
export const getBloqueos = async (idFiado: number, ) => {
    updateHeader();
    return await axios.get<Bloqueos[]>(API + '/bloqueos/' + idFiado , { headers: headersJson }); 
};

//OBTIENE LAS CONDICIONES DE AUTORIZACION QUE SE MUESTRAN EN EL FORMULARIO
export const getCondiciones = async () => {
    updateHeader();
    return await axios.get<Condiciones[]>(API + '/condiciones', { headers: headersJson });
};

//OBTIENE UNA ARRAY DE FIADOS QUE SE MUESTRAN EN FINDCLIENT PARA CREAR UN NUEVO COMPROMISO
export const getClientAlls = async (idAgente: string, busqueda:string) => {
    updateHeader();
    return await axios.get<Cliente[]>(API + '/cliente/busqueda?busqueda=' + encodeURIComponent(busqueda) + '&idAgente=' + idAgente , { headers: headersJson } );
};

//OBTIENE INFORMACION DE UN CLIENTE
export const getClient = async ( idFiado: number, externo: number) => {
    updateHeader();
    return await axios.get<Cliente>(API + '/cliente/' + idFiado + '?externo=' + externo, { headers: headersJson });
};

//OBTIENE UNA ARRAY DE FUNCIONARIOS QUE SE MUESTRAN CUANDO EL EJECUTIVO NO TIENE FACULTADES
export const getFuncionarios = async ( busqueda:string, idCompromiso:number) => {
    updateHeader();
    return await axios.get<Funcionarios[]>(API + '/funcionarios?busqueda=' + encodeURIComponent(busqueda) + '&idCompromiso=' + idCompromiso, { headers: headersJson });
};

//OBTIENE UN CLIENTE (OBJETO) ID=1098 PARA MOSTRAR SU INFORMACIÓN
export const getClientInfo = async (idAgente: string, busqueda:string) => {
    updateHeader();
    return await axios.get<Cliente>(API + '/cliente/busqueda?idAgente=' + idAgente + '&busqueda=' + encodeURIComponent(busqueda), { headers: headersJson } );
};

//OBTIENE UN COMPROMISO YA CREADO PARA MOSTRAR SU INFORMACION
export const getClientAut = async ( externo: number, idCompromiso: number) => {
    updateHeader();
    return await axios.get<Compromiso>(API + '/compromiso/'+ idCompromiso + '?externo=' + externo, { headers: headersJson });
};

//OBTIENE LOS TIPOS DE COMPROMISO QUE SE MUESTRAN EN EL FORMULARIO
export const getTipo = async () => {
    updateHeader();
    return await axios.get<TipoCompromiso[]>(API + '/tiposCompromiso', { headers: headersJson });
};

//OBTIENE LOS PRODUCTOS QUE SE MUESTRAN EN EL FORMULARIO
export const getProducto = async (busqueda:string, ) => {
    updateHeader();
    return await axios.get<Producto[]>(API + '/productos?producto=' + encodeURIComponent(busqueda) , { headers: headersJson });
};

//OBTIENE LOS COMPROMISOS QUE SE MUESTRAN EN LA TABLA PRINCIPAL DE TODOS LOS PERFILES
export const getCompromisos = async (
    idClienteDirecto: string, idAgente:string, tipoFiltro: string,
    paginacion: PaginacionModel, search:string ) => {
    let paginacionQueryParams= '';
    let searchQueryParams= '';


   /*  if (paginacion.currentPage){
        paginacionQueryParams += '&page='+ paginacion.currentPage
    }
    if (paginacion.size){
        paginacionQueryParams += '&size='+ paginacion.size
    } */
  
        searchQueryParams += '&search='+ search
       
  
    updateHeader();
    return await axios.get< {paginacion: PaginacionModel, elementos:ElementosCompromiso[] }>(API +
        '/compromisos?idAgente=' + idAgente + '&idClienteDirecto=' + idClienteDirecto +
    '&page='+ paginacion.currentPage + '&size='+ paginacion.size +
    searchQueryParams + '&tipoFiltro=' + tipoFiltro, { headers: headersJson });

    //https://lf-sgf-compromisos-api-impl-test.us-east-1.np.paas.lmig.com/lf-compromisos-api/1.0.0/compromisos?idAgente=3842&idClienteDirecto=1&page=1&search=a&size=10&tipoFiltro=1

    //token * string 
    //idClienteDirecto integer es el id del usuario loggeado 
    //idAgente integer es el id del agente a filtrar los compromisos
    //tipoFiltro integer es el id del filtro 1 apoyo, 2 bloqueo 3 por vencer 4 vencidos
};

export const getCompromisosEstadisticas = async (
    paginacion: PaginacionModel,
    idEjecutivoCuentaYGar: number | null,
    idAgente: number| null,
    clienteDirecto: boolean,
    filtro: TiposDeCompromisoEnum,
    search: string) => {

    let queryParams= '';

    queryParams += '&clienteDirecto='+ clienteDirecto

    if (paginacion.currentPage){
        queryParams += '&page='+ paginacion.currentPage
    }

    if (paginacion.size){
        queryParams += '&size='+ paginacion.size
    }

    if (idEjecutivoCuentaYGar){
        queryParams += '&idEjecutivoCuentaYGar='+ idEjecutivoCuentaYGar
    }else if (idAgente){
        queryParams += '&idAgente='+ idAgente
    }

    if (filtro == TiposDeCompromisoEnum.PORAPOYO ){
        queryParams+= '&tipoFiltro=' + TiposDeCompromisoEnum.PORAPOYO
    }
    if (filtro == TiposDeCompromisoEnum.BLOQUEO ){
        queryParams+= '&tipoFiltro=' + TiposDeCompromisoEnum.BLOQUEO
    }
    if (filtro == TiposDeCompromisoEnum.PORVENCER ){
        queryParams+= '&tipoFiltro=' + TiposDeCompromisoEnum.PORVENCER
    }
    if (filtro == TiposDeCompromisoEnum.VENCIDOS ){
        queryParams+= '&tipoFiltro=' + TiposDeCompromisoEnum.VENCIDOS
    }

    if (search.length>0){
        queryParams+='&search='+ search;
    }

    updateHeader();
    return await axios.get<{paginacion: PaginacionModel, elementos:ElementosCompromiso[]}>(`${API}/compromisos/estadisticas/?${queryParams}`, { headers: headersJson });

    //https://lf-sgf-compromisos-api-impl-test.us-east-1.np.paas.lmig.com/lf-compromisos-api/1.0.0/compromisos?idAgente=3842&idClienteDirecto=1&page=1&search=a&size=10&tipoFiltro=1

    //token * string
    //idClienteDirecto integer es el id del usuario loggeado
    //idAgente integer es el id del agente a filtrar los compromisos
    //tipoFiltro integer es el id del filtro 1 apoyo, 2 bloqueo 3 por vencer 4 vencidos
};


//OBTIENE LOS COMPROMISOS QUE SE MUESTRAN EN LA TABLA PRINCIPAL DE FUNCIONARIOS
export const getCompromisosFuncionarios = async (idFuncionario:string, tipoFiltro: string, paginacion: PaginacionModel, search:string ) => { 
    let paginacionQueryParams= '';
    let searchQueryParams= '';


   /*  if (paginacion.currentPage){
        paginacionQueryParams += '&page='+ paginacion.currentPage
    }
    if (paginacion.size){
        paginacionQueryParams += '&size='+ paginacion.size
    } */
  
        searchQueryParams += '&search='+ search
       
  
    updateHeader();
    return await axios.get< {paginacion: PaginacionModel, elementos:ElementosCompromiso[] }>(API + '/compromisosFuncionario?idFuncionario=' + idFuncionario + 
    '&page='+ paginacion.currentPage + '&size='+ paginacion.size +
    searchQueryParams + '&tipoFiltro=' + tipoFiltro, { headers: headersJson });

    //https://lf-sgf-compromisos-api-impl-test.us-east-1.np.paas.lmig.com/lf-compromisos-api/1.0.0/compromisosFuncionario?idFuncionario=18&page=1&search=a&size=10&tipoFiltro=1

    //token * string 
    //idClienteDirecto integer es el id del usuario loggeado 
    //idAgente integer es el id del agente a filtrar los compromisos
    //tipoFiltro integer es el id del filtro 1 apoyo, 2 bloqueo 3 por vencer 4 vencidos
};




//OBTIENE LOS COMPROMISOS QUE SE MUESTRAN EN LA TABLA DE EJECUTIVO/FUNCIONARIO QUE ESTAN PENDIENTES POR AUTORIZAR
export const getCompromisosPendientes = async ( idFuncionario:string, paginacion: PaginacionModel) => {

    let paginacionQueryParams= '';

    if (paginacion.currentPage){
        paginacionQueryParams += '&page='+ paginacion.currentPage
    }
    if (paginacion.size){
        paginacionQueryParams += '&size='+ paginacion.size
    }
    updateHeader();
    return await axios.get< {paginacion: PaginacionModel, elementos:ElementosCompromiso[] }>(API + '/compromisosPorAutorizar?idFuncionario=' + idFuncionario + paginacionQueryParams, { headers: headersJson });
};

//OBTIENE LAS FACULTADES DE UN EJECUTIVO PARA PODER DICTAMINAR
export const getFacultades = async (idEjecutivo:string, monto: number, producto:number) => {
    updateHeader();
    return await axios.get<CodigoRespuesta>(API + '/obtieneFacultades?idEjecutivo=' + idEjecutivo + '&monto=' + producto + '&producto=' + monto, { headers: headersJson });
};


//OBTIENE A LOS AGENTES
export const getAgentes = async (busqueda:string, idPromotor:number | null, idEjecutivo: number | null) => {

    let paginacionQueryParams= '';

    if (idPromotor){
        paginacionQueryParams += '&idPromotor='+ idPromotor
    }
    if (idEjecutivo){
        paginacionQueryParams += '&idEjecutivo='+ idEjecutivo
    }

    updateHeader();
    return await axios.get<Agente[]>(API  + '/agentes?busqueda='+ busqueda  + paginacionQueryParams, { headers: headersJson });
    //clave de promotor otro caso null
    //https://lf-sgf-compromisos-api-impl-development.us-east-1.np.paas.lmig.com/lf-compromisos-api/1.0.0/agentes?busqueda=teresa&idEjecutivo=12
};

//OBTIENE A LOS EJECUTIVOS
export const getEjecutivos = async (busqueda:string ) => {
    updateHeader();
    return await axios.get<Ejecutivo[]>(API  + '/ejecutivos?busqueda=' + encodeURIComponent(busqueda) , { headers: headersJson });
};

//OBTIENE TODOS LOS COMPROMISOS DE UN FIADO
export const getHistorialFiado = async (idFiado:number ) => {
    updateHeader();
    return await axios.get<HistorialCompromiso[]>(API + '/historialCompromisos/' + idFiado , { headers: headersJson });
};

//OBTIENE EL HISTORIAL DE UN COMPROMISO
export const getHistorialCompromiso = async (idCompromiso:number, ) => {
    updateHeader();
    return await axios.get<Historial[]>(API + '/historial/' + idCompromiso , { headers: headersJson });
};

//OBTIENE EL DESTINATARIO PARA LOS CORREOS
export const getDestinatario= async (idCompromiso:number ) => {
    updateHeader();
    return await axios.get<Destinatario>(API + '/obtenerDestinatario?idCompromiso=' + idCompromiso , { headers: headersJson });
};

//OBTIENE EL CADENA PARA LOS CORREOS
export const getRemitente= async (idCompromiso:number ) => {
    updateHeader();
    return await axios.get< Correo[] >(APIMENSAJES + '/revision?idProyecto=2&idReferencia=' + idCompromiso ,
        { headers: headersJsonMessages });
};

//OBTIENE EL NUMERO DE COMPROMISOS CREADOS PARA UN FIADO 
export const getNumCompromisos= async (idFiado:number, idCompromiso:number ) => {
    updateHeader();
    return await axios.get<NumeroCompromisos>( API + '/compromisosFiado?idCompromiso=' + idCompromiso + '&idFiado=' + idFiado , { headers: headersJson });

    //https://lf-sgf-compromisos-api-impl-development.us-east-1.np.paas.lhttps://lf-sgf-compromisos-api-impl-development.us-east-1.np.paas.lmig.com/lf-compromisos-api/1.0.0/compromisosFiado?idCompromiso=1554&idFiado=3

};

//OBTIENE LOS MIEMBROS DEL COMITE 
export const getComite = async ( ) => {
    updateHeader();
    return await axios.get<Funcionarios []>( API  + '/comite'  , { headers: headersJson });
};

//OBTIENE LOS MIEMBROS DEL SUBCOMITE POR OFICINA
export const getSubComite = async (idFiado:number ) => {
    updateHeader();
    return await axios.get<Funcionarios[]>( API  + '/subComite?idFiado=' + idFiado , { headers: headersJson });
};

//OBTIENE LOS MIEMBROS DEL SUBCOMITE POR OFICINA
export const getComiteBuro = async () => {
    updateHeader();
    return await axios.get<Funcionarios[]>( API  + '/comiteBuro' , { headers: headersJson });
};

//OBTIENE EL HISTORIAL DE UN COMPROMISO
export const getPorCumplir = async ( paginacion: PaginacionModel, search: string) => {
    let paginacionQueryParams= '';

    if (paginacion.currentPage){
        paginacionQueryParams += '?page='+ paginacion.currentPage
    }
    if (paginacion.size){
        paginacionQueryParams += '&size='+ paginacion.size
    }
    if (search){
        paginacionQueryParams += '&buscar='+ encodeURIComponent(search)
    }
    updateHeader();
    return await axios.get< {paginacion: PaginacionModel, elementos:ElementosCompromiso[] }>(API + '/compromisosPorCumplir' + paginacionQueryParams, { headers: headersJson });
};

//CREA UN COMPROMISO CON LOS VALORES DEL FORMULARIO
export const postCompromiso = async (compromiso: Compromiso) => {
    updateHeader();
    return await axios.post<CompromisoPOST>(API  + '/compromiso', compromiso, { headers: headersJson });
};

export const postCompromisov2 = async (compromiso: Compromiso) => {
    updateHeader();
    return await axios.post<Compromiso>(API  + '/compromiso', compromiso, { headers: headersJson });
};
//ENVIA RECHAZO/ACEPTACION POR PARTE DEL FIADO 
export const postAceptaFiado = async (compromiso: AceptaCompromisoFiado) => {
    updateHeader();
    return await axios.post<AceptaCompromisoFiado>(API  + '/aceptaCompromiso' , compromiso, { headers: headersJson });
};

//ENVIA RECHAZO/AUTORIZACION POR PARTE DEL EJECUTIVO/FUNCIONARIO
export const postAutorizacion = async (compromiso: AutorizaCompromiso) => {
    updateHeader();
    return await axios.post<AutorizaCompromiso>(API  + '/autorizaCompromiso' , compromiso, { headers: headersJson });
};

//ENVIA RECHAZO/AUTORIZACION DE UNA RENOVACIÒN POR PARTE DEL EJECUTIVO/FUNCIONARIO
export const postRenovacion = async ( compromiso: AutorizaCompromiso) => {
    updateHeader();
    return await axios.post<CodigoRespuesta>(API  + '/renuevaCompromiso'  , compromiso, { headers: headersJson });
};


//MARCA UN COMPROMISO COMO CUMPLIDO
export const postCompromisoCumplido= async ( compromisoCumplido: AutorizaCompromiso ) => {
    updateHeader();
    return await axios.post<CodigoRespuesta>(API  + '/compromisoCumplido' , compromisoCumplido, { headers: headersJson });

    //https://swaggerhub.lmig.com/virts/n0359615/lf-compromisos-api/1.0.0/compromisoCumplido?token=rfsdfds
};

//MARCA UN COMPROMISO COMO REVOCADO
export const postCompromisoRevocado= async ( compromisoRevocado: AutorizaCompromiso ) => {
    updateHeader();
    return await axios.post<CodigoRespuesta>(API  + '/revocaCompromiso' , compromisoRevocado, { headers: headersJson });
};

//MARCA UN COMPROMISO COMO REVOCADO
export const postAutorizaPlazoCompromiso= async ( autorizaPlazoCompromiso: AutorizaCompromiso ) => {
    updateHeader();
    return await axios.post<CodigoRespuesta>(API  + '/autorizaPlazoCompromiso' , autorizaPlazoCompromiso, { headers: headersJson });
};


//NOTIFICA A UN FUNCIONARIO CUANDO SE LE HA ASIGNADO UN COMPROMISO PARA AUTORIZAR
export const postNotificaFuncionario= async ( idCompromiso: number, idFuncionario: number ) => {
    updateHeader();
    return await axios.post<CodigoRespuesta>(API  + '/notificaFuncionario?idCompromiso=' + idCompromiso + '&idFuncionario=' + idFuncionario, {},{ headers: headersJson });
};

export const getCompromisosGaran = async (idFiado: number) => {
    updateHeader();
    return await axios.get<GarantiaCompromisoModel []>(`${API}/compromisosGarantias?idFiado=` + idFiado , { headers: headersJson });
}

//NOTIFICA AL RESPONSABLE DE OFICINA CUANDO SE LE HA ASIGNADO UN COMPROMISO PARA AUTORIZAR
export const postNotificaResponsableOficina = async ( idCompromiso: number, idFiado: number ) => {
    updateHeader();
    return await axios.post<CodigoRespuesta>(API  + '/notificaResponsableOficina?idCompromiso=' + idCompromiso + '&idFiado=' + idFiado, {},{ headers: headersJson });
};


//NOTIFICA AL COMITE DE SUSCRIPCION CUANDO SE LE HA ASIGNADO UN COMPROMISO PARA AUTORIZAR
export const postNotificaComiteS = async ( idCompromiso: number, idFiado: number ) => {
    updateHeader();
    return await axios.post<CodigoRespuesta>( API  + '/notificaComite?idCompromiso=' + idCompromiso + '&idFiado=' + idFiado, {},{ headers: headersJson });
};

//NOTIFICA AL SUBCOMITE DE SUSCRIPCION CUANDO SE LE HA ASIGNADO UN COMPROMISO PARA AUTORIZAR
export const postNotificaSubComiteS = async ( idCompromiso: number, idFiado: number ) => {
    updateHeader();
    return await axios.post<CodigoRespuesta>(API  + '/notificaComite?idCompromiso=' + idCompromiso + '&idFiado=' + idFiado, {},{ headers: headersJson });
};

//REVIERTE COMPROMISO CUMPLIDO 
export const postRevierteCompromiso = async ( compromisoRevertido : AutorizaCompromiso) => {
  
    updateHeader();
    return await axios.post<CodigoRespuesta>(API  + '/revierteCompromisoCumplido' , compromisoRevertido, { headers: headersJson });
};

//
export const postRevierteConceptoCumplido = async ( conceptoRevertido : CumpleConcepto) => {

    updateHeader();
    return await axios.post<CodigoRespuesta>(API  + '/revierteConceptoCumplido',  conceptoRevertido , { headers: headersJson });

   // https://lf-sgf-compromisos-api-impl-development.us-east-1.np.paas.lmig.com/lf-compromisos-api/1.0.0/revierteConceptoCumplido?idCompromiso=1559&idConcepto=5&tipoCompromiso=1
};


export const postConceptoCumplido = async (conceptoCumplido : CumpleConcepto) => {
  
    updateHeader();
    return await axios.post<CodigoRespuesta>(API  + '/conceptoCumplido', conceptoCumplido , { headers: headersJson });

    //https://lf-sgf-compromisos-api-impl-development.us-east-1.np.paas.lmig.com/lf-compromisos-api/1.0.0/conceptoCumplido?fechaCumplimiento=05%2F05%2F2022&idCompromiso=1560&idConcepto=6&tipoCompromiso=1
};


export const getPorCumplirFiado = async ( paginacion: PaginacionModel, idFiado: number) => {
    let paginacionQueryParams= '';

    if (paginacion.currentPage){
        paginacionQueryParams += '&page='+ paginacion.currentPage
    }
    if (paginacion.size){
        paginacionQueryParams += '&size='+ paginacion.size
    }

    updateHeader();
    return await axios.get< {paginacion: PaginacionModel, elementos:ElementosCompromiso[] }>(API + '/compromisosPorCumplirFiado?idFiado='+ idFiado + paginacionQueryParams, { headers: headersJson });

    //https://lf-sgf-compromisos-api-impl-development.us-east-1.np.paas.lmig.com/lf-compromisos-api/1.0.0/compromisosPorCumplirFiado?idFiado=1&page=1&size=10
};

    

