import {AddObligorLanguageEnglish, AddObligorLanguageSpanish} from "../add-obligor/AddObligorLanguage";

export interface CreateWarrantyLanguageModel {
    loadingCollaterals: string;
    selectTypeUse: string;
    enterBond: string;
    enterInclusion: string;
    exclusiveToMe: string;
    selectLoans: string;
    loadingOs: string;
    errorOs: string;
    noResults: string;
    results: string;
    selectKey: string;
    selectOption: string;
    maximumAmount: string;
    selectCurrency: string;
    enterAmount: string;
    limitAmount: string;
    selectPercent: string;
    recommendPercent: string;
    weightedAmount: string;
    dateStart: string;
    dateEnd: string;
    errorCollateral: string;
    btnSending: string;
    btnContinue: string;
    iCantSave: string;
    iCantUpdate: string;
    warrantyNoSaved: string;
    //validaciones
    valSelectTypeUse: string;
    valTypeUseExclusive: string;
    valDirFianza: string;
    valDirInclusion: string;
    valselectKey: string;
    valMaxAmount: string;
    valselectCurrency: string;
    valenterAmount: string;
    valdateDate: string;
    valdatePercent: string;
    noValidDateInit: string;
    noValidDateEnd: string;
    sorryNotsaveDir: string;
    minDateInit: string;
    maxDateInit: string;
    minDateEnd: string;
    minDateEndToday: string;
    maxDateEnd: string;
    msgAnyPercent : string;

}

export const CreateWarrantyLanguageEnglish: CreateWarrantyLanguageModel ={
    loadingCollaterals: 'Loading Collaterals',
    selectTypeUse: 'Select a Type of Use',
    selectOption: 'Select an Option',
    enterBond: 'Enter a bond',
    enterInclusion: 'Enter a Inclusion',
    exclusiveToMe: 'Exclusive for another principal',
    selectLoans: 'Write the name of the Principal',
    loadingOs: AddObligorLanguageEnglish.loadingOs,
    errorOs: AddObligorLanguageEnglish.errorOs,
    noResults: AddObligorLanguageEnglish.noResults,
    results: AddObligorLanguageEnglish.results,
    selectKey: 'Select the type of Loan',
    maximumAmount: 'Maximum amount',
    selectCurrency: 'Select a Currency',
    enterAmount: 'Enter an Amount.',
    limitAmount: 'The maximum limit has been exceeded',
    selectPercent: 'Weighting percentage',
    recommendPercent: 'recommended',
    weightedAmount: 'Weighted amount',
    dateStart: 'Effective date',
    dateEnd: 'End of Effective Date',
    errorCollateral: 'Sorry something happened while loading Collaterals, please check your connection and try again.',
    btnSending: 'Sending',
    btnContinue: 'Continue',
    iCantSave: 'Sorry, Couldn\'t Save Warranty.',
    iCantUpdate: 'Sorry, Couldn\'t Modify Loan',
    warrantyNoSaved: 'Sorry, the Loans could not be Saved.',
    valSelectTypeUse: 'Select a Type of Use please.',
    valTypeUseExclusive: 'Select a obligor-details, please',
    valDirFianza: 'Enter a valid bond',
    valDirInclusion: 'Please enter a valid include',
    valselectKey: 'Select a the type of Loan.',
    valMaxAmount: 'Enter the maximum amount',
    valselectCurrency: 'Select a Currency please.',
    valenterAmount: 'Enter a Valid Amount please.',
    valdateDate: 'Please enter a Valid Date.',
    valdatePercent: 'Please enter a Valid Percent.',
    noValidDateInit: 'The Start date is not valid, please enter it again.',
    noValidDateEnd: 'The Final Date is not valid, please enter it again.',
    sorryNotsaveDir: 'Sorry, Failed to Save directed Loan.',
    minDateInit: 'The start date cannot be less than today.',
    maxDateInit: 'The Start Date cannot be greater than the End Date, please enter it again.',
    minDateEnd: 'The End Date must be greater than the Start Date.',
    minDateEndToday: 'The End Date must be at least greater than today',
    maxDateEnd: '',
    msgAnyPercent: 'Any percentage that is not recommended will be subject to approval.',




}
export const CreateWarrantyLanguageSpanish: CreateWarrantyLanguageModel ={
    loadingCollaterals: 'Cargando Garantías',
    selectTypeUse: 'Selecciona un Tipo de Uso',
    selectOption: 'Selecciona una Opción',
    enterBond: 'Ingresa una fianza',
    enterInclusion: 'Ingresa una Inclusion',
    exclusiveToMe: 'Exclusiva para otro fiado',
    selectLoans: 'Escribe el nombre del fiado',
    loadingOs: AddObligorLanguageSpanish.loadingOs,
    errorOs: AddObligorLanguageSpanish.errorOs,
    noResults: AddObligorLanguageSpanish.noResults,
    results: AddObligorLanguageSpanish.results,
    selectKey: 'Selecciona el tipo de garantía',
    maximumAmount: 'Importe máximo',
    selectCurrency: 'Selecciona una Moneda',
    enterAmount: 'Ingresa un Monto',
    limitAmount: 'Se ha superado el límite máximo',
    selectPercent: 'Porcentaje de ponderación',
    recommendPercent: 'recomendado',
    weightedAmount: 'Monto ponderado',
    dateStart: ' Fecha de Inicio de Vigencia',
    dateEnd: 'Fecha Final de Vigencia',
    errorCollateral: 'Lo sentimos algo ha ocurrido al cargar la Garantía, revise su conexión e intente de nuevo.',
    btnSending: 'Enviando',
    btnContinue: 'Continuar',
    iCantSave: 'Lo sentimos, no se ha podido Guardar la Garantía.',
    iCantUpdate: 'Lo sentimos, no se ha podido Modificar la Garantía',
    warrantyNoSaved: 'Lo sentimos, no se ha podido Guardar la Garantía.',
    valSelectTypeUse: 'Selecciona un Tipo de Uso por favor.',
    valTypeUseExclusive: 'Selecciona un obligado a solidario, por favor',
    valDirFianza: 'Introduce una fianza válida',
    valDirInclusion: 'Introduce una inclusión válida',
    valselectKey: 'Selecciona un tipo de garantía.',
    valMaxAmount: 'Ingresa el importe máximo',
    valselectCurrency: 'Selecciona una Moneda por favor.',
    valenterAmount: 'Ingresa un Monto Válido por favor.',
    valdateDate: 'Ingresa una Fecha Válida por favor.',
    valdatePercent: 'Ingresa un porcentaje Válido por favor.',
    noValidDateInit: 'La Fecha Inicial no válida, por favor ingrésala nuevamente.',
    noValidDateEnd: 'La Fecha Final no válida, por favor ingrésala nuevamente.',
    sorryNotsaveDir: 'Lo sentimos, no se ha podido Guardar la Garantía direccionada.',
    minDateInit: 'La Fecha Inicial no puede ser menor al día de hoy.',
    maxDateInit: 'La Fecha Inicial no puede ser mayor a la Fecha Final, por favor ingrésala nuevamente.',
    maxDateEnd: '',
    minDateEnd: 'La Fecha Final tiene que se mayor a la Fecha Inicial.',
    minDateEndToday: 'La Fecha Final tiene que ser por lo menos mayor a hoy',
    msgAnyPercent: 'Cualquier porcentaje que no sea el recomendado será sujeto a aprobación',

}