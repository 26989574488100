import React, {useState} from 'react';
import {ButtonGroup, Dropdown} from "react-bootstrap";
import {Button, IconSeeMore, Modal} from "@lmig/lmds-react";
import {useHistory} from "react-router-dom";
import {ButtonStatusWarranty} from "../button-status-warranty/ButtonStatusWarranty";
import {UserModel} from "../../../models/public/UserModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {validRoles} from "../../../class/CommonClass";
import {estadoVigenciaGarantia} from "../../../models/garantias/EstadoVigenciaGarantia";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import environment from '../../../environments/environment';

interface StatusWarrantyProps {
    idWarranty: number;
    folio: number;
    version: number;
    idPrincipal: number;
    idLote?: number;
    estadoVigencia: number,
    descEstadoVigencia: string,
    revisar?: boolean
    returnDelete: (del: boolean) => void;


}

export const StatusRowWarranty: React.FC<StatusWarrantyProps> = ({ idWarranty, folio, version ,idPrincipal ,idLote , estadoVigencia, descEstadoVigencia, revisar, returnDelete}:StatusWarrantyProps)=> {

    const languageState = useSelector((state: RootStore) => state.language.language?.statusRowWarranty);
    const history = useHistory();
    const  localStorageClass = new LocalStorageClass();
    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [modalDelete, setModalDelete] = useState<boolean>(false);

    const [deletingWar, setDeletingWar] = useState<boolean>(false);

    const [errorDeletingWar, setErrorDeletingWar] = useState<boolean>(false);

    const [msgSuccessBaja, setMsgSuccessBaja] = useState<string>('');

    const editWarranty = () => {
        history.push('/garantias/fiado/' + idPrincipal + '/garantia/'+ idWarranty)
    };

    const reviewWarranty = () => {
        history.push('/garantias/revision/fiado/' + idPrincipal + '/garantia/' + idWarranty)
    };
    const WarrantieHistory = () => {
        history.push(`/garantias/historial?fiado=${idPrincipal}&folio=${folio}`)
    };
    const deleteWarranty = async () => {
        setDeletingWar(true);
        setMsgSuccessBaja('');
        setErrorDeletingWar(false);
        try {
            const res = await garantiasService.deleteBajaGarantia(idWarranty);
            setDeletingWar(false);
            if (res.status == 202){
                setErrorDeletingWar(true);
                setMsgSuccessBaja(res.data.mensaje);

            }else{
                setErrorDeletingWar(false);
                returnDelete(true);
            }



        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                setDeletingWar(false);
                setErrorDeletingWar(true);
            }
        }

    };
    
    return (
        <>
            {(validRoles(PERFIL.roles, localStorageClass.AUDITOR) &&
            (validRoles(PERFIL.roles, localStorageClass.AGENTE)  ||
                validRoles(PERFIL.roles, localStorageClass.PROMOTOR ))

            ) ? (
                <>
                    <Dropdown as={ButtonGroup} >
                        <ButtonStatusWarranty
                            status={estadoVigencia}
                            description={descEstadoVigencia} />

                        <Dropdown.Toggle split variant="light" id="dropdown-split-basic" >
                            <IconSeeMore></IconSeeMore>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={ () => {WarrantieHistory()}}>
                                {languageState?.history}
                            </Dropdown.Item>
                        </Dropdown.Menu>

                    </Dropdown>


                </>
            ):
                <Dropdown as={ButtonGroup} >

                    <ButtonStatusWarranty
                        status={estadoVigencia}
                        description={descEstadoVigencia} />
                    <Dropdown.Toggle split variant="light" id="dropdown-split-basic" >
                        <IconSeeMore></IconSeeMore>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>

                        {(validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS) ) ? (

                            <>
                                {(estadoVigencia == estadoVigenciaGarantia.enCaptura)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>
                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.enviada)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.enRevision)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>
                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.autorizada)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={ () => {setModalDelete(true)}}>
                                            {languageState?.delete}
                                        </Dropdown.Item>
                                    </>: null
                                }

                                {(estadoVigencia == estadoVigenciaGarantia.rechazada)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={ () => {setModalDelete(true)}}>
                                            {languageState?.delete}
                                        </Dropdown.Item>
                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.vencida)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={ () => {setModalDelete(true)}}>
                                            {languageState?.delete}
                                        </Dropdown.Item>
                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.sustituida)?
                                    <>

                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.baja)?
                                    <>

                                    </>: null
                                }

                            </>
                        ): null
                        }

                        {(validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA) &&
                            !validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS)) ? (
                            <>
                                {(estadoVigencia == estadoVigenciaGarantia.enCaptura)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>
                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.enviada)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.enRevision)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>
                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.autorizada)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>
                                    </>: null
                                }

                                {(estadoVigencia == estadoVigenciaGarantia.rechazada)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>


                                    </>: null
                                }

                                {(estadoVigencia == estadoVigenciaGarantia.vencida)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>
                                    </>: null
                                }

                                {(estadoVigencia == estadoVigenciaGarantia.sustituida)?
                                    <>

                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.baja)?
                                    <>
                                    </>: null
                                }
                            </>
                        ): null
                        }

                        {/*Que sea puro auditor */}
                        {(validRoles(PERFIL.roles, localStorageClass.AUDITOR) &&
                        !validRoles(PERFIL.roles, localStorageClass.AGENTE) &&
                        !validRoles(PERFIL.roles, localStorageClass.PROMOTOR)) ? (
                            <>
                                <Dropdown.Item
                                    onClick={ () => {reviewWarranty()}}>
                                    {languageState?.review}
                                </Dropdown.Item>


                            </>
                        ): null
                        }


                        {(validRoles(PERFIL.roles, localStorageClass.AGENTE) &&
                            !validRoles(PERFIL.roles, localStorageClass.AUDITOR)) ? (
                            <>
                                {(estadoVigencia == estadoVigenciaGarantia.enCaptura)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>
                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.enviada)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.enRevision)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>
                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.autorizada)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>
                                    </>: null
                                }

                                {(estadoVigencia == estadoVigenciaGarantia.rechazada)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>


                                    </>: null
                                }

                                {(estadoVigencia == estadoVigenciaGarantia.vencida)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>
                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.sustituida)?
                                    <>

                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.baja)?
                                    <>

                                    </>: null
                                }
                            </>
                        ): null
                        }


                        {(validRoles(PERFIL.roles, localStorageClass.PROMOTOR) &&
                            !validRoles(PERFIL.roles, localStorageClass.AUDITOR)) ? (
                            <>
                                {(estadoVigencia == estadoVigenciaGarantia.enCaptura)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>
                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.enviada)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.enRevision)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>
                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.autorizada)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>
                                    </>: null
                                }

                                {(estadoVigencia == estadoVigenciaGarantia.rechazada)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>


                                    </>: null
                                }

                                {(estadoVigencia == estadoVigenciaGarantia.vencida)?
                                    <>
                                        <Dropdown.Item
                                            onClick={ () => {reviewWarranty()}}>
                                            {languageState?.review}
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            onClick={ () => {editWarranty()}}>
                                            {languageState?.edit}
                                        </Dropdown.Item>
                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.sustituida)?
                                    <>

                                    </>: null
                                }
                                {(estadoVigencia == estadoVigenciaGarantia.baja)?
                                    <>

                                    </>: null
                                }
                            </>
                        ): null
                        }

                        <Dropdown.Item
                            onClick={ () => {WarrantieHistory()}}>
                            {languageState?.history}
                        </Dropdown.Item>


                    </Dropdown.Menu>
                </Dropdown>
            }




            <Modal
                isOpen={modalDelete}
                size="medium"
                modalStyle="standard"
                title={languageState?.titleModalDel! + ' ' + folio + ((version> 0 || null)? '-' + version: '') }
                closeFunc={() =>setModalDelete(false)}>

                <h5 className="text-center">

                    {(msgSuccessBaja.length == 0 )? (languageState?.areYouSureDel): msgSuccessBaja }

                </h5>

                <br/>
                <div className="row">
                    <div className="col-md-6 col-12">
                        <Button
                            dynamicWidth
                            variant="secondary"
                            onClick={()=>{
                                setModalDelete(false);
                            }}>
                            {languageState?.cancelDel}
                        </Button>
                    </div>
                    <div className="col-md-6 col-12">


                        {!deletingWar ? (
                                <Button
                                    dynamicWidth
                                    variant="primary"
                                    onClick={()=>{
                                        deleteWarranty();
                                    }}>
                                    {languageState?.confirmDel}
                                </Button>
                            ):
                            <ButtonSpinner label={languageState?.cancellingWar!}/>
                        }

                    </div>

                </div>

            </Modal>


        </>
    );
};




