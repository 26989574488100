export interface SelectProductosLineaLanguage {
    loadingRamos: string;
    loadingProducts: string;
    errorLoadingProducts: string;
    errorLoadingRamos: string;
    products: string;
    selectProduct: string;
    searchProduct: string;
    selectAllProducts: string;
    allProductsSelected: string;
    ramos: string;
    selectBranch: string;
    searchBranch: string;
    selectAllBranch: string;
    allBranchSelected: string;

}

export const SelectProductosLineaEnglish: SelectProductosLineaLanguage = {
    loadingProducts: '',
    loadingRamos: '',
    errorLoadingProducts: 'Sorry, the products could not be loaded',
    errorLoadingRamos: 'Sorry, the bouquets could not be loaded',
    ramos: 'Branch',
    selectBranch:'Select a Branch',
    searchBranch: 'Search Branch ',
    selectAllBranch: 'Select all',
    allBranchSelected: 'All Branchs are selected',
    products: 'Products',
    selectProduct:'Select a product',
    searchProduct: 'Search Product ',
    selectAllProducts: 'Select all',
    allProductsSelected: 'All products are selected'


}
export const SelectProductosLineaSpanish: SelectProductosLineaLanguage = {
    loadingRamos: '',
    loadingProducts: '',
    errorLoadingProducts: 'Lo sentimos, no se han podido cargar los productos',
    errorLoadingRamos: 'Lo sentimos, no se han podido cargar los ramos',
    ramos: 'Ramos',
    selectBranch:'Selecciona un Ramo',
    searchBranch: 'Buscar un Ramo ',
    selectAllBranch: 'Seleccciona todos los ramos ',
    allBranchSelected: 'Todos los ramos está seleccionados',
    products: 'Productos',
    selectProduct: "Selecciona un producto",
    searchProduct: 'Buscar Producto',
    selectAllProducts:'Seleccionar todos',
    allProductsSelected: 'Todos los productos están seleccionados'


}
