export interface LanguageModelDepositosGarantias {
    //Principal
    titlePage: string,
    searchBar: string,
    btnUpload: string,
    reference: string,
    amount: string,
    date: string,
    depositor: string,
    bond: string,
    surety: string,
    status: string,
    actions: string,
    statusApplied: string,
    statusNotApplied: string,
    applyManualPayment: string,
    generateReceipt: string,
    checkReceipt: string,
    showing: string,
    showingTo: string,
    showingTotal: string,
    records: string,

    //Filtros principales
    chkReferencesG: string,
    chkUnreconciled: string,
    chkReconciled: string,
    //filtro estadisticas
    chkWatch: string,

    //ModalCargaArchivo
    btnSend: string,
    lblSelectFile: string,
    lblErrorFormat: string,
    lblFileUpload: string,
    lblLoad: string,
    lblUploadingFile: string,
    //modal envio Error
    lblMsjErrorSend: string,
    lblMsjError401: string,

    //Carga
    loadingStatics: string,
    loadingReferences: string,

    //msg busqueda
    noResults: string,


    //ModalPDf
    btnSign: string,
    btnDownload: string,
    lblReceiptError: string,
    lblProcessOfSigning: string,

    //modal-autorizar-pago
    lblAuthorizePayment: string,
    lblToAuthorize: string,
    btnApply: string,

    //modal aplicar pago
    lblApplyPayment: string,
    lblResourceOwner: string,
    //error aplicar pago
    lblErrorApplyPayment: string,

    //generales?
    btnAccept: string,
    btnCancel: string,

    //msgExito
    lblReferenceApplied: string,
    lblAppliedAorrectly: string,
    lblSentSuccess: string,

    //mensaje fecha
    lblDateINoValid: string,
    lblDateIElderly: string,


    lblDateFNoValid: string,
    lblDateFminor: string,


}

export const languageDepostitosGarantiasIngles: LanguageModelDepositosGarantias = {
    //Principal
    titlePage: 'Deposits in Guarantee',
    searchBar: 'Search',
    btnUpload: 'Upload file',
    reference: 'Reference',
    amount: 'Amount',
    date: 'Payment date',
    depositor: 'Depostitor',
    bond: 'Bond',
    surety: 'Surety',
    status: 'Status',
    actions: 'Actions',
    statusApplied: 'Applied',
    statusNotApplied: 'Without applying',
    applyManualPayment: 'Apply manual payment',
    generateReceipt: 'Generate receipt',
    checkReceipt: 'check receipt',
    showing: 'Showing records from',
    showingTo: 'to',
    showingTotal: 'out of a total of',
    records: 'records',

    //Filtros principales
    chkReferencesG: 'References generated',
    chkUnreconciled: 'Unreconciled',
    chkReconciled: 'Reconciled',
    //filtro estadisticas
    chkWatch: 'Watch',

    //ModalCargaArchivo
    btnSend: 'Send',
    lblSelectFile: 'Click "Upload" or drag a file.',
    lblErrorFormat: 'Format not supported.',
    lblFileUpload: 'File upload',
    lblLoad: 'Load',
    lblUploadingFile: 'Uploading File',
    //modal envio Error
    lblMsjErrorSend: 'Error during file processing.',
    lblMsjError401: 'Operation not allowed.',


    //Carga
    loadingStatics: 'Loading statistics',
    loadingReferences: 'Loading references',

    //msg busqueda
    noResults: 'No results found.',

    //ModalPDf
    btnSign: 'Sign',
    btnDownload: 'Download',
    lblReceiptError: 'Unexpected error.',
    lblProcessOfSigning: 'Receipt in the process of signing.',

    //modal-autorizar-pago
    lblAuthorizePayment: 'Authorize Payment',
    lblToAuthorize: 'Are you sure you want to authorize the payment',
    btnApply: 'Apply',

    //modal aplicar pago
    lblApplyPayment: 'Apply Payment',
    lblResourceOwner: 'Resource owner',
    //error aplicar pago
    lblErrorApplyPayment: 'An error occurred applying the reference, please try again later.',

    //generales?
    btnAccept: 'Accept',
    btnCancel: 'Cancel',
    //msgExito
    lblReferenceApplied: 'The reference was applied correctly',
    lblAppliedAorrectly: 'Applied correctly',
    lblSentSuccess: 'Sent Successfully',

    //mensaje fecha
    lblDateINoValid: 'The start date is not valid, please enter it again.',
    lblDateIElderly: 'The start date cannot be greater than the end date, please enter it again.',


    lblDateFNoValid: 'Invalid end date, please enter it again.',
    lblDateFminor: 'The end date cannot be less than the start date.',


}

export const languageDepostitosGarantiasEspaniol: LanguageModelDepositosGarantias = {
    //Principal
    titlePage: 'Depósitos en Garantía',
    searchBar: 'Buscar',
    btnUpload: 'Cargar archivo',
    reference: 'Referencia',
    amount: 'Importe',
    date: 'Fecha pago',
    depositor: 'Depositante',
    bond: 'Fianza',
    surety: 'Fiado',
    status: 'Estatus',
    actions: 'Acciones',
    statusApplied: 'Aplicado',
    statusNotApplied: 'Sin aplicar',
    applyManualPayment: 'Aplicar pago manual',
    generateReceipt: 'Generar recibo',
    checkReceipt: 'Consultar recibo',
    showing: 'Mostrando registros del',
    showingTo: 'al',
    showingTotal: 'de un total de ',
    records: 'registros',
    //Filtros principales
    chkReferencesG: 'Referencias generadas',
    chkUnreconciled: 'Sin conciliar',
    chkReconciled: 'Conciliados',
    //filtro estadisticas
    chkWatch: 'Ver',
    //ModalCargaArchivo
    btnSend: 'Enviar',
    lblSelectFile: 'Click o arrastra para seleccionar un archivo.',
    lblErrorFormat: 'Formato no soportado.',
    lblFileUpload: 'Cargar Archivo',
    lblLoad: 'Cargar',
    lblUploadingFile: 'Subiendo Archivo',
    //modal envio Error
    lblMsjErrorSend: 'Error durante el procesamiento del archivo.',
    lblMsjError401: 'Operación no permitida.',

    //modal Carga
    loadingStatics: 'Cargando estadísticas',
    loadingReferences: 'Cargando referencias',


    //msg busqueda
    noResults: 'No se han encontrado resultados.',

    //ModalPDf
    btnSign: 'Firmar',
    btnDownload: 'Descargar',
    lblReceiptError: 'Error inesperado.',
    lblProcessOfSigning: 'Recibo en proceso de firma.',

    //modal-autorizar-pago
    lblAuthorizePayment: 'Autorizar Pago',
    lblToAuthorize: 'Está seguro que desea autorizar el pago',
    btnApply: 'Aplicar',

    //modal aplicar pago
    lblApplyPayment: 'Aplicar Pago',
    lblResourceOwner: ' Propietario de recursos',
    //error aplicar pago
    lblErrorApplyPayment: 'Ha ocurrido un error aplicando la referencia, intentelo de nuevo mas tarde.',

    //generales?
    btnAccept: 'Aceptar',
    btnCancel: 'Cancelar',

    //msgExito
    lblReferenceApplied: 'Se aplico la referencia correctamente',
    lblAppliedAorrectly: 'Se aplico correctamente',
    lblSentSuccess: 'Enviado Exitosamente',

    //mensaje fecha
    lblDateINoValid: 'La fecha inicial no válida, por favor ingrésala nuevamente.',
    lblDateIElderly: 'La fecha inicial no puede ser mayor a la fecha final, por favor ingrésala nuevamente.',


    lblDateFNoValid: 'La fecha final no válida, por favor ingrésala nuevamente.',
    lblDateFminor: 'La fecha final no puede ser menor a la fecha inicial.',
    //ModalDetalle
}