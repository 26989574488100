import React, {useEffect, useState} from 'react';
import './ExpedientIdentification.scss';
import {IconErrorAlert, IconSuccessAlert, Modal} from "@lmig/lmds-react";
import {ExpedienteIdetificacionModel} from "../../../models/garantias/ExpedienteIdetificacionModel";
import {initExpedienteIdentification} from "../../../class/CommonClass";
import * as garantiasService from '../../../services/garantias/GarantiasService';
import {AxiosError} from "axios";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import {RootStore} from "../../../Store";
import { useSelector} from "react-redux";
import {TypeDocModel} from "../../../models/garantias/TypeDocModel";
import {statesDocIdentification} from "../../../models/garantias/statesDocIdentification";
import {DocExpedienteIdentificacion} from "../doc-expediente-identificacion/DocExpedienteIdentificacion";
import {ModalAuthorizationExpediente} from "../modal-authorization-expediente/ModalAuthorizationExpediente";
import {ModalRejectExpediente} from "../modal-reject-expediente/ModalRejectExpediente";
import {UserModel} from "../../../models/public/UserModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";

interface ExpedientIdentificationProps {
    idPrincipal: number;
    showButtons: boolean;

}


export const ExpedientIdentification: React.FC<ExpedientIdentificationProps> = ({ idPrincipal, showButtons}:ExpedientIdentificationProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    const localStorageClass = new LocalStorageClass();

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [openModalExpedient, setOpenModalExpedient] = useState<boolean>(false);

    const [listExpediente, setListExpediente] = useState<TypeDocModel []>([]);

    const [loadingExpedient, setLoadingExpedient] = useState<boolean>(true);

    const [errorExpediente, setErrorExpediente] = useState<boolean>(true);

    const [expedientComplete, setExpedientComplete] = useState<boolean>(false);

    const [idDocAuthorize, setIdDocAuthorize] = useState<number>(0);

    const [idDocReject, setIdDocReject] = useState<number>(0);



    useEffect(() => {
        getExpedienteCompleto(idPrincipal);
    },[idPrincipal]);

    const getExpedienteCompleto = async (idPrincipal: number) => {
        setLoadingExpedient(true);
        setErrorExpediente(false);
        try {
            const res = await garantiasService.getExpedienteCompleto(idPrincipal);
            setExpedientComplete(res.data.expedienteCompleto);
            setLoadingExpedient(false);
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingExpedient(false);
                setErrorExpediente(true);

            }
        }
    }
    const getExpedientIdentification =  async (idPrincipal: number) => {
        setLoadingExpedient(true);
        setErrorExpediente(false);
        try {
            const res = await garantiasService.getExpedienteIdentificacion(idPrincipal);
            await agruparDocPorTipo(res.data);
            setLoadingExpedient(false);
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingExpedient(false);
                setErrorExpediente(true);

            }
        }
    }

    // reformatear y reorganizar por categorias tomando idTipoDocumento como criterio
    const agruparDocPorTipo = (listExpedientes: ExpedienteIdetificacionModel[]) => {

        const listDocuments: TypeDocModel [] = []

        let expedientAprove = true;

        listExpedientes.map((i, index) =>
            {

                // encontramos un documento igual
                const auxDoc: ExpedienteIdetificacionModel = i
                let documento: TypeDocModel ={
                    id: i.idTipoDocumento,
                    nombre: i.descripcionTipoDocumento,
                    documents: [auxDoc]
                };

                for (let j= listExpedientes.length-1; j> index; j--){
                    // console.log(i.descripcion);
                    //  console.log(i.idTipoDocumento +'=='+ listExpedientes[j].idTipoDocumento)
                    if (i.idTipoDocumento == listExpedientes[j].idTipoDocumento){

                        // encontramos un documento igual
                        const auxDoc: ExpedienteIdetificacionModel = listExpedientes[j]
                        documento.documents.push(auxDoc);
                        // borrar el registro
                        listExpedientes.splice(j,1);

                    }
                }

                listDocuments.push(documento);

            }
        )

        // verificar que todos los documents sean aprobados o estaen guardados en docuware
        for(const expediente of listDocuments){
            for(const doc of expediente.documents){
                if (doc.estado == statesDocIdentification.autorizado ||
                    doc.estado == statesDocIdentification.guardadoDW){

                }else {
                    expedientAprove = false
                    break;
                }
            }
            if (!expedientAprove){
                break
            }
        }

        listDocuments.forEach((i, index) => {
            i.documents.forEach((j, index2) => {
                if (j.idDocumento == null){
                    i.documents.splice(index2, 1)
                }
            })
        })

        setListExpediente(listDocuments);
        setLoadingExpedient(false);
        setExpedientComplete(expedientAprove)

    }

    const openModal = () => {
        getExpedientIdentification(idPrincipal);
        setOpenModalExpedient(true);

    }

    const moveExpediente = (idDestinity: number, idDocumento: number)=> {

        const auxListExpedientes: TypeDocModel []= listExpediente;
        let documentFound: ExpedienteIdetificacionModel = initExpedienteIdentification;

        auxListExpedientes.forEach((i, index) => {
            i.documents.forEach((j, index2) => {
                if (idDocumento == j.idDocumento){
                    documentFound = j;
                    i.documents.splice(index2, 1)
                }
            })
        })

        documentFound.idTipoDocumento = idDestinity

        putExpedienteIdentificacion(documentFound);



    }
    const putExpedienteIdentificacion = async  (expediente: ExpedienteIdetificacionModel) => {
        let auxListExpedientes= listExpediente;
        setLoadingExpedient(true);
        try {
            const res = await garantiasService.putExpedienteIdentificacion(expediente);

            getExpedientIdentification(idPrincipal);
            auxListExpedientes.forEach((value, index) => {
                if (value.id == res.data.idTipoDocumento){
                    value.documents.push(res.data);
                }
            })

            console.log(auxListExpedientes);
            setLoadingExpedient(false);
            setListExpediente(auxListExpedientes)



        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                // quitar el spiner de loading
                setLoadingExpedient(false);

            }
        }
    }




    return (
        <>
            <div id="expedient-identification">
                {/* quitar al manita en el agente y ejecutivo de garantías
                bloque y desbloqueo por comisión junto con un campo de texto
                no se te olvide cambiar las labels fiado bloqueado y bloqueo de comisión
                el bloqueo de comisión solo lo pued ever el jecutiovo de garantías
                */}

                {loadingExpedient?(
                        <SpinnerLoading/>
                    ):
                    errorExpediente?(
                            <NegativeAlert
                                textAlert={languageState?.language?.expedientIdentificaction.ExpedientComplete!}/>
                        ):
                        <>
                            <a className="open-expedient text-center"
                               onClick={() => {
                                   openModal()
                               }}>
                                {expedientComplete?
                                    <>
                                        <IconSuccessAlert
                                            className="d-block mx-auto"
                                            size="48"
                                            color="success"
                                            title= {languageState?.language?.expedientIdentificaction.ExpedientComplete}/>

                                        <div className="text-success status-expedient">
                                            {languageState?.language?.expedientIdentificaction.ExpedientComplete}
                                        </div>
                                    </> :
                                    <>
                                        <IconErrorAlert
                                            className="d-block mx-auto"
                                            size="48"
                                            color="error"
                                            title={languageState.language?.expedientIdentificaction.ExpedientIncomplete}/>

                                        <div className="text-danger status-expedient">
                                            {languageState.language?.expedientIdentificaction.ExpedientIncomplete}
                                        </div>
                                    </>
                                }
                            </a>

                        </>

                }

                <Modal
                    isOpen={openModalExpedient}
                    size="large"
                    className="modal-expedient-identification"
                    modalStyle="standard"
                    title= {languageState.language?.expedientIdentificaction.ExpedientTitleModal}
                    closeFunc={() =>
                    {
                        setOpenModalExpedient(false)
                    }}>

                    {
                        !loadingExpedient?
                            listExpediente.map((iTypeDoc, index) =>
                                (
                                    <div key={index}>
                                        <DocExpedienteIdentificacion
                                            idPrincipal={idPrincipal}
                                            typeDoc={iTypeDoc}
                                            moveExpediente={moveExpediente}
                                            authorizeDoc={idDoc => setIdDocAuthorize(idDoc)}
                                            rejectDoc={idDoc => setIdDocReject(idDoc)}
                                        />
                                    </div>

                                )) :
                            <SpinnerLoading text={languageState.language?.expedientIdentificaction.loadingExpedient}/>
                    }
                </Modal>



                <ModalAuthorizationExpediente
                    idDoc={idDocAuthorize}
                    resetIdDoc={() => {
                        setIdDocAuthorize(0);
                        getExpedientIdentification(idPrincipal);
                    }}
                />

                <ModalRejectExpediente
                    idDoc={idDocReject}
                    resetIdDoc={() => {
                        setIdDocReject(0);
                        getExpedientIdentification(idPrincipal);

                    }}
                />

            </div>

        </>
    );
};
