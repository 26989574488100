import React, { useEffect, useState} from 'react';
import './LockPrincipal.scss';
import {
    Button, Modal,
} from "@lmig/lmds-react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import {MotivoRechazoModel} from "../../../models/garantias/MotivoRechazoModel";
import {ConditionOptionModel} from "../../../models/public/ConditionOptionModel";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import Select from "react-select";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";
import {SingleValue} from "react-select/dist/declarations/src/types";
import {BloqueoCliente} from "../../../models/garantias/BloqueoCliente";
import {InputChange} from "../../../class/CommonClass";
import {dispatchPutLockPrincipal} from "../../../actions/FiadoActions";
import {TextAlert} from "../../public/text-alert/TextAlert";

interface LockPrincipalProps {
    idPrincipal: number;
    showModal: boolean;
    namePrincipal?: string;
    clickShowModal: (show: boolean, idPrincipal?: number) => void;

}

export const LockPrincipal: React.FC<LockPrincipalProps> = ({idPrincipal, showModal, namePrincipal, clickShowModal}:LockPrincipalProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language.language?.lockPrincipal);

    const dispatch = useDispatch();

    const initReason:MotivoRechazoModel ={ idMotivo: 0, descripcion: ''}

    const [listReasons, setListReasons] = useState<MotivoRechazoModel []>([]);

    const [loadingReasons, setLoadingReasons] = useState<boolean>(true);

    const [errorReaseons, setErrorReaseons] = useState<boolean>(true);

    const [sendingAuthorization, setSendingAuthorization] = useState<boolean>(false);

    const [listConditionalOption, setListConditionalOption] = useState<ConditionOptionModel[]>([]);

    const [selectedReason, setSelectedReason] = useState<MotivoRechazoModel>(initReason);

    const [descReason, setDescReason] = useState<string>('');

    const [errorLockPrincipal, setErrorLockPrincipal] = useState<boolean>(true);

    const [msgValidLock, setMsgValidLock] = useState<string>('');

    const openModal = async () => {
        setLoadingReasons(true);
        setErrorReaseons(false);
        setMsgValidLock('');
        setDescReason('');
        setSelectedReason(initReason)
        try {
            const res = await garantiasService.getMotivosBloqueo();
            setListReasons(res.data);
            formatList(res.data);
            setLoadingReasons(false);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setErrorReaseons(true);
                setLoadingReasons(false);

            }
        }
    }

    const formatList = (rejectList: MotivoRechazoModel[]) => {
        let  listaCondicionalAuxiliar: ConditionOptionModel[] = [];
        for(const i of rejectList){
            listaCondicionalAuxiliar.push({
                name: '',
                value: i.idMotivo,
                label: i.descripcion,
                status: 0})
        }
        setListConditionalOption(listaCondicionalAuxiliar);
    }

    
    const putLockPrincipal = async(idPrincipal: number, motivo: MotivoRechazoModel, descripcion: string) => {
        setLoadingReasons(true);
        setErrorLockPrincipal(false);
        const bloqueoCliente:  BloqueoCliente = {
            descripcion: descripcion,
            motivo: motivo
        }
        try {
            const res = await garantiasService.putBloqueaCliente(idPrincipal, bloqueoCliente);
            setSelectedReason(initReason)
            setDescReason('');
            setLoadingReasons(false);
            clickShowModal!(false);
            dispatch(dispatchPutLockPrincipal(idPrincipal));

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingReasons(false);
                setErrorLockPrincipal(true);

            }
        }
    }

    const handleInputChangeSelect = (e: SingleValue<ConditionOptionModel>) => {

        console.log(e)
        const auxselectedReasons: MotivoRechazoModel [] = []

        setSelectedReason({idMotivo: e!.value, descripcion: e!.label});
    }

    const handleInputChange = (e: InputChange) =>{
        setDescReason( e.target.value );
    }
    
    const onClickLock = () => {
        console.log(selectedReason.idMotivo);
        console.log(descReason)
        if (selectedReason.idMotivo == 0){
            setMsgValidLock(languageState?.msgValidReason!)
        }else if (descReason == ''){
            setMsgValidLock(languageState?.msgValidDescription!)
        }else if(selectedReason.idMotivo && descReason != ''){
            putLockPrincipal(idPrincipal, selectedReason, descReason);
        }

    }
    
    

    useEffect(() => {
        if (showModal){
            openModal();
        }
    },[showModal]);


    return (
        <>

            <Modal
                isOpen={showModal}
                size="medium"
                modalStyle="standard"
                title=""
                closeFunc={() => {
                    clickShowModal(false);
                }}

            >
                <div id="modal-lock-principal">
                    {loadingReasons?(
                            <div className="col-12">
                                <SpinnerLoading />
                            </div>
                        ):
                        (!errorReaseons)? (
                                <>
                                  <div className="col-12 list-reject">
                                      <h4>{namePrincipal? languageState?.titleModalto + ' ' + namePrincipal: languageState?.titleModal}</h4>

                                      <Select
                                            placeholder={languageState?.selectReasons}
                                            name="blocks"
                                            className="options-block"
                                            options={listConditionalOption}
                                            onChange={handleInputChangeSelect}
                                        />

                                        {listReasons.length == 0? (
                                            <div className="col-12">
                                                <br/><br/>
                                                <h5>{languageState?.reintent!}</h5>
                                            </div>
                                        ): null}

                                        <div className="add-description">{languageState?.addDescription}<br/></div>
                                        <textarea
                                            className="form-control"
                                            onChange={handleInputChange}
                                            value={descReason}
                                            rows={5}>
                                </textarea>


                                    </div>
                                    <div className="row" id="buttons">
                                        <div className="col-12">
                                            <TextAlert
                                                textAlert={msgValidLock}/><br/>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <Button
                                                dynamicWidth
                                                variant="secondary"
                                                onClick={()=>{
                                                    clickShowModal(false);
                                                }}>
                                                {languageState?.close!}
                                            </Button>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            {!sendingAuthorization ? (
                                                    <Button
                                                        dynamicWidth
                                                        variant="primary"
                                                        onClick={()=>{
                                                            onClickLock();
                                                        }}>
                                                        {languageState?.lock!}
                                                    </Button>
                                                ):
                                                <ButtonSpinner label={languageState?.lock!}/>
                                            }

                                        </div>
                                    </div>
                                </>
                            ):
                            <div className="col-12">
                                <NegativeAlert
                                    textAlert={languageState?.sorryReason!}/>
                            </div>
                    }
                </div>







            </Modal>
        </>
    );
};