import React, { Component } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import { AxiosError } from 'axios';
import './BajasReclamaciones.scss';
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import NavBar from "../../../components/public/nav-bar/NavBar";
import { SpinnerLoading } from "../../../components/public/spinner-loading/SpinnerLoading";
import { Dropdown, Pagination, Card, CardDeck, Badge as BadgeBootstrap } from 'react-bootstrap';
import {
    Content,
    Radio,
    RadioGroup,
    Table,
    TableCell,
    TableRow,
    Link as LinkLiberty,
    CheckboxGroup,
    Checkbox,
    IconButton,
    IconDownload,
    Modal,
    Heading,
    Button,
    IconEdit,
    GridRow,
    GridCol,
    Form,
    FieldGroup,
    AlphanumericInput,
    IconSeeMore,
    IconSuccessAlert,
    IconHelp,
    IconErrorAlert,
    IconInfo,
    Badge,
    IconWarning

} from "@lmig/lmds-react";
import { BajaReclamacionModel } from '../../../models/juridico/BajaReclamacionModel'
import { formatCurrency } from "../../../class/CommonClass";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import * as apiService from '../../../services/juridico/JuridicoService';
import { PaginacionModel } from "../../../models/public/PaginacionModel";



interface BajasReclamacionesProps {
    language: DefaultStateI
}

class BajasReclamaciones extends Component<BajasReclamacionesProps & RouteComponentProps, BajasReclamacionesState>{


    public localStorageClass = new LocalStorageClass();

    constructor(props: any) {
        super(props);
        document.title = "Bajas Reclamaciones"

        this.state = {
            listaBajaReclamacion: [],
            listPaginacion: [],
            numPaginas: 1,
            currentPage: 1,
            numTotalBajas: 0,
            numRegistrosMin: 0,
            numRegistrosMax: 0,
            loadingBajas: false,
            modalCarta: false,
            modalExito: false,
            modalError: false,
            modalSolicitud: false,
            cveBajaRec: 0,
            estado: 0,
            cveRec: '',
            tipoRec: true,
            filterCheckBox: {
                confirmada: false,
                porConfirmar: true,
            },
            lblSolicitud: '',
            lblError: '',
            lblExito: '',
            loadingSolicitud: false,
            modalEliminar: false,
            loadingInfoCarta: false,
            infoCarta: {
                noReq: '',
                noCheque: '',
            },
            cambio: false,
            loadingEliminarSolicitud: false,

        }

    }

    descargaDocumentos = () => {
        const URL = 'https://www.orimi.com/pdf-test.pdf'
        window.open(URL, "ventana1", "left=100,top=100,width=700,height=700,scrollbars=NO")
    }

    openModalSolicitud = (cveBajaRec: number, estado: number, cveRec: string) => {

        //SE ASIGNA + 1 AL ESTADO PARA SOLICITAR EL SIGUIENTE
        estado += 1;
        this.setState({
            modalSolicitud: true,
            cveBajaRec: cveBajaRec,
            estado: estado,
            cveRec: cveRec,
            lblSolicitud: estado === 2 ? 'La solicitud de baja sera notificada para su autorización' : 'La solicitud de baja sera enviada a Sun Systems para ser contabilizada'
        })
    }
    openModalCarta = (cveBajaRec: number, cveRec: string) => {
        this.setState({
            modalCarta: true,
            cveBajaRec: cveBajaRec,
            cveRec: cveRec,
        })
        this.getInfoCarta(cveBajaRec);
    }
    openModalEliminar = (cveBajaRec: number, cveRec: string) => {
        this.setState({
            modalEliminar: true,
            cveBajaRec: cveBajaRec,
            cveRec: cveRec,
        })
    }

    public async getBajas(tipo: number, page: number, size: number) {
        this.setState({
            loadingBajas: true,

        })
        try {
            page > 1 ? this.setState({ numRegistrosMin: 1 + (10 * (page - 1)) }) : this.setState({ numRegistrosMin: 1 });
            page > 1 ? this.setState({
                numRegistrosMax:
                    ((10 * page) > this.state.numTotalBajas ?
                        this.state.numTotalBajas : ((10 * page - 1) + this.state.numTotalBajas % this.state.numPaginas)
                    )
            }) :
                this.setState({ numRegistrosMax: 10 });
            const res = await apiService.getBajas(tipo, page, size);
            const response: { paginacion: PaginacionModel, detalleBajas: BajaReclamacionModel[] } = res.data;
            if (response.detalleBajas === null || response.detalleBajas.length === 0) {
                this.setState({
                    listaBajaReclamacion: [],
                    loadingBajas: false,
                })
                return;
            }
            this.setState({
                listaBajaReclamacion: response.detalleBajas,
                numPaginas: response.paginacion.totalPages,
                numTotalBajas: response.paginacion.totalItems,
                loadingBajas: false,
            })
            const array: number[] = [];
            for (let i = 1; i <= this.state.numPaginas; i++) {
                array.push(i);
            }
            this.setState({ listPaginacion: array })

        } catch (e) {
            const error = e as AxiosError;
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.data);
                this.setState({
                    listaBajaReclamacion: [],
                    loadingBajas: false,
                })
            }
        }
    }
    handlePaginator = (opcion: number) => {
        let pag: number = this.state.currentPage;

        if (opcion === 1) {
            pag = this.state.currentPage + 1;
            this.setState({ currentPage: pag });
        } else {
            pag = this.state.currentPage - 1;
            this.setState({ currentPage: pag });
        }
        this.getBajas(this.state.filterCheckBox.porConfirmar ? 0 : 1, pag, 10);
    }

    handleCheckBoxChangeFiltro = (name: string): void => {
        let auxFiltro = this.state.filterCheckBox;
        if (name === 'porConfirmar' && !this.state.filterCheckBox.porConfirmar) {
            auxFiltro = {
                porConfirmar: true,
                confirmada: false
            }
            this.getBajas(0, 1, 10)

        } else if (name === 'confirmada' && !this.state.filterCheckBox.confirmada) {
            auxFiltro = {
                porConfirmar: false,
                confirmada: true
            }
            this.getBajas(this.state.filterCheckBox.porConfirmar ? 0 : 1, 1, 10)
        }
        this.setState({
            filterCheckBox: auxFiltro,
            currentPage: 1
        })

    }
    handleInput = (value: string, name: string): void => {
        if (name === 'noCheque') {
            this.setState({
                infoCarta: {
                    noCheque: value,
                    noReq: this.state.infoCarta.noReq
                }
            })
        } else if (name === 'noReq') {
            this.setState({
                infoCarta: {
                    noCheque: this.state.infoCarta.noCheque,
                    noReq: value
                }
            })
        }
        this.setState({
            cambio: true
        })


    }


    render() {

        return <>
            <NavBar />
            <Content id="bajas-reclamaciones" className="container-fluid">

                <h1 id='title-page'>Bajas reclamaciones</h1>
                <div className="col-md-12 col-lg-12 mt-2">
                    <CheckboxGroup name="filtro-check" >
                        <div className="row">
                            <div className="col-6 container-center">
                                <Checkbox
                                    name='Por confirmar'
                                    labelVisual='Por confirmar'
                                    checked={this.state.filterCheckBox.porConfirmar}
                                    onChange={(e) => this.handleCheckBoxChangeFiltro('porConfirmar')} />
                            </div>
                            <div className="col-6 container-center">
                                <Checkbox
                                    name='Confirmadas'
                                    labelVisual='Confirmadas'
                                    checked={this.state.filterCheckBox.confirmada}
                                    onChange={(e) => this.handleCheckBoxChangeFiltro('confirmada')} />
                            </div>
                        </div>
                    </CheckboxGroup>
                </div>
                {this.state.loadingBajas ? (
                    <div className="col-12">
                        <SpinnerLoading text="Cargando bajas" />
                    </div>
                ) :
                    (
                        <div><Table id='tbl-baja-reclamacion' className="mt-2">
                            <thead>
                                <TableRow>

                                    <TableCell type="colHead">
                                        Cve. Reclamacion
                                    </TableCell>
                                    <TableCell type="colHead">
                                        No. de fianza
                                    </TableCell>
                                    <TableCell type="colHead">
                                        Tipo de baja
                                    </TableCell>
                                    <TableCell type="colHead">
                                        Monto
                                    </TableCell>
                                    <TableCell type="colHead" className="colStatus">
                                        Tipo de pago
                                    </TableCell>
                                    <TableCell type="colHead">
                                        Fecha Contable
                                    </TableCell>
                                    <TableCell type="colHead" className="colStatus">
                                        Estatus
                                    </TableCell>
                                    <TableCell type="colHead" className="colStatus">
                                        Opciones
                                    </TableCell>
                                </TableRow>
                            </thead>
                            <tbody>
                                {this.state.listaBajaReclamacion.map((baja, index) => (
                                    <TableRow key={index}>

                                        <TableCell type="rowHead">{baja.cveRec}</TableCell>
                                        <TableCell type="rowHead">{baja.fianza}</TableCell>
                                        <TableCell type="rowHead">{baja.tipoBaja}</TableCell>
                                        <TableCell type="rowHead">{baja.monto}</TableCell>
                                        <TableCell type="rowHead">{baja.tipoPago}</TableCell>
                                        <TableCell type="rowHead">{baja.fechaContable}</TableCell>
                                        <TableCell type="rowHead">

                                            {/* Se comenta por cambio
                                                baja.autorizacion === 1 ? <IconInfo size="24" title='Revision' className="badgeYellow cursor"
                                                    onClick={() => this.openModalSolicitud(baja.cveBajaRec, baja.autorizacion)} /> :
                                                    baja.autorizacion === 2 ? <IconInfo size="24" title='En proceso a autorizacion' className="badgeOrange cursor"
                                                        onClick={() => this.openModalSolicitud(baja.cveBajaRec, baja.autorizacion)} /> :
                                                        baja.autorizacion === 3 ? <IconInfo size="24" title='Autorizada' className="badgeGreen cursor"
                                                            onClick={() => this.openModalSolicitud(baja.cveBajaRec, baja.autorizacion)} /> :
                                                            baja.autorizacion === 4 ? <IconInfo size="24" title='Enviada a sun systems' className="badgeBlue cursor"
                                                                onClick={() => this.openModalSolicitud(baja.cveBajaRec, baja.autorizacion)} /> :
                                                                <IconInfo size="24" title='Rechazada' className="badgeGray"
                                                                    onClick={() => this.openModalSolicitud(baja.cveBajaRec, baja.autorizacion)} />
                                */}
                                            {baja.autorizacion === 1 ? 'Revision' :
                                                baja.autorizacion === 2 ? 'En proceso a autorizacion' :
                                                    baja.autorizacion === 3 ? 'Autorizada' :
                                                        baja.autorizacion === 4 ? 'Enviada a sun systems' :
                                                            baja.autorizacion === 5 ? 'Rechazada' :
                                                                baja.autorizacion === 6 ? 'Contabilizada' :
                                                                    'Rechazo de Sun Systems'}
                                        </TableCell>
                                        <TableCell type="rowHead" >
                                            <Dropdown >
                                                <Dropdown.Toggle variant="light" id="dropdown-download" disabled={baja.autorizacion === 2 || baja.autorizacion === 6}>
                                                    <IconSeeMore ></IconSeeMore>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align='right'>
                                                    {/* 
                                                    Dropdown.Item onClick={() => this.setState({ modal: true })}>
                                                        Descargar carta
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => this.descargaDocumentos()}>
                                                        Descargar análisis
                                                    </Dropdown.Item>*/}
                                                    {
                                                        baja.autorizacion === 1 ?
                                                            <Dropdown.Item onClick={() => this.openModalSolicitud(baja.cveBajaRec, baja.autorizacion, baja.cveRec)}>
                                                                Solicitar Autorización
                                                            </Dropdown.Item> :
                                                            baja.autorizacion === 3 ?
                                                                <Dropdown.Item onClick={() => this.openModalSolicitud(baja.cveBajaRec, baja.autorizacion, baja.cveRec)}>
                                                                    Enviar a Sun Systems
                                                                </Dropdown.Item> : null}
                                                    {baja.autorizacion === 1 || baja.autorizacion === 2 || baja.autorizacion === 3 || baja.autorizacion === 4 || baja.autorizacion === 6
                                                        ? <div>
                                                            <Dropdown.Item onClick={() => this.openModalCarta(baja.cveBajaRec, baja.cveRec)}>
                                                                Carta de pago
                                                            </Dropdown.Item>
                                                            {/*<Dropdown.Item onClick={() => this.descargaDocumentos()}>
                                                                Analisis
                                                    </Dropdown.Item>*/}
                                                        </div> : null}
                                                    {baja.autorizacion === 1 || baja.autorizacion === 3 || baja.autorizacion === 5 || baja.autorizacion === 7
                                                        ? <Dropdown.Item onClick={() => this.openModalEliminar(baja.cveBajaRec, baja.cveRec)}>
                                                            Eliminar Baja
                                                        </Dropdown.Item> : null}

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </tbody>
                        </Table>
                            <div className="container-center mt-5" hidden={this.state.listaBajaReclamacion.length > 0} >
                                <h3>{this.props.language.language?.depositosGarantias.noResults} </h3>
                            </div>
                            <div className="row mt-5" hidden={this.state.listaBajaReclamacion.length < 1}>
                                <div className="col-md-5 show-registers">
                                    {this.props.language.language?.depositosGarantias.showing} {this.state.numRegistrosMin} {this.props.language.language?.depositosGarantias.showingTo} {this.state.numTotalBajas > 10 ? this.state.numRegistrosMax : this.state.numTotalBajas} {this.props.language.language?.depositosGarantias.showingTotal}  {this.state.numTotalBajas} {this.props.language.language?.depositosGarantias.records}.
                                </div>
                                <div className="col-md-7">
                                    <Pagination className="float-md-right" id="pagination-table">
                                        <Pagination.Prev
                                            onClick={() => this.state.currentPage > 1 ? this.handlePaginator(2) : null}
                                        />
                                        {
                                            this.state.listPaginacion.map((i, index) =>
                                            (
                                                <Pagination.Item
                                                    key={i}
                                                    active={i === this.state.currentPage}
                                                    onClick={() => (this.setState({ currentPage: i }), this.getBajas(this.state.filterCheckBox.porConfirmar ? 0 : 1, i, 10))}
                                                >
                                                    {/*Pagina {this.state.currentPage} */}
                                                    {i}
                                                </Pagination.Item>
                                            ))
                                        }
                                        <Pagination.Next
                                            onClick={() => this.state.currentPage < this.state.numPaginas ? this.handlePaginator(1) : null}
                                        />
                                    </Pagination>
                                </div>
                            </div>
                        </div>)}

                <Modal
                    isOpen={this.state.modalCarta}
                    closeFunc={() => this.setState({ modalCarta: false })}>
                    <div className="container-center">
                        <h3>Carta de pago</h3>
                        {this.state.loadingInfoCarta ? <SpinnerLoading text="Cargando info Carta" /> :
                            (<div>
                                <GridRow gutters justify="center">
                                    <GridCol base={12}>
                                        <AlphanumericInput labelVisual="No. de cheque" name="noCheque" value={this.state.infoCarta.noCheque} onChange={(e) => this.handleInput(e.target.value, e.target.name)} />
                                        <AlphanumericInput labelVisual="No de req de pago" name="noReq" value={this.state.infoCarta.noReq} onChange={(e) => this.handleInput(e.target.value, e.target.name)} />
                                    </GridCol>
                                </GridRow>
                                <GridRow gutters justify="center">
                                    <GridCol base={12}>
                                        {this.state.cambio ?
                                            <Button dynamicWidth variant="primary" onClick={() => this.putInfoCarta()}                                        >
                                                Guardar Cambios
                                            </Button> :
                                            <Button dynamicWidth variant="primary" onClick={() => this.getCarta(this.state.cveBajaRec)}                                        >
                                                Generar Carta
                                            </Button>
                                        }


                                    </GridCol>
                                </GridRow>
                            </div>)
                        }
                    </div>
                </Modal>
                <Modal
                    id='modal-solicitud'
                    isOpen={this.state.modalSolicitud}
                    size="medium"
                    modalStyle="standard"
                    /*</Content>title='Autorizar Pago'*/
                    className='container-center'
                    closeFunc={() => this.setState({ modalSolicitud: false })}
                >
                    <div className="container-center">
                        <h3>Bajas de reclamacion</h3>
                    </div>
                    <div className='container' >
                        <div className='row container-center'>
                            {this.state.lblSolicitud}<strong>{this.state.cveRec}</strong>
                        </div>
                        <div className=" mt-4">
                            {this.state.loadingSolicitud ? (
                                <div className="col-12">
                                    <SpinnerLoading text="Realizando solicitud" />
                                </div>
                            ) : (< div > <Button
                                type='button'
                                variant='primary'
                                size='small'
                                onClick={() => this.postSolicitud(this.state.cveBajaRec, this.state.estado)}
                            >Aceptar</Button>
                                <Button
                                    type='button'
                                    variant='secondary'
                                    size='small'
                                    onClick={() => this.setState({ modalSolicitud: false })}
                                >Cancelar</Button>
                            </div>
                            )}

                        </div>
                    </div>
                </Modal>
                <Modal
                    id='modal-exito'
                    isOpen={this.state.modalExito}
                    size="small"
                    modalStyle="standard"
                    className='container-center'
                    closeFunc={() => this.setState({ modalExito: false })}
                >
                    <div className='container mt-5'>
                        <div className='row mt-5 mb-5'>
                            <div className='col-12 '>
                                <h2>{this.state.lblExito}</h2>
                            </div>
                            <div className='col-12 mb-3'>
                                <IconSuccessAlert color='positive' size='64'></IconSuccessAlert>
                            </div>
                            <div className='col-12'>
                                <Button variant='primary' size='small' onClick={() => this.setState({ modalExito: false })}
                                >Aceptar
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    id='modal-error'
                    isOpen={this.state.modalError}
                    size="small"
                    modalStyle="standard"
                    className='container-center'
                    closeFunc={() => this.setState({ modalError: false })}
                >
                    <div className='container mt-5'>
                        <div className='row mt-5 mb-5'>
                            <div className='col-12 '>
                                <h2>{this.state.lblError}</h2>
                            </div>
                            <div className='col-12 mb-3'>
                                <IconWarning color='caution' size='64' />
                            </div>
                            <div className='col-12'>
                                <Button variant='primary' size='small' onClick={() => this.setState({ modalError: false })}
                                >Aceptar
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    id='modal-eliminar'
                    isOpen={this.state.modalEliminar}
                    size="medium"
                    modalStyle="standard"
                    /*</Content>title='Autorizar Pago'*/
                    className='container-center'
                    closeFunc={() => this.setState({ modalEliminar: false })}
                >
                    <div className="container-center">
                        <h3>Bajas de reclamacion</h3>
                    </div>
                    <div className='container' >
                        <div className='row container-center'>
                            Desea eliminar la baja
                            <strong>{this.state.cveRec}</strong>
                        </div>
                        <div className=" mt-4">
                            {this.state.loadingEliminarSolicitud ? (
                                <div>
                                    <SpinnerLoading text="Eliminando solicitud" />
                                </div>
                            ) : <div>
                                <Button
                                    type='button'
                                    variant='primary'
                                    size='small'
                                    onClick={() => this.deleteSolicitud()}
                                >Aceptar</Button>
                                <Button
                                    type='button'
                                    variant='secondary'
                                    size='small'
                                    onClick={() => this.setState({ modalSolicitud: false })}
                                >Cancelar</Button>
                            </div>
                            }
                        </div>
                    </div>
                </Modal>
                {/*<Button
                    type='button'
                    variant='secondary'
                    size='small'
                    onClick={() => this.getAnalisis(47)}
                        >Prueba</Button>*/}
            </Content>
        </>
    }

    componentDidMount() {
        this.getBajas(0, 1, 10)


    }

    postSolicitud = async (cveBaja: number, estado: number) => {
        this.setState({
            loadingSolicitud: true
        })
        try {
            const res = await apiService.postSolicitud(cveBaja, estado);

            console.log(res);
            if (res.data.messageResponse.code === 0) {
                this.setState({
                    modalSolicitud: false,
                    lblExito: estado === 2 ? 'La solicitud de baja fue notificada para su autorización' : 'La solicitud de baja fue enviada a Sun Systems',
                    modalExito: true,
                    loadingSolicitud: false
                })
            } else {
                this.setState({
                    modalSolicitud: false,
                    lblError: estado === 2 ? 'Error inesperado durante la solicitud autorizacion' : 'Error inesperado durante envio a Sun Systems',
                    modalError: true,
                    loadingSolicitud: false
                })
            }

            this.getBajas(this.state.filterCheckBox.porConfirmar ? 0 : 1, 1, 10)


        } catch (error) {
            const err = error as AxiosError;
            if (err.response) {
                console.log(err.response.status);
                console.log(err.response.data.error);
                console.log(err.response.data);
                this.setState({
                    modalSolicitud: false,
                    lblError: 'Ha ocurrido un error, intentelo mas tarde',
                    modalError: true,
                    loadingSolicitud: false
                })
            }

        }
    }

    getCarta = async (cveBaja: number) => {

        try {

            const response = await apiService.getCarta(cveBaja);
            let a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
            a.download = `Carta de Pago ${this.state.cveRec}.docx`;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
            this.setState({
                modalCarta: false
            });
        } catch (e) {
            const error = e as AxiosError;
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.data);

            }
        }
    }
    getAnalisis = async (cveBaja: number) => {

        try {

            const response = await apiService.getAnalisis(cveBaja);
            let a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
            a.download = `analisis rec .docx`;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
            this.setState({
                modalCarta: false
            });
        } catch (e) {
            const error = e as AxiosError;
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.data);

            }
        }
    }

    getInfoCarta = async (cveBajaRec: number) => {
        this.setState({
            loadingInfoCarta: true,
        })
        try {
            const res = await apiService.getInfoCarta(cveBajaRec);
            console.log(res.data);

            this.setState({
                infoCarta: {
                    noReq: res.data.noRequerimiento,
                    noCheque: res.data.noCheque
                },
                loadingInfoCarta: false,
            })

        } catch (error) {
            const err = error as AxiosError;
            if (err.response) {
                this.setState({
                    loadingInfoCarta: false,
                })
                console.log(err.response.status);
                console.log(err.response.data.error);
                console.log(err.response.data);
            }

        }
    }
    putInfoCarta = async () => {
        this.setState({
            loadingInfoCarta: true,
        })
        try {
            const res = await apiService.putInfoCarta(this.state.cveBajaRec,
                this.state.infoCarta.noCheque,
                this.state.infoCarta.noReq);
            console.log(res.data);
            this.getCarta(this.state.cveBajaRec);
            this.setState({
                loadingInfoCarta: false,
                cambio: false,
                modalCarta: false
            })

        } catch (error) {
            const err = error as AxiosError;
            if (err.response) {
                this.setState({
                    loadingInfoCarta: false,
                })
                console.log(err.response.status);
                console.log(err.response.data.error);
                console.log(err.response.data);
            }

        }
    }

    deleteSolicitud = async () => {
        this.setState({
            loadingEliminarSolicitud: true,
        })
        try {
            const res = await apiService.deleteSolicitud(this.state.cveBajaRec);
            console.log(res.data);
            if (res.data.messageResponse.code === 0)
                this.setState({
                    loadingEliminarSolicitud: false,
                    modalEliminar: false,
                    modalExito: true,
                    lblExito: 'A sido eliminada correctamente'
                })
            this.getBajas(this.state.filterCheckBox.porConfirmar ? 0 : 1, this.state.currentPage, 10)
        } catch (error) {
            const err = error as AxiosError;
            if (err.response) {
                this.setState({
                    loadingEliminarSolicitud: false,
                    modalEliminar: false,
                    modalError: true,
                    lblError: 'A ocurrido un error, intente mas tarde '
                })
                console.log(err.response.status);
                console.log(err.response.data.error);
                console.log(err.response.data);
            }

        }
    }



}



const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(BajasReclamaciones);

interface BajasReclamacionesState {
    listaBajaReclamacion: BajaReclamacionModel[],
    listPaginacion: number[],
    numPaginas: number,
    currentPage: number,
    numTotalBajas: number,
    numRegistrosMin: number,
    numRegistrosMax: number,
    loadingBajas: boolean,
    modalCarta: boolean,
    modalExito: boolean,
    modalError: boolean,
    modalSolicitud: boolean,
    cveBajaRec: number,
    cveRec: string
    estado: number,
    tipoRec: boolean,
    filterCheckBox: {
        confirmada: boolean,
        porConfirmar: boolean,
    },
    lblSolicitud: string,
    lblError: string,
    lblExito: string,
    loadingSolicitud: boolean,
    loadingInfoCarta: boolean,
    modalEliminar: boolean,
    infoCarta: {
        noReq: string,
        noCheque: string,
    },
    cambio: boolean,
    loadingEliminarSolicitud: boolean,
}
