import { ChangeEvent, Component } from "react";
import './SelectStyles.scss';
import axios, { AxiosError } from "axios";
import Select, { MultiValue } from 'react-select';
import * as engagementService from '../../../services/compromisos/EngagementsService';
import { Bloqueos } from "../../../models/compromisos/Bloqueos";
import { UserModel } from "../../../models/public/UserModel";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import { RootStore } from "../../../Store";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { connect } from "react-redux";


type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any >;


class SelectBlocks extends Component<IListProps, IListState>{
 

  public idFiado= this.props.idFiado
  public localStorageClass = new LocalStorageClass();

  constructor(props: any) {

    super(props);

    const PERFIL = this.localStorageClass.getProfile();
   

    this.state = {
      bloqueos: [],
      listConditionalOption: [],
      PERFIL: PERFIL,
    }

    this.handleInputChangeSelect = this.handleInputChangeSelect.bind(this)

  }

  componentDidMount() {
  
    this.getBloqueosActivos(this.idFiado);
  }


  render(): JSX.Element {
   
    return <>

      <div className="select-blocks">

        <Select        
           placeholder={`${this.props.language.language?.createEngagement.seleccionaBloqueos}`}   
          isMulti
          options={this.state.listConditionalOption}             
          onChange={this.handleInputChangeSelect}
          //defaultValue={[colourOptions[2], colourOptions[3]]}       
        />
      </div>

    </>
  }


  async getBloqueosActivos(idFiado:number): Promise<void> {
    
    const res = await engagementService.getBloqueos(idFiado);     
    this.setState({bloqueos: res.data});  
    //console.log(res.data)
    console.log("Se encuentran bloqueos activos para el fiado: " + this.state.bloqueos)   

    this.formatearLista();  
    
    
  }


  formatearLista(): void {
   
    let  listaCondicionalAuxiliar: BlockOption[]; 
    listaCondicionalAuxiliar = [] 

    console.log(this.state.bloqueos)
  
    for( const condition of this.state.bloqueos ){        
  
        listaCondicionalAuxiliar.push({ name: "bloqueo", value: condition.idBloqueo, label: condition.descripcion}) 

    } 

    /* if(this.state.bloqueos.length == 0 ){

      listaCondicionalAuxiliar.push({ name: "bloqueo", value: 0, label: "No cuenta con Bloqueos Activos"}) 
    }  */

    this.setState( { listConditionalOption: listaCondicionalAuxiliar } )
  }

  formatearListaXTipo(tipo:string): void {
    
    let  listaCondicionalAuxiliar: BlockOption[]; 
    listaCondicionalAuxiliar = [] 

   console.log("Tipo " + tipo)
    for( const condition of this.state.bloqueos){ 

      if(condition.descripcion.includes(tipo)){

        listaCondicionalAuxiliar.push({ name: "condicion", value: condition.idBloqueo, label: condition.descripcion}) 
      } 

    } 

    this.setState( { listConditionalOption: listaCondicionalAuxiliar }) 

  } 

  handleInputChangeSelect (e: MultiValue<BlockOption>): void{

    let  listaBloqueosAuxiliar: Bloqueos[]; 
    listaBloqueosAuxiliar = [] 

    let  listaGarantias: Bloqueos[]; 
    listaGarantias= [] 

    let  listaBuro: Bloqueos[]; 
    listaBuro = [] 

   //console.log("El tamaño de E es: " + e.length)

   if(e.length == 0 ){

    //console.log("Entro a nada con " + e)
    listaGarantias = [] 
    listaBuro = [] 
    listaBloqueosAuxiliar = [] 
    this.getBloqueosActivos(this.idFiado);

  } 

  for (const elemento of e){

    if(elemento.label.includes("GARANTIAS") ){
      
      //console.log("Entro a garantias")
      listaGarantias.push({ idBloqueo: elemento.value, descripcion: elemento.label, estatus: 0})
      listaBloqueosAuxiliar = listaGarantias
      this.formatearListaXTipo("GARANTIAS");
      
    }   

    else if(elemento.label.includes("BURO") ){
      
      //console.log("Entro a buro")
      listaBuro.push({ idBloqueo: elemento.value, descripcion: elemento.label, estatus: 0})
      listaBloqueosAuxiliar = listaBuro
      listaGarantias = [] 
      this.formatearListaXTipo("BURO");
     
    } 


  } 

    this.props.selectedBlocks(listaBloqueosAuxiliar);
  }

    
} 


interface IListProps {

  idFiado: number;
  language: DefaultStateI;
  selectedBlocks: (listBlocks: Bloqueos[]) => void;

}


interface IListState {

  bloqueos: Bloqueos[];
  listConditionalOption: BlockOption[]; 
  PERFIL: UserModel;

}

interface BlockOption {
  name: string;
  value: number;
  label: string;
  color?: string;
  isFixed?: boolean;
  isDisabled?: boolean;
}

const mapStateToProps = (state: RootStore) => ({
  language: state.language
});


export default connect(mapStateToProps)(SelectBlocks);

