export interface LineaDeAfianzamientoLanguage {
    createLine: string;
    invoice: string;
    movements: string;
    amountMove: string;
    amountAccumulated: string;
    selectProducts: string;
    products: string;
    actions: string;
    montoMaximoEv: string;
    montoMaximoAc: string;
    cancel: string;
    save: string;
    saving: string;
    showing: string;
    to: string;
    of: string;
    entries: string;
    msgProduct: string;
    enterMontoMaximoEv: string;
    enterMontoMaximoAc: string;
    exceedMontoMaximoAc: string;
    loadingLineasAfianzamiento: string;
    errorLineasAfianzamiento: string;
    deleteLineaAfianzamiento: string;
    areYouSureDelete: string;
    delete: string;
    deleting: string;
    editLineaAfianzamiento: string;
    edit: string;
    editing: string;
    renewal: string;


}

export const LineaDeAfianzamientoEnglish: LineaDeAfianzamientoLanguage = {
    createLine: 'New Bonding Line',
    invoice: 'Invoice',
    movements: 'Movements',
    amountMove: 'Movement Amount',
    amountAccumulated: 'Accumulated Amount',
    selectProducts: 'Select Products',
    products: 'Products',
    actions: 'Actions',
    montoMaximoEv: 'Amount per movement',
    montoMaximoAc: 'Accumulated Maximum amount',
    cancel: 'Cancel',
    save: 'Save',
    saving: 'Saving',
    showing: 'Showing',
    to: 'to',
    of :'of',
    entries: 'entries',
    msgProduct: 'Select Products to create a Bonding line, please',
    enterMontoMaximoAc: 'Please enter a Maximum Accumulated Amount',
    enterMontoMaximoEv: 'Enter an Amount per movement, please ',
    exceedMontoMaximoAc: 'The Amount per movement cannot be greater than the Maximum Accumulated Amount',
    loadingLineasAfianzamiento: 'Loading Bonding Lines',
    errorLineasAfianzamiento: 'Error loading Bond Lines',
    deleteLineaAfianzamiento: 'Delete Bonding Line',
    areYouSureDelete: 'Do you want to eliminate the Bonding line with the folio',
    delete: 'Delete',
    deleting: 'Deleting',
    editLineaAfianzamiento: 'Renew Bonding Line',
    edit: 'Edit',
    editing: 'Editing',
    renewal: 'Renew bonding line'

}
export const LineaDeAfianzamientoSpanish: LineaDeAfianzamientoLanguage = {
    createLine: 'Nueva Línea de Afianzamiento',
    invoice: 'Folio',
    movements: 'Movimientos',
    amountMove: 'Monto por Movimiento',
    amountAccumulated: 'Monto Acumulado',
    selectProducts: 'Selecciona Productos',
    products: 'Productos',
    actions: 'Acciones',
    montoMaximoEv: 'Monto por movimiento',
    // El límite del cúmulo/suma de las fianzas emitidas por esta Línea de Afianzamiento
    montoMaximoAc: 'Monto Máximo Acumulado',
    cancel: 'Cancelar',
    save: 'Guardar',
    saving: 'Guardando',
    showing: 'Mostrando',
    to: 'al',
    of: 'de un total de',
    entries: 'registros',
    msgProduct: 'Selecciona Productos para crear una línea de Afianzamiento,  por favor',
    enterMontoMaximoEv: 'Ingresa un Monto por movimiento, por favor',
    enterMontoMaximoAc: 'Ingresa un Monto Máximo Acumulado, por favor',
    exceedMontoMaximoAc: 'El Monto por movimiento no puede ser mayor al Monto Máximo Acumulado',
    loadingLineasAfianzamiento: 'Cargando Líneas de afianzamiento',
    errorLineasAfianzamiento: 'Error al cargar las Líneas de Afianzamiento',
    deleteLineaAfianzamiento: 'Eliminar Linea de Afianzamiento',
    areYouSureDelete: '¿Quieres eliminar la línea de Afianzamiento con el folio',
    delete: 'Eliminar',
    deleting: 'Eliminando',
    editLineaAfianzamiento: 'Renovar Línea de Afianzamiento',
    edit: 'Editar',
    editing: 'Editando',
    renewal: 'Renovar línea afianzamiento'
}
