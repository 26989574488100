import React, {ChangeEvent, useEffect, useState} from 'react';
import './DragDropGarantia.scss';
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {MAXSIZEUPLOADFILE} from "../../../class/CommonClass";

interface DragDropExpedienteProps {
    idGarantia: number
    idTipoDocumento: number;
    returnFormData: (formData: FormData) => void;

}

export const DragDropGarantia: React.FC<DragDropExpedienteProps> = ({ idGarantia,
                                                                        idTipoDocumento,
                                                                        returnFormData}:DragDropExpedienteProps)=> {

    const languageState = useSelector((state: RootStore) => state.language);


    const [dragActive, setDragActive] = useState(false);

    const [idTypeDestinityState, setIdTypeDestinityState] = useState(0);

    // ref
    const inputRef = React.useRef<HTMLInputElement>(null);

    // handle drag events

    // triggers when file is dropped

    // triggers when file is selected with click
    const handleFile = (e: any) => {
        if (e && e.preventDefault) { // add?
            e.preventDefault();
            e.persist();
            console.log('target files ' + e);
            if (e.target.files && e.target.files[0]) {

                handleFile(e.target.files);
            }
        }

    }
    const handleDrag = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }
    const handleDrop = (e: React.DragEvent<HTMLDivElement | any> ) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            console.log(e)
            console.log(inputRef.current!.files);
            handleFile(e.dataTransfer.files);
            if (e.dataTransfer.files[0].type =='application/pdf' ||
                e.dataTransfer.files[0].type =='image/png' ||
                e.dataTransfer.files[0].type =='image/jpeg' ||
                e.dataTransfer.files[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                //console.log(e.target.files![0])
                const formData = new FormData();
                formData.append('idGarantia', String(idGarantia));
                formData.append('idTipoDocumento', String(idTipoDocumento));
                formData.append('fileName', e.dataTransfer.files![0]);
                inputRef.current!.value = '';// vaciar el input file
                returnFormData(formData);

            }
            else if (e.dataTransfer.files![0].size >= MAXSIZEUPLOADFILE){
                // setOpenModalExcedLimitFile(true)
            }

        }else {
            const idHTML = e.dataTransfer.getData("text");
            const idDoc: number = parseInt(idHTML.substr('id-doc-'.length, idHTML.length));

        }
    }



    // triggers when file is selected with click
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            console.log('handleChange' + e)

            handleFile(e.target.files);
            if (e.target.files[0].type =='application/pdf' ||
                e.target.files[0].type =='image/png' ||
                e.target.files[0].type =='image/jpeg' ||
                e.target.files[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                //console.log(e.target.files![0])
                const formData = new FormData();
                formData.append('idGarantia', String(idGarantia));
                formData.append('idTipoDocumento', String(idTipoDocumento));
                formData.append('fileName', e.target.files![0]);
                e.target.value = '';// vaciar el input file
                returnFormData(formData);

            }
            else if (e.target.files![0].size >= MAXSIZEUPLOADFILE){
                // setOpenModalExcedLimitFile(true)
            }
        }
    }
    const onButtonClick = () => {
        if (inputRef.current != null){
            inputRef.current!.click();
        }

    };

    useEffect(() => {

    },[]);


    return (
        <div className="w-100 drop-area " id="drag-drop-garantia">
            <form id="form-file-upload"
                  className="w-100 drop-area text-center"
                  onDragEnter={handleDrag}
                  onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file"
                       id="input-file-upload" multiple={true} onChange={handleChange} />
                <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                    <div>
                        <p>{languageState.language?.expedientIdentificaction.dragHere}</p>
                        <button className="upload-button" onClick={onButtonClick}>
                            Cambiar el Archivo
                        </button>
                    </div>
                </label>
                { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
            </form>
        </div>
    );
};