import { ChangeEvent, Component } from "react";
import './Cuenta.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import { AlphanumericInput, Button, Card, FieldGroup, Form, IconAgent, IconButton, IconEdit, IconEmail, IconMyAccount, Modal, } from "@lmig/lmds-react";
import { RouteComponentProps } from "react-router-dom";
import userProfile from '../../../assets/images/public/login/liberty_mutual-logo.png';
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import '@lmig/lf-sgf-library-messages/src/index.css'
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { UserModel } from "../../../models/public/UserModel";
import { NegativeAlert } from "../../../components/public/negative-alert/NegativeAlert";
import { formatValue } from "../../../class/CommonClass";
import * as generalService from "../../../services/api-gral/ApiGralService";
import { InformacionUsuario } from "../../../models/public/InformacionUsuario";
import { AxiosError } from "axios";
import { CodigoRespuesta } from "../../../models/compromisos/CodigoRespuesta";
import { PositiveAlert } from "../../../components/public/positive-alert/PositiveAlert";
import { VerificationInput } from "../../../components/public/varification-input/VerificationInput";

type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>;

interface IListProps {

    idCompromiso: number;
    idFiado: number;
    language: DefaultStateI
}


interface Params {
    idCompromiso: string;
    idFiado: string;
}

interface LocationState {
    ownerName: string;
}

// Static Context is available when you use Static Router*
interface SC {
    statusCode?: number;
}



class Cuenta extends Component<IListProps & RouteComponentProps<Params, SC, LocationState>, CheckConceptState> {

    public localStorageClass = new LocalStorageClass();
    // public correoActualizado = "";

    public initialMsg: Messages = {
        msgExcesoIntentos: '',
        msgEmailNoValido: '',
        msgErrorCodigo: '',
        msgOperacion: '',
        msgCodigo: ''
    }




    constructor(props: any) {
        super(props);

        document.title = "Cuenta"
        //document.title = `${this.props.language.language?.createEngagement.nombreDocumento}`

        this.state = {
            intentos: 0,
            habilitaNuevoCodigo: true,
            habilitaEnvioCodigo: true,
            hablitarEdicion: true,
            correoActualizado: "",
            PERFIL: this.localStorageClass.getProfile(),
            mensajesAlerta: this.initialMsg,
            informacionUsuario: {
                idUsuario: 0,
                nombre: "",
                username: "",
                rol: 0
            },
            codigo: "",
            codigoRespuesta: {
                codigo: 0,
                descripcion: ""
            },
            codigoNotificado: 0
        }


        this.handleInputChangeEmail = this.handleInputChangeEmail.bind(this)
        this.handleInputCode = this.handleInputCode.bind(this)
    }

    componentDidMount() {

        this.getInformacion();
    }


    render() {
        return <>
            <div className="container-fluid " id="count">
                <NavBar />

                <div className="row container-count">
                    <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-4" />
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 card" id="form-count">
                        <div className="row">
                            <div className="col-4"></div>
                            <div className="col-4 div-profile">

                                <img src={userProfile}
                                    className="img-fluid
                                                img-thumbnail
                                                d-block
                                                mx-auto"
                                    id="img-count" />
                            </div>
                            <div className="col-4 "></div>
                        </div>
                        <h3 className="text-center">CUENTA</h3>
                        <Form >
                            <div className="col-md-12">
                                <div className="row buttons-navigation">
                                    <div className="col-md-12">
                                        <AlphanumericInput
                                            className="float-right w-100 textContact"
                                            id="input--labelAsHeading"
                                            labelVisual={this.props.language.language?.createEngagement.nombreDestinatario}
                                            value={`${this.state.informacionUsuario.nombre}`}
                                            disabled />
                                    </div>
                                    <div className="col-md-12">
                                        <AlphanumericInput
                                            className="float-right w-100 textContact"
                                            id="input--labelAsHeading"
                                            labelVisual={this.props.language.language?.createEngagement.usuario}
                                            value={`${this.state.PERFIL.username}`}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-12">

                                        <div className="row ">
                                         
                                                <div className="col-10 ">
                                                    <AlphanumericInput disabled={this.state.hablitarEdicion}
                                                        className="float-right w-100 textContact"
                                                        id="input--labelAsHeading"
                                                        labelVisual={this.props.language.language?.createEngagement.correoDestinatario}
                                                        value={`${this.state.PERFIL.email}`}
                                                        name="correoActual"
                                                        onChange={this.handleInputChangeEmail} 
                                                    />
                                                </div>
                                               
                                            <div className="col-2 ">
                                                <IconButton onClick={() => { this.hablitaEdiciones() }} >
                                                    <IconEdit />
                                                </IconButton>
                                            </div>

                                            <div className="col-12 ">
                                            <NegativeAlert textAlert={this.state.mensajesAlerta.msgEmailNoValido} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-12">
                                <div className="row buttons-navigation">
                                    <div className="col-md-6">
                                        <br />
                                        <Button dynamicWidth
                                            variant="secondary"
                                            href="/menu">
                                            {this.props.language.language?.createEngagement.regresar}
                                        </Button>
                                    </div>
                                    <div className="col-md-6">
                                        <br />
                                        {this.state.correoActualizado == '' ? (
                                            <Button dynamicWidth disabled
                                                variant="primary"
                                                onClick={() => {
                                                    this.getCodigoConfirmacion()

                                                }}>
                                                Actualizar
                                            </Button>
                                        ) : <Button dynamicWidth
                                            variant="primary"
                                            onClick={() => {
                                                this.getCodigoConfirmacion()

                                            }}>
                                            Actualizar
                                        </Button>}
                                    </div>
                                </div>

                            </div>
                            <Modal
                                isOpen={this.state.openModalCodigoConfirmación}
                                size="medium"
                                modalStyle="standard"
                                title="Código de Validación"
                                // className="modalExito"
                                id="modal3"
                                closeFunc={() => {
                                    this.setState({ openModalCodigoConfirmación: false })
                                }}>
                                <h3 className="textoModal">Ingresa el código que se envió a tu correo</h3>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form id="form--codigoconfirmacion">
                                            <VerificationInput
                                                value={`${this.state.codigo}`}
                                                name="code"
                                                type="number" // text or number
                                                onValueChange={(value) => {
                                                    this.handleInputCode(value)

                                                }} />
                                            <div className="col-md-10">
                                            <br />
                                                <NegativeAlert textAlert={this.state.mensajesAlerta.msgErrorCodigo} />
                                                <NegativeAlert textAlert={this.state.mensajesAlerta.msgExcesoIntentos} />
                                                <PositiveAlert textAlert={this.state.mensajesAlerta.msgCodigo} />
                                                <PositiveAlert textAlert={this.state.mensajesAlerta.msgOperacion} />
                                            </div>
                                        </Form>
                                        <br />
                                        <Button disabled={this.state.habilitaEnvioCodigo}
                                            variant="primary"
                                            onClick={() => {
                                                this.postValidaCodigo()

                                            }}
                                            className="btn-confirmacion  w-100">
                                            {this.props.language.language?.createEngagement.continuarCodigo}
                                        </Button>

                                        <Button disabled={this.state.habilitaNuevoCodigo}
                                            className="btn-confirmacion  w-100"
                                            onClick={() => {
                                                this.getCodigoConfirmacion()

                                            }}
                                            variant="primary">
                                            {this.props.language.language?.createEngagement.enviar}
                                        </Button>
                                    </div>
                                </div>
                            </Modal>
                        </Form>
                   
                   
                        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-4" />
                    </div>
                </div>
            </div>
        </>
    }

    hablitaEdiciones(): void {
      
        this.setState({ hablitarEdicion: false })

    }

    handleInputChangeEmail(e: InputChange): void {

        const name = e.target.value;
        const value = e.target.value;

        this.setState({ correoActualizado: value })

    }

    validaFormulario(): boolean {

        const leyendaCorreo = 'Captura un correo válido'
        const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

        if (emailRegex.test(this.state.correoActualizado)) {

            
            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgEmailNoValido: formatValue("") }
            }))

            return true
        } else {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgEmailNoValido: formatValue(leyendaCorreo) }
            }))

            return false
        }
    }

    inicializacionModal(): void {

        this.setState({ openModalCodigoConfirmación: false, codigo: "" });

        this.setState(prevState => ({
            mensajesAlerta: { ...prevState.mensajesAlerta, msgCodigo: formatValue('') }
        }))

        this.setState(prevState => ({
            mensajesAlerta: { ...prevState.mensajesAlerta, msgOperacion: formatValue('') }
        }))

       // this.setState({habilitaEnvioCodigo : true})
    }

    handleInputCode(value: string): void {

        let aux = value      
        this.setState({ codigo: aux })

        if(value.length == 6){
            
            this.setState({habilitaEnvioCodigo : false})

        }else{
            this.setState({habilitaEnvioCodigo : true})

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgErrorCodigo: formatValue('') }
            }))

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgExcesoIntentos: formatValue('') }
            }))

        }

    
    }


    async getInformacion(): Promise<void> {
        try {
            const res = await generalService.getInformacionUsuario(this.state.PERFIL.username)

            // actualizar el objeto compromiso con la autorizacion
            this.setState({ informacionUsuario: res.data })

            this.state.informacionUsuario.idUsuario = parseInt(this.state.PERFIL.id)
            this.state.informacionUsuario.username = this.state.PERFIL.username
           

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log("Error:" + err.response.status)
                console.log("Error:" + err.response.data)

            }
        }
    }

    async getCodigoConfirmacion(): Promise<void> {

        if (this.validaFormulario() == false) {

            console.log("No cuenta con información en el formulario")

        } else {

            this.setState({ openModalCodigoConfirmación: true });

            try {
                
                const res = await generalService.getCodigoConfirmacion(this.state.PERFIL.username, this.state.correoActualizado)

                if(this.state.intentos >= 6){
                    this.setState({ intentos: 0 })

                    this.setState(prevState => ({
                        mensajesAlerta: { ...prevState.mensajesAlerta, msgErrorCodigo: formatValue('') }
                    }))

                    this.setState(prevState => ({
                        mensajesAlerta: { ...prevState.mensajesAlerta, msgExcesoIntentos: formatValue('') }
                    }))
    
                    this.setState({habilitaNuevoCodigo: true,  codigo: "" });

                   
                }
               
                this.setState({ codigoNotificado: res.data })

            } catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    console.log("Error:" + err.response.status)
                    console.log("Error:" + err.response.data)

                }
            }
        }
    }

    async postValidaCodigo(): Promise<void> {

        const exitoso = "Código Verificado"
        const falla = "El código no es válido"
        const exitosaOperacion = "Se actualizó correctamente el correo"
        const excesoIntentos = "Excediste el número de intentos permitidos"
      
        try {
           
            const res = await generalService.postValidaCodigo(this.state.PERFIL.username, parseInt(this.state.codigo), this.state.correoActualizado, tipoOperacion.EMAIL)

            // actualizar el objeto compromiso con la autorizacion
            this.setState({ codigoRespuesta: res.data })
           

            if (this.state.codigoRespuesta.codigo == 1) {

                this.setState(prevState => ({
                    mensajesAlerta: { ...prevState.mensajesAlerta, msgCodigo: formatValue(exitoso) }
                }))

                this.setState(prevState => ({
                    mensajesAlerta: { ...prevState.mensajesAlerta, msgOperacion: formatValue(exitosaOperacion) }
                }))

                const auxPerfil: UserModel = this.state.PERFIL
                auxPerfil.email = this.state.correoActualizado;
                this.localStorageClass.setSomeString(this.localStorageClass.PERFIL, JSON.stringify(auxPerfil))

                this.setState({ PERFIL: auxPerfil })
                this.setState({ correoActualizado: "" })

                await new Promise(done => setTimeout(() => {
                    this.inicializacionModal()
                   
                }, 3000));

               
            } 

            if (this.state.codigoRespuesta.codigo == 0 && this.state.intentos <= 5) {
              
                this.setState(prevState => ({
                    mensajesAlerta: { ...prevState.mensajesAlerta, msgErrorCodigo: formatValue(falla) }
                }))

                let auxIntentos= this.state.intentos + 1
                this.setState({intentos:  auxIntentos})
             
            }

            if(this.state.intentos <= 3){

                this.setState( { habilitaEnvioCodigo: false })
                this.setState( { habilitaNuevoCodigo: true })
            }else{

                this.setState(prevState => ({
                    mensajesAlerta: { ...prevState.mensajesAlerta, msgExcesoIntentos: formatValue(excesoIntentos) }
                }))

                this.setState( { habilitaEnvioCodigo: true })
                this.setState( { habilitaNuevoCodigo: false })

            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log("Error:" + err.response.status)
                console.log("Error:" + err.response.data)

            }
        }
    }

}
const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(Cuenta);

interface CheckConceptState {

    PERFIL: UserModel;
    openModalCodigoConfirmación?: boolean;
    openModalClose?: boolean;
    mensajesAlerta: Messages;
    informacionUsuario: InformacionUsuario,
    codigoRespuesta: CodigoRespuesta,
    codigo: string,
    codigoNotificado: number
    correoActualizado: string
    hablitarEdicion: boolean
    habilitaEnvioCodigo: boolean
    habilitaNuevoCodigo: boolean
    intentos: number
}

interface Messages {
    msgEmailNoValido: string
    msgErrorCodigo: string;
    msgCodigo: string;
    msgOperacion: string;
    msgExcesoIntentos: string;
   

}

export enum tipoOperacion {
    PASS = 1,
    EMAIL = 2

}