
import React, { ChangeEvent, Component } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { DefaultStateI } from '../../../../reducer/LanguageReducer';
import { RootStore } from '../../../../Store';
import NavBar from '../../../../components/public/nav-bar/NavBar';
import Form from '@lmig/lmds-react/Form/Form';
import { LocalStorageClass } from '../../../../class/LocalStorageClass';
import Select from '@lmig/lmds-react/Form/Select/Select';
import { Alert, AlphanumericInput, Button, GridCol, GridRow, IconDownload, IconSuccessAlert, IconUpload, Table, TableCell, TableRow } from '@lmig/lmds-react';
import './Autorizadas.scss'
import { BreadCumb } from '../../../../components/public/breadcumb/BreadCumb';
import { PipeLineIcon } from '../../../../components/public/pipeline-steps/PipelineIcon';
import { Entidad } from '../../../../models/buro-credito/entidad';
import { AxiosError } from 'axios';
import * as getCreditbureau from "../../../../services/buro-credito/CreditbureauService"
import * as apiS3Services from "../../../../services/api-s3/ApiS3Services";
import { ConsultaBuro } from '../../../../models/buro-credito/ConsultaBuro';
import { Fisicas } from '../../../../models/buro-credito/Fisicas';
import { Morales } from '../../../../models/buro-credito/Morales';
import { PaginacionModel } from '../../../../models/public/PaginacionModel';
import { initPaginacion} from '../../../../class/CommonClass';
import { PdfFile } from '../../../../models/buro-credito/PdfFile';

export type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>;

interface ConsultVVProps {
    language: DefaultStateI
}
interface Params {
    folios: string;
}


class Autorizadas extends Component<ConsultVVProps & RouteComponentProps<Params>, ConsultVVState> {

    public localStorageClass = new LocalStorageClass();
    public select = document.getElementById('Persona');
    options: any;
    public propiedades: ConsultaBuro[] = [];


    public folios: number;
    constructor(props: any) {
        super(props);
        this.state = {
            estadoCaptura: 3,
            idWarranty: 0,
            loadingWarrantyDetail: true,
            errorWarrantyDetail: false,
            select: '',
            status: 0,
            validador: 1,
            entidad: [],
            datovalid: '',
            folio: '',
            FormFisicas: new FormData,
            datos: [],
            datofisica: [],
            datoMoral: [],
            paginacion: initPaginacion,
            loadingConsult: true,
            listPaginacion: [],
            PDFObject: this.initPDF,
            Loading_info: false,
            mensajes: '',
            search: '',
            variable: {
                folio: '',
                nombre: '',
                tipoEntidad: '',
                tipoPersona: '',
                rfc: '',
                estado: '',
                estadoRepublica: '',
                segundoNombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                fechaNacimiento: '',
                sexo: '',
                codigoPostal: '',
                calle: '',
                numexterior: '',
                delegacionMunicipio: '',
                numinterior: '',
                telefono: '',
                estadoDomicilio: '',
                razonSocial: '',
                representante: '',
                comentario:'',
                codigoBuro:'',
                colonia:''
            }
        }

        this.folios = parseInt(this.props.match.params.folios);
        this.NextPage = this.NextPage.bind(this);
        this.PrevPage = this.PrevPage.bind(this);
        this.Endquery = this.Endquery.bind(this);
        this.downl = this.downl.bind(this);
        this.getDatos = this.getDatos.bind(this);
        this.getCredit = this.getCredit.bind(this);
        this.getDatos = this.getDatos.bind(this);
        this.getConsultRes = this.getConsultRes.bind(this);

    }

    public initPDF: PdfFile = {
        carpeta: '',
        nombreArchivo: ''
    }

    render(): JSX.Element {
        return <>
          <NavBar />
            <div className="container-fluid warranty" id="consult-query">
              
                <div className="consult col-12  col-md-12">

                    <BreadCumb title={this.props.language.language?.createquery.credittitle!}
                        listBreadcumbs={[
                            { title: this.props.language.language?.createquery.Home!, url: '/buro-credito/Solicitudes' },
                            { title: this.props.language.language?.createquery.consult!, url: '/' }
                        ]} />

                    <div className="row" id="header-consult">

                        <div className="container">

                            <div className="Pipeline col-md-12 col-sm-12" id='pipeline-2'>
                                <PipeLineIcon
                                    listSteps={[this.props.language.language?.createquery.pipeline1!,
                                    this.props.language.language?.createquery.pipeline2!,
                                    this.props.language.language?.createquery.pipeline4!]}
                                    currentStep={this.state.estadoCaptura! - 1} />


                            </div>
                        </div>



                        <div className="Formulario" id='formulario-consult'>


                            <>
                                <Form id="form-consult" >
                                    {this.state.estadoCaptura === 1 ? (
                                        <>
                                            <div className="col-md-12" id='consult'>
                                                <h3>{[this.props.language.language?.createquery.data!]}</h3>
                                                <hr />

                                                <div className='col-12'>
                                                    <p>{[this.props.language.language?.createquery.entity!]}</p>
                                                    <Select
                                                        id="select--default"
                                                        isRequired
                                                        placeholderOption="Prueba"
                                                        labelVisual={`${this.state.variable.tipoEntidad} `}
                                                        disabled

                                                    >

                                                    </Select>
                                                    <br />
                                                    <p>{[this.props.language.language?.createquery.person!]}</p>
                                                    <Select
                                                        id="Persona"
                                                        name='persona'
                                                        isRequired
                                                        labelVisual={this.state.variable.tipoPersona}
                                                        disabled

                                                    >


                                                    </Select>
                                                </div>
                                                <br />
                                                <>

                                                    <div className="col-md-12" id='consult'>
                                                        <h3>{[this.props.language.language?.createquery.persondata!]}</h3>
                                                        <hr />


                                                        <div className='col-12'>
                                                            {this.state.variable.tipoPersona === 'Fisica' &&
                                                                <>
                                                                    <p>RFC</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.rfc}
                                                                        value=""
                                                                        required={true} disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.name!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.nombre}
                                                                        value="" disabled />


                                                                    <p>{[this.props.language.language?.createquery.second!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.segundoNombre}
                                                                        value="" disabled />
                                                                    <p>{[this.props.language.language?.createquery.last!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.apellidoPaterno}
                                                                        value="" disabled />
                                                                    <p>{[this.props.language.language?.createquery.mother!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.apellidoMaterno}
                                                                        value="" disabled />

                                                                    <p>{[this.props.language.language?.createquery.date!]}</p>
                                                                    <input
                                                                        name="fechaInicial"
                                                                        id="fechaInicial"
                                                                        className="input-sdgf"
                                                                        placeholder={this.state.variable.fechaNacimiento}
                                                                        disabled />

                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.telefono!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.telefono}
                                                                        value="" disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.sex!]}</p>
                                                                    <Select
                                                                        id="select--default"
                                                                        isRequired
                                                                        labelVisual={this.state.variable.sexo}
                                                                        onChange={function placeholderOptionOnChange() { }}
                                                                        disabled
                                                                    >

                                                                    </Select>
                                                                </>}

                                                            {this.state.variable.tipoPersona === 'Moral' &&
                                                                <>
                                                                    <p>{[this.props.language.language?.createquery.razonsocial!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.razonSocial}
                                                                        value="" disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.representante!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.representante}
                                                                        value="" disabled />
                                                                    <br />
                                                                    <p>RFC</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.rfc}
                                                                        value=""
                                                                        required={true} disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.telefono!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.variable.telefono}
                                                                        value="" disabled />


                                                                </>}
                                                        </div>
                                                    </div><br /><div className="col-md-12" id='consult'>
                                                        <h3>{[this.props.language.language?.createquery.residence!]}</h3>
                                                        <hr />


                                                        <div className='col-12'>

                                                            <p>C.P</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                labelVisual={this.state.variable.codigoPostal}
                                                                required={true} disabled />


                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.state!]}</p>
                                                            <Select
                                                                id="select--default"
                                                                isRequired
                                                                labelVisual={this.state.variable.estadoDomicilio}
                                                                onChange={function placeholderOptionOnChange() { }}
                                                                disabled
                                                            >

                                                            </Select>

                                                            <p>{[this.props.language.language?.createquery.town!]}</p>
                                                            <Select
                                                                id="select--default"
                                                                isRequired
                                                                labelVisual={this.state.variable.delegacionMunicipio}
                                                                onChange={function placeholderOptionOnChange() { }}
                                                                disabled
                                                            >

                                                            </Select>


                                                            <p>{[this.props.language.language?.createquery.street!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                labelVisual={this.state.variable.calle}
                                                                value="" disabled />
                                                            <p>{[this.props.language.language?.createquery.number!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                labelVisual={this.state.variable.numexterior}
                                                                value="" disabled />
                                                            <p>{[this.props.language.language?.createquery.numint!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                labelVisual={this.state.variable.numinterior}
                                                                value="" disabled />


                                                            <br />
                                                            <GridRow gutterSize="fixed-16" gutters>

                                                                <GridCol base={12} md={12}>
                                                                    <Button dynamicWidth variant="primary" onClick={this.NextPage}>
                                                                        {this.props.language.language?.createquery.NextPage!}
                                                                    </Button>
                                                                </GridCol>
                                                            </GridRow>
                                                        </div>

                                                    </div></>

                                            </div>



                                        </>) : null}
                                    {this.state.estadoCaptura === 2 ? (
                                        <>
                                            <div className="col-12" onClick={this.downl}>
                                                <div className="card" id='cards'>
                                                    <div className="card-body text-center"><br /><br />
                                                        <h3 className="card-title">{this.props.language.language?.createquery.format!}</h3><br />
                                                        <div className="icon-wrapper">
                                                            <IconDownload size="64" color='gray' />
                                                        </div>
                                                        {this.props.language.language?.createquery.downl!}
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>

                                            <br /><br />
                                            <GridRow gutterSize="fixed-16" gutters>
                                                <GridCol base={12} md={6}>
                                                    <Button dynamicWidth onClick={this.PrevPage}>
                                                        {this.props.language.language?.createquery.PrevPage!}
                                                    </Button>
                                                </GridCol>
                                                <GridCol base={12} md={6}>
                                                    <Button dynamicWidth variant="primary" onClick={this.NextPage}>
                                                        {this.props.language.language?.createquery.NextPage!}
                                                    </Button>
                                                </GridCol>
                                            </GridRow>
                                        </>

                                    ) : null}
                                    {this.state.estadoCaptura === 3 ? (
                                        <>
                                            <div className="col-12" >
                                                <div className="card" id='cards'>
                                                    <div className="card-body text-center"><br /><br />
                                                       <br />
                                                        <div className="icon-wrapper">
                                                        <IconSuccessAlert color="positive" size="64" />
                                                            <br /><br />
                                                        </div>
                                                        <h4 className="card-title">Consulta Autorizada</h4>
                                                       
                                                       <p>{this.state.variable.comentario}</p>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>

                                            <br /><br />
                                            <div className="boton">
                                                <GridRow gutterSize="fixed-16" gutters>
                                                    <GridCol base={12} md={6}>
                                                        <Button dynamicWidth onClick={this.PrevPage}>
                                                            {this.props.language.language?.createquery.PrevPage!}
                                                        </Button>
                                                    </GridCol>
                                                    <GridCol base={12} md={6}>
                                                        <Button dynamicWidth variant="primary" onClick={this.Endquery}>
                                                            {this.props.language.language?.createquery.finalize!}
                                                        </Button>
                                                    </GridCol>
                                                </GridRow>

                                            </div>
                                        </>

                                    ) : null}


                                </Form></>




                        </div>

                    </div>
                </div>
            </div >
        </>


    }


    saveWarranty() {
        throw new Error('Method not implemented.');

    }

    public NextPage() {
        const { estadoCaptura } = this.state
        this.setState({ estadoCaptura: estadoCaptura + 1 })
    }

    public PrevPage() {
        const { estadoCaptura } = this.state
        this.setState({ estadoCaptura: estadoCaptura - 1 })
    }

    public Endquery() {
        this.props.history.push('/buro-credito/Solicitudes');
    }

    public downl() {
        this.postURl();
    }



    componentDidMount() {
        this.getCredit();
        this.getDatos();
        this.getConsultRes();
    }


    public getDatos(): void {

        var storedata = localStorage.getItem('FolioConsulta');
        if (storedata == null) {
            console.log('vacio')

        } else {
            var data = storedata.replace(/['"]+/g, '')
            this.setState({ folio: data })
            //console.log(data)
            this.setState({
                PDFObject: { carpeta: 'buro', nombreArchivo: data + `.pdf` }
            })


        }
    }

    async getCredit(): Promise<void> {
        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                const res = await getCreditbureau.getConsul(dt);

                this.setState({ variable: res.data })
            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    public async postURl() {
        try {
            const res = await apiS3Services.postUrl(this.state.PDFObject);
            console.log(res.data)
            const urldata = JSON.stringify(res.data.url)
            const comillas = urldata.replace(/['"]+/g, '')
            console.log(comillas)
            var win = window.open(comillas, '_blank');
            win?.focus();

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }



    public async getConsultRes() {
        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data

                this.setState({ Loading_info: true })
                const res = await getCreditbureau.getConsultRes(dt)


                const ob = (res.data.creditos)
                this.setState({ datofisica: ob })
                this.setState({ datoMoral: ob })
                const mensaje = res.data.mensaje;
                this.setState({ mensajes: mensaje });

                if (res.data.creditos == null) {
                    this.setState({ errorWarrantyDetail: true })
                } else {
                    const ob = (res.data.creditos)
                    this.setState({ datofisica: ob })
                    this.setState({ loadingConsult: false })
                    this.setState({ errorWarrantyDetail: false })

                }
                this.setState({ Loading_info: false })


            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                this.setState({ errorWarrantyDetail: true })

                this.setState({ Loading_info: false })
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }
}


const mapStateToProps = (state: RootStore) => ({
    language: state.language
});

export default connect(mapStateToProps)(Autorizadas);


interface ConsultVVState {

    estadoCaptura: number;
    idWarranty: number,
    loadingWarrantyDetail: boolean,
    errorWarrantyDetail: boolean,
    select: string,
    status: number,
    validador: number,
    entidad: Entidad[],
    datovalid: string,
    folio: string,
    datos: ConsultaBuro[],
    datofisica: Fisicas[],
    datoMoral: Morales[],
    FormFisicas: FormData,
    paginacion: PaginacionModel,
    loadingConsult: boolean,
    listPaginacion: number[],
    PDFObject: PdfFile,
    search: string,
    variable: ConsultaBuro,
    Loading_info: boolean,
    mensajes: string
}

function setProductName(value: any) {
    throw new Error('Function not implemented.');
}

function searchProducts(value: any) {
    throw new Error('Function not implemented.');
}

