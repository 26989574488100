import {SpecialAction} from "../models/public/SpecialAction";

export const SPECIAL_ACTIONS_LOADING = "SPECIAL_ACTIONS_LOADING";
export const SPECIAL_ACTIONS_FAIL = "SPECIAL_ACTIONS_FAIL";
export const SPECIAL_ACTIONS_SUCCESS = "SPECIAL_ACTIONS_SUCCESS";


export interface SpecialActionsLoading {
    type: typeof SPECIAL_ACTIONS_LOADING
}

export interface SpecialActionsFail {
    type: typeof SPECIAL_ACTIONS_FAIL
}

export interface SpecialActionsSuccess {
    type: typeof SPECIAL_ACTIONS_SUCCESS,
    payload: SpecialAction[]
}

export type SpecialActionsDispatchTypes = SpecialActionsLoading | SpecialActionsFail | SpecialActionsSuccess