import axios from 'axios';
import environment from "../../environments/environment";
import { LocalStorageClass } from "../../class/LocalStorageClass";
import { PdfFile } from '../../models/buro-credito/PdfFile';

const APIS3= environment.APIS3;
const localStorageClass = new LocalStorageClass();

const headers = {
    Accept: 'application/json',
    'X-API-KEY': environment.APIKEYS3,
    Authorization: localStorageClass.getProfileToken(),
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode(),

}

const headersFormData = {
    'Content-Type': 'multipart/form-data',
    'X-API-KEY': environment.APIKEYS3,
    Accept: 'application/json',
    Authorization: localStorageClass.getProfileToken(),
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode(),

}



//Enviamos el documento
export const postFile = async (formData: FormData) => {
    return await axios.post<FormData>(`${APIS3}/archivo` , formData, { headers: headersFormData });
};


//obtiene la url
export const postUrl = async (PDFObject: PdfFile) => {
    return await axios.post<PdfFile>(`${APIS3}/url` , PDFObject, { headers: headers});
};


