import React, {Component} from "react";
import './ObligorsDetails.scss';
import {Link, RouteComponentProps} from "react-router-dom";
import {DefaultStateI} from "../../../reducer/LanguageReducer";
import {CifrasFiadoModel} from "../../../models/garantias/CifrasFiadoModel";
import {RootStore} from "../../../Store";
import {connect} from "react-redux";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import NavBar from "../../../components/public/nav-bar/NavBar";
import {BreadCumb} from "../../../components/public/breadcumb/BreadCumb";
import {ExpedientIdentification} from "../../../components/garantias/expedient-identification/ExpedientIdentification";
import {InfoPrincipal} from "../../../components/garantias/info-principal/InfoPrincipal";
import {Accordion, Button as ButtonBootstrap, Card, ProgressBar} from "react-bootstrap";
import {formatCurrency, validRoles} from "../../../class/CommonClass";
import {TableLoansPrincipal} from "../../../components/garantias/table-loans-principal/TableLoansPrincipal";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import {UserModel} from "../../../models/public/UserModel";
import $ from "jquery";
import {FaComments} from "react-icons/fa";
import ComentariosFiado from "../../../components/garantias/comentarios-fiado/ComentariosFiado";
import {PrincipalStatistics} from "../../../components/garantias/principal-statistics/PrincipalStatistics";
import {ResumeGarantias} from "../../../components/garantias/resume-garantias/ResumeGarantias";
import {PrincipalActions} from "../../../components/garantias/principal-actions/PrincipalActions";
import {Accordion as AccordionLiberty, AccordionTab, Button, IconAddItem} from "@lmig/lmds-react";
import {LineaDeAfianzamiento} from "../../../components/garantias/linea-de-afianzamiento/LineaDeAfianzamiento";
import {AcordionPrincipal} from "../../../models/garantias/AcordionPrincipal";

interface ObligorsDetailsProps {
    id: number;
    language: DefaultStateI
}
interface Params {
    idSolidary: string;
}

interface LocationState {
    ownerName: string;
}

// Static Context is available when you use Static Router*
interface SC {
    statusCode?: number;
}
class ObligorsDetails extends Component<ObligorsDetailsProps & RouteComponentProps<Params, SC, LocationState>, ObligorsDetailsState> {


    public localStorageClass = new LocalStorageClass();

    public initAcordion: AcordionPrincipal= {
        resumen: false,
        lineaAfianzamiento: false,
        garantias: true
    }
    constructor(props: any) {
        super(props);
        document.title = "Obligado a Solidario " + this.props.match.params.idSolidary;

        this.state = {
            count: 0,
            PERFIL:  this.localStorageClass.getProfile(),
            idSolidary: parseInt(this.props.match.params.idSolidary),
            idPrincipal:0,
            openComentario: false,
            acordion: this.initAcordion

        }

    }

    render(): JSX.Element {
        return <>
              <NavBar/>
            <div className="container-fluid">
          

                <div id="obligors-details">
                    <BreadCumb title={this.props.language.language?.obligorsDetail.title!}
                               listBreadcumbs={[
                                   {title: this.props.language.language?.obligorsDetail.home!, url:'/garantias/'},
                                   {title: this.props.language.language?.obligorsDetail.title!, url:this.props.location.pathname}]}/>

                    <div className="row" id="header-principal">
                        {(validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) ||
                            validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA)) ?
                            (<div className="col-md-12 text-right"
                                  onClick={e =>
                                      this.setState({openComentario: !this.state.openComentario})}>
                                <b className="comments-obligors">
                                    {this.props.language.language?.principalDetail.comments}&nbsp;
                                    <FaComments />
                                </b><br/>
                            </div>) : null
                        }

                        <div className="col-md-12" id="seccion-obligors">
                            <div className="row">
                                <div className="col-md-12 col-lg-6" >
                                    <div className="row">
                                        {(validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) ||
                                            validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR)) &&
                                        validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR) ?
                                            <></>:
                                            <div className="col-md-4">
                                                <ExpedientIdentification
                                                    idPrincipal={this.state.idSolidary}
                                                    showButtons={false}/>
                                            </div>
                                        }
                                        <div className="col-md-8">

                                            <InfoPrincipal
                                                idPrincipal={this.state.idSolidary}
                                                idPrincipaBack={this.state.idPrincipal}
                                                label={this.props.language.language?.principalDetail.fiado!}
                                                openObligors={true}
                                                addOS={false}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <PrincipalActions idPrincipal={this.state.idSolidary}/>

                                        </div>

                                    </div>

                                </div>


                                <div className="col-md-12 col-lg-6">
                                    <PrincipalStatistics idPrincipal={this.state.idSolidary}/>
                                </div>

                                <div className="col-lg-6"/>
                                {/*de esta lista voy a seleccionar los os que tengo*/}
                                {/* /cliente/detalle/{idCliente}/{busquedaOS}*/}

                            </div>
                        </div>


                        <div className="col-md-3" id="comentarios-obligors">
                            <ComentariosFiado idFiado={this.state.idSolidary}
                                              openComentario={this.state.openComentario}/>

                        </div>

                    </div>


                    <div className="row">
                        <div className="col-md-9">
                        </div>
                        <div className="col-md-3">


                            {((validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE)  ||
                                validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR) ||
                                validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO)  ||
                                validRoles(this.state.PERFIL.roles, this.localStorageClass.AUXILIAR_JURIDICO)
                            )) ?
                                null
                                :
                                <Link
                                    title={this.props.language.language?.principalDetail.createWarranty}
                                    to={"/garantias/crear-garantia/fiado/" + this.state.idSolidary }>

                                    <Button
                                        dynamicWidth
                                        className="w-100"
                                        variant="primary"
                                        id="create-line"
                                    >
                                        <IconAddItem size="16"
                                                     title={this.props.language.language?.principalDetail.createWarranty}
                                                     color="blue"/>
                                        &nbsp;
                                        {this.props.language.language?.principalDetail.createWarranty}
                                    </Button>

                                </Link>
                            }


                            <br/>
                        </div>
                    </div>

                    <Accordion defaultActiveKey="2">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={ButtonBootstrap}  variant="link" eventKey="0"
                                                  onClick={event => {this.onClickShowResume()}}>
                                    {this.props.language.language?.principalDetail.resume}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>

                                    {(this.state.acordion.resumen == true)&&
                                        <div className="col-md-12 acordion-content" id="acordion-resumen">
                                            <ResumeGarantias idPrincipal={this.state.idSolidary} />
                                        </div>
                                    }

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={ButtonBootstrap} variant="link" eventKey="1"
                                                  onClick={event => {this.onClickLinea()}}>
                                    {this.props.language.language?.principalDetail.tableLineas}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    {(this.state.acordion.lineaAfianzamiento == true)&&
                                        <div className="col-md-12 acordion-content" id="acordion-linea">
                                            <LineaDeAfianzamiento idPrincipal={this.state.idSolidary}/>
                                        </div>
                                    }


                                </Card.Body>
                            </Accordion.Collapse>

                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={ButtonBootstrap} variant="link" eventKey="2"
                                                  onClick={event => {this.onClickGarantia()}}>
                                    {this.props.language.language?.principalDetail.garantias}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    {(this.state.acordion.garantias == true)&&
                                        <div className="col-md-12 acordion-content" id="acordion-garantias">
                                            <TableLoansPrincipal
                                                showFiado={false}
                                                idPrincipal={this.state.idSolidary}

                                            />
                                        </div>
                                    }
                                </Card.Body>
                            </Accordion.Collapse>

                        </Card>
                    </Accordion>



                </div>
            </div>

        </>
    }

    componentDidMount(){

        const query = new URLSearchParams(this.props.location.search);
        const idFiado  =  query.get('fiado');
        console.log(Number(idFiado))

        if (!isNaN(Number(idFiado))){
            this.setState({idPrincipal: parseInt(idFiado!)})

        }


        let infoPrincipal = $('#seccion-obligors').width();

        let show = false;
        $(".comments-obligors").click(function(){
            console.log(infoPrincipal)


            $( "#comentarios-obligors" ).toggle({duration:"slow",
                start(animation: JQuery.Animation<HTMLElement>) {
                    if (!show){
                        show = true;
                        $( '#seccion-obligors').removeClass( "col-md-12" );
                        $( '#seccion-obligors').addClass( "col-md-9" );

                        $('.obligors-details').css({ "padding-left": "3%"});

                        $('.obligors-details').css({ "padding-right": "0px"});


                    }else {
                        show = false
                        $( '#seccion-obligors').addClass( "col-md-12" );
                        $( '#seccion-obligors').removeClass( "col-md-9" );
                        $('.obligors-details').css({ "padding-left": "7%"});
                        $('.obligors-details').css({ "padding-right": "7%"});
                    }

                },

            });

        });



    }

    componentDidUpdate(prevProps: ObligorsDetailsProps & RouteComponentProps<Params, SC, LocationState>) {
        //   console.log('componentDidUpdate')

        if (prevProps.match.params.idSolidary !== this.props.match.params.idSolidary) {
            console.log(prevProps.match.params.idSolidary )
            console.log(this.props.match.params.idSolidary)
            const idSolidaryAux = parseInt(this.props.match.params.idSolidary);
            //alert(idPrincipalAux);
            this.setState({idSolidary: idSolidaryAux});
        }
    }

    onClickShowResume(){
        const show = !this.state.acordion.resumen;

        this.setState({acordion: {resumen: show, lineaAfianzamiento: false, garantias: false}})
    }

    onClickLinea(){
        const show = !this.state.acordion.lineaAfianzamiento;

        this.setState({acordion: {resumen: false, lineaAfianzamiento: show, garantias: false}})
    }

    onClickGarantia(){
        const show = !this.state.acordion.garantias;

        this.setState({acordion: {resumen: false, lineaAfianzamiento: false, garantias: show}})
    }
}

const mapStateToProps = (state: RootStore)  => ({
    language: state.language
});

export default connect(mapStateToProps) (ObligorsDetails);


interface ObligorsDetailsState {
    count: number;
    PERFIL: UserModel;
    idSolidary: number;
    idPrincipal: number;
    openComentario: boolean;
    acordion: AcordionPrincipal;

}






