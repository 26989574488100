import React, {ChangeEvent, useEffect, useState} from 'react';
import './ModalRejectExpediente.scss';
import {
    Button,
    IconAdd,
    IconChecklist,
    IconCheckmark,
    IconEdit,
    IconErrorAlert,
    IconSearch, IconThumbsDown, IconThumbsUp,
    IconTrash,
    IconUpload, Modal
} from "@lmig/lmds-react";
import {ExpedienteIdetificacionModel} from "../../../models/garantias/ExpedienteIdetificacionModel";
import {MAXSIZEUPLOADFILE, openNewWindow, validFileImg, validRoles} from "../../../class/CommonClass";
import * as garantiasService from '../../../services/garantias/GarantiasService';
import {AxiosError} from "axios";
import {RootStore} from "../../../Store";
import {useSelector} from "react-redux";
import {TypeDocModel} from "../../../models/garantias/TypeDocModel";
import {statesDocIdentification} from "../../../models/garantias/statesDocIdentification";
import {Alert, Dropdown, Spinner} from "react-bootstrap";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {UserModel} from "../../../models/public/UserModel";
import {MotivoRechazoModel} from "../../../models/garantias/MotivoRechazoModel";
import {ConditionOptionModel} from "../../../models/public/ConditionOptionModel";
import Select, {MultiValue} from "react-select";
import {UploadDragExpediente} from "../upload-drag-expediente/UploadDragExpediente";
import {ModalFileMaxUpload} from "../../public/modal-file-max-upload/ModalFileMaxUpload";
import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";

interface ModalAuthorizationExpedienteProps {
    idDoc: number;
    resetIdDoc: () => void;

}


export const ModalRejectExpediente: React.FC<ModalAuthorizationExpedienteProps> = (
    {idDoc,
        resetIdDoc
    }:ModalAuthorizationExpedienteProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    const localStorageClass = new LocalStorageClass();

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [openModalRejection, setOpenModalRejection] = useState<boolean>(false);

    const [loadingReasons, setLoadingReasons] = useState<boolean>(true);

    const [listReasons, setListReasons] = useState<MotivoRechazoModel []>([]);

    const [errorReaseons, setErrorReaseons] = useState<boolean>(true);


    const [listConditionalOption, setListConditionalOption] = useState<ConditionOptionModel[]>([]);

    const [selectedReasons, setSelectedReasons] = useState<MotivoRechazoModel []>([]);


    const [sendingAuthorization, setSendingAuthorization] = useState<boolean>(false);

    const [errorQuoteReason, setErrorQuoteReason] = useState<boolean>(false);

    const [selectIdDoc, setSelectIdDoc] = useState<number>(0);


    const openModalReject = async (idDoc: number) => {

        if (idDoc){
            setOpenModalRejection(true);
            setSelectIdDoc(idDoc)
            setOpenModalRejection(true);
            setLoadingReasons(true);
            setErrorReaseons(false);
            setSelectIdDoc(idDoc)
            try {
                // 4 para dexpedientes de identificación
                const res = await garantiasService.getMotivosRechazoExpedineteIdentificacion(idDoc);
                setListReasons(res.data);
                formatList(res.data);
                setLoadingReasons(false);
            } catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    console.log(err.response.status)
                    console.log(err.response.data)
                    setErrorReaseons(true);
                    setLoadingReasons(false);

                }
            }
        }
    }

    const closeModalReject = () => {
        setOpenModalRejection(false);
        resetIdDoc()
    }


    const putQuoteExpedient = async  (idDocumento : number, estadoDocumento : number, motivos?: MotivoRechazoModel[]) => {
         setSendingAuthorization(true);
         setErrorQuoteReason(false);
         try {
             const res = await garantiasService.putQuoteExpedienteIdentificacion(idDocumento, estadoDocumento, motivos!);
             setSendingAuthorization(false);
             setOpenModalRejection(false);
             closeModalReject();

         } catch (error) {
             const err = error as AxiosError
             if (err.response) {
                 console.log(err.response.status)
                 console.log(err.response.data)
                 setSendingAuthorization(false);
                 setErrorQuoteReason(true);
             }
         }
    }


    const formatList = (rejectList: MotivoRechazoModel[]) => {
        let  listaCondicionalAuxiliar: ConditionOptionModel[] = [];

        for(const i of rejectList){

            listaCondicionalAuxiliar.push({
                name: '',
                value: i.idMotivo,
                label: i.descripcion,
                status: 0})
        }
        setListConditionalOption(listaCondicionalAuxiliar);
    }

    const handleInputChangeSelect = (e: MultiValue<ConditionOptionModel>) => {

        console.log(e)
        const auxselectedReasons: MotivoRechazoModel [] = []
        for (const i of e){
            auxselectedReasons.push({idMotivo: i.value, descripcion: i.label})
        }
        setSelectedReasons(auxselectedReasons);
    }


    useEffect(() => {
        if (idDoc){
            openModalReject(idDoc);
        }

    },[idDoc]);



    return (
        <>
            {/*modal rechazar documento*/}
             <Modal
                isOpen={openModalRejection}
                size="medium"
                modalStyle="standard"
                title={languageState.language?.expedientIdentificaction.selectReason}
                closeFunc={() => {
                    closeModalReject()
                }}
                id="modal-rechazo">
                {loadingReasons?(
                        <div className="col-12">
                            <SpinnerLoading />
                        </div>
                    ):
                    (!errorReaseons)? (
                            <div className="col-12 list-reject">

                                {listReasons.length==0? (
                                        <div className="col-12">
                                            <br/><br/>
                                            <h5>{languageState.language?.expedientIdentificaction.noResults}</h5>
                                        </div>
                                    ):
                                    <>
                                        <Select
                                            placeholder={languageState.language?.expedientIdentificaction.selectReason}
                                            isMulti
                                            name="blocks"
                                            options={listConditionalOption}
                                            onChange={handleInputChangeSelect}
                                        />
                                    </>
                                }
                            </div>):
                        <div className="col-12">
                            <NegativeAlert textAlert={languageState.language?.expedientIdentificaction.sorryReason!}/>
                        </div>
                }
                <div className="row">
                    <div className="col-md-6 col-12">
                        <Button
                            dynamicWidth
                            variant="secondary"
                            onClick={()=>{
                                setOpenModalRejection(false);
                            }}>
                            {languageState.language?.expedientIdentificaction.close!}
                        </Button>
                    </div>
                    <div className="col-md-6 col-12">
                        <Button
                            dynamicWidth
                            variant="primary"
                            onClick={()=>{
                                putQuoteExpedient(selectIdDoc,statesDocIdentification.rechazado,selectedReasons);
                            }}>
                            {languageState.language?.expedientIdentificaction.save!}
                        </Button>
                    </div>

                </div>

            </Modal>







        </>
    );
};

