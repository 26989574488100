import axios from "axios";
import { LocalStorageClass } from "../../class/LocalStorageClass";
import environment from "../../environments/environment";
import { PaginacionModel } from "../../models/public/PaginacionModel";
import { BajaReclamacionModel } from "../../models/juridico/BajaReclamacionModel";

const API = environment.APIJURIDICO;
//const API = 'http://localhost:8080/reclamacion';
const localStorageClass = new LocalStorageClass();

const header = {
    Accept: "application/json",
    "env": environment.env,
    Authorization: localStorageClass.getProfileToken(),
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode()
};

const updateHeader = () => {
    header.Authorization = localStorageClass.getProfileToken();
    header.idLenguaje = localStorageClass.getLanguageId();
    header.lang = localStorageClass.getLanguageCode();
}


export const getBajas = async (tipo: number, page: number, size: number) => {
    updateHeader();
    return await axios.get<{ paginacion: PaginacionModel, detalleBajas: BajaReclamacionModel[] }>(`${API}/bajas?page=${page}&size=${size}&tipoConsulta=${tipo}`, { headers: header });
};

export const postSolicitud = async (cveBaja: number, estado: number) => {
    updateHeader();
    return await axios.post<{ messageResponse: { message: string, code: number } }>(`${API}/solicitud?cveBaja=${cveBaja}&estado=${estado}`, null,{ headers: header });
};

export const getCarta = async (cveBaja: number) => {
    updateHeader();
    return await axios.get(`${API}/cartaPago?cveRec=${cveBaja}`, { responseType: 'arraybuffer', headers: header });
};


export const postAutoriza = async (cveBaja: string, estado: string) => {
    updateHeader();
    return await axios.post<{ messageResponse: { code: number, message: string, } }>(`${API}/autoriza?cveBaja=${cveBaja}&estado=${estado}`, null,{ headers: header });
};

export const getInfoCarta = async (cveBaja: number) => {
    updateHeader();
    return await axios.get<{ noRequerimiento: string, noCheque: string }>(`${API}/infoCarta?cveBaja=${cveBaja}`, { headers: header });
};

export const putInfoCarta = async (cveBajaRec: number, noCheque: string, noReq: string) => {
    updateHeader();
    return await axios.put<{ messageResponse: { message: string, code: number, } }>(`${API}/guardaInfoCarta?cveBaja=${cveBajaRec}&nocheque=${noCheque}&noreq=${noReq}`, { headers: header });
};

export const deleteSolicitud = async (cveBajaRec: number) => {
    updateHeader();
    return await axios.delete<{ messageResponse: { message: string, code: number, } }>(`${API}/eliminaSolicitud?cveBaja=${cveBajaRec}`, { headers: header });
};

export const getAnalisis = async (cveBaja: number) => {
    updateHeader();
    return await axios.get(`${API}/analisisReclamacion?cveRec=${cveBaja}`, { responseType: 'arraybuffer', headers: header });
};