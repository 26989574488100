export interface SelectUserEngagementsLanguage {
    labelToogle: string;
    searchExecutive: string;
    searchAgent: string;
}

export const SelectUserEngagementsLanguageEnglish: SelectUserEngagementsLanguage ={
    labelToogle: 'Direct Client',
    searchExecutive: 'Search Executive',
    searchAgent: 'Search Agent',


}


export const SelectUserEngagementsLanguageSpanish: SelectUserEngagementsLanguage ={

    labelToogle: 'Cliente Directo',
    searchExecutive: 'Buscar Ejecutivos',
    searchAgent: 'Buscar Agente',
}