import React, { ChangeEvent, Component } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { DefaultStateI } from '../../../reducer/LanguageReducer';
import { RootStore } from '../../../Store';
import NavBar from '../../../components/public/nav-bar/NavBar';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import './ViewAuditoria.scss'
import { Button, GridCol, GridRow, IconButton, IconDocument, Table, TableCell, TableRow } from '@lmig/lmds-react';
import { BreadCumb } from '../../../components/public/breadcumb/BreadCumb';
import { CreateAudi, DetallesAuditOficina } from '../../../models/buro-credito/Datos';
import * as getCreditbureau from "../../../services/buro-credito/CreditbureauService"
import { AxiosError } from 'axios';
import { SpinnerLoading } from '../../../components/public/spinner-loading/SpinnerLoading';
import Swal from 'sweetalert2';

export type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>;

interface vencidProps {
    language: DefaultStateI
}



class ViewAuditoria extends Component<vencidProps & RouteComponentProps, Audistate> {

    public localStorageClass = new LocalStorageClass();
    public select = document.getElementById('Persona');
    public archivo = "";
    public initDatosAudi: CreateAudi = {
        tipoPersona: '',
        fechaInicio: '',
        fechaFin: '',
    }
    options: any;

    constructor(props: any) {
        super(props);
        this.state = {
            estadoCaptura: 1,
            folioAuditoria: '',
            foliosProcesados: '',
            fechaConsulta: '',
            Consul_buro: false,
            ListAuditOficina: [],
            validador: false,
            table: 0,
            idOficina: 0,
            tipoPersona: '',
            fechaFin: '',
            fechaInicio: ''

        }
        this.PrevPage = this.PrevPage.bind(this);
        this.Endquery = this.Endquery.bind(this);

    }





    render(): JSX.Element {
        return <>
            <div className="container-fluid audi" id="Create-Auditoria">
                <NavBar />

                <div className="Formulario" id='formulario'>

                    <BreadCumb title={this.props.language.language?.ViewAudi.NewAudi!}
                        listBreadcumbs={[
                            { title: this.props.language.language?.ViewAudi.Home!, url: '/buro-credito/Auditoria' },
                            { title: this.props.language.language?.ViewAudi.NewAudi!, url: '/buro-credito/View-Auditoria' }
                        ]} />



                    <div className="col-md-12" id='consult'>


                        <br />
                        <div className="container">
                            <div className="row">
                                <div className="col-sm">
                                    <h4>{this.props.language.language?.ViewAudi.FolioAuditoria!}:{this.state.folioAuditoria}</h4>
                                </div>
                                <div className="col-sm">
                                    <h4>{this.props.language.language?.ViewAudi.FoliosProce!}:{this.state.foliosProcesados}</h4>
                                </div>
                                <div className="col-sm">
                                    <h4>{this.props.language.language?.ViewAudi.Fecha!}:{this.state.fechaConsulta}</h4>
                                </div>
                            </div>
                        </div>
                        <hr />
                        {this.state.Consul_buro ? (
                            <div className="col-12">
                                <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                            </div>
                        ) :
                            <>
                                {this.state.table === 1 &&
                                    <div className="col-md-12 col-sm-12">
                                        <Table id='table-Auditoria' >
                                            <thead>
                                                <TableRow >
                                                    <TableCell dataHeader={'Folio'} type="colHead" className='tb'>
                                                        {this.props.language.language?.ViewAudi.Oficina!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'fechaCarga'} type="colHead" className='tb'>
                                                        {this.props.language.language?.ViewAudi.NumerFolios!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'Tipo'} type="colHead" className='tb'>
                                                        {this.props.language.language?.ViewAudi.Consultas!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'Fecha'} type="colHead" className='tb'>
                                                        {this.props.language.language?.ViewAudi.Reporte!}
                                                    </TableCell>

                                                </TableRow>
                                            </thead>


                                            <tbody>
                                                {this.state.validador === true ? (
                                                    <>
                                                        {
                                                            this.state.ListAuditOficina && this.state.ListAuditOficina.map((value: DetallesAuditOficina, index: number) => (


                                                                <TableRow key={index} id={value.foliosDuplicados} >

                                                                    <TableCell type="rowHead" className={(value.oficina === 'Folios sin conciliar' ? "Rojo" : '' || value.oficina === 'Total' ? "Verde" : '')}>

                                                                        {value.oficina}
                                                                    </TableCell>
                                                                    <TableCell className={(value.oficina === 'Folios sin conciliar' ? "Rojo" : '' || value.oficina === 'Total' ? "Verde" : '')}>
                                                                        {value.totalNumFolios}
                                                                    </TableCell>
                                                                    <TableCell className={(value.oficina === 'Folios sin conciliar' ? "Rojo" : '' || value.oficina === 'Total' ? "Verde" : '')}>
                                                                        {value.foliosDuplicados}
                                                                    </TableCell>


                                                                    <TableCell dataHeader={'Estatus'} className="estatus">
                                                                        
                                                                        <IconButton background="transparent" onClick={() => this.handleView(value.idOficina)}>
                                                                            
                                                                            <IconDocument color="informative" size="24" />
                                                                        </IconButton>
                                                                    </TableCell>

                                                                </TableRow>




                                                            ))
                                                        }


                                                    </>
                                                ) : null}
                                            </tbody>

                                        </Table>
                                        <br />

                                    </div>
                                } </>}
                        <div className="boton" >

                            <Button dynamicWidth variant="primary" onClick={this.Endquery}>
                                {this.props.language.language?.ViewAudi.Finalizar!}
                            </Button>

                        </div>



                    </div>


                </div>
            </div>

        </>
    }


    saveWarranty() {
        throw new Error('Method not implemented.');

    }
    componentDidMount() {
        this.getDetalleFolProcess();
    }

    public PrevPage() {
        const { estadoCaptura } = this.state

        this.setState({ estadoCaptura: estadoCaptura - 1 })

    }

    public Endquery() {
        this.props.history.push('/buro-credito/Auditoria');
    }


    public handleView(idOficina: string,) {
        var storedata = localStorage.getItem('tipoPersona');
        if (storedata == null) {
        } else {
            var data = storedata.replace(/['"]+/g, '')
            var dt = data

            if (dt === 'Moral') {
                this.setState({ tipoPersona: '2' })
            }

            if (dt === 'Fisica') {
                this.setState({ tipoPersona: '1' })
            }

            const idOfi = Number(idOficina);
            this.setState({ idOficina: idOfi }, () =>
                this.postURl());

        }
    }

    public async postURl() {


        var storedata = localStorage.getItem('FolioAuditoria');
        if (storedata == null) {
        } else {
            var data = storedata.replace(/['"]+/g, '')
            var dt = data
            try {

                const res = await getCreditbureau.getURLReporte(this.state.fechaFin, this.state.fechaInicio, dt, this.state.idOficina, this.state.tipoPersona);
                const urldata = JSON.stringify(res.data.url)
                const comillas = urldata.replace(/['"]+/g, '')
                console.log(comillas)
               // window.open(comillas, '_blank');
               if(res.data.nombreArchivo === 'No existe'){
                this.alert();
               }else{
                var link = document.createElement('a');
                link.href = comillas;
                link.download = 'true';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
               }
                


            } catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    console.log(err.response.status)
                    console.log(err.response.data)
                }
            }
        }
    }


    public async getDetalleFolProcess() {
        var storedata = localStorage.getItem('FolioAuditoria');
        if (storedata == null) {
        } else {
            var data = storedata.replace(/['"]+/g, '')
            var dt = data

            try {
                const res = await getCreditbureau.getDetalleFolProcess(dt)
                const folio = res.data.folioAuditoria
                const foliosProcesados = res.data.foliosProcesados
                const fechaConsulta = res.data.fechaConsulta
                const fechaInicio = res.data.fechaInicio
                const fechaFin = res.data.fechaFin
                this.setState({ folioAuditoria: folio })
                this.setState({ foliosProcesados: foliosProcesados })
                this.setState({ fechaConsulta: fechaConsulta })
                this.setState({ fechaInicio: fechaInicio })
                this.setState({ fechaFin: fechaFin })

                this.getdetalleFoliosOficinas()
            }

            catch {

            }
        }

    }






    public async getdetalleFoliosOficinas() {
        var storedata = localStorage.getItem('FolioAuditoria');
        if (storedata == null) {
        } else {
            var data = storedata.replace(/['"]+/g, '')
            var dt = data
            try {
                this.setState({ Consul_buro: true })
                const res = await getCreditbureau.getdetalleFoliosOficinas(this.state.fechaFin, this.state.fechaInicio, dt)
                const response = res.data
                let x: DetallesAuditOficina[] = []//listado
                let y = {}
                res.data.forEach((Item: DetallesAuditOficina) => {
                    if (Item.oficina === 'Folios sin conciliar') {
                        y = Item
                    } else {
                        x.push(Item)
                    }
                })
                    ; (x as any).push(y)

                    

                this.setState({ ListAuditOficina: x })


                if (response == null) {
                    this.setState({ Consul_buro: true })
                    this.setState({ validador: false })
                    this.setState({ table: 0 })

                } else {
                    this.setState({ Consul_buro: false })
                    this.setState({ validador: true })
                    this.setState({ table: 1 })
                }

            } catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    console.log(err.response.status)
                    console.log(err.response.data)
                }
            }
        }
    }





    //alertas
    public alert() {
        Swal.fire(
            'No existe archivo!',
            '',
            'error'
        )
    }









}



const mapStateToProps = (state: RootStore) => ({
    language: state.language
});

export default connect(mapStateToProps)(ViewAuditoria);


interface Audistate {
    estadoCaptura: number;
    folioAuditoria: string,
    foliosProcesados: string,
    fechaConsulta: string,
    validador: boolean,
    Consul_buro: boolean,
    ListAuditOficina: DetallesAuditOficina[],
    table: number,
    idOficina: number,
    tipoPersona: string,
    fechaFin: string,
    fechaInicio: string


}



