import React, { Component} from "react";
import {RouteComponentProps} from "react-router-dom";
import {
    AlphanumericInput,
    Badge,
    Button,
    Checkbox,
    CheckboxGroup,
    Link as LinkLiberty,
    IconLockClosed,
    IconLockOpen,
    IconAddItem,
    IconSearch,
} from "@lmig/lmds-react";
import { Link } from "react-router-dom";
import {Card, CardDeck} from "react-bootstrap";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import './Warranties.scss';
import SelectTypeUser, {initTipoPerfil, TypeProfileModel} from "../../../components/garantias/select-type-user/SelectTypeUser";
import NavBar from "../../../components/public/nav-bar/NavBar";
import 'react-circular-progressbar/dist/styles.css';
import {RootStore} from "../../../Store";
import {connect} from "react-redux";
import {DefaultStateI} from "../../../reducer/LanguageReducer";
import {UserModel} from "../../../models/public/UserModel";
import {formatCurrency, initPaginacion, InputChange, validRoles} from "../../../class/CommonClass";
import {AxiosError} from "axios";
import * as apiService from '../../../services/api-gral/ApiGralService';
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {PaginacionModel} from "../../../models/public/PaginacionModel";
import {EstadisticasModel} from "../../../models/garantias/EstadisticasModel";
import {SpinnerLoading} from "../../../components/public/spinner-loading/SpinnerLoading";
import {FiadoModel} from "../../../models/garantias/FiadoModel";
import {SolvenciaEnum} from "../../../models/garantias/SolvenciaEnum";
import {NegativeAlert} from "../../../components/public/negative-alert/NegativeAlert";
import {BreadCumb} from "../../../components/public/breadcumb/BreadCumb";
import {ClienteModel} from "../../../models/public/ClienteModel";
import {LockPrincipal} from "../../../components/garantias/lock-principal/LockPrincipal";
import {UnlockPrincipal} from "../../../components/garantias/unlock-principal/UnlockPrincipal";
import { SolvenciaStateEnum } from "../../../models/garantias/SolvenciaStateEnum";

interface WarrantiesProps {
    language: DefaultStateI
}

class Warranties extends Component<WarrantiesProps & RouteComponentProps , WarrantiesState>{

    public localStorageClass = new LocalStorageClass();

    public initFilters : FilterSearchModel = {
        conSolvencia: false,
        sinSolvencia: false,
        porVencer: false
    }

    constructor(props: any) {
        super(props);
        document.title = "Garantías"

        const PERFIL = this.localStorageClass.getProfile();

        this.state = {
            typeProfile: initTipoPerfil,
            idOficina: 0,
            PERFIL: PERFIL,
            saludoE:'',
            listFiados: [],
            loadingFiados: false,
            errorFiados: false,
            paginacion: initPaginacion,
            listStatistics:[],
            loadingStatistics:false,
            search: '',
            filterSearch: this.initFilters,
            showPrincipalLock: false,
            principalLockSelect: {
                idCliente: 0,
                idAgente:0,
                razonSocial: '',
                rfc: '',
                propietario: '',
                estado: 0,
                estadoBloqueoCliente: false,
                estadoBloqueoComision: false,
                descripcion: ''
            },
            showPrincipalUnLock: false,
            principalUnLockSelect: {
                idCliente: 0,
                idAgente:0,
                razonSocial: '',
                rfc: '',
                propietario: '',
                estado: 0,
                estadoBloqueoCliente: false,
                estadoBloqueoComision: false,
                descripcion: ''
            },
            disabledSearch: false,
            seHizoUnaBusqueda: false
        }

        this.handleScroll = this.handleScroll.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    render() {


        return <div onScroll={this.handleScroll}>
              <NavBar/>

            <div className="container-fluid" id="warranties">
          

                <div className="waranty-container">
                    <BreadCumb
                        title={this.props.language.language?.warranties.warrantiesTitle!}
                    />

                    <SelectTypeUser reciveTypeProfile={
                        (tipoPerfil: TypeProfileModel, idOficina) => {
                            this.loadDashboard(tipoPerfil, idOficina!)
                        }
                    }/>

                    {this.state.loadingStatistics?(
                        <div className="col-12">
                            <SpinnerLoading text={this.props.language.language?.warranties.loadingStatics}/>
                        </div>
                    ):
                        <CardDeck className="cards-percentage">
                            {this.state.listStatistics.map((i,index) =>
                                (
                                    <Card key={i.idEstadistica}>
                                        <Card.Body>
                                            <Card.Title>{i.descripcion}</Card.Title>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <CircularProgressbar
                                                        value={i.porcentaje}
                                                        text={`${i.porcentaje}%`}
                                                        styles={buildStyles({
                                                            // Rotation of path and trail, in number of turns (0-1)
                                                            rotation: 0.25,
                                                            // Text size
                                                            textSize: '16px',
                                                            // How long animation takes to go from one percentage to another, in seconds
                                                            pathTransitionDuration: 0.5,
                                                            // Colors
                                                            pathColor: `rgba(6, 116, 140, 0.5)`,
                                                            textColor: '#06748C',

                                                        })}/>
                                                </div>
                                                <div className="col-md-6 ">
                                                    <Card.Text>

                                                    </Card.Text>
                                                </div>

                                            </div>

                                        </Card.Body>
                                        <Card.Footer className="text-right bg-white">
                                            <Link
                                                to={"/garantias/estadistica/" + i.descripcion + "/"+ i.idEstadistica + "/"+
                                                this.state.typeProfile.tipo + "/" + this.state.typeProfile.id}>
                                                <LinkLiberty
                                                    className={"h6"} variant="standalone">
                                                    {this.props.language.language?.warranties.openStatics}
                                                </LinkLiberty>
                                            </Link>
                                        </Card.Footer>
                                    </Card>
                                ))
                            }
                        </CardDeck>
                    }


                    <div className="seccion-botons">
                        <div className="row">


                            <div className="col-md-4 col-lg-6"/>

                            <div className="col-md-4 col-lg-4">
                                <AlphanumericInput
                                    className="float-right w-100"
                                    labelVisual={this.props.language.language?.warranties.searchLabel}
                                    name="search"
                                    autoComplete="off"
                                    onChange={this.handleInputChange}
                                    onKeyDown={this.onKeyDown}
                                    disabled={this.state.loadingFiados}
                                    value={this.state.search}
                                />
                            </div>
                            <div className="col-md-4 col-lg-2">
                                    <Button  variant="primary"
                                             className="float-right w-100"
                                             id="btn-search-principals"
                                             disabled={this.state.loadingFiados}
                                            onClick={()=> {this.serchPrincipals()}}>
                                        <IconSearch size="16" />&nbsp; {this.props.language.language?.warranties.searchButton}
                                    </Button>
                            </div>
                            <div className="col-md-6"/>
                            <div className="col-md-6">

                                <CheckboxGroup  name="solvencia" >
                                    <div className={"row"}>
                                        <div className={"col-4"}>
                                            <Checkbox
                                                onChange={this.handleCheckBoxChange}
                                                name={SolvenciaEnum.conSolvencia}
                                                checked={this.state.filterSearch.conSolvencia}
                                                labelVisual={this.props.language.language?.warranties.filterwithSolv} />
                                        </div>
                                        <div className={"col-4"}>
                                            <Checkbox
                                                onChange={this.handleCheckBoxChange}
                                                name={SolvenciaEnum.sinSolvencia}
                                                checked={this.state.filterSearch.sinSolvencia}
                                                labelVisual={this.props.language.language?.warranties.filterwithOutSolv} />
                                        </div>
                                        <div className={"col-4"}>
                                            <Checkbox
                                                onChange={this.handleCheckBoxChange}
                                                name={SolvenciaEnum.porVencer}
                                                checked={this.state.filterSearch.porVencer}
                                                labelVisual={this.props.language.language?.warranties.toExpire}
                                            />
                                        </div>

                                    </div>
                                </CheckboxGroup>

                            </div>


                        </div>

                    </div>

                    {(this.state.errorFiados)? (
                            <div className="col-12">
                                <NegativeAlert
                                    textAlert={this.props.language.language?.warranties.errorFiados!}/>
                            </div>):
                            <div className="col-12">
                                {this.state.listFiados.map((i,index) =>
                                    (
                                        <Card key={i.cliente.idCliente} border={
                                            `${!i.cliente.estadoBloqueoCliente && i.cliente.estado==SolvenciaStateEnum.conSolvencia ? "success" : ""}` +
                                            `${!i.cliente.estadoBloqueoCliente && i.cliente.estado==SolvenciaStateEnum.sinSolvencia ? "danger" : ""}` +
                                            `${!i.cliente.estadoBloqueoCliente && i.cliente.estado==SolvenciaStateEnum.porVencer ? "warning" : ""}`+
                                            `${i.cliente.estadoBloqueoCliente ? "danger" : ""}`
                                        } >
                                            <Card.Header className="text-right ">
                                                {(
                                                    validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR)
                                                )?(
                                                    <></>
                                                ):
                                                    <Link to={"/garantias/crear-garantia/fiado/" + i.cliente.idCliente} >

                                                        <IconAddItem size="16"
                                                                     title={this.props.language.language?.warranties.createWarranty!}
                                                                     color="informative"/>&nbsp;&nbsp;

                                                    </Link>
                                                }


                                                {(i.cliente.estado==SolvenciaStateEnum.conSolvencia)?(<span className="badge badge-pill badge-success">{this.props.language.language?.warranties.withSolv!}</span>):null}
                                                {(i.cliente.estado==SolvenciaStateEnum.sinSolvencia)?(<span className="badge badge-pill badge-danger">{this.props.language.language?.warranties.withOutSolv!} </span>):null}
                                                {(i.cliente.estado==SolvenciaStateEnum.porVencer)?(<span className="badge badge-pill badge-success">{this.props.language.language?.warranties.toExpire!} </span>):null}

                                            </Card.Header>
                                            <Card.Body >
                                                <Card.Text className="item-warranty">
                                                    <div className="row">
                                                        <div className="col-md-2 icon-warranty">

                                                            {(!i.cliente.estadoBloqueoCliente)?(
                                                                validRoles(this.state.PERFIL.roles,
                                                                        this.localStorageClass.EJECUTIVODEGARANTIAS) ? (
                                                                            <>
                                                                                <IconLockOpen
                                                                                    size="48"
                                                                                    color="positive"
                                                                                    className="d-block mx-auto lock-open"
                                                                                    title={this.props.language.language?.warranties.lockPrincipal! +
                                                                                        ' ' + i.cliente.razonSocial}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            showPrincipalLock: true,
                                                                                            showPrincipalUnLock: false,
                                                                                            principalLockSelect: i.cliente
                                                                                        })
                                                                                    }}

                                                                                />
                                                                               {/* <div className="text-success  label-lock">
                                                                                    {this.props.language.language?.warranties.lockPrincipal! +
                                                                                        ' ' + i.cliente.razonSocial}
                                                                                </div>*/}

                                                                            </>):
                                                                            <>
                                                                                <IconLockOpen
                                                                                    size="48"
                                                                                    color="positive"
                                                                                    title={this.props.language.language?.warranties.unlockedPrincipal}
                                                                                    className="d-block mx-auto lock-open"
                                                                                    onClick={() => {}}
                                                                                />
                                                                                   {/* <div className="text-success  label-lock">
                                                                                        {this.props.language.language?.warranties.unlockedPrincipal}
                                                                                    </div>*/}
                                                                            </>


                                                            ):
                                                                validRoles(this.state.PERFIL.roles,
                                                                    this.localStorageClass.EJECUTIVODEGARANTIAS) ? (
                                                                        <>
                                                                            <IconLockClosed
                                                                                color="error"
                                                                                size="48"
                                                                                className="d-block mx-auto"
                                                                                title={this.props.language.language?.warranties.unLockPrincipal}
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        showPrincipalUnLock: true,
                                                                                        showPrincipalLock: false,
                                                                                        principalUnLockSelect: i.cliente
                                                                                    })
                                                                                }}

                                                                            />
                                                                             {/*   <div className="text-danger  label-lock">
                                                                                {this.props.language.language?.warranties.unLockPrincipal}
                                                                                </div>*/}

                                                                        </>):
                                                                         <>
                                                                            <IconLockClosed
                                                                                color="error"
                                                                                size="48"
                                                                                className="d-block mx-auto"
                                                                                title={this.props.language.language?.warranties.lockedPrincipal} />
                                                                            {/*     <div className="text-danger label-lock">
                                                                                     {this.props.language.language?.warranties.lockedPrincipal}
                                                                                 </div>*/}

                                                                        </>
                                                            }



                                                        </div>
                                                        <div className="col-md-10">

                                                            <div className="row">
                                                                <div className="col-md-8">
                                                                    <h4>
                                                                        {i.cliente.razonSocial}
                                                                    </h4>
                                                                    <h4 className="font-weight-bold">
                                                                        {i.cliente.rfc}
                                                                    </h4>
                                                                    <br/>
                                                                </div>
                                                                <div className="col-md-4"/>

                                                                <div className="col-md-12 elementos">
                                                                    <div className="row">
                                                                        {i.elementos.map((j,index2) =>
                                                                            (   <div key={index2} className="col">
                                                                                    {j.descripcion} <br/>
                                                                                    <span className="amount">{formatCurrency(j.monto)}</span>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 estatus">
                                                                    <div className="row">
                                                                        {i.estatus.map((j,index2) =>
                                                                            (     <div className="col-md-3">
                                                                                    {j.idEstado == 2 ? (
                                                                                        <Badge className="float-left" highlightType="informative">
                                                                                            {j.numGarantia + ' ' + j.estado }
                                                                                        </Badge>
                                                                                    ): null}
                                                                                    {j.idEstado == 3 ? (
                                                                                        <Badge className="float-left" highlightType="positive">
                                                                                            {j.numGarantia + ' ' + j.estado }
                                                                                        </Badge>
                                                                                    ): null}
                                                                                    {j.idEstado == 6 ? (
                                                                                        <Badge className="float-left" highlightType="caution">
                                                                                            {j.numGarantia + ' ' + j.estado }
                                                                                        </Badge>
                                                                                    ): null}

                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 see-details" >
                                                                    <Link
                                                                        to={"/garantias/fiado/" + i.cliente.idCliente}>
                                                                        {this.props.language.language?.warranties.seeDetails!}
                                                                    </Link>

                                                                    <div className="name-agent float-right">{i.cliente.propietario}</div>


                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    ))
                                }

                                {(!this.state.seHizoUnaBusqueda) &&
                                    <div className="col-12 text-center">
                                        <br/><br/>
                                        {/*<h5>{this.props.language.language?.warranties.searchFiados!}</h5>*/}
                                        <br/><br/><br/>
                                    </div>
                                }


                                {(this.state.listFiados.length==0 && !this.state.loadingFiados && this.state.seHizoUnaBusqueda) ? (
                                    <div className="col-12 text-center">
                                        <br/><br/>
                                        <h5>{this.props.language.language?.warranties.noResults!}</h5>
                                        <br/><br/>
                                    </div>
                                ): null}
                            </div>
                    }
                    {this.state.loadingFiados ? (
                        <div className="col-12">
                            <SpinnerLoading text={this.props.language.language?.warranties.loadingFiados!}/>
                        </div>): null
                    }

                </div>

            </div>
            {/*Modal de Fiado bloqueados*/}
            <LockPrincipal
                idPrincipal={this.state.principalLockSelect.idCliente}
                showModal={this.state.showPrincipalLock}
                namePrincipal={this.state.principalLockSelect.razonSocial}
                clickShowModal={(show, idPrincipal) => {
                    this.setState({showPrincipalLock: show})
                    if (idPrincipal){
                        this.lockedPrinicipal(idPrincipal)
                    }
                    }
                }/>


            {/*Modal desbloquear Fiado */}
            <UnlockPrincipal
                idPrincipal={this.state.principalUnLockSelect.idCliente}
                showModal={this.state.showPrincipalUnLock}
                namePrincipal={this.state.principalLockSelect.razonSocial}
                clickShowModal={(show, idPrincipal) => {
                    this.setState({showPrincipalUnLock: show})
                    if (idPrincipal){
                        this.unLockedPrinicipal(idPrincipal)
                    }
                }}/>
        </div>
    }

    componentDidMount(){
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    public lockedPrinicipal (idPrincipal: number){
        let auxlistPrincipals: FiadoModel[] = this.state.listFiados;
        for (let principal  of auxlistPrincipals){
            if (principal.cliente.idCliente == idPrincipal){
                principal.cliente.estadoBloqueoCliente = true;
                break;
            }
        }
        this.setState({listFiados:auxlistPrincipals })
    }
    public unLockedPrinicipal (idPrincipal: number){
        let auxlistPrincipals: FiadoModel[] = this.state.listFiados;
        for (let principal  of auxlistPrincipals){
            if (principal.cliente.idCliente == idPrincipal){
                principal.cliente.estadoBloqueoCliente = false;
                break;
            }
        }
        this.setState({listFiados:auxlistPrincipals })
    }
    public loadDashboard(typeProfile: TypeProfileModel, idOficina: number | null){
        this.setState({typeProfile: typeProfile, idOficina: idOficina})
        // inicializar las estadisticas y los clientes
        this.setState({listStatistics: [], paginacion: initPaginacion, search: '', filterSearch: this.initFilters})
        this.getEstadisticas(typeProfile, idOficina);
        //this.getClients(typeProfile, idOficina, initPaginacion, '', this.initFilters, true);
    }

    public async getEstadisticas(typeProfile: TypeProfileModel, idOficina: number | null) {
        this.setState({loadingStatistics: true})
        try {
            const res = await garantiasService.getEstadisticas(typeProfile, idOficina);
           // console.log(res.data)
            this.setState({loadingStatistics: false, listStatistics: res.data})

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                this.setState({loadingStatistics:false});


            }
        }
    }

    public onKeyDown (event: React.KeyboardEvent<HTMLDivElement>){
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.serchPrincipals()
        }
    }

    public async getClients (typeProfile: TypeProfileModel,
                             idOficina: number | null,
                             paginacion: PaginacionModel,
                             search: string,
                             filtersSearch: FilterSearchModel,
                             initFiados: boolean) {
        if (!this.state.seHizoUnaBusqueda){
            this.setState({seHizoUnaBusqueda: true})
        }

        // cargando fiados
        if (initFiados){
            this.setState({listFiados:[], loadingFiados:true});
        }else {
            this.setState({loadingFiados:true});
        }

        console.log('search' + search);
        if (search == ''){search = 'all'}
        try {
            const res = await apiService.getClientesBusqueda(typeProfile,idOficina,paginacion,search, filtersSearch);
          //  console.log(res.data);
            const response: {paginacion: PaginacionModel, detalle: FiadoModel[]} = res.data;
            response.paginacion.size = paginacion.size;
            // agregar los nuevos registros sin borrar los anteriores
            const auxListFiados = this.state.listFiados;

            for (const fiado of response.detalle){
                auxListFiados.push(fiado);
            }
            console.log(this.state.search);
            // validar que lo que estamos buscando sea lo que estamos seteando
            if (this.state.search == search || search == 'all' ){
                this.setState({
                    loadingFiados:false,
                    paginacion:response.paginacion,
                    listFiados:auxListFiados});
            }


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                this.setState({loadingFiados:false});
                this.setState({errorFiados:true});

            }
        }
    }

    public loadInfiniteScroll(bottom: boolean){
        if (bottom){
            console.log('bottom reached')
            //hacer las operaciones de la paginación
            const  auxPaginacion = this.state.paginacion;
            const nextPage = (auxPaginacion.currentPage+1);
            console.log('paginacion actual ' + auxPaginacion.currentPage);
            console.log('paginacion siguiente ' + nextPage);
            if (nextPage <= auxPaginacion.totalPages){
                if (!this.state.loadingFiados){
                    auxPaginacion.currentPage = nextPage
                    this.getClients(this.state.typeProfile,this.state.idOficina, auxPaginacion, this.state.search, this.state.filterSearch, false);
                }

            }

        }else {
           // console.log('not at bottom')
        }

    }

    public handleScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
      //  console.log('windowBottom ' + windowBottom + ' >= docHeight: ' + docHeight);
        if ( Math.round(windowBottom) >= docHeight-1) {
        //    console.log('bottom reached')
            this.loadInfiniteScroll(true)
        } else {
            this.loadInfiniteScroll(false)
        }
    }

    public handleInputChange (e: InputChange): void{
        const value = e.target.value;
       // console.log(e.target.value);
        if (e.target.name == 'search'){
            this.setState({search:value }) // iniciar los valores

        }
    }

    public handleCheckBoxChange (e: InputChange): void{
        const name = e.target.name;
       // console.log(e.target.value);
       // console.log(e.target.name);
        let auxFiltersSearch = this.state.filterSearch;
        switch (name){
            case SolvenciaEnum.conSolvencia:

                auxFiltersSearch.conSolvencia = auxFiltersSearch.conSolvencia? false: true
                this.setState({filterSearch: auxFiltersSearch})
                break;
            case SolvenciaEnum.sinSolvencia:
                auxFiltersSearch.sinSolvencia = auxFiltersSearch.sinSolvencia? false: true
                this.setState({filterSearch: auxFiltersSearch})
                break;
            case SolvenciaEnum.porVencer:
                auxFiltersSearch.porVencer = auxFiltersSearch.porVencer? false: true
                this.setState({filterSearch: auxFiltersSearch})
                break;
        }

    }



    private serchPrincipals() {
        // incializar la paginación si se va a buscar
        this.setState({paginacion: initPaginacion})
        this.getClients(this.state.typeProfile,this.state.idOficina, initPaginacion, this.state.search, this.state.filterSearch, true);

    }
}

const mapStateToProps = (state: RootStore)  => ({
    language: state.language
});


export default connect(mapStateToProps) (Warranties);

interface WarrantiesState {
    typeProfile: TypeProfileModel;
    idOficina: number | null;
    PERFIL: UserModel;
    saludoE: string;
    listFiados: FiadoModel[];
    paginacion: PaginacionModel;
    loadingFiados:  boolean;
    errorFiados: boolean;
    listStatistics: EstadisticasModel[];
    loadingStatistics:  boolean;
    search: string;
    filterSearch: FilterSearchModel;
    showPrincipalLock: boolean;
    principalLockSelect: ClienteModel;
    showPrincipalUnLock: boolean;
    principalUnLockSelect: ClienteModel;
    disabledSearch: boolean;
    seHizoUnaBusqueda: boolean;
}

export interface FilterSearchModel{
    conSolvencia: boolean;
    sinSolvencia: boolean;
    porVencer: boolean;
}




