import {Dispatch} from "redux";
import * as generalService from "../services/api-gral/ApiGralService";
import {
    SPECIAL_ACTIONS_FAIL,
    SPECIAL_ACTIONS_LOADING,
    SPECIAL_ACTIONS_SUCCESS,
    SpecialActionsDispatchTypes
} from "./SpecialActionTypes";

export const getActionSpecials = () => async (dispatch: Dispatch<SpecialActionsDispatchTypes>) => {
    try {
        dispatch({
            type: SPECIAL_ACTIONS_LOADING
        })

        const res = await generalService.getEspecialAction()

        dispatch({
            type: SPECIAL_ACTIONS_SUCCESS,
            payload: res.data
        })

    } catch(e) {
        dispatch({
            type: SPECIAL_ACTIONS_FAIL
        })
    }
};