import React, { Component, Key } from "react";
import './CreateEngagement.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import {Route, RouteComponentProps} from "react-router-dom";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import '@lmig/lf-sgf-library-messages/src/index.css'
import 'react-infinite-calendar/styles.css';
import {BreadCumb} from "../../../components/public/breadcumb/BreadCumb";
import FormCreateEngagments from "../../../components/compromisos/form-create-engagments/FormCreateEngagments";
import FormCreateEngagmentsClase
    from "../../../components/compromisos/form-create-engagments/FormCreateEngagmentsClase";
import {FormCreateEngagmentsNew} from "../../../components/compromisos/form-create-engagments/FormCreateEngagmentsNew";
import environment from "../../../environments/environment";

interface CreateEngagementProps {
    language: DefaultStateI
}

interface Params {
    idFiadoBueno: string;
}

interface LocationState {
    ownerName: string;
}

interface SC {
    statusCode?: number;
}

class CreateEngagement extends Component<CreateEngagementProps & RouteComponentProps<Params, SC, LocationState>, CreateEngagementState> {


    constructor(props: any) {
        super(props);

    }

    render(): JSX.Element {
        return <>
              <NavBar />
            <div className="container-fluid" >
          
                <div id="create-engagements">
                    <BreadCumb  title={this.props.language.language?.createEngagement.nombreDocumentoCreacion!}
                                listBreadcumbs={[
                                    { title: 'Inicio', url: '/compromisos' },
                                    { title: this.props.language.language?.createEngagement.nombreDocumentoCreacion!, url:this.props.location.pathname }]}/>

                    {environment.env != "dev" && (
                        <FormCreateEngagmentsClase
                            idFiadoBueno={parseInt(this.props.match.params.idFiadoBueno)}
                            isModal={false}
                        />
                    )}

                    {environment.env == "dev" && (
                        <FormCreateEngagmentsNew
                            idFiadoBueno={parseInt(this.props.match.params.idFiadoBueno)}
                            isModal={false}
                        />
                    )}


                </div>


            </div>
        </>
    }

    componentDidMount() {
       document.title = "Crear Compromiso"
    }
    componentWillMount(){
        //document.title = `${this.props.language.language?.createEngagement.titulo}`
    }

}

const mapStateToProps = (state: RootStore) => ({
    language: state.language

});

export default connect(mapStateToProps)(CreateEngagement);

interface CreateEngagementState {}
