import React, {ChangeEvent, Component} from "react";
import './Warranty.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import {
    AlphanumericInput, Badge,
    Button,
    Form,
    IconCheckmark, IconCloseCircle, IconData,
    IconSearch,
    IconTrash,
    IconUpload, Modal
} from "@lmig/lmds-react";
import {RouteComponentProps} from "react-router-dom";
import * as garantiasService from '../../../services/garantias/GarantiasService';
import {connect} from "react-redux";
import {RootStore} from "../../../Store";
import {DefaultStateI} from "../../../reducer/LanguageReducer";
import {ExpedienteModel} from "../../../models/garantias/ExpedienteModel";
import {PipeLineSteps} from "../../../components/public/pipeline-steps/PipeLineSteps";

import {
    openNewWindow,
    validRoles,MAXSIZEUPLOADFILE, validFileExpediente, nowDate
} from "../../../class/CommonClass";
import {AxiosError} from "axios";
import {ArchivoGarantia} from "../../../models/garantias/ArchivoGarantia";
import {SpinnerLoading} from "../../../components/public/spinner-loading/SpinnerLoading";
import { Spinner} from "react-bootstrap";
import {ButtonSpinner} from "../../../components/public/button-spinner/ButtonSpinner";
import {NegativeAlert} from "../../../components/public/negative-alert/NegativeAlert";
import {ResponsableGarantiaModel} from "../../../models/garantias/ResponsableGarantiaModel";
import {MessagesAPIComponent} from "@lmig/lf-sgf-library-messages/src";
import '@lmig/lf-sgf-library-messages/src/index.css'
import {ProgresoGarantiaModel} from "../../../models/garantias/ProgresoGarantiaModel";
import {UserModel} from "../../../models/public/UserModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import environment from "../../../environments/environment";
import {
    statesQuoteDoc
} from "../../../components/garantias/authorization-doc-warranty/AuthorizationDocWarranty";
import {AuthorizationDictumWarranty} from "../../../components/garantias/authorization-dictum-warranty/AuthorizationDictumWarranty";
import {
    ExpedientIdentification,
} from "../../../components/garantias/expedient-identification/ExpedientIdentification";
import {estadoVigenciaGarantia} from "../../../models/garantias/EstadoVigenciaGarantia";
import {estadoCapturaGarantia} from "../../../models/garantias/EstadoCapturaGarantia";
import {BreadCumbPrev} from "../../../components/public/breadcumb-prev/BreadCumbPrev";
import {InfoPrincipal} from "../../../components/garantias/info-principal/InfoPrincipal";
import {ModalFileMaxUpload} from "../../../components/public/modal-file-max-upload/ModalFileMaxUpload";
import CurrencyInputSDGF from "../../../components/public/currency-input-sdgf/CurrencyInputSDGF";
import {ExpedienteSelect} from "../../../components/garantias/expedient-select/ExpedientSelect";
import { ErrorSustitucion } from "../../../models/garantias/ErrorSustitucion";
import {CreateWarranty} from "../../../components/garantias/create-warranty/CreateWarranty";

interface CreateWarrantyProps {
    id: number;
    language: DefaultStateI
}

interface Params {
    idFiado: string;
    idFolio?: string
}

interface LocationState {
    ownerName: string;
}

// Static Context is available when you use Static Router*
interface SC {
    statusCode?: number;
}


class Warranty extends Component<CreateWarrantyProps & RouteComponentProps<Params, SC, LocationState>, WarrantyState> {

    public localStorageClass = new LocalStorageClass();

    public initResponsable: ResponsableGarantiaModel = {
        perfil: '',
        para: '',
        correoPara: '',
    }

    constructor(props: any) {
        super(props);

        this.state = {
            idFiado: 0,
            currentStep: 1,
            estadoCaptura: 1,
            listExpediente: [],
            loadingExpediente: false,
            idGarantia: 0,
            responsabeGarantia: this.initResponsable,
            sendingGanrantia: false,
            sendingExpediente: false,
            idGrantiaQueryParam: 0,
            PERFIL:  this.localStorageClass.getProfile(),
            progresoGarantia: initProgreso,
            disabledInputs: false,
            openModalConfirm: false,
            openModalExcedLimitFile: false,
            errorSusutitucion: {
                showModal: false,
                msgSustitucion: ''
            },
            errorAutorizacion: {
                showModal: false,
                msgSustitucion: ''
            },

        }

        this.handleFileInput = this.handleFileInput.bind(this)
        this.handleInputChangeExp = this.handleInputChangeExp.bind(this);
    }

    render() {
        return <>
            <NavBar/>
            <div className="container-fluid warranty" id="create-warranty">
            
                <div className="create-warranty">
                    <BreadCumbPrev title={this.props.language.language?.warranty.title!}/>
                    <div className="row" id="header-warranty">
                        <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-4">
                                    <ExpedientIdentification
                                        idPrincipal={parseInt(this.props.match.params.idFiado)}
                                        showButtons={true}/>
                                </div>
                                <div className="col-md-8 ">
                                    <InfoPrincipal
                                        idPrincipal={parseInt(this.props.match.params.idFiado)}
                                        label={this.props.language.language?.warranty.principal!}
                                        openObligors={true}
                                        addOS={false}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-7" >
                            <PipeLineSteps
                                listSteps={[this.props.language.language?.warranty.pipeline1!,
                                    this.props.language.language?.warranty.pipeline2!,
                                    this.props.language.language?.warranty.pipeline3!,
                                    this.props.language.language?.warranty.pipeline4!]}
                                currentStep={this.state.currentStep!-1}
                                clickSteep={show => {
                                    this.openEstadoCaptuta(show);
                                }}
                            />

                        </div>

                        <div className="col-md-12">
                            <Form id="form-liberty">
                                {this.state.currentStep == 1 ? (
                                    <CreateWarranty
                                        idFiado={this.state.idFiado}
                                        idGarantia={this.state.idGarantia}
                                        disabledInputs={this.state.disabledInputs}
                                        returnGetProgresoGarantia={idGarantia => {
                                            this.setState({idGarantia: idGarantia});
                                            this.getWarrantyProgress(idGarantia!);

                                        }}
                                        returnOpenExpediente={idGarantia => {
                                            this.openExpedient(idGarantia);
                                        }}
                                        returnPutProgresoGarantia={(idGarantia, estadoCaptura, estadoGarantia) => {

                                            this.setState({idGarantia: idGarantia});
                                            this.putWarrantyProgress(idGarantia, estadoCaptura, estadoGarantia);
                                        }}
                                    />
                                ) : null}
                                {this.state.currentStep == 2 ? (
                                    this.state.loadingExpediente ?
                                        <SpinnerLoading text={this.props.language.language?.warranty.loadingDoc!}/> :
                                        <div className="row">
                                            {this.state.listExpediente.length>0? (
                                                <div className="col-md-12 expedient-files">
                                                    {
                                                        this.state.listExpediente.map((i, index) =>
                                                            (
                                                                <div key={index} className="expedient">
                                                                    <h5><br/>{i.descripcion} </h5>

                                                                    <hr/>
                                                                    {i.obligatorio?(
                                                                        <b className="text-dark">
                                                                            {this.props.language.language?.warranty.mandatoryDoc!}
                                                                        </b>): null
                                                                    }
                                                                    <div className="row input-sdgf upload-file">

                                                                        <div className="col-md-2 col-12">
                                                                            {i.estado == statesQuoteDoc.enRevision? (
                                                                                <IconCheckmark color="success"
                                                                                               size="24"
                                                                                               title={this.props.language.language?.warranty.inReview!}
                                                                                               className="float-left"/>

                                                                            ):null}
                                                                            {i.estado == statesQuoteDoc.enCaptura? (
                                                                                <IconData color="gray"
                                                                                          size="24"
                                                                                          title={this.props.language.language?.warranty.inCapture!}
                                                                                          className="float-left"/>
                                                                            ):null}
                                                                            {i.estado == statesQuoteDoc.aprobado? (
                                                                                <>
                                                                                    <IconCheckmark color="success"
                                                                                                   size="24"
                                                                                                   title={this.props.language.language?.warranty.approved!}
                                                                                                   className="float-left"/>

                                                                                    <IconCheckmark color="success"
                                                                                                   size="24"
                                                                                                   title={this.props.language.language?.warranty.approved!}
                                                                                                   className="float-left"/>
                                                                                </>

                                                                            ):null}
                                                                            {i.estado == statesQuoteDoc.rechazado? (
                                                                                <IconCloseCircle
                                                                                    color="error"
                                                                                    size="24"
                                                                                    title={this.props.language.language?.warranty.rejected!}
                                                                                    className="float-left"/>
                                                                            ):null}
                                                                        </div>
                                                                        <div className="col-md-7 col-12 title-doc">
                                                                            {i.descripcion}
                                                                        </div>
                                                                        <div className="col-md-3 col-12 action-buttons">
                                                                            <div className="row">

                                                                                {!this.state.disabledInputs?(
                                                                                    <a className="col">
                                                                                        {!i.loading? (
                                                                                                <label className="custom-file-upload">
                                                                                                    <input
                                                                                                        type="file"
                                                                                                        id={String(index)}
                                                                                                        name={String(i.idTipoDocumento)}
                                                                                                        accept=".pdf, .xls, .xlsx, .csv"
                                                                                                        onChange={this.handleFileInput}
                                                                                                    />
                                                                                                    <IconUpload title={this.props.language.language?.warranty.uploadFiles!}
                                                                                                                color="gray" size="24" />

                                                                                                </label>
                                                                                            ):
                                                                                            <Spinner animation="border"
                                                                                                     className="spinner-uploading-file"
                                                                                                     size="sm"
                                                                                                     role="status"
                                                                                                     variant="warning">
                                                                                            </Spinner>
                                                                                        }
                                                                                    </a>
                                                                                ): null}

                                                                                {!this.state.disabledInputs && i.url ? (
                                                                                    <a className="col">
                                                                                        {!i.deleting? (
                                                                                                <IconTrash
                                                                                                    title={this.props.language.language?.warranty.deleteFile!}
                                                                                                    color="negative"
                                                                                                    size="24"
                                                                                                    onClick={() => {
                                                                                                        this.deleteFileWarranty(index)
                                                                                                    }}
                                                                                                />
                                                                                            ):
                                                                                            <Spinner animation="border"
                                                                                                     className="spinner-uploading-file"
                                                                                                     size="sm"
                                                                                                     role="status"
                                                                                                     variant="danger">
                                                                                            </Spinner>
                                                                                        }

                                                                                    </a>
                                                                                ):null}

                                                                                {i.url? (
                                                                                    <a className="col" onClick={() => {
                                                                                        openNewWindow(i.url!)
                                                                                    }}>
                                                                                        <IconSearch color="informative"
                                                                                                    size="24"
                                                                                                    title={this.props.language.language?.warranty.watchFile!}/>
                                                                                    </a>
                                                                                ):null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <NegativeAlert textAlert={i.msgExpendiente!}/>

                                                                    {
                                                                        i.detalle.map((iDetail, index2) =>
                                                                            (
                                                                                <div key={index2}>
                                                                                    {iDetail.inputType == inputTypeWarranty.Date  ? (
                                                                                        <div>
                                                                                            <label className="label-input">
                                                                                                {i.obligatorio?(<span>*</span>): null}
                                                                                                {iDetail.descripcion}
                                                                                            </label>
                                                                                            <input
                                                                                                type="date"
                                                                                                max="9999-12-31"
                                                                                                className="input-sdgf"
                                                                                                id={String(index)}
                                                                                                name={String(index2)}
                                                                                                value={iDetail.valor!}
                                                                                                onChange={this.handleInputChangeExp}
                                                                                                disabled={this.state.disabledInputs}
                                                                                            />
                                                                                        </div>
                                                                                    ): null}
                                                                                    {iDetail.inputType == inputTypeWarranty.String  ? (
                                                                                        <div>
                                                                                            <label className="label-input">
                                                                                                {i.obligatorio?(<span>*</span>): null}
                                                                                                {iDetail.descripcion}
                                                                                            </label>
                                                                                            <AlphanumericInput
                                                                                                id={String(index)}
                                                                                                name={String(index2)}
                                                                                                filter={/^.{1,500}$/}
                                                                                                labelVisual={iDetail.descripcion}
                                                                                                value={iDetail.valor!}
                                                                                                onChange={this.handleInputChangeExp}
                                                                                                disabled={this.state.disabledInputs}
                                                                                            />

                                                                                        </div>
                                                                                    ): null}
                                                                                    {iDetail.inputType == inputTypeWarranty.Double  ? (
                                                                                        <div>
                                                                                            <label className="label-input">
                                                                                                {i.obligatorio?(<span>*</span>): null}
                                                                                                {iDetail.descripcion}

                                                                                            </label>

                                                                                            <CurrencyInputSDGF
                                                                                                value={parseFloat(iDetail.valor!)}
                                                                                                name={String(index2)}
                                                                                                placeholder=""
                                                                                                disabled={this.state.disabledInputs}
                                                                                                onValueChange={
                                                                                                    (value, name) => {

                                                                                                        let auxListExpediente = this.state.listExpediente;
                                                                                                        if (value){
                                                                                                            auxListExpediente[index].detalle[index2].valor = String(value)
                                                                                                        }else {
                                                                                                            auxListExpediente[index].detalle[index2].valor = String(0)
                                                                                                        }

                                                                                                        this.setState({listExpediente: auxListExpediente})
                                                                                                    }
                                                                                                }/>

                                                                                        </div>
                                                                                    ): null}
                                                                                    {iDetail.inputType == inputTypeWarranty.LongText  ? (
                                                                                        <div>
                                                                                            <label className="label-input">
                                                                                                {i.obligatorio?(<span>*</span>): null}
                                                                                                {iDetail.descripcion}

                                                                                            </label><br/>
                                                                                            <textarea
                                                                                                id={String(index)}
                                                                                                name={String(index2)}
                                                                                                onChange={this.handleInputChangeExp}
                                                                                                disabled={this.state.disabledInputs}
                                                                                                className="input-sdgf"
                                                                                                maxLength={500}
                                                                                            >
                                                                                                {iDetail.valor!}
                                                                                            </textarea>
                                                                                        </div>
                                                                                    ): null}

                                                                                    {iDetail.inputType == inputTypeWarranty.Select? (
                                                                                        <div>
                                                                                            <label className="label-input">
                                                                                                {i.obligatorio?(<span>*</span>): null}
                                                                                                {iDetail.descripcion}
                                                                                            </label><br/>

                                                                                            <ExpedienteSelect
                                                                                                idCatalogo={iDetail.idCatalogo}
                                                                                                idSubCatalogo={iDetail.idSubCatalogo}
                                                                                                value={iDetail.valor}
                                                                                                idGarantia={this.state.idGarantia}
                                                                                                disabled={this.state.disabledInputs}
                                                                                                onValueChange={(catalogo, subcatalogo,value) => {
                                                                                                    const auxListExpediente: ExpedienteModel[] = this.state.listExpediente;
                                                                                                    auxListExpediente[index].detalle[index2].idSubCatalogo = subcatalogo
                                                                                                    if (value){
                                                                                                        auxListExpediente[index].detalle[index2].valor = value!.toString();
                                                                                                    }
                                                                                                    this.setState({listExpediente: auxListExpediente})
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    ): null}

                                                                                    <NegativeAlert textAlert={iDetail.msgExpendiente!}/>
                                                                                </div>

                                                                            ))
                                                                    }
                                                                </div>
                                                            ))
                                                    }
                                                    {
                                                        (this.state.listExpediente.length==0)?(
                                                            <div className="col-md-12 ">
                                                                {this.props.language.language?.warranty.notFoundDocs!}
                                                            </div>

                                                        ): null
                                                    }
                                                </div>
                                            ) : <div className="col-md-12 expedient-files">
                                                <NegativeAlert textAlert= {this.props.language.language?.warranty.notFoundDocs!}/>
                                            </div>
                                            }
                                            <div className="col-md-12 buttons-navigation">
                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <br/>
                                                        <Button dynamicWidth
                                                                variant="secondary"
                                                                onClick={() => this.back()}>
                                                            {this.props.language.language?.warranty.btnReturn!}
                                                        </Button>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <br/>

                                                        {!this.state.sendingExpediente ? (
                                                                <Button dynamicWidth
                                                                        variant="primary"
                                                                        onClick={() =>{
                                                                            this.setState({openModalConfirm: true});
                                                                        }
                                                                        }>
                                                                    {this.props.language.language?.warranty.btnContinue!}
                                                                </Button>
                                                            ):
                                                            <ButtonSpinner label={this.props.language.language?.warranty.btnSending!}/>
                                                        }



                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                ) : null}
                                {this.state.currentStep == 3 ? (
                                    <div className="row">
                                        <div className="col-md-12">
                                            {this.props.language.language?.warranty.labelReview1!}
                                            {this.state.idGarantia}
                                            {this.props.language.language?.warranty.labelReview2!}
                                            <br/>

                                        </div>

                                        <div className="col-md-12">

                                            <MessagesAPIComponent idAPI={1}
                                                                  disableCreate={validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR)}
                                                                  idEspecifico={this.state.idGarantia!}
                                                                  emailRemitente={this.state.PERFIL.email}
                                                                  nombreRemitente={this.state.PERFIL.username}
                                                                  emailDest={this.state.responsabeGarantia.correoPara}
                                                                  nombreDest={this.state.responsabeGarantia.para}
                                                                  TOKEN={this.state.PERFIL.accessToken}
                                                                  APIURL={environment.APIMENSAJES}
                                                                  APIKEY={environment.APIKEYMENSAJES}
                                            />

                                        </div>


                                        <div className="col-md-12 buttons-navigation">
                                            <div className="row">

                                                <div className="col-md-6">
                                                    <br/>
                                                    <Button dynamicWidth
                                                            variant="secondary"
                                                            onClick={() => {
                                                                // abrir el expediente
                                                                this.openExpedient(this.state.idGarantia!); }
                                                            }>
                                                        {this.props.language.language?.warranty.btnReturn!}
                                                    </Button>
                                                </div>
                                                <div className="col-md-6">
                                                    <br/>
                                                    <Button dynamicWidth
                                                            variant="primary"
                                                            onClick={() => this.openDictamen(this.state.idGarantia!)}>
                                                        {this.props.language.language?.warranty.btnContinue!}
                                                    </Button>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                ) : null}

                                {this.state.currentStep == 4 ? (
                                    <>
                                        <AuthorizationDictumWarranty
                                            idWarranty={this.state.idGarantia!}
                                            idFiado={this.state.idFiado}
                                            visibleButtons={false}/>

                                        <div className="row">
                                            <div className="col-md-12 buttons-navigation">
                                                <div className="row">

                                                    <div className="col-md-12">
                                                        <br/>
                                                        <Button dynamicWidth
                                                                variant="secondary"
                                                                onClick={() => this.openMessages(this.state.progresoGarantia.idGarantia)}>
                                                            {this.props.language.language?.warranty.btnReturn!}
                                                        </Button>
                                                    </div>


                                                </div>
                                            </div>


                                        </div>
                                    </>
                                ) : null}

                            </Form>

                        </div>

                    </div>


                </div>

            </div>
            <Modal
                isOpen={this.state.openModalConfirm}
                size="medium"
                modalStyle="standard"
                title={this.props.language.language?.warranty.confirmSend!}
                closeFunc={() => this.setState({openModalConfirm:false})}
            >

                <div className="modal-language">


                    {(validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS))?
                            <Badge className="" highlightType="caution">
                                {this.props.language.language?.warranty.warrantyAutorizated!}
                            </Badge>:
                        <Badge className="" highlightType="informative">
                                {this.props.language.language?.warranty.areYouSureSend!}
                        </Badge>
                    }
                    <br/>

                    <br/>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <Button
                                dynamicWidth
                                variant="secondary"
                                onClick={()=>{
                                    this.setState({openModalConfirm:false})
                                }}>
                                {this.props.language.language?.warranty.cancelButton!}
                            </Button>
                        </div>
                        <div className="col-md-6 col-12">


                            {!this.state.sendingExpediente ? (
                                    <Button
                                        dynamicWidth
                                        variant="primary"
                                        onClick={()=>{
                                            this.saveDocs();
                                        }}>
                                        {this.props.language.language?.warranty.confirmButton!}
                                    </Button>
                                ):
                                <ButtonSpinner label={this.props.language.language?.warranty.btnSending!}/>
                            }

                        </div>

                    </div>
                </div>


            </Modal>

            <Modal
                isOpen={this.state.errorSusutitucion.showModal}
                size="medium"
                modalStyle="standard"
                title={this.props.language.language?.warranty.errSustitution!}
                closeFunc={() => this.setState({errorSusutitucion:{showModal: false, msgSustitucion:''}})}>
                <h5 className="text-center">
                    {this.state.errorSusutitucion.msgSustitucion}
                </h5>
                <div className="w-100 pr-5 pl-5">
                    <Button
                        className="w-100"
                        onClick={
                            () => {
                                this.setState({errorSusutitucion: {showModal:false, msgSustitucion: '' }})
                            }
                        }
                        variant="primary">
                        {this.props.language.language?.warranty.close}
                    </Button>
                </div>
            </Modal>

            <Modal
                isOpen={this.state.errorAutorizacion.showModal}
                size="medium"
                modalStyle="standard"
                title={this.props.language.language?.warranty.errAutorizacion}
                closeFunc={() => this.setState({errorAutorizacion:{showModal: false, msgSustitucion: ''}})}>
                <h5 className="text-center">
                    {this.props.language.language?.warranty.msgErrAutorizacion}
                </h5>
                <div className="w-100 pr-5 pl-5">
                    <Button
                        className="w-100"
                        onClick={
                            () => {
                                this.setState({errorAutorizacion:{showModal: false, msgSustitucion: ''}})
                            }
                        }
                        variant="primary">
                        {this.props.language.language?.warranty.close}
                    </Button>
                </div>
            </Modal>
            <ModalFileMaxUpload
                openModal={this.state.openModalExcedLimitFile}
                maxSize={MAXSIZEUPLOADFILE}
                clickShowModal={show1 => {
                    this.setState({openModalExcedLimitFile: show1})
                }}/>
        </>
    }

    componentDidMount(){
        if (validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) ||
            validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS)
        ){
            if(this.props.match.params.idFiado){
                const auxIdFiado =  parseInt(this.props.match.params.idFiado);
                this.setState({idFiado: auxIdFiado})
                document.title = 'Crear Garantía  Fiado ' + auxIdFiado
            }

            if (this.props.match.params.idFolio){
                const auxIdWarranty = parseInt(this.props.match.params.idFolio);
                document.title = 'Editar Garantía  ' + auxIdWarranty
                this.setState({idGarantia: auxIdWarranty})
            }
        }else {
            if (validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) ||
                validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR)  ){
                this.props.history.push('/garantias');
            }else {
                this.props.history.push('/401');
            }

        }


    }

    public handleFileInput (e: ChangeEvent<HTMLInputElement>): void{
        console.log(e)
        if (validFileExpediente(e)){
            console.log(e.target.files![0])
            const indiceExpediente= parseInt(e.target.id);
            const formData = new FormData();
            formData.append('idGarantia', String(this.state.idGarantia));
            formData.append('idTipoDocumento', String([e.target.name]));
            formData.append('fileName', e.target.files![0]);
            formData.append('fechaCarga', nowDate());
            this.postFileWarranty(formData, indiceExpediente);

        }else if (e.target.files![0].size >= MAXSIZEUPLOADFILE){
            this.setState({openModalExcedLimitFile: true})
            e.target.value = '';// vaciar el input file
        }
    }

    // EXPEDIENTE
    public handleInputChangeExp (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void{
        const indiceExpediente = parseInt(e.target.id);
        const indicetalleDoc =  parseInt(e.target.name);
        const value = e.target.value;

        // console.log(indiceExpediente);
        const auxListExpediente = this.state.listExpediente;
        // const documentoDetalle = auxListExpediente[indiceExpediente].detalle[indicetalleDoc];

        auxListExpediente[indiceExpediente].detalle[indicetalleDoc].valor = value;
        // console.log(auxListExpediente);
        this.setState({listExpediente: auxListExpediente})





    }

    async getExpedient(idWarranty: number) {
        this.setState({loadingExpediente:true})
        try {
            const res = await garantiasService.getExpediente(idWarranty);
            const auxListExpediente = res.data;
            for (const expedient of auxListExpediente){
                for (const detalle of expedient.detalle){
                    if (detalle.inputType == inputTypeWarranty.Double){
                        if (!detalle.valor){
                            detalle.valor = '';
                        }
                    }
                }
            }


            this.setState({listExpediente: auxListExpediente, loadingExpediente:false})
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                this.setState({loadingExpediente: false});

            }
        }
    }

    public async postFileWarranty(formData: FormData, indiceExpediente: number): Promise<void> {
        // activar el loading del expediente
        let auxLisExpedient = this.state.listExpediente;
        auxLisExpedient[indiceExpediente].loading = true;
        this.setState({listExpediente: auxLisExpedient})

        try {
            const res = await garantiasService.postArchivoGarantia(formData);
            const response: ArchivoGarantia = res.data;
            // quitar el spiner de loading
            auxLisExpedient[indiceExpediente].loading = false;
            auxLisExpedient[indiceExpediente].url = response.url;
            auxLisExpedient[indiceExpediente].idDocumento = response.idDocumento;
            auxLisExpedient[indiceExpediente].estado = statesQuoteDoc.enCaptura;
            // buscar el id del tipo de documento ya actualizarlo
            this.setState({listExpediente: auxLisExpedient})

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                // quitar el spiner de loading
                auxLisExpedient[indiceExpediente].loading = false;
                this.setState({listExpediente: auxLisExpedient})

            }
        }
    }

    public async deleteFileWarranty(index: number) {
        const auxListExpediente = this.state.listExpediente;
        const document = auxListExpediente[index];
        // cargarle la animaciónd e borrando
        document.deleting = true;

        auxListExpediente.splice(index, 1, document);
        this.setState({listExpediente: auxListExpediente});

        try {
            const res = await garantiasService.deleteArchivoGarantia(document.idDocumento!);
            document.deleting = false;
            document.url = null;
            document.estado =statesQuoteDoc.enCaptura;
            auxListExpediente.splice(index, 1, document);
            this.setState({listExpediente: auxListExpediente});

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                document.deleting = false;
                auxListExpediente.splice(index, 0, document);
                this.setState({listExpediente: auxListExpediente});

            }
        }
    }

    async saveDocs(): Promise<void> {
        this.setState({sendingExpediente: true});
        const idWarranty = this.state.idGarantia!
        if (!this.state.disabledInputs){
            if (this.state.progresoGarantia.estadoVigencia == estadoVigenciaGarantia.enCaptura ||
                this.state.progresoGarantia.estadoVigencia == estadoVigenciaGarantia.enviada) {
                // si esta en capturo  o enviada puede modificarlo

                const auxListExpediente = this.state.listExpediente;
                // validamos los documentos obligatorios

                if (this.validRequiredDocs(auxListExpediente)){

                   let nDocsSended = 0; // expedientes Obligatorios guardados

                    // enviamos uno por uno
                    for (let i = 0; i< auxListExpediente.length; i++){
                        // si ya se guardo actualizalo
                        if (this.isSavedDoc(auxListExpediente[i])) {
                            try {
                                const res = await garantiasService.putExpediente(auxListExpediente[i]);
                                // guardar el nuevo expediente que devuelve el back con el idDetalleDoc
                                auxListExpediente[i] = res.data;

                                this.setState({listExpediente: auxListExpediente})

                                // si ya se guardaron todos los expedientes OBLIGATORIOS avanzamos next
                                if ( auxListExpediente[i].obligatorio == 1){
                                    nDocsSended++;
                                }

                                console.log('ronda: ' + i + ' length: ' + auxListExpediente.length);
                                console.log('nDocsSended '+  nDocsSended + ' docuemntos obligatorios ' + this.getListDocsRequired(auxListExpediente).length)

                                if (i == auxListExpediente.length-1 && // si ya estamos en la última ronda
                                    nDocsSended == this.getListDocsRequired(auxListExpediente).length // ya tenemos los documentos obligatorios
                                ){

                                    this.setState({sendingExpediente: false});
                                    this.openMessages(idWarranty);
                                    // desabilitar la edición
                                    this.setState({disabledInputs: true});
                                    // actualizar el progreso
                                    // si es ejecutivo de garantías se aprueba en automático
                                    if (validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) ){
                                        this.putWarrantyProgress(idWarranty,estadoCapturaGarantia.dictamen, estadoVigenciaGarantia.autorizada);

                                    }else{
                                        this.putWarrantyProgress(idWarranty,estadoCapturaGarantia.revision, estadoVigenciaGarantia.enviada);
                                    }
                                }

                            }
                            catch (error) {
                                const err = error as AxiosError
                                if (err.response) {
                                    console.log(err.response.status)
                                    console.log(err.response.data)
                                    this.setState({sendingExpediente: false});
                                }
                            }
                        }
                        // si no entonces lo guardas
                        else{

                            try {
                                const res = await garantiasService.postExpediente(auxListExpediente[i]);
                                // guardar el nuevo expediente que devuelve el back con el idDetalleDoc
                                const response: ExpedienteModel = res.data;
                                auxListExpediente[i] = response;
                                this.setState({listExpediente: auxListExpediente})
                                // si ya se guardaron todos los expedientes avanzamos next
                                if (auxListExpediente[i].obligatorio == 1){
                                    nDocsSended++;
                                }
                                console.log('ronda: ' + i + ' length: ' + auxListExpediente.length);
                                console.log('nDocsSended '+  nDocsSended + ' docuemntos obligatorios ' + this.getListDocsRequired(auxListExpediente).length)

                                if (i == auxListExpediente.length-1 && // si ya estamos en la última ronda
                                    nDocsSended == this.getListDocsRequired(auxListExpediente).length) // ya tenemos los documentos obligatorios
                                {

                                    this.setState({sendingExpediente: false});
                                    this.openMessages(idWarranty);
                                    // desabilitar la edición
                                    this.setState({disabledInputs: true});
                                    // actualizar el progreso
                                    // si es ejecutivo de garantías se aprueba en automático
                                    if (validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) ){
                                        this.putWarrantyProgress(idWarranty,estadoCapturaGarantia.dictamen, estadoVigenciaGarantia.autorizada);

                                    }else{
                                        this.putWarrantyProgress(idWarranty,estadoCapturaGarantia.revision, estadoVigenciaGarantia.enviada);
                                    }
                                }

                            }
                            catch (error) {
                                const err = error as AxiosError
                                if (err.response) {
                                    this.setState({sendingExpediente: false});
                                    console.log(err.response.status)
                                    console.log(err.response.data)

                                }
                            }

                        }
                    }

                }
                else {
                    this.setState({openModalConfirm: false, sendingExpediente: false});
                }
            }else{
                this.setState({sendingExpediente: false});
                this.openMessages(idWarranty);
            }
        }else{
            this.setState({sendingExpediente: false});
            this.openMessages(idWarranty);
        }

    }

    public saveMontoPonderadoDocs(){
        if (validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) ){
            this.putWarrantyProgress(this.state.idGarantia!,estadoCapturaGarantia.dictamen, estadoVigenciaGarantia.autorizada);

        }else{
            this.putWarrantyProgress(this.state.idGarantia!,estadoCapturaGarantia.revision, estadoVigenciaGarantia.enviada);
            // desabilidar la edición de los documentos

        }
    }

    public validRequiredDocs(auxListExpediente: ExpedienteModel[]): boolean {
        console.log(auxListExpediente)
        // obtener una sublista de los documentos obligatorios
        const subListOblibatorios = this.getListDocsRequired(auxListExpediente);
        // validar documentos obligatorios
        for (let i = 0; i<auxListExpediente.length; i++){
            console.log(auxListExpediente[i]);
            if (auxListExpediente[i].obligatorio == 1){
                console.log(this.isValidDocumentObligatory(auxListExpediente[i], i));
                if (!this.isValidDocumentObligatory(auxListExpediente[i], i)){
                    return false;
                    break;
                }
            }

        }



        return  true;
    }

    private getListDocsRequired(auxListExpediente: ExpedienteModel[]): ExpedienteModel []{
        let subListOblibatorios: ExpedienteModel [] = [];
        for (const doc of auxListExpediente){
            if (doc.obligatorio){
                subListOblibatorios.push(doc);
            }
        }
        return subListOblibatorios;
    }

    private getListDocsOptionals(auxListExpediente: ExpedienteModel[]): ExpedienteModel []{
        let subListOptionals: ExpedienteModel [] = [];
        for (const doc of auxListExpediente){
            if (doc.obligatorio == 0){
                subListOptionals.push(doc);
            }
        }
        return subListOptionals;
    }

    public isValidDocumentObligatory(doc: ExpedienteModel, index: number): boolean{
        // inicializar los mensajes vacíos
        let auxListExpedientes = this.state.listExpediente;

        doc.msgExpendiente= ''
        doc.detalle.map((i,index) =>{
            i.msgExpendiente = '';
        })

        auxListExpedientes[index] = doc;

        this.setState({listExpediente: auxListExpedientes})
        let validDoc = true;
        if (doc.obligatorio){
            if (!doc.idDocumento || !doc.url ){
                doc.msgExpendiente= this.props.language.language?.warranty.pleaseUpload + ' ' + doc.descripcion
                validDoc = false;
            }
            doc.detalle.map((i,index) =>{
                if (!i.valor){
                    validDoc = false;
                    i.msgExpendiente = this.props.language.language?.warranty.enterInfoPlease + ' '  + i.descripcion
                }
            })
        }
        auxListExpedientes[index] = doc;
        this.setState({listExpediente: auxListExpedientes})
        return validDoc;
    }

    public isValidDocumentOptional(doc: ExpedienteModel, index: number): boolean{
        // inicializar los mensajes vacíos
        let auxListExpedientes = this.state.listExpediente;

        doc.msgExpendiente= ''
        doc.detalle.map((i,index) =>{
            i.msgExpendiente = '';
        })

        auxListExpedientes[index] = doc;

        this.setState({listExpediente: auxListExpedientes})

        if (doc.idDocumento && doc.url){
            doc.detalle.map((i,index) =>{
                if (!i.valor){
                    return false;
                    i.msgExpendiente = this.props.language.language?.warranty.enterInfoPlease + ' '  + i.descripcion
                }
            })
        }


        auxListExpedientes[index] = doc;
        this.setState({listExpediente: auxListExpedientes})
        return true;
    }
    public isSavedDoc(doc: ExpedienteModel): boolean{
        // si no tiene expedientes no se a guardado

        if (doc.url?.length==0 || doc.url == null){
            return false;
        }
        if (doc.detalle.length> 0){
            console.log('validar expediente'+ doc.descripcion)
            for (const i of doc.detalle){
                if (i.idDetalleDoc == null){
                  //  console.log(i.idDetalleDoc)
                    return false
                    break;

                }
            }
        }
        return true;
    }
    // correos garantia
    private async getWarrantyManager(idGarantia: number) {

        try {
            const res = await garantiasService.getResponsableGarantia(idGarantia);
            // guardar el nuevo expediente que devuelve el back con el idDetalleDoc
            const responsabeGarantia: ResponsableGarantiaModel = res.data;
            this.setState({responsabeGarantia: responsabeGarantia})


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }




    private async getWarrantyProgress(idWarranty: number) {

        try {
            const res = await garantiasService.getGarantiaProgreso(idWarranty);
            const progress: ProgresoGarantiaModel = res.data;
            this.setState({progresoGarantia: progress})
            this.setState({estadoCaptura: progress.estadoCaptura})
            switch (progress.estadoCaptura) {
                case 1:
                    //no es necesario porque ya cargo el detalle de la garatía
                    break;
                case 2:
                    this.openExpedient(idWarranty);
                    break
                case 3:
                    this.openMessages(idWarranty);
                    if (progress.estadoVigencia == estadoVigenciaGarantia.enRevision
                    ){ // si ya ha sido enviada
                        this.setState({disabledInputs: true})
                    }else  if (progress.estadoVigencia == estadoVigenciaGarantia.autorizada ||
                        progress.estadoVigencia == estadoVigenciaGarantia.rechazada ||
                        progress.estadoVigencia == estadoVigenciaGarantia.vencida
                    ){
                        this.setState({disabledInputs: false})
                        this.openDictamen(idWarranty);
                    }


                    break
                case 4:
                    this.openDictamen(idWarranty);
                    break
                default:

                    break;
            }


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    private async putWarrantyProgress(idGarantia: number, estadoCaptura: number, estadoGarantia?: number) {

        this.setState({errorSusutitucion: {showModal: false, msgSustitucion:''}})

        try {
            const res = await garantiasService.putGarantiaProgreso(idGarantia, estadoCaptura, estadoGarantia!);
            const progress: ProgresoGarantiaModel = res.data;

            if (res.status == 200){
                this.setState({estadoCaptura:progress.estadoCaptura,
                    currentStep:progress.estadoCaptura ,
                    progresoGarantia: progress})
                if (progress.estadoVigencia == estadoVigenciaGarantia.enCaptura ||
                    progress.estadoVigencia == estadoVigenciaGarantia.enviada){
                    this.setState({disabledInputs: false}) // habilitar si aùn no ha pasado a revision
                }
            }

            if (res.status == 202){
                this.setState({
                    errorSusutitucion: {showModal: true, msgSustitucion: progress.descSustitucion}
                });

            }

            if (res.status == 401){
                this.setState({errorAutorizacion: {showModal: true, msgSustitucion: this.props.language.language?.warranty.errAutorizacion!}})
            }


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }


    }

    public openExpedient(idGarantia: number) {
        this.getExpedient(idGarantia);
        this.setState({currentStep: 2})
        window.scrollTo(0, 0);

    }

    public openMessages(idGarantia: number) {
        this.getWarrantyManager(idGarantia);
        this.setState({currentStep: 3, openModalConfirm: false})
        window.scrollTo(0, 0);
    }

    private openDictamen(number: number) {
        this.setState({currentStep: 4})
        window.scrollTo(0, 0);
    }

    public back(): void {
        this.setState({currentStep: this.state.currentStep! - 1})
        window.scrollTo(0, 0);
    }

    public openEstadoCaptuta(show: number){
        show = show+1;
        console.log('estado de captura: '+ this.state.estadoCaptura + ' == quiero ver el ' + (show)  )

        if (show <= this.state.estadoCaptura ){
            this.setState({currentStep: show});
            const idGarantiaAux: number = this.state.idGarantia;
            switch (show){
                case 1:
                    // abrir garantía
                    break;
                case 2:
                    if (idGarantiaAux){
                        this.openExpedient(idGarantiaAux);
                    }
                    break;
                case 3:
                    if (idGarantiaAux){
                        this.openMessages(idGarantiaAux);
                    }
                    break;
                case 4:
                    if (idGarantiaAux){
                        this.openDictamen(idGarantiaAux);
                    }
                    break;
            }
        }
    }
}
const mapStateToProps = (state: RootStore)  => ({
    language: state.language
});

export default connect(mapStateToProps) (Warranty);

interface WarrantyState {
    idFiado: number;
    currentStep: number
    estadoCaptura: number;
    listExpediente: ExpedienteModel[];
    loadingExpediente: boolean;
    sendingExpediente: boolean;
    sendingGanrantia: boolean;
    idGarantia: number;
    responsabeGarantia: ResponsableGarantiaModel,
    idGrantiaQueryParam: number;
    PERFIL: UserModel;
    progresoGarantia: ProgresoGarantiaModel,
    disabledInputs: boolean;
    openModalConfirm: boolean;
    openModalExcedLimitFile: boolean;
    errorSusutitucion: ErrorSustitucion;
    errorAutorizacion: ErrorSustitucion;
}

export enum inputTypeWarranty {
    String = 'String',
    Date = 'Date',
    Double = 'Double',
    LongText = 'Long String',
    Select = 'Select'
}
export const initProgreso: ProgresoGarantiaModel = {
    idGarantia: 0,
    estadoVigencia: estadoVigenciaGarantia.enCaptura,
    descEstadoVigencia: '',
    estadoCaptura: 0,
    descEstadoCaptura: '',
    estadoSustitucion: 0,
    descSustitucion: ''
}