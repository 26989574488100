import React, {Component, ReactChild} from "react";
import { RouteComponentProps } from "react-router-dom";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import "./ReporteExpediente.scss";
import NavBar from "../../../components/public/nav-bar/NavBar";
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { BreadCumb } from "../../../components/public/breadcumb/BreadCumb";
import { Alert, Button, DatePicker } from "@lmig/lmds-react";
import { ReporteExpedienteModel } from "../../../models/garantias/ReporteExpedienteModel";
import * as apiService from "../../../services/api-gral/ApiGralService";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import { AxiosError } from "axios";
import { ItemModel } from "../../../models/public/ItemModel";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { Spinner } from "react-bootstrap";
import {maxDate, minToday, sumDate, validRoles} from "../../../class/CommonClass";
import { NegativeAlert } from "../../../components/public/negative-alert/NegativeAlert";
import { ReporteExpedienteEjecutivos } from "../../../models/garantias/ReporteExpedienteEjecutivos";
import { ReporteEjecutivos } from "../../../models/garantias/ReporteEjecutivos";
import {MultiSelectOption} from "../../../models/public/MultiSelectOption";
import {UserModel} from "../../../models/public/UserModel";


interface ReporteExpedienteProps {
    language: DefaultStateI;
}

class ReporteExpediente extends Component<
    ReporteExpedienteProps & RouteComponentProps,
    ReporteExpedienteState
> {
    public localStorageClass = new LocalStorageClass();

    public initReporte: ReporteExpedienteModel = {
        fechaInicio: new Date().getFullYear().toString() + "-01-02",
        fechaFin: new Date().toISOString().slice(0, 10),
        ejecutivos: [], // 1
    };

    public initMessages: msgExpediente = {
        msgFechaI: "",
        msgFechaF: "",
        msgEjecutivos: "",
        msgData: "",
    };

    constructor(props: any) {
        super(props);
        document.title = "Reporte Expediente";
        const PERFIL = this.localStorageClass.getProfile();
        this.state = {
            PERFIL: PERFIL,
            reporteExpediente: this.initReporte,
            idEjecutivoCuenta: 0,
            listEjecutivos: [],
            sendingExpediente: false,
            errorSendExpediente: false,
            ejecutivosSelected: [],
            listEjecutivosSelected: [],
            listReporteEjecutivos: [],
            mesagges: this.initMessages,
            showDownload: false,
        };
        this.handleOnSearchEjecutivos = this.handleOnSearchEjecutivos.bind(this);
        this.handleOnSelectEjecutivos = this.handleOnSelectEjecutivos.bind(this);
        this.handleOnChangeMultiSelect = this.handleOnChangeMultiSelect.bind(this);
        this.postGenerateReporte = this.postGenerateReporte.bind(this)
    }

    render() {
        return (
            <>
                <NavBar />

                <div className="container-fluid" id="reporte-expediente">
                    <div className="waranty-container">
                        <BreadCumb
                            title={this.props.language.language?.reporteExpediente.title!}
                        />

                        <div className="row">
                            <div className="col-md-3 pt-3">
                                <input
                                    type="date"
                                    className="input-sdgf"
                                    value={this.state.reporteExpediente.fechaInicio}
                                    onChange={(event) => {
                                        this.setState((prevState) => ({
                                            reporteExpediente: {
                                                ...prevState.reporteExpediente,
                                                fechaInicio: event.target.value,
                                            },
                                            showDownload: false,
                                        }));
                                        this.initMessages.msgData = "";
                                    }}
                                    onMouseLeave={(e: React.MouseEvent<any>) =>
                                        this.validFechaInicial()
                                    }
                                    onSelect={(event) => this.validFechaInicial()}
                                    onBlur={(event: React.FocusEvent<any>) =>
                                        this.validFechaInicial()
                                    }
                                />

                                <NegativeAlert textAlert={this.state.mesagges.msgFechaI} />
                            </div>

                            <div className="col-md-3 pt-3">
                                <input
                                    type="date"
                                    className="input-sdgf"
                                    min={this.minDateFinish()}
                                    max={minToday()}
                                    value={this.state.reporteExpediente.fechaFin}
                                    onChange={(event) => {
                                        if(event.target.value<=minToday())
                                        {this.setState((prevState) => ({
                                            reporteExpediente: {
                                                ...prevState.reporteExpediente,
                                                fechaFin: event.target.value,
                                            },
                                            showDownload: false,
                                        }));
                                        this.initMessages.msgData = "";}
                                    }}
                                    onMouseLeave={(e: React.MouseEvent<any>) =>
                                        this.validFechaFinal()
                                    }
                                    onSelect={(event) => this.validFechaFinal()}
                                    onBlur={(event: React.FocusEvent<any>) =>
                                        this.validFechaFinal()
                                    }
                                />

                                <NegativeAlert textAlert={this.state.mesagges.msgFechaF} />
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-12 pt-4">
                                <MultiSelect
                                    className="w-100 "
                                    placeholder={this.props.language.language?.reporteExpediente.selectExecutive}
                                    onChange={this.handleOnChangeMultiSelect}
                                    options={this.state.listEjecutivos}
                                />

                                <NegativeAlert textAlert={this.state.mesagges.msgEjecutivos} />
                            </div>
                            <div className="col-md-3 pt-4">
                                {this.state.sendingExpediente ? (
                                    <Button
                                        variant="secondary"
                                        size="medium"
                                        disabled={this.state.sendingExpediente}
                                        className=" w-100"
                                    >
                                        <Spinner
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            variant="secondary"
                                        ></Spinner>
                                        &nbsp;
                                        {
                                            this.props.language.language?.reporteExpediente
                                                .generatingReport
                                        }
                                    </Button>
                                ) : (
                                    <Button
                                        variant="primary"
                                        size="medium"
                                        disabled={this.state.sendingExpediente}
                                        className=" w-100"
                                        onClick={() => {
                                            this.postGenerateReporte();
                                        }}
                                    >
                                        {
                                            this.props.language.language?.reporteExpediente
                                                .generateReport
                                        }
                                    </Button>
                                )}
                            </div>
                            <div className="col-md-9 pt-4">
                                {this.initMessages.msgData && (
                                    <Alert highlightType="informative">
                                        {this.initMessages.msgData}
                                    </Alert>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    componentDidMount() {
        if (validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) ||
            (validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR) && !validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE)) &&
            (validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR) && !validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR))
        ){
            this.getEjecutivosCuenta("");
        }else {

            this.props.history.push('/401');
        }

    }

    componentWillUnmount() {}

    public handleOnChangeMultiSelect(values: any) {
        console.log(values);
        console.log(values.length);
        let arr: number[] = [];
        if (values.length > 0){
           arr = values.split(",");
        }
        console.log(arr);
        arr = arr.map((str) => Number(str));
        this.setState((prevState) => ({
            reporteExpediente: {
                ...prevState.reporteExpediente,
                ejecutivos: arr,
            },
            showDownload: false,
        }));
        this.initMessages.msgData = "";
    }

    public async postGenerateReporte() {
        let reporteExpedienteAux: ReporteExpedienteModel = this.state.reporteExpediente;

        if (this.isValidReporte(reporteExpedienteAux)) {
            let ejecutivosAux: ReporteEjecutivos[] = [];
            let ejecutivosSelect: number[] = this.state.reporteExpediente.ejecutivos;
            const listEjecutivosAux: MultiSelectOption[] = this.state.listEjecutivos;

            if (ejecutivosSelect.length > 0) {
                for (const i of ejecutivosSelect) {
                    const found = listEjecutivosAux.find((ejecutivo) => parseInt(ejecutivo.value) === i);
                    console.log(found)
                    if (found){
                        ejecutivosAux.push({
                            idEjecutivo: parseInt(found!.value),
                            nombre: found!.label,
                        });
                    }

                }
            } else if (ejecutivosSelect.length == 0){
                // cuando no tenga ningun ejecutivo seleccionado manda todos
                for (const i of listEjecutivosAux) {
                    reporteExpedienteAux.ejecutivos.push(parseInt(i!.value));
                    ejecutivosAux.push({
                        idEjecutivo: parseInt(i.value),
                        nombre: i.label,
                    });
                }
            }
            this.setState({ listEjecutivosSelected: ejecutivosAux });

            this.setState({ sendingExpediente: true, errorSendExpediente: false });
            try {
                const res = await garantiasService.postReporteExpediente(
                    reporteExpedienteAux
                );

                if (res.status === 200) {
                    let a = window.document.createElement("a");
                    a.href = window.URL.createObjectURL(
                        new Blob([res.data], { type: "application/octet-stream" })
                    );
                    a.download = `ReporteGarantias de ${this.state.reporteExpediente.fechaInicio} al ${this.state.reporteExpediente.fechaFin}.xlsx`; ///Agregar fechas
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else if (res.status === 204) {
                    this.initMessages.msgData = "Lo sentimos no pudimos encontrar información con los criterios de búsqueda seleccionados, Intente nuevamente con nuevos criterios";
                }
                this.setState({ sendingExpediente: false });
            } catch (error) {
                const err = error as AxiosError;
                if (err.response) {
                    console.log(err.response.status);
                    console.log(err.response.data);
                    this.setState({
                        sendingExpediente: false,
                        errorSendExpediente: true,
                    });
                }
            }
        }
    }

    public isValidReporte(reporte: ReporteExpedienteModel): boolean {
        if (!this.validFechaInicial()) {
            // setGarantiaMsg({...garantiaMsgs, msgTipo: languageState?.valSelectTypeUse!});
            return false;
        }
        if (!this.validFechaFinal()) {
            // setGarantiaMsg({...garantiaMsgs, msgTipo: languageState?.valSelectTypeUse!});
            return false;
        }
        return true;
    }

    public async getEjecutivosCuenta(busqueda: string) {
        //console.log('get ejecutivos ');
        try {
            const res = await apiService.getEjecutivosCuenta(null, busqueda, null);
            // vaciar a lista de gentes en item para que se muestre el select
            let auxItems: MultiSelectOption[] = [];
            for (const ejecutivo of res.data) {
                auxItems.push({
                    value: ejecutivo.idEjecutivo.toString(),
                    label:
                        ejecutivo.nombre +
                        " " +
                        ejecutivo.apPaterno +
                        " " +
                        ejecutivo.apMaterno,
                });
            }
            this.setState({ listEjecutivos: auxItems });
        } catch (error) {
            const err = error as AxiosError;
            if (err.response) {
                console.log(err.response.status);
                console.log(err.response.data);
            }
        }
    }

    public handleOnSearchEjecutivos(search: string) {
        if (search.length >= 2) {
            const found = this.state.listEjecutivos.find(
                (item) => item.label === search
            );
            if (!found) {
                this.setState({ idEjecutivoCuenta: 0 });
                // toca recargar y traer los datos del puro ejecutivo
            }
            //  this.getEjecutivosCuenta(search);
        }
        console.log(search);
        //this.getEjecutivosCuenta(search);
    }

    public handleOnSelectEjecutivos(itemSelected: ItemModel) {
        const auxSelected = [...this.state.ejecutivosSelected];

        auxSelected.push(itemSelected.id!);

        this.setState((prevState) => ({
            reporteExpediente: {
                ...prevState.reporteExpediente,
                ejecutivos: auxSelected,
            },
        }));
    }

    public validFechaInicial() {
        let initialDate = new Date(this.state.reporteExpediente.fechaInicio!);

        const fechaFinal = this.state.reporteExpediente.fechaFin!;
        //  console.log('fecha inicial: ' + fechaInicial.length);
        if (this.state.reporteExpediente.fechaInicio!.length === 10) {
            // si esta llena la fecha inicial
            if (initialDate > maxDate) {
                this.setState((prevState) => ({
                    mesagges: {
                        ...prevState.mesagges,
                        msgFechaI:
                            this.props.language.language?.reporteExpediente.noValidDateInit!,
                    },
                }));
                return false;
            } else if (fechaFinal) {
                // vamos a comparar si la fecha final no sea menor a la fecha inicial
                let finalDate = new Date(fechaFinal);
                if (finalDate < initialDate) {
                    this.setState((prevState) => ({
                        mesagges: {
                            ...prevState.mesagges,
                            msgFechaI:
                                this.props.language.language?.reporteExpediente.maxDateInit!,
                        },
                    }));
                    return false;
                } else {
                    this.setState((prevState) => ({
                        mesagges: { ...prevState.mesagges, msgFechaI: "" },
                    }));
                    return true;
                }
            } else {
                this.setState((prevState) => ({
                    mesagges: { ...prevState.mesagges, msgFechaI: "" },
                }));
                return true;
            }
        } else {
            this.setState((prevState) => ({
                mesagges: {
                    ...prevState.mesagges,
                    msgFechaI:
                        this.props.language.language?.reporteExpediente.noValidDateInit!,
                },
            }));
            return false;
        }
    }

    public minDateFinish(): string {
        let minDate: string = "";
        const fechaInicial = this.state.reporteExpediente.fechaInicio;

        if (fechaInicial) {
            minDate = fechaInicial;
        } else {
            minDate = minToday();
        }

        return minDate;
    }

    public validFechaFinal(): boolean {
        const fechaInicial = this.state.reporteExpediente.fechaInicio;
        const fechaFinal = this.state.reporteExpediente.fechaFin;

        let endDate = new Date(fechaFinal);
        const startDate = new Date(this.minDateFinish());

        if (fechaFinal.length === 10) {
            // si esta llena la fecha final

            // si la fecha final es menor a la inicial
            if (fechaInicial && endDate < startDate) {
                // le escribimos un mensaje
                this.setState((prevState) => ({
                    mesagges: {
                        ...prevState.mesagges,
                        msgFechaF:
                            this.props.language.language?.reporteExpediente.minDateEnd! ,
                    },
                }));
                return false;
            } else if (endDate > maxDate) {
                this.setState((prevState) => ({
                    mesagges: {
                        ...prevState.mesagges,
                        msgFechaF:
                            this.props.language.language?.reporteExpediente.noValidDateEnd!,
                    },
                }));
                return false;
            } else {
                this.setState((prevState) => ({
                    mesagges: { ...prevState.mesagges, msgFechaF: "" },
                }));
                return true;
            }
        } else {
            this.setState((prevState) => ({
                mesagges: {
                    ...prevState.mesagges,
                    msgFechaF:
                        this.props.language.language?.reporteExpediente.noValidDateEnd!,
                },
            }));
            return false;
        }
    }
}

const mapStateToProps = (state: RootStore) => ({
    language: state.language,
});

export default connect(mapStateToProps)(ReporteExpediente);

interface ReporteExpedienteState {
    PERFIL: UserModel;
    reporteExpediente: ReporteExpedienteModel;
    idEjecutivoCuenta: number;
    listEjecutivos: MultiSelectOption[];
    sendingExpediente: boolean;
    errorSendExpediente: boolean;
    ejecutivosSelected: number[];
    listEjecutivosSelected: ReporteEjecutivos[];
    listReporteEjecutivos: ReporteExpedienteEjecutivos[];
    mesagges: msgExpediente;
    showDownload: boolean;
}

interface msgExpediente {
    msgFechaI: string;
    msgFechaF: string;
    msgEjecutivos: string;
    msgData: string;
}

