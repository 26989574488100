import React, {ChangeEvent, useEffect, useState} from 'react';
import './FormCreateEngagmentsNew.scss';
import InfoEngagement from "../info-engagement/InfoEngagement";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {
    AlphanumericInput,
    Button, Checkbox,
    CheckboxGroup,
    Form, GridCol, GridRow,
    List,
    ListItem, Modal,
    Select,
    SelectOption,
    Toggle
} from "@lmig/lmds-react";
import {PrevPage} from "../../public/prev-page/PrevPage";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import SelectCondition from "../../public/select-type/SelectCondition";
import SelectBlocks from "../../public/select-type/SelectBlocks";
import AutoCompleteProduct from "../../public/select-type/AutoCompleteProduct";
import CurrencyInputSDGF from "../../public/currency-input-sdgf/CurrencyInputSDGF";
import {formatValue, InputChange, validateEmail, validRoles} from "../../../class/CommonClass";
import Info from "@lmig/lmds-react/icons/Info";
import SuccessAlert from "@lmig/lmds-react/icons/SuccessAlert";
import {MessagesAPIComponent} from "@lmig/lf-sgf-library-messages/src";
import environment from "../../../environments/environment";
import FindFuncionario from "../find-client/FindFuncionario";
import FindComite from "../find-client/FindComite";
import FindSubComite from "../find-client/FindSubComite";
import FindComiteBuro from "../find-client/FindComiteBuro";
import {ElementosCompromiso} from "../../../models/compromisos/ElementosCompromiso";
import {NumeroCompromisos} from "../../../models/compromisos/NumeroCompromisos";
import {UserModel} from "../../../models/public/UserModel";
import {Compromiso} from "../../../models/compromisos/Compromiso";
import {CompromisoPOST} from "../../../models/compromisos/CompromisoPOST";
import {TipoCompromiso} from "../../../models/compromisos/TipoCompromiso";
import {CodigoRespuesta} from "../../../models/compromisos/CodigoRespuesta";
import {AutorizaCompromiso} from "../../../models/compromisos/AutorizaCompromiso";
import {Destinatario} from "../../../models/compromisos/Destinatario";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import * as engagementService from "../../../services/compromisos/EngagementsService";
import {Bloqueos} from "../../../models/compromisos/Bloqueos";
import {Condiciones} from "../../../models/compromisos/Condiciones";
import {AxiosError} from "axios";
import {PipeLineSteps} from "../../public/pipeline-steps/PipeLineSteps";
import {Messagescompromisos} from "../../../models/compromisos/Messagescompromisos";
import {tiposCompromisoEnum} from "../../../models/compromisos/TiposCompromisoEnum";
import {CompromisoFiadoEnum} from "../../../models/compromisos/CompromisoFiadoEnum";
import {StatusCompromisoEnum} from "../../../models/compromisos/StatusCompromisoEnum";
import {postCompromisov2} from "../../../services/compromisos/EngagementsService";
import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";



interface FormCreateEngagmentsProps {
    idFiadoBueno: number;
    isModal: boolean; // true
    clickShowModal?: (show: boolean) => void;
}

export const FormCreateEngagmentsNew: React.FC<FormCreateEngagmentsProps> = (
    {
        idFiadoBueno,
        isModal,
        clickShowModal,
    }:FormCreateEngagmentsProps) => {

    const languageState = useSelector((state: RootStore) => state.language.language?.formCreateEngagmentsLanguage);

    const localStorageClass = new LocalStorageClass();

    const initialMsgsEngagments: Messagescompromisos = {
        msgTipo: '',
        msgCondicion: '',
        msgBloqueo: '',
        msgProducto: '',
        msgMonto: '',
        msgCorreo: '',
        msgFecha: '',
        msgMotivo: ''
    }

    const initialEngagement: Compromiso = {
        bloqueos: [],
        conFiado: CompromisoFiadoEnum.sinFiado,
        condiciones: [],
        datosFiado: {
            correo: '',
            fechaAceptaCompr: '',
            idCompromiso: 0,
            nombreRep: ''
        },
        dictamen: '',
        fechaComprometida: '',
        fechaCompromisoCumplido: '',
        fechaSolicitud: '',
        idCompromiso: 0,
        idFiado: 0,
        idSolicitante: '',
        monto: 0,
        motivo: '',
        numRenovaciones: 0,
        producto: '',
        statusCompromiso: 0,
        tipoBloqueo: 0,
        tipoCompromiso: 0,
        tipoCumplimiento: 0,
        tipoSolicitante: 0

    }

    const initialTieneFacultades: CodigoRespuesta = {
            codigo: 0,
            descripcion: ''
        }

    const [PERFIL] = useState<UserModel>(localStorageClass.getProfile());

    const [count,setCount] = useState<number>(0);

    const [listTipos, setListTipos] = useState<TipoCompromiso[]>([]);

    const [mensajesAlerta, setMensajesAlerta] = useState<Messagescompromisos>(initialMsgsEngagments);

    const [engagementObject,setEngagementObject] = useState<Compromiso>(initialEngagement);

    const [sendingEngagment,setSendingEngagment] = useState<boolean>(false);

    const [aceptaTeminos,setAceptaTeminos] = useState<boolean>(false);

    const [tieneFacultades,setTieneFacultades] = useState<CodigoRespuesta>(initialTieneFacultades);



    useEffect(() => {
        getTipoCompromisos();
        setEngagementObject({...engagementObject, idFiado: idFiadoBueno});

    },[idFiadoBueno,isModal]);

    const getTipoCompromisos = async () => {
        const res = await engagementService.getTipo();
        setListTipos(res.data);
    }
    const selectedBlocks = (listBlocks: Bloqueos[]) => {

    }
    const selectedConditions = (listConditions: Condiciones[]) => {
        setMensajesAlerta({...mensajesAlerta, msgCondicion: ''})


        let foudBuro = false;

        for (const elemento of listConditions) {

            console.log("Lista de Condiciones Recibidas: " + elemento.descripcion)

            if (elemento.descripcion.includes("Garantía")) {
            }
            if (elemento.descripcion.includes("Buró")) {
                foudBuro = false;
                break;
            }
        }

        let tipoBloqueo = 1;
        if (foudBuro){
            tipoBloqueo = 2;
        }

        setEngagementObject({...engagementObject, condiciones: listConditions, tipoBloqueo: tipoBloqueo});

    }

    const selectedProducto = (producto: string, conAlertaProduct: boolean) => {
        console.log("Seleccionó el producto: " + producto + " " + conAlertaProduct)
        if (producto.length>0) {
            setEngagementObject({...engagementObject, producto: producto });

        }else {
            setMensajesAlerta({...mensajesAlerta, msgProducto: ''})
        }

    }

    const postEngagement = async () => {
        if (validaFormulario(engagementObject)) {
            setSendingEngagment(true);
            try {
                const res= await engagementService.postCompromisov2(reformatSendCompromiso(engagementObject));
                setEngagementObject(reformatReadCompromiso(res.data))
                setSendingEngagment(false);
                setCount(1)
            } catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    console.log("Code Error:" + err.response.status)
                    console.log("Error:" + err.response.statusText)
                    setSendingEngagment(false);
                    setCount(0);
                }
            }

        }

    }

    const validaFormulario = (engagementObject: Compromiso) => {
        setMensajesAlerta(initialMsgsEngagments)
        if (engagementObject.tipoCompromiso == 0) {
            setMensajesAlerta({...mensajesAlerta, msgTipo: languageState?.msgTipo!})
            return false;
        }
        if (engagementObject.tipoCompromiso == tiposCompromisoEnum.porApoyo && engagementObject.condiciones.length == 0) {
            setMensajesAlerta({...mensajesAlerta, msgCondicion: languageState?.msgCondicion!})
            return false;
        }
        if (engagementObject.tipoCompromiso == tiposCompromisoEnum.debloqueo && engagementObject.bloqueos.length == 0) {
            setMensajesAlerta({...mensajesAlerta, msgBloqueo: languageState?.msgBloqueo!})
            return false;
        }
        if (engagementObject.producto == '') {
            setMensajesAlerta({...mensajesAlerta, msgProducto: languageState?.msgProducto!})
            return false;

        }
        if (engagementObject.monto == 0 || engagementObject.monto == undefined) {
            setMensajesAlerta({...mensajesAlerta, msgProducto: languageState?.msgMonto!})
            return false;
        }

        if (engagementObject.conFiado == CompromisoFiadoEnum.sinFiado && engagementObject.fechaComprometida == '') {
            setMensajesAlerta({...mensajesAlerta, msgFecha: languageState?.msgFecha!})
            return false;

        }


        if (engagementObject.conFiado == CompromisoFiadoEnum.conFiado && !validateEmail(engagementObject.datosFiado.correo)) {
            setMensajesAlerta({...mensajesAlerta, msgCorreo: languageState?.msgCorreo!})
            return false;
        }

        if (engagementObject.motivo == '') {
            setMensajesAlerta({...mensajesAlerta, msgMotivo: languageState?.msgMotivo!})
            return false;
        }

        return true;
    }
    const handleInputChangeToggle = (event?: React.ChangeEvent<HTMLInputElement>, isChecked?: boolean) => {

        if (isChecked == true) {
            setEngagementObject({...engagementObject, conFiado: CompromisoFiadoEnum.conFiado });

        } else {
            console.log("Sin Fiado")
            const datosFiadoAux = engagementObject.datosFiado;
            datosFiadoAux.correo = '';
            setEngagementObject({...engagementObject, conFiado: CompromisoFiadoEnum.sinFiado, datosFiado: datosFiadoAux });
        }

    }

    const handleInputChangeEmail = (e: InputChange) => {
        const value = e.target.value;
        const datosFiadoAux = engagementObject.datosFiado;
        datosFiadoAux.correo = value;
        setEngagementObject({...engagementObject, datosFiado: datosFiadoAux });

    }
    //     handleInputChangeDate(e: InputChange): void {
    const handleInputChangeDate = (e: InputChange) => {
        const value = e.target.value;
        let fechaComprometida = new Date(value);
        let hoy = new Date();

        setMensajesAlerta({...mensajesAlerta, msgFecha: ''})
        if (fechaComprometida.getMonth() < hoy.getMonth()) {
            setMensajesAlerta({...mensajesAlerta, msgFecha: languageState?.dateNoValid!})
        }else {
            setEngagementObject({...engagementObject, fechaComprometida: value});
        }
    }
    const handleInputChangeSelect = (e: InputChange) => {
        const name = e.target.name
        switch (name) {
            case 'tipoCompromiso':
                setEngagementObject({...engagementObject, tipoCompromiso: parseInt(e.target.value)});
                break;
        }
    }

    const handleInputChangeMotivo = (e: InputChange) => {
        const value = e.target.value;
        setEngagementObject({...engagementObject, motivo: value});

    }
    const reformatSendCompromiso = (engagement: Compromiso): Compromiso => {
      // reformatear la fecha al formato 04/09/2023
        // solo porque el backend asi viene
        if (engagement.fechaComprometida){
            // 2023-12-01 to dia/mes/anio;
            let dateParts = engagement.fechaComprometida.split("-");
            engagement.fechaComprometida = dateParts[2] +'/' + dateParts[1] + '/' + dateParts[0];
        }

        return engagement;

    }

    const reformatReadCompromiso = (engagement: Compromiso): Compromiso => {

        if (engagement.fechaSolicitud){
            let dateParts = engagement.fechaSolicitud.split("/");
            engagement.fechaSolicitud = dateParts[2] +'-' + dateParts[1] + '-' +dateParts[0]
        }
        if (engagement.fechaComprometida){
            let dateParts = engagement.fechaComprometida.split("/");
            engagement.fechaComprometida = dateParts[2] +'-' + dateParts[1] + '-' +dateParts[0]
        }
        if (engagement.fechaCompromisoCumplido){
            let dateParts = engagement.fechaCompromisoCumplido.split("/");
            engagement.fechaCompromisoCumplido = dateParts[2] +'-' + dateParts[1] + '-' +dateParts[0]
        }

        return engagement;
    }

    const getFacultades = async () => {

        // const ejecutivo = PERFIL.clave!
        //
        // const res = await engagementService.getFacultades(ejecutivo, engagementObject.producto, engagementObject.monto);
        // setTieneFacultades(res.data);

    }

    const back = () => {
      setCount((count>1)? count-1 : 1)
    }
    const minToday = () => {
        const date = new Date().getDate();
        const month = new Date().getMonth() + 1;
        const year = new Date().getFullYear();
        let today = "";

        if (month < 10 && date < 10) {
            const monthAux = "0" + month
            const dateAux = "0" + date
            today = year + "-" + monthAux + "-" + dateAux
        }

        if (month < 10 && date >= 10) {
            const monthAux = "0" + month
            today = year + "-" + monthAux + "-" + date
        }

        if (month >= 10 && date >= 10) {
            today = year + "-" + month + "-" + date
        }

        return today;
    }
    const minLast = () => {
        const date = new Date().getDate();
        const year = new Date().getFullYear();
        let last = "";
        const monthA = new Date().getMonth() + 6;
        let yearA = year;

        if (monthA < 10 && date < 10) {
            const monthAux = "0" + monthA
            const dateAux = "0" + date
            last = yearA + "-" + monthAux + "-" + dateAux
        }

        if (monthA < 10 && date >= 10) {
            const monthAux = "0" + monthA
            last = yearA + "-" + monthAux + "-" + date

        }

        if (monthA >= 10 && date >= 10) {
            last = yearA + "-" + monthA + "-" + date

        }

        return last;
    }


    const handleInputChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("Acepta Términos: " + event.target.checked)
         setAceptaTeminos(event.target.checked)
    }

    return <>
        <div id="form-create-engagments-new">
            <div className="row">
                <div className="col-md-5">
                    <InfoEngagement id={idFiadoBueno} />
                </div>

                <div className="col-md-7">

                    <PipeLineSteps
                        listSteps={
                            [`${languageState?.compromiso} `,
                                `${languageState?.aceptacion} `,
                                `${languageState?.revision} `, `
                                ${languageState?.dictamen} `]}
                        currentStep={count}
                        clickSteep={show => {
                        }}
                    />

                </div>
                <div className="col-md-2"/>

                <div className="col-md-8" id="create-engagment">

                    {count == 0 ? (
                        <div className="row">
                            <div className="col-md-12">
                                <label className="label-input">{languageState?.tipoLabel} </label>
                                <Select
                                    labelA11y={languageState?.seleccionaTipo}
                                    labelVisual={languageState?.seleccionaTipo}
                                    onChange={handleInputChangeSelect}
                                    isRequired
                                    name="tipoCompromiso"
                                    value={String(engagementObject.tipoCompromiso!)}
                                    placeholderOption={languageState?.seleccionaTipo} >
                                    <SelectOption  value="0">
                                        {languageState?.selectOption!}
                                    </SelectOption>
                                    {
                                        listTipos.map((i, index) =>
                                            (<SelectOption key={index} value={i.idTipoCompromiso.toString()}>
                                                    {i.descripcion}
                                                </SelectOption>
                                            ))
                                    }
                                </Select>
                                <NegativeAlert textAlert={mensajesAlerta.msgTipo} />

                                {(engagementObject.tipoCompromiso == tiposCompromisoEnum.porApoyo) ?
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="label-input">
                                                {languageState?.condicionesLabel}</label>
                                                <SelectCondition selectedConditions={selectedConditions} />
                                        </div>
                                        <NegativeAlert textAlert={mensajesAlerta.msgCondicion} />
                                    </div> : null}

                                {(engagementObject.tipoCompromiso == tiposCompromisoEnum.debloqueo) ?
                                    <div className="row">
                                        <div className="col-md-12">

                                            <label className="label-input">{languageState?.bloqueosLabel}</label>
                                            <SelectBlocks idFiado={idFiadoBueno} selectedBlocks={selectedBlocks} />

                                        </div>
                                        <NegativeAlert textAlert={mensajesAlerta.msgBloqueo} />
                                    </div> : null}
                            </div>

                            <div className="col-md-12">
                                <label className="label-input">{languageState?.producto}</label>
                            </div>

                            <div className="col-md-12">
                                <AutoCompleteProduct selectedProducto={selectedProducto} />

                                <NegativeAlert textAlert={mensajesAlerta.msgProducto} />
                            </div>

                            <div className="col-md-12">
                                <label className="label-input">{languageState?.monto}</label>
                                <CurrencyInputSDGF
                                    value={engagementObject.monto}
                                    placeholder="Ingresa un Monto"
                                    name="monto"
                                    msgLimit={languageState?.limitAmount!}
                                    onValueChange={(value, name) => {
                                        setEngagementObject({...engagementObject, monto: value!});
                                    }} />

                                <NegativeAlert textAlert={mensajesAlerta.msgMonto} />
                            </div>

                            <div className="col-md-12"> <br /> </div>

                            <div className="col-md-12 ">
                                <br/>
                                <Toggle
                                    name="conFiado"
                                    compact
                                    dynamicWidth
                                    labelVisual={languageState?.fiadoLabel}
                                    className="toogle"
                                    onChange={handleInputChangeToggle} />
                            </div>

                            <div className="col-md-12">
                            {(engagementObject.conFiado == CompromisoFiadoEnum.conFiado) ?
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="label-input">{languageState?.email}</label>
                                        <AlphanumericInput
                                            name="correo"
                                            labelVisual="fiado@mail.com"
                                            value={engagementObject.datosFiado.correo}
                                            onChange={handleInputChangeEmail} />
                                    </div>
                                    <NegativeAlert textAlert={mensajesAlerta.msgCorreo} />
                                </div>
                                :
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="label-input">{languageState?.fecha}</label>
                                        <input
                                            type="date"
                                            name="fechaComprometida"
                                            className="input-sdgf"
                                            id="fechaComprometida"
                                            min={minToday()}
                                            max={minLast()}
                                            onChange={handleInputChangeDate} />
                                    </div>
                                    <div className="col-md-12">
                                        <NegativeAlert textAlert={mensajesAlerta.msgFecha} />
                                    </div>
                                </div>
                            }
                            </div>
                            <div className="col-md-12">
                                <label className="label-input">{languageState?.motivo}</label>
                                <textarea required name="motivo"
                                          onChange={handleInputChangeMotivo}
                                          className="motivo-input"
                                          rows={4}
                                          cols={55} />

                                <NegativeAlert textAlert={mensajesAlerta.msgMotivo} />
                            </div>

                            <div className="col-md-12">
                                <div className="row buttons-navigation">
                                    <div className="col-md-6">
                                        <br />
                                        {(isModal) ? (
                                                <>
                                                    <Button dynamicWidth
                                                            onClick={() => {clickShowModal!(false)}}
                                                            variant="secondary">
                                                        {languageState?.cancel}
                                                    </Button>
                                                </>
                                            ) :
                                            <PrevPage />
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <br />
                                        {!sendingEngagment ? (
                                        <Button dynamicWidth
                                                variant="primary"
                                                onClick={() => postEngagement()}>
                                            {languageState?.continuar}
                                        </Button>
                                        ):
                                        <ButtonSpinner label={languageState?.saving!}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    ): null}

                    {count == 1 ? (
                        <div className="row">
                            {/* LEYENDA CUANDO EL COMPROMISO ES SIN FIADO */}
                            {engagementObject.conFiado == CompromisoFiadoEnum.sinFiado &&
                            engagementObject.statusCompromiso != StatusCompromisoEnum.porAutorizarPlazo ? (

                                    <div className="row">
                                        <div className="col-md-12">
                                            <br /><br />
                                            {languageState?.aceptoCompromiso}
                                            <br/><br/>
                                            <hr />
                                        </div>
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4 text-center " >
                                            <Checkbox
                                                onChange={handleInputChangeCheckBox}
                                                checked={aceptaTeminos}
                                                defaultChecked={false}
                                                required={true}
                                                labelVisual={languageState?.aceptaTerminos}
                                                name="grid"/>
                                            <br/> <br/>
                                        </div>
                                        <div className="col-md-4"></div>
                                        <div className="col-md-6">
                                            <Button dynamicWidth
                                                    variant="secondary"
                                                    onClick={() => back()}>
                                                {languageState?.aceptaTerminos}
                                            </Button>
                                        </div>
                                        <div className="col-md-6">
                                            <Button dynamicWidth
                                                    variant="primary"
                                                    disabled={!aceptaTeminos}
                                                    href="/compromisos">
                                                {languageState?.finalizar}
                                            </Button>
                                        </div>

                                    </div>

                            ) : null}

                            {/* LEYENDA CUANDO EL COMPROMISO ES CON FIADO */}
                            {engagementObject.conFiado == CompromisoFiadoEnum.conFiado ? (
                               <div className="row">
                                   <div className="col-md-12">
                                       <br /><br />
                                       {languageState?.sendSolicitud}
                                       {engagementObject.datosFiado.correo}
                                       <br /><br />
                                       <hr />
                                   </div>
                               </div>
                            ) : null}

                            {/* LEYENDA CUANDO EXCEDE LOS 3 MESES DE PLAZO */}
                            {engagementObject.statusCompromiso == StatusCompromisoEnum.porAutorizarPlazo ? (
                                <div className="col-md-12">
                                    <br /><br />
                                    {languageState?.exceedThreeMonths}
                                    <br /><br />
                                    <hr />
                                </div>
                            ) : null}
{/*
                            <div className="col-md-12">
                                <div className="row buttons-navigation">
                                    <div className="col-md-6">
                                        <br />
                                        <Button dynamicWidth variant="secondary" onClick={() => this.backRegresa()}> {languageState?.regresar}</Button>
                                    </div>

                                      NO FUE CON FIADO Y EL USUARIO TIENE FACULTADES
                                    {this.state.correoFiado == '' && this.state.conFacultades.codigo == 1 && this.state.reponseObject.respuesta.statusCompromiso.toString() != '9' ? (
                                        <div className="col-md-6">
                                            {this.state.aceptaTerminos == true ? (
                                                <><br /><Button dynamicWidth variant="primary" onClick={() => this.next()}> {languageState?.continuar} </Button></>
                                            ) : <><br /><Button dynamicWidth variant="primary" onClick={() => this.next()} disabled> {languageState?.continuar} </Button></>}
                                        </div>) : null}


                                      NO FUE CON FIADO Y EL USUARIO NO TIENE FACULTADES
                                    {this.state.correoFiado == '' && this.state.conFacultades.codigo == 0  && validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) ? (
                                        <div className="col-md-6">
                                            {this.state.aceptaTerminos == true || (this.state.reponseObject.respuesta.statusCompromiso.toString() == '9' && this.state.aceptaTerminos == false) ? (
                                                <><br />
                                                    {(this.props.isModal) ?
                                                        <Button dynamicWidth variant="primary"
                                                                onClick={
                                                                    () => {
                                                                        if (this.props.isModal) {
                                                                            this.props.clickShowModal!(false)
                                                                        }
                                                                    }}>
                                                            {languageState?.finalizar} </Button>
                                                        :
                                                        <Button dynamicWidth variant="primary"
                                                                onClick={() => {
                                                                    if (this.state.autorizaRO == true) {
                                                                        this.postNotificaRO()
                                                                        // this.setState({ openModalResponsableOficina: true });
                                                                    } else if (this.state.autorizaRO == false ) {
                                                                        this.setState({ openModalClose: true });
                                                                    }
                                                                }} > {languageState?.finalizar} </Button>
                                                    }
                                                </>
                                            ) : <><br /><Button dynamicWidth variant="primary" href="/compromisos" disabled> {languageState?.finalizar} </Button></>}
                                        </div>) : null}

                                    {this.state.correoFiado == '' && this.state.conFacultades.codigo == 1  && validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) && this.state.reponseObject.respuesta.statusCompromiso.toString() == '9' ? (
                                        <div className="col-md-6">
                                            {this.state.aceptaTerminos == true || (this.state.reponseObject.respuesta.statusCompromiso.toString() == '9' && this.state.aceptaTerminos == false) ? (
                                                <><br />
                                                    {(this.props.isModal) ?
                                                        <Button dynamicWidth variant="primary"
                                                                onClick={
                                                                    () => {
                                                                        if (this.props.isModal) {
                                                                            this.props.clickShowModal!(false)
                                                                        }
                                                                    }}>
                                                            {languageState?.finalizar} </Button>
                                                        :
                                                        <Button dynamicWidth variant="primary"
                                                                onClick={() => {
                                                                    if (this.state.autorizaRO == true) {
                                                                        this.postNotificaRO()
                                                                        //this.setState({ openModalResponsableOficina: true });
                                                                    } else if (this.state.autorizaRO == false) {
                                                                        this.setState({ openModalClose: true });
                                                                    }
                                                                }} > {languageState?.finalizar} </Button>
                                                    }
                                                </>
                                            ) : <><br /><Button dynamicWidth variant="primary" href="/compromisos" disabled> {languageState?.finalizar} </Button></>}
                                        </div>) : null}

                                    {this.state.correoFiado == '' && validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) || validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR) ? (
                                        <div className="col-md-6">
                                            {this.state.aceptaTerminos == true || (this.state.reponseObject.respuesta.statusCompromiso.toString() == '9' && this.state.aceptaTerminos == false) ? (
                                                <><br />
                                                    {(this.props.isModal) ?
                                                        <Button dynamicWidth variant="primary"
                                                                onClick={
                                                                    () => {
                                                                        if (this.props.isModal) {
                                                                            this.props.clickShowModal!(false)
                                                                        }
                                                                    }}>
                                                            {languageState?.finalizar} </Button>
                                                        :
                                                        <Button dynamicWidth variant="primary" href="/compromisos" > {languageState?.finalizar} </Button>
                                                    }
                                                </>
                                            ) :  <><br /><Button dynamicWidth variant="primary" href="/compromisos" disabled> {languageState?.finalizar} </Button></>}
                                        </div>) : null}

                                     FUE CON FIADO
                                    {this.state.correoFiado != '' ? (
                                        <div className="col-md-6">
                                            <br />
                                            <Button dynamicWidth variant="primary" href="/compromisos" > {languageState?.finalizar} </Button>
                                        </div>) : null}



                                </div>
                            </div>*/}
                        </div>
                    ) : null}

                </div>

                    <div className="col-md-2" />
            </div>
        </div>
    </>;
};


interface OptionModel {
    value: number;
    label: string;
}


