import React, { useEffect, useState} from 'react';
import './Comentarios.scss';
import {Accordion, Card, Spinner} from "react-bootstrap";
import { PillButton } from '@lmig/lmds-react-pill-button';
import {AxiosError} from "axios";
import { ComentarioModel } from '../../../models/garantias/ComentarioModel';
import * as apiGralService from "../../../services/api-gral/ApiGralService";
import { useSelector} from "react-redux";
import { RootStore } from '../../../Store';
import { validRoles } from "../../../class/CommonClass";
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import { UserModel } from "../../../models/public/UserModel";
import {AcordionTruncate} from "../acordion-truncate/AcordionTruncate";


interface ComentariosFiadoProps {
    idFiado:number;
    openComentario: boolean;
}


export const ComentariosFiado: React.FC<ComentariosFiadoProps> = ({idFiado ,openComentario}: ComentariosFiadoProps) => {

    const languageState = useSelector((state: RootStore) => state.language);
    const [listComentarios, setListComentarios] = useState<ComentarioModel []>([]);
    const [textComentario, setTextComentario] = useState<string>('');
    const [loadingPostComentario, setLoadingPostComentario] = useState<boolean>(false);
    const localStorageClass = new LocalStorageClass();
    const [PERFIL] = useState<UserModel>(localStorageClass.getProfile());


    useEffect(() => {
        if (openComentario == true){
            getComentario( idFiado );
        }

    }, [openComentario, idFiado]);

    const formatDate = (date: string) => {
        const d = new Date(date);
        var dateString = d.getFullYear()
            + "-" + (d.getMonth()+1 < 10 ? '0' + (d.getMonth()+1 ): (d.getMonth()+1))
            + "-" + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate())
            + " " + (d.getHours() <10 ? ("0" + d.getHours()) : d.getHours())
            + ":" + (d.getMinutes() < 10 ? ("0" + d.getMinutes()) : d.getMinutes()) + " hrs.";
        return dateString;

    }

    const getComentario = async (idCliente: number ) => {
        try {
            // para la privacidad revisar en apiGralService
            const res = await apiGralService.getComentario(idCliente);
            var reverse = res.data.reverse();
            if( listComentarios !== reverse){
                setListComentarios(res.data);
            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
         //       console.log(err.response.status)
         //       console.log(err.response.data)
            }
        }
    }


    const postComentario = async (comentario: string ) => {
        setLoadingPostComentario( true );
        try {
            // para implementar categoria y privacidad revisar ApigralService
            await apiGralService.postComentario(idFiado ,comentario );
            setTextComentario('');
            setLoadingPostComentario( false );
            getComentario( idFiado );
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                setLoadingPostComentario( false );
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }
    return (
        <div className="row" id="comentarios-fiado">
            <div className="col-lg-12" >
                <Accordion defaultActiveKey="0">
                    <Card>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                {validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS) ?
                                <>
                                    <div className="" id='escribe-comentario"'>
                                            <textarea
                                                maxLength={500}
                                                placeholder={languageState?.language?.comentariosFiado.writeComment}
                                                className="input-sdgf"
                                                rows={1}
                                                onChange={(e) => setTextComentario(e.target.value)}
                                                value={textComentario}
                                            ></textarea>
                                    </div>
                                    <div className='mb-2 btn-send'>
                                        <PillButton
                                            size="small"
                                            variant="primary"
                                            onClick={() => postComentario(textComentario ) }
                                            disabled={textComentario === '' || loadingPostComentario}>
                                            { loadingPostComentario?  languageState?.language?.comentariosFiado.sending : languageState?.language?.comentariosFiado.send}
                                            { loadingPostComentario ? <Spinner animation="border" size="sm" /> : null}
                                        </PillButton>
                                    </div> 
                                </>: null}
                                <div className="container scroll_div">
                                    {listComentarios.map((i, index) => (
                                        <div key={index}>
                                            {
                                                (!i.es_propietario)?
                                                    <>
                                                        <div className="message-blue">
                                                            <div className="message-user">
                                                                {i.registradoPor}
                                                            </div>
                                                            <div className="message-content">
                                                                <AcordionTruncate length={50} text={i.comentario!}/>
                                                            </div>
                                                        </div>
                                                        <div className="message-timestamp-left">{formatDate(i.fechaRegistro)}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="message-orange">
                                                            <div className="message-user">
                                                                {i.registradoPor}
                                                            </div>
                                                            <div className="message-content">
                                                                <AcordionTruncate length={50} text={i.comentario!}/>
                                                            </div>
                                                        </div>
                                                        <div className="message-timestamp-right">{formatDate(i.fechaRegistro)}
                                                        </div>
                                                    </>
                                            }


                                        </div>

                                    ))}
                                </div>
                            {listComentarios.length === 0 ? <div> {languageState?.language?.comentariosFiado.noComment}  </div>: null }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </div>


    );
};

export default ComentariosFiado