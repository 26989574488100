import {
    LanguageModel,
    SELECT_LANGUAGE_ENGLISH,
    SELECT_LANGUAGE_SPANISH,
    SelectLanguageDispatchTypes
} from "../actions/SelectLanguageActionTypes";

export interface DefaultStateI {
    language?: LanguageModel
}

const defaultState: DefaultStateI = {

};

const languageReducer = (state: DefaultStateI = defaultState,
                         action: SelectLanguageDispatchTypes) : DefaultStateI => {
    switch (action.type) {
        case SELECT_LANGUAGE_ENGLISH:
            return {
                language: action.payload
            }
        case SELECT_LANGUAGE_SPANISH:
            return {
                language: action.payload
            }
        default:
            return state
    }
};
export default languageReducer;