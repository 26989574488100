import React, { ChangeEvent, Component } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { DefaultStateI } from '../../../../reducer/LanguageReducer';
import { RootStore } from '../../../../Store';
import NavBar from '../../../../components/public/nav-bar/NavBar';
import Form from '@lmig/lmds-react/Form/Form';
import { LocalStorageClass } from '../../../../class/LocalStorageClass';
import Select from '@lmig/lmds-react/Form/Select/Select';
import { Alert, AlphanumericInput, Button, GridCol, GridRow, Heading, IconButton, IconDownload, IconEdit, IconSearch, IconTrash, IconUpload, Modal, Table, TableCell, TableRow } from '@lmig/lmds-react';
import './Inpross.scss'
import { BreadCumb } from '../../../../components/public/breadcumb/BreadCumb';
import { Entidad } from '../../../../models/buro-credito/entidad';
import { AxiosError } from 'axios';
import * as getCreditbureau from "../../../../services/buro-credito/CreditbureauService";
import * as apiS3Services from "../../../../services/api-s3/ApiS3Services";
import * as consultaBuroServices from "../../../../services/consultar-buro/ConsultarBuroServices";
import { ConsultaBuro } from '../../../../models/buro-credito/ConsultaBuro';
import { Fisicas } from '../../../../models/buro-credito/Fisicas';
import { Morales } from '../../../../models/buro-credito/Morales';
import { initPaginacion, validFilepdf } from '../../../../class/CommonClass';
import { PaginacionModel } from '../../../../models/public/PaginacionModel';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PDFFile } from '../../../../components/buro-credito/PDFFile';
import { PDFMorales } from '../../../../components/buro-credito/PDFMorales';
import { SpinnerLoading } from '../../../../components/public/spinner-loading/SpinnerLoading';
import { UserModel } from '../../../../models/public/UserModel';
import { ModalBody } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { PipeLineIcon } from '../../../../components/public/pipeline-steps/PipelineIcon';
import { fechasEdit, FolioSeq, FormatDocument, RegistroFormato, tableFormatDocument } from '../../../../models/buro-credito/Datos';
import { PdfFile } from '../../../../models/buro-credito/PdfFile';
import { valueTernary } from 'react-select/dist/declarations/src/utils';
import { Value } from 'sass';


export type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>;

interface ConsultProssProps {
    language: DefaultStateI
}
interface Params {
    folios: string;
}



class InPross extends Component<ConsultProssProps & RouteComponentProps<Params>, ConsultProssState> {

    public localStorageClass = new LocalStorageClass();
    public select = document.getElementById('Persona');
    options: any;
    public folios: number;
    public archivo = "";
    public date = new Date();
    public set = this.date.getFullYear() + '-' + String(this.date.getMonth() + 1).padStart(2, '0') + '-' + String(this.date.getDate()).padStart(2, '0');

    public initDatos: FormatDocument = {
        fechaAutorizacion: '',
        fechaCarga: '',
        nomArchivoCarta: '',
        rfc: '',
        usuario: '',
        folioConsulta: '',
        subirArchivo: '',
        idCartaUsada:''
    }
    public initRegistroFormat: RegistroFormato = {
        fechaAutorizacion: '',
        fechaCarga: '',
        nombreArchivoCarta: '',
        rfc: '',
        usuario: '',
        folioConsulta: ''
    }
    public initDatosF: tableFormatDocument = {
        estatus: '',
        fechaAutorizacion: '',
        fechaCarga: '',
        idFormato: '',
        idStatus: '',
        nombreArchivoCarta: '',
        rfc: '',
        usuario: '',
        statusCarta: '',
        idCartaUsada:'0'
    }
    public initPDF: PdfFile = {
        carpeta: '',
        nombreArchivo: ''
    }

    constructor(props: any) {
        super(props);
        this.state = {
            estadoCaptura: 2,
            select: '',
            status: 0,
            validador: 1,
            entidad: [],
            datovalid: '',
            folio: '',
            datofisica: [],
            datoMoral: [],
            fechaAutorizacion: this.set,
            fechaAutoAux: '',
            mensajeM: '',
            mensajeF: '',
            Loading: true,
            datoForm: this.initDatos,
            errorBURODetail: false,
            errortablaformat: false,
            modalUpdate: false,
            formData: new FormData(),
            FormFisicas: new FormData(),
            prueba: this.initDatosF,
            tableFormatodOC: [],
            PERFIL: this.localStorageClass.getProfile(),
            validafile: 0,
            paginacion: initPaginacion,
            loadingConsult: true,
            listPaginacion: [],
            table: 0,
            isValid: false,
            calif: '',
            consulta: true,
            search: '',
            pdfOpen: false,
            show: true,
            viewCalif: false,
            fechaAutorizacionFormato: '',
            fechaConsultaBuro: '',
            pdfShow: false,
            Consul_buro: false,
            idFormatoDelete: '',
            PDFObject: this.initPDF,
            registroFormato: this.initRegistroFormat,
            AuxregistroFormato: this.initDatosF,
            isValid1: false,
            nombreCarta: '',
            consult_Format: false,
            FolioSeq: {
                folio: ''
            },
            fechas: {
                fechaAutorizacion: '',
                idFormato: '',
                folio: ''
            },
            Datos_consult: {
                folio: '',
                nombre: '',
                tipoEntidad: '',
                tipoPersona: '',
                rfc: '',
                colonia: '',
                estadoRepublica: '',
                segundoNombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                fechaNacimiento: '',
                sexo: '',
                codigoPostal: '',
                calle: '',
                estado: '',
                numinterior: '',
                delegacionMunicipio: '',
                numexterior: '',
                telefono: '',
                estadoDomicilio: '',
                razonSocial: '',
                representante: '',
                codigoBuro: '',
                idFormato: ''
            },
            AuxDatos_consult: {
                folio: '',
                nombre: '',
                tipoEntidad: '',
                tipoPersona: '',
                rfc: '',
                colonia: '',
                estadoRepublica: '',
                segundoNombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                fechaNacimiento: '',
                sexo: '',
                codigoPostal: '',
                calle: '',
                estado: '',
                numinterior: '',
                delegacionMunicipio: '',
                numexterior: '',
                telefono: '',
                estadoDomicilio: '',
                razonSocial: '',
                representante: '',
                codigoBuro: '',
                idFormato: ''
            }
        }
        this.folios = parseInt(this.props.match.params.folios);


        this.NextPage = this.NextPage.bind(this);
        this.PrevPage = this.PrevPage.bind(this);
        this.Endquery = this.Endquery.bind(this);
        this.downl = this.downl.bind(this);
        this.Submit = this.Submit.bind(this);
        this.getMorales = this.getMorales.bind(this);
        this.getfisicas = this.getfisicas.bind(this);
        this.getDatos = this.getDatos.bind(this);
        this.PdfVis = this.PdfVis.bind(this);
        this.getCalifBuro = this.getCalifBuro.bind(this);
        this.fechAut = this.fechAut.bind(this);
        this.posTablaFormato = this.posTablaFormato.bind(this);
        this.getTablaForm = this.getTablaForm.bind(this);
        this.putUpdatetablaForm = this.putUpdatetablaForm.bind(this);

    }

    render(): JSX.Element {
        return <>
          <NavBar />
            <div className="container-fluid warranty" id="consult-query">
              
                <div className="consult">

                    <BreadCumb title={this.props.language.language?.createquery.credittitle!}
                        listBreadcumbs={[
                            { title: this.props.language.language?.createquery.Home!, url: '/buro-credito' },
                            { title: this.props.language.language?.createquery.consult!, url: '/' }
                        ]} />

                    <div className="row" id="header-consult">

                        <div className="container">

                            <div className="Pipeline col-md-12 col-sm-12" id='pipeline-2'>
                                <PipeLineIcon
                                    listSteps={[this.props.language.language?.createquery.pipeline1!,
                                    this.props.language.language?.createquery.pipeline2!,
                                    this.props.language.language?.createquery.pipeline4!]}
                                    currentStep={this.state.estadoCaptura! - 1} />


                            </div>
                        </div>
                        <div className="Formulario" id='formulario-consult'>


                            <>
                                <Form id="form-consult" >
                                    {this.state.estadoCaptura === 1 ? (
                                        <>
                                            <div className="col-md-12" id='consult'>
                                                <h3>{[this.props.language.language?.createquery.data!]}</h3>
                                                <hr />

                                                <div className='col-12'>
                                                    <p>{[this.props.language.language?.createquery.entity!]}</p>
                                                    <Select
                                                        id="select--default"
                                                        isRequired
                                                        placeholderOption="Prueba"
                                                        labelVisual={`${this.state.Datos_consult.tipoEntidad} `}
                                                        disabled

                                                    >

                                                    </Select>
                                                    <br />
                                                    <p>{[this.props.language.language?.createquery.person!]}</p>
                                                    <Select
                                                        id="Persona"
                                                        name='persona'
                                                        isRequired
                                                        labelVisual={this.state.Datos_consult.tipoPersona}
                                                        disabled

                                                    >


                                                    </Select>
                                                </div>
                                                <br />
                                                <>

                                                    <div className="col-md-12" id='consult'>
                                                        <h3>{[this.props.language.language?.createquery.persondata!]}</h3>
                                                        <hr />


                                                        <div className='col-12'>
                                                            {this.state.Datos_consult.tipoPersona === 'Fisica' &&
                                                                <>
                                                                    <p>RFC</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.Datos_consult.rfc}
                                                                        value=""
                                                                        required={true} disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.name!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.Datos_consult.nombre}
                                                                        value="" disabled />


                                                                    <p>{[this.props.language.language?.createquery.second!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.Datos_consult.segundoNombre}
                                                                        value="" disabled />
                                                                    <p>{[this.props.language.language?.createquery.last!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.Datos_consult.apellidoPaterno}
                                                                        value="" disabled />
                                                                    <p>{[this.props.language.language?.createquery.mother!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.Datos_consult.apellidoMaterno}
                                                                        value="" disabled />

                                                                    <p>{[this.props.language.language?.createquery.date!]}</p>
                                                                    <input
                                                                        name="fechaInicial"
                                                                        id="fechaInicial"
                                                                        className="input-sdgf"
                                                                        placeholder={this.state.Datos_consult.fechaNacimiento}
                                                                        disabled />

                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.telefono!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.Datos_consult.telefono}
                                                                        value="" disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.sex!]}</p>
                                                                    <Select
                                                                        id="select--default"
                                                                        isRequired
                                                                        labelVisual={this.state.Datos_consult.sexo}
                                                                        onChange={function placeholderOptionOnChange() { }}
                                                                        disabled
                                                                    >

                                                                    </Select>
                                                                </>}

                                                            {this.state.Datos_consult.tipoPersona === 'Moral' &&
                                                                <>
                                                                    <p>RFC</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.Datos_consult.rfc}
                                                                        value=""
                                                                        required={true} disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.razonsocial!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.Datos_consult.razonSocial}
                                                                        value="" disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.representante!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.Datos_consult.representante}
                                                                        value="" disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.telefono!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual={this.state.Datos_consult.telefono}
                                                                        value="" disabled />

                                                                </>}
                                                        </div>
                                                    </div><br /><div className="col-md-12" id='consult'>
                                                        <h3>{[this.props.language.language?.createquery.residence!]}</h3>
                                                        <hr />


                                                        <div className='col-12'>

                                                            <p>C.P</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                labelVisual={this.state.Datos_consult.codigoPostal}
                                                                required={true} disabled />


                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.state!]}</p>
                                                            <Select
                                                                id="select--default"
                                                                isRequired
                                                                labelVisual={this.state.Datos_consult.estadoDomicilio}
                                                                onChange={function placeholderOptionOnChange() { }}
                                                                disabled
                                                            >

                                                            </Select>

                                                            <p>{[this.props.language.language?.createquery.town!]}</p>
                                                            <Select
                                                                id="select--default"
                                                                isRequired
                                                                labelVisual={this.state.Datos_consult.delegacionMunicipio}
                                                                onChange={function placeholderOptionOnChange() { }}
                                                                disabled
                                                            >

                                                            </Select>


                                                            <p>{[this.props.language.language?.createquery.street!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                labelVisual={this.state.Datos_consult.calle}
                                                                value="" disabled />
                                                            <p>{[this.props.language.language?.createquery.number!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                labelVisual={this.state.Datos_consult.numexterior}
                                                                value="" disabled />

                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.numint!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                labelVisual={this.state.Datos_consult.numinterior}
                                                                value="" disabled />
                                                            <GridRow gutterSize="fixed-16" gutters>

                                                                <GridCol base={12} md={12}>
                                                                    <Button dynamicWidth variant="primary" onClick={this.NextPage}>
                                                                        {this.props.language.language?.createquery.NextPage!}
                                                                    </Button>
                                                                </GridCol>
                                                            </GridRow>
                                                        </div>

                                                    </div></>

                                            </div>



                                        </>) : null}
                                    {this.state.estadoCaptura === 2 ? (
                                        <>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card" id='cards-idcartas'>
                                                        <div className="card-body text-center">
                                                            <h3 className="card-title">{this.props.language.language?.createquery.format!}</h3><br />
                                                            {this.state.Datos_consult.tipoPersona === 'Fisica' &&
                                                                <>
                                                                    <div className="icon-wrapper">

                                                                        <PDFDownloadLink document={<PDFFile />} fileName="AutorizacionparasolicitarInformacionCrediticia">
                                                                            {({ loading }) =>
                                                                                loading ? (
                                                                                    ''
                                                                                ) : (
                                                                                    <IconDownload size="64" color='gray' />
                                                                                )
                                                                            }
                                                                        </PDFDownloadLink>
                                                                    </div>
                                                                </>}

                                                            {this.state.Datos_consult.tipoPersona === 'Moral' &&
                                                                <>
                                                                    <div className="icon-wrapper">

                                                                        <PDFDownloadLink document={<PDFMorales />} fileName="AutorizacionparasolicitarInformacionCrediticia">
                                                                            {({ loading }) =>
                                                                                loading ? (
                                                                                    ''
                                                                                ) : (
                                                                                    <IconDownload size="64" color='gray' />
                                                                                )
                                                                            }
                                                                        </PDFDownloadLink>
                                                                    </div>
                                                                </>}
                                                            {this.props.language.language?.createquery.downl!}
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card" id='cards-idcartas'>
                                                        <div className="file-upload" id='image-upload-wrap'>

                                                            <input
                                                                type="file"
                                                                id='file-upload-input'
                                                                accept='application/pdf'
                                                                onChange={this.handleChange} />
                                                            <div className="card-body text-center">
                                                                <h3 className="card-title">{this.props.language.language?.createquery.auto!}</h3>

                                                                <div className="text-information">
                                                                    <IconUpload size="64" color='gray' /><br />
                                                                    {this.props.language.language?.createquery.arrastra!} <br />
                                                                    {this.props.language.language?.createquery.file!}

                                                                    {this.state.consulta === false &&
                                                                        <>
                                                                            <Alert id='alert_up' highlightType="positive">
                                                                                {this.props.language.language?.createquery.alert_up!}
                                                                            </Alert>
                                                                        </>}
                                                                </div>
                                                            </div>

                                                            <Modal className='Modal-fecha' isOpen={this.state.pdfShow} size="large"
                                                                modalStyle="standard" closeFunc={() => {
                                                                    this.setState({ pdfShow: false });
                                                                }}>
                                                                <ModalBody>
                                                                    <div className="autocomplete-wrapper-fecha" >
                                                                        <Heading tag="span" type="h3-light">{this.props.language.language?.createquery.fechaauto!}</Heading>
                                                                        <br /><br />
                                                                        <div className="dropdown">

                                                                            <input
                                                                                type='date'
                                                                                id="fechaAuto"
                                                                                name='fechaAuto'
                                                                                className='form-control'
                                                                                value={this.state.fechaAutorizacion}
                                                                                onChange={this.handleinputDate}

                                                                            />
                                                                        </div>
                                                                        {this.state.isValid ? (
                                                                            <div className="col-12">
                                                                                <Alert id='alert' highlightType="negative">
                                                                                    {this.props.language.language?.createquery.fechaInco!}
                                                                                </Alert>
                                                                            </div>
                                                                        ) :
                                                                            <>
                                                                            </>}
                                                                    </div>
                                                                    <Button id='btn-fecha-auto' variant="primary" onClick={this.fechAut}>
                                                                        {this.props.language.language?.createquery.guardar!}
                                                                    </Button>

                                                                </ModalBody>

                                                            </Modal><br />
                                                            <Button className='btn-archivo' size="small" variant="primary" onClick={this.PdfVis} hidden={this.state.show}>
                                                                {this.props.language.language?.createquery.visualFile!}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <Modal isOpen={this.state.pdfOpen} size="large"
                                                        className='Modal-pdf'
                                                        closeFunc={() => {
                                                            this.setState({ pdfOpen: false });
                                                        }}
                                                    >

                                                        <iframe title='archivo' className="iframe-pdf" src={this.archivo} allowFullScreen ></iframe>


                                                    </Modal>
                                                </div>
                                            </div>
                                            <br />
                                            <div>
                                                <br /><br />
                                                {this.state.errortablaformat ? (
                                                    <div className="col-12">
                                                        <Alert id='alert_up' highlightType="negative">
                                                            {this.props.language.language?.createquery.alertaDatos!}
                                                        </Alert>
                                                    </div>
                                                ) :
                                                    <>
                                                        {this.state.consult_Format === true ? (
                                                            <div className="col-12">
                                                                <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                                            </div>
                                                        ) :

                                                            <>

                                                                <div className="col-md-12">
                                                                    <Table id='table-archivo' >
                                                                        <thead>
                                                                            <TableRow >
                                                                                <TableCell dataHeader={'Folio'} type="colHead" className='tb'>
                                                                                    No.
                                                                                </TableCell>

                                                                                <TableCell dataHeader={'Nombre'} type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.fechaCarga!}
                                                                                </TableCell>
                                                                                <TableCell dataHeader={'Tipo'} type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.fechaAutorizacion!}
                                                                                </TableCell>
                                                                                <TableCell dataHeader={'Fecha'} type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.user!}
                                                                                </TableCell>
                                                                                <TableCell dataHeader={'FolioBC'} type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.status!}
                                                                                </TableCell>
                                                                                <TableCell dataHeader={'FolioBC'} type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.acciones!}
                                                                                </TableCell>


                                                                            </TableRow>
                                                                        </thead>

                                                                        {this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => (

                                                                            <>
                                                                                <tbody>

                                                                                    <TableRow >

                                                                                        <TableCell type="rowHead">
                                                                                            {index + 1}
                                                                                        </TableCell>

                                                                                        <TableCell>
                                                                                            {value.fechaCarga}
                                                                                        </TableCell>
                                                                                        <TableCell >
                                                                                            {value.fechaAutorizacion}
                                                                                        </TableCell>
                                                                                        <TableCell >
                                                                                            {value.usuario}
                                                                                        </TableCell>
                                                                                        <TableCell >
                                                                                            {value.estatus}
                                                                                        </TableCell>


                                                                                        <TableCell dataHeader={'Estatus'} className="estatus">
                                                                                            <div className="row">
                                                                                                <div className="col">
                                                                                                    <IconButton background="transparent" onClick={() => this.handleView(value.nombreArchivoCarta)} >
                                                                                                        <IconSearch size="24" />
                                                                                                    </IconButton>
                                                                                                    {value.estatus === 'Vigente'  && value.idCartaUsada === '0' ?  (
                                                                                                        <>
                                                                                                            <IconButton background="transparent" onClick={() => this.handleEdit(value.fechaAutorizacion, value.fechaCarga, value.idFormato, value.nombreArchivoCarta)} >
                                                                                                                <IconEdit size="24" />
                                                                                                            </IconButton>
                                                                                                            <IconButton background="transparent" onClick={() => this.handleDelete(value.idFormato)} >
                                                                                                                <IconTrash size="24" />
                                                                                                            </IconButton>
                                                                                                        </>
                                                                                                    ) :
                                                                                                        <>
                                                                                                        </>}
                                                                                                </div>

                                                                                            </div>



                                                                                        </TableCell>





                                                                                    </TableRow>





                                                                                </tbody>
                                                                            </>
                                                                        ))}
                                                                    </Table>
                                                                    <br />

                                                                </div>
                                                            </>}
                                                    </>}

                                                <Modal className='Modal-fecha' isOpen={this.state.modalUpdate} size="medium"
                                                    modalStyle="standard" closeFunc={() => {
                                                        this.setState({ modalUpdate: false });
                                                    }}>
                                                    <ModalBody>
                                                        <div className="autocomplete-wrapper-fecha" >
                                                            <Heading tag="span" type="h3-light">{this.props.language.language?.createquery.editfecha!}</Heading>
                                                            <br /><br />
                                                            <div className="container">
                                                                <div className="row">

                                                                    <div className="col">
                                                                        <div className="dropdown">
                                                                            <p>{this.props.language.language?.createquery.fechaAutorizacion!}</p>
                                                                            <input
                                                                                type='date'
                                                                                id="fechaAutorizacion"
                                                                                name='fechaAutorizacion'
                                                                                className='form-control'
                                                                                value={this.state.fechas.fechaAutorizacion}
                                                                                onChange={this.handleinputfecha}


                                                                            />
                                                                            {this.state.isValid1 ? (
                                                                                <div className="col-12">
                                                                                    <Alert id='alert' highlightType="negative">
                                                                                        {this.props.language.language?.createquery.fechaInco!}
                                                                                    </Alert>
                                                                                </div>
                                                                            ) :
                                                                                <>
                                                                                </>}
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <br />
                                                            <div>
                                                                <Button variant="primary" size="large" id='guadarfecha' onClick={this.putUpdatetablaForm} >
                                                                    {this.props.language.language?.createquery.guardar!}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </ModalBody>

                                                </Modal>
                                            </div>
                                            <GridRow gutterSize="fixed-16" gutters>
                                                <GridCol base={12} md={6}>
                                                    <Button dynamicWidth onClick={this.PrevPage}>
                                                        {this.props.language.language?.createquery.PrevPage!}
                                                    </Button>
                                                </GridCol>
                                                <GridCol base={12} md={6}>
                                                    <Button dynamicWidth variant="primary" onClick={this.Submit}>
                                                        {this.props.language.language?.createquery.NextPage!}
                                                    </Button>
                                                </GridCol>
                                            </GridRow>
                                        </>

                                    ) : null}
                                    {this.state.estadoCaptura === 3 ? (
                                        <>

                                            {this.state.Datos_consult.tipoPersona === 'Fisica' &&
                                                <>
                                                    <h3>{this.props.language.language?.createquery.detallef!}</h3>
                                                    <br />
                                                    {this.state.errorBURODetail ? (
                                                        <div className="col-12">
                                                            <Alert id='alert_up' highlightType="negative">
                                                                {this.state.mensajeF}
                                                            </Alert>
                                                        </div>
                                                    ) :
                                                        <>
                                                            {this.state.Consul_buro === true ? (
                                                                <div className="col-12">
                                                                    <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                                                </div>
                                                            ) :

                                                                <>


                                                                    {this.state.table === 1 &&

                                                                        <div className="tabla" id='tabla'>
                                                                            {this.state.viewCalif === true &&
                                                                                <>
                                                                                    <div className="container">
                                                                                        <div className="row">
                                                                                            <div className="col"  >
                                                                                                <h5 id='calif'>{this.props.language.language?.createquery.fechaCarga!}: {this.state.fechaConsultaBuro}</h5>
                                                                                            </div>
                                                                                            <div className="col"  >
                                                                                                <h5 id='calif'>{this.props.language.language?.createquery.fechaAutorizacion!}: {this.state.fechaAutorizacionFormato}</h5>
                                                                                            </div>
                                                                                            <div className="col">
                                                                                                <h5 id='calif'>{this.props.language.language?.createquery.calif!}: {this.state.calif}</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>}
                                                                            <Table id="table-credit">
                                                                                <thead>
                                                                                    <TableRow>
                                                                                        <TableCell dataHeader="A" type="colHead">
                                                                                            {this.props.language.language?.createquery.credit!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="B" type="colHead">
                                                                                            {this.props.language.language?.createquery.grantor!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="C" type="colHead">
                                                                                            {this.props.language.language?.createquery.saldo!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="D" type="colHead">
                                                                                            {this.props.language.language?.createquery.amount!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="E" type="colHead">
                                                                                            {this.props.language.language?.createquery.method!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="F" type="colHead">
                                                                                            {this.props.language.language?.createquery.max!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="G" type="colHead">
                                                                                            {this.props.language.language?.createquery.update!}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </thead>
                                                                                {this.state.datofisica.map((value: Fisicas, index: number) => (

                                                                                    <>
                                                                                        <tbody>
                                                                                            <TableRow key={index}>
                                                                                                <TableCell dataHeader="A" type="rowHead">
                                                                                                    {value.tipoCredito}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.tipoOtorgante}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.saldoActual}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.monto}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.formatoPago}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.maxMorosMopFechaImporte}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.ultimaActualizacion}
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                            <TableRow>

                                                                                            </TableRow>
                                                                                        </tbody>
                                                                                    </>
                                                                                ))}
                                                                            </Table>
                                                                        </div>
                                                                    }</>} </>}
                                                </>}
                                            {this.state.Datos_consult.tipoPersona === 'Moral' &&
                                                <>
                                                    <h3>{this.props.language.language?.createquery.detalle!}</h3>
                                                    <br />
                                                    {this.state.errorBURODetail ? (
                                                        <div className="col-12">
                                                            <Alert id='alert_up' highlightType="negative">
                                                                {this.state.mensajeM}
                                                            </Alert>
                                                        </div>
                                                    ) :
                                                        <>
                                                            {this.state.Consul_buro === true ? (
                                                                <div className="col-12">
                                                                    <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                                                </div>
                                                            ) :

                                                                <>

                                                                    {this.state.table === 1 &&
                                                                        <div className="tabla" id='tabla'>
                                                                            {this.state.viewCalif === true &&
                                                                                <>
                                                                                    <div className="container">
                                                                                        <div className="row">
                                                                                            <div className="col"  >
                                                                                                <h5 id='calif'>{this.props.language.language?.createquery.fechaCarga!}: {this.state.fechaConsultaBuro}</h5>
                                                                                            </div>
                                                                                            <div className="col"  >
                                                                                                <h5 id='calif'>{this.props.language.language?.createquery.fechaAutorizacion!}: {this.state.fechaAutorizacionFormato}</h5>
                                                                                            </div>
                                                                                            <div className="col">
                                                                                                <h5 id='calif'>{this.props.language.language?.createquery.calif!}: {this.state.calif}</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>}
                                                                            <Table id="table-credit">
                                                                                <thead>
                                                                                    <TableRow>
                                                                                        <TableCell dataHeader="A" type="colHead">
                                                                                            {this.props.language.language?.createquery.contrato!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="B" type="colHead">
                                                                                            {this.props.language.language?.createquery.tipooto!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="C" type="colHead">
                                                                                            {this.props.language.language?.createquery.tipocre!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="D" type="colHead">
                                                                                            {this.props.language.language?.createquery.vgente!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="E" type="colHead">
                                                                                            {this.props.language.language?.createquery.dia1!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="F" type="colHead">
                                                                                            {this.props.language.language?.createquery.dia30!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="G" type="colHead">
                                                                                            {this.props.language.language?.createquery.dia60!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="G" type="colHead">
                                                                                            {this.props.language.language?.createquery.dia90!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="G" type="colHead">
                                                                                            {this.props.language.language?.createquery.dia120!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="G" type="colHead">
                                                                                            {this.props.language.language?.createquery.dia180!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="G" type="colHead">
                                                                                            {this.props.language.language?.createquery.histo!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="G" type="colHead">
                                                                                            {this.props.language.language?.createquery.actua!}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </thead>

                                                                                {this.state.datoMoral.map((value: Morales, index: number) => (

                                                                                    <>

                                                                                        <tbody>
                                                                                            <TableRow key={index}>
                                                                                                <TableCell type="rowHead">
                                                                                                    {value.id}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.tipoOtorgante}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.tipoCredito}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.saldoVigente}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.vencido1a29}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.vencido30a59}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.vencido60a89}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.vencido90a119}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.vencido120a179}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.vencido180}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.historicoPagos}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.fechaActualizado}
                                                                                                </TableCell>

                                                                                            </TableRow>
                                                                                            <TableRow>

                                                                                            </TableRow>
                                                                                        </tbody>
                                                                                    </>
                                                                                ))}
                                                                            </Table>
                                                                        </div>
                                                                    }</>}</>}</>}


                                            <br /><br />
                                            <div className="boton">
                                                <GridRow gutterSize="fixed-16" gutters>
                                                    <GridCol base={12} md={6}>
                                                        <Button dynamicWidth onClick={this.PrevPage}>
                                                            {this.props.language.language?.createquery.PrevPage!}
                                                        </Button>
                                                    </GridCol>
                                                    <GridCol base={12} md={6}>
                                                        <Button dynamicWidth variant="primary" onClick={this.Endquery}>
                                                            {this.props.language.language?.createquery.finalize!}
                                                        </Button>
                                                    </GridCol>
                                                </GridRow>

                                            </div>
                                        </>

                                    ) : null}


                                </Form></>




                        </div>

                    </div>
                </div>
            </div >
        </>

    }


    saveWarranty() {
        throw new Error('Method not implemented.');

    }

    //funciones de redirección 

    public Submit() {
        const { estadoCaptura } = this.state
        this.setState({ estadoCaptura: estadoCaptura + 1 })
        if (this.state.Datos_consult.idFormato!.toString() === '0') {
            this.postFechAutoidFormatoO(this.state.registroFormato.fechaAutorizacion)
            this.postArchivoidformato(this.state.Datos_consult.idFormato!)
        }
        this.getMorales(this.state.Datos_consult.rfc);
        this.getfisicas(this.state.Datos_consult.rfc);

    }

    public NextPage() {
        const { estadoCaptura } = this.state
        this.setState({ estadoCaptura: estadoCaptura + 1 })

    }

    public PdfVis() {
        this.setState({ pdfOpen: true })

    }

    public fechAut() {
        this.postFechAuto();
        this.setState({ pdfShow: false })
        this.postFileValidacion(this.state.formData);

    }

    public handleDelete(value: string) {
        console.log(value)
        this.setState({
            idFormatoDelete: value
        })
        this.alertDeleteFormat()

    }

    //obtenemos el nomnre y datos del archivo a mandar buscar y mandamos llamar la funcion para la url
    public handleView(value: string) {

        this.setState({ PDFObject: { carpeta: 'buro', nombreArchivo: value + `.pdf` } }, () =>
            this.postURl());


    }


    //Carta usada
    public async cartaUsada(idCartaUsada: number, idFormato: string) {
        try {
            const res = await getCreditbureau.putCartaUsada(idCartaUsada, idFormato);
        } catch (error) {

        }
    }

    public handleEdit(fechaAutorizacion: string, fechaCarga: string, idFormato: string, folio: string) {
        console.log(fechaAutorizacion)
        console.log(fechaCarga)

        this.setState({
            fechas: {
                ...this.state.fechas,
                fechaAutorizacion: fechaAutorizacion,
                idFormato: idFormato,
                folio: folio
            }
        })
        this.setState({ modalUpdate: true })
        //this.putUpdatetablaForm();
    }


    public handleinputfecha = async (e: InputChange) => {
        //var inputFields = this.state.fechas;
        e.persist();
        await this.setState(preveState => ({
            fechas: { ...this.state.fechas, [e.target.name]: e.target.value }

        }))


        var fechaA = this.state.fechas.fechaAutorizacion.split("-")
        var fechA = fechaA[2] + "-" + fechaA[1] + "-" + fechaA[0];
        //this.setState({ fechaAutoAux: fech })
        // console.log(this.state.fechaAutoAux)
        var isValid1 = fechA.match(/^(3[01]|[12][0-9]|0?[1-9])(\/|-)(1[0-2]|0?[1-9])\2([0-9]{2})?[0-9]{2}$/);

        if (isValid1) {
            // console.log("fecha Correcta.");
            this.setState({ isValid1: false })
        } else {
            //console.log("Fecha Incorrecta");
            this.setState({ isValid1: true })
        }

    }




    public handleinputDate = (e: InputChange): void => {
        this.setState({ fechaAutorizacion: e.target.value })
        //console.log(this.state.fechaAutorizacion)

        var fecha = e.target.value.split("-")
        var fech = fecha[2] + "-" + fecha[1] + "-" + fecha[0];
        this.setState({ fechaAutoAux: fech })
        console.log(this.state.fechaAutoAux)
        var isValid = fech.match(/^(3[01]|[12][0-9]|0?[1-9])(\/|-)(1[0-2]|0?[1-9])\2([0-9]{2})?[0-9]{2}$/);

        if (isValid) {
            //console.log("fecha Correcta.");
            this.setState({ isValid: false })
        } else {
            //console.log("Fecha Incorrecta");
            this.setState({ isValid: true })
        }


    }


    public PrevPage() {
        const { estadoCaptura } = this.state
        this.setState({ estadoCaptura: estadoCaptura - 1 })
    }

    public Endquery() {
        this.props.history.push('/buro-credito');
    }

    public downl() {
        const comillas = 'https://www.libertyfianzas.com/wp-content/uploads/2021/04/AutorizacionparasolicitarInformacionCrediticia.pdf';
        window.open(comillas, '_blank');
    }

    public async getEntid() {
        try {
            const res = await getCreditbureau.getEntidades();
            console.log(res.data)
            this.setState({ entidad: res.data })

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {

            }
        }
    }

    componentDidMount() {
        this.getCredit();
        this.getDatos();

    }
    async getCredit(): Promise<void> {
        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                const res = await getCreditbureau.getConsul(dt);
                const dat = Object.values(res.data)
                this.setState({ AuxDatos_consult: res.data })
                this.setState({ Datos_consult: this.state.AuxDatos_consult })
                console.log(this.state.Datos_consult)
                console.log(dat)
                this.getTablaForm(this.state.Datos_consult.rfc);
                console.log(this.state.Datos_consult.idFormato!.toString())
                if (this.state.Datos_consult.idFormato!.toString() !== '0') {
                    this.getregistroFormatoBuro(this.state.Datos_consult.idFormato!);

                }


            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }


    async getregistroFormatoBuro(p: string): Promise<void> {
        try {

            const res = await getCreditbureau.getregistroFormatoBuro(this.state.Datos_consult.idFormato!);
            console.log(res.data)
            this.setState({ registroFormato: res.data })
            //console.log(this.state.registroFormato.nombreArchivoCarta)


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }

    }

    public getDatos(): void {

        var storedata = localStorage.getItem('FolioConsulta');
        if (storedata == null) {
        } else {
            var data = storedata.replace(/['"]+/g, '')
            this.setState({ folio: data })


        }
    }


    public async postURl() {

        try {

            const res = await apiS3Services.postUrl(this.state.PDFObject);
            const urldata = JSON.stringify(res.data.url)
            const comillas = urldata.replace(/['"]+/g, '')
            console.log(comillas)
            var win = window.open(comillas, '_blank');
            win?.focus();

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    public async putDeletetablaForm() {
        try {
            await getCreditbureau.putDeletetablaForm(this.state.idFormatoDelete);
            this.getTablaForm(this.state.Datos_consult.rfc)


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    public async putUpdatetablaForm() {

        console.log(this.state.fechas)
        var fechas = this.state.fechas.fechaAutorizacion.split("-")
        var fechaA = fechas[2] + "-" + fechas[1] + "-" + fechas[0];
        this.state.fechas.fechaAutorizacion = fechaA
        const res = await getCreditbureau.putUpdatetablaForm(this.state.fechas.fechaAutorizacion, this.state.fechas.idFormato);
        console.log(res)
        this.setState({ modalUpdate: false });
        this.getTablaForm(this.state.Datos_consult.rfc)
        this.postFechaAutoEdit(this.state.fechas.fechaAutorizacion, this.state.fechas.folio)
    }


    public async getfisicas(F: string) {

        this.setState({ Consul_buro: true })
        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                if (this.state.Datos_consult.tipoPersona === 'Fisica') {
                    var tipoConsulta = 'PF';
                    var data = storedata.replace(/['"]+/g, '')
                    var dt = data
                    if (this.state.Datos_consult.fechaNacimiento === '') {
                        if (this.state.Datos_consult.fechaNacimiento === undefined || this.state.Datos_consult.fechaNacimiento === '' || this.state.Datos_consult.fechaNacimiento === 'NaN') {
                            this.state.Datos_consult.fechaNacimiento = "";
                            //funciona correctamente

                        }
                    }
                    if (this.state.Datos_consult.fechaNacimiento !== undefined) {
                        var fecha = this.state.Datos_consult.fechaNacimiento.split("-")
                        this.state.Datos_consult.fechaNacimiento = '';

                        this.state.Datos_consult.fechaNacimiento = fecha[2] + fecha[1] + fecha[0];


                        var fechasA = this.state.registroFormato.fechaAutorizacion.split("-")
                        this.state.registroFormato.fechaAutorizacion = '';
                        this.state.registroFormato.fechaAutorizacion = fechasA[2] + '-' + fechasA[1] + '-' + fechasA[0];
                        this.state.Datos_consult.calle = encodeURIComponent(this.state.Datos_consult.calle);
                        this.state.Datos_consult.numexterior = encodeURIComponent(this.state.Datos_consult.numexterior);
                        this.state.Datos_consult.numinterior = encodeURIComponent(this.state.Datos_consult.numinterior);
                        const res = await consultaBuroServices.getFisicas(this.state.Datos_consult.rfc, this.state.Datos_consult.nombre, this.state.Datos_consult.segundoNombre,
                            this.state.Datos_consult.apellidoPaterno, this.state.Datos_consult.apellidoMaterno, this.state.Datos_consult.fechaNacimiento, this.state.Datos_consult.codigoPostal, this.state.Datos_consult.calle,
                            this.state.Datos_consult.codigoBuro, this.state.Datos_consult.estadoDomicilio!, this.state.Datos_consult.delegacionMunicipio, this.state.Datos_consult.numexterior, dt, tipoConsulta, this.state.PERFIL.username, this.state.Datos_consult.tipoEntidad,
                            this.state.registroFormato.fechaAutorizacion, this.state.Datos_consult.idCliente!
                        )

                        const mensaje = res.data.mensaje;
                        this.setState({ mensajeF: mensaje });
                        if (res.data.creditos == null) {
                            this.setState({ errorBURODetail: true })
                            this.setState({ table: 0 })
                        } else {
                            const ob = (res.data.creditos)
                            this.setState({ datofisica: ob })
                            this.setState({ table: 1 })
                            this.setState({ Consul_buro: false })
                            this.setState({ errorBURODetail: false })
                            this.getCalifBuro();
                           // if (this.state.Datos_consult.idFormato! !== '0') {
                                this.cartaUsada(1, this.state.Datos_consult.idFormato!)
                           // }
                        }
                    }
                }

            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                //this.setState({ Consul_buro: false })
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }



    public async getMorales(M: string) {



        this.setState({ Consul_buro: true })

        try {

            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                let data = storedata.replace(/['"]+/g, '')
                let dt = data
                if (this.state.Datos_consult.tipoPersona === 'Moral') {
                    var tipoConsulta = 'PM';

                    this.state.Datos_consult.rfc = encodeURIComponent(this.state.Datos_consult.rfc);
                    this.state.Datos_consult.nombre = encodeURIComponent(this.state.Datos_consult.razonSocial);
                    this.state.Datos_consult.numexterior = encodeURIComponent(this.state.Datos_consult.numexterior);
                    this.state.Datos_consult.numinterior = encodeURIComponent(this.state.Datos_consult.numinterior);
                    var fechasA = this.state.registroFormato.fechaAutorizacion.split("-")
                    this.state.registroFormato.fechaAutorizacion = '';
                    this.state.registroFormato.fechaAutorizacion = fechasA[2] + '-' + fechasA[1] + '-' + fechasA[0];
                    const res = await consultaBuroServices.getMorales(this.state.Datos_consult.rfc, this.state.Datos_consult.nombre,
                        this.state.Datos_consult.calle, this.state.Datos_consult.delegacionMunicipio,
                        this.state.Datos_consult.codigoPostal, this.state.Datos_consult.estadoDomicilio!, this.state.Datos_consult.codigoBuro, this.state.Datos_consult.numexterior, dt, tipoConsulta, this.state.PERFIL.username, this.state.Datos_consult.tipoEntidad,
                        this.state.registroFormato.fechaAutorizacion, this.state.Datos_consult.idCliente!)

                    const mensaje = res.data.mensaje;
                    this.setState({ mensajeM: mensaje });
                    if (res.data.creditos == null) {
                        this.setState({ errorBURODetail: true })
                        this.setState({ table: 0 })
                    } else {
                        const ob = (res.data.creditos)
                        this.setState({ datoMoral: ob })
                        this.setState({ table: 1 })
                        this.setState({ Consul_buro: false })
                        this.setState({ errorBURODetail: false })
                        this.getCalifBuro();
                        
                        //if (this.state.Datos_consult.idFormato! !== '0') {
                            this.cartaUsada(1, this.state.Datos_consult.idFormato!)
                       // }
                    }
                }
            }

        } catch (error) {

            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }



    public handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {


        if (e.target.files![0].size > 10097152) {
            this.alertArchivo();
            const fileObj = e.target.files && e.target.files[0];
            if (!fileObj) {
                return;

            }


            this.setState({ consulta: true })
            this.setState({ show: true })
            this.setState({ pdfOpen: true })

            console.log('fileObj is', fileObj);

            // 👇️ reset file input
            e.target.value = '';

            // 👇️ is now empty
            console.log(e.target.files);

        } else {
            if (validFilepdf(e)) {
                var storedata = localStorage.getItem('FolioConsulta');
                if (storedata == null) {
                    console.log('vacio')
                } else {
                    var data = storedata.replace(/['"]+/g, '')
                    const dt = data;
                    const res = await getCreditbureau.getFolioSeqFormato(dt)
                    const ob = res.data
                    console.log(ob)
                    this.setState({ FolioSeq: ob })
                    console.log(this.state.FolioSeq.folio)
                    const formDataAuxiliar = this.state.formData
                    formDataAuxiliar.set('archivo', e.target.files![0]);
                    formDataAuxiliar.set('carpeta', 'buro');
                    formDataAuxiliar.set('nombreArchivo', this.state.FolioSeq.folio + '.pdf');
                    this.setState({ formData: formDataAuxiliar })
                    this.setState({ consulta: false })
                    console.log(e.target.files![0].arrayBuffer)
                    //this.setState({ pdf: e.target.files![0].name })
                    this.setState({ show: false })
                    this.setState({ pdfShow: true })
                    var fecha = this.state.fechaAutorizacion.split("-")
                    var fech = fecha[2] + "-" + fecha[1] + "-" + fecha[0];
                    this.setState({ fechaAutoAux: fech })
                    console.log(this.state.fechaAutoAux)
                    var prueba = (window.URL).createObjectURL(e.target.files![0])
                    console.log(prueba)
                    this.archivo = prueba;
                }

            }

        }

    }

    public async postFechAuto() {
        try {
            var fecha = this.state.fechaAutorizacion.split("-")
            var fech = fecha[2] + "-" + fecha[1] + "-" + fecha[0];
            this.setState({ fechaAutoAux: fech })
            console.log(this.state.fechaAutoAux)
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data

                console.log(this.state.fechaAutoAux)
                await getCreditbureau.postFechAuto(this.state.fechaAutoAux, dt)
               // this.posTablaFormato();

            }
        } catch {

        }

    }

    public async postFechAutoidFormatoO(fecha: string) {
        try {

            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                var fechaA = fecha.split("-")
                var fech = fechaA[2] + "-" + fechaA[1] + "-" + fechaA[0];

                await getCreditbureau.postFechAuto(fech, dt)
                //this.posTablaFormato();
            }

        } catch {

        }

    }
    public async postFechaAutoEdit(fecha: string, folio: string) {
        try {


            await getCreditbureau.postFechAuto(fecha, folio)
            //this.posTablaFormato();


        } catch {

        }

    }

    public async posTablaFormato() {
        try {
            var fecha = this.state.fechaAutorizacion.split("-")
            var fech = fecha[2] + "/" + fecha[1] + "/" + fecha[0];
            let date = new Date();
            let set = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
            //console.log(this.state.fechaAutoAux)
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                this.setState({
                    datoForm:
                    {
                        ...this.state.datoForm,
                        fechaAutorizacion: fech,
                        fechaCarga: set,
                        nomArchivoCarta: this.state.FolioSeq.folio,
                        rfc: this.state.Datos_consult.rfc,
                        usuario: this.state.PERFIL.username,
                        folioConsulta: dt,
                        subirArchivo: '1',
                        idCartaUsada:'0'
                    }
                })
                console.log(this.state.formData)




                console.log(this.state.fechaAutoAux)
                await getCreditbureau.posTablaFormato(this.state.datoForm)
                this.getTablaForm(this.state.Datos_consult.rfc);
                this.getCredit();

            }
        } catch {

        }
    }
    public async getTablaForm(rfc: string) {
        this.setState({ consult_Format: true })
        try {

            const res = await getCreditbureau.getTablaForm(this.state.Datos_consult.rfc)
            var datoForm = res.data
            var ob = Object.values(datoForm)
            this.setState({ prueba: datoForm })
            this.setState({ tableFormatodOC: ob })
            if (ob.length === 0) {
                this.setState({ errortablaformat: true })
                this.setState({ consult_Format: false })

            } else {
                this.setState({ consult_Format: false })
                this.setState({ errortablaformat: false })
                const status = this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => value.nombreArchivoCarta);
                const str = status[0].toString();


                const fechaauto = this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => value.fechaAutorizacion);
                const strF = fechaauto[0].toString();
                const fechacarga = this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => value.fechaCarga);
                const strC = fechacarga[0].toString();

                this.setState({
                    registroFormato: {
                        nombreArchivoCarta: str,
                        fechaAutorizacion: strF,
                        rfc: this.state.registroFormato.rfc,
                        usuario: this.state.registroFormato.usuario,
                        fechaCarga: this.state.registroFormato.fechaCarga,
                        folioConsulta: this.state.registroFormato.folioConsulta
                    }
                })


                console.log(this.state.registroFormato.nombreArchivoCarta)
                console.log(this.state.Datos_consult.idFormato!)






            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }
    //Mandamos la info si idformato es igual a 0

    public postArchivoidformato(idFormato: string) {
        console.log('entra aqui a la funcion')
        console.log(idFormato)
        const status = this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => value.nombreArchivoCarta);
        const str = status[0].toString();
        console.log(str)
        const fechaauto = this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => value.fechaAutorizacion);
        const strF = fechaauto[0].toString();
        const strFa = strF.split("-")
        const strAuto = strFa[2] + '/' + strFa[1] + '/' + strFa[0];
        console.log(strAuto)
        const fechacarga = this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => value.fechaCarga);
        const strC = fechacarga[0].toString();
        const strCa = strC.split("-")
        const strCarga = strCa[2] + '/' + strCa[1] + '/' + strCa[0];
        console.log(strCarga)
        console.log('Si entra cuando no tienen archivo' + idFormato)
        var storedata = localStorage.getItem('FolioConsulta');
        if (storedata == null) {
            console.log('vacio')
        } else {
            var data = storedata.replace(/['"]+/g, '')
            var dt = data
            this.setState({
                datoForm:
                {
                    ...this.state.datoForm,
                    fechaAutorizacion: strAuto,
                    fechaCarga: strCarga,
                    nomArchivoCarta: dt,
                    rfc: this.state.Datos_consult.rfc,
                    usuario: this.state.PERFIL.username,
                    folioConsulta: str,
                    subirArchivo: '0',
                    idCartaUsada:'1'
                }
            }, () => this.prueba()
            )
            //this.getTablaForm(this.state.Datos_consult.rfc);
            this.getregistroFormatoBuro(this.state.Datos_consult.idFormato!)

        }
    }

    public async prueba() {
        console.log('entra aqui para subir el postarchivo')
        const res = await getCreditbureau.posTablaFormato(this.state.datoForm)
        this.getCredit();
        const idCartaRelacionada = res.data.idCartaRelacionada!
        console.log(idCartaRelacionada)
        this.cartaUsada(1, idCartaRelacionada)
        //console.log(res.data)


    }

    public async postFileValidacion(formData: FormData) {

        try {
            await apiS3Services.postFile(formData)
            this.getCredit();
            this.posTablaFormato()
            // this.setState({ Consul_buro: true })


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }


    public async getCalifBuro() {

        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                const res = await getCreditbureau.getCalifBuro(dt)
                if (this.state.Datos_consult.tipoEntidad === 'Fiado') {
                    this.setState({ viewCalif: true })
                } else {
                    this.setState({ viewCalif: false })
                }
                if (res.data.calificacion !== null) {
                    const calif = res.data.calificacion
                    const fechAuto = res.data.fechaAutorizacionFormato
                    const fechConsul = res.data.fechaConsultaBuro
                    this.setState({ calif: calif })
                    this.setState({ fechaAutorizacionFormato: fechAuto })
                    this.setState({ fechaConsultaBuro: fechConsul })

                } else {
                    const calif = 'No aplica';
                    this.setState({ calif: calif })
                }
            }
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }
    public alertArchivo() {
        Swal.fire(
            'Archivo demasiado grande!',
            '',
            'warning'
        )
    }

    public alertDeleteFormat() {

        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        Swal.fire({
            title: 'Estas seguro de eliminar el archivo?',
            text: "No podras recuperarlo",
            icon: 'warning',
            confirmButtonColor: '#008040',
            cancelButtonColor: '#D32F2F',
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Eliminado!',
                    'Tu archivo a sido borrado con exito',
                    'success'
                )
                this.putDeletetablaForm();
            }
        })
    }


}



const mapStateToProps = (state: RootStore) => ({
    language: state.language
});

export default connect(mapStateToProps)(InPross);


interface ConsultProssState {

    estadoCaptura: number;
    select: string,
    status: number,
    validador: number,
    entidad: Entidad[],
    datovalid: string,
    folio: string,
    datofisica: Fisicas[],
    datoMoral: Morales[],
    Loading: boolean,
    formData: FormData,
    validafile: number,
    paginacion: PaginacionModel,
    loadingConsult: boolean,
    listPaginacion: number[],
    consulta: boolean,
    search: string,
    Datos_consult: ConsultaBuro,
    AuxDatos_consult: ConsultaBuro,
    FormFisicas: FormData,
    Consul_buro: boolean,
    errorBURODetail: boolean,
    table: number,
    mensajeM: string,
    mensajeF: string,
    show: boolean,
    pdfOpen: boolean,
    calif: string,
    PERFIL: UserModel,
    viewCalif: boolean,
    pdfShow: boolean,
    fechaAutorizacion: string,
    fechaAutoAux: string,
    fechaAutorizacionFormato: string,
    fechaConsultaBuro: string,
    isValid: boolean,
    datoForm: FormatDocument,
    tableFormatodOC: tableFormatDocument[],
    prueba: tableFormatDocument,
    PDFObject: PdfFile,
    idFormatoDelete: string,
    modalUpdate: boolean,
    errortablaformat: boolean,
    fechas: fechasEdit,
    FolioSeq: FolioSeq,
    registroFormato: RegistroFormato,
    isValid1: boolean,
    nombreCarta: string,
    AuxregistroFormato: tableFormatDocument,
    consult_Format: boolean
}