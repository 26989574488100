import axios from 'axios';
import environment from "../../environments/environment";
import { LocalStorageClass } from "../../class/LocalStorageClass";
import { ConsultaBuro } from '../../models/buro-credito/ConsultaBuro';
import { Entidad } from '../../models/buro-credito/entidad';
import { estado } from '../../models/buro-credito/cp';
import { archAudit, DashboarAuditorias, Datos, DetallesAuditOficina, DetallesAuditProcess, FileBase64, FormatDocument, tableFormatDocument } from '../../models/buro-credito/Datos';
import { PaginacionModel } from '../../models/public/PaginacionModel';
import { admin_Contra } from '../../models/buro-credito/Admin_contraseñas';
import { Calif_buro, Respuesta_buro } from '../../models/buro-credito/Resp_buro';
import { foliosVigent } from '../../models/buro-credito/Folios_vigentes';
import { List_Office } from '../../models/buro-credito/folio';

const API = environment.APICREDIT;
const localStorageClass = new LocalStorageClass();

const headers = {
    Accept: 'application/json',
    'X-API-KEY': environment.APIKEYCREDIT,
    Authorization: localStorageClass.getProfileToken(),
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode(),
}

const headersFormData = {
    'Content-Type': 'multipart/form-data',
    'X-API-KEY': environment.APIKEYCREDIT,
    Accept: 'application/json',
    Authorization: localStorageClass.getProfileToken(),
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode(),

}



    


export const getConsulta = async (search:string, filtro: number,paginacion: PaginacionModel)  => {
    let paginacionQueryParams= '' ;

    if (paginacion.currentPage) {
        paginacionQueryParams += '&page=' + paginacion.currentPage
    }
    if (paginacion.size) {
        paginacionQueryParams += '&size=' + paginacion.size

    }

    

    
 
    return await axios.get<{paginacion: PaginacionModel,consultas:ConsultaBuro[], filtro:number}>(`${API}/historicoConsultas?busqueda=`+search+`&estado=`+ filtro+ paginacionQueryParams, { headers: headers });//historicoConsultas
}; 


//Obtener tipo entidades
export const getEntidades = async () => {
    return await axios.get<Entidad[]>(`${API}/entidades`, { headers: headers });//entidades
};
//Obtener status
export const getestado = async (cp: string) => {
    return await axios.get<estado>(`${API}/infoCodigoPostal?codigoPostal=` + cp, { headers: headers });//infoCodigoPostal
};


//carta usada
export const putCartaUsada = async (idCartaUsada:number,idFormato:string) => {
    return await axios.put(`${API}/estatusCartaUsada?idCartaUsada=`+idCartaUsada + `&idFormato=`+idFormato,null, { headers: headers });
};


//Guardar los datos ingresados al crear
export const postArchivo = async (consultObject: ConsultaBuro) => {
    return await axios.post<ConsultaBuro>(`${API}/datosConsultaHistorico`, consultObject, { headers: headers });//datosBuroHistorico
};


//Trae los porcentajes de los status
export const getPorecentaje = async ( rol:string, user:string) => {
    return await axios.get<Datos[]>(`${API}/porcentajesConsultas?rol=`+rol+`&usuario=`+ user, { headers: headers });//porcentajesConsultas
};

//Datos consulta usurios
export const getConsul = async (folio: string,) => {
    return await axios.get<ConsultaBuro>(`${API}/datosConsulta?folio=` + folio, { headers: headers });//datosConsulta
};

//Obtener tipo entidades
export const getConsultRes = async (folio: string) => {
    return await axios.get<Respuesta_buro>(`${API}/respuestaBuro?folio=`+folio, { headers: headers });//respuestaBuro
};

//ADMINISTRACIÓN DE CONTRASEÑAS

//Obtener contraseñas
export const getContra = async () => {
    return await axios.get<{ datos: admin_Contra}>(`${API}/datosUsuario` , { headers: headers });//datosUsuario
};


//Actualiza la contraseña
export const putContra = async (
    password:string,
    usuario:string
) => {
    return await axios.post<{ datos: admin_Contra}>(`${API}/passwordUsuario?password=`+password+`&usuario=`+usuario,null,{ headers: headers });
};


//Dashboard ejecutivo busqueda de fiados y filtro de oficinas
export const getConEject = async (search:string,filtro: number,oficina:string, rol:string,paginacion: PaginacionModel, usuario:string)  => {
    let paginacionQueryParams= '' ;

    if (paginacion.currentPage) {
        paginacionQueryParams += '&page=' + paginacion.currentPage
    }
    if (paginacion.size) {
        paginacionQueryParams += '&size=' + paginacion.size

    }

    
  
 
    return await axios.get<{paginacion: PaginacionModel,consultas:ConsultaBuro[], filtro:number, rol:string}>(`${API}/historicoConsultas?busqueda=`+search+`&estado=`+ filtro +`&oficina=`+ oficina +`&rolSolicitante=`+rol + paginacionQueryParams+ `&usuario=`+usuario, { headers: headers });
};

//Cambia el status del ejecutivo
export const putStatusEject = async (archivo:string, folio:string, status:string) => {
    return await axios.put<Datos[]>(`${API}/autorizarConsultaStatus?comentario=`+archivo+`&folio=`+folio+`&status=`+status,null, { headers: headers });
};
//Cambia el status del agente
export const putStatusAgent = async (archivo:string, folio:string) => {
    return await axios.put<Datos[]>(`${API}/statusArchivo?archivo=`+archivo+`&folio=`+folio,null, { headers: headers });
};


//Obtener Comsulta Agente
export const getConsultaAgente = async (value:string, token:string, user:string) => {
    return await axios.get<ConsultaBuro>(`${API}/buroFiado?busqueda=`+value+`&token=`+token+`&usuario=`+user, { headers: headers });
};

//Obtener Comsulta Fiados/Buro
export const getConsultaFiado = async (value:string, filtro:number, token:string) => {
    return await axios.get<ConsultaBuro>(`${API}/buroFiado?busqueda=`+value+`&filtro=`+filtro+`&token=`+token, { headers: headers });
};

//Obtener Comsulta Fiados/Buro busqueda por idFiado
export const getConsultaidFiado = async (filtro:number,idFiado:number|null,  token:string) => {
    return await axios.get<ConsultaBuro>(`${API}/buroFiado?filtro=`+filtro+`&idFiado=`+idFiado+`&token=`+token, { headers: headers });
};

//ActualizaDatosHistorico
export const putActualizar = async (variable: ConsultaBuro) => {
    return await axios.put(`${API}/datosHistorico`, variable, { headers: headers })
}
export const getConsulAgent = async (search:string,filtro: number,id:string, paginacion: PaginacionModel, usuario:string)  => {
    let paginacionQueryParams= '' ;

    if (paginacion.currentPage) {
        paginacionQueryParams += '&page=' + paginacion.currentPage
    }
    if (paginacion.size) {
        paginacionQueryParams += '&size=' + paginacion.size

    }

    

    
 
    return await axios.get<{paginacion: PaginacionModel,consultas:ConsultaBuro[], filtro:number}>(`${API}/historicoConsultas?busqueda=`+search+`&estado=`+ filtro  +`&idTipoEntidadAgente=`+id + paginacionQueryParams+`&usuario=`+usuario , { headers: headers });
};

//Obtener  si ya cuenta con una consulta
export const getClienteConsulta = async (rfc:string, user:string) => {
    return await axios.get<foliosVigent>(`${API}/clienteConsultaVigente?rfc=`+rfc+`&usuario=`+user, { headers: headers });
};
//Obtener  lista de oficinas
export const getlistaOficina = async (user:string) => {
    return await axios.get<List_Office>(`${API}/oficinas?usuario=`+user, { headers: headers });
};


//Obtenemos la calificacion de buro
export const getCalifBuro = async (folio:string) => {
    return await axios.get<Calif_buro>(`${API}/respuestaCalificacionBuro?folio=`+folio, { headers: headers });
};

//Envio de fecha Autorización
export const postFechAuto = async (fechaAuto:string, folio:string) => {
    return await axios.put(`${API}/actualizaFechaFormatoBuro?fechaAutorizacionFormato=`+fechaAuto+`&folio=`+folio,null, { headers: headers });
};

//Envio de datos para la tabla de formatos
export const posTablaFormato = async (FormatoArchivo: FormatDocument) => {
    return await axios.post<FormatDocument>(`${API}/FormatoBuroDatos`, FormatoArchivo, { headers: headers });
};

//Obtenemos los datos de la tabla formatos
export const getTablaForm = async (rfc:string) => {
    return await axios.get<tableFormatDocument>(`${API}/datosFormatoBuro?rfc=`+rfc, { headers: headers });
};

//Obtenemos los datos de la tabla formatos con la etiqueta de carta usada
export const getTablaFormCars = async (rfc:string, idFormato:number) => {
    return await axios.get<tableFormatDocument>(`${API}/formatoCartaUsada?idFormato=`+idFormato+`&rfc=`+rfc, { headers: headers });
};

//Delete de datos tabla formatos
export const putDeletetablaForm = async (idFormato:string) => {
    return await axios.put(`${API}/estatusFormato?idFormato=`+idFormato,null, { headers: headers });
};

//Actualizamos los campos de las fechas de la tabla formatos
export const putUpdatetablaForm = async (fechaAutorizacion:string, idFormato:string) => {
    return await axios.put(`${API}/datosFormato?fechaAutorizacion=`+fechaAutorizacion+`&idFormato=`+idFormato,null, { headers: headers });
};

//Nos regresa la secuencia del folio al que vamos a mandar como nombre para el archivo
export const getFolioSeqFormato = async (folio:string) => {
    return await axios.get(`${API}/folioSeqFormatoBuro?folio=`+folio, { headers: headers });
};

//Nos regresa el registro
export const getregistroFormatoBuro = async (idFormato:string) => {
    return await axios.get(`${API}/registroFormatoBuro?idFormato=`+idFormato, { headers: headers });
};


//Nos regresa el archivo de la tabla
export const getregistroActivo = async (folio:string, idFormato:string) => {
    return await axios.get(`${API}/folioArchivoFormato?folio=`+folio+`&idFormato=`+idFormato, { headers: headers });
};



///Auditoria


//Dashboar Auditorias/historicoAuditoria?filtro=0&page=1&size=10 
export const GetAuditorias = async (  filtro: number,paginacion: PaginacionModel) => {

    let paginacionQueryParams= '' ;

    if (paginacion.currentPage) {
        paginacionQueryParams += '&page=' + paginacion.currentPage
    }
    if (paginacion.size) {
        paginacionQueryParams += '&size=' + paginacion.size

    }

    return await axios.get<{paginacionAuditoria: PaginacionModel,consultas:DashboarAuditorias[], filtro:number}>(`${API}/historicoAuditoria?filtro=`+filtro+ paginacionQueryParams, { headers: headers });
};
//Busqueda Dashboard
export const GetBusquedaAudit = async ( busqueda:string, filtro: number,paginacion: PaginacionModel) => {

    let paginacionQueryParams= '' ;

    if (paginacion.currentPage) {
        paginacionQueryParams += '&page=' + paginacion.currentPage
    }
    if (paginacion.size) {
        paginacionQueryParams += '&size=' + paginacion.size

    }

    return await axios.get<DashboarAuditorias[]>(`${API}/historicoAuditoria?busquedaFecha=`+busqueda+`&filtro=`+filtro+ paginacionQueryParams, { headers: headers });
};


//Enviamos el documento y datos
export const postFile = async ( formData: FormData) => {
    return await axios.post<FormData>(`${API}/archivoAuditoria`,formData , { headers: headersFormData, });
};


//Obtenemos los datos FolioAuditoria/FoliosProcesados/fecha

export const getDetalleFolProcess = async (folioAuditoria:string) => {
    return await axios.get<DetallesAuditProcess>(`${API}/detalleFolProcesados?folioAuditoria=`+folioAuditoria, { headers: headers });
};

//Obtenemos datoos de la tabla Folios Oficina

export const getdetalleFoliosOficinas = async (fechaFin:string, fechaInicio:string,folioAuditoria:string) => {
    return await axios.get<DetallesAuditOficina[]>(`${API}/detalleFoliosOficinas?fechaFin=`+fechaFin+`&fechaInicio=`+fechaInicio+`&folioAuditoria=`+folioAuditoria, { headers: headers });
};

//Obtenemos la Ruta de los reportes por oficina
export const getURLReporte = async (fechaFin:string, fechaInicio:string,folioAuditoria:string, idOficina:number, tipoPersona:string) => {
    return await axios.post<archAudit>(`${API}/generarReporteOficinas?fechaFin=`+fechaFin+`&fechaInicio=`+fechaInicio+`&folioAuditoria=`+folioAuditoria+`&idOficina=`+idOficina+`&tipoPersona=`+tipoPersona, null,{ headers: headers });
};

//Enviamos el documento en base64 y datos del archivo
export const postFileBase64 = async ( FilesBase64: FileBase64) => {
    return await axios.post<FileBase64[]>(`https://lf-sgf-apigeneral-impl-development.us-east-1.np.paas.lmig.com/sharedpoint/subirBase64`,FilesBase64 , { headers: headers});
};
