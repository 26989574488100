
import React, { Component } from 'react'
import { Dropdown, Pagination } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { initPaginacion, loadPaginationList } from '../../../class/CommonClass';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import { BreadCumb } from '../../../components/public/breadcumb/BreadCumb';
import NavBar from '../../../components/public/nav-bar/NavBar';
import { TypeProfileModel } from '../../../components/garantias/select-type-user/SelectTypeUser';
import { DefaultStateI } from '../../../reducer/LanguageReducer';
import { RootStore } from '../../../Store';
import '../credit-bureau/Creditbureau.scss'
import { Alert, Badge, IconSeeMore, Table, TableCell, TableRow } from '@lmig/lmds-react';
import * as getCreditbureau from "../../../services/buro-credito/CreditbureauService"
import { AxiosError } from 'axios';
import { ConsultaBuro } from '../../../models/buro-credito/ConsultaBuro';
import { PaginacionModel } from '../../../models/public/PaginacionModel';
import { SpinnerLoading } from '../../../components/public/spinner-loading/SpinnerLoading';
import { UserModel } from '../../../models/public/UserModel';


interface FiltrarProps {
    language: DefaultStateI
}
interface Params {
    folio: string;
}

class FiltrarVencida extends Component<FiltrarProps & RouteComponentProps<Params>, FiltrarState> {

    public localStorageClass = new LocalStorageClass();

    public folio: number;

    constructor(props: any) {
        super(props);
        document.title = "Consultas Proceso"



        this.state = {
            status: 0,
            listBureau: [],
            paginacion: initPaginacion,
            loadingConsult: true,
            datoCaptura: [],
            listPaginacion: [],
            search: '',
            folio: '',
            errorTableBuro: false,
            valida_tabla: false,
            table: 0,
            PERFIL: this.localStorageClass.getProfile(),


        }
        this.folio = parseInt(this.props.match.params.folio);
        this.handlenew = this.handlenew.bind(this);
        this.getProceso = this.getProceso.bind(this);
    }



    render() {
        return <>
          <NavBar />
            <div className="container-fluid " id="creditbureau">
              
                <div className="creditbureau-container" >
                    <BreadCumb title={this.props.language.language?.createfiltrar.title3!}
                        listBreadcumbs={[
                            { title: this.props.language.language?.createquery.Home!, url: '/buro-credito' },
                            { title: this.props.language.language?.createfiltrar.title3!, url: '/buro-credito/filtrar-consultas/vencidas' },
                        ]} />


                </div>

                <br />
                <div className="col-md-12">
                    {this.state.errorTableBuro ? (
                        <div className="col-12">
                            <Alert highlightType="negative">
                                {this.props.language.language?.creditbureau.alert!}
                            </Alert>
                        </div>
                    ) :
                        <>

                            {this.state.valida_tabla === true ? (
                                <div className="col-12">
                                    <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                </div>
                            ) :

                                <>
                                    {this.state.table === 1 &&



                                        < Table id='table-credit' >
                                            <thead>
                                                <TableRow>
                                                    <TableCell dataHeader={'Folio'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.creditfolio!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'Nombre'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.creditname!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'Tipo'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.credittype!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'Fecha'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.creditdate!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'FolioBC'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.creditbc!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'Fecha'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.creditBCalif!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'FechaAutorizacion'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.creditFechAuto!}
                                                    </TableCell>

                                                    <TableCell dataHeader={'Estatus'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.creditstatus!}
                                                    </TableCell>


                                                </TableRow>
                                            </thead>

                                            <tbody>
                                                {
                                                    this.state.datoCaptura.map((value: ConsultaBuro, index: number) => (


                                                        <TableRow key={index}>

                                                            < TableCell type="rowHead" >
                                                                {value.folio}
                                                            </TableCell>


                                                            <TableCell>
                                                                {value.nombre + " " + value.apellidoPaterno}
                                                            </TableCell>



                                                            <TableCell >
                                                                {value.tipoEntidad}
                                                            </TableCell>
                                                            <TableCell >
                                                                {value.fechaConsulta}
                                                            </TableCell>
                                                            <TableCell >
                                                                {value.folioBcConsulta}
                                                            </TableCell>
                                                            <TableCell >
                                                                {value.calificacionBuro}
                                                            </TableCell>
                                                            <TableCell >
                                                                {value.fechaAutorizacionFormato}
                                                            </TableCell>

                                                            <TableCell dataHeader={'Estatus'} className="estatus">
                                                                <div className="row">
                                                                    <div className="col">

                                                                        {value.estado === '3' ? (
                                                                            <Badge highlightType="negative">
                                                                                &nbsp;&nbsp;{this.props.language.language?.createfiltrar.vencid!}&nbsp;&nbsp;
                                                                            </Badge>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col">
                                                                        {value.estado === '3' ? (
                                                                            <Dropdown>
                                                                                <Dropdown.Toggle variant="light" className="dropdown-more" id="dropdown-basic-1">
                                                                                    <IconSeeMore></IconSeeMore>
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item onClick={() => this.handleProcess(value.folio!)}>
                                                                                        {this.props.language.language?.createfiltrar.consult!}
                                                                                    </Dropdown.Item>

                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        ) : null}

                                                                    </div>
                                                                </div>



                                                            </TableCell>

                                                        </TableRow>

                                                    ))
                                                }



                                            </tbody>
                                        </Table>
                                    }
                                </>}
                        </>}

                    {/*paginacion*/}
                    < div className="row pagination-sdgf" >
                        <div className="col-md-5 show-registers">
                            Mostrando registros del&nbsp;
                            {(this.state.paginacion.currentPage * this.state.paginacion.size) - (this.state.paginacion.size - 1)} al&nbsp;
                            {(this.state.paginacion.currentPage * this.state.paginacion.size)} de un total de&nbsp;
                            {this.state.paginacion.totalItems} registros.
                        </div>
                        <div className="col-md-7 ">
                            <Pagination className="float-md-right" id="pagination-table-warranty">
                                <Pagination.Prev
                                    onClick={() => {
                                        this.clickGoToPage((this.state.listPaginacion[0]) - 1)

                                    }}
                                />
                                {
                                    this.state.listPaginacion.map((i, index) =>
                                    (<Pagination.Item
                                        key={i}
                                        active={i === this.state.paginacion.currentPage}
                                        onClick={() => {
                                            this.clickGoToPage(i)
                                        }}>
                                        {i}
                                    </Pagination.Item>

                                    ))
                                }
                                <Pagination.Next
                                    onClick={() => {
                                        this.clickGoToPage(this.state.listPaginacion[this.state.listPaginacion.length - 1] + 1)
                                    }}
                                />

                            </Pagination>

                        </div>

                    </div>


                </div>

            </div>
        </>

    }
    loadDashboard(tipoPerfil: TypeProfileModel) {
        throw new Error('Method not implemented.');
    }

    public handlenew() {
        this.props.history.push('/buro-credito/crear-consulta');
    }

    componentDidMount() {
        this.getProceso(this.folio, this.state.paginacion);
    }

    public async getProceso(folio: number, paginacion: PaginacionModel) {
        try {
            this.setState({ valida_tabla: true })
            const res = await getCreditbureau.getConsulta(this.state.search, 3, paginacion);
            const response: { paginacion: PaginacionModel, consultas: ConsultaBuro[] } = res.data;
            response.paginacion.size = paginacion.size;
            //console.log(response)
            this.setState({
                paginacion: response.paginacion,
                loadingConsult: false,
                datoCaptura: res.data.consultas,
                listPaginacion: loadPaginationList(res.data.paginacion)
            })
            //console.log(res.data.consultas)
            if (response.consultas == null) {
                this.setState({ errorTableBuro: true })
                this.setState({ table: 0 })
                this.setState({ valida_tabla: false })

            } else {
                this.setState({ valida_tabla: false })
                this.setState({ errorTableBuro: false })
                this.setState({ table: 1 })
            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    private clickGoToPage(page: number) {
        const totalPages: number = this.state.paginacion.totalPages;
        if (page >= 1 && page <= totalPages) {
            const auxPaginacion = this.state.paginacion;
            auxPaginacion.currentPage = page;
            this.setState({ paginacion: auxPaginacion })
            this.getProceso(this.folio, this.state.paginacion);
        }

    }

    public handleProcess(value: string) {
        localStorage.setItem('FolioConsulta', JSON.stringify(value));
        this.props.history.push('/buro-credito/consultar/info-vencidas');
    }

}




function mapStateToProps(state: RootStore) {
    return ({
        language: state.language
    });
}


export default connect(mapStateToProps)(FiltrarVencida);

interface FiltrarState {
    status: number,
    listBureau: ConsultaBuro[];
    paginacion: PaginacionModel,
    loadingConsult: boolean,
    datoCaptura: ConsultaBuro[],
    listPaginacion: number[],
    search: string,
    folio: string,
    errorTableBuro: boolean,
    valida_tabla: boolean,
    table: number,
    PERFIL: UserModel,
}