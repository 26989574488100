import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import './ModalFileMaxUpload.scss';
import {
    Button, IconAdd, IconChecklist,
    IconCheckmark, IconEdit, IconErrorAlert,
    IconSearch, IconSuccessAlert, IconThumbsDown, IconThumbsUp,
    IconTrash,
    IconUpload,
    Link as LinkLiberty,
    Modal
} from "@lmig/lmds-react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";



interface ModalFileMaxUploadProps {
    openModal: boolean;
    maxSize: number;
    clickShowModal: (show: boolean) => void;
}


export const ModalFileMaxUpload: React.FC<ModalFileMaxUploadProps> = ({ openModal, maxSize, clickShowModal}:ModalFileMaxUploadProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language.language?.modalFileMaxUpload);


    const [openModalExcedLimitFile, setOpenModalExcedLimitFile] = useState<boolean>(false);

    useEffect(() => {
        if (openModal){
            setOpenModalExcedLimitFile(true);
        }

    },[openModal]);


    return (
        <>

                {/*modal se ha sobrepasado el tamaño documento*/}
                <Modal
                    isOpen={openModalExcedLimitFile}
                    size="medium"
                    modalStyle="standard"
                    title=""
                    closeFunc={() => {
                        setOpenModalExcedLimitFile(false);
                        clickShowModal(false)
                    }}
                    id="modal-exeed-limit">

                    <div className="row">
                        <div className="col-12 h5">
                            {languageState?.modalExcedLimitFile} {Math.round( maxSize/1048576)} mb.
                        </div>

                        <div className="col-md-6 col-12"/>
                        <div className="col-md-6 col-12">
                            <Button
                                dynamicWidth
                                variant="primary"
                                onClick={()=>{
                                    setOpenModalExcedLimitFile(false);
                                    clickShowModal(false)
                                }}>
                                {languageState?.ok}
                            </Button>
                        </div>

                    </div>

                </Modal>


        </>
    );
};


