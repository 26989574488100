import React, {useEffect, useState} from "react";
import './Home.scss';
import NavBarPublic from "../../../components/public/nav-bar-public/NavBarPublic";
import Soporte from '../../../assets/images/public/home/Woman_at_Desk7.png';
import Contacto from '../../../assets/images/public/home/Cell Phone.png';
import Contizador from '../../../assets/images/public/home/Building2.png';
import environment from "../../../environments/environment";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {
    IconClose,
    IconHighrise,
    IconLifetimeRepairGuarantee, IconPeople,
    IconPolicy,
    LogoLibertyMutualHoriz
} from "@lmig/lmds-react";

import  {PillButton} from '@lmig/lmds-react-pill-button'
import {Card} from "react-bootstrap";
const Home = () => {
    const languageState = useSelector((state: RootStore) => state.language.language?.homeLanguage);

    const [showMore, setshowMore] = useState<boolean>(false);

    useEffect(() => {
        // useffect
        document.title = "Liberty Fianzas S.A de C.V"
    });

    return (
        <>
            <NavBarPublic/>

            <div className="container-fluid" id="home">

                    <div className="row banner-home1">
                        <div className="col-md-7 ">
                            <h1>
                                {languageState?.title} <br/>
                            </h1><br/>
                            {languageState?.textBanner}

                        </div>
                        <div className="col-md-5"/>

                    </div>

                    <div className="row ">
                        <div className="col-12">
                            <div className="header-seccion">
                                {(!showMore)?
                                    <>
                                        <h2>Liberty Mutual Group<br/></h2>
                                        <div className="row items ">
                                            <div className="col-md-1"></div>
                                            <div className="col-md-2">
                                                <LogoLibertyMutualHoriz className="d-block max-auto ico" />
                                                <h5>
                                                    {languageState?.item1}
                                                </h5>
                                            </div>
                                            <div className="col-md-2">
                                                <IconLifetimeRepairGuarantee size="64"
                                                                             color="informative"
                                                                             className="d-block mx-auto ico" />
                                                <h5>
                                                    {languageState?.item2}
                                                </h5>
                                            </div>
                                            <div className="col-md-2">
                                                <IconPeople size="64"
                                                          color="informative"
                                                          className="d-block mx-auto ico" />
                                                <h5>
                                                    {languageState?.item3}
                                                </h5>
                                            </div>
                                            <div className="col-md-2">
                                                <IconPolicy size="64"
                                                            color="informative"
                                                            className="d-block mx-auto ico" />
                                                <h5>
                                                    {languageState?.item4}
                                                </h5>
                                            </div>
                                            <div className="col-md-2">
                                                <IconHighrise size="64"
                                                              color="informative"
                                                              className="d-block mx-auto ico" />
                                                <h5>
                                                    {languageState?.item5}
                                                </h5>
                                            </div>
                                            <div className="col-md-1"></div>
                                            <div className="col-md-12 text-center learn-more" >
                                                <PillButton variant="primary"
                                                onClick={()=>{
                                                    setshowMore(true)
                                                }}>
                                                    {languageState?.showMore}
                                                </PillButton>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="card-home">


                                            <IconClose id="close-card"
                                                       onClick={()=>{
                                                           setshowMore(false)}
                                                       }/>
                                            <h3 className="text-center">Liberty Mutual Group<br/></h3>
                                            <ul>
                                                <li> {languageState?.li1}</li>
                                                <li> {languageState?.li2}</li>
                                                <li> {languageState?.li3}</li>
                                                <li> {languageState?.li4}</li>
                                                <li> {languageState?.li5}</li>
                                                <li> {languageState?.li6}</li>
                                                <li> {languageState?.li7}</li>
                                                <li> {languageState?.li8}</li>
                                                <li> {languageState?.li9}</li>
                                                <li> {languageState?.li10}</li>
                                                <li> {languageState?.li11}</li>
                                                <p>
                                                    {languageState?.t1}&nbsp;
                                                    <a href="https://www.libertymutualgroup.com/about-lm/global/liberty-mutual-surety/"
                                                       target="_blank">
                                                        LIBERTY MUTUAL GROUP
                                                    </a>

                                                </p>

                                                <span className="fuente">{languageState?.source}:&nbsp;
                                                    <a href="https://www.libertymutualgroup.com/about-lm/global/liberty-mutual-surety/" target="_blank">
                                            Liberty Mutual Group</a>,
                                           <a href="https://www.rankingthebrands.com/Brand-detail.aspx?brandID=2215" target="_blank"> Ranking the brands</a>
                                    </span>


                                            </ul>
                                            {environment.env == "dev" &&

                                                <p>Equipo de Desarrollo.<br/></p>
                                            }
                                            {environment.env == "test" &&
                                                <>
                                                    <p>
                                                        T E S T<br/>
                                                        Estimado usuarios en caso de presentarse un
                                                        incidente durante sus pruebas, favor de comunicarse con Yanet Carmona a la extensión 1841.

                                                    </p>
                                                </>
                                            }
                                            <div className="w-100 text-center">
                                                <PillButton variant="secondary"
                                                            onClick={()=>{
                                                                setshowMore(false)
                                                            }}>
                                                    Ver menos
                                                </PillButton>
                                            </div>

                                    </div>
                                }
                            </div>

                            <div className="row banner-home2">
                                <div className="col-md-5">
                                    <Card className="card-validator">
                                        <Card.Body>
                                            <h3>
                                               Valida tu  Fianzas <br/>
                                            </h3><br/>
                                            En Liberty Fianzas, hacemos las cosas más fáciles <br/>
                                            para nuestros clientes, valida una fianza con un par de clics.<br/><br/>

                                            <PillButton variant="primary"
                                                        onClick={()=>{
                                                            window.location.replace(environment.URLVALFIANZA);
                                                        }}>
                                                Ir al Validador de Fianzas
                                            </PillButton>
                                        </Card.Body>
                                    </Card>


                                </div>
                                <div className="col-md-7"/>

                            </div>
                            <div className="col-md-12">
                            <div className="row bottom-card-home">

                                <div className="col-md-12">
                                    <h2 className="text-center">
                                        Liberty Fianzas <br/>
                                    </h2>

                                    <div className="row">
                                        <div className="col-md-6 text-bottom">
                                            <h3>{languageState?.supportTitle}</h3>
                                            {languageState?.supportText1} <a href="tel:5551280700">55 5128 0700</a>
                                            &nbsp;
                                            {languageState?.supportText2}&nbsp;
                                            <a href="tel:8005014700">800 5014 700</a>&nbsp;{languageState?.supportText3}
                                            <br/> <br/>
                                            <a href="tel:8005014700">
                                                <PillButton variant="primary">
                                                    Comunicarse
                                                </PillButton>
                                            </a>


                                        </div>
                                        <div className="col-md-6 ">
                                            <img src={Soporte} className="img-fluid d-block mx-auto"/>
                                        </div>

                                    </div>

                                </div>
                                <div className="col-md-12">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src={Contacto} className="img-fluid d-block mx-auto"/>
                                        </div>
                                        <div className="col-md-6 text-bottom">
                                            <h3>{languageState?.contactTitle}</h3>
                                            {languageState?.contactText}
                                            <br/><br/>
                                            <a href="https://www.libertyfianzas.com/contacto">
                                                <PillButton variant="primary">
                                                    Contactar con Liberty Fianzas
                                                </PillButton>
                                            </a>
                                        </div>

                                    </div>

                                </div>
                                <div className="col-md-12">

                                    <div className="row">
                                        <div className="col-md-6 text-bottom">
                                            <h3>{languageState?.quoteTitle}</h3>
                                            {languageState?.quoteText}
                                            <br/><br/>
                                            <a href="https://cotizador.libertyfianzas.com/contacto/SERF">
                                                <PillButton variant="primary">
                                                    Ir al cotizador
                                                </PillButton>
                                            </a>
                                        </div>
                                        <div className="col-md-6 ">
                                            <img src={Contizador} className="img-fluid d-block mx-auto"/>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>




                        </div>


                    </div>
                </div>

        </>
    );
};

export default Home;
