export interface InfoPrincipalLanguage {
    obligatory: string;
    agents: string;
    showMore: string;
    loadingInterview: string;
    errorInterview: string;
    addOs: string;
    locked: string;
    unLocked: string;
    returnPrincipal: string;
    lockedComision: string;
    unlockedComision: string;
}

export const InfoPrincipalLanguageEnglish: InfoPrincipalLanguage ={
    obligatory: 'Obligor',
    agents: 'Agent',
    showMore: 'Show More',
    loadingInterview: 'Loading Interview',
    errorInterview: 'Error loading interview',
    addOs: 'Add Obligor',
    locked: 'Principal Blocked',
    unLocked: 'Principal Unlocked',
    returnPrincipal: 'Return to our Principal ',
    lockedComision:  'Unlocked Commission ',
    unlockedComision:'Locked Commission ',

}
export const InfoPrincipalLanguageSpanish: InfoPrincipalLanguage ={
    obligatory: 'Obligado Solidario',
    agents: 'Agente',
    showMore: 'Ver más',
    loadingInterview: 'Cargando Entrevista',
    errorInterview:'Error al cargar la entrevista',
    addOs: 'Agregar OS',
    locked: 'Fiado Bloqueado',
    unLocked: 'Fiado Desbloqueado',
    returnPrincipal: 'Regresar a nuestro Fiado',
    lockedComision: 'Comisión Desbloqueada',
    unlockedComision:'Comisión Bloqueada',
}
