import React, { Component } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  TableCell,
  TableRow,
  Table,
  Heading,
  Link as LinkLiberty,
  IconSearch,
  IconBrokenLink,
  IconData,
  IconErrorAlert,
} from "@lmig/lmds-react";
import "../warranty/Warranty.scss";
import "./WarrantyComparison.scss";
import NavBar from "../../../components/public/nav-bar/NavBar";
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import {
  formatCurrency,
  formatValue,
  initPaginacion,
  InputChange,
  openNewWindow,
} from "../../../class/CommonClass";
import { UserModel } from "../../../models/public/UserModel";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import { BreadCumbPrev } from "../../../components/public/breadcumb-prev/BreadCumbPrev";
import { HistorialModel } from "../../../models/garantias/HistorialModel";
import { PaginacionModel } from "../../../models/public/PaginacionModel";
import { TypeProfileModel } from "../../../components/garantias/select-type-user/SelectTypeUser";
import * as apiService from "../../../services/api-gral/ApiGralService";
import { FiadoModel } from "../../../models/garantias/FiadoModel";
import { AxiosError } from "axios";
import { FilterSearchModel } from "../warranties/Warranties";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import { ComparacionGarantias } from "../../../models/garantias/ComparacionGarantias";
import { Card } from "react-bootstrap";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { DocumentoComparacion } from "../../../models/garantias/DocumentosComparacion";
import { SpinnerLoading } from "../../../components/public/spinner-loading/SpinnerLoading";

interface WarrantyComparisonProps {
  language: DefaultStateI;
}

class WarrantyComparison extends Component<
  WarrantyComparisonProps & RouteComponentProps,
  WarrantyComparisonState
> {
  public localStorageClass = new LocalStorageClass();

  constructor(props: any) {
    super(props);

    document.title = "Comparación de Garantías";
    this.state = {
      idFiado: 0,
      idFolio: 0,
      PERFIL: this.localStorageClass.getProfile(),
      listComparacion: [],
      errorComparacion: false,
      selectedCheckboxes: [],
      paginacion: initPaginacion,
      listPaginacion: [],
      idClient: "",
      documento1: [],
      documento2: [],
      loadingComparison: false,
      loadingDocuments: false,
      auxComparacion: "",
      isDireccionada:  false,
      isExclusiva: false,
    };
  }
  handleCheckboxChange = (id: number) => {
    const { selectedCheckboxes } = this.state;

    if (selectedCheckboxes.includes(id)) {
      // Si la casilla ya está seleccionada, la deseleccionamos
      this.setState({
        selectedCheckboxes: selectedCheckboxes.filter((id) => id !== id),
      });
    } else if (selectedCheckboxes.length < 2) {
      // Si aún no se han seleccionado dos casillas, la seleccionamos
      this.setState({
        selectedCheckboxes: [...selectedCheckboxes, id],
      });
    }
  };
  render() {
    return (
      <>
            <NavBar />
        <div className="container-fluid warranty" id="warranty-comparison">
    
          <BreadCumbPrev
            title={
              this.props.language.language?.WarrantyComparisonLanguage.title!
            }
          />

          <div className="col-md-12 ">
            <Heading type="h4-bold">
              {this.props.language.language?.WarrantyComparisonLanguage.title}:{" "}
              {this.state.idFolio}
            </Heading>
            {this.state.loadingComparison ? (
              <div className="col-12">
                <SpinnerLoading
                  text={
                    this.props.language.language?.WarrantyComparisonLanguage
                      .loadingComparison
                  }
                />
              </div>
            ) : (
              <Table className="">
                <thead>
                  <TableRow>
                    <TableCell type="colHead"></TableCell>
                    {this.state.listComparacion.map((i, index) => (
                      <TableCell
                        key={index}
                        className="text-center"
                        type="colHead"
                      >
                        {i.detalleGarantia.folio} -{" "}
                        {i.detalleGarantia.version}
                      </TableCell>
                    ))}
                  </TableRow>
                </thead>
                <tbody>
                  <TableRow>
                    <TableCell>
                      {
                        this.props.language.language?.WarrantyComparisonLanguage
                          .typeUse
                      }{" "}
                    </TableCell>
                    {this.state.listComparacion.map((i, index) => (
                      <TableCell
                        key={index}
                        className={this.classNameComparison(
                          i.detalleGarantia.tipoUso,
                          this.state.listComparacion[0].detalleGarantia.tipoUso,
                          index
                        )}
                      >
                        {i.detalleGarantia.tipoUso}<br/>
                      </TableCell>
                    ))}


                  </TableRow>

                  {(this.state.isDireccionada) &&
                      <TableRow >
                        <TableCell>
                          {
                            this.props.language.language?.WarrantyComparisonLanguage
                                .fianza
                          }{" "}
                        </TableCell>

                        {this.state.listComparacion.map((i, index) => (
                            <TableCell
                                key={index}
                                className={this.classNameComparison(
                                    i.detalleGarantia.fianza!,
                                    this.state.listComparacion[0].detalleGarantia.fianza!,
                                    index
                                )}
                            >

                              {(i.detalleGarantia.fianza) ?(
                                  <>
                                    {i.detalleGarantia.fianza!} - {i.detalleGarantia.inclusion!}<br/>

                                  </>

                              ):null}

                            </TableCell>
                        ))}
                      </TableRow>

                  }


                {(this.state.isExclusiva) && 
                <TableRow >
                <TableCell>
                  {
                    this.props.language.language?.WarrantyComparisonLanguage
                        .fiados
                  }{" "}
                </TableCell>
          
                {this.state.listComparacion.map((i, index) => (
                            <TableCell
                                key={index}
                                className={this.classNameComparison(
                                    String(i.detalleGarantia.clientesExclusiva),
                                    String(this.state.listComparacion[0].detalleGarantia.clientesExclusiva!),
                                    index
                                )}
                            >
                              <ul>
                                {i.detalleGarantia.clientesExclusiva?.map((clienteExc, index2) => (
                                  <li
                                  key={index2}>
                                    {
                                      clienteExc.rfc + ' ' + clienteExc.razonSocial
                                     
                                    }
                                  </li>
                                ))}
                              </ul>

                            </TableCell>
                        ))}
                </TableRow>
                }


                  <TableRow>
                    <TableCell>
                      {
                        this.props.language.language?.WarrantyComparisonLanguage
                          .guaranteeType
                      }
                    </TableCell>
                    {this.state.listComparacion.map((i, index) => (
                      <TableCell
                        key={index}
                        className={this.classNameComparison(
                          i.detalleGarantia.tipoGarantia,
                          this.state.listComparacion[0].detalleGarantia
                            .tipoGarantia,
                          index
                        )}
                      >
                        {i.detalleGarantia.tipoGarantia}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {
                        this.props.language.language?.WarrantyComparisonLanguage
                          .currency
                      }{" "}
                    </TableCell>
                    {this.state.listComparacion.map((i, index) => (
                      <TableCell
                        key={index}
                        className={this.classNameComparison(
                          i.detalleGarantia.moneda,
                          this.state.listComparacion[0].detalleGarantia.moneda,
                          index
                        )}
                      >
                        {i.detalleGarantia.moneda}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      {
                        this.props.language.language?.WarrantyComparisonLanguage
                          .amount
                      }
                    </TableCell>
                    {this.state.listComparacion.map((i, index) => (
                      <TableCell
                        key={index}
                        className={this.classNameComparison(
                          i.detalleGarantia.monto.toString(),
                          this.state.listComparacion[0].detalleGarantia.monto.toString(),
                          index
                        )}
                      >
                        {formatCurrency(i.detalleGarantia.monto)}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      {
                        this.props.language.language?.WarrantyComparisonLanguage
                          .weighing
                      }
                    </TableCell>
                    {this.state.listComparacion.map((i, index) => (
                      <TableCell
                        key={index}
                        className={this.classNameComparison(
                          i.detalleGarantia.porcentajePonderacion.toString(),
                          this.state.listComparacion[0].detalleGarantia.porcentajePonderacion.toString(),
                          index
                        )}
                      >
                        {i.detalleGarantia.porcentajePonderacion} %
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {
                        this.props.language.language?.WarrantyComparisonLanguage
                          .weightedAmount
                      }
                    </TableCell>
                    {this.state.listComparacion.map((i, index) => (
                      <TableCell
                        key={index}
                        className={this.classNameComparison(
                          i.detalleGarantia.montoPonderado.toString(),
                          this.state.listComparacion[0].detalleGarantia.montoPonderado.toString(),
                          index
                        )}
                      >
                        {formatCurrency(i.detalleGarantia.montoPonderado)}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {" "}
                      {
                        this.props.language.language?.WarrantyComparisonLanguage
                          .validity
                      }
                    </TableCell>
                    {this.state.listComparacion.map((i, index) => (
                      <TableCell
                        key={index}
                        className={this.classNameComparison(
                          i.detalleGarantia.fechaInicial +
                            i.detalleGarantia.fechaFinal,
                          this.state.listComparacion[0].detalleGarantia
                            .fechaInicial +
                            this.state.listComparacion[0].detalleGarantia
                              .fechaFinal,
                          index
                        )}
                      >
                        {i.detalleGarantia.fechaInicial}
                        <br />
                        {i.detalleGarantia.fechaFinal}
                        <br />
                      </TableCell>
                    ))}
                  </TableRow>
                </tbody>
              </Table>
            )}
            <Heading type="h4-bold">
              {
                this.props.language.language?.WarrantyComparisonLanguage
                  .documents
              }
            </Heading>
            {this.state.loadingDocuments ? (
              <div className="col-12">
                <SpinnerLoading
                  text={
                    this.props.language.language?.WarrantyComparisonLanguage
                      .loadingDocuments
                  }
                />
              </div>
            ) : (
              <Table className="">
                <thead>
                  <TableRow>
                    <TableCell type="colHead"></TableCell>
                    {this.state.listComparacion.map((i, index) => (
                      <TableCell
                        key={index}
                        className="text-center"
                        type="colHead"
                      >
                        {i.detalleGarantia.folio} -{" "}
                        {i.detalleGarantia.version}
                      </TableCell>
                    ))}
                  </TableRow>
                </thead>
                <tbody>
                  {this.state.documento1.map((i, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell className="title">{i.descripcion}</TableCell>
                        <TableCell className="text-center">
                          {i.url ? (
                            <a
                              className="col"
                              onClick={() => {
                                openNewWindow(i.url!);
                              }}
                            >
                              <IconSearch
                                color="informative"
                                size="24"
                                title={
                                  this.props.language.language
                                    ?.WarrantyComparisonLanguage.watchFile!
                                }
                                className="cursor"
                              />
                            </a>
                          ) : (
                            <IconBrokenLink
                              color="informative"
                              size="24"
                              title={
                                this.props.language.language
                                  ?.WarrantyComparisonLanguage.brokenLink!
                              }
                            />
                          )}
                        </TableCell>
                        <TableCell
                          className={this.classNameDocuments(i.url, i.url2)}
                        >
                          {i.url2 ? (
                            <a
                              className="col"
                              onClick={() => {
                                openNewWindow(i.url2!);
                              }}
                            >
                              <IconSearch
                                color="informative"
                                size="24"
                                title={
                                  this.props.language.language
                                    ?.WarrantyComparisonLanguage.watchFile!
                                }
                                className="cursor"
                              />
                            </a>
                          ) : (
                            <IconBrokenLink
                              color="informative"
                              size="24"
                              title={
                                this.props.language.language
                                  ?.WarrantyComparisonLanguage.brokenLink!
                              }
                            />
                          )}
                        </TableCell>
                      </TableRow>
                      {i.detalle.map((iD, indexD) => (
                        <TableRow key={indexD}>
                          <TableCell>&emsp;&emsp;{iD.descripcion}</TableCell>
                          <TableCell
                            className={this.classNameDocuments(
                              iD.valor,
                              iD.valor2
                            )}
                          >
                            {iD.valor ? (
                              <>{iD.valor}</>
                            ) : (
                              <>
                                <IconErrorAlert
                                  color="negative"
                                  size="24"
                                  title={
                                    this.props.language.language
                                      ?.WarrantyComparisonLanguage.noData!
                                  }
                                />
                              </>
                            )}
                          </TableCell>
                          <TableCell className="text-center">
                            {iD.valor2 ? (
                              <>{iD.valor2}</>
                            ) : (
                              <>
                                <IconErrorAlert
                                  color="negative"
                                  size="24"
                                  title={
                                    this.props.language.language
                                      ?.WarrantyComparisonLanguage.noData!
                                  }
                                />
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ))}

                  {this.state.documento2.map((i, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell className="title">{i.descripcion}</TableCell>
                        <TableCell className="text-center">
                          <IconBrokenLink
                            color="informative"
                            size="24"
                            title={
                              this.props.language.language
                                ?.WarrantyComparisonLanguage.brokenLink!
                            }
                          />
                        </TableCell>
                        <TableCell className="text-center">
                          {i.url ? (
                            <a
                              className="col"
                              onClick={() => {
                                openNewWindow(i.url!);
                              }}
                            >
                              <IconSearch
                                color="informative"
                                size="24"
                                title={
                                  this.props.language.language
                                    ?.WarrantyComparisonLanguage.watchFile!
                                }
                                className="cursor"
                              />
                            </a>
                          ) : (
                            <IconBrokenLink
                              color="informative"
                              size="24"
                              title={
                                this.props.language.language
                                  ?.WarrantyComparisonLanguage.brokenLink!
                              }
                            />
                          )}
                        </TableCell>
                      </TableRow>
                      {i.detalle.map((iD, indexD) => (
                        <TableRow key={indexD}>
                          <TableCell>&emsp;&emsp;{iD.descripcion}</TableCell>
                          <TableCell className="text-center">
                            <IconErrorAlert
                              color="negative"
                              size="24"
                              title={
                                this.props.language.language
                                  ?.WarrantyComparisonLanguage.noData!
                              }
                            />
                          </TableCell>
                          <TableCell className="text-center">
                            {iD.valor ? (
                              <>{iD.valor}</>
                            ) : (
                              <>
                                <IconErrorAlert
                                  color="negative"
                                  size="24"
                                  title={
                                    this.props.language.language
                                      ?.WarrantyComparisonLanguage.noData!
                                  }
                                />
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
          <br />
          <br />
          <br />
        </div>
      </>
    );
  }

  componentDidMount() {
    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);

    const idFolio = urlParams.get("idFolio");
    // console.log(idFolio);
    const idGarantia1 = urlParams.get("garantia1");

    const idGarantia2 = urlParams.get("garantia2");

    this.setState({ idFolio: parseInt(idFolio!) });
    this.getComparacion(parseInt(idGarantia1!), parseInt(idGarantia2!));
  }

  public async getComparacion(idGarantia1: number, idGarantia2: number) {
    this.setState({ loadingComparison: true, loadingDocuments: true });
    try {
      const res = await garantiasService.getCompararHistorial(
        idGarantia1,
        idGarantia2
      );
       console.log(res.data)
      console.log(res.data.reverse())
      this.setState({
        loadingComparison: false,
        loadingDocuments: false,
        listComparacion: res.data.reverse(),
      });
      // vamos a buscar si tiene alguna garanes direccionada
       this.isGarantiaDireccionada(res.data.reverse())
      this.reorder(res.data.reverse());

      this.isGarantiaExclusiva(res.data.reverse())
      this.reorder(res.data.reverse());
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        console.log(err.response.status);
        console.log(err.response.data);
        this.setState({ loadingComparison: false, loadingDocuments: false });
      }
    }
  }

  isGarantiaDireccionada(listComparacion: ComparacionGarantias[]){
    for (const i  of listComparacion){
      if (i.detalleGarantia.tipoUso == 'Direccionada'){
        this.setState({isDireccionada: true})
        break;
      }
    }
  }

  isGarantiaExclusiva(listComparacion: ComparacionGarantias[]){
    for (const i  of listComparacion){
      if (i.detalleGarantia.tipoUso == 'Exclusiva'){
        this.setState({isExclusiva: true})
        break;
      }
    }
  }


  reorder(garantia: ComparacionGarantias[]) {
    const documento1 = garantia[0].documentos;
    const documento2 = garantia[1].documentos;
    this.setState({
      documento1,
      documento2,
    });
    let i: number, j: number, k: number;
    for (i = 0; i < documento1.length; i++) {
      for (j = 0; j < documento2.length; j++) {
        if (documento1[i].descripcion === documento2[j].descripcion) {
          documento1[i].url2 = documento2[j].url;
          for (k = 0; k < documento1[i].detalle.length; k++) {
            documento1[i].detalle[k].valor2 = documento2[j].detalle[k].valor;
          }
          // Eliminar el elemento coincidente en documento2
          documento2.splice(j, 1);
          break; // Romper el bucle una vez emparejado y eliminado
        }
      }
    }
    this.setState({
      documento1,
      documento2,
    });



  }

  classNameComparison(currentValue: string, firstValue: string, index: number) {
    if (index === 0) {
      return "text-center";
    }

    if (currentValue !== firstValue) {
      return "text-center bg-warning text-dark";
    }

    return "text-center";
  }
  updateAuxComparacion(valor: string) {}

  classNameDocuments(valor1: string | null, valor2: string | null) {
    if (valor1 !== undefined && valor2 !== undefined && valor1 !== valor2) {
      return "text-center bg-warning text-dark";
    }
    return "text-center";
  }
}

interface WarrantyComparisonState {
  idFiado: number;
  idFolio: number;
  PERFIL: UserModel;
  listComparacion: ComparacionGarantias[];
  errorComparacion: boolean;
  selectedCheckboxes: number[];
  paginacion: PaginacionModel;
  listPaginacion: number[];
  idClient: string;
  documento1: DocumentoComparacion[];
  documento2: DocumentoComparacion[];
  loadingComparison: boolean;
  loadingDocuments: boolean;
  auxComparacion: string;
  isDireccionada: boolean;
  isExclusiva: boolean;
}
const mapStateToProps = (state: RootStore) => ({
  language: state.language,
});

export default connect(mapStateToProps)(WarrantyComparison);
