export interface AuditoriasLanguageModel {

    Title: string;
    Folio:string;
    Periodo:string;
    TipoPersona:string;
    Usuario:string;
    Detalle:string;

    //Botones
    NewAudit:string;
    Search:string;
    Consultar:string;



}

export const AuditoriasLanguageEnglish: AuditoriasLanguageModel = {

    Title: 'Audit',
    Folio:'Invoice',
    Periodo:'Period',
    TipoPersona:'Person Type',
    Usuario:'User',
    Detalle:'Detail',
    NewAudit:'New Audit',
    Search:'Search',
    Consultar:'Consult'

}


export const AuditoriasLanguageSpanish: AuditoriasLanguageModel = {

    Title:'Auditoria',
    Folio:'Folio',
    Periodo:'Periodo',
    TipoPersona:'Tipo Persona',
    Usuario:'Usuario',
    Detalle:'Detalle',
    NewAudit:'Nueva Auditoria',
    Search:'Buscar',
    Consultar:'Consultar',


}