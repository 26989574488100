import React, {useEffect, useState} from 'react';
import './PipeLineCE.scss';
import {IconCheckmark} from "@lmig/lmds-react";

interface PipeLineStepsProps {
    listSteps:string [],
    currentStep: number,
  

}

export const PipeLineStepsDetails: React.FC<PipeLineStepsProps> = ({ listSteps, currentStep }: PipeLineStepsProps) => {

    useEffect(() => {
      //  console.log(currentStep);
        //console.log(listSteps)

      
    }, []);

    return (
        <>
            { listSteps.length > 0 &&  listSteps.length < 5 ? (
                <div className="col-md-12" id="pipelineCE">

                    <hr id={"hrr"}/>
                    <div className="row pipeDetails">

                 
                        {listSteps.map((i, index) => (
                                    <div
                                        key={index}
                                        className={
                                        `${listSteps.length==2 ? "col-md-6" : ""}` +
                                        `${listSteps.length==3 ? "col-md-4" : ""}` +
                                        `${listSteps.length==4 ? "col-md-3" : ""}`
                                        + ' text-center'}>
                                        
                                    
                                        {currentStep > index? (
                                                <span className="badge rounded-pill bg-success text-white">
                                                <IconCheckmark color={"inverse"} size="16"></IconCheckmark>
                                                </span>

                                        ) :  <span className="badge rounded-pill bg-warning text-dark">{index+1} </span>}

                                       
                                        
                                        <br/>
                                        <span className="item-name"> {i} </span>

                                    </div>
                                ))
                        }





                    </div>
                </div>
            ) :
                <>
                {/*No se puede hacer un pipe line mayor a 4 opciones*/}
                Error Pipeline
                </>
            }

        </>

    );
};

