export interface ReviewLoteLanguageModel {
    title: string;
    home: string;
    fiado: string;
    search: string;


}

export const ReviewLoteLanguageEnglish: ReviewLoteLanguageModel ={
    title: 'Review of Collaterals',
    home: 'Home',
    fiado: 'Principal',
    search: 'Search'
}
export const ReviewLoteLanguageSpanish: ReviewLoteLanguageModel ={
    title: 'Revisión de Garantías',
    home: 'Inicio',
    fiado: 'Fiado',
    search: 'Buscar'
}