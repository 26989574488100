import { Component } from 'react';
import { IconMyAccount, Table, TableCell, TableRow, IconSeeMore, Button, Modal, AlphanumericInput, SelectOption, Select } from "@lmig/lmds-react";
import '../table-engagements/TableEngagements.scss';
import Info from '@lmig/lmds-react/icons/Info';
import ReactTooltip from 'react-tooltip';
import * as engagementService from '../../../services/compromisos/EngagementsService';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import { CompromisoOption } from '../../../models/compromisos/CompromisoOption';
import { UserModel } from '../../../models/public/UserModel';
import { Dropdown, Pagination } from 'react-bootstrap';
import { RootStore } from "../../../Store";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { connect } from "react-redux";
import { CodigoRespuesta } from '../../../models/compromisos/CodigoRespuesta';
import { AxiosError } from 'axios';
import { AutorizaCompromiso } from '../../../models/compromisos/AutorizaCompromiso';
import { SpinnerLoading } from '../../public/spinner-loading/SpinnerLoading';
import SuccessAlert from '@lmig/lmds-react/icons/SuccessAlert';
import { formatCurrency, formatValue, InputChange, loadPaginationList } from '../../../class/CommonClass';
import { PaginacionModel } from '../../../models/public/PaginacionModel';
import { ElementosCompromiso } from '../../../models/compromisos/ElementosCompromiso';


interface TableEngagementsProps {

    language: DefaultStateI,
    idFiado: number;
}

interface SteepsModel {
    openModalClose?: boolean;
    PERFIL: UserModel;
    listCompromisosPorAutorizar: ElementosCompromiso[];
    notificacion: CodigoRespuesta;
    completedEngagementObject: AutorizaCompromiso;
    openModalConfirmacion: boolean;
    openModalFecha: boolean;
    idCompromiso: number,
    paginacion: PaginacionModel,
    mensajesAlerta: Messages;
    listPaginacion: number[],

}


class TableCheckEngagementsByFiado extends Component<TableEngagementsProps, SteepsModel> {


    public localStorageClass = new LocalStorageClass();
    public today: string;
    public last: string;
    public initialEngagementStateAcepta: AutorizaCompromiso = {
        idCompromiso: 0,
        idAccion: 0,
        fechaComprometida: '',
        motivo: '',
        dictamen: '',
        nombreRep: '',
        usuario: '',
    }

    public initPaginacion: PaginacionModel = {
        currentPage: 1,
        totalItems: 0,
        totalPages: 0,
        size: 10,
    }

    public initialMsg: Messages = {
        msgTipo: '',
        msgCondicion: '',
        msgBloqueo: '',
        msgProducto: '',
        msgMonto: '',
        msgCorreo: '',
        msgFecha: '',
        msgMotivo: ''
    }



    constructor(props: any) {
        super(props);

        this.today = '';
        this.last = '';
        const PERFIL = this.localStorageClass.getProfile();

        this.state = {
            mensajesAlerta: this.initialMsg,
            idCompromiso: 0,
            openModalClose: false,
            PERFIL: PERFIL,
            completedEngagementObject: this.initialEngagementStateAcepta,
            listCompromisosPorAutorizar: [],
            notificacion: {
                codigo: 0,
                descripcion: ''
            },
            paginacion: this.initPaginacion,
            listPaginacion: [],
            openModalConfirmacion: false,
            openModalFecha: false
        }

        const date = new Date().getDate();
        const month = new Date().getMonth() + 1;
        const year = new Date().getFullYear();
        let today = "";
        let last = "";
        const monthA = new Date().getMonth() + 6;
        let yearA = year;



        if (month < 10 && date < 10) {
            const monthAux = "0" + month
            const dateAux = "0" + date
            today = year + "-" + monthAux + "-" + dateAux
            this.today = today
        }

        if (month < 10 && date >= 10) {
            const monthAux = "0" + month
            today = year + "-" + monthAux + "-" + date
            this.today = today
        }

        if (month >= 10 && date >= 10) {
            today = year + "-" + month + "-" + date
            this.today = today
        }


        if (monthA < 10 && date < 10) {
            const monthAux = "0" + monthA
            const dateAux = "0" + date
            last = yearA + "-" + monthAux + "-" + dateAux
            this.last = last
        }

        if (monthA < 10 && date >= 10) {
            const monthAux = "0" + monthA
            last = yearA + "-" + monthAux + "-" + date
            this.last = last
        }

        if (monthA >= 10 && date >= 10) {
            last = yearA + "-" + monthA + "-" + date
            this.last = last
        }

        console.log("Fecha minima: " + this.today)
        console.log("Fecha máxima: " + this.last)


        this.handleInputChangeSelect = this.handleInputChangeSelect.bind(this)

    }



    componentDidMount() {
        this.getPorCumplirFiado(this.state.paginacion);
    }

    render(): JSX.Element {

        return (
            <><br/>
              <br/>
                <div className="col-12">
                    <div className="row seccion-botons" id="nShow-n-entries">
                        <div className="col-2">
                            <Select
                                isRequired
                                name="nEntries"
                                labelA11y=""
                                labelVisual={'Mostrar ' + this.state.paginacion.size + ' Registros'}
                                value={String(this.state.paginacion.size)}
                                onChange={this.handleInputChangeSelect}
                                placeholderOption="">
                                <SelectOption value="10"> 10 </SelectOption>
                                <SelectOption value="25"> 25 </SelectOption>
                                <SelectOption value="50"> 50 </SelectOption>
                                <SelectOption value="100"> 100 </SelectOption>
                            </Select>
                        </div>

                    </div>
                </div>
                {/* {this.state.listCompromisosPorAutorizar.length == 0 ? (
                    <div className="w-100"> <SpinnerLoading text={'Cargando Registros'} /> </div>
                ) : */}
                    <><Table id="demo-table" tableName=" ">
                        <thead>
                            {this.state.listCompromisosPorAutorizar.length != 0 ? (
                                <><br />
                                    <TableRow>
                                        <TableCell dataHeader="A" type="colHead">     </TableCell>
                                        <TableCell dataHeader="K" type="colHead">  {this.props.language.language?.tableEngagements.folio} </TableCell>
                                        {/* <TableCell dataHeader="A" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.rfc}  </TableCell>
                                        <TableCell dataHeader="B" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.nombre}  </TableCell> */}
                                        <TableCell dataHeader="C" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.tipo}  </TableCell>
                                        <TableCell dataHeader="D" type="colHead"> {this.props.language.language?.tableEngagements.producto}  </TableCell>
                                        <TableCell dataHeader="E" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.monto}  </TableCell>
                                        <TableCell dataHeader="E" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.condiciones}  </TableCell>
                                        <TableCell dataHeader="F" type="colHead"> {this.props.language.language?.tableEngagements.motivo}  </TableCell>
                                        <TableCell dataHeader="G" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.fecha} </TableCell>
                                        <TableCell dataHeader="H" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.renovaciones}  </TableCell>
                                        <TableCell dataHeader="I" type="colHead" className="extras"> {this.props.language.language?.tableEngagements.estatus} </TableCell>
                                        <TableCell dataHeader="J" type="colHead"> {this.props.language.language?.tableEngagements.acciones} </TableCell>
                                    </TableRow> </>
                            ) : <><br />
                                <TableRow>
                                    <TableCell dataHeader="A" type="colHead" >  </TableCell>
                                </TableRow>  </>}
                        </thead>
                        <tbody>
                            {this.state.listCompromisosPorAutorizar.length == 0 ? (
                                <><br />
                                    <TableRow>
                                        
                                        <TableCell dataHeader="" type="colHead" className="extras"> {`${this.props.language.language?.tableEngagements.sinRegistros}`} </TableCell>
                                
                                    </TableRow>
                                </>
                            ) : null}

                            {this.state.listCompromisosPorAutorizar.map((i, index) => <TableRow>
                                <TableCell dataHeader="A" type="rowHead"> <IconMyAccount size="24" /> </TableCell>
                                <TableCell dataHeader="K" type="rowHead" > {i.idCompromiso} </TableCell>
                               {/*  <TableCell dataHeader="A" type="rowHead" >{i.rfc}  </TableCell>
                                <TableCell dataHeader="Nombre"> {i.fiado}</TableCell>  */}
                                <TableCell dataHeader="Tipo" className="extras"> {i.descTipoCompromiso}  </TableCell>
                                <TableCell dataHeader="Producto">

                                    <Info title=" " data-tip={i.producto} data-for='getContent' size="16" className="prod"> </Info>
                                    <ReactTooltip id='getContent' className='custom-color-no-arrow'
                                        getContent={(dataTip) => `${this.props.language.language?.tableEngagements.producto}: ${dataTip}`} />

                                </TableCell>
                                <TableCell dataHeader="Monto" className="extras"> {formatCurrency(i.monto)} </TableCell>
                                <TableCell dataHeader="Condiciones">

                                    {i.condiciones == null ? (
                                        <>
                                            <Info title=" " data-tip={i.bloqueos.map((j, index) => j.descripcion)} data-for='getContent1' size="16" > </Info>
                                            <ReactTooltip id='getContent1' className='custom-color-no-arrow' getContent={(dataTip) => `${this.props.language.language?.tableEngagements.bloqueos}: ${dataTip}`} /></>
                                    ) : null}

                                    {i.condiciones != null ? (
                                        <>
                                            <Info title=" " data-tip={i.condiciones.map((j, index) => j.descripcion)} data-for='getContent2' size="16" className="cond"> </Info>
                                            <ReactTooltip id='getContent2' className='custom-color-no-arrow' getContent={(dataTip) => `${this.props.language.language?.tableEngagements.condiciones}: ${dataTip}`} /></>
                                    ) : null}

                                </TableCell>
                                <TableCell dataHeader="Motivo">

                                    <Info title=" " data-tip={i.motivo} data-for='getContent3' size="16" className="prod"> </Info>
                                    <ReactTooltip id='getContent3' className='custom-color-no-arrow' getContent={(dataTip) => `${this.props.language.language?.tableEngagements.motivo}: ${dataTip}`} />

                                </TableCell>
                                <TableCell dataHeader="Fecha" className="extras">{i.fechaComprometida}</TableCell>
                                <TableCell dataHeader="Renovacion" className="extras"> {i.numRenovaciones} </TableCell>
                                <TableCell dataHeader="Estatus" >
                                    <div className="row">
                                        
                                        {i.descStatusCompromiso == 'Vigente' ? (
                                            <div className={"col-8"}>
                                                <button type="button" className="btn btn-cumplido rounded-pill"> {i.descStatusCompromiso!}  </button>
                                            </div>
                                        ) : null}

                                        {i.descStatusCompromiso == 'Vencido' ? (
                                            <div className={"col-8"}>
                                                <button type="button" className="btn btn-vencido rounded-pill"> {i.descStatusCompromiso!}  </button>
                                            </div>
                                        ) : null}

                                        {i.descStatusCompromiso == 'Rechazado' ? (
                                            <div className={"col-8"}>
                                                <button type="button" className="btn btn-rechazado rounded-pill"> {i.descStatusCompromiso!}  </button>
                                            </div>
                                        ) : null}

                                        {i.descStatusCompromiso == 'Cumplido' ? (
                                            <div className={"col-8"}>
                                                <button type="button" className="btn btn-cumplido rounded-pill"> {i.descStatusCompromiso!}  </button>
                                            </div>
                                        ) : null}

                                        {i.descStatusCompromiso == 'Por Aceptar Fiado' ? (
                                            <div className={"col-8"}>
                                                <button type="button" className="btn btn-paf rounded-pill"> {i.descStatusCompromiso!}  </button>
                                            </div>
                                        ) : null}

                                        {i.descStatusCompromiso == 'Rechazado Por Fiado' ? (
                                            <div className={"col-8"}>
                                                <button type="button" className="btn btn-rpf rounded-pill"> {i.descStatusCompromiso!}  </button>
                                            </div>
                                        ) : null}
                                      

                                        {i.descStatusCompromiso == 'Revocado' ? (
                                            <div className={"col-8"}>
                                                <button type="button" className="btn btn-revocado rounded-pill"> {i.descStatusCompromiso!}  </button>
                                            </div>
                                        ) : null}


                                    </div>
                                </TableCell>
                                <TableCell dataHeader="Acciones" className="extras">
                                    <div className="row">
                                        <div className={"col-4"}>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" className="dropdown-more-options" id="dropdown-basic-2">
                                                    <IconSeeMore></IconSeeMore>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align='right'>
                                                {i.descStatusCompromiso == "Cumplido" ? ( 
                                                    <Dropdown.Item
                                                    href={"/revertir/" + i.idCompromiso + '/fiado/' + i.idFiado}> Revertir Cumplimiento   
                                                    </Dropdown.Item>
                                                ):null}

                                                {i.descStatusCompromiso == "Vigente" || i.descStatusCompromiso == "Vencido"  ? ( 
                                                    <Dropdown.Item
                                                       href={"/cumplimiento/" + i.idCompromiso + "/fiado/" + i.idFiado}>
                                                        {this.props.language.language?.tableEngagements.marcar}
                                                    </Dropdown.Item>
                                                ):null}
                                                    
                                                    <Dropdown.Item
                                                        href={"/compromisos/detail/" + i.idCompromiso + "/fiado/" + i.idFiado}> {this.props.language.language?.tableEngagements.verDetalles}</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>)}
                        </tbody>
                    </Table>
                        <div className="row pagination-sdgf">
                            <div className="col-md-5 show-registers">
                                Mostrando registros del&nbsp;
                                {(this.state.paginacion.currentPage * this.state.paginacion.size) - (this.state.paginacion.size - 1)} al&nbsp;
                                {(this.state.paginacion.currentPage * this.state.paginacion.size)} de un total de&nbsp;
                                {this.state.listCompromisosPorAutorizar.length} registros.
                            </div>
                            <div className="col-md-7 ">
                                <Pagination className="float-md-right" id="pagination-table-warranty">
                                    <Pagination.Prev
                                        onClick={() => {
                                            this.clickGoToPage((this.state.listPaginacion[0]) - 1);
                                        }} />
                                    {this.state.listPaginacion.map((i, index) => (<Pagination.Item
                                        key={i}
                                        active={i === this.state.paginacion.currentPage}
                                        onClick={() => {
                                            this.clickGoToPage(i);
                                        }}>
                                        {i}
                                    </Pagination.Item>

                                    ))}
                                    <Pagination.Next
                                        onClick={() => {
                                            this.clickGoToPage(this.state.listPaginacion[this.state.listPaginacion.length - 1] + 1);
                                        }} />

                                </Pagination>
                            </div>
                        </div>
                        <Modal
                            isOpen={this.state.openModalClose}
                            size="medium"
                            modalStyle="standard"
                            //title="Fiado"
                            //className="modalExito"
                            id="modal2"
                            closeFunc={() => {
                                this.setState({ openModalClose: false });
                            }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="col-md-4 alert">
                                        <SuccessAlert
                                            size="64"
                                            color="positive">
                                        </SuccessAlert>
                                    </div>

                                    <h2> {this.props.language.language?.createEngagement.operacionExitosa} </h2>

                                    <br />
                                    <Button href="/compromisos"
                                        variant="primary"
                                        className="btn-ok">
                                        {this.props.language.language?.createEngagement.aceptar}
                                    </Button>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            isOpen={this.state.openModalConfirmacion}
                            size="medium"
                            modalStyle="standard"
                            id="modal3"
                            closeFunc={() => {
                                this.setState({ openModalConfirmacion: false });
                            }}>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="col-md-4 info">
                                        <Info
                                            size="64"
                                            color="informative">
                                        </Info>
                                    </div>

                                    <h2 className="texto"> {this.props.language.language?.createEngagement.preguntaConfirmaCumplido} </h2>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Button onClick={() => {
                                                this.setState({ openModalConfirmacion: false });
                                            }}
                                                //href="/compromisos"
                                                variant="secondary"
                                                className="btn-cancel">
                                                {this.props.language.language?.createEngagement.cancelar}
                                            </Button>
                                        </div>
                                        <div className="col-md-6">
                                            <Button
                                                onClick={() => this.postCompromisoCumplido(this.state.idCompromiso)}
                                                //href="/compromisos"
                                                variant="primary">
                                                {this.props.language.language?.createEngagement.aceptar}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            isOpen={this.state.openModalFecha}
                            size="medium"
                            modalStyle="standard"
                            id="modal4"
                            closeFunc={() => {
                                this.setState({ openModalFecha: false });
                            }}>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="col-md-4 info">
                                        <Info
                                            size="64"
                                            color="informative">
                                        </Info>
                                    </div>

                                    <h2 className="texto"> {this.props.language.language?.createEngagement.seleccionaFecha} </h2>

                                    <div className="col-md-12">

                                        <input
                                            type="date"
                                            name="fechaComprometida"
                                            className={"input-sdgf"}
                                            id="fechaComprometida"
                                            //min={this.today}
                                            max={this.today}
                                            onChange={this.handleInputChangeDate}
                                        />
                                    </div>

                                    <br />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Button onClick={() => {
                                                this.setState({ openModalFecha: false });
                                            }}
                                                //href="/compromisos"
                                                variant="secondary"
                                                className="btn-cancel">
                                                {this.props.language.language?.createEngagement.cancelar}
                                            </Button>
                                        </div>
                                        <div className="col-md-6">
                                            <Button
                                                onClick={() => this.postCompromisoCumplido(this.state.idCompromiso)}
                                                //href="/compromisos"
                                                variant="primary">
                                                {this.props.language.language?.createEngagement.aceptar}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal></>
               {/*  } */}
            </>
        );

    }

    private clickGoToPage(page: number): void {

        const totalPages: number = this.state.paginacion.totalPages;
        if (page >= 1 && page <= totalPages) {
            const auxPaginacion = this.state.paginacion;
            auxPaginacion.currentPage = page;
            this.setState({ paginacion: auxPaginacion })

            this.getPorCumplirFiado(this.state.paginacion)
        }

    }

    handleInputChangeSelect(e: InputChange): void {
        //console.log(typeProfile)

        let auxPaginacion = this.state.paginacion;
        auxPaginacion.size = formatValue(e.target.value)
        auxPaginacion.currentPage = 1;
        this.getPorCumplirFiado(auxPaginacion)
        this.setState({ paginacion: auxPaginacion })

    }

    handleInputChangeDate(e: InputChange): void {

        const value = e.target.value;
        const date = value
        const day = date?.substring(8, 10)
        const month = date?.substring(5, 7)
        const year = date?.substring(0, 4)
        const mesActual = new Date().getMonth() + 1;

        console.log("Fecha seleccionada: " + value)
        //this.setState ({ openModalFecha: false })
        //this.setState({ openModalConfirmacion: true})
       
    }

    async verificaOpcion(idCompromiso: number): Promise<void> {

        console.log("Se seleccionó el compromiso: " + idCompromiso)
        this.setState({ openModalFecha: true })
        this.setState({ idCompromiso: idCompromiso })

    }

    async getPorCumplirFiado(paginacion: PaginacionModel): Promise<void> {

        const res = await engagementService.getPorCumplirFiado(paginacion, this.props.idFiado);

        const response: { paginacion: PaginacionModel, elementos: ElementosCompromiso[] } = res.data;
        response.paginacion.size = paginacion.size;
        this.setState({
            paginacion: response.paginacion,
            listCompromisosPorAutorizar: response.elementos,
            listPaginacion: loadPaginationList(res.data.paginacion)
        })


        for (const atributo of response.elementos) {

            let estatus: string;
            estatus = ''

            let tipo: string;
            tipo = ''

            if (atributo.statusCompromiso == statusCompromiso.PORAUTORIZAR) {
                estatus = 'Por Autorizar'
            } else if (atributo.statusCompromiso == statusCompromiso.VIGENTE) {
                estatus = 'Vigente'
            } else if (atributo.statusCompromiso == statusCompromiso.VENCIDO) {
                estatus = 'Vencido'
            } else if (atributo.statusCompromiso == statusCompromiso.RECHAZADO) {
                estatus = 'Rechazado'
            } else if (atributo.statusCompromiso == statusCompromiso.REVOCADO) {
                estatus = 'Revocado'
            } else if (atributo.statusCompromiso == statusCompromiso.CUMPLIDO) {
                estatus = 'Cumplido'
            } else if (atributo.statusCompromiso == statusCompromiso.PORACEPTARF) {
                estatus = 'Por Aceptar Fiado'
            } else if (atributo.statusCompromiso == statusCompromiso.RECHADOF) {
                estatus = 'Rechazado Por Fiado'
            } else if (atributo.statusCompromiso == statusCompromiso.AUTORIZAPLAZO) {
                estatus = 'Por Autorizar Plazo'
            }
            atributo.descStatusCompromiso = estatus

            if (atributo.tipoCompromiso == tiposCompromiso.PORAPOYO) {
                tipo = 'Apoyo'
            } else if (atributo.tipoCompromiso == tiposCompromiso.BLOQUEO) {
                tipo = 'Bloqueo'
            }

            atributo.descTipoCompromiso = tipo
        }
        this.setState({ listCompromisosPorAutorizar: response.elementos })

    }


    async postCompromisoCumplido(idCompromiso: number): Promise<void> {

        this.state.completedEngagementObject.idCompromiso = idCompromiso
        this.state.completedEngagementObject.idAccion = 6
        this.state.completedEngagementObject.fechaComprometida = ''
        this.state.completedEngagementObject.motivo = ''
        this.state.completedEngagementObject.dictamen = ''
        this.state.completedEngagementObject.nombreRep = ''
        this.state.completedEngagementObject.usuario = this.state.PERFIL.username
        this.setState ({ openModalFecha: false })

        try {
            const res = await engagementService.postCompromisoCumplido(this.state.completedEngagementObject);
            this.setState({ notificacion: res.data })
            this.setState({ openModalConfirmacion: false })

            if (this.state.notificacion.codigo == 1) {

                console.log('Marcado como Compromiso Cumplido ')
                this.setState({ openModalClose: true })


            } else {

                console.log("Algo ocurrió en el proceso")
            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log("Code Error:" + err.response.status)
                console.log("Error: " + err.response.statusText)

            }
        }
    }

}


export default TableCheckEngagementsByFiado;

interface Messages {
    msgTipo: string;
    msgCondicion: string;
    msgBloqueo: string;
    msgProducto: string;
    msgMonto: string;
    msgCorreo: string;
    msgFecha: string;
    msgMotivo: string;
}

export enum statusCompromiso {

    PORAUTORIZAR = 1,
    VIGENTE = 2,
    VENCIDO = 3,
    RECHAZADO = 4,
    REVOCADO = 5,
    CUMPLIDO = 6,
    PORACEPTARF = 7,
    RECHADOF = 8,
    AUTORIZAPLAZO = 9
}

export enum tiposCompromiso {

    PORAPOYO = 1,
    BLOQUEO = 2,

}