import React, { useEffect, useState} from 'react';
import {
    Button, Modal,
} from "@lmig/lmds-react";
import { useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {AxiosError} from "axios";

import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";

interface ModalDeleteOsProps {
    idOS: number;
    showModal: boolean;
    nameOs?: string;
    clickShowModal: (show: boolean, idPrincipal?: number) => void;
    deleted?: () => void;

}

export const ModalDeleteOs: React.FC<ModalDeleteOsProps> = ({idOS, showModal, nameOs, clickShowModal, deleted}: ModalDeleteOsProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language.language?.modalDeleteOs);

    const [sendingDelete, setSendingDelete] = useState<boolean>(false);


    const [descReason, setDescReason] = useState<string>('');

    const [errorLDeletePrincipal, setErrorDeletePrincipal] = useState<boolean>(true);

    const deleteOS = async(idOs: number) => {
        setSendingDelete(true);
        setErrorDeletePrincipal(false);

        try {
           // const res = await garantiasService.putBloqueaComisionCliente(idPrincipal, bloqueoCommission);
            setSendingDelete(false);
            clickShowModal!(false, idOS);
            deleted!();

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setSendingDelete(false);
                setErrorDeletePrincipal(true);

            }
        }
    }

    useEffect(() => {

    },[]);


    return (
        <>

            <Modal
                isOpen={showModal}
                size="medium"
                modalStyle="standard"
                title=""
                closeFunc={() => {
                    clickShowModal(false);
                }}
                id="modal-lock-commission-principal"
            >
                <div className="col-12 list-reject">
                    <h4>
                        {languageState?.deleteOS } &nbsp;
                        {nameOs}
                    </h4><br/>

                </div>

                <div className="row">
                    <div className="col-md-6 col-12">
                        <Button
                            dynamicWidth
                            variant="secondary"
                            onClick={()=>{
                                clickShowModal(false);
                            }}>
                            {languageState?.cancel!}
                        </Button>
                    </div>
                    <div className="col-md-6 col-12">
                        {!sendingDelete ? (
                                <Button
                                    dynamicWidth
                                    variant="primary"
                                    onClick={()=>{
                                        deleteOS(idOS);
                                    }}>
                                    {languageState?.delete!}
                                </Button>
                            ):
                            <ButtonSpinner label={languageState?.deleting!}/>
                        }

                    </div>

                </div>



            </Modal>
        </>
    );
};