import React, {useEffect, useState} from 'react';
import './ButtonSpinner.scss';
import {Button} from "@lmig/lmds-react";
import {Spinner} from "react-bootstrap";
interface ButtonSpinnerProps {
    label:string,

}

export const ButtonSpinner: React.FC<ButtonSpinnerProps> = ({ label }: ButtonSpinnerProps) => {


    return (
        <div id={"btn-spinner"}>
            <Button dynamicWidth
                    variant="primary"
            >
                <Spinner animation="border"
                         size={"sm"}
                         variant="primary" /> &nbsp;&nbsp;
                {label}
            </Button>
        </div>

    );
};

