import React, { useEffect, useState } from 'react';
import { Page, Image, Svg, Line, Text, Document, StyleSheet, Link } from '@react-pdf/renderer';
import { AxiosError } from 'axios';
import * as getCreditbureau from "../../services/buro-credito/CreditbureauService";
import logo from '../../assets/images/public/logo-web-liberty16.png';

const borderColor = '#CFD2CF';
const styles = StyleSheet.create({


    body: {
        paddingTop: 20,
        paddingBottom: 35,
        paddingHorizontal: 40,
    },
    title: {
        fontSize: 9,
        textAlign: "center",
        marginTop: 1,
    },
    text: {
        margin: 12,
        fontSize: 8.5,
        color: "black",
        opacity: 5,
        textAlign: "justify",
    },
    image: {
        marginVertical: 0,
        marginLeft: 10,
        maxWidth: 90,
        maxHeight: 90,
        marginBottom: 2,

    },
    negrita: {
        fontWeight: 'extrabold',
        color: "gray",
        margin: 10,
        fontSize: 8.5,
        textAlign: "justify",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 9,
        bottom: 50,
        left: 0,
        right: 5,
        marginRight: 50,
        textAlign: "right",
        color: "gray",

    },
    bajo: {
        fontSize: 8,
        textAlign: "center",
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: '#CFD2CF',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 20,
        fontStyle: 'bold',
        bottom: 25,
        margin: 1,

    },
    description: {
        width: '40%',
        flexDirection: 'row',
        height: 19,
        textAlign: 'left',
        fontSize: 8.5,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        borderLeftColor: borderColor,
        backgroundColor: '#99E5EA',
        borderLeftWidth: 1,
    },
    qty: {
        width: '50%',
        height: 20,
        fontSize: 8.5,
        borderRightWidth: 1,
        textAlign: 'center',
        borderRightColor: borderColor,
        paddingRight: 8,
    }, tabla1: {
        width: '20%',
        flexDirection: 'row',
        height: 19,
        textAlign: 'left',
        fontSize: 8.5,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        borderLeftColor: borderColor,
        backgroundColor: '#99E5EA',
        borderLeftWidth: 1,
    },
    tabla1qty: {
        width: '80%',
        height: 20,
        fontSize: 8.5,
        borderRightWidth: 1,
        textAlign: 'center',
        borderRightColor: borderColor,
        paddingRight: 8,
    }, header: {
        fontSize: 12,
        marginTop: 20,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    }, textheader: {
        fontSize: 8,
        marginTop: 10,
        marginLeft: 400,
        textAlign: 'center',
        color: 'black',
    }, textheader2: {
        fontSize: 9,
        marginTop: 2,
        marginLeft: 400,
        textAlign: 'center',
        color: 'gray',
    }, footer: {
        fontSize: 6,
        marginTop: 792,
        textAlign: 'center',
        marginLeft: 60,
        color: 'grey',
        position: "absolute",
    }, firma: {
        borderBottomColor: '#FFD000',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 20,
        width: 185,
        marginLeft: 162,
        bottom: 2,

    },


});


export const PDFMoralesAgent = () => {

    const [conta, setConta] = useState<boolean>(false);
    const [rfc, setRfc] = useState('');
    const [calle, setCalle] = useState('');
    const [cp, setCp] = useState('');
    const [muni, setMuni] = useState('');
    const [estado, setEstado] = useState('');
    const [numero, setNumeroext] = useState('');
    const [telefono, setTelefono] = useState('');
    const [numeroint, setNumeroInt] = useState('');
    const [colonia, setColonia] = useState('');
    const [folio, setFolio] = useState('');
    const [razonsocial, setRazonSocial] = useState('');
    const [representante, setRepresentante] = useState('');
    const [date, setDate] = useState('');







    async function getCredit() {

        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio');
            } else {
                var data = storedata.replace(/['"]+/g, '');
                var dt = data;
                const res = await getCreditbureau.getConsul(dt);
                const dato = (res.data);
                var objet = Object.values(dato)
                let date = new Date();
                let set = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
                console.log(objet)
                if (objet.length === 0) {
                    setConta(false)
                } else {
                    setConta(true)
                    if ({ conta: true && objet.length != 0 }) {
                        setDate(set)
                        setRfc(objet[1])
                        setCalle(objet[8])
                        setCp(objet[11])
                        setMuni(objet[9])
                        setEstado(objet[18])
                        setNumeroInt(objet[14])
                        setNumeroext(objet[13])
                        setTelefono(objet[15])
                        setFolio(dt);
                        setRazonSocial(objet[19])
                        setRepresentante(objet[20])
                    }
                }
            }

        } catch (error) {
            const err = error as AxiosError;
            if (err.response) {
                console.log(err.response.status);
                console.log(err.response.data);
            }
        }

    }




    useEffect(() => {
        getCredit();
    },[])




    return (
        <>
            {conta == true &&
                <>
                    <Document>
                        <Page size="A4" style={styles.body}>

                            <view style={styles.header} >
                                <Text style={styles.textheader2} fixed>PERSONA MORAL</Text>

                                <Text style={styles.textheader} fixed>No. de Folio   <Text style={styles.negrita}>{folio}  </Text></Text>


                                <Image style={styles.image} src={logo} fixed />


                                <Svg height="20" width="500" >
                                    <Line
                                        x1="0"
                                        y1="0"
                                        x2="600"
                                        y2="1"
                                        strokeWidth={5}
                                        stroke="rgb(255,208,0)"
                                    />
                                </Svg>
                            </view>
                            <Text style={styles.title} >AUTORIZACIÓN PARA SOLICITAR INFORMACIÓN CREDITICIA</Text>

                            <Text style={styles.text}>


                                <Text style={styles.negrita}>{razonsocial}  </Text>  representada por <Text style={styles.negrita}>{representante}  </Text>, autorizo expresamente a LIBERTY FIANZAS, S.A. DE C.V. para que por conducto de sus funcionarios facultados lleven a cabo investigaciones sobre el comportamiento crediticio de la empresa que represento en las Sociedades de Información Crediticia que estime conveniente. Así mismo, declaro que conozco la naturaleza y el alcance de la información que se solicitará, del uso que LIBERTY FIANZAS, S.A. DE C.V. hará de tal información, y de esta podrá realizar consultas periódicas de su historial crediticio, consintiendo que esta autorización se encuentra vigente por un periodo de tres años a partir de la fecha de expedición y en todo caso, durante el tiempo que mantenga relación jurídica con mi representada, bajo protesta de decir verdad manifiesto ser representante legal de la empresa mencionada en esta autorización.


                            </Text>

                            <Text style={styles.text} >
                                Para tal efecto cito los datos generales:{"\n"}{"\n"}
                            </Text>

                            <view style={styles.row}></view>
                            <view style={styles.row}>
                                <Text style={styles.tabla1}>Razón Social</Text>
                                <Text style={styles.tabla1qty}>{razonsocial}</Text>

                            </view>
                            <view style={styles.row}>
                                <Text style={styles.tabla1}>R.F.C.</Text>
                                <Text style={styles.tabla1qty}>{rfc}</Text>
                            </view>
                            <view style={styles.row}>
                                <Text style={styles.tabla1}>Representante Legal</Text>
                                <Text style={styles.tabla1qty}>{representante}</Text>
                            </view>
                            {/* segunda tabla */}
                            <view style={styles.row}></view>
                            <view style={styles.row}>
                                <Text style={styles.description}>Dirección</Text>
                                <Text style={styles.qty}>{calle}</Text>
                                <Text style={styles.description}>No.Ext.</Text>
                                <Text style={styles.qty}>{numero}</Text>

                            </view>
                            <view style={styles.row}>
                                <Text style={styles.description}>No. Int.</Text>
                                <Text style={styles.qty}>{numeroint}</Text>
                                <Text style={styles.description}>Ciudad o Población</Text>
                                <Text style={styles.qty}>{muni}</Text>
                            </view>

                            <view style={styles.row}>
                                <Text style={styles.description}>C.P.</Text>
                                <Text style={styles.qty}>{cp}</Text>
                                <Text style={styles.description}>Estado</Text>
                                <Text style={styles.qty}>{estado}</Text>
                            </view>

                            <view style={styles.row}>
                                <Text style={styles.description}>Teléfono</Text>
                                <Text style={styles.qty}>{telefono}</Text>
                                <Text style={styles.description}>Fecha autorización</Text>
                                <Text style={styles.qty}>{date}</Text>
                            </view>








                            <Text style={styles.text}>
                                Manifiesto absoluta conformidad y acepto que este documento sea propiedad de LIBERTY FIANZAS, S.A. DE C.V. y/o Sociedad de Información Crediticia Consultada,
                                para efecto de control y cumplimiento del Artículo 28 de la Ley para Regular a las Sociedades de Información Crediticia. {"\n"}{"\n"}
                                Anexo a esta autorización copia simple de la identificación oficial del que la suscribe, misma que tiene la firma que utilizo habitualmente para todos los documentos que firmo. {"\n"}{"\n"}{"\n"}
                                {"\n"}{"\n"}                        </Text>

                            <view style={styles.firma}></view>
                            <Text style={styles.bajo} > {representante}</Text>







                            <Text style={styles.pageNumber}
                                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
                            <Text style={styles.footer}>Liberty Fianzas, S.A. de C.V. autorizada para funcionar como institución de fianzas filial de Liberty Mutual Insurance Company, Institución Financiera del Exterior EEUU Paseo de la Reforma 350piso 7-A colonia Juarez , Cuauhtémoc, CDMX, 06600, 5551280700  <Link src='www.libertyfianzas.com' style={styles.footer}>www.libertyfianzas.com</Link></Text>
                        </Page>

                    </Document>
                    </>}
                </>

    )

}



