import React, {ChangeEvent, useEffect, useState} from 'react';
import {Alert, Dropdown, Spinner} from 'react-bootstrap';
import './UploadDragExpediente.scss';
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {TypeDocModel} from "../../../models/garantias/TypeDocModel";
import {ExpedienteIdetificacionModel} from "../../../models/garantias/ExpedienteIdetificacionModel";
import {
    getFileExtension,
    isAclaracionFirmasCSAM,
    MAXSIZEUPLOADFILE,
    nowDate,
    validFileImg
} from "../../../class/CommonClass";

interface UploadDragExpedienteProps {
    idCliente: number;
    tipoDocumento: TypeDocModel;
    postDocIdentification: (formData: FormData) => void;
    dropHtml:(idDestinity: number, idDocumento: number) => void;

}

export const UploadDragExpediente: React.FC<UploadDragExpedienteProps> = ({ idCliente,
                                                                              tipoDocumento,
                                                                              postDocIdentification,
                                                                                dropHtml}:UploadDragExpedienteProps)=> {

    const languageState = useSelector((state: RootStore) => state.language);

    const [dragActive, setDragActive] = useState(false);

    const [excedLimitFile, setExcedLimitFile] = useState<boolean>(false);

    // ref
    const inputRef = React.useRef<HTMLInputElement>(null);

    // triggers when file is selected with click
    const handleFile = (e: any) => {
        if (e && e.preventDefault) { // add?
            e.preventDefault();
            e.persist();
            console.log('target files ' + e);
            if (e.target.files && e.target.files[0]) {

                handleFile(e.target.files);
            }
        }

    }
    // handle drag events
    const handleDrag = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }
    // triggers when file is dropped
    const handleDrop = (e: React.DragEvent<HTMLDivElement | any> ) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        setExcedLimitFile(false);

        console.log(e)

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            console.log(e.dataTransfer.files);
            handleFile(e.dataTransfer.files);
            if (e.dataTransfer.files![0].size >= MAXSIZEUPLOADFILE){
                setExcedLimitFile(true);

            }else {
                console.log('correo ' + e.dataTransfer.files[0].name);
                const  nameFile= e.dataTransfer.files[0].name;
                const formData = new FormData();
                formData.append('idCliente', String(idCliente));
                formData.append('idTipoDocumento', String(tipoDocumento.id));
                formData.append('fileName', e.dataTransfer.files![0]);
                formData.append('fechaCarga', nowDate());
                if (getFileExtension(nameFile) =='pdf' ||
                    getFileExtension(nameFile) =='png' ||
                    getFileExtension(nameFile) =='jpeg' ||
                    getFileExtension(nameFile) =='jpg' ||
                    getFileExtension(nameFile) == 'msg' ||
                    getFileExtension(nameFile) == 'eml'
                ){
                    postDocIdentification(formData);
                }
            }
        }
        else {
            const idHTML = e.dataTransfer.getData("text");
            const idDoc: number = parseInt(idHTML.substr('id-doc-'.length, idHTML.length));
            console.log('mover el documento '+ idDoc + ' al ' + tipoDocumento.id);
            dropHtml(tipoDocumento.id, idDoc);


        }
    }
    // triggers when file is selected with click
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {

         if (e.target.files![0].size >= MAXSIZEUPLOADFILE){
                setExcedLimitFile(true)
            }else {
                 const formData = new FormData();
                 formData.append('idCliente', String(idCliente));
                 formData.append('idTipoDocumento', String(tipoDocumento.id));
                 formData.append('fileName', e.target.files![0]);
                 formData.append('fechaCarga', nowDate());
                 e.target.value = '';// vaciar el input file
                 postDocIdentification(formData);
            }

        }
    }
    const onButtonClick = () => {
        if (inputRef.current != null){
            inputRef.current!.click();
        }

    };

    useEffect(() => {

    },[]);


    return (
        <div className="w-100 drop-area " id="upload-drag-expedient">
            {(excedLimitFile)?
                <Alert variant="danger" onClose={() => setExcedLimitFile(false)} dismissible>
                    <Alert.Heading>  </Alert.Heading>
                    <p> {languageState.language?.modalFileMaxUpload.modalExcedLimitFile} {Math.round( MAXSIZEUPLOADFILE/1048576)} mb.</p>
                </Alert>:
                null
            }



            <form id="form-file-upload"
                  className="w-100 drop-area text-center"
                  onDragEnter={handleDrag}
                  onSubmit={(e) => e.preventDefault()}>
                {(isAclaracionFirmasCSAM(tipoDocumento.id)) ?
                    <input ref={inputRef}
                           type="file"
                           accept=".pdf,.png,.jpeg,.eml,.msg"
                           id="input-file-upload"
                           multiple={true}
                           onChange={handleChange} />:
                    <input ref={inputRef}
                           type="file"
                           accept=".pdf,.png,.jpeg"
                           id="input-file-upload"
                           multiple={true}
                           onChange={handleChange} />
                }

                <label id="label-file-upload"
                       htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                    <div>
                        <p>{languageState.language?.expedientIdentificaction.dragHere}</p>
                        <button className="upload-button" onClick={onButtonClick}>
                            Arrastrar el archivo de {tipoDocumento.nombre}
                        </button>
                    </div>
                </label>
                { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
            </form>
        </div>
    );
};