export interface TableEngagementsLanguage {
    
    folio:string
    rfc: string
    nombre: string
    tipo: string
    producto: string
    monto:string
    condiciones:string
    bloqueos: string
    motivo: string
    fecha: string
    renovaciones: string
    estatus: string
    sinRegistros: string
    solicitarRenovacion: string
    revertirCumplido: string
    solicitarRevocacion: string
    verDetalles: string
    autorizar: string
    acciones: string
    marcar: string
    porPlazo: string
    spinner: string
    //HISTORIAL
    fechaCreacion:string
    fechaCumplimiento: string
    fechaActualizacion: string
    usuario: string
    accionesH: string
    show: string;
    registers: string;
    search: string;
    showing: string;
    to: string;
    of: string;
    entries: string;
    
}

export const TableEngagmentsLanguageEnglish: TableEngagementsLanguage ={
    
    
    folio: 'Request ID',
    rfc: 'Tax ID',
    nombre: 'Name',
    tipo: 'Type',
    producto: 'Product',
    monto: 'Amount',
    condiciones: 'Conditions',
    bloqueos: 'Blocks',
    motivo: 'Subject',
    fecha: 'Due Date',
    renovaciones: 'Renewals',
    estatus: 'Status', 
    sinRegistros: 'No Compromises to View',
    solicitarRenovacion: 'Renewal',
    verDetalles: 'Detail',
    autorizar: 'Authorize',
    acciones: 'Options',
    marcar: 'Check Completed',
    fechaCreacion: 'Create Date',
    fechaActualizacion: 'Update Date',
    fechaCumplimiento: 'Due Date',
    usuario: 'User',
    accionesH: 'Actions',
    solicitarRevocacion: 'Revocation',
    porPlazo: 'Authorize by Date',
    revertirCumplido: 'Revert',
    spinner: 'Loading Information',
    show: 'Show',
    registers: 'Registers',
    search: 'Search',
    showing: 'Showing',
    to: 'to',
    of :'of',
    entries: 'entries'

   
}


export const TableEngagmentsLanguageSpanish: TableEngagementsLanguage ={
    
    
    folio: 'Folio',
    rfc: 'RFC',
    nombre: 'Razón Social',
    tipo: 'Tipo',
    producto: 'Producto',
    monto: 'Monto',
    condiciones: 'Condiciones',
    bloqueos: 'Bloqueos',
    motivo: 'Motivo',
    fecha: 'Fecha de Cumplimiento',
    renovaciones: 'Renovaciones',
    estatus: 'Estatus',
    sinRegistros: 'NO HAY REGISTROS POR MOSTRAR',
    solicitarRenovacion: 'Solicitar Renovación',
    verDetalles: 'Ver Detalles',
    autorizar: 'Autorizar',
    acciones: 'Acciones',
    marcar: 'Marcar como Cumplido',
    fechaActualizacion: 'Fecha de Actualización',
    fechaCreacion: 'Fecha de Creación',
    fechaCumplimiento: 'Fecha de Cumplimiento',
    usuario: 'Usuario',
    accionesH: 'Acciones',
    solicitarRevocacion: 'Revocación',
    porPlazo: 'Autorizar Plazo',
    revertirCumplido: 'Revertir Cumplimiento',
    spinner: 'Cargando Información',
    show: 'Mostrar',
    registers: 'Registros',
    search: 'Buscar',
    showing: 'Mostrando registros del',
    to: 'al',
    of: 'de un total de',
    entries: 'registros'

}