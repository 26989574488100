import React, {Component} from "react";
import './PrincipalDetail.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import {Accordion, Card, Button as ButtonBootstrap} from "react-bootstrap";
import {Link, RouteComponentProps} from "react-router-dom";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {BreadCumb} from "../../../components/public/breadcumb/BreadCumb";
import {TableLoansPrincipal} from "../../../components/garantias/table-loans-principal/TableLoansPrincipal";

import {validRoles} from "../../../class/CommonClass";
import {ExpedientIdentification} from "../../../components/garantias/expedient-identification/ExpedientIdentification";
import {RootStore} from "../../../Store";
import {connect} from "react-redux";
import {DefaultStateI} from "../../../reducer/LanguageReducer";
import {InfoPrincipal} from "../../../components/garantias/info-principal/InfoPrincipal";
import {UserModel} from "../../../models/public/UserModel";
import ComentariosFiado from "../../../components/garantias/comentarios-fiado/ComentariosFiado";
import  $ from "jquery";
import {FaComments, FaPlus} from "react-icons/fa";
import {PrincipalStatistics} from "../../../components/garantias/principal-statistics/PrincipalStatistics";
import {ResumeGarantias} from "../../../components/garantias/resume-garantias/ResumeGarantias";
import {PrincipalActions} from "../../../components/garantias/principal-actions/PrincipalActions";
import {LineaDeAfianzamiento} from "../../../components/garantias/linea-de-afianzamiento/LineaDeAfianzamiento";
import {AcordionPrincipal} from "../../../models/garantias/AcordionPrincipal";
import {IconAddItem, Button} from "@lmig/lmds-react";
import {PillButton} from "@lmig/lmds-react-pill-button";
interface CreditDetailProps {
    id: number;
    language: DefaultStateI
}
interface Params {
    idPrincipal: string;
}

interface LocationState {
    ownerName: string;
}

// Static Context is available when you use Static Router*
interface SC {
    statusCode?: number;
}
class PrincipalDetail extends Component<CreditDetailProps & RouteComponentProps<Params, SC, LocationState>, PrincipalDetailState> {

    public localStorageClass = new LocalStorageClass();

    public initAcordion: AcordionPrincipal= {
        resumen: false,
        lineaAfianzamiento: false,
        garantias: true
    }

    constructor(props: any) {
        super(props);
        document.title = "Fiado " + this.props.match.params.idPrincipal;
        this.state = {
            count: 0,
            PERFIL:  this.localStorageClass.getProfile(),
            loadingCifras: false,
            idPrincipal: parseInt(this.props.match.params.idPrincipal),
            errorGetCifrasFiado: false,
            openComentario: false,
            acordion: this.initAcordion
        }

    }

    render(): JSX.Element {
        return <>
             <NavBar/>
            <div className="container-fluid">
        
                <div className="principal-detail">
                    <BreadCumb title={this.props.language.language?.principalDetail.title!}
                               listBreadcumbs={[
                                   {title: this.props.language.language?.principalDetail.home!, url:'/garantias/'},
                                   {title: this.props.language.language?.principalDetail.title!, url:this.props.location.pathname}]}/>

                    <div className="row" id="header-principal">

                        {(validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) ||
                            validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA))?
                            (<div className="col-md-12 text-right"
                                  onClick={e =>
                                      this.setState({openComentario: !this.state.openComentario})}>
                                <b className="comments">
                                    {this.props.language.language?.principalDetail.comments}&nbsp;
                                    <FaComments />
                                </b><br/>
                            </div>) : null
                        }

                        <div className="col-md-12" id="seccion-principal">
                            <div className="row">
                                <div className="col-md-12 col-lg-6">

                                    <div className="row">
                                        {(validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) ||
                                            validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR)) &&
                                        validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR) ?
                                            <></> :
                                            <div className="col-md-4">
                                                <ExpedientIdentification
                                                    idPrincipal={this.state.idPrincipal}
                                                    showButtons={false}
                                                />
                                            </div>
                                        }

                                        <div className="col-md-8">
                                            <InfoPrincipal
                                                idPrincipal={this.state.idPrincipal}
                                                label={this.props.language.language?.principalDetail.fiado!}
                                                openObligors={true}
                                                addOS={true}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <PrincipalActions idPrincipal={this.state.idPrincipal}/>

                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-12 col-lg-6">
                                    <PrincipalStatistics idPrincipal={this.state.idPrincipal!}/>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-3" id="comentarios-principal">
                            <ComentariosFiado idFiado={this.state.idPrincipal}
                                              openComentario={this.state.openComentario}/>

                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-9">
                        </div>
                        <div className="col-md-3">


                            {((validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE)  ||
                                validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR) ||
                                validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO)  ||
                                validRoles(this.state.PERFIL.roles, this.localStorageClass.AUXILIAR_JURIDICO)
                            )) ?
                                null
                                :
                                <Link
                                    title={this.props.language.language?.principalDetail.createWarranty}
                                    to={"/garantias/crear-garantia/fiado/" + this.state.idPrincipal }>

                                    <Button
                                        dynamicWidth
                                        className="w-100"
                                        variant="primary"
                                        id="create-line"
                                    >
                                        <IconAddItem size="16"
                                                     title={this.props.language.language?.principalDetail.createWarranty}
                                                     color="blue"/>
                                        &nbsp;
                                        {this.props.language.language?.principalDetail.createWarranty}
                                    </Button>

                                </Link>
                            }


                            <br/>
                        </div>
                    </div>

                    <Accordion defaultActiveKey="2">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={ButtonBootstrap}  variant="link" eventKey="0"
                                                  onClick={event => {this.onClickShowResume()}}>
                                    {this.props.language.language?.principalDetail.resume}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>

                                    {(this.state.acordion.resumen == true)&&
                                        <div className="col-md-12 acordion-content" id="acordion-resumen">
                                            <ResumeGarantias idPrincipal={this.state.idPrincipal} />
                                        </div>
                                    }

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={ButtonBootstrap} variant="link" eventKey="1"
                                                  onClick={event => {this.onClickLinea()}}>
                                    {this.props.language.language?.principalDetail.tableLineas}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    {(this.state.acordion.lineaAfianzamiento == true)&&
                                        <div className="col-md-12 acordion-content" id="acordion-linea">
                                            <LineaDeAfianzamiento idPrincipal={this.state.idPrincipal}/>
                                        </div>
                                    }


                                </Card.Body>
                            </Accordion.Collapse>

                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={ButtonBootstrap} variant="link" eventKey="2"
                                                  onClick={event => {this.onClickGarantia()}}>
                                    {this.props.language.language?.principalDetail.garantias}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    {(this.state.acordion.garantias == true)&&
                                        <div className="col-md-12 acordion-content" id="acordion-garantias">
                                            <TableLoansPrincipal
                                                showFiado={false}
                                                idPrincipal={this.state.idPrincipal}

                                            />
                                        </div>
                                    }
                                </Card.Body>
                            </Accordion.Collapse>

                        </Card>
                    </Accordion>

                </div>
            </div>



        </>
    }

    componentDidMount(){
        let infoPrincipal = $('#seccion-principal').width();

        let show = false;
        $(".comments").click(function(){
            console.log(infoPrincipal)
            //$( '#seccion-principal' ).toggle("slow");

            $( "#comentarios-principal" ).toggle({duration:"slow",
                start(animation: JQuery.Animation<HTMLElement>) {
                    if (!show){
                        show = true;
                        $('#comentarios-fiado').show();
                        $( '#seccion-principal').removeClass("col-md-12");
                        $( '#seccion-principal').addClass("col-md-9");


                    }else {
                        show = false
                        $('#comentarios-fiado').hide();
                        $( '#seccion-principal').addClass( "col-md-12" );
                        $( '#seccion-principal').removeClass( "col-md-9" );

                 /*       $('.principal-detail').css({ "padding-left": "7%"});
                        $('.principal-detail').css({ "padding-right": "7%"});*/
                    }

                },

            });

        });


    }

    componentDidUpdate(prevProps: CreditDetailProps & RouteComponentProps<Params, SC, LocationState>) {
        //  console.log('componentDidUpdate')

        if (prevProps.match.params.idPrincipal !== this.props.match.params.idPrincipal) {
            console.log(prevProps.match.params.idPrincipal )
            console.log(this.props.match.params.idPrincipal)
            const idPrincipalAux = parseInt(this.props.match.params.idPrincipal);
            //alert(idPrincipalAux);
            this.setState({idPrincipal: idPrincipalAux});
        }
    }

    onClickShowResume(){
        const show = !this.state.acordion.resumen;

        this.setState({acordion: {resumen: show, lineaAfianzamiento: false, garantias: false}})
    }

    onClickLinea(){
        const show = !this.state.acordion.lineaAfianzamiento;

        this.setState({acordion: {resumen: false, lineaAfianzamiento: show, garantias: false}})
    }

    onClickGarantia(){
        const show = !this.state.acordion.garantias;

        this.setState({acordion: {resumen: false, lineaAfianzamiento: false, garantias: show}})
    }

}

const mapStateToProps = (state: RootStore)  => ({
    language: state.language
});

export default connect(mapStateToProps) (PrincipalDetail);

interface PrincipalDetailState {
    count: number;
    PERFIL: UserModel;
    loadingCifras: boolean;
    idPrincipal: number;
    errorGetCifrasFiado: boolean;
    openComentario: boolean;
    acordion: AcordionPrincipal;
}