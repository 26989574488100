
import React, { ChangeEvent, Component } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { DefaultStateI } from '../../../reducer/LanguageReducer';
import { RootStore } from '../../../Store';
import NavBar from '../../../components/public/nav-bar/NavBar';
import Form from '@lmig/lmds-react/Form/Form';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import Select from '@lmig/lmds-react/Form/Select/Select';
import { Alert, AlphanumericInput, Badge, Button, GridCol, GridRow,  IconDocumentMulti, IconDownload, IconSuccessAlert, Modal, SelectOption, Table, TableCell, TableRow, Textarea } from '@lmig/lmds-react';
import './Revision-Solicitud.scss'
import { BreadCumb } from '../../../components/public/breadcumb/BreadCumb';
import { Entidad } from '../../../models/buro-credito/entidad';
import { AxiosError } from 'axios';
import * as getCreditbureau from "../../../services/buro-credito/CreditbureauService";
import * as apiS3Services from "../../../services/api-s3/ApiS3Services";
import * as consultaBuroServices from "../../../services/consultar-buro/ConsultarBuroServices";
import { ConsultaBuro } from '../../../models/buro-credito/ConsultaBuro';
import { Fisicas } from '../../../models/buro-credito/Fisicas';
import { Morales } from '../../../models/buro-credito/Morales';
import { initPaginacion,  validFilepdf } from '../../../class/CommonClass';
import { SpinnerLoading } from '../../../components/public/spinner-loading/SpinnerLoading';
import { PaginacionModel } from '../../../models/public/PaginacionModel';
import { PipeLineDictament } from '../../../components/public/pipeline-steps/PipelineDictament';
import { HiOutlinePencil } from "react-icons/hi";
import { DelegacionMunicipio, estado } from '../../../models/buro-credito/cp';
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import { PdfFile } from '../../../models/buro-credito/PdfFile';
import { foliosVigent } from '../../../models/buro-credito/Folios_vigentes';
import { UserModel } from '../../../models/public/UserModel';



const DEFAULT_INPUT_TEXT = "";

export type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>;

interface ConsultProssProps {
    language: DefaultStateI
}
interface Params {
    folios: string;
}



class Enrevision extends Component<ConsultProssProps & RouteComponentProps<Params>, ConsultProssState> {

    public localStorageClass = new LocalStorageClass();
    public select = document.getElementById('Persona');
    public initPDF: PdfFile = {
        carpeta: '',
        nombreArchivo: ''
    }
    options: any;
    public folios: number;
    public initConsult: ConsultaBuro = {
        nombre: '',
        tipoEntidad: '',
        tipoPersona: '',
        rfc: '',
        segundoNombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        fechaNacimiento: '',
        sexo: '',
        codigoPostal: '',
        calle: '',
        colonia: '',
        delegacionMunicipio: '',
        idDireccion: '',
        numexterior: '',
        folio: '',
        estado: '',
        estadoRepublica: '',
        numinterior: '',
        telefono: '',
        estadoDomicilio: '',
        razonSocial: '',
        representante: '',
        codigoBuro: ''
    }
    constructor(props: any) {
        super(props);
        const est: estado = {

            estado: "",
            codigoBuro: "",
            delegacionMunicipio: [
                {
                    nombre: "",

                }
            ]
        };



        this.state = {
            estadoCaptura: 1,
            select: '',
            status: '1',
            validador: 1,
            entidad: [],
            datovalid: '',
            folio: '',
            PDFObject: this.initPDF,
            text: DEFAULT_INPUT_TEXT,
            consultObject: this.initConsult,
            estados: est,
            datofisica: [],
            datoMoral: [],
            mensajeM: '',
            mensajeF: '',
            isOpen: false,
            disable: false,
            Loading: true,
            comentario: '',
            errorBURODetail: false,
            formData: new FormData(),
            FormFisicas: new FormData(),
            validafile: 0,
            paginacion: initPaginacion,
            loadingConsult: true,
            listPaginacion: [],
            fechaAutoAux:'',
            table: 0,
            consulta: true,
            isClose: false,
            PERFIL: this.localStorageClass.getProfile(),
            mensajeConsult: [],
            search: '',
            Consul_buro: false,
            Datos_consult: {
                folio: '',
                colonia: '',
                nombre: '',
                tipoEntidad: '',
                tipoPersona: '',
                rfc: '',
                estadoRepublica: '',
                segundoNombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                fechaNacimiento: '',
                sexo: '',
                codigoPostal: '',
                calle: '',
                estado: '',
                numinterior: '',
                delegacionMunicipio: '',
                numexterior: '',
                telefono: '',
                estadoDomicilio: '',
                razonSocial: '',
                representante: '',
                codigoBuro: ''
            }
        }
        this.folios = parseInt(this.props.match.params.folios);


        this.NextPage = this.NextPage.bind(this);
        this.PrevPage = this.PrevPage.bind(this);
        this.downl = this.downl.bind(this);
        this.Submit = this.Submit.bind(this);
        this.getMorales = this.getMorales.bind(this);
        this.getfisicas = this.getfisicas.bind(this);
        this.getDatos = this.getDatos.bind(this);
        this.rechazar = this.rechazar.bind(this);
        this.autorizar = this.autorizar.bind(this);
        this.handlemensaje = this.handlemensaje.bind(this);
        this.autoriza = this.autoriza.bind(this);
        this.rechazada = this.rechazada.bind(this);
        this.rechazar = this.rechazar.bind(this);
        this.autorizar = this.autorizar.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    changeText(e: { target: { value: any; }; }) {
        let text = e.target.value;

        this.setState({
            text,
        });

        /*
         * This will update the value that the confirm
         * button resolves to:
         */
        Swal.getActions();
    }

    render(): JSX.Element {
        return <>
         <NavBar />
            <div className="container-fluid warranty" id="consult-query">
               
                <div className="consult">

                    <BreadCumb title={'En revision'}
                        listBreadcumbs={[
                            { title: this.props.language.language?.createquery.Home!, url: '/buro-credito/Solicitudes-consulta' },
                            { title: this.props.language.language?.createquery.consult!, url: '/buro-credito/consultar/enrevision' }
                        ]} />



                    <div className="row" id="header-consult">

                        <div className="container">

                            <div className="Pipeline col-md-12 col-sm-12" id='pipeline-2'>
                                <PipeLineDictament
                                    listSteps={[this.props.language.language?.createquery.pipeline1!,
                                    this.props.language.language?.createquery.pipeline2!,
                                    this.props.language.language?.createquery.pipeline3!,
                                    this.props.language.language?.createquery.pipeline4!]}
                                    currentStep={this.state.estadoCaptura! - 1} />


                            </div>
                        </div>

                        <div className="Formulario" id='formulario-consult'>


                            <>
                                {this.state.isClose === true ? (
                                    <Alert highlightType="negative">
                                        Este RFC ya cuenta con consultas vigentes
                                    </Alert>
                                ) : null}
                                <Form id="form-consult" >
                                    {this.state.estadoCaptura === 1 ? (
                                        <>
                                            <div className="col-md-12" id='consult'>
                                                <h3>{[this.props.language.language?.createquery.data!]}</h3>
                                                <hr />

                                                <div className='col-12'>
                                                    <p>{[this.props.language.language?.createquery.entity!]}</p>
                                                    <Select
                                                        id="select--default"
                                                        isRequired
                                                        placeholderOption="Prueba"
                                                        labelVisual={`${this.state.Datos_consult.tipoEntidad} `}
                                                        disabled

                                                    >

                                                    </Select>
                                                    <br />
                                                    <p>{[this.props.language.language?.createquery.person!]}</p>
                                                    <Select
                                                        id="Persona"
                                                        name='persona'
                                                        isRequired
                                                        labelVisual={this.state.Datos_consult.tipoPersona}
                                                        onChange={this.handleinputChange}
                                                        disabled

                                                    >


                                                    </Select>
                                                </div>
                                                <br />
                                                <>

                                                    <div className="col-md-12" id='consult'>
                                                        <h3>{[this.props.language.language?.createquery.persondata!]}</h3>
                                                        <hr />


                                                        <div className='col-12'>
                                                            {this.state.Datos_consult.tipoPersona === 'Fisica' &&
                                                                <>
                                                                    <p>RFC</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        name='rfc'
                                                                        labelVisual=""
                                                                        value={this.state.Datos_consult.rfc}
                                                                        onChange={this.handleinputChange} disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.name!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        name='name'
                                                                        labelVisual=""
                                                                        value={this.state.Datos_consult.nombre}
                                                                        onChange={this.handleinputChange} disabled />


                                                                    <p>{[this.props.language.language?.createquery.second!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        name='segundoNombre'
                                                                        labelVisual=""
                                                                        value={this.state.Datos_consult.segundoNombre}
                                                                        onChange={this.handleinputChange} disabled />

                                                                    <p>{[this.props.language.language?.createquery.last!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        name='apellidoPaterno'
                                                                        labelVisual=""
                                                                        value={this.state.Datos_consult.apellidoPaterno}
                                                                        onChange={this.handleinputChange} disabled />

                                                                    <p>{[this.props.language.language?.createquery.mother!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual=""
                                                                        name='apellidoMaterno'
                                                                        value={this.state.Datos_consult.apellidoMaterno}
                                                                        onChange={this.handleinputChange} disabled />

                                                                    <p>{[this.props.language.language?.createquery.date!]}</p>
                                                                    <input
                                                                        type='date'
                                                                        id="fechaNacimiento"
                                                                        name='fechaNacimiento'
                                                                        className='form-control'
                                                                        min="1936-01-01" max="2005-12-31"
                                                                        value={this.state.Datos_consult.fechaNacimiento}
                                                                        onChange={this.handleinputChange}
                                                                        onBlur={this.handleinputChange}
                                                                        disabled
                                                                    />



                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.telefono!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual=""
                                                                        value={this.state.Datos_consult.telefono}
                                                                        onChange={this.handleinputChange} disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.sex!]}</p>
                                                                    <Select
                                                                        id="select--default"
                                                                        isRequired
                                                                        labelVisual={this.state.Datos_consult.sexo}
                                                                        onChange={function placeholderOptionOnChange() { }}
                                                                        disabled
                                                                    >

                                                                    </Select>
                                                                </>}

                                                            {this.state.Datos_consult.tipoPersona === 'Moral' &&
                                                                <>

                                                                    <p>RFC</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual
                                                                        value={this.state.Datos_consult.rfc}
                                                                        onChange={this.handleinputChange} disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.razonsocial!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual
                                                                        value={this.state.Datos_consult.razonSocial}
                                                                        onChange={this.handleinputChange} disabled />
                                                                    <br />
                                                                    <p>{[this.props.language.language?.createquery.representante!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual
                                                                        value={this.state.Datos_consult.representante}
                                                                        onChange={this.handleinputChange} disabled />
                                                                    <br />


                                                                    <p>{[this.props.language.language?.createquery.telefono!]}</p>
                                                                    <AlphanumericInput
                                                                        id="Alphanumeric--default"
                                                                        labelVisual
                                                                        value={this.state.Datos_consult.telefono}
                                                                        onChange={this.handleinputChange} disabled />

                                                                </>}
                                                        </div>
                                                    </div><br />
                                                    <div className="col-md-12" id='consult'>
                                                        <h3>{[this.props.language.language?.createquery.residence!]}</h3>
                                                        <hr />


                                                        <div className='col-12'>

                                                            <p>C.P</p>

                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                name='codigoPostal'
                                                                labelVisual
                                                                value={this.state.Datos_consult.codigoPostal}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} disabled />


                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.state!]}</p>


                                                            <input
                                                                id="estadoRepublica"
                                                                name='estadoRepublica'
                                                                className='form-control'
                                                                onChange={this.handleinputChange}
                                                                placeholder={this.state.Datos_consult.estadoDomicilio}
                                                                value={this.state.estados?.estado}
                                                                disabled />


                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.town!]}</p>
                                                            <Select
                                                                id="delegacionMunicipio"
                                                                name='delegacionMunicipio'
                                                                onChange={this.handleinputChange}
                                                                labelVisual={this.state.Datos_consult.delegacionMunicipio}
                                                                value={this.state.consultObject.delegacionMunicipio}
                                                                disabled
                                                            >

                                                                <SelectOption value="0">
                                                                    {this.props.language.language?.createquery.select!}
                                                                </SelectOption>
                                                                {this.state.estados.delegacionMunicipio.map((i: DelegacionMunicipio, index: number) => (
                                                                    <SelectOption value={i.nombre.toString()} key={index}>
                                                                        {i.nombre}
                                                                    </SelectOption>
                                                                ))}


                                                            </Select>

                                                            <br />




                                                            <p>{[this.props.language.language?.createquery.street!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                labelVisual
                                                                value={this.state.Datos_consult.calle}
                                                                onChange={this.handleinputChange} disabled />
                                                            <p>{[this.props.language.language?.createquery.number!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                labelVisual
                                                                value={this.state.Datos_consult.numexterior}
                                                                onChange={this.handleinputChange} disabled />

                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.numint!]}</p>
                                                            <AlphanumericInput
                                                                id="Alphanumeric--default"
                                                                labelVisual
                                                                value={this.state.Datos_consult.numinterior}
                                                                onChange={this.handleinputChange} disabled />


                                                            <GridRow gutterSize="fixed-16" gutters>

                                                                <GridCol base={12} md={12}>
                                                                    <Button dynamicWidth variant="primary" onClick={this.NextPage}>
                                                                        {this.props.language.language?.createquery.NextPage!}
                                                                    </Button>
                                                                </GridCol>
                                                            </GridRow>
                                                        </div>

                                                    </div></>

                                            </div>



                                        </>) : null}
                                    {this.state.estadoCaptura === 2 ? (
                                        <>
                                            <div className="col-12" onClick={this.downl}>
                                                <div className="card" id='cards'>
                                                    <div className="card-body text-center"><br /><br />
                                                        <h3 className="card-title">{this.props.language.language?.createquery.format!}</h3><br />
                                                        <div className="icon-wrapper">
                                                            <IconDownload size="64" color='gray' />
                                                        </div>
                                                        {this.props.language.language?.createquery.downl!}
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>

                                            <br /><br />
                                            <GridRow gutterSize="fixed-16" gutters>
                                                <GridCol base={12} md={6}>
                                                    <Button dynamicWidth onClick={this.PrevPage}>
                                                        {this.props.language.language?.createquery.PrevPage!}
                                                    </Button>
                                                </GridCol>
                                                <GridCol base={12} md={6}>
                                                    <Button dynamicWidth variant="primary" onClick={this.NextPage}>
                                                        {this.props.language.language?.createquery.NextPage!}
                                                    </Button>
                                                </GridCol>
                                            </GridRow>
                                        </>

                                    ) : null}
                                    {this.state.estadoCaptura === 3 ? (
                                        <>
                                            <div className="col-12">
                                                <div className="card" id='card'>
                                                    <div className="card-body text-center"><br /><br />
                                                        <h5 className="card-title"><IconDocumentMulti size="64" /><br /><br /><h5>Autorización de Consulta <br /> Con folio {this.state.folio} </h5>
                                                        </h5>
                                                        <div id='badge'>
                                                            <Badge highlightType="informative">
                                                                En revisión
                                                            </Badge>
                                                        </div>
                                                        <br />
                                                        <div id='card-cards'>
                                                            <p><HiOutlinePencil size={24} />&nbsp;Información general del fiado</p>
                                                            <hr />
                                                            <p> <IconSuccessAlert color="positive" size="16" />&nbsp;Documentación</p>

                                                        </div><br />
                                                        <button className="btn btn-danger" onClick={this.rechazar}>
                                                            Rechazar
                                                        </button> &nbsp;
                                                        <button className='btn btn-success' onClick={this.Submit}>
                                                            Autorizar
                                                        </button>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                            <Modal className='Modal-rechazas' isOpen={this.state.isOpen} size="medium"
                                                modalStyle="standard"
                                                closeFunc={() => {
                                                    this.setState({ isOpen: false });
                                                }}
                                            >
                                                <ModalBody>
                                                    <h3> Rechazar consulta</h3>
                                                    <p>¿Estas seguro que quieres rechazar la consulta?</p><br />
                                                    Escribe una observación:
                                                    <Alert highlightType="negative">
                                                        Comentario obligatorio
                                                    </Alert>
                                                    <Textarea name='comentario' value={this.state.comentario} onChange={this.handlemensaje} onBlurCapture={this.handlemensaje} rows={4} cols={40}>

                                                    </Textarea>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button size="large" onClick={this.cancel}>
                                                        Cancelar
                                                    </Button>
                                                    <Button size="large" variant="primary" disabled={this.state.consulta} onClick={this.rechazada}>
                                                        Enviar
                                                    </Button>

                                                </ModalFooter>
                                            </Modal>
                                            <br />
                                            <GridRow gutterSize="fixed-16" gutters>
                                                <GridCol >
                                                    <Button dynamicWidth onClick={this.PrevPage}>
                                                        {this.props.language.language?.createquery.PrevPage!}
                                                    </Button>
                                                </GridCol>

                                            </GridRow>
                                        </>

                                    ) : null}
                                    {this.state.estadoCaptura === 4 ? (
                                        <>

                                            {this.state.Datos_consult.tipoPersona === 'Fisica' &&
                                                <>
                                                    <h3>{this.props.language.language?.createquery.detallef!}</h3>
                                                    <br />
                                                    {this.state.errorBURODetail ? (
                                                        <div className="col-12">
                                                            <Alert id='alert_up' highlightType="negative">
                                                                {this.state.mensajeF}
                                                            </Alert>
                                                        </div>
                                                    ) :
                                                        <>
                                                            {this.state.Consul_buro === true ? (
                                                                <div className="col-12">
                                                                    <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                                                </div>
                                                            ) :

                                                                <>

                                                                    {this.state.table === 1 &&
                                                                        <div className="tabla" id='tabla'>

                                                                            <Table id="table-credit">
                                                                                <thead>
                                                                                    <TableRow>
                                                                                        <TableCell dataHeader="A" type="colHead">
                                                                                            {this.props.language.language?.createquery.credit!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="B" type="colHead">
                                                                                            {this.props.language.language?.createquery.grantor!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="C" type="colHead">
                                                                                            {this.props.language.language?.createquery.saldo!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="D" type="colHead">
                                                                                            {this.props.language.language?.createquery.amount!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="E" type="colHead">
                                                                                            {this.props.language.language?.createquery.method!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="F" type="colHead">
                                                                                            {this.props.language.language?.createquery.max!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="G" type="colHead">
                                                                                            {this.props.language.language?.createquery.update!}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </thead>
                                                                                {this.state.datofisica.map((value: Fisicas, index: number) => (

                                                                                    <>
                                                                                        <tbody>
                                                                                            <TableRow key={index}>
                                                                                                <TableCell dataHeader="A" type="rowHead">
                                                                                                    {value.tipoCredito}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.tipoOtorgante}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.saldoActual}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.monto}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.formatoPago}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.maxMorosMopFechaImporte}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.ultimaActualizacion}
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                            <TableRow>

                                                                                            </TableRow>
                                                                                        </tbody>
                                                                                    </>
                                                                                ))}
                                                                            </Table>
                                                                        </div>
                                                                    }</>} </>}
                                                </>}
                                            {this.state.Datos_consult.tipoPersona === 'Moral' &&
                                                <>
                                                    <h3>{this.props.language.language?.createquery.detalle!}</h3>
                                                    <br />
                                                    {this.state.errorBURODetail ? (
                                                        <div className="col-12">
                                                            <Alert id='alert_up' highlightType="negative">
                                                                {this.state.mensajeM}
                                                            </Alert>
                                                        </div>
                                                    ) :
                                                        <>
                                                            {this.state.Consul_buro === true ? (
                                                                <div className="col-12">
                                                                    <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                                                </div>
                                                            ) :

                                                                <>

                                                                    {this.state.table === 1 &&
                                                                        <Table id="table-credit">
                                                                            <thead>
                                                                                <TableRow>
                                                                                    <TableCell dataHeader="A" type="colHead">
                                                                                        {this.props.language.language?.createquery.contrato!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="B" type="colHead">
                                                                                        {this.props.language.language?.createquery.tipooto!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="C" type="colHead">
                                                                                        {this.props.language.language?.createquery.tipocre!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="D" type="colHead">
                                                                                        {this.props.language.language?.createquery.vgente!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="E" type="colHead">
                                                                                        {this.props.language.language?.createquery.dia1!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="F" type="colHead">
                                                                                        {this.props.language.language?.createquery.dia30!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="G" type="colHead">
                                                                                        {this.props.language.language?.createquery.dia60!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="G" type="colHead">
                                                                                        {this.props.language.language?.createquery.dia90!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="G" type="colHead">
                                                                                        {this.props.language.language?.createquery.dia120!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="G" type="colHead">
                                                                                        {this.props.language.language?.createquery.dia180!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="G" type="colHead">
                                                                                        {this.props.language.language?.createquery.histo!}
                                                                                    </TableCell>
                                                                                    <TableCell dataHeader="G" type="colHead">
                                                                                        {this.props.language.language?.createquery.actua!}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </thead>

                                                                            {this.state.datoMoral.map((value: Morales, index: number) => (

                                                                                <>

                                                                                    <tbody>
                                                                                        <TableRow key={index}>
                                                                                            <TableCell type="rowHead">
                                                                                                {value.id}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.tipoOtorgante}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.tipoCredito}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.saldoVigente}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.vencido1a29}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.vencido30a59}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.vencido60a89}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.vencido90a119}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.vencido120a179}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.vencido180}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.historicoPagos}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {value.fechaActualizado}
                                                                                            </TableCell>

                                                                                        </TableRow>
                                                                                        <TableRow>

                                                                                        </TableRow>
                                                                                    </tbody>
                                                                                </>
                                                                            ))}
                                                                        </Table>
                                                                    }</>}</>}</>}


                                            <br /><br />
                                            <div className="boton">
                                                <GridRow gutterSize="fixed-16" gutters>
                                                    <GridCol base={12} md={6}>
                                                        <Button dynamicWidth onClick={this.PrevPage}>
                                                            {this.props.language.language?.createquery.PrevPage!}
                                                        </Button>
                                                    </GridCol>
                                                    <GridCol base={12} md={6}>
                                                        <Button dynamicWidth variant="primary" onClick={this.autorizar} disabled={this.state.disable}>
                                                            {this.props.language.language?.createquery.finalize!}
                                                        </Button>
                                                    </GridCol>
                                                </GridRow>

                                            </div>

                                            <Modal className='Modal-rechazas' isOpen={this.state.isOpen} size="medium"
                                                modalStyle="standard" closeFunc={() => {
                                                    this.setState({ isOpen: false });
                                                }}
                                            >
                                                <ModalBody>
                                                    <h3> Autorizar consulta</h3>
                                                    <p>¿Estas seguro que quieres autorizar la consulta?</p><br />
                                                    Escribe una observación:
                                                    <Textarea name='comentario' value={this.state.comentario} onChange={this.handlemensaje} rows={4} cols={40} />


                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button size="large" variant="primary" onClick={this.autoriza}>
                                                        Enviar
                                                    </Button>

                                                </ModalFooter>
                                            </Modal>
                                        </>

                                    ) : null}


                                </Form></>




                        </div>

                    </div>
                </div>
            </div >
        </>

    }


    saveWarranty() {
        throw new Error('Method not implemented.');

    }

    public Submit() {
        const { estadoCaptura } = this.state
        this.setState({ estadoCaptura: estadoCaptura + 1 })
        console.log('entra aqui')
        this.getMorales();
        this.getfisicas();
        this.setState({ status: '2' })
        console.log(this.state.status)

    }

    public handlemensaje(e: { preventDefault: () => void; target: { value: any; }; }) {
        e.preventDefault();
        const val = e.target.value;
        this.setState({ comentario: val });
        if (this.state.comentario.length >= 1) {
            this.setState({ consulta: false })
            this.setState({ status: '3' })
        } this.getstatusject();


    }

    public NextPage() {
        const { estadoCaptura } = this.state
        this.setState({ estadoCaptura: estadoCaptura + 1 })

    }


    public handleinputChange = (e: InputChange) => {

        this.setState(preveState => ({
            consultObject: { ...preveState.consultObject, [e.target.name]: e.target.value }

        }))
       // console.log(this.state.consultObject)

        this.getEstado();
    }

    public async getEstado() {
        try {
            if (this.state.consultObject.codigoPostal.length === 5) {
                const res = await getCreditbureau.getestado(this.state.consultObject.codigoPostal);
                if (res.data?.estado) {
                    this.setState({ estados: res.data })
                }

            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }


    public PrevPage() {
        const { estadoCaptura } = this.state
        this.setState({ estadoCaptura: estadoCaptura - 1 })
    }

    public cancel() {
        this.setState({ isOpen: false })
        this.setState({ comentario: '' })
        this.setState({ status: '1' })
        this.getstatusject();
        //console.log(this.state.status + "Si lo cambia")

    }

    public rechazada() {
        this.props.history.push('/buro-credito/Solicitudes-consulta');
        window.location.replace('');
        this.setState({ isOpen: false })

        
        this.setState({ status: '3' })
        this.getstatusject();
        //console.log(this.state.status)
        

        
    }
    public autoriza() {
        this.props.history.push('/buro-credito/Solicitudes-consulta');
        window.location.replace('');
        this.setState({ isOpen: false })
    }
    public rechazar() {
        this.setState({ isOpen: true })

    }
    public autorizar() {
        this.setState({ isOpen: true })
        this.getstatusject();

    }

    public downl() {
        this.postURl();
    }

    public async postURl() {
        try {
            const res = await apiS3Services.postUrl(this.state.PDFObject);
            console.log(res.data)
            const urldata = JSON.stringify(res.data.url)
            const comillas = urldata.replace(/['"]+/g, '')
            console.log(comillas)
            var win = window.open(comillas, '_blank');
            win?.focus();

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }



    public async getEntid() {
        try {
            const res = await getCreditbureau.getEntidades();
            //console.log(res.data)
            this.setState({ entidad: res.data })

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {

            }
        }
    }

    componentDidMount() {
        this.getCredit();
        this.getDatos();
    }
    async getCredit(): Promise<void> {
        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
               // console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                const res = await getCreditbureau.getConsul(dt);
                //const dat = Object.values(res.data)
                this.setState({ Datos_consult: res.data })
                //console.log(this.state.Datos_consult)
               // console.log(dat)
                if (this.state.mensajeConsult.length !== 0) {
                    this.setState({ isClose: true })
                } else {
                    this.setState({ isClose: false })
                }
            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
        this.getFolios(this.state.Datos_consult.rfc);
    }

    public async getFolios(value: string) {
        try {
            //console.log('Antes de ' + this.state.Datos_consult.rfc)
            const res = await getCreditbureau.getClienteConsulta(this.state.Datos_consult.rfc, this.state.Datos_consult.solicitante!);
            const ob = Object(res.data)
            this.setState({ mensajeConsult: ob })
           // console.log(this.state.mensajeConsult)
            if (this.state.mensajeConsult.length !== 0) {
                this.setState({ isClose: true })
            } else {
                this.setState({ isClose: false })
            }


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {

                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    public getDatos(): void {

        var storedata = localStorage.getItem('FolioConsulta');
        if (storedata == null) {
            console.log('vacio')

        } else {
            var data = storedata.replace(/['"]+/g, '')
            this.setState({ folio: data })
            //console.log(data)
            this.setState({
                PDFObject: { carpeta: 'buro', nombreArchivo: data + `.pdf` }
            })


        }
    }

    public async getfisicas() {

        try {

            this.setState({ Consul_buro: true })
            this.setState({ disable: true })
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {

                var data = storedata.replace(/['"]+/g, '')
                var dt = data

                if (this.state.Datos_consult.tipoPersona === 'Fisica') {
                    var tipoConsulta = 'PF';
                   // console.log('entra a fiiscas')
                    if (this.state.Datos_consult.fechaNacimiento === undefined) {
                   //     console.log('entra aqui si es nulla ')
                        this.state.Datos_consult.fechaNacimiento = '';
                  //      console.log(this.state.Datos_consult.fechaNacimiento)
                    }
                    if (this.state.Datos_consult.fechaNacimiento !== undefined) {

                        var fecha = this.state.Datos_consult.fechaNacimiento.split("-")
                        this.state.Datos_consult.fechaNacimiento = '';
                        this.state.Datos_consult.fechaNacimiento = fecha[2] + fecha[1] + fecha[0];
                        const res = await consultaBuroServices.getFisicas(this.state.Datos_consult.rfc, this.state.Datos_consult.nombre, this.state.Datos_consult.segundoNombre,
                            this.state.Datos_consult.apellidoPaterno, this.state.Datos_consult.apellidoMaterno, this.state.Datos_consult.fechaNacimiento, this.state.Datos_consult.codigoPostal, this.state.Datos_consult.calle,
                            this.state.Datos_consult.codigoBuro, this.state.Datos_consult.estadoDomicilio!, this.state.Datos_consult.delegacionMunicipio, this.state.Datos_consult.numexterior, dt, tipoConsulta,this.state.PERFIL.username, this.state.Datos_consult.tipoEntidad
                            ,this.state.fechaAutoAux,this.state.Datos_consult.idFiado!)
                      //  console.log('se manda correctamente')
                        const mensaje = res.data.mensaje;
                        this.setState({ mensajeF: mensaje });
                        if (res.data.creditos == null) {
                            this.setState({ errorBURODetail: true })
                            this.setState({ table: 0 })
                            this.setState({ disable: false })
                        } else {
                            const ob = (res.data.creditos)
                            this.setState({ datofisica: ob })
                            this.setState({ table: 1 })
                            this.setState({ Consul_buro: false })
                            this.setState({ errorBURODetail: false })
                            this.setState({ disable: false })

                        }
                    }
                }
            }



        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                this.setState({ Consul_buro: false })
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }


    public async getstatusject() {
        this.setState({ Consul_buro: true })
        var storedata = localStorage.getItem('FolioConsulta');
        if (storedata == null) {
            console.log('vacio')
        } else {
            var data = storedata.replace(/['"]+/g, '')
            var fol = data.toString();

            //console.log(this.state.status + 'estatus al enviar')
            //console.log(this.state.comentario + 'asi se ve el comentario')
            await getCreditbureau.putStatusEject(this.state.comentario, fol, this.state.status);
        }
    }


    public async getMorales() {


        try {
            //console.log('entra a morales')
            this.setState({ Consul_buro: true })
            this.setState({ disable: true })
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                if (this.state.Datos_consult.tipoPersona === 'Moral') {
                    var tipoConsulta = 'PM';
                    const res = await consultaBuroServices.getMorales(this.state.Datos_consult.rfc, this.state.Datos_consult.nombre,
                        this.state.Datos_consult.calle, this.state.Datos_consult.delegacionMunicipio, this.state.Datos_consult.codigoPostal, this.state.Datos_consult.estadoDomicilio!, this.state.Datos_consult.codigoBuro, this.state.Datos_consult.numexterior, dt, tipoConsulta,
                        this.state.PERFIL.username, this.state.Datos_consult.tipoEntidad,this.state.fechaAutoAux,this.state.Datos_consult.idFiado!)

                    const mensaje = res.data.mensaje;
                    this.setState({ mensajeM: mensaje });
                    if (res.data.creditos == null) {
                        this.setState({ errorBURODetail: true })
                        this.setState({ table: 0 })
                        this.setState({ disable: true })

                    } else {
                        const ob = (res.data.creditos)
                        this.setState({ datoMoral: ob })
                        this.setState({ table: 1 })
                        this.setState({ Consul_buro: false })
                        this.setState({ errorBURODetail: false })
                        this.setState({ disable: false })
                    }
                }
            }

        } catch (error) {

            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }



    public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (validFilepdf(e)) {
            const formDataAuxiliar = this.state.formData
            formDataAuxiliar.append('archivo', e.target.files![0]);
            formDataAuxiliar.append('carpeta', 'buro');
            formDataAuxiliar.append('nombreArchivo', this.state.folio + '.pdf');
            this.setState({ formData: formDataAuxiliar })
            this.setState({ consulta: false })

        }




    }

    public async postFileValidacion(formData: FormData) {

        try {
            await apiS3Services.postFile(formData)
            
            this.getMorales();
            this.getfisicas();



        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }


}



const mapStateToProps = (state: RootStore) => ({
    language: state.language
});

export default connect(mapStateToProps)(Enrevision);


interface ConsultProssState {

    estadoCaptura: number;
    select: string,
    status: string,
    validador: number,
    entidad: Entidad[],
    datovalid: string,
    folio: string,
    datofisica: Fisicas[],
    datoMoral: Morales[],
    Loading: boolean,
    formData: FormData,
    validafile: number,
    paginacion: PaginacionModel,
    loadingConsult: boolean,
    listPaginacion: number[],
    consulta: boolean,
    search: string,
    Datos_consult: ConsultaBuro,
    FormFisicas: FormData,
    Consul_buro: boolean,
    errorBURODetail: boolean,
    table: number,
    mensajeM: string,
    mensajeF: string,
    estados: estado,
    consultObject: ConsultaBuro,
    text: string,
    isOpen: boolean,
    comentario: string,
    PDFObject: PdfFile,
    disable: boolean,
    mensajeConsult: foliosVigent[],
    PERFIL: UserModel,
    isClose: boolean,
    fechaAutoAux:string
}



