import {AddObligorLanguageEnglish, AddObligorLanguageSpanish} from "../add-obligor/AddObligorLanguage";

export interface TableWarrantyLanguageModel {

    createWarranty: string;
    loadingWarranties: string;
    errorWarranties: string;
    show: string;
    registers: string;
    search: string;
    current: string;
    review: string;
    pendient: string;
    expire: string;
    showing: string;
    to: string;
    of: string;
    entries: string;
    invoice: string;
    warranty: string;
    typeWarranty: string;
    valueWarranty: string;
    amountWeighted: string;
    vigency: string;
    dateCapture: string;
    dateStart: string;
    dateEnd: string;
    propietary: string;
    relevantDescription: string;
    fiado: string;
    status: string;
    moreFilters: string;
    cleanFilters: string;
    filtersObligors: string;
    searchOs: string;
    selectAllOs: string;
    allOsSelected: string;
    filtersTitle: string;
    showLoansPrincipal: string;
    loadingOs: string;
    errorOs: string;
    noResults: string;

}

export const TableWarrantyLanguageEnglish: TableWarrantyLanguageModel ={
    createWarranty: 'Create Collateral',
    loadingWarranties: 'Loading Collaterals',
    errorWarranties: 'Sorry something happened while loading Collaterals, please check your connection and try again.',
    show: 'Show',
    registers: 'entries',
    search: 'Search',
    current: 'Current',
    review: 'In Review',
    pendient: 'Pending',
    expire: 'Expire',
    showing: 'Showing',
    to: 'to',
    of :'of',
    entries: 'entries',
    invoice: 'Invoice',
    warranty: 'Collateral',
    typeWarranty: 'Type',
    valueWarranty: 'Loan Value',
    amountWeighted: 'Weighted amountWeighted',
    vigency: 'Vigency',
    dateCapture: 'Date of capture',
    dateStart: 'Effective date',
    dateEnd: 'End of Effective Date',
    propietary: 'Propietary',
    relevantDescription: 'Description',
    fiado: 'Principal',
    status: 'Status',
    moreFilters: 'More filters',
    cleanFilters: 'Clean filters',
    filtersObligors:'Select Obligated Solidarity',
    searchOs: 'Search Obligors ',
    selectAllOs: 'Select all',
    allOsSelected: 'All Obligors are selected',
    filtersTitle: 'Filters',
    showLoansPrincipal: 'Show all the Loans of the principal',
    loadingOs: AddObligorLanguageEnglish.loadingOs,
    errorOs: AddObligorLanguageEnglish.errorOs,
    noResults: AddObligorLanguageEnglish.noResults

}
export const TableWarrantyLanguageSpanish: TableWarrantyLanguageModel ={
    createWarranty: 'Crear Garantía',
    loadingWarranties: 'Cargando Garantias',
    errorWarranties: 'Lo sentimos algo ha ocurrido al cargar garantías, revise su conexión e intente de nuevo.',
    show: 'Mostrar',
    registers: 'Registros',
    search: 'Buscar',
    current: 'Vigente',
    review: 'En Revisión',
    pendient: 'Pendiente',
    expire: 'Vencida',
    showing: 'Mostrando registros del',
    to: 'al',
    of: 'de un total de',
    entries: 'registros',
    invoice: 'Folio',
    warranty: 'Garantía',
    typeWarranty: 'Tipo',
    valueWarranty: 'Valor de la Garantía',
    amountWeighted: 'Monto Ponderado',
    vigency: 'Vigencia',
    dateCapture: 'Fecha de captura',
    dateStart: 'Fecha de Inicio de Vigencia',
    dateEnd: 'Fecha Final de Vigencia',
    propietary: 'Propietario',
    relevantDescription: 'Descripción',
    fiado: 'Fiado',
    status: 'Estatus',
    moreFilters: 'Más Filtros',
    cleanFilters: 'Limpiar Filtros',
    filtersObligors: "Selecciona Obligados Solidario",
    searchOs: 'Buscar Obligado Solidario',
    selectAllOs:'Seleccionar todos',
    allOsSelected: 'Todos los Obligados están seleccionados',
    filtersTitle: 'Filtros ',
    showLoansPrincipal: 'Mostrar todas las Garantías del fiado',
    loadingOs: AddObligorLanguageSpanish.loadingOs,
    errorOs: AddObligorLanguageSpanish.errorOs,
    noResults: AddObligorLanguageSpanish.noResults
}