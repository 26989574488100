import React, { useEffect, useState} from 'react';
import {Pagination} from 'react-bootstrap';
import './ResumeGarantias.scss';
import {IconPeople, IconSpecialist, Table, TableCell, TableRow,} from "@lmig/lmds-react";
import { useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {AxiosError} from "axios";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import {formatCurrency, initPaginacion, loadPaginationList, validRoles,} from "../../../class/CommonClass";
import {UserModel} from "../../../models/public/UserModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import {PaginacionModel} from "../../../models/public/PaginacionModel";
import {ResumenGarantias} from "../../../models/garantias/ResumenGarantias";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {RolResumenEnum} from "../../../models/garantias/RolResumenEnum";
import {StatusRowPrincipal} from "../status-row-principal/StatusRowPrincipal";
import {ADDOS} from "../../../actions/FiadoActionTypes";
import {DownloadReporteCumulo} from "../downloadReporteCumulo/DownloadReporteCumulo";
interface ResumeGarantiasProps {
    idPrincipal: number;

}

export const ResumeGarantias: React.FC<ResumeGarantiasProps> = ({idPrincipal}:ResumeGarantiasProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language.language?.resumeGarantias);

    const fiadoAction = useSelector((state: RootStore) => state.fiadoAction.fiadoAction);

    const localStorageClass = new LocalStorageClass();

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [listResumen, setlistResumen] = useState<ResumenGarantias []>([]);
    const [loadingResumen, setLoadingResumen] = useState<boolean>(false);
    const [errorResumen, setErrorResumen] = useState<boolean>(false);
    const [paginacion, setPaginacion] = useState<PaginacionModel>(initPaginacion);
    const [listPaginacion, setListPaginacion] = useState<number[]>([]);

    const [loadingTotal, setLoadingTotal] = useState<boolean>(false);
    const [errorTotal, setErrorTotal] = useState<boolean>(false);
    const [totalResumen, setTotalResumen] = useState<ResumenGarantias>({
        estadoBloqueo:false,
        estadoBloqueoComision: false,
        garantias:0,
        garantiasDisponibles: 0,
        montoAfianzadoCubierto: 0,
        montoAfianzadoCubiertoTerceros: 0,
        montoAfianzadoPendientes: 0,
        nombre: '',
        rol: RolResumenEnum.fiado,
        totalAfiazado:0
    });


    const getResume = async (idPrincipal: number, paginacion: PaginacionModel) => {

        setLoadingResumen(true);
        setErrorResumen(false)
        try {
            const res = await garantiasService.getResumen(idPrincipal,paginacion);
            res.data.paginacion.size = paginacion.size;
            setListPaginacion(loadPaginationList(res.data.paginacion));
            setLoadingResumen(false);
            setPaginacion(res.data.paginacion);
            setlistResumen(res.data.elementos);
            getTotalResume(idPrincipal)
        }
        catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingResumen(false);
                setErrorResumen(true)

            }
        }
    };

    const getTotalResume = async (idPrincipal: number) => {

        setLoadingTotal(true);
        setErrorTotal(false)
        try {
            const res = await garantiasService.getTotalResumen(idPrincipal);
            setTotalResumen(res.data)
            setLoadingTotal(false);

        }
        catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingTotal(false);
                setErrorTotal(true)
            }
        }
    };

    const clickGoToPage = (page: number) => {
        const totalPages: number = paginacion.totalPages;
        if (page>=1 &&  page <= totalPages){
            const auxPaginacion = paginacion;
            auxPaginacion.currentPage = page;
            setPaginacion(auxPaginacion);
            getResume(idPrincipal, auxPaginacion);
        }

    }

    const returnDeleteOS = () => {
        getResume(idPrincipal, paginacion);
    }

    const printTotalGarDisp = (garantiasTotales: number,garantiasDisponibles : number ): JSX.Element => {
        const porcentaje = garantiasDisponibles *100 /garantiasTotales;
        if (porcentaje >= 0.8){
          return  <span className="text-success">{formatCurrency(totalResumen.garantiasDisponibles)} </span>
        }else if (porcentaje >= .04 &&  porcentaje < 0.8){
            return <span className="text-warning">{formatCurrency(totalResumen.garantiasDisponibles)} </span>
        }
        return <span className="">{formatCurrency(totalResumen.garantiasDisponibles)} </span>
    }

    const updateFiadoAction = () => {
        switch (fiadoAction?.action) {
            case ADDOS:
                setPaginacion(initPaginacion);
                getResume(idPrincipal, initPaginacion);

                break;
            default:
                setPaginacion(initPaginacion);
                getResume(idPrincipal, initPaginacion);
                break;
        }
    }

    useEffect(() => {

            updateFiadoAction()



    },[fiadoAction, idPrincipal]);

    return (
        <div id="resume-garantias"  style={{
            transition: "all .2s",
        }}>

            <div className="row seccion-botons " id="nShow-n-entries">

                <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                    {/*        <Select
                        isRequired
                        name="nEntries"
                        labelA11y=""
                        labelVisual={languageState?.show! + ' '+
                            String(paginacion.size) + ' ' + languageState?.registers}
                        value={String(paginacion.size)}
                        onChange={handleInputChangeSelect}
                        placeholderOption="">
                        <SelectOption value="10">
                            10
                        </SelectOption>
                        <SelectOption value="25">
                            25
                        </SelectOption>
                        <SelectOption value="50">
                            50
                        </SelectOption>
                        <SelectOption value="100">
                            100
                        </SelectOption>
                    </Select>*/}
                </div>
                <div className="col-12 col-sm-12 col-md-2 col-lg-4"/>

            </div>


            {loadingResumen? (
                    <div className="w-100">
                        <SpinnerLoading
                            text={languageState?.loadingResume}/>
                    </div>
                ):
                (errorResumen)? (
                        <div className="col-md-12">
                            <NegativeAlert
                                textAlert={languageState?.errorResume!}/>
                        </div>):
                    <>
                        <div className="row ">
                            {/*data table*/}
                            <Table   id="tabla-resumen">
                                <thead>
                                <TableRow className="text-center">
                                    <TableCell  type="colHead">
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.nameResume} type="colHead">
                                        {languageState?.nameResume}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.garantias} type="colHead">
                                        {languageState?.garantias}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.totalAfianzado} type="colHead">
                                        {languageState?.totalAfianzado}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.montoAfianzadoCubierto} type="colHead">
                                        {languageState?.montoAfianzadoCubierto}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.montoAfianzadoPendientes} type="colHead">
                                        {languageState?.montoAfianzadoPendientes}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.montoAfianzadoCubiertoTerceros} type="colHead">
                                        {languageState?.montoAfianzadoCubiertoTerceros}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.garantiasDisponibles} type="colHead">
                                        {languageState?.garantiasDisponibles}
                                    </TableCell>
                                    <TableCell dataHeader={languageState?.acciones} type="colHead">
                                        {languageState?.acciones}
                                    </TableCell>
                                </TableRow>
                                </thead>
                                <tbody>

                                {
                                    listResumen.map((i, index) =>
                                        (
                                            <TableRow key={index} className="columnas">
                                                <TableCell className="rol"  >
                                                    <div>
                                                        {(i.rol ==RolResumenEnum.fiado)?
                                                            <>
                                                                <IconSpecialist
                                                                    color={i.estadoBloqueo == true? "error": "success"}
                                                                    title={i.estadoBloqueo == true? languageState?.fiadoBloqueado: ''}
                                                                    size="24" /><br/>
                                                                <span className={i.estadoBloqueo == true? "text-danger": "text-success"}>
                                                                {languageState?.rolFiado}
                                                            </span>
                                                            </>:
                                                            <>
                                                                <IconPeople
                                                                    color={i.estadoBloqueo == true? "error": "success"}
                                                                    title={i.estadoBloqueo == true? languageState?.osBloqueado: ''}
                                                                    size="24" /><br/>
                                                                <span className={i.estadoBloqueo == true? "text-danger": "text-success"}>
                                                                {languageState?.rolOs}
                                                            </span>
                                                            </>
                                                        }
                                                    </div>
                                                </TableCell>
                                                <TableCell  className="nombre" dataHeader={languageState?.nameResume} >
                                                    {i.nombre}
                                                </TableCell>
                                                <TableCell className="cantidad" dataHeader={languageState?.garantias} >
                                                    {formatCurrency(i.garantias)}
                                                </TableCell>
                                                <TableCell className="totalAfiazado" dataHeader={languageState?.totalAfianzado}>
                                                    {formatCurrency(i.totalAfiazado)}
                                                    <div className="button-download-totalAfianzado">

                                                                {i.totalAfiazado > 0 ?
                                                                    <>
                                                                        &nbsp;
                                                                        <DownloadReporteCumulo
                                                                            idCliente={i.idCliente!}
                                                                            title={languageState?.downloadReportecumulo! + ' ' + i.nombre}
                                                                            nameFile={languageState?.fileReporteCumulo! + ' ' + i.nombre}
                                                                            errDownloading={languageState?.errDownloadinReporte!}
                                                                        />
                                                                    </>
                                                                    :
                                                                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>


                                                                }

                                                    </div>


                                                </TableCell>
                                                <TableCell className="cantidad" dataHeader={languageState?.montoAfianzadoCubierto}>
                                                    {formatCurrency(i.montoAfianzadoCubierto)}
                                                </TableCell>
                                                <TableCell className="cantidad" dataHeader={languageState?.montoAfianzadoPendientes}>
                                                    {formatCurrency(i.montoAfianzadoPendientes)}
                                                </TableCell>
                                                <TableCell className="cantidad" dataHeader={languageState?.montoAfianzadoCubiertoTerceros}>
                                                    {formatCurrency(i.montoAfianzadoCubiertoTerceros)}
                                                </TableCell>
                                                <TableCell className="cantidad" dataHeader={languageState?.garantiasDisponibles}>
                                                    {formatCurrency(i.garantiasDisponibles)}
                                                </TableCell>
                                                <TableCell className="accions " dataHeader={languageState?.acciones} >

                                                    <StatusRowPrincipal
                                                        idFiado={idPrincipal!}
                                                        idCliente={i.idCliente!}
                                                        name={i.nombre}
                                                        rol={i.rol}
                                                        estadoBloqueo={i.estadoBloqueo}
                                                        estadoBloqueoComision={i.estadoBloqueoComision}

                                                        returnDeleteOS={returnDeleteOS}/>

                                                </TableCell>

                                            </TableRow>
                                        ))
                                }

                                { (errorTotal)? (
                                        <div className="col-md-12">
                                            <NegativeAlert
                                                textAlert={languageState?.errorResume!}/>
                                        </div>):
                                    null


                                }

                                <TableRow>
                                    <TableCell className="text-center rol">
                                        <b>{languageState?.total}:</b>
                                    </TableCell>
                                    <TableCell>

                                    </TableCell>
                                    <TableCell className="text-right">
                                        {formatCurrency(totalResumen.garantias)}
                                    </TableCell>
                                    <TableCell className="text-right">
                                        {formatCurrency(totalResumen.totalAfiazado)}

                                    </TableCell>
                                    <TableCell className="text-right">
                                        {formatCurrency(totalResumen.montoAfianzadoCubierto)}

                                    </TableCell>
                                    <TableCell className="text-right">
                                        {formatCurrency(totalResumen.montoAfianzadoPendientes)}
                                    </TableCell>
                                    <TableCell className="text-right">
                                        {formatCurrency(totalResumen.montoAfianzadoCubiertoTerceros)}
                                    </TableCell>
                                    <TableCell className="text-right">
                                        {printTotalGarDisp(totalResumen.garantias , totalResumen.garantiasDisponibles )}
                                    </TableCell>
                                    <TableCell >
                                    </TableCell>

                                </TableRow>

                                </tbody>
                            </Table>
                        </div>
                        <div className="row pagination-sdgf">
                            <div className="col-md-5 show-registers">
                                {languageState?.showing}&nbsp;
                                {(paginacion.currentPage * paginacion.size)- (paginacion.size-1)}  {languageState?.to}&nbsp;
                                {(paginacion.currentPage * paginacion.size)}  {languageState?.of}&nbsp;
                                {paginacion.totalItems}  {languageState?.entries}.
                            </div>
                            <div className="col-md-7 ">
                                <Pagination className="float-md-right" id="pagination-table-warranty">
                                    <Pagination.Prev
                                        onClick={()=>{
                                            clickGoToPage((listPaginacion[0])-1)
                                        }}
                                    />
                                    {
                                        listPaginacion.map((i, index) =>
                                            ( <Pagination.Item
                                                    key={i}
                                                    active={i === paginacion.currentPage}
                                                    onClick={()=>{
                                                        clickGoToPage(i)
                                                    }}>
                                                    {i}
                                                </Pagination.Item>

                                            ))
                                    }
                                    <Pagination.Next
                                        onClick={()=>{
                                            clickGoToPage(listPaginacion[listPaginacion.length-1]+1)
                                        }}
                                    />

                                </Pagination>

                            </div>

                        </div>

                    </>


            }





        </div>
    );
};