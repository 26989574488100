import React, { ChangeEvent, Component } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { DefaultStateI } from '../../../reducer/LanguageReducer';
import { RootStore } from '../../../Store';
import NavBar from '../../../components/public/nav-bar/NavBar';
import Form from '@lmig/lmds-react/Form/Form';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import './ConfigCuenta.scss'
import { ImLock } from 'react-icons/im';
import {  AlphanumericInput, Button, FieldGroup, IconSuccessAlert } from '@lmig/lmds-react';
import { RiUserFill } from 'react-icons/ri';
import { PillButton } from '@lmig/lmds-react-pill-button';
import { IoBusinessSharp } from 'react-icons/io5';
import { FaUserTie } from 'react-icons/fa';
import * as getCreditbureau from "../../../services/buro-credito/CreditbureauService";
import { Modal } from '@lmig/lmds-react-modal';
import show from '../../../assets/images/public/login/show.png';
import hidden from '../../../assets/images/public/login/hidden.png';
import { admin_Contra } from '../../../models/buro-credito/Admin_contraseñas';
import { ModalBody, ModalFooter } from 'react-bootstrap';


export type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>;

interface vencidProps {
    language: DefaultStateI
}



class ConfigCuenta extends Component<vencidProps & RouteComponentProps, vencidState> {

    public localStorageClass = new LocalStorageClass();
    public select = document.getElementById('Persona');
    options: any;

    public confirmpass: admin_Contra = {
        usuario: '',
        password: '',
        tipoUsuario: '',
        confirm_pass: ''
    }
    constructor(props: any) {
        super(props);
        this.state = {
            loadingWarrantyDetail: true,
            errorWarrantyDetail: false,
            validador: 0,
            showPsw: false,
            edit: 0,
            diss: true,
            adminFisicas: this.confirmpass,
            adminMorales: this.confirmpass,
            buttons: 1,
            alert: false,
            isOpen: false
        }
        this.validMoral = this.validMoral.bind(this);
        this.validFisica = this.validFisica.bind(this);
        this.Edit = this.Edit.bind(this);
        this.EditM = this.EditM.bind(this);
        this.Cancel = this.Cancel.bind(this);
        this.Admin_Contra = this.Admin_Contra.bind(this);
        this.enviardatosF = this.enviardatosF.bind(this);
        this.enviardatosM = this.enviardatosM.bind(this);
        this.cerrar = this.cerrar.bind(this);
    }





    render(): JSX.Element {
        return <>
          <NavBar />
            <div className="container-fluid warranty" id="consult-query">
              
                <div className="config" id='config'>

                    <h2>{this.props.language.language?.configAccount.title!}</h2>

                </div>


                <div className="container">
                    <div className="row ">
                        <div className="col-12 col-md-5">

                            <div className="card" id='cardss'>
                                <div className="card-body" onClick={this.validFisica} >
                                    <p><FaUserTie size={50} /> {this.props.language.language?.configAccount.fisica!}</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card" id='cardss'>
                                <div className="card-body" onClick={this.validMoral} >
                                    <p> <IoBusinessSharp size={50} />{this.props.language.language?.configAccount.morales!}</p>
                                </div>
                            </div>

                        </div>

                        <div className="col-12 col-md-7">
                            <Form id='form-admin' encType="form-data" className="col-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.validador === 0 &&

                                            <FieldGroup labelStyle="h4" labelVisual={this.props.language.language?.configAccount.fisica!} >



                                                <p><RiUserFill />{this.props.language.language?.configAccount.user!}</p>
                                                <div className="row input-passad">

                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <AlphanumericInput labelVisual=''
                                                                    className="input-user-name"
                                                                    name="usuario"
                                                                    onChange={this.handleinputFisicas}
                                                                    onBlur={this.handleinputFisicas}
                                                                    value={this.state.adminFisicas.usuario}
                                                                    disabled
                                                                    type='text' />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p><ImLock />{this.props.language.language?.configAccount.password!}</p>

                                                <div className="row input-passad">



                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-10">
                                                                <AlphanumericInput labelVisual=''
                                                                    className="input-user-name"
                                                                    name="password"
                                                                    onChange={this.handleinputFisicas}
                                                                    onBlur={this.handleinputFisicas}
                                                                    value={this.state.adminFisicas.password}
                                                                    disabled={this.state.diss}
                                                                    type={this.state.showPsw ? 'text' : 'password'} />

                                                            </div>
                                                            <div className="col-2">
                                                                {(this.state.showPsw) ?
                                                                    <a
                                                                        id="ico-show-psw"
                                                                        onClick={() => {
                                                                            this.setState({ showPsw: false })
                                                                        }} >
                                                                        <img src={show} className="img-fluid d-block mx-auto" />

                                                                    </a> :
                                                                    <a
                                                                        id="ico-hidden-psw"
                                                                        onClick={() => {
                                                                            this.setState({ showPsw: true })
                                                                        }} >
                                                                        <img src={hidden} className="img-fluid d-block mx-auto" />
                                                                    </a>
                                                                }


                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                {this.state.edit == 1 &&
                                                    <>
                                                        <p><ImLock />{this.props.language.language?.configAccount.confirm!}</p>

                                                        <div className="row input-confirm-passd">



                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-10">
                                                                        <AlphanumericInput labelVisual=''
                                                                            className="input-user-name"
                                                                            name="confirm_pass"
                                                                            value={this.state.adminFisicas.confirm_pass}
                                                                            onChange={this.handleinputFisicas}
                                                                            onBlur={this.handleinputFisicas}
                                                                            type={this.state.showPsw ? 'text' : 'password'} />

                                                                    </div>
                                                                    <div className="col-2">
                                                                        {(this.state.showPsw) ?
                                                                            <a
                                                                                id="ico-show-psw"
                                                                                onClick={() => {
                                                                                    this.setState({ showPsw: false })
                                                                                }} >
                                                                                <img src={show} className="img-fluid d-block mx-auto" />

                                                                            </a> :
                                                                            <a
                                                                                id="ico-hidden-psw"
                                                                                onClick={() => {
                                                                                    this.setState({ showPsw: true })
                                                                                }} >
                                                                                <img src={hidden} className="img-fluid d-block mx-auto" />

                                                                            </a>
                                                                        }


                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <Modal id='modal-contra' isOpen={this.state.isOpen} onClick={() => this.setState({ isOpen: false })} size="medium" >
                                                            <ModalBody >
                                                                <IconSuccessAlert color="positive" size="64" /><br />
                                                                <h5> {this.props.language.language?.configAccount.new_contra!} </h5>
                                                                <ModalFooter>
                                                                    <Button variant="primary" onClick={this.cerrar}>
                                                                        {this.props.language.language?.configAccount.continue!}
                                                                    </Button>
                                                                </ModalFooter>
                                                            </ModalBody>
                                                        </Modal>
                                                    </>



                                                }

                                                <br />
                                                {this.state.buttons === 1 &&
                                                    <div className="" id='buttons'>
                                                        <PillButton type="reset" onClick={this.Cancel}>
                                                            {this.props.language.language?.configAccount.cancel!}
                                                        </PillButton>
                                                        <PillButton type="submit" variant="primary" onClick={this.Edit}>
                                                            {this.props.language.language?.configAccount.edit!}
                                                        </PillButton>
                                                        <br />
                                                        <br />
                                                    </div>

                                                }

                                                {this.state.buttons === 2 &&
                                                    <div className="" id='buttons'>
                                                        <PillButton type="reset" onClick={this.Cancel}>
                                                            {this.props.language.language?.configAccount.cancel!}
                                                        </PillButton>
                                                        <PillButton type="submit" variant="primary" onClick={() => this.setState({ isOpen: true })} >
                                                            {this.props.language.language?.configAccount.send!}
                                                        </PillButton>
                                                        <br />
                                                        <br />
                                                    </div>

                                                }

                                            </FieldGroup>
                                        }

                                        {this.state.validador === 1 &&

                                            <FieldGroup labelStyle="h4" labelVisual={this.props.language.language?.configAccount.morales!} >



                                                <p><RiUserFill />{this.props.language.language?.configAccount.user!}</p>
                                                <div className="row input-passad">

                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <AlphanumericInput labelVisual=''
                                                                    className="input-user-name"
                                                                    name="usuario"
                                                                    onChange={this.handleinputMorales}
                                                                    onBlur={this.handleinputMorales}
                                                                    value={this.state.adminMorales.usuario}
                                                                    disabled
                                                                    type='text' />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p><ImLock />{this.props.language.language?.configAccount.password!}</p>

                                                <div className="row input-passad">



                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-10">
                                                                <AlphanumericInput labelVisual=''
                                                                    className="input-user-name"
                                                                    name="password"
                                                                    onChange={this.handleinputMorales}
                                                                    onBlur={this.handleinputMorales}
                                                                    value={this.state.adminMorales.password}
                                                                    disabled={this.state.diss}
                                                                    type={this.state.showPsw ? 'text' : 'password'} />

                                                            </div>
                                                            <div className="col-2">
                                                                {(this.state.showPsw) ?
                                                                    <a
                                                                        id="ico-show-psw"
                                                                        onClick={() => {
                                                                            this.setState({ showPsw: false })
                                                                        }} >
                                                                        <img src={show} className="img-fluid d-block mx-auto" />

                                                                    </a> :
                                                                    <a
                                                                        id="ico-hidden-psw"
                                                                        onClick={() => {
                                                                            this.setState({ showPsw: true })
                                                                        }} >
                                                                        <img src={hidden} className="img-fluid d-block mx-auto" />
                                                                    </a>
                                                                }


                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                {this.state.edit === 1 &&
                                                    <>
                                                        <p><ImLock />{this.props.language.language?.configAccount.confirm!}</p>

                                                        <div className="row input-confirm-passd">



                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-10">
                                                                        <AlphanumericInput labelVisual=''
                                                                            className="input-user-name"
                                                                            name="confirm_pass"
                                                                            value={this.state.adminMorales.confirm_pass}
                                                                            onChange={this.handleinputMorales}
                                                                            onBlur={this.handleinputMorales}
                                                                            type={this.state.showPsw ? 'text' : 'password'} />

                                                                    </div>
                                                                    <div className="col-2">
                                                                        {(this.state.showPsw) ?
                                                                            <a
                                                                                id="ico-show-psw"
                                                                                onClick={() => {
                                                                                    this.setState({ showPsw: false })
                                                                                }} >
                                                                                <img src={show} className="img-fluid d-block mx-auto" />

                                                                            </a> :
                                                                            <a
                                                                                id="ico-hidden-psw"
                                                                                onClick={() => {
                                                                                    this.setState({ showPsw: true })
                                                                                }} >
                                                                                <img src={hidden} className="img-fluid d-block mx-auto" />

                                                                            </a>
                                                                        }


                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <Modal id='modal-contra' isOpen={this.state.isOpen} onClick={() => this.setState({ isOpen: false })} size="medium" >
                                                            <ModalBody >
                                                                <IconSuccessAlert color="positive" size="64" /><br />
                                                                <h5> {this.props.language.language?.configAccount.new_contra!} </h5>
                                                                <ModalFooter>
                                                                    <Button variant="primary" onClick={this.cerrar}>
                                                                        {this.props.language.language?.configAccount.continue!}
                                                                    </Button>
                                                                </ModalFooter>
                                                            </ModalBody>
                                                        </Modal>
                                                    </>



                                                }

                                                <br />
                                                {this.state.buttons === 1 &&
                                                    <div className="" id='buttons'>
                                                        <PillButton type="reset" onClick={this.Cancel}>
                                                            {this.props.language.language?.configAccount.cancel!}
                                                        </PillButton>
                                                        <PillButton type="submit" variant="primary" onClick={this.Edit}>
                                                            {this.props.language.language?.configAccount.edit!}
                                                        </PillButton>
                                                        <br />
                                                        <br />
                                                    </div>

                                                }

                                                {this.state.buttons === 2 &&
                                                    <div className="" id='buttons'>
                                                        <PillButton type="reset" onClick={this.Cancel}>
                                                            {this.props.language.language?.configAccount.cancel!}
                                                        </PillButton>
                                                        <PillButton type="submit" variant="primary" onClick={() => this.setState({ isOpen: true })} >
                                                            {this.props.language.language?.configAccount.send!}
                                                        </PillButton>
                                                        <br />
                                                        <br />
                                                    </div>

                                                }

                                            </FieldGroup>
                                        }
                                    </div>
                                </div>


                            </Form>


                        </div>

                    </div>
                </div>


            </div>

        </>
    }


    saveWarranty() {
        throw new Error('Method not implemented.');

    }
    //funciones de redireccion

    validMoral() {
        this.setState({ validador: 1 })
    }

    validFisica() {
        this.setState({ validador: 0 })
    }

    Edit() {
        this.setState({ edit: 1 })
        this.setState({ diss: false })
        this.setState({ buttons: 2 })

    }

    EditM() {
        this.setState({ edit: 2 })
        this.setState({ diss: false })
        this.setState({ buttons: 1 })
    }
    Cancel() {
        this.setState({ edit: 0 })
        this.setState({ diss: true })
        this.setState({ buttons: 1 })
    }

    enviarcontraF() {
        this.enviardatosF();
        console.log('si entra aqui')
    }
    enviarcontraM() {
        this.enviardatosM();
    }
    cerrar() {
        this.setState({ isOpen: false });
        window.location.reload()
    }
    componentDidMount() {
        this.Admin_Contra();

    }

    //obtenemos los datos de las cuentas

    public async Admin_Contra() {
        const res = await getCreditbureau.getContra();

        const datos = (res.data.datos);
        const exa = Object.values(datos)
        this.setState({ adminFisicas: exa[0] })
        this.setState({ adminMorales: exa[1] })

    }





    //obtenemos los datos de la nueva contraseña persona fisica
    public handleinputFisicas = (e: InputChange) => {
       

        this.setState(preveState => ({
            adminFisicas: { ...preveState.adminFisicas, [e.target.name]: e.target.value }

        }))
        console.log('estos datos fisicos' + this.state.adminFisicas.password, this.state.adminFisicas.usuario)
        this.enviardatosF();

    }

    //obtenemos los datos de la nueva contraseña persona fisica
    public handleinputMorales = (e: InputChange) => {
       

        this.setState(preveState => ({
            adminMorales: { ...preveState.adminMorales, [e.target.name]: e.target.value }

        }))

        console.log('Estos datos morales' + this.state.adminMorales.password, this.state.adminMorales.usuario)
        this.enviardatosM();
    }
    //enviamos los datos para guardar la nueva constraseña persona fisica
    public async enviardatosF() {

        if (this.state.adminFisicas.password !== this.state.adminFisicas.confirm_pass) {
            this.setState({ alert: true })
            console.log('los datos no coinciden')
        } else {
            this.setState({ alert: false })
        }

        const res = getCreditbureau.putContra(this.state.adminFisicas.password, this.state.adminFisicas.usuario);
        console.log(res)


    }
    //enviamos los datos para guardar la nueva constraseña persona Moral
    public async enviardatosM() {

        if (this.state.adminMorales.password !== this.state.adminMorales.confirm_pass) {
            this.setState({ alert: true })
            console.log('los datos no coinciden')
        } else {
            this.setState({ alert: false })
        }

        const res = getCreditbureau.putContra(this.state.adminMorales.password, this.state.adminMorales.usuario);
        console.log(res)



    }





}



const mapStateToProps = (state: RootStore) => ({
    language: state.language
});

export default connect(mapStateToProps)(ConfigCuenta);


interface vencidState {

    loadingWarrantyDetail: boolean,
    errorWarrantyDetail: boolean,
    validador: number,
    showPsw: boolean,
    edit: number,
    diss: boolean,
    adminFisicas: admin_Contra,
    adminMorales: admin_Contra,
    buttons: number,
    alert: boolean,
    isOpen: boolean



}



