import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/esm/Accordion'
import Card from 'react-bootstrap/esm/Card'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { validRoles } from "../../class/CommonClass";
import { LocalStorageClass } from '../../class/LocalStorageClass';
import environment from '../../environments/environment';
import { UserModel } from '../../models/public/UserModel';
import { RootStore } from '../../Store';

interface AccesBuroProps {
    close: () => void;
}
export const AccessBuro: React.FC<AccesBuroProps> = ({ close }: AccesBuroProps) => {
    const localStorageClass = new LocalStorageClass();
    const languageState = useSelector((state: RootStore) => state.language);
    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());
    const history = useHistory();
    //menu buro
    const [accesBuro, setAccesBuro] = useState<boolean>(false);
    const specialActionsState = useSelector((state: RootStore) => state.specialActions.specialActions);

    useEffect(() => {

        validaAccesoBuro();

    }, [specialActionsState]);

    const validaAccesoBuro = async () => {
        // setAccesBuro(false);
        //Acceso apartado Buro
        // console.log('buscar acceso')
        //  console.log(specialActionsState)

        if (specialActionsState != undefined) {
            const found = specialActionsState.find((action) => action.descripcionAccion === "Acceso buro credito")
            if (found) {
                //console.log('Lo encontreeee!')
                setAccesBuro(true);
            } else {
                setAccesBuro(false);
            }

        }


    };


    return (
        <>
            {(accesBuro) ? (

                <Card>
                        <Card.Body>
                            <ul>
                                {
                                    validRoles(PERFIL.roles, localStorageClass.BURO) ? (
                                        <li onClick={() => {
                                            history.push('/buro-credito/Auditoria')
                                            close()
                                        }}>
                                            {languageState.language?.navBar.titulo13!}
                                        </li>
                                ) : null}



                            </ul>
                        </Card.Body>
                </Card>
            ) : null}
        </>
    )
}
