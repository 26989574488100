import React, { Component} from "react";
import {RouteComponentProps} from "react-router-dom";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import './Menu.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import {RootStore} from "../../../Store";
import {connect} from "react-redux";
import {DefaultStateI} from "../../../reducer/LanguageReducer";
import {UserModel} from "../../../models/public/UserModel";
import {AxiosError} from "axios";
import * as apiTokenService from "../../../services/api-session/ApiSession";
import {AlphanumericInput} from "@lmig/lmds-react";
import {InputChange, validRoles} from "../../../class/CommonClass";
import warranty from '../../../assets/images/public/menu/warranty.png';
import handshake from '../../../assets/images/public/menu/handshake.png';
import creditBureu from '../../../assets/images/public/menu/credit.png';
import calculator from '../../../assets/images/public/menu/calculator.png';

interface MenuProps {
    language: DefaultStateI
}

class Menu extends Component<MenuProps & RouteComponentProps , MenuState>{

    public localStorageClass = new LocalStorageClass();

    constructor(props: any) {
        super(props);
        document.title = "Menu"

        const PERFIL = this.localStorageClass.getProfile();
        //si no hay un token guardado
        if (!PERFIL.accessToken){
            // redireccionar
            this.props.history.push('/login');
        }
        this.state = {
            PERFIL: PERFIL,
            search: '',
            visibleEnaggments: true,
            visibleLoans:  true,
            visibleBureau:  true,
            visibleAcountability:  true,
            visibleRH:  true,

        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.buroacces = this.buroacces.bind(this);

    }

    render() {

        return <>
            <NavBar/>

            <div className="container-fluid" id="menu">
                <div className="row">
                    <div className="col-md-3"/>

                    <div className="col-md-6 card card-menu" >

                        <h3 className="text-left ">Bienvenido&nbsp;
                            <span className="text-uppercase">
                                {this.state.PERFIL.username.substring(0,1)}
                            </span>
                            <span className="text-lowercase">
                              {this.state.PERFIL.username.substring(1,this.state.PERFIL.username.length)}
                            </span>:
                        </h3>
                        <AlphanumericInput labelVisual="Buscar Modulo"
                                           className="input-user-name"
                                           autoComplete="off"
                                           autoFocus={true}
                                           name="searchModule"
                                           onChange={this.handleInputChange}
                                           value={this.state.search}
                                           type="text" />
                        <br/>



                        <div className="row" id="modules">

                            {(this.state.visibleEnaggments)?(
                                <>
                                    {( validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO) ||
                                        validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) ||
                                        validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) ||
                                        validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR) ||
                                        validRoles(this.state.PERFIL.roles, this.localStorageClass.BURO) ||
                                        validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS)) ?
                                        <div className="mb-3 col-md-6"
                                             onClick={()=>{
                                                     this.props.history.push('/compromisos')
                                             }}>
                                            <div className="card bg-light">
                                                <div className="row no-gutters">
                                                    <div className="col-4 engagments">
                                                        <img src={handshake} className="img-fluid d-block mx-auto"/>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="card-body">
                                                            <div className="title-module">
                                                                Compromisos
                                                            </div>
                                                            <div className="see-module">
                                                                Ver Modulo
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        :null}

                                </>

                            ): null}

                            {(this.state.visibleLoans)?(
                                <>
                                    {(
                                        validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVODEGARANTIAS) ||
                                        validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) ||
                                        validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) ||
                                        validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR) ||
                                        validRoles(this.state.PERFIL.roles, this.localStorageClass.AUDITOR)

                                    )?(
                                        <div className="mb-3 col-md-6" >

                                            <div className="card bg-light"
                                                 onClick={()=>{
                                                     this.props.history.push('/garantias')
                                                 }}
                                            >
                                                <div className="row no-gutters">
                                                    <div className="col-4 warranties">
                                                        <img src={warranty} className="img-fluid d-block mx-auto"/>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="card-body">
                                                            <div className="title-module">
                                                                Garantías
                                                            </div>
                                                            <div className="see-module">
                                                                Ver Modulo
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ): null}
                                </>
                            ): null}


                            {(this.state.visibleBureau)?(
                                <>
                                    {validRoles(this.state.PERFIL.roles, this.localStorageClass.BURO)?(
                                    <div className="mb-3 col-md-6"
                                         onClick={this.buroacces}>

                                        <div className="card bg-light">
                                            <div className="row no-gutters">
                                                <div className="col-4 credit-bureu">
                                                    <img src={creditBureu} className="img-fluid d-block mx-auto"/>
                                                </div>
                                                <div className="col-8">
                                                    <div className="card-body">
                                                        <div className="title-module">
                                                            Buro de Crédito
                                                        </div>
                                                        <div className="see-module">
                                                            Ver Modulo
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                     ): null}
                                </>
                            ): null}



                            {(this.state.visibleAcountability)?(
                                <>
                                    <div className="mb-3 col-md-6"
                                         onClick={()=>{
                                              this.props.history.push('/rh/polizas')
                                         }}>
                                        <div className="card bg-light">
                                            <div className="row no-gutters">
                                                <div className="col-4 accounting">
                                                    <img src={calculator} className="img-fluid d-block mx-auto"/>
                                                </div>
                                                <div className="col-8">
                                                    <div className="card-body">
                                                        <div className="title-module">
                                                            Contabilidad
                                                        </div>
                                                        <div className="see-module">
                                                            Ver Modulo
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </>
                            ): null}


                  {/*          {(this.state.visibleRH)?(
                                <>
                                              {(validRoles(this.state.PERFIL.roles, this.localStorageClass.USUARIOCONTABLE)) ?
                                    <div className="mb-3 col-md-6"
                                         onClick={()=>{
                                             //  this.props.history.push('/rh/polizas')
                                         }}>
                                        <div className="card bg-light">
                                            <div className="row no-gutters">
                                                <div className="col-4 human-resources">
                                                    <img src={target} className="img-fluid d-block mx-auto"/>
                                                </div>
                                                <div className="col-8">
                                                    <div className="card-body">
                                                     <span className="badge badge-pill badge-warning">
                                                             Desarrollo&nbsp;
                                                         <BsCode/>
                                                        </span>
                                                        <div className="title-module">
                                                            Recursos Humanos
                                                        </div>
                                                        <div className="see-module">
                                                            Ver Modulo
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                           : null }
                                </>
                            ): null}*/}













                        </div>


                    </div>

                    <div className="col-md-3"/>
                </div>
            
            </div>
            <br/><br/><br/><br/>
        </>
    }

    componentDidMount(){

    }

    componentWillMount(){

    }

    public buroacces (){
        if(validRoles(this.state.PERFIL.roles, this.localStorageClass.BURO)){
            this.props.history.push('/buro-credito')
        }
        if(validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE)){
            this.props.history.push('/buro-credito/Solicitudes')
        }
    }

    public handleInputChange  (e: InputChange) {
        const value: string = e.target.value.toLowerCase();
        this.setState({search: value});

        if (value.length== 0){
            this.setState({
                visibleEnaggments: true,
                visibleLoans:  true,
                visibleBureau:  true,
                visibleAcountability:  true,
                visibleRH:  true,
            })

        }else {
            if (value.match(/^gar.*$/) || value.match(/^tía.*$/)) {
                // do something
                this.setState({visibleLoans: true})
            }else {
                this.setState({visibleLoans: false});
            }

            if (value.match(/^compr.*$/) || value.match(/^misos.*$/)) {
                // do something
                this.setState({visibleEnaggments: true})
            }else {
                this.setState({visibleEnaggments: false});
            }


            if (value.match(/^bur.*$/) || value.match(/^uro.*$/)) {
                // do something
                this.setState({visibleBureau: true})
            }else {
                this.setState({visibleBureau: false});
            }

            if (value.match(/^cont.*$/) || value.match(/^idad.*$/)) {
                // do something
                this.setState({visibleAcountability: true})
            }else {
                this.setState({visibleAcountability: false});
            }


            if (value.match(/^rec.*$/) || value.match(/^humanos.*$/) || value.match(/rh.*/)) {
                // do something
                this.setState({visibleRH: true})
            }else {
                this.setState({visibleRH: false});
            }

        }



    }

    private async validToken(){
        try {
            const res = await apiTokenService.getValidaToken(this.state.PERFIL.accessToken);
            // console.log(res.data)

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                if (err.response.status ==400){
                    console.log(err.response.status)
                    console.log(err.response.data)
                    this.props.history.push('/');
                }


            }
        }
    }

    private serchModules() {

    }
}

const mapStateToProps = (state: RootStore)  => ({
    language: state.language
});


export default connect(mapStateToProps) (Menu);

interface MenuState {
    PERFIL: UserModel;
    search: string;
    visibleEnaggments: boolean;
    visibleLoans: boolean;
    visibleBureau: boolean;
    visibleAcountability: boolean;
    visibleRH: boolean;

}



