import React, { useEffect} from 'react';
import {
    IconAddDriver, IconAgent, IconCaretDown, IconCaretUp, IconClock,
    IconMyAccount, IconSpecialist
} from "@lmig/lmds-react";
import './FooterWarrantyReview.scss';
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";

interface FooterWarrantyReviewProps {
    impact: string;
    idUserType: number;
    time: string
}

export const FooterWarrantyReview: React.FC<FooterWarrantyReviewProps> = ({impact,idUserType,  time}:FooterWarrantyReviewProps)=> {


    // redux
    const languageState = useSelector((state: RootStore) => state.language);



    useEffect(() => {


    },[]);



    return (
        <>
            {(impact=='1')?
                <IconCaretUp size="24" color="success" className=""/>
                : null
            }
            {(impact=='0')?
                <IconCaretDown size="24" className=""/>
                : null
            }
            &nbsp;

            {(idUserType==1)?
                <IconMyAccount size="24" className="" title="Agente"/>
                : null
            }
            {(idUserType==2)?
                <IconSpecialist size="24" className="" title="Broker"/>
                : null
            }
            {(idUserType==3)?
                <IconAddDriver size="24" className="" title="Platino"/>
                : null
            }
            {(idUserType==4)?
                <IconAgent size="24" className="" title="Ejecutivo de Cuenta"/>
                : null
            }
            &nbsp;
            <IconClock size="24" className=""/>&nbsp;{time}

        </>
    );
};






