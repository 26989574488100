export interface PrincipalDetailLanguage {
    title: string;
    home: string;
    fiado: string;
    cumulus: string;
    garantias: string;
    comments: string;
    tableResume: string;
    tableLineas: string;
    tableLoans: string;
    createWarranty: string;
    resume: string;
}

export const PrincipalDetailLanguageEnglish: PrincipalDetailLanguage ={
    title: 'Principal details',
    home: 'Home',
    fiado: 'Principal',
    cumulus: 'Cumulus',
    garantias: 'Loans',
    comments: 'Comments',
    tableResume: 'Resumen of Loans',
    tableLineas: 'Bonding Lines',
    tableLoans: 'Table of Collaterals',
    createWarranty: 'Create Loan',
    resume: 'Resume'
}
export const PrincipalDetailLanguageSpanish: PrincipalDetailLanguage ={
    title: 'Detalles del Fiado',
    home: 'Inicio',
    fiado: 'Fiado',
    cumulus: 'Cúmulo',
    garantias: 'Garantías',
    comments: 'Comentarios',
    tableResume: 'Resumen de Garantías',
    tableLineas: 'Línea  Afianzamiento',
    tableLoans: 'Tabla de Garantías',
    createWarranty: 'Crear Garantía',
    resume: 'Resumen'
}