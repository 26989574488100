import {LocalStorageClass} from "../../../class/LocalStorageClass";
import React, { useEffect, useState} from "react";
import {
    Select,
    SelectOption,
    Toggle
} from "@lmig/lmds-react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {UserModel} from "../../../models/public/UserModel";
import {InputChange, validRoles} from "../../../class/CommonClass";
import * as apiService from '../../../services/api-gral/ApiGralService';
import {AxiosError} from "axios";
import {EjecutivoListModel} from "../../../models/public/EjecutivoListModel";
import {AgenteListModel} from "../../../models/public/AgenteListModel";
import {TypeProfileEnum} from "../../../models/garantias/TypeProfileEnum";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import './SelectTypeUser.scss';
import {SearchAutocomplete} from "../../public/search-autocomplete/SearchAutocomplete";
import {ItemModel} from "../../../models/public/ItemModel";

interface SelectAgentProps {
    reciveTypeProfile: (tipoPerfil: TypeProfileModel, idOficina?: number) => void;
}

const SelectTypeUser: React.FC<SelectAgentProps>  = ({reciveTypeProfile}) => {
    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    const localStorageClass = new LocalStorageClass();

    const initialStateContact: SelectModel = {
        idEjecutivoGarantias: 0,
        idAuditor: 0,
        idEjecutivo: 0,
        idAgente: 0
    }

    const [isClientDirect, setIsClientDirect] = useState<boolean>(true);

    const [visibleAgents, setVisibleAgents] = useState<boolean>(false);

    const [select, setSelect] = useState<SelectModel>(initialStateContact);

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [loadingProfileID, setLoadingProfileID] = useState<boolean>(false);

    const [errorProfileID, setErrorProfileID] = useState<boolean>(false);

    const [listEjecutivo, setListEjecutivo] = useState<EjecutivoListModel[]>([]);

    const [listAgentes, setListAgentes] = useState<AgenteListModel[]>([]);

    const [isEjecutivoGar, setIsEjecutivoGar] = useState<boolean>(false);

    const [idEjecutivoGar, setIdEjecutivoGar] = useState<number>(0);

    const [idEjecutivoCuenta, setIdEjecutivoCuenta] = useState<number>(0);

    const [idAgente, setIdAgente] = useState<number>(0);

    const [idPromotor, setIdPromotor] = useState<number>(0);

    const [idAuditor, setIdAuditor] = useState<number>(0);

    const [idOficina, setIdOficina] = useState<number>(0);

    const [itemsOffices, setItemsOffices] = useState<ItemModel[]>([]);


    useEffect(() => {
        if  (validRoles(PERFIL.roles,localStorageClass.EJECUTIVODEGARANTIAS)){
            getPerfilEjeGar();
        }else {
            if  (validRoles(PERFIL.roles,localStorageClass.EJECUTIVOCUENTA)){
                getPerfilEjeCuentas();
            }
            else {
                if  (validRoles(PERFIL.roles,localStorageClass.AGENTE)){
                    getPerfilAgente();
                }
               else {
                    if (validRoles(PERFIL.roles, localStorageClass.PROMOTOR)){
                        getPerfilPromotor();
                    }
                    else if (validRoles(PERFIL.roles, localStorageClass.AUDITOR) &&
                        ( !validRoles(PERFIL.roles, localStorageClass.AGENTE) ||
                            !validRoles(PERFIL.roles, localStorageClass.PROMOTOR))


                    ){
                        getPerfilAuditor();
                    }

                }
            }

        }


    },[]);

    const getPerfilEjeGar =  async () => {
        setErrorProfileID(false);
        setLoadingProfileID(true);
        try {
            const res = await apiService.getPerfilEjeGar();

            const idEjecutivoGar = res.data.idEjecutivoGar;

            returnTypeProfile({id: idEjecutivoGar, tipo: TypeProfileEnum.idEjGarantias}, idOficina)
            setIdEjecutivoGar(idEjecutivoGar)
            setIsEjecutivoGar(true);
            setErrorProfileID(false);
            setLoadingProfileID(false);
            getOficinas('all'); // traer todas las oficinas del ejecutivo de garantias

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setErrorProfileID(true);
                setLoadingProfileID(false);
            }
        }
    };

    const getPerfilEjeCuentas =  async () => {
        setErrorProfileID(false);
        setLoadingProfileID(true);
        try {
            const res = await apiService.getPerfilEje();
            //  console.log(res.data);
            setIdEjecutivoCuenta(res.data.idEjecutivo)
            returnTypeProfile({id: res.data.idEjecutivo, tipo: TypeProfileEnum.idEjecutivo}, idOficina)
            //setIsEjecutivoCuenta(true);
            getAgentes(null, res.data.idEjecutivo);
            setErrorProfileID(false);
            setLoadingProfileID(false);
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setErrorProfileID(true);
                setLoadingProfileID(false);
            }
        }
    };

    const getPerfilAgente =  async () => {
        setErrorProfileID(false);
        setLoadingProfileID(true);
        try {
            const res = await apiService.getPerfilAgente();
            console.log(res.data);
            setIdAgente(res.data.idAgente)
            returnTypeProfile({id: res.data.idAgente, tipo: TypeProfileEnum.idAgente}, idOficina)
            setErrorProfileID(false);
            setLoadingProfileID(false);
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setErrorProfileID(true);
                setLoadingProfileID(false);
            }
        }
    };

    const  getPerfilAuditor =  async () => {
        setErrorProfileID(false);
        setLoadingProfileID(true);
        try {
            const res = await apiService.getPerfilAuditor();
            console.log(res.data);
            setIdAuditor(res.data.idAuditor)
            returnTypeProfile({id: res.data.idAuditor, tipo: TypeProfileEnum.idAuditor}, idOficina)
            setErrorProfileID(false);
            setLoadingProfileID(false);

            getOficinas('all'); // traer todas las oficinas del ejecutivo de garantias
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setErrorProfileID(true);
                setLoadingProfileID(false);
            }
        }
    };

    const getPerfilPromotor =  async () => {
        setErrorProfileID(false);
        setLoadingProfileID(true);
        try {
            const res = await apiService.getPerfilPromotor();
            console.log(res.data);
            setIdPromotor(res.data.idPromotor)
            returnTypeProfile({id: res.data.idPromotor, tipo: TypeProfileEnum.idPromotor}, idOficina)
            setErrorProfileID(false);
            setLoadingProfileID(false);
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setErrorProfileID(true);
                setLoadingProfileID(false);
            }
        }
    };

    const getAgentes = async (idEjecutivoGarantias: number | null, idEjecutivoCuentas: number| null) => {
        setSelect({ ...select, idAgente: 0});
        try {
            const res = await apiService.getAgentes(idEjecutivoGarantias, idEjecutivoCuentas, null);
            console.log(res.data);
            setListAgentes(res.data);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    const getEjecutivosCuenta = async (idEjecutivoGarantias: number, idOficina: number | null) => {
        console.log('get ejecutivos de cuenta');

        setVisibleAgents(false);
        setSelect({ ...select, idEjecutivo: 0});

        try {
            const res = await apiService.getEjecutivosCuenta(idEjecutivoGarantias,  null, idOficina);
            //  console.log(res.data);
            setListEjecutivo(res.data)

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    };

    const getOficinas = async (search: string) => {
        try {
            const res = await apiService.getBusquedaOficinas(search);
            let auxItems: ItemModel [] = []
            for (const oficina of res.data){
                auxItems.push({id: oficina.idOficina, name: oficina.nomOficina})
            }
            setItemsOffices(auxItems)

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    const handleInputChangeSelect = (e: InputChange) => {


        if (e.target.name == 'idEjecutivo'){
            setVisibleAgents(true);
            const idEjecutivoCuenta = parseInt(e.target.value);
            if (idEjecutivoCuenta>0){
                returnTypeProfile({id: idEjecutivoCuenta, tipo: TypeProfileEnum.idEjecutivo}, idOficina)
                getAgentes(null, idEjecutivoCuenta)
            }

        }
        if (e.target.name == 'idAgente'){
            const idAgente = parseInt(e.target.value);
            if (idAgente>0){
                returnTypeProfile({id: idAgente, tipo: TypeProfileEnum.idAgente}, idOficina)
            }

        }

        setSelect({ ...select, [e.target.name]: parseInt(e.target.value) });
    }

    const handleToogle = async (e: any) =>{
        // setContact({ ...contact, [e.target.name]: e.target.value });
        const name = e.target.name;
        if (name == 'isEjecutivoGar'){
            const  auxIsEjecutivoGar = isEjecutivoGar? false: true
            setIsEjecutivoGar(auxIsEjecutivoGar)
            if (auxIsEjecutivoGar){
                setIdEjecutivoCuenta(0);

                returnTypeProfile({id: idEjecutivoGar, tipo: TypeProfileEnum.idEjGarantias}, idOficina)
            }else{
                getEjecutivosCuenta(idEjecutivoGar, null);
            }

        }
        if (name == 'isClientDirect'){
            const  auxIsClientDirect = isClientDirect? false: true
            setIsClientDirect(auxIsClientDirect)
            if (auxIsClientDirect){
                returnTypeProfile({id: idEjecutivoCuenta, tipo: TypeProfileEnum.idEjecutivo}, idOficina)
            }else{
                getAgentes(null, idEjecutivoCuenta);
            }
        }

    }

    const returnTypeProfile = (typeProfile: TypeProfileModel, idOficina: number | null) => {

        //y lo regresa al compoenete padre
        reciveTypeProfile(typeProfile, idOficina!);
    }

    const onSelectOffices = (itemSelected: ItemModel) => {
        const idOficina = itemSelected.id!;
        setIdOficina(idOficina);

        if (validRoles(PERFIL.roles, localStorageClass.AUDITOR)){
            returnTypeProfile({id: 0, tipo: TypeProfileEnum.idAuditor}, idOficina);
        }

        if  (validRoles(PERFIL.roles,localStorageClass.EJECUTIVODEGARANTIAS)){
            returnTypeProfile({id: idEjecutivoGar, tipo: TypeProfileEnum.idEjGarantias}, idOficina);
        }

        getEjecutivosCuenta(idEjecutivoGar, idOficina);
    }

    const onClearOffices = () => {
        setIdOficina(0);

        if (validRoles(PERFIL.roles, localStorageClass.AUDITOR)){
            setSelect({ ...select, idEjecutivo: 0, idAgente: 0, idAuditor: idAuditor});
            setIdOficina(0);
            returnTypeProfile({id: idAuditor, tipo: TypeProfileEnum.idAuditor}, null);
        }

        if  (validRoles(PERFIL.roles,localStorageClass.EJECUTIVODEGARANTIAS)){
            setSelect({ ...select, idEjecutivo: 0, idAgente: 0, idEjecutivoGarantias: idEjecutivoGar});
            setIdOficina(0);
            returnTypeProfile({id: idEjecutivoGar, tipo: TypeProfileEnum.idEjGarantias}, null);
        }
    }

    return (
        <>
            <div className="row" id="select-type-user">

                {
                    (loadingProfileID)? (
                            <div className="col-12">
                                <SpinnerLoading text={languageState.language?.selectAgent.loadingProfile}/>
                            </div>):
                        (errorProfileID)? (
                                <div className="col-12">
                                    <NegativeAlert
                                        textAlert={languageState.language?.selectAgent.sorrySomethingHappened!}/>
                                </div>):
                            <div className="col-12">


                                {/*Ejecutivo de garantias   */ }
                                {
                                    (validRoles(PERFIL.roles,localStorageClass.EJECUTIVODEGARANTIAS))? (
                                        <div className="row" id="ejecutivo-gar">
                                            <div className="col-md-12">
                                                <Toggle
                                                    compact
                                                    dynamicWidth
                                                    onChange={handleToogle}
                                                    defaultChecked={isEjecutivoGar}
                                                    name='isEjecutivoGar'
                                                    checked={isEjecutivoGar}
                                                    labelVisual={languageState.language?.selectAgent.isExecutiveGarantias} />
                                            </div>


                                            {(!isEjecutivoGar )?
                                                <>
                                                    <div className="col-md-4 search-oficces">
                                                        {/*Select oficinas*/}
                                                        <SearchAutocomplete
                                                            items={itemsOffices}
                                                            placeHolder={languageState.language?.selectAgent.searchOffices!}
                                                            onSelect={onSelectOffices}
                                                            onSearch={()=> {}}
                                                            onClear={onClearOffices}
                                                        />


                                                    </div>

                                                    <div className="col-md-4">

                                                        <Select
                                                            labelA11y={languageState.language?.selectAgent.selectExecutive}
                                                            labelVisual={languageState.language?.selectAgent.selectExecutive}
                                                            onChange={function placeholderOptionOnChange(event: React.FormEvent<HTMLSelectElement>){
                                                                console.log(event.currentTarget)
                                                                handleInputChangeSelect(event)
                                                            }}
                                                            value={String(select.idEjecutivo)}
                                                            name="idEjecutivo"
                                                            placeholderOption={languageState.language?.selectAgent.selectExecutive}
                                                        >
                                                            <SelectOption value='0'>{languageState.language?.selectAgent.selectOption} </SelectOption>
                                                            {
                                                                listEjecutivo.map((i, index) =>
                                                                    (   <SelectOption key={i.idEjecutivo} value={String(i.idEjecutivo)}>
                                                                            {i.nombre+ ' ' + i.apPaterno + ' ' + i.apMaterno}
                                                                        </SelectOption>
                                                                    ))
                                                            }
                                                        </Select>
                                                    </div>
                                                </>

                                                : null }
                                            {(!isEjecutivoGar && visibleAgents)?
                                                <>
                                                    <div className="col-md-4">
                                                        <Select
                                                            labelA11y={languageState.language?.selectAgent.selectAgent}
                                                            labelVisual={languageState.language?.selectAgent.selectAgent}
                                                            onChange={function placeholderOptionOnChange(event: React.FormEvent<HTMLSelectElement>){
                                                                console.log(event.currentTarget)
                                                                handleInputChangeSelect(event)
                                                            }}
                                                            name="idAgente"
                                                            value={String(select.idAgente)}
                                                            placeholderOption={languageState.language?.selectAgent.selectAgent}
                                                        >
                                                            <SelectOption value='0'>{languageState.language?.selectAgent.selectOption} </SelectOption>
                                                            {
                                                                listAgentes.map((i, index) =>
                                                                    (   <SelectOption key={i.idAgente} value={String(i.idAgente)}>
                                                                            {i.nombre+ ' ' + i.apPaterno + ' ' + i.apMaterno}
                                                                        </SelectOption>
                                                                    ))
                                                            }
                                                        </Select>
                                                    </div>
                                                </>

                                                : null }


                                        </div>): <>

                                        {/*Ejecutivo de cuentas   */}
                                        { validRoles(PERFIL.roles,localStorageClass.EJECUTIVOCUENTA)  ? (
                                            <div className="col-12">
                                                <div className="row">
                                                    {/*       Ejecutivo de cuentas  Cliente directo  */}
                                                    <div className="col-md-12">
                                                        <Toggle
                                                            compact
                                                            dynamicWidth
                                                            onChange={handleToogle}
                                                            name="isClientDirect"
                                                            defaultChecked={isClientDirect}
                                                            checked={isClientDirect}
                                                            labelVisual={languageState.language?.selectAgent.clientDirect} />
                                                    </div>
                                                    {
                                                        ( !isClientDirect )? (
                                                            <div className="col-md-4">
                                                                <Select
                                                                    labelA11y={languageState.language?.selectAgent.selectAgent}
                                                                    labelVisual={languageState.language?.selectAgent.selectAgent}
                                                                    onChange={function placeholderOptionOnChange(event: React.FormEvent<HTMLSelectElement>){
                                                                        console.log(event.currentTarget)
                                                                        handleInputChangeSelect(event)
                                                                    }}
                                                                    name="idAgente"
                                                                    value={String(select.idAgente)}
                                                                    placeholderOption={languageState.language?.selectAgent.selectAgent}
                                                                >
                                                                    <SelectOption value=''>{languageState.language?.selectAgent.selectOption} </SelectOption>
                                                                    {
                                                                        listAgentes.map((i, index) =>
                                                                            (   <SelectOption key={i.idAgente} value={String(i.idAgente)}>
                                                                                    {i.nombre+ ' ' + i.apPaterno + ' ' + i.apMaterno}
                                                                                </SelectOption>
                                                                            ))
                                                                    }
                                                                </Select>
                                                            </div>): null
                                                    }
                                                </div>

                                            </div>
                                        ): <>

                                            {/*Auditor   */ }
                                            {
                                                (validRoles(PERFIL.roles,localStorageClass.AUDITOR) &&
                                                    !validRoles(PERFIL.roles, localStorageClass.AGENTE) &&
                                                    !validRoles(PERFIL.roles, localStorageClass.PROMOTOR)
                                                )? (
                                                    <div className="row" id="auditor">

                                                        <div className="col-md-4 search-oficces">
                                                            {/*Select oficinas*/}
                                                            <SearchAutocomplete
                                                                items={itemsOffices}
                                                                placeHolder={languageState.language?.selectAgent.searchOffices!}
                                                                onSelect={onSelectOffices}
                                                                onSearch={()=> {}}
                                                                onClear={onClearOffices}
                                                            />
                                                        </div>
                                                        {(idOficina)?(
                                                            <>
                                                                <div className="col-md-4">

                                                                    <Select
                                                                        labelA11y={languageState.language?.selectAgent.selectExecutive}
                                                                        labelVisual={languageState.language?.selectAgent.selectExecutive}
                                                                        onChange={function placeholderOptionOnChange(event: React.FormEvent<HTMLSelectElement>){
                                                                            console.log(event.currentTarget)
                                                                            handleInputChangeSelect(event)
                                                                        }}
                                                                        value={String(select.idEjecutivo)}
                                                                        name="idEjecutivo"
                                                                        placeholderOption={languageState.language?.selectAgent.selectExecutive}
                                                                    >
                                                                        <SelectOption value='0'>{languageState.language?.selectAgent.selectOption} </SelectOption>
                                                                        {
                                                                            listEjecutivo.map((i, index) =>
                                                                                (   <SelectOption key={i.idEjecutivo} value={String(i.idEjecutivo)}>
                                                                                        {i.nombre+ ' ' + i.apPaterno + ' ' + i.apMaterno}
                                                                                    </SelectOption>
                                                                                ))
                                                                        }
                                                                    </Select>
                                                                </div>

                                                                <div className="col-md-4"></div>
                                                            </>
                                                        ): null}


                                                        {(visibleAgents)?
                                                            <>
                                                                <div className="col-md-4">
                                                                    <Select
                                                                        labelA11y={languageState.language?.selectAgent.selectAgent}
                                                                        labelVisual={languageState.language?.selectAgent.selectAgent}
                                                                        onChange={function placeholderOptionOnChange(event: React.FormEvent<HTMLSelectElement>){
                                                                            console.log(event.currentTarget)
                                                                            handleInputChangeSelect(event)
                                                                        }}
                                                                        name="idAgente"
                                                                        value={String(select.idAgente)}
                                                                        placeholderOption={languageState.language?.selectAgent.selectAgent}
                                                                    >
                                                                        <SelectOption value='0'>{languageState.language?.selectAgent.selectOption} </SelectOption>
                                                                        {
                                                                            listAgentes.map((i, index) =>
                                                                                (   <SelectOption key={i.idAgente} value={String(i.idAgente)}>
                                                                                        {i.nombre+ ' ' + i.apPaterno + ' ' + i.apMaterno}
                                                                                    </SelectOption>
                                                                                ))
                                                                        }
                                                                    </Select>
                                                                </div>
                                                            </>

                                                            : null }


                                                    </div>): null
                                            }
                                        </>}

                                    </>
                                }




                            </div>
                }

            </div>
        </>
    );

};

export default SelectTypeUser;

interface SelectModel {
    idEjecutivoGarantias: number;
    idAuditor: number;
    idEjecutivo: number;
    idAgente: number;
}



export interface TypeProfileModel {
    id: number,
    tipo: TypeProfileEnum
}
export
const  initTipoPerfil: TypeProfileModel = {
    id: 0,
    tipo: TypeProfileEnum.default
};
