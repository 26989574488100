import Permission from "../models/users-management/Permission";

export const LOAD_PERMISSIONS = "LOAD_PERMISSIONS";

export const LOAD_PERMISSIONS_SUCCESS = "LOAD_PERMISSIONS_SUCCESS";

export const LOAD_PERMISSIONS_FAIL = "LOAD_PERMISSIONS_FAIL";

export interface PermissionsLoading {
  type: typeof LOAD_PERMISSIONS;
}

export interface PermissionsFail {
  type: typeof LOAD_PERMISSIONS_FAIL;
}

export interface PermissionsSuccess {
  type: typeof LOAD_PERMISSIONS_SUCCESS;
  payload: Permission[];
}

export type PermissionDispatchTypes =
  | PermissionsLoading
  | PermissionsFail
  | PermissionsSuccess;
