import {
    LanguageModel,
    SelectLanguageDispatchTypes
} from "../actions/SelectLanguageActionTypes";
import {ExpedienteIdetificacionModel} from "../models/garantias/ExpedienteIdetificacionModel";
import {initExpedienteIdentification} from "../class/CommonClass";
import {LOAD_PERMISSIONS, LOAD_PERMISSIONS_FAIL, LOAD_PERMISSIONS_SUCCESS} from "../actions/PermissionsActionTypes";
import {
    CREATE_EXPEDIENTE,
    DELETE_EXPEDIENTE,
    ExpedientIdentificationDispatchTypes,
    MOVE_EXPEDIENTE
} from "../actions/ExpedienteIdentificationActionTypes";

export interface ExpedienteIdentificationReducerModel {
    idExpedienteDestinity: number,
    expediente: ExpedienteIdetificacionModel
}

const defaultState: ExpedienteIdentificationReducerModel = {
    idExpedienteDestinity: 0,
    expediente: initExpedienteIdentification
};

const expedienteIdentificationReducer = (state: ExpedienteIdentificationReducerModel = defaultState,
                               action: ExpedientIdentificationDispatchTypes) : ExpedienteIdentificationReducerModel => {
    switch (action.type) {
        case CREATE_EXPEDIENTE:
            return {
                idExpedienteDestinity: action.payload.idExpedienteDestinity,
                expediente: action.payload.expediente
            };
        case MOVE_EXPEDIENTE:
            return {
                idExpedienteDestinity: action.payload.idExpedienteDestinity,
                expediente: action.payload.expediente
            };
        case DELETE_EXPEDIENTE:
            return {
                idExpedienteDestinity: action.payload.idExpedienteDestinity,
                expediente: action.payload.expediente
            };
        default:
            return state;
    }

};
export default expedienteIdentificationReducer;