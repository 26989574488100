import {ChangeEvent} from "react";
import {UserModel} from "../models/public/UserModel";
import {PaginacionModel} from "../models/public/PaginacionModel";
import {LanguageModel} from "../models/public/LanguageModel";
import flagMexico from "../assets/images/public/nav/flagMexico-modified.png";
import flagUnitedStates from "../assets/images/public/nav/flagUnitedSatates-modified.png";
import {LocalStorageClass} from "./LocalStorageClass";
import {directionModal} from "../models/public/DirectionModal";
import {statesDocIdentification} from "../models/garantias/statesDocIdentification";
import {LineaAfinzamiento} from "../models/garantias/LineaAfinzamiento";
import {MsgNuevaLineaAfianzamiento} from "../models/garantias/MsgNuevaLineaAfianzamiento";
import {DocAclaracionFirmasCSAM} from "../models/garantias/DocAclaracionFirmasCSAM";

export const MAXSIZEUPLOADFILE = 52428800; // 50 MB = 52,428,800 Bytes (in binary)
export type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any >;
export class CommonClass {

    constructor() {
    }

    public setSomeString(key: string, value: string): void {
            localStorage.setItem(key, value);
        }
}
    export const formatValue = (value: string | number): any  => {

        if (isNaN(Number(value))){
            return value;
        }else{
            return Number(value);
        }
    };

    export const validDate = (value: string): string  => {
       // console.log('fomat date: len:' + value.length + ' value: ' + value);
        let year = '';
        let posGuion= 0;
            for (let i=0 ; i<value.length; i++){
           //     console.log('['+ i + ']' + value.substring(i,i+1))
                if (value.substring(i,i+1) == '-'){
                    posGuion = i;
                    if (i>4){
                        year = value.substring(posGuion-4,posGuion);
                    }
                    else {
                        year = value.substring(0,4);
                    }
                    break;
                }

            }
            const  newDate = year+'' + value.substring(posGuion, value.length)

        return  newDate
    };

    export const minToday = (): string  => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
    }

    export const maxDate =  new Date('9999-12-31')

    export const todayDate = new Date(minToday())

    export const nowDate = (): string => {
        const d = new Date();
        return d.toISOString();
    }

    export const dateFormat =  new Date().toISOString().slice(0, 10);
    export const formatCurrency = (number: number): any  => {
        return new Intl.NumberFormat('en-US',{
            style: 'currency',
            currency: 'USD',
        }).format(number)
    };

    export const validFileImg = (e: ChangeEvent<HTMLInputElement>): boolean  => {

            if (e.target.files![0].type =='application/pdf' ||
                e.target.files![0].type =='image/png' ||
                e.target.files![0].type =='image/jpeg'){
                if (e.target.files![0].size <= MAXSIZEUPLOADFILE){
                    return true;
                }
            }
        return false;
    };

export const validExpedienteIden = (e: ChangeEvent<HTMLInputElement>): boolean  => {

    if (e.target.files![0].type =='application/pdf' ||
        e.target.files![0].type =='image/png' ||
        e.target.files![0].type =='image/jpeg'){
        if (e.target.files![0].size <= MAXSIZEUPLOADFILE){
            return true;
        }
    }
    return false;
};
export const validExpedienteCorreos = (e: ChangeEvent<HTMLInputElement>): boolean  => {

    if (e.target.files![0].type =='application/pdf' ||
        e.target.files![0].type =='image/png' ||
        e.target.files![0].type =='image/jpeg' ||
        e.target.files![0].type == 'message/rfc822'){
        if (e.target.files![0].size <= MAXSIZEUPLOADFILE){
            return true;
        }
    }
    return false;
};
export const validFileExpediente = (e: ChangeEvent<HTMLInputElement>): boolean  => {

    console.log(e.target.files![0].type)
    if (e.target.files![0].type =='application/pdf' ||
        e.target.files![0].type =='image/png' ||
        e.target.files![0].type =='image/jpeg' ||
        e.target.files![0].type =='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        e.target.files![0].type =='application/vnd.ms-excel' ||
        e.target.files![0].type =='text/csv'){
        if (e.target.files![0].size <= MAXSIZEUPLOADFILE){
            return true;
        }
    }
    return false;
};



// esta funcion busca en la lista de roles la cadena que le pases
    export const validRoles = ( listRoles: string[], perfil: string): boolean  => {
        for (const roll of listRoles){
            if (roll== perfil){
                return  true;
            }
        }

        return false;
    }
    // funcion que crea las paginaciones
    export const loadPaginationList =  (ipaginacion: PaginacionModel)=> {
        const totalPages: number =  ipaginacion.totalPages;
        const currentPage: number =  ipaginacion.currentPage;
        let auxPaginacion: number[] = [];
     //   console.log('current page: ' +  currentPage)

        // mostar de 5 en 5
        const nShow = 5
        // vamos a calcular los multiplos para obtener las
        // secciones
        const residuo = currentPage% nShow;
        if (residuo==0){ // si el que seleccione es multiplo de 5
            for (let i = currentPage- nShow; i< currentPage; i++){
                auxPaginacion.push(i+1);
            }
        }else {// si no entonces calcular la paginación sin pasarse del numero total de pages
            let nSeccion = Math.trunc(currentPage/ nShow);
        //    console.log('nSeccion: ' +  nSeccion)
            for (let i=(nSeccion*nShow) ; i<(nSeccion+1)*nShow; i++){
                let page = i+1
                if (page<=totalPages){
                    auxPaginacion.push(page)
                }
            }
        }




        return auxPaginacion;


    };

    //funcion validador pdf
    export const validFilepdf = (e: ChangeEvent<HTMLInputElement>): boolean  => {
        if (e.target.files![0].type =='application/pdf'){
            if (e.target.files![0].size <= MAXSIZEUPLOADFILE){
                return true;
            }
        }
    return false;
    };

    export const openNewWindow = (src: string, width?: number, height?: number) =>  {


        const localStorageClass = new LocalStorageClass();
        // eslint-disable-next-line no-restricted-globals
        const screenWidth = screen.width
        // eslint-disable-next-line no-restricted-globals
        const screenHeight = screen.height

        if (!width || !height){
            width = screenWidth/2;
            height = screenHeight;
        }


        // eslint-disable-next-line no-restricted-globals
        var left = 0; //screenWidth/4;
        if (localStorageClass.getDirModal() == directionModal.left){
            left  = 0
            localStorageClass.setSomeString(localStorageClass.DIRMODAL,directionModal.right );
        }else if (localStorageClass.getDirModal() == directionModal.right){
            left = screenWidth-width;
            localStorageClass.setSomeString(localStorageClass.DIRMODAL,directionModal.left );
        }
        // eslint-disable-next-line no-restricted-globals
        var top = 0;
        window.open (src,
            "_blank",
            "menubar=1," +
                    "resizable=1," +
                    "width=" + width + "," +
                    "height=" + height +"," +
                    "top=" + top +"," +
                    "left=" + left
        )
    }

    export const openNewWindowCenter = (src: string, width?: number, height?: number) =>  {
    const localStorageClass = new LocalStorageClass();
    // eslint-disable-next-line no-restricted-globals
    const screenWidth = screen.width
    // eslint-disable-next-line no-restricted-globals
    const screenHeight = screen.height

    if (!width || !height){
        width = screenWidth/2;
        height = screenHeight;
    }


    // eslint-disable-next-line no-restricted-globals
    var left = (screenWidth - width) /2;

    // eslint-disable-next-line no-restricted-globals
    var top = 0;
    window.open (src,
        "_blank",
        "menubar=1," +
        "resizable=1," +
        "width=" + width + "," +
        "height=" + height +"," +
        "top=" + top +"," +
        "left=" + left
    )
}


    export const isAclaracionFirmasCSAM = (idTipoDoc: number) => {
        if ((idTipoDoc == DocAclaracionFirmasCSAM.personasFisicas) ||
            (idTipoDoc == DocAclaracionFirmasCSAM.personasMorales)){
            return true
        }
        return false
    }
export const sumDate = (date: string, nDays: number): string => {

    let result = new Date(date);
    result.setDate(result.getDate() + nDays);
    const days = (result.getDate()<10) ? '0' + result.getDate() : result.getDate();
    const month = ((result.getMonth()+1) <10) ? '0'+ (result.getMonth()+1) : (result.getMonth()+1);
    // le sumamos NDays días y lo seteamos en fecha final

    return  result.getFullYear()+ '-' + month + '-' + days
}

export const validateEmail =  (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export const getFileExtension = (filename: string) => {
    return filename.split('.').pop();
}

export const initPerfil: UserModel = {
        id: '',
        username: '',
        email:'',
        roles: [],
        accessToken: '',
        tokenType: '',
        clave: ''
    }

    export const initPaginacion: PaginacionModel = {
        currentPage: 1,
        totalItems: 0,
        totalPages: 0,
        size:10,
    }

    export const initListLanguages: LanguageModel[] = [
        {
            idLenguaje: 1,
            lang:'es',
            descripcion: 'Español',
            image: flagMexico,
            checked: false
        },
        {
            idLenguaje: 2,
            lang:'en',
            descripcion: 'English',
            image: flagUnitedStates,
            checked: false
        }
    ];

    export const initExpedienteIdentification =
        {
            idDocumento: 0,
            idTipoDocumento: 0,
            idClave: 0,
            descripcion: '',
            descripcionTipoDocumento: '',
            url: '',
            observacion: '',
            estado: statesDocIdentification.pendiente,
            obligatorio: false,
            documentoGuardado: false,
            detalle: ''
        }
export const initLineaAfianzamiento: LineaAfinzamiento = {
    idCliente: 0,
    productos: [],
    montoMaximoEv: 0,
    montoMaximoAc: 0,
    montoAC: 0,
    estatus: 0
}

export  const initMsgLineaAfianzamiento: MsgNuevaLineaAfianzamiento = {
    msgProductos: '',
    msgMontoMaximoEv: '',
    msgMontoMaximoAC:''
}
