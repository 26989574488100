import React, {useEffect} from "react";
import './FooterPublic.css';
import {Disclaimer} from "@lmig/lmds-react";
import {NavLF, FooterLF} from "@lmig/lf-react-components/src/";
import '@lmig/lf-react-components/src/index.css'
interface FooterPublicProps {

}
const FooterPublic: React.FC<FooterPublicProps> = ({}: FooterPublicProps) => {

    useEffect(() => {
    });

    return (

            <FooterLF env={'dev'}></FooterLF>
    );
};

export default FooterPublic;
