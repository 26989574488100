
import React, {useEffect, useRef, useState} from "react";
import './NameSystem.scss';
const NameSystem = () => {
    let i = 0;
    let txt = 'Nuevo Sistema';
    let speed =170 ;

    const pdemo = useRef<HTMLParagraphElement>(null);


    const [newName, setNewName] = useState(txt);

    useEffect(() => {

        setInterval(() => {

            if(pdemo.current != null){
                if (i < txt.length) {
                    pdemo.current!.innerHTML += txt.charAt(i);
                    i++;
                }else {
                    pdemo.current!.innerHTML = '';
                    i=0;
                }
            }


     }, speed);


    }, []);

    return (
        <>
            <div className="" id="name-system">
                    <div className="" id="sub-header" ref={pdemo}>

                    </div>



            </div>

        </>
    );
};

export default NameSystem;
