import React, {ChangeEvent, useEffect, useState} from 'react';
import './DocumentosGarantiaMantenimiento.scss';

import {RootStore} from "../../../Store";
import {useSelector} from "react-redux";
import {ExpedienteIdetificacionModel} from "../../../models/garantias/ExpedienteIdetificacionModel";
import * as supportService from "../../../services/support/SupportService";
import {statesDocIdentification} from "../../../models/garantias/statesDocIdentification";
import {AxiosError} from "axios";
import {getExpediente} from "../../../services/support/SupportService";
import {inputTypeWarranty} from "../../../views/garantias/warranty/Warranty";
import {ExpedienteModel} from "../../../models/garantias/ExpedienteModel";
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import {
    AuthorizationDocWarranty,
    statesQuoteDoc
} from "../../garantias/authorization-doc-warranty/AuthorizationDocWarranty";
import {
    AlphanumericInput, Button, IconBrokenLink,
    IconCheckmark,
    IconCloseCircle,
    IconData,
    IconSearch,
    IconTrash,
    IconUpload
} from "@lmig/lmds-react";
import {Spinner} from "react-bootstrap";
import {MAXSIZEUPLOADFILE, openNewWindow, validFileExpediente, validRoles} from "../../../class/CommonClass";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import CurrencyInputSDGF from "../../public/currency-input-sdgf/CurrencyInputSDGF";
import {ExpedienteSelect} from "../../garantias/expedient-select/ExpedientSelect";
import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";
import {ModalFileMaxUpload} from "../../public/modal-file-max-upload/ModalFileMaxUpload";
import {ArchivoGarantia} from "../../../models/garantias/ArchivoGarantia";
import {DragDropGarantia} from "../drag-drop-garantia/DragDropGarantia";


interface DocumentosGarantiaMantenimientoProps {
    idGarantia: number;

}


export const DocumentosGarantiaMantenimiento: React.FC<DocumentosGarantiaMantenimientoProps> = (
    {idGarantia
    }:DocumentosGarantiaMantenimientoProps)=> {
    // redux
    const languageState = useSelector((state: RootStore) => state.language);

    const [loading, setLoading] = useState<boolean>(false);

    const [listExpediente, setListExpediente] = useState<ExpedienteModel[]>([]);

    const [openModalExcedLimitFile, setOpenModalExcedLimitFile] = useState<boolean>(false);

    const getDocumentosGarantia = async (idGarantia: number) => {

        setLoading(true);

        try {
            const res = await supportService.getExpediente(idGarantia);

            console.log(res.data)

            const auxListExpediente = res.data;
            for (const expedient of auxListExpediente){
                for (const detalle of expedient.detalle){
                    if (detalle.inputType == inputTypeWarranty.Double){
                        if (!detalle.valor){
                            detalle.valor = '';
                        }
                    }
                }
            }

            
            setListExpediente(auxListExpediente);
            setLoading(false);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoading(false);

            }
        }
    }

    const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (validFileExpediente(e)){
            const indiceExpediente= parseInt(e.target.id);
            const formData = new FormData();
            formData.append('idGarantia', String(idGarantia));
            formData.append('idTipoDocumento', String([e.target.name]));
            formData.append('fileName', e.target.files![0]);
            e.target.value = '';// vaciar el input file
            postFileWarranty(formData, indiceExpediente);

        }else if (e.target.files![0].size >= MAXSIZEUPLOADFILE){
            setOpenModalExcedLimitFile(true)
            e.target.value = '';// vaciar el input file
        }
    }

    const postFileWarranty = async (formData: FormData, indiceExpediente: number) => {
        // activar el loading del expediente

        const auxlistExpediente = listExpediente.map((obj, index) => {
            if (index === indiceExpediente) {
                return {...obj,
                    loading: true};
            }else {
                return {...obj,
                    loading:false};
            }

        });

      //  console.log(auxlistExpediente);
        setListExpediente(auxlistExpediente);


        try {
            const res = await supportService.postArchivoGarantia(formData);
            // quitar el spiner de loading

            const newlistExpediente = listExpediente.map((obj, index) => {
                if (index === indiceExpediente) {
                    return {...obj,
                        loading: false,
                        url:  res.data.url,
                        idDocumento: res.data.idDocumento,
                        estado: statesQuoteDoc.enRevision

                    };
                }else {
                    return {...obj};
                }

            });

            setListExpediente(newlistExpediente);

        }
        catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                // quitar el spiner de loading
                const newlistExpediente = listExpediente.map((obj, index) => {
                    if (index === indiceExpediente) {
                        return {...obj,
                            loading: false,

                        };
                    }else {
                        return {...obj};
                    }

                });

                setListExpediente(newlistExpediente);

            }
        }
    }


    useEffect(() => {
        getDocumentosGarantia(idGarantia)
    },[idGarantia]);



    return (
        <>
            {loading?
                <SpinnerLoading text={languageState.language?.warranty.loadingDoc!}/> :
                <div className="row" id="documento-garantia-mantenimiento" >
                    {listExpediente.length>0? (
                        <div className="col-md-12 expedient-files">
                            {
                                listExpediente.map((i, index) =>
                                    (
                                        <div key={index} className="expedient">
                                            <hr/>
                                            {i.obligatorio?(
                                                <b className="text-dark">
                                                    {languageState.language?.warranty.mandatoryDoc!}
                                                </b>): null
                                            }
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="row input-sdgf upload-file">

                                                        <div className="col-md-2 col-12">
                                                            {i.estado == statesQuoteDoc.enRevision? (
                                                                <IconCheckmark color="success"
                                                                               size="24"
                                                                               title={languageState.language?.warranty.inReview!}
                                                                               className="float-left"/>

                                                            ):null}
                                                            {i.estado == statesQuoteDoc.enCaptura? (
                                                                <IconData color="gray"
                                                                          size="24"
                                                                          title={languageState.language?.warranty.inCapture!}
                                                                          className="float-left"/>
                                                            ):null}
                                                            {i.estado == statesQuoteDoc.aprobado? (
                                                                <>
                                                                    <IconCheckmark color="success"
                                                                                   size="24"
                                                                                   title={languageState.language?.warranty.approved!}
                                                                                   className="float-left"/>

                                                                    <IconCheckmark color="success"
                                                                                   size="24"
                                                                                   title={languageState.language?.warranty.approved!}
                                                                                   className="float-left"/>
                                                                </>

                                                            ):null}
                                                            {i.estado == statesQuoteDoc.rechazado? (
                                                                <IconCloseCircle
                                                                    color="error"
                                                                    size="24"
                                                                    title={languageState.language?.warranty.rejected!}
                                                                    className="float-left"/>
                                                            ):null}
                                                        </div>

                                                        <div className="col-md-7 col-12 title-doc">
                                                            {i.descripcion}
                                                        </div>

                                                        <div className="col-md-3 col-12 action-buttons">
                                                            <div className="row">
                                                                {i.url && i.url!= null  ? (
                                                                        <a className="col" onClick={() => {
                                                                            openNewWindow(i.url!)
                                                                        }}>
                                                                            <IconSearch color="informative"
                                                                                        size="24"
                                                                                        title={languageState.language?.warranty.watchFile!}
                                                                                        className=""/>
                                                                        </a>
                                                                    ):
                                                                    null
                                                                }


                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            {i.loading == true? (
                                                                    <div className="w-100">
                                                                        <SpinnerLoading
                                                                            text={languageState.language?.tableWarranty.loadingWarranties}/>
                                                                    </div>
                                                                ):
                                                                <>
                                                                {(i.idDocumento!= null && i.documentoGuardado == false)?
                                                                    <DragDropGarantia
                                                                        idGarantia={idGarantia}
                                                                        idTipoDocumento={i.idTipoDocumento}
                                                                        returnFormData={formData => {
                                                                            postFileWarranty(formData, index);
                                                                        }}
                                                                    >

                                                                    </DragDropGarantia>:
                                                                    <>
                                                                        {(i.idDocumento!=null  && i.documentoGuardado == true)?
                                                                            <>     <br/><b>Este Archivo, ya esta Guardado en S3</b>
                                                                            </>:
                                                                            null
                                                                        }
                                                                    </>}
                                                                </>


                                                            }
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>

                                           {/* {
                                                i.detalle.map((iDetail, index2) =>
                                                    (
                                                        <div key={index2}>
                                                            {iDetail.inputType == inputTypeWarranty.Date  ? (
                                                                <div>
                                                                    <label className="label-input">
                                                                        {i.obligatorio?(<span>*</span>): null}
                                                                        {iDetail.descripcion}
                                                                    </label>
                                                                    <input
                                                                        type="date"
                                                                        className={"input-sdgf"}
                                                                        id={String(index)}
                                                                        name={String(index2)}
                                                                        value={iDetail.valor!}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            ): null}
                                                            {iDetail.inputType == inputTypeWarranty.String  ? (
                                                                <div>
                                                                    <label className="label-input">
                                                                        {i.obligatorio?(<span>*</span>): null}
                                                                        {iDetail.descripcion}
                                                                    </label>
                                                                    <AlphanumericInput
                                                                        id={String(index)}
                                                                        filter={/^.{1,500}$/}
                                                                        name={String(index2)}
                                                                        labelVisual={iDetail.descripcion}
                                                                        value={iDetail.valor!}
                                                                        disabled={true}
                                                                    />

                                                                </div>

                                                            ): null}
                                                            {iDetail.inputType == inputTypeWarranty.Double  ? (
                                                                <div>
                                                                    <label className="label-input">
                                                                        {i.obligatorio?(<span>*</span>): null}
                                                                        {iDetail.descripcion}
                                                                    </label>

                                                                    <input type="text"
                                                                           className="input-sdgf"
                                                                           id={String(index)}
                                                                           name={String(index2)}
                                                                           value={Number(iDetail.valor)}
                                                                           disabled={true}/>


                                                                </div>

                                                            ): null}

                                                            {iDetail.inputType == inputTypeWarranty.LongText  ? (
                                                                <div>
                                                                    <label className="label-input">
                                                                        {i.obligatorio?(<span>*</span>): null}
                                                                        {iDetail.descripcion}

                                                                    </label><br/>
                                                                    <textarea
                                                                        id={String(index)}
                                                                        name={String(index2)}
                                                                        className="input-sdgf"
                                                                        maxLength={500}
                                                                        disabled={true}
                                                                    >
                                                                                                {iDetail.valor!}
                                                                                            </textarea>


                                                                </div>
                                                            ): null}

                                                            {iDetail.inputType == inputTypeWarranty.Select  ? (
                                                                <div>
                                                                    <label className="label-input">
                                                                        {i.obligatorio?(<span>*</span>): null}
                                                                        {iDetail.descripcion}
                                                                    </label><br/>

                                                                    <ExpedienteSelect
                                                                        idCatalogo={iDetail.idCatalogo}
                                                                        idSubCatalogo={iDetail.idSubCatalogo}
                                                                        value={iDetail.valor}
                                                                        idGarantia={idGarantia}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            ): null}

                                                            <NegativeAlert textAlert={iDetail.msgExpendiente!}/>

                                                        </div>

                                                    ))
                                            }*/}
                                        </div>

                                    ))
                            }
                            {
                                (listExpediente.length==0)?(
                                    <div className="col-md-12 ">
                                        {languageState.language?.warranty.notFoundDocs!}
                                    </div>

                                ): null
                            }
                        </div>
                    ) : <div className="col-md-12 expedient-files">
                        <NegativeAlert textAlert= {languageState.language?.warranty.notFoundDocs!}/>
                    </div>
                    }

                </div>
            }



            <ModalFileMaxUpload
                openModal={openModalExcedLimitFile}
                maxSize={MAXSIZEUPLOADFILE}
                clickShowModal={show1 => {
                    //alert(show1)
                    setOpenModalExcedLimitFile(show1)
                }}/>


        </>
    );
};

