import { ChangeEvent, Component } from "react";
import './SelectStyles.scss';
import Select, { MultiValue } from 'react-select';
import { Condiciones } from "../../../models/compromisos/Condiciones";
import * as engagementService from '../../../services/compromisos/EngagementsService';
import { UserModel } from "../../../models/public/UserModel";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import { RootStore } from "../../../Store";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { connect } from "react-redux";
import {ConditionOptionModel} from "../../../models/public/ConditionOptionModel";


type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any >;

class SelectCondition extends Component<IListProps, IListState>{


  public localStorageClass = new LocalStorageClass();



  constructor(props: any) {

    super(props);


    const PERFIL = this.localStorageClass.getProfile();
    //si no hay un token guardado
    if (!PERFIL.accessToken){
        // redireccionar
        //this.props.history.push('/');
    }

    this.state = {
      condiciones: [],
      listConditionalOption: [],
      PERFIL: PERFIL,

    }

    this.handleInputChangeSelect = this.handleInputChangeSelect.bind(this)

  }

  componentDidMount() {
    this.getCondiciones();
  }


  render(): JSX.Element {
   


    return <>

      <div className="select-blocks">

        <Select
          //defaultValue={[colourOptions[2], colourOptions[3]]}
          placeholder={`${this.props.language.language?.createEngagement.seleccionaCondicion}`}   
          isMulti
          name="blocks"
          options={this.state.listConditionalOption}
          onChange={this.handleInputChangeSelect}
        />
      </div>

    </>
  }


  async getCondiciones(): Promise<void> {

    const res = await engagementService.getCondiciones();   
    this.setState({condiciones: res.data});  

    this.formatearLista();   

  }

  formatearLista(): void {
    
    let  listaCondicionalAuxiliar: ConditionOptionModel[];
    listaCondicionalAuxiliar = [] 
   
    for( const condition of this.state.condiciones){    

      listaCondicionalAuxiliar.push({ name: "condicion", value: condition.idCondicion , label: condition.descripcion, status: condition.estatus}) 
     
    } 

    this.setState( { listConditionalOption: listaCondicionalAuxiliar } ) 

  } 


  formatearListaXTipo(tipo:string): void {
    
    let  listaCondicionalAuxiliar: ConditionOptionModel[] = [] ;

   console.log("Tipo " + tipo)
    for( const condition of this.state.condiciones){ 

          if(condition.descripcion.includes(tipo)){

          listaCondicionalAuxiliar.push({ name: "condicion", value: condition.idCondicion, label: condition.descripcion, status: condition.estatus}) 
          }
      
    } 
    this.setState( { listConditionalOption: listaCondicionalAuxiliar }) 
  } 

  handleInputChangeSelect (e: MultiValue<ConditionOptionModel>): void{

    let  listaCondicionesAuxiliar: Condiciones[]; 
    listaCondicionesAuxiliar = [] 

    let  listaGarantias: Condiciones[]; 
    listaGarantias= [] 

    let  listaBuro: Condiciones[]; 
    listaBuro = [] 

    //console.log("El tamaño de E es: " + e.length)

    if(e.length == 0 ){

      //console.log("Entro a nada con " + e)
      listaGarantias = [] 
      listaBuro = [] 
      listaCondicionesAuxiliar = [] 
      this.getCondiciones();

    } 

    for (const elemento of e){

      if(elemento.label.includes("Garantías") ){
        
        //console.log("Entro a garantias")
        listaGarantias.push({ idCondicion: elemento.value, descripcion: elemento.label, estatus: elemento.status})
        listaCondicionesAuxiliar = listaGarantias
        this.formatearListaXTipo("Garantías");
        
      }   
  
      else if(elemento.label.includes("Buró") ){
        
        //console.log("Entro a buro")
        listaBuro.push({idCondicion: elemento.value, descripcion: elemento.label, estatus: elemento.status })
        listaCondicionesAuxiliar = listaBuro
        listaGarantias = [] 
        this.formatearListaXTipo("Buró");
       
      } 


    } 

    this.props.selectedConditions(listaCondicionesAuxiliar);
  }

}

interface IListProps {

  language: DefaultStateI
  selectedConditions: (listCondiciones: Condiciones[]) => void;
 

}

interface IListState {

  condiciones: Condiciones[];
  listConditionalOption: ConditionOptionModel[];
  PERFIL: UserModel;

}




const mapStateToProps = (state: RootStore) => ({
  language: state.language
});


export default connect(mapStateToProps)(SelectCondition);
