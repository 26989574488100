import { ChangeEvent, Component } from "react";
import './RevocationEngagements.scss';
import NavBar from "../../../components/public/nav-bar/NavBar";
import InfoEngagement from "../../../components/compromisos/info-engagement/InfoEngagement";
import { AlphanumericInput, Button, Form, List, ListItem, Modal, } from "@lmig/lmds-react";
import { RouteComponentProps } from "react-router-dom";
import { LocalStorageClass } from "../../../class/LocalStorageClass";
import { PipeLineStepsDetails } from "../../public/pipeline-steps/PipeLineStepsDetails";
import { AxiosError } from 'axios';
import * as engagementService from '../../../services/compromisos/EngagementsService';
import { Compromiso } from '../../../models/compromisos/Compromiso';
import {formatCurrency, formatValue, InputChange, validRoles} from "../../../class/CommonClass";
import { AutorizaCompromiso } from "../../../models/compromisos/AutorizaCompromiso";
import { CodigoRespuesta } from "../../../models/compromisos/CodigoRespuesta";
import { Cliente } from "../../../models/compromisos/Cliente";
import SuccessAlert from "@lmig/lmds-react/icons/SuccessAlert";
import FindFuncionario from "../find-client/FindFuncionario";
import { MessagesAPIComponent } from "@lmig/lf-sgf-library-messages/src";
import '@lmig/lf-sgf-library-messages/src/index.css'
import { Correo } from "../../../models/compromisos/Correo";
import environment from "../../../environments/environment";
import { DefaultStateI } from "../../../reducer/LanguageReducer";
import { RootStore } from "../../../Store";
import { connect } from "react-redux";
import { BreadCumbEngagements } from "../../public/breadcumb/BreadCumbEngagements";
import Info from "@lmig/lmds-react/icons/Info";
import { ElementosCompromiso } from "../../../models/compromisos/ElementosCompromiso";
import {UserModel} from "../../../models/public/UserModel";
import { Destinatario } from "../../../models/compromisos/Destinatario";



interface IListProps {

    idCompromiso: number;
    idFiado: number;
    language: DefaultStateI
}


interface Params {
    idCompromiso: string;
    idFiado: string;
}

interface LocationState {
    ownerName: string;
}

// Static Context is available when you use Static Router*
interface SC {
    statusCode?: number;
}

interface BlockOption {
    value: number;
    label: string;
}


interface ConditionOption {
    value: number;
    label: string;
}


class RevocationEngagements extends Component<IListProps & RouteComponentProps<Params, SC, LocationState>, RenewalState> {

    public idFiado = 0;
    public idCompromiso = 0;
    public today: string;
    public last: string;
    public correoDestinario = '';
    public nombreDestinario = '';

    public localStorage = new LocalStorageClass();
    public localStorageClass = new LocalStorageClass();
    public initialEngagementStateAcepta: AutorizaCompromiso = {
        idCompromiso: 0,
        idAccion: 0,
        fechaComprometida: '',
        motivo: '',
        dictamen: '',
        nombreRep: '',
        usuario: '',
    }
    public initialEngagementState: Compromiso = {

        bloqueos: [],
        conFiado: 0,
        condiciones: [],
        datosFiado: {
            correo: '',
            fechaAceptaCompr: '',
            idCompromiso: 0,
            nombreRep: ''
        },
        dictamen: '',
        fechaComprometida: '',
        fechaCompromisoCumplido: '',
        fechaSolicitud: '',
        idCompromiso: 0,
        idFiado: 0,
        idSolicitante: '',
        monto: 0,
        motivo: '',
        numRenovaciones: 0,
        producto: '',
        statusCompromiso: 0,
        tipoBloqueo: 0,
        tipoCompromiso: 0,
        tipoCumplimiento: 0,
        tipoSolicitante: 0

    }

    public initialMsg: Messages = {
        msgTipo: '',
        msgCondicion: '',
        msgBloqueo: '',
        msgProducto: '',
        msgMonto: '',
        msgCorreo: '',
        msgFecha: '',
        msgMotivo: ''
    }

    constructor(props: any) {
        super(props);
        this.idCompromiso = parseInt(this.props.match.params.idCompromiso);
        this.idFiado = parseInt(this.props.match.params.idFiado);
        this.today = '';
        this.last = '';
        document.title = "Revocar Compromiso"

        this.state = {
            count: 0,
            PERFIL: this.localStorageClass.getProfile(),
            mensajesAlerta: this.initialMsg,
            compromiso: this.initialEngagementState,
            engagementObject: this.initialEngagementState,
            authorizeEngagementObject: this.initialEngagementStateAcepta,
            compromisoDetails: [{
                fiado: '',
                rfc: '',
                idCompromiso: 0,
                fechaSolicitud: '',
                bloqueos: [{

                    descripcion: '',
                    idBloqueo: 0,
                    estatus: 0
                }],
                condiciones: [{
                    idCondicion: 0,
                    descripcion: '',
                    estatus: 0
                }],
                fechaComprometida: '',
                idFiado: 0,
                monto: 0,
                motivo: '',
                numRenovaciones: 0,
                producto: '',
                statusCompromiso: 0,
                tipoCompromiso: 0,
                idFuncionario: ''
            }],
            BlockOption: [{
                value: 0,
                label: '',
            }],
            ConditionOption: [{
                value: 0,
                label: '',
            }],
            conFacultades: {
                codigo: 0,
                descripcion: ''
            },
            cliente: {
                idCliente: 0,
                razonSocial: '',
                rfc: '',
                agente: '',
                idAgente: 0
            },
            email: {
                correoDe: '',
                correoPara: '',
                de: '',
                fecha: '',
                idProyecto: '',
                idReferencia:'',
                mensaje: '',
                para: '',
                perfil: ''
        
            },
            renovado: {
                codigo: 0,
                descripcion: ''
            },
            destinatario: {
                nombre: '',
                email: ''
            
            }


        }

        const date = new Date().getDate();
        const month = new Date().getMonth() + 1;
        const year = new Date().getFullYear();
        let today = "";
        let last = "";
        const monthA = new Date().getMonth() + 6;
        const yearA = year;

        if (month < 10 && date < 10) {

            const monthAux = "0" + month
            const dateAux = "0" + date
            today = year + "-" + monthAux + "-" + dateAux
            this.today = today

        } else {

            today = year + "-" + month + "-" + date
            this.today = today
        }



        if (month > 10 && date < 10) {

            const dateAux = "0" + date
            today = year + "-" + month + "-" + dateAux
            this.today = today
        }


        if (monthA < 10 && date < 10) {

            const montAA = "0" + monthA
            const dateAux = "0" + date
            last = yearA + "-" + montAA + "-" + dateAux
            this.last = last

        } else {

            last = yearA + "-" + monthA + "-" + date
            this.last = last
        }


        if (monthA > 10 && date < 10) {

            const dateAux = "0" + date
            last = yearA + "-" + monthA + "-" + dateAux
            this.last = last
        }

        console.log("Fecha minima: " + this.today)
        console.log("Fecha máxima: " + this.last)


        this.handleInputChangeName = this.handleInputChangeName.bind(this)
        this.handleInputChangeEmail2 = this.handleInputChangeEmail2.bind(this)
        this.handleInputChangeSelect = this.handleInputChangeSelect.bind(this)

    }

    componentDidMount() {

        this.getFacultades();
        this.getClientAut();
        this.getClientInfo();

    }


    render() {
        return <>
            <NavBar />
            <div className="container-fluid " id="revocation-engagements">
            
                <div className="col-md-8">
                    <BreadCumbEngagements
                        title={this.props.language.language?.createEngagement.nombreDocumentoRevocacion!}
                        listBreadcumbs={[
                            { title: 'Inicio', url: '/compromisos' },
                            { title: this.props.language.language?.createEngagement.nombreDocumentoRevocacion!, url: this.props.location.pathname },
                        ]}
                    />
                </div>

                <div className="create-engagement">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-8 seccion-info ">
                                    <InfoEngagement id={this.idFiado} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <PipeLineStepsDetails
                                listSteps={[`${this.props.language.language?.createEngagement.compromiso} `, `${this.props.language.language?.createEngagement.revision} `,
                                `${this.props.language.language?.createEngagement.dictamen} `]}
                                currentStep={this.state.count!} />
                        </div>

                        <div className="col-md-12">
                            <Form id="form-liberty">
                                {this.state.count == 0 ? (
                                    <div className="row">
                                        {this.state.compromisoDetails.map((i, index) =>
                                            <div className="col-md-12">
                                                <label className="label-input">{this.props.language.language?.createEngagement.tipoLabel}</label>
                                                <AlphanumericInput
                                                    className="float-right w-100"
                                                    id="input--labelAsHeading"
                                                    labelVisual=""
                                                    value= {i.descTipoCompromiso}
                                                    disabled
                                                />
                                            </div>
                                        )}

                                        {(this.state.compromiso.tipoCompromiso == 1) ?
                                            <div className="col-md-12">
                                                <label className="label-input">{this.props.language.language?.createEngagement.condicionesLabel}</label>
                                                <List compact className="condition-input">
                                                    {this.state.ConditionOption.map((condition, index) =>
                                                        <ListItem>{condition.label}</ListItem>
                                                    )}
                                                </List>
                                            </div> : null}

                                        {(this.state.compromiso.tipoCompromiso == 2) ?
                                            <div className="col-md-12">
                                                <label className="label-input">{this.props.language.language?.createEngagement.bloqueosLabel}</label>
                                                <List compact className="condition-input">
                                                    {this.state.BlockOption.map((bloq, index) =>
                                                        <ListItem>{bloq.label}</ListItem>
                                                    )}
                                                </List>
                                            </div> : null}

                                        <div className="col-md-12">
                                            <label className="label-input">{this.props.language.language?.createEngagement.producto}</label>
                                            <AlphanumericInput
                                                className="float-right w-100"
                                                id="input--labelAsHeading"
                                                labelVisual=""
                                                value={`${this.state.compromiso.producto}`} disabled
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <label className="label-input">{this.props.language.language?.createEngagement.monto}</label>
                                            <AlphanumericInput
                                                className="float-right w-100"
                                                id="input--labelAsHeading"
                                                labelVisual=""
                                                value={formatCurrency(this.state.compromiso.monto)} disabled
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <label className="label-input">{this.props.language.language?.createEngagement.nombreAgente}</label>
                                            <AlphanumericInput
                                                className="float-right w-100"
                                                id="input--labelAsHeading"
                                                labelVisual=""
                                                value={this.state.cliente.agente} disabled
                                            />
                                        </div>

                                        <div className="col-md-7">
                                            <label className="label-input">{this.props.language.language?.createEngagement.fecha}</label>
                                            <AlphanumericInput
                                                className="float-right w-100"
                                                id="input--labelAsHeading"
                                                labelVisual="" 
                                                value={`${this.state.compromiso.fechaComprometida}`} disabled />
                                        </div>

                                        <div className="col-md-12">

                                            <label className="label-input">{this.props.language.language?.createEngagement.motivo}</label>
                                            <textarea value={`${this.state.compromiso.motivo}`} className={"input-sdgf"} rows={4} cols={55} disabled />

                                        </div>

                                        <div className="col-md-12">

                                            <div className="row buttons-navigation">
                                                <div className="col-md-6">
                                                    <br />
                                                    <Button dynamicWidth
                                                        variant="secondary"
                                                        //onClick={() => this.back()}
                                                        href="/compromisos">
                                                        {this.props.language.language?.createEngagement.regresar}
                                                    </Button>
                                                </div>
                                                {/*     <div className="col-md-4">
                                                    <br />

                                                    {validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO) ? (
                                                        <HistoryEngagementsF idPrincipal={this.idPrincipal} />
                                                    ) : <HistoryEngagements idCompromiso={this.idCompromiso} />}

                                                </div> */}
                                                <div className="col-md-6">
                                                    <br />
                                                    <Button dynamicWidth
                                                        variant="primary"
                                                        onClick={() => this.next()}>
                                                        {this.props.language.language?.createEngagement.continuar}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </Form>

                            {this.state.count == 1 ? (
                                <>

                                    <Form id="form-mensajes">
                                        <div className="row mensajes">

                                            <MessagesAPIComponent idAPI={2}
                                                                  disableCreate={false}
                                                idEspecifico={this.state.authorizeEngagementObject.idCompromiso!}
                                                emailRemitente={this.state.PERFIL.email}
                                                nombreRemitente={this.state.PERFIL.username}
                                                emailDest={this.state.destinatario.email}
                                                nombreDest={this.state.destinatario.nombre}
                                                TOKEN={this.state.PERFIL.accessToken}
                                                APIURL={environment.APIMENSAJES}
                                               APIKEY={environment.APIKEYMENSAJES}
                                            />
                                        </div>
                                    </Form>
                                    <Form id="form-liberty">

                                        <div className="col-md-12">
                                            <div className="row buttons-navigation">
                                                <div className="col-md-6">
                                                    <br />
                                                    <Button dynamicWidth
                                                        variant="secondary"
                                                        onClick={() => this.back()}>
                                                        {this.props.language.language?.createEngagement.regresar}
                                                    </Button>
                                                </div>

                                                {validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) || validRoles(this.state.PERFIL.roles, this.localStorageClass.PROMOTOR) ? (
                                                    <div className="col-md-6">
                                                        <br />
                                                        <Button dynamicWidth
                                                            variant="primary"
                                                            onClick={() => this.next()}>
                                                            {this.props.language.language?.createEngagement.continuar}
                                                        </Button>
                                                    </div>) : null}


                                                {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) ? (
                                                    <div className="col-md-6">
                                                        <br />
                                                        <Button dynamicWidth
                                                            variant="primary"
                                                            onClick={() => this.next()}>
                                                            {this.props.language.language?.createEngagement.continuar}
                                                        </Button>
                                                    </div>
                                                ) : null}

                                                {validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO) || validRoles(this.state.PERFIL.roles, this.localStorageClass.BURO) ? (
                                                    <div className="col-md-6">
                                                        <br />
                                                        <Button dynamicWidth
                                                            variant="primary"
                                                            onClick={() => this.next()}>
                                                            {this.props.language.language?.createEngagement.continuar}
                                                        </Button>
                                                    </div>
                                                ) : null}

                                            </div>
                                        </div>

                                    </Form></>
                            ) : null}

                            {this.state.count == 2 ? (

                                <><Form id="form-dictamen">
                                    <div className="row">

                                    {validRoles(this.state.PERFIL.roles, this.localStorageClass.AGENTE) ?
                                            <div className="col-md-12 text-center">
                                                <label className="label-input"></label>
                                                <textarea
                                                    name="dictamen"
                                                    onChange={this.handleInputChangeSelect}
                                                    placeholder={this.props.language.language?.createEngagement.redactaDictamen}
                                                    className={"input-sdgf"} rows={5} cols={5} />
                                            </div> : null}

                                        {validRoles(this.state.PERFIL.roles, this.localStorageClass.EJECUTIVOCUENTA) ?
                                            <div className="col-md-12 text-center">
                                                <label className="label-input"></label>
                                                <textarea
                                                    name="dictamen"
                                                    onChange={this.handleInputChangeSelect}
                                                    placeholder={this.props.language.language?.createEngagement.redactaDictamen}
                                                    className={"input-sdgf"} rows={5} cols={5} />
                                            </div> : null}



                                        {validRoles(this.state.PERFIL.roles, this.localStorageClass.FUNCIONARIO) || validRoles(this.state.PERFIL.roles, this.localStorageClass.BURO) ?
                                            <div className="col-md-12 text-center">
                                                <label className="label-input"></label>
                                                <textarea
                                                    name="dictamen"
                                                    onChange={this.handleInputChangeSelect}
                                                    placeholder={this.props.language.language?.createEngagement.redactaDictamen}
                                                    className={"input-sdgf"} rows={5} cols={5} />
                                            </div> : null}

                                        <div className="col-md-12">
                                            <div className="row buttons-navigation">


                                                <div className="col-md-4">
                                                    <br />
                                                    <Button dynamicWidth
                                                        variant="secondary"
                                                        onClick={() => this.back()}>
                                                        {this.props.language.language?.createEngagement.regresar}
                                                    </Button>
                                                </div>

                                                <div className="col-md-4">
                                                    <br />
                                                    <Button variant="primary" dynamicWidth
                                                        onClick={() => {
                                                            this.setState({ openModalRevocar: true })
                                                        }}>
                                                        {this.props.language.language?.createEngagement.revocar}
                                                    </Button>
                                                </div>


                                            </div>
                                        </div>

                                        <Modal
                                            isOpen={this.state.openModalClose}
                                            size="medium"
                                            modalStyle="standard"
                                            //title="Fiado"
                                            //className="modalExito"
                                            id="modal2"
                                            closeFunc={() => {
                                                this.setState({ openModalClose: false })
                                            }}>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="col-md-4 alert">
                                                        <SuccessAlert
                                                            size="64"
                                                            color="positive">
                                                        </SuccessAlert>
                                                    </div>

                                                    <h2 className="texto">  {this.props.language.language?.createEngagement.operacionExitosa}</h2>

                                                    <br />
                                                    <Button href="/compromisos"
                                                        variant="primary"
                                                        className="btn-ok">
                                                        {this.props.language.language?.createEngagement.aceptar}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Modal>

                                        <Modal
                                            isOpen={this.state.openModalRevocar}
                                            size="medium"
                                            modalStyle="standard"
                                            id="modal4"
                                            closeFunc={() => {
                                                this.setState({ openModalRevocar: false })
                                            }}>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="col-md-4 info">
                                                        <Info
                                                            size="64"
                                                            color="informative">
                                                        </Info>
                                                    </div>

                                                    <h2 className="texto"> {this.props.language.language?.createEngagement.preguntaConfirmaRevocacion} </h2>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <br />
                                                            <Button onClick={() => {
                                                                this.setState({ openModalRevocar: false })
                                                            }}
                                                                //href="/compromisos"
                                                                variant="secondary"
                                                                className="btn-cancel">
                                                                {this.props.language.language?.createEngagement.cancelar}
                                                            </Button>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <br />
                                                            <Button
                                                                onClick={() => this.postCompromisoRevocado()}
                                                                //href="/compromisos"
                                                                variant="primary"
                                                            >
                                                                {this.props.language.language?.createEngagement.aceptar}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                </Form></>
                            ) : null}

                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    next(): void {
        this.setState({ count: this.state.count! + 1 })

    }

    back(): void {
        this.setState({ count: this.state.count! - 1 })
    }

    handleInputChangeSelect(e: InputChange): void {


        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
            authorizeEngagementObject: { ...prevState.authorizeEngagementObject, dictamen: formatValue(value) }
        }))

       
    }

    handleInputChangeEmail2(e: InputChange): void {

        const name = e.target.value;
        const value = e.target.value;

        const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        this.correoDestinario = value

        if (emailRegex.test(this.correoDestinario)) {

            console.log("Correo de Destinatario válido")

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgCorreo: formatValue('') }
            }))

            this.setState(prevState => ({
                email: { ...prevState.email, correoPara: formatValue(this.correoDestinario) }
            }))

        } else {

            this.setState(prevState => ({
                mensajesAlerta: { ...prevState.mensajesAlerta, msgCorreo: formatValue('Por favor captura un correo valido') }
            }))
            this.setState(prevState => ({
                email: { ...prevState.email, correoPara: formatValue('') }
            }))

        }

        //this.setState({ correoPara: this.correoDestinario  }  )
        console.log("Correo y Nombre del destinario: " + this.state.email.para + " " + this.state.email.correoPara)

    }

    handleInputChangeName(e: InputChange): void {

        const name = e.target.value;
        const value = e.target.value;

        this.nombreDestinario = value
        this.state.email.para = this.nombreDestinario
        console.log("Correo del destinario: " + this.state.email.para)

    }

    async postCompromisoRevocado(): Promise<void> {


        this.state.authorizeEngagementObject.usuario = this.state.PERFIL.username

        try {
            const res = await engagementService.postCompromisoRevocado(this.state.authorizeEngagementObject);

            // actualizar el objeto compromiso con el nuevo creado
            this.setState({ renovado: res.data })
            console.log(this.state.renovado)

            if (this.state.renovado.codigo == 1) {

                console.log('Se revocó exitosamente el Compromiso')
                this.setState({ openModalClose: true })
                this.setState({ openModalRevocar: false })


            } else {

                console.log("Algo ocurrió en la Revocación del Compromiso " + this.idCompromiso)
            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log("Error:" + err.response.status)
                console.log("Error:" + err.response.data)

            }
        }
    }




    async getClientAut(): Promise<void> {

        let externo = 0;

        const res = await engagementService.getClientAut(externo, this.idCompromiso);
        this.setState({ compromiso: res.data });
        this.setState({ engagementObject: res.data })

        const date = this.state.compromiso.fechaComprometida?.toString()
        const day = date?.substring(8, 10)
        const month = date?.substring(5, 7)
        const year = date?.substring(0, 4)

        const completeDate = day + '/' + month + '/' + year

        this.state.authorizeEngagementObject.motivo = this.state.compromiso.motivo
        this.state.authorizeEngagementObject.fechaComprometida = this.state.compromiso.fechaComprometida
        this.state.authorizeEngagementObject.nombreRep = ''
        this.state.authorizeEngagementObject.idCompromiso = this.state.compromiso.idCompromiso
        this.state.authorizeEngagementObject.idAccion = 5
        this.state.authorizeEngagementObject.usuario = this.state.PERFIL.username
        this.state.authorizeEngagementObject.idCompromiso= this.idCompromiso


        console.log("Se obtiene el Compromiso en la Revocación con el ID: " + this.state.compromiso.idCompromiso);

        let listaAuxiliarB: BlockOption[];
        listaAuxiliarB = []
        let listaAuxiliarC: ConditionOption[];
        listaAuxiliarC = []

       

        let tipo: string;
        tipo = ''

        for(const atributo of this.state.compromisoDetails) {

        if(this.state.compromiso.tipoCompromiso == tiposCompromiso.PORAPOYO) {
            tipo = 'Apoyo'
        }else if (this.state.compromiso.tipoCompromiso == tiposCompromiso.BLOQUEO) {
            tipo = 'Bloqueo'
        }     
            atributo.descTipoCompromiso = tipo
        }

        for (const valor of this.state.compromiso.bloqueos) {
                listaAuxiliarB.push({ value: valor.idBloqueo, label: valor.descripcion })
        }

            for (const valor of this.state.compromiso.condiciones) {
                listaAuxiliarC.push({ value: valor.idCondicion , label: valor.descripcion })
            }
           
        
        this.setState({ ConditionOption: listaAuxiliarC })
        this.setState({ BlockOption: listaAuxiliarB })

    }


    async getFacultades(): Promise<void> {

        let externo = 0;

        const res1 = await engagementService.getClientAut(externo, this.idCompromiso);
        this.setState({ engagementObject: res1.data })

        const producto = parseInt(this.state.engagementObject.producto)
        const monto = this.state.engagementObject.monto
        const ejecutivo = this.state.PERFIL.clave!

        const res = await engagementService.getFacultades(ejecutivo, producto, monto);
        this.setState({ conFacultades: res.data });
    }

    async getClientInfo(): Promise<void> {

        const res = await engagementService.getClient(this.idFiado, 0);
        this.setState({ cliente: res.data });
        console.log("Se encuentra Ciente en Renovación por ID")
        console.log(this.state.cliente)
    }

 /*    async getDestinatrio(): Promise<void> {

        const res = await engagementService.getDestinatario( this.idCompromiso);
        this.setState({ destinatario: res.data });
        console.log("Se obtiene el destinatario" )
        console.log(res.data)

    } */
}


const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(RevocationEngagements);



interface RenewalState {
    count?: number;
    PERFIL: UserModel;
    compromiso: Compromiso;
    engagementObject: Compromiso;
    authorizeEngagementObject: AutorizaCompromiso;
    openModalClose?: boolean;
    openModalRevocar?: boolean;
    compromisoDetails: ElementosCompromiso[];
    ConditionOption: ConditionOption[];
    BlockOption: BlockOption[];
    conFacultades: CodigoRespuesta;
    cliente: Cliente;
    email: Correo;
    renovado: CodigoRespuesta;
    mensajesAlerta: Messages;
    destinatario: Destinatario;
}

interface Messages {
    msgTipo: string;
    msgCondicion: string;
    msgBloqueo: string;
    msgProducto: string;
    msgMonto: string;
    msgCorreo: string;
    msgFecha: string;
    msgMotivo: string;
}

export enum tiposCompromiso{

    PORAPOYO = 1,
    BLOQUEO = 2,
  
}