export interface LanguageModelMsgFirma {
    //Principal
    msgSuccess: string,
    msgError: string,
    msgLoading: string,
    btnBack: string,

}

export const languageMsgFirmaIngles: LanguageModelMsgFirma = {
    //Principal
    msgSuccess: 'I signed receipt successfully',
    msgError: 'Unexpected error during receipt signing',
    msgLoading: 'Loading results',
    btnBack: 'To return',




}

export const languageMsgFirmaEspaniol: LanguageModelMsgFirma = {
    //Principal
    msgSuccess: 'Se firmo el recibo con éxito',
    msgError: 'Error inesperado durante la firma de recibo',
    msgLoading: 'Cargando resultados',
    btnBack: 'Regresar',
}