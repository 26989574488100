import  {FormEvent, useEffect, useRef, useState} from "react";
import './Login.scss';
import {
    AlphanumericInput,
    Button,
    IconLockClosed,
    IconMyAccount, Link as LinkLiberty
} from "@lmig/lmds-react";
import NavBarLogin from "../../../components/public/nav-bar-login/NavBarLogin";
import userProfile from '../../../assets/images/public/login/user.png';
import {InputChange, validRoles} from "../../../class/CommonClass";
import {LoginModel} from "../../../models/public/LoginModel";
import * as apiTokenService from "../../../services/api-session/ApiSession";
import {AxiosError} from "axios";
import {SpinnerLoading} from "../../../components/public/spinner-loading/SpinnerLoading";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {Link, useHistory} from "react-router-dom";
import {NegativeAlert} from "../../../components/public/negative-alert/NegativeAlert";
import hidden from '../../../assets/images/public/login/hidden.png';
import show from '../../../assets/images/public/login/show.png';
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import {UserModel} from "../../../models/public/UserModel";

const Login = () => {
    const languageState = useSelector((state: RootStore) => state.language.language?.login);

    const localStorageClass = new LocalStorageClass();

    const initLogin: LoginModel = {
        username: '',
        password: ''
    }

    const history = useHistory();

    const [login, setLogin] = useState<LoginModel>(initLogin);

    const [sending, setSending] = useState(false);

    const [errorSignin, setErrorSing] = useState(false);

    const [showErrorSubmit, setShowErrorSubmit] = useState<boolean>(false);

    const [showPsw, setShowPsw] = useState(false);

    const textInputUserName = useRef<HTMLInputElement | any>(null);

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const urlDash = '/menu';

    useEffect(() => {
        document.title = "Login";
        existSession()

    });

    const existSession = async () => {
        if (PERFIL.accessToken){
            try {
                const res = await apiTokenService.getValidaToken(PERFIL.accessToken);
                // console.log(res.data)
                history.push(urlDash);
            } catch (error) {
                const err = error as AxiosError
                if (err.response) {
                    if (err.response.status ==400){
                        console.log(err.response.status)
                        console.log(err.response.data)
                        history.push('/login');
                    }


                }
            }
        }

    }

    const handleInputChange = (e: InputChange) =>{
            setLogin({ ...login, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        setShowErrorSubmit(false);
        setErrorSing(false);
        e.preventDefault();
        if (validateFormLogin()) {
            setSending(true);
            try {
                const res = await apiTokenService.postSignin(login);
                setSending(false);

                    await localStorageClass.setSomeString(localStorageClass.PERFIL, JSON.stringify(res.data));
                    history.push(urlDash)


            } catch (error) {
                const err = error as AxiosError


                if (err.response) {
                    if (err.response.status == 401){
                        setSending(false);
                        setErrorSing(true);
                    }else{
                        setSending(false);
                        setShowErrorSubmit(true);
                        console.log(err.response.status)
                        console.log(err.response.data)
                    }

                }
            }
        }

    };

    const validateFormLogin = async  ()  => {
        return true;
    }

    return (
        <>
            <NavBarLogin/>

            <div className="container-fluid" id="login">


                <div className="row container-login">
                    <div className="col-md-4"/>
                    <div className="col-md-4 card" id="form-login">
                        <div className="row">
                            <div className="col-4"></div>
                            <div className="col-4 div-profile">
                                <img src={userProfile}
                                     className="img-fluid
                                                img-thumbnail
                                                d-block
                                                mx-auto"
                                     id="img-profile"/>
                            </div>
                            <div className="col-4 "></div>
                        </div>
                        {(sending)?<>
                            <h3 className="text-center">{languageState?.loading}</h3>
                            <SpinnerLoading />
                        </>:<>
                            <h3 className="text-center">{languageState?.login}</h3>
                            <form onSubmit={handleSubmit} className="col-12">
                                <div className="row input-group">

                                    <div className="col-md-2 ">
                                        <IconMyAccount
                                            size="24"
                                            className="d-none
                                                    d-sm-none
                                                    d-md-block
                                                    d-lg-block
                                                    d-xl-block
                                                    mx-auto "
                                        />
                                    </div>
                                    <div className="col-md-10 ">
                                        <AlphanumericInput labelVisual={languageState?.username}
                                                           className="input-user-name"
                                                           autoComplete="off"
                                                           ref={textInputUserName}
                                                           autoFocus={true}
                                                           name="username"
                                                           onChange={handleInputChange}
                                                           value={login.username}
                                                           type="text" />
                                    </div>
                                </div>

                                <div className="row input-group">

                                    <div className="col-md-2">
                                        <IconLockClosed
                                            size="24"
                                            className="d-none
                                                    d-sm-none
                                                    d-md-block
                                                    d-lg-block
                                                    d-xl-block
                                                    mx-auto "
                                        />
                                    </div>
                                    <div className="col-md-10">
                                        <div className="row">
                                            <div className="col-10">
                                                <AlphanumericInput labelVisual={languageState?.password}
                                                                   className="input-user-name"
                                                                   name="password"
                                                                   onChange={handleInputChange}
                                                                   value={login.password}
                                                                   type={showPsw?'text': 'password'} />
                                            </div>
                                            <div className="col-2">
                                                {(showPsw)?
                                                    <a
                                                        id="ico-show-psw"
                                                        onClick={()=>{
                                                            setShowPsw(false)
                                                        }} >
                                                        <img src={show} className="img-fluid d-block mx-auto"/>

                                                    </a>:
                                                    <a
                                                        id="ico-hidden-psw"
                                                        onClick={()=>{
                                                            setShowPsw(true)
                                                        }} >
                                                        <img src={hidden} className="img-fluid d-block mx-auto"/>

                                                    </a>
                                                }


                                            </div>
                                        </div>

                                    </div>

                                </div>

                                {/*     <CheckboxGroup onChange={function onChange(){}}>
                                <Checkbox id="remember-psw" labelVisual="Recordar Contraseña" name="default" value="remeber" />
                            </CheckboxGroup>*/}

                                {(errorSignin)?
                                    <div className="col-12 text-center">
                                        <br/>
                                       <NegativeAlert textAlert={languageState?.errorUserPsw!} />
                                        <br/>
                                    </div>: null}

                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="btn-login w-100">
                                    {languageState?.login}
                                </Button>
                                <br/>
                                <br/>
                                <hr/>
                                <div className="col-12 text-center">
                                    <Link to="/recuperar" >
                                        <LinkLiberty  variant="inline">
                                            {languageState?.recoverPsw}
                                        </LinkLiberty>
                                    </Link>
                                </div>


                            </form>
                        </> }


                    </div>
                    <div className="col-md-4"/>


                </div>

            </div>


        </>


    );
};

export default Login;
