export interface LanguageModelReporteGarantias {
    //Principal
    titlePage: string,
    busquedaAgente: string,
    busquedaFiado: string,
    fiado: string,
    garantia: string,
    agente: string,
    fecha_vencimiento: string,
    estatus: string,
    cumulo:string,
    fechaInicial: string,
    fechaFinal: string,
    

}

export const languageReporteGarantiasIngles: LanguageModelReporteGarantias = {
    titlePage: 'Expired Warranties',
    busquedaAgente: 'Search Agente',
    busquedaFiado:'Search Fiado',
    fiado:'Trusted',
    garantia:'Warranty',
    agente:'Agent',
    fecha_vencimiento: 'Expiration Date',
    estatus: 'Status',
    cumulo:'Cumulus',
    fechaInicial: 'Enter the Start Date',
    fechaFinal: 'Enter End Date',
}

export const languageReporteGarantiasEspaniol: LanguageModelReporteGarantias = {
    titlePage: 'Garantías Vencidas',
    busquedaAgente: 'Buscar Agente',
    busquedaFiado:'Buscar Fiado',
    fiado:'Fiado',
    garantia:'Garantía',
    agente:'Agente',
    fecha_vencimiento: 'Fecha Vencimiento',
    estatus: 'Estatus',
    cumulo:'Cúmulo',
    fechaInicial: 'Ingresa La Fecha Inicial',
    fechaFinal: 'Ingresa La Fecha Final',
    
}
