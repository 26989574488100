export interface AddObligorLanguage {
    searchOs: string;
    btnSearchOs: string;
    selectObligors: string;
    addOs: string;
    loadingOs: string;
    errorOs: string;
    noResults: string;
    results: string;
    search: string;
    errorSaving: string;
    close: string;
    save: string;
    saving: string;


}

export const AddObligorLanguageEnglish: AddObligorLanguage ={
    searchOs: 'Write Os',
    btnSearchOs: 'Search',
    selectObligors: 'Select ObligorsDetails',
    addOs: 'Add Obligor',
    loadingOs: 'Loading ObligorsDetails',
    errorOs: 'Sorry, something happened while loading ObligorsDetails, please check your connection and try again.',
    noResults: 'No results found',
    results: 'Results',
    search: 'Search Obligor',
    errorSaving: 'We\'re sorry, something has happened when saving Obligor\'s, check your connection and try again.\n',
    close: 'Close',
    save: 'Save',
    saving: 'Saving'

}
export const AddObligorLanguageSpanish: AddObligorLanguage ={
    searchOs: 'Escribe el Obligado',
    btnSearchOs: 'Buscar',
    selectObligors: 'Selecciona los aobligado Solidario ',
    addOs: 'Agregar OS',
    loadingOs: 'Cargando Obligado a Solidario',
    errorOs: 'Lo sentimos algo ha ocurrido al cargar Obligados a Solidario, revise su conexión e intente de nuevo.',
    noResults: 'No se Encontraron Resultados',
    results: 'Resultados',
    search: 'Buscar...',
    errorSaving: 'Lo sentimos algo, ha ocurrido al guardar los Obligados a Solidario, revise su conexión e intente de nuevo.',
    close: 'Cerrar',
    save: 'Guardar',
    saving: 'Guardando'
}