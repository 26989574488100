import React, { ChangeEvent, Component } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { DefaultStateI } from '../../../reducer/LanguageReducer';
import { RootStore } from '../../../Store';
import NavBar from '../../../components/public/nav-bar/NavBar';
import Form from '@lmig/lmds-react/Form/Form';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import Select from '@lmig/lmds-react/Form/Select/Select';
import SelectOption from '@lmig/lmds-react/Form/Select/SelectOption';
import { Alert, AlphanumericInput, Button, DatePicker, GridCol, GridRow, Heading, IconButton, IconDownload, IconEdit, IconSearch, IconTrash, IconUpload, Modal, Table, TableCell, TableRow } from '@lmig/lmds-react';
import './CreateQuery.scss'
import { BreadCumb } from '../../../components/public/breadcumb/BreadCumb';
import { PipeLineIcon } from '../../../components/public/pipeline-steps/PipelineIcon';
import { Entidad } from '../../../models/buro-credito/entidad';
import { AxiosError } from 'axios';
import * as getCreditbureau from "../../../services/buro-credito/CreditbureauService"
import * as apiS3Services from "../../../services/api-s3/ApiS3Services";
import * as consultaBuroServices from "../../../services/consultar-buro/ConsultarBuroServices";
import { ConsultaBuro } from '../../../models/buro-credito/ConsultaBuro';
import { DelegacionMunicipio, estado } from '../../../models/buro-credito/cp';
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { Fisicas } from '../../../models/buro-credito/Fisicas';
import { Morales } from '../../../models/buro-credito/Morales';
import { PaginacionModel } from '../../../models/public/PaginacionModel';
import { initPaginacion, MAXSIZEUPLOADFILE, validFilepdf } from '../../../class/CommonClass';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PDFFile } from '../../../components/buro-credito/PDFFile';
import { PDFMorales } from '../../../components/buro-credito/PDFMorales';
import { SpinnerLoading } from '../../../components/public/spinner-loading/SpinnerLoading';
import { UserModel } from '../../../models/public/UserModel';
import { ModalBody } from 'react-bootstrap';
import { foliosVigent } from '../../../models/buro-credito/Folios_vigentes';
import { SearchAutocomplete } from '../../../components/public/search-autocomplete/SearchAutocomplete';
import { ItemModel } from '../../../models/public/ItemModel';
import { fechasEdit, FolioSeq, FormatDocument, RegistroFormato, tableFormatDocument } from '../../../models/buro-credito/Datos';
import { PdfFile } from '../../../models/buro-credito/PdfFile';

export type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any>;

interface CreatequeryProps {
    language: DefaultStateI
}


class Createquery extends Component<CreatequeryProps & RouteComponentProps, CreatequeryState> {

    public localStorageClass = new LocalStorageClass();
    public select = document.getElementById('Persona');
    public archivo = "";
    public idFiado = "";
    public tipoEntidad = "";
    public date = new Date();
    public set = this.date.getFullYear() + '-' + String(this.date.getMonth() + 1).padStart(2, '0') + '-' + String(this.date.getDate()).padStart(2, '0');



    public initConsult: ConsultaBuro = {
        nombre: '',
        tipoEntidad: '',
        tipoPersona: '',
        rfc: '',
        estadoDomicilio: '',
        segundoNombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        fechaNacimiento: '',
        sexo: '',
        codigoPostal: '',
        calle: '',
        delegacionMunicipio: '',
        numexterior: '',
        estado: '',
        estadoRepublica: '',
        numinterior: '',
        telefono: '',
        colonia: '',
        razonSocial: '',
        representante: '',
        codigoBuro: '',
        idFormato: ''
    }
    public initDatos: FormatDocument = {
        fechaAutorizacion: '',
        fechaCarga: '',
        nomArchivoCarta: '',
        rfc: '',
        usuario: '',
        folioConsulta: '',
        subirArchivo: '',
        idCartaUsada: '',
        idCartaRelacionada:''

    }

    public initDatosF: tableFormatDocument = {
        estatus: '',
        fechaAutorizacion: '',
        fechaCarga: '',
        idFormato: '',
        idStatus: '',
        nombreArchivoCarta: '',
        rfc: '',
        usuario: '',
        statusCarta: '',
        idCartaUsada: ''
    }

    public initRegistroFormat: RegistroFormato = {
        fechaAutorizacion: '',
        fechaCarga: '',
        nombreArchivoCarta: '',
        rfc: '',
        usuario: '',
        folioConsulta: ''
    }

    public initPDF: PdfFile = {
        carpeta: '',
        nombreArchivo: ''
    }


    options: any;
    valido: boolean | undefined;

    constructor(props: any) {
        super(props);
        const est: estado = {

            estado: "",
            codigoBuro: "",
            delegacionMunicipio: [
                {
                    nombre: "",

                }
            ]
        };
        this.state = {
            estadoCaptura: 1,
            idWarranty: 0,
            loadingWarrantyDetail: false,
            errorWarrantyDetail: false,
            PERFIL: this.localStorageClass.getProfile(),
            select: '',
            status: 0,
            validador: 1,
            pdfOpen: false,
            entidad: [],
            datovalid: '',
            PDFObject: this.initPDF,
            prueba: this.initDatosF,
            consultObject: this.initConsult,
            tableFormatodOC: [],
            estados: est,
            valido: true,
            show: true,
            folio: '',
            auxCp: '',
            isValid: false,
            pdfShow: false,
            itemFiados: [],
            datofisica: [],
            condicion: false,
            Consul_buro: false,
            datoMoral: [],
            mensajeF: '',
            isClose: false,
            isOpen: false,
            mensajeM: '',
            Loading: true,
            auxIdcliente: 0,
            formData: new FormData(),
            FormFisicas: new FormData(),
            fechaAutorizacion: this.set,
            validafile: 0,
            paginacion: initPaginacion,
            loadingConsult: true,
            listPaginacion: [],
            consulta: true,
            searchFiado: '',
            datoCaptura: [],
            search: '',
            tipopersona: '',
            viewCalif: false,
            table: 0,
            alerta: 0,
            alerta1: 0,
            alerta2: 0,
            boton: false,
            value: '',
            visible: true,
            rfc: '',
            listFiadoOption: [],
            mensajeConsult: [],
            fechaAutoAux: '',
            fechaAutorizacionFormato: '',
            fechaConsultaBuro: '',
            calif: '',
            pdf: '',
            datoForm: this.initDatos,
            modalUpdate: false,
            idFormatoDelete: '',
            errortablaformat: false,
            registroFormato: this.initRegistroFormat,
            isValid1: false,
            FolioSeq: {
                folio: ''
            },
            fechas: {
                fechaAutorizacion: '',
                idFormato: '',
                folio: ''
            },
            variable: {
                folio: '',
                nombre: '',
                tipoEntidad: '',
                tipoPersona: '',
                rfc: '',
                estadoRepublica: '',
                segundoNombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                fechaNacimiento: '',
                sexo: '',
                codigoPostal: '',
                calle: '',
                estado: '',
                colonia: '',
                estadoDomicilio: '',
                numexterior: '',
                delegacionMunicipio: '',
                numinterior: '',
                telefono: '',
                razonSocial: '',
                representante: '',
                codigoBuro: '',
                idFormato: ''
            }
        }


        this.NextPage = this.NextPage.bind(this);
        this.PrevPage = this.PrevPage.bind(this);
        this.Endquery = this.Endquery.bind(this);
        this.handleInpu = this.handleInpu.bind(this);
        this.handleinputChange = this.handleinputChange.bind(this);
        this.postDatos = this.postDatos.bind(this);
        this.Submit = this.Submit.bind(this);
        this.getMorales = this.getMorales.bind(this);
        this.getfisicas = this.getfisicas.bind(this);
        this.getCredit = this.getCredit.bind(this);
        this.PdfVis = this.PdfVis.bind(this);
        this.getCalifBuro = this.getCalifBuro.bind(this);
        this.fechAut = this.fechAut.bind(this);
        this.Cerrar = this.Cerrar.bind(this);
        this.getDatosFiado = this.getDatosFiado.bind(this);
        this.seleccion = this.seleccion.bind(this);
        this.abrirModal = this.abrirModal.bind(this);
        this.onSelectItem = this.onSelectItem.bind(this);
        this.ChangeModal = this.ChangeModal.bind(this);
        this.posTablaFormato = this.posTablaFormato.bind(this);
        this.getTablaForm = this.getTablaForm.bind(this);
        this.putUpdatetablaForm = this.putUpdatetablaForm.bind(this);
        this.postArchivoidformato = this.postArchivoidformato.bind(this);
    }

    render(): JSX.Element {
        return <>
          <NavBar />
            <div className="container-fluid warranty" id="create-query">
              
                <div className="create">

                    <BreadCumb title={this.props.language.language?.createquery.credittitle!}
                        listBreadcumbs={[
                            { title: this.props.language.language?.createquery.Home!, url: '/buro-credito' },
                            { title: this.props.language.language?.createquery.Create!, url: '/buro-credito/crear-consulta' }
                        ]} />

                    <div className="row" id="header-create">

                        <div className="container">

                            <div className="Pipeline col-md-12 col-sm-12" id='pipeline'>
                                <PipeLineIcon
                                    listSteps={[this.props.language.language?.createquery.pipeline1!,
                                    this.props.language.language?.createquery.pipeline2!,
                                    this.props.language.language?.createquery.pipeline4!]}
                                    currentStep={this.state.estadoCaptura! - 1} />


                            </div>
                        </div>


                        <div className="Formulario" id='formulario'>



                            <Form id="form-query" onSubmit={this.postDatos}>
                                <div className="col-md-12" id='consult'>
                                    {this.state.estadoCaptura === 1 ? (

                                        <div className="col-md-12" id='datos'>
                                            <h3>{[this.props.language.language?.createquery.data!]}</h3>
                                            <hr />



                                            <div className='col-12'>
                                                <p>{[this.props.language.language?.createquery.entity!]}</p>
                                                <Select
                                                    id="tipoEntidad"
                                                    labelVisual={this.props.language.language?.createquery.select!}
                                                    name="tipoEntidad"
                                                    onChange={this.handleinputChange}
                                                    onBlur={this.ChangeModal}
                                                    value={this.state.consultObject.tipoEntidad}
                                                >

                                                    <SelectOption value="0">
                                                        {this.props.language.language?.createquery.select!}
                                                    </SelectOption>
                                                    {
                                                        this.state.entidad.map((i: Entidad, index: number) =>
                                                        (<SelectOption value={i.id!.toString()} key={index}  >
                                                            {i.nombre}
                                                        </SelectOption>
                                                        ))
                                                    }



                                                </Select>

                                                <Modal className='Modal-fiado-buro' isOpen={this.state.isOpen} size="large"
                                                    modalStyle="standard" closeFunc={() => {
                                                        this.setState({ isOpen: false });
                                                        this.props.history.push('/buro-credito/crear-consulta');
                                                    }}>
                                                    <div className="autocomplete-wrapper-fiados-buro" >
                                                        <ModalBody>

                                                            <Heading tag="span" type="h3-light">Buscar Fiado</Heading>
                                                            <br /><br />
                                                            <div className='item'  >
                                                                <SearchAutocomplete
                                                                    items={this.state.itemFiados}
                                                                    placeHolder={'Buscar Fiados'}
                                                                    onSelect={this.onSelectItem}
                                                                    onSearch={this.onSearchItem}
                                                                    onClear={this.onClearItem}
                                                                />
                                                            </div>
                                                            <br /><br />
                                                            <Button id='btn-fiado-busqueda' variant="primary" onClick={this.Cerrar} disabled={this.state.visible}>Continuar</Button>

                                                        </ModalBody>

                                                    </div>
                                                </Modal>
                                                <br />
                                                <p>{[this.props.language.language?.createquery.person!]}</p>
                                                <Select
                                                    id="tipoPersona"
                                                    name='tipoPersona'
                                                    labelVisual={this.props.language.language?.createquery.select!}
                                                    onChange={this.handleinputChange}
                                                    value={this.state.consultObject.tipoPersona}


                                                >
                                                    <SelectOption value="2">
                                                        Moral
                                                    </SelectOption>
                                                    <SelectOption value="1">
                                                        Fisica
                                                    </SelectOption>

                                                </Select>
                                            </div>


                                            <br />
                                            {this.state.consultObject.tipoPersona === '1' &&

                                                <>
                                                    <div className="col-md-12" id='datos'>
                                                        <h3>{[this.props.language.language?.createquery.persondata!]}</h3>
                                                        <hr />

                                                        <p id='calif' className='is-required'>{[this.props.language.language?.createquery.dataRequired!]}</p>

                                                        <div className='col-12'>

                                                            <p>RFC</p>
                                                            <input
                                                                type="text"
                                                                id="rfc"
                                                                name='rfc'
                                                                className='form-control'
                                                                maxLength={13}
                                                                onChange={this.handleinputChange}
                                                                onFocus={this.handleinputChange}

                                                                value={this.state.consultObject.rfc}
                                                            />


                                                            <br />
                                                            <p className='is-required'>{[this.props.language.language?.createquery.name!]}</p>
                                                            <input
                                                                id="nombre"
                                                                name='nombre'
                                                                className='form-control'
                                                                onChange={this.handleinputChange}
                                                                value={this.state.consultObject.nombre} />

                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.second!]}</p>
                                                            <input
                                                                id="segundoNombre"
                                                                name='segundoNombre'
                                                                className='form-control'
                                                                onChange={this.handleinputChange}
                                                                value={this.state.consultObject.segundoNombre} />
                                                            <br />
                                                            <p className='is-required'>{[this.props.language.language?.createquery.last!]}</p>
                                                            <input
                                                                id="apellidoPaterno"
                                                                name='apellidoPaterno'
                                                                className='form-control'
                                                                onChange={this.handleinputChange}
                                                                value={this.state.consultObject.apellidoPaterno} />

                                                            <br />

                                                            <p>{[this.props.language.language?.createquery.mother!]}</p>
                                                            <input
                                                                id="apellidoMaterno"
                                                                name='apellidoMaterno'
                                                                className='form-control'
                                                                onChange={this.handleinputChange}
                                                                value={this.state.consultObject.apellidoMaterno} />

                                                            <br />
                                                            <p >{[this.props.language.language?.createquery.date!]}</p>



                                                            <input
                                                                type='date'
                                                                id="fechaNacimiento"
                                                                name='fechaNacimiento'
                                                                className='form-control'
                                                                min="1936-01-01" max="2005-12-31"
                                                                value={this.state.consultObject.fechaNacimiento}
                                                                onChange={this.handleinputChange}
                                                            />
                                                            <br />
                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.telefono!]}</p>
                                                            <input
                                                                id="telefono"
                                                                name='telefono'
                                                                className='form-control'
                                                                onChange={this.handleinputChange}
                                                                value={this.state.consultObject.telefono}
                                                                onBlur={this.handleinputChange} />
                                                            <br />


                                                            <p>{[this.props.language.language?.createquery.sex!]}</p>
                                                            <Select
                                                                id="sexo"
                                                                name='sexo'
                                                                onChange={this.handleinputChange}
                                                                value={this.state.consultObject.sexo} labelVisual={this.props.language.language?.createquery.select!}
                                                            >
                                                                <SelectOption value="M">
                                                                    Hombre
                                                                </SelectOption>
                                                                <SelectOption value="F">
                                                                    Mujer
                                                                </SelectOption>
                                                            </Select>

                                                        </div>
                                                    </div><br /><div className="col-md-12" id='datos'>
                                                        <h3>{[this.props.language.language?.createquery.residence!]}</h3>
                                                        <hr />


                                                        <div className='col-12'>

                                                            <p className='is-required'>C.P</p>
                                                            <input
                                                                id="codigoPostal"
                                                                name='codigoPostal'
                                                                className='form-control'
                                                                value={this.state.consultObject.codigoPostal}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} />

                                                            {this.state.alerta2 === 2 &&
                                                                <>
                                                                    <h6 id='calif'>Cp es requerido</h6>
                                                                </>}


                                                            <br />
                                                            <p className='is-required'>{[this.props.language.language?.createquery.state!]}</p>


                                                            <input
                                                                id="estadoRepublica"
                                                                name='estadoRepublica'
                                                                className='form-control'
                                                                onChange={this.handleinputChange}
                                                                value={this.state.estados?.estado}
                                                                disabled />


                                                            <br />
                                                            <p className='is-required'>{[this.props.language.language?.createquery.town!]}</p>
                                                            <Select
                                                                id="delegacionMunicipio"
                                                                name='delegacionMunicipio'
                                                                onChange={this.handleinputChange}
                                                                labelVisual
                                                                value={this.state.consultObject.delegacionMunicipio}
                                                            >

                                                                <SelectOption value="0">
                                                                    {this.props.language.language?.createquery.select!}
                                                                </SelectOption>
                                                                {this.state.estados.delegacionMunicipio.map((i: DelegacionMunicipio, index: number) => (
                                                                    <SelectOption value={i.nombre.toString()} key={index}>
                                                                        {i.nombre}
                                                                    </SelectOption>
                                                                ))}


                                                            </Select>

                                                            <br />


                                                            <br />
                                                            <p className='is-required'>{[this.props.language.language?.createquery.street!]}</p>
                                                            <input
                                                                id="calle"
                                                                name='calle'
                                                                className='form-control'
                                                                onChange={this.handleinputChange}
                                                                value={this.state.consultObject.calle} />
                                                            <br />
                                                            <p className='is-required'>{[this.props.language.language?.createquery.number!]}</p>
                                                            <input
                                                                id="numexterior"
                                                                name='numexterior'
                                                                className='form-control'
                                                                onChange={this.handleinputChange}
                                                                value={this.state.consultObject.numexterior}
                                                                onBlur={this.handleinputChange} />
                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.numint!]}</p>
                                                            <input
                                                                id="numinterior"
                                                                name='numinterior'
                                                                className='form-control'
                                                                onBlur={this.handleinputChange}
                                                                onChange={this.handleinputChange}
                                                                value={this.state.consultObject.numinterior}
                                                            />
                                                            <br />
                                                            <GridRow gutterSize="fixed-16" gutters>

                                                                <GridCol base={12} md={12}>
                                                                    <Button dynamicWidth variant="primary" onClick={this.NextPage} disabled={this.state.boton} >
                                                                        {this.props.language.language?.createquery.NextPage!}
                                                                    </Button>
                                                                </GridCol>
                                                            </GridRow>
                                                        </div>

                                                    </div></>
                                            }

                                            {this.state.consultObject.tipoPersona === '2' &&


                                                <><div className="col-12" id='datos'>
                                                    <h3>{[this.props.language.language?.createquery.persondata!]}</h3>
                                                    <hr />

                                                    <p id='calif' className='is-required'>{[this.props.language.language?.createquery.dataRequired!]}</p>


                                                    <div className='col-12'>

                                                        <p className='is-required'>RFC</p>
                                                        <input
                                                            type="text"
                                                            id="rfc"
                                                            name='rfc'
                                                            className='form-control'
                                                            maxLength={13}
                                                            onChange={this.handleinputChange}
                                                            value={this.state.consultObject.rfc}
                                                        />


                                                        <br />
                                                        <p className='is-required'>{[this.props.language.language?.createquery.razonsocial!]}</p>
                                                        <input
                                                            id="razonSocial"
                                                            name='razonSocial'
                                                            value={this.state.consultObject.razonSocial}
                                                            className='form-control'
                                                            onChange={this.handleinputChange}
                                                            required />

                                                        <br />
                                                        <p className='is-required'>{[this.props.language.language?.createquery.representante!]}</p>
                                                        <input
                                                            id="representante"
                                                            name='representante'
                                                            className='form-control'
                                                            onChange={this.handleinputChange}
                                                            value={this.state.consultObject.representante} required />

                                                        <br />
                                                        <p>{[this.props.language.language?.createquery.telefono!]}</p>
                                                        <input
                                                            id="telefono"
                                                            name='telefono'
                                                            className='form-control'
                                                            onChange={this.handleinputChange}
                                                            value={this.state.consultObject.telefono}
                                                            onBlur={this.handleinputChange} />
                                                        <br />

                                                    </div>
                                                </div><br /><div className="col-md-12" id='datos'>
                                                        <h3>{[this.props.language.language?.createquery.residence!]}</h3>
                                                        <hr />


                                                        <div className='col'>

                                                            <p className='is-required'>C.P</p>
                                                            <input
                                                                id="codigoPostal"
                                                                name='codigoPostal'
                                                                className='form-control'
                                                                value={this.state.consultObject.codigoPostal}
                                                                onChange={this.handleinputChange}
                                                                onBlur={this.handleinputChange} />

                                                            {this.state.alerta2 === 2 &&
                                                                <>
                                                                    <h6 id='calif'>CP es requerido</h6>
                                                                </>}


                                                            <br />
                                                            <p className='is-required'>{[this.props.language.language?.createquery.state!]}</p>


                                                            <input
                                                                id="estadoRepublica"
                                                                name='estadoRepublica'
                                                                className='form-control'
                                                                onChange={this.handleinputChange}
                                                                value={this.state.estados?.estado}
                                                                disabled />


                                                            <br />
                                                            <p className='is-required'>{[this.props.language.language?.createquery.town!]}</p>
                                                            <Select
                                                                id="delegacionMunicipio"
                                                                name='delegacionMunicipio'
                                                                onChange={this.handleinputChange}
                                                                labelVisual={this.props.language.language?.createquery.select!}
                                                                value={this.state.consultObject.delegacionMunicipio}

                                                            >
                                                                <SelectOption value="0">
                                                                    {this.props.language.language?.createquery.select!}
                                                                </SelectOption>

                                                                {this.state.estados.delegacionMunicipio.map((i: DelegacionMunicipio, index: number) => (
                                                                    <SelectOption value={i.nombre.toString()} key={index}>
                                                                        {i.nombre}
                                                                    </SelectOption>
                                                                ))}


                                                            </Select>

                                                            <br />


                                                            <br />
                                                            <p className='is-required'>{[this.props.language.language?.createquery.street!]}</p>
                                                            <input
                                                                id="calle"
                                                                name='calle'
                                                                className='form-control'
                                                                onChange={this.handleinputChange}
                                                                value={this.state.consultObject.calle} />
                                                            <br />
                                                            <p className='is-required'>{[this.props.language.language?.createquery.number!]}</p>
                                                            <input
                                                                id="numexterior"
                                                                name='numexterior'
                                                                className='form-control'
                                                                onChange={this.handleinputChange}
                                                                value={this.state.consultObject.numexterior}
                                                            />

                                                            <br />
                                                            <p>{[this.props.language.language?.createquery.numint!]}</p>
                                                            <input
                                                                id="numinterior"
                                                                name='numinterior'
                                                                className='form-control'
                                                                onChange={this.handleinputChange}
                                                                value={this.state.consultObject.numinterior}
                                                            />

                                                            <br />
                                                            <GridRow gutterSize="fixed-16" gutters>

                                                                <GridCol base={12} md={12}>
                                                                    <Button dynamicWidth variant="primary" onClick={this.NextPage} disabled={this.state.boton} >
                                                                        {this.props.language.language?.createquery.NextPage!}
                                                                    </Button>
                                                                </GridCol>
                                                            </GridRow>
                                                        </div>

                                                    </div></>
                                            }
                                        </div>



                                    ) : null}
                                    {this.state.estadoCaptura === 2 ? (
                                        <>
                                            <div className="row">
                                                <div className="col-md-6" >
                                                    <div className="card" id='cards-idcartas'>
                                                        <div className="card-body text-center">
                                                            <h3 className="card-title">{this.props.language.language?.createquery.format!}</h3><br /><br />
                                                            {this.state.variable.tipoPersona === 'Fisica' &&
                                                                <>
                                                                    <div className="icon-wrapper">
                                                                        {this.state.condicion === true &&
                                                                            <>
                                                                                <PDFDownloadLink document={<PDFFile />} fileName="AutorizacionparasolicitarInformacionCrediticia">
                                                                                    {({ loading }) =>
                                                                                        loading ? (
                                                                                            ''
                                                                                        ) : (
                                                                                            <IconDownload size="64" color='gray' />
                                                                                        )
                                                                                    }
                                                                                </PDFDownloadLink>
                                                                            </>}
                                                                    </div>
                                                                </>}

                                                            {this.state.variable.tipoPersona === 'Moral' &&
                                                                <>
                                                                    <div className="icon-wrapper">
                                                                        {this.state.condicion === true &&
                                                                            <>
                                                                                <PDFDownloadLink document={<PDFMorales />} fileName="AutorizacionparasolicitarInformacionCrediticia">
                                                                                    {({ loading }) =>
                                                                                        loading ? (
                                                                                            ''
                                                                                        ) : (
                                                                                            <IconDownload size="64" color='gray' />
                                                                                        )
                                                                                    }
                                                                                </PDFDownloadLink>
                                                                            </>}
                                                                    </div>
                                                                </>}
                                                            {this.props.language.language?.createquery.downl!}
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card" id='cards-idcartas'>
                                                        <div className="file-upload" id='image-upload-wrap'>

                                                            <input
                                                                type="file"
                                                                id='file-upload-input'
                                                                accept='application/pdf'
                                                                onChange={this.handleChange}
                                                            />
                                                            <div className="card-body text-center">
                                                                <h3 className="card-title">{this.props.language.language?.createquery.auto!}</h3>

                                                                <div className="text-information">
                                                                    <IconUpload size="64" color='gray' /><br />
                                                                    {this.props.language.language?.createquery.arrastra!} <br />
                                                                    {this.props.language.language?.createquery.file!}

                                                                    {this.state.consulta === false &&
                                                                        <div className='col-12' id='alert'>
                                                                            <Alert id='alert_up' highlightType="positive">
                                                                                {this.props.language.language?.createquery.alert_up!}
                                                                            </Alert>
                                                                        </div>}
                                                                </div>

                                                                <Modal className='Modal-fecha' isOpen={this.state.pdfShow} size="large"
                                                                    modalStyle="standard" closeFunc={() => {
                                                                        this.setState({ pdfShow: false });
                                                                    }}>
                                                                    <ModalBody>
                                                                        <div className="autocomplete-wrapper-fecha" >
                                                                            <Heading tag="span" type="h3-light"> {this.props.language.language?.createquery.fechaauto!}</Heading>
                                                                            <br /><br />
                                                                            <div className="dropdown">

                                                                                <input
                                                                                    type='date'
                                                                                    id="fechaAuto"
                                                                                    name='fechaAuto'
                                                                                    className='form-control'
                                                                                    value={this.state.fechaAutorizacion}
                                                                                    onChange={this.handleinputDate}
                                                                                    onBlur={this.handleinputDate}

                                                                                />

                                                                            </div>
                                                                            {this.state.isValid ? (
                                                                                <div className="col-12">
                                                                                    <Alert id='alert' highlightType="negative">
                                                                                        {this.props.language.language?.createquery.fechaInco!}
                                                                                    </Alert>
                                                                                </div>
                                                                            ) :
                                                                                <>
                                                                                </>}
                                                                        </div>
                                                                        <Button id='btn-fecha-auto' variant="primary" onClick={this.fechAut}>
                                                                            {this.props.language.language?.createquery.guardar!}
                                                                        </Button>
                                                                    </ModalBody>

                                                                </Modal><br /><br />
                                                                <Button className='btn-archivo' size="small" variant="primary" onClick={this.PdfVis} hidden={this.state.show}>
                                                                    {this.props.language.language?.createquery.visualFile!}
                                                                </Button>


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Modal isOpen={this.state.pdfOpen} size="large"
                                                        className='Modal-pdf'
                                                        closeFunc={() => {
                                                            this.setState({ pdfOpen: false });
                                                        }}
                                                    >

                                                        <iframe title='archivo' className="iframe-pdf" src={this.archivo} allowFullScreen ></iframe>


                                                    </Modal>
                                                </div>
                                            </div>

                                            <div>
                                                <br /><br /><br />
                                                {this.state.errortablaformat ? (
                                                    <div className="col-12">
                                                        <Alert id='alert_up' highlightType="negative">
                                                            {this.props.language.language?.createquery.alertaDatos!}
                                                        </Alert>
                                                    </div>
                                                ) :
                                                    <>
                                                        {this.state.Consul_buro === true ? (
                                                            <div className="col-12">
                                                                <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                                            </div>
                                                        ) :

                                                            <>

                                                                <div className="col-md-12">
                                                                    <Table id='table-archivo' className='Tablaformatos' >
                                                                        <thead>
                                                                            <TableRow >
                                                                                <TableCell dataHeader={'Folio'} type="colHead" className='tb'>
                                                                                    No.
                                                                                </TableCell>
                                                                                <TableCell dataHeader={'fechaCarga'} type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.fechaCarga!}
                                                                                </TableCell>
                                                                                <TableCell dataHeader={'Tipo'} type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.fechaAutorizacion!}
                                                                                </TableCell>
                                                                                <TableCell dataHeader={'Fecha'} type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.user!}
                                                                                </TableCell>
                                                                                <TableCell dataHeader={'FolioBC'} type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.status!}
                                                                                </TableCell>
                                                                                <TableCell dataHeader={'FolioBC'} type="colHead" className='tb'>
                                                                                    {this.props.language.language?.createquery.acciones!}
                                                                                </TableCell>


                                                                            </TableRow>
                                                                        </thead>

                                                                        {this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => (

                                                                            <>
                                                                                <tbody>

                                                                                    <TableRow >

                                                                                        <TableCell type="rowHead">
                                                                                            {index + 1}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {value.fechaCarga}
                                                                                        </TableCell>
                                                                                        <TableCell >
                                                                                            {value.fechaAutorizacion}
                                                                                        </TableCell>
                                                                                        <TableCell >
                                                                                            {value.usuario}
                                                                                        </TableCell>
                                                                                        <TableCell >
                                                                                            {value.estatus}
                                                                                        </TableCell>


                                                                                        <TableCell dataHeader={'Estatus'} className="estatus">
                                                                                            <div className="row">
                                                                                                <div className="col">
                                                                                                    <IconButton background="transparent" onClick={() => this.handleView(value.nombreArchivoCarta)} >
                                                                                                        <IconSearch size="24" />
                                                                                                    </IconButton>
                                                                                                    {value.estatus === 'Vigente' && value.idCartaUsada === '0' ? (
                                                                                                        <>
                                                                                                            <IconButton background="transparent" onClick={() => this.handleEdit(value.fechaAutorizacion, value.idFormato, value.nombreArchivoCarta)} >
                                                                                                                <IconEdit size="24" />
                                                                                                            </IconButton>
                                                                                                            <IconButton background="transparent" onClick={() => this.handleDelete(value.idFormato)} >
                                                                                                                <IconTrash size="24" />
                                                                                                            </IconButton>
                                                                                                        </>
                                                                                                    ) :
                                                                                                        <>
                                                                                                        </>}
                                                                                                </div>

                                                                                            </div>



                                                                                        </TableCell>

                                                                                    </TableRow>





                                                                                </tbody>
                                                                            </>
                                                                        ))}
                                                                    </Table>
                                                                    <br />

                                                                </div>
                                                            </>}
                                                    </>}

                                                <Modal className='Modal-fecha' isOpen={this.state.modalUpdate} size="medium"
                                                    modalStyle="standard" closeFunc={() => {
                                                        this.setState({ modalUpdate: false });
                                                    }}>
                                                    <ModalBody>
                                                        <div className="autocomplete-wrapper-fecha" >
                                                            <Heading tag="span" type="h3-light">Edición de Fechas</Heading>
                                                            <br /><br />
                                                            <div className="container">
                                                                <div className="row">

                                                                    <div className="col">
                                                                        <div className="dropdown">
                                                                            <p>{this.props.language.language?.createquery.fechaAutorizacion!}</p>
                                                                            <input
                                                                                type='date'
                                                                                id="fechaAutorizacion"
                                                                                name='fechaAutorizacion'
                                                                                className='form-control'
                                                                                value={this.state.fechas.fechaAutorizacion}
                                                                                onChange={this.handleinputfecha}

                                                                            />
                                                                            {this.state.isValid1 ? (
                                                                                <div className="col-12">
                                                                                    <Alert id='alert' highlightType="negative">
                                                                                        {this.props.language.language?.createquery.fechaInco!}
                                                                                    </Alert>
                                                                                </div>
                                                                            ) :
                                                                                <>
                                                                                </>}
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <br />
                                                            <div>
                                                                <Button variant="primary" size="large" id='guadarfecha' onClick={this.putUpdatetablaForm} >
                                                                    {this.props.language.language?.createquery.guardar!}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </ModalBody>

                                                </Modal>
                                            </div>
                                            <GridRow gutterSize="fixed-16" gutters>
                                                <GridCol base={12} md={6}>
                                                    <Button dynamicWidth onClick={this.PrevPage}>
                                                        {this.props.language.language?.createquery.PrevPage!}
                                                    </Button>
                                                </GridCol>
                                                <GridCol base={12} md={6}>
                                                    <Button dynamicWidth variant="primary" onClick={this.Submit} >
                                                        {this.props.language.language?.createquery.NextPage!}
                                                    </Button>
                                                </GridCol>
                                            </GridRow>
                                        </>

                                    ) : null}
                                    {this.state.estadoCaptura === 3 ? (
                                        <>

                                            {this.state.variable.tipoPersona === 'Fisica' &&
                                                <>
                                                    <h3>{this.props.language.language?.createquery.detallef!}</h3>
                                                    <br />
                                                    {this.state.errorWarrantyDetail ? (
                                                        <div className="col-12">
                                                            <Alert highlightType="negative">
                                                                {this.state.mensajeF}
                                                            </Alert>
                                                        </div>
                                                    ) :
                                                        <>

                                                            {this.state.Consul_buro === true ? (
                                                                <div className="col-12">
                                                                    <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                                                </div>
                                                            ) :

                                                                <>


                                                                    {this.state.table === 1 &&

                                                                        <div className="tabla" id='tabla'>
                                                                            {this.state.viewCalif === true &&
                                                                                <>
                                                                                    <div className="container">
                                                                                        <div className="row">
                                                                                            <div className="col"  >
                                                                                                <h5 id='calif'>{this.props.language.language?.createquery.fechaCarga!}: {this.state.fechaConsultaBuro}</h5>
                                                                                            </div>
                                                                                            <div className="col"  >
                                                                                                <h5 id='calif'>{this.props.language.language?.createquery.fechaAutorizacion!}: {this.state.fechaAutorizacionFormato}</h5>
                                                                                            </div>
                                                                                            <div className="col">
                                                                                                <h5 id='calif'>{this.props.language.language?.createquery.calif!}: {this.state.calif}</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>}
                                                                            <Table id="table-credit">
                                                                                <thead>
                                                                                    <TableRow>
                                                                                        <TableCell dataHeader="A" type="colHead">
                                                                                            {this.props.language.language?.createquery.credit!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="B" type="colHead">
                                                                                            {this.props.language.language?.createquery.grantor!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="C" type="colHead">
                                                                                            {this.props.language.language?.createquery.saldo!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="D" type="colHead">
                                                                                            {this.props.language.language?.createquery.amount!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="E" type="colHead">
                                                                                            {this.props.language.language?.createquery.method!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="F" type="colHead">
                                                                                            {this.props.language.language?.createquery.max!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="G" type="colHead">
                                                                                            {this.props.language.language?.createquery.update!}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </thead>
                                                                                {this.state.datofisica.map((value: Fisicas, index: number) => (

                                                                                    <>
                                                                                        <tbody>
                                                                                            <TableRow key={index}>
                                                                                                <TableCell dataHeader="A" type="rowHead">
                                                                                                    {value.tipoCredito}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.tipoOtorgante}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.saldoActual}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.monto}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.formatoPago}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.maxMorosMopFechaImporte}
                                                                                                </TableCell>
                                                                                                <TableCell dataHeader="B">
                                                                                                    {value.ultimaActualizacion}
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                            <TableRow>

                                                                                            </TableRow>
                                                                                        </tbody>
                                                                                    </>
                                                                                ))}
                                                                            </Table>
                                                                        </div>
                                                                    }</>} </>}
                                                </>}
                                            {this.state.variable.tipoPersona === 'Moral' &&
                                                <>
                                                    <h3>{this.props.language.language?.createquery.detalle!}</h3>
                                                    <br />
                                                    {this.state.errorWarrantyDetail ? (
                                                        <div className="col-12">
                                                            <Alert highlightType="negative">
                                                                {this.state.mensajeM}
                                                            </Alert>
                                                        </div>
                                                    ) :
                                                        <>
                                                            {this.state.Consul_buro ? (
                                                                <div className="col-12">
                                                                    <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                                                </div>
                                                            ) :
                                                                <>


                                                                    {this.state.table === 1 &&
                                                                        <div className="tabla" id='tabla'>
                                                                            {this.state.viewCalif === true &&
                                                                                <>
                                                                                    <div className="container">
                                                                                        <div className="row">
                                                                                            <div className="col"  >
                                                                                                <h5 id='calif'>{this.props.language.language?.createquery.fechaCarga!}: {this.state.fechaConsultaBuro}</h5>
                                                                                            </div>
                                                                                            <div className="col"  >
                                                                                                <h5 id='calif'>{this.props.language.language?.createquery.fechaAutorizacion!}: {this.state.fechaAutorizacionFormato}</h5>
                                                                                            </div>
                                                                                            <div className="col">
                                                                                                <h5 id='calif'>{this.props.language.language?.createquery.calif!}: {this.state.calif}</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </>}
                                                                            <Table id="table-credit">
                                                                                <thead>
                                                                                    <TableRow>
                                                                                        <TableCell dataHeader="A" type="colHead">
                                                                                            {this.props.language.language?.createquery.contrato!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="B" type="colHead">
                                                                                            {this.props.language.language?.createquery.tipooto!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="C" type="colHead">
                                                                                            {this.props.language.language?.createquery.tipocre!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="D" type="colHead">
                                                                                            {this.props.language.language?.createquery.vgente!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="E" type="colHead">
                                                                                            {this.props.language.language?.createquery.dia1!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="F" type="colHead">
                                                                                            {this.props.language.language?.createquery.dia30!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="G" type="colHead">
                                                                                            {this.props.language.language?.createquery.dia60!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="G" type="colHead">
                                                                                            {this.props.language.language?.createquery.dia90!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="G" type="colHead">
                                                                                            {this.props.language.language?.createquery.dia120!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="G" type="colHead">
                                                                                            {this.props.language.language?.createquery.dia180!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="G" type="colHead">
                                                                                            {this.props.language.language?.createquery.histo!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader="G" type="colHead">
                                                                                            {this.props.language.language?.createquery.actua!}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </thead>

                                                                                {this.state.datoMoral.map((value: Morales, index: number) => (

                                                                                    <>

                                                                                        <tbody>
                                                                                            <TableRow key={index}>
                                                                                                <TableCell type="rowHead">
                                                                                                    {value.id}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.tipoOtorgante}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.tipoCredito}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.saldoVigente}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.vencido1a29}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.vencido30a59}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.vencido60a89}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.vencido90a119}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.vencido120a179}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.vencido180}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.historicoPagos}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.fechaActualizado}
                                                                                                </TableCell>

                                                                                            </TableRow>
                                                                                            <TableRow>

                                                                                            </TableRow>
                                                                                        </tbody>
                                                                                    </>
                                                                                ))}
                                                                            </Table>
                                                                        </div>
                                                                    } </>} </>}


                                                    <br /><br />

                                                </>}
                                            <br /><br />
                                            <div className="boton" >
                                                <GridRow gutterSize="fixed-16" gutters>
                                                    <GridCol base={12} md={6}>
                                                        <Button dynamicWidth onClick={this.PrevPage}>
                                                            {this.props.language.language?.createquery.PrevPage!}
                                                        </Button>
                                                    </GridCol>
                                                    <GridCol base={12} md={6}>
                                                        <Button dynamicWidth variant="primary" onClick={this.Endquery}>
                                                            {this.props.language.language?.createquery.finalize!}
                                                        </Button>
                                                    </GridCol>
                                                </GridRow>

                                            </div>


                                        </>

                                    ) : null}
                                </div>
                            </Form>



                        </div>

                    </div>
                </div>
            </div >
        </>


    }




    saveWarranty() {
        throw new Error('Method not implemented.');

    }

    componentDidMount() {
        this.getEntid();
        this.getEstado();
        this.getDatos();
    }

    public NextPage() {
        const { estadoCaptura } = this.state
        this.postDatos();
        this.setState({ estadoCaptura: estadoCaptura + 1 })
    }

    //funcion para  regresar al apartado anterior
    public PrevPage() {
        const { estadoCaptura } = this.state
        this.setState({ estadoCaptura: estadoCaptura - 1 })

    }

    //Regresamos al dashboard del inicio
    public Endquery() {
        this.props.history.push('/buro-credito');
    }

    //fimcion para abrir el apartado del  pdf
    public PdfVis() {
        this.setState({ pdfOpen: true })

    }

    //funcion para eliminar los datos de la tabla formatos
    public handleDelete(value: string) {
        //console.log(value)
        this.setState({
            idFormatoDelete: value
        })
        this.alertDeleteFormat()

    }

    //funcion para visualizar el pdf y enviamos los datos para obtener la ruta
    public handleView(value: string): void {
        this.setState({ PDFObject: { carpeta: 'buro', nombreArchivo: value + `.pdf` } }, () =>
            this.postURl());
    }

    //Editamos las fecha de la tabla formatos
    public handleEdit(fechaAutorizacion: string, idFormato: string, folio: string) {
        //console.log(fechaAutorizacion)
        //console.log(fechaCarga)

        this.setState({
            fechas: {
                ...this.state.fechas,
                fechaAutorizacion: fechaAutorizacion,
                idFormato: idFormato,
                folio: folio
            }
        })
        this.setState({ modalUpdate: true })


        //this.putUpdatetablaForm();
    }

    //enviamos los datos
    public Submit(e: InputChange) {
        e.preventDefault();
        const { estadoCaptura } = this.state
        this.setState({ estadoCaptura: estadoCaptura + 1 })
        if (this.state.variable.idFormato!.toString() === '0') {
            console.log('si no tiene archivo entra al if principal')
            this.postFechAutoidFormatoO(this.state.registroFormato.fechaAutorizacion)
            this.postArchivoidformato(this.state.variable.idFormato!)
        }
        this.getfisicas();
        this.getMorales();

    }


    //Carta usada
    public async cartaUsada(idCartaUsada: number, idFormato: string) {
        try {
            const res = await getCreditbureau.putCartaUsada(idCartaUsada, idFormato);
            
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }

        }
    }

    //obtenetmos la url del s3 para el archivo
    public async postURl() {
        try {
            const res = await apiS3Services.postUrl(this.state.PDFObject);
            const urldata = JSON.stringify(res.data.url)
            const comillas = urldata.replace(/['"]+/g, '')
            //console.log(comillas)
            var win = window.open(comillas, '_blank');
            win?.focus();

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }



    //nos regresa si ya tiene un archivo ligado la conuslta
    async getregistroFormatoBuro(p: string): Promise<void> {
        try {
console.log('entra a registrar el archivo')
            const res = await getCreditbureau.getregistroFormatoBuro(this.state.variable.idFormato!);
            //console.log(res.data)
            this.setState({ registroFormato: res.data })

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }

    }

    //guardamos la fecha de autorización al momento de editarla
    public handleinputfecha = async (e: InputChange) => {
        //var inputFields = this.state.fechas;
        e.persist();
        await this.setState(preveState => ({
            fechas: { ...this.state.fechas, [e.target.name]: e.target.value }

        }))



        var fechaA = this.state.fechas.fechaAutorizacion.split("-")
        var fechA = fechaA[2] + "-" + fechaA[1] + "-" + fechaA[0];
        //this.setState({ fechaAutoAux: fech })
        // console.log(this.state.fechaAutoAux)
        var isValid1 = fechA.match(/^(3[01]|[12][0-9]|0?[1-9])(\/|-)(1[0-2]|0?[1-9])\2([0-9]{2})?[0-9]{2}$/);

        if (isValid1) {
            // console.log("fecha Correcta.");
            this.setState({ isValid1: false })
        } else {
            //console.log("Fecha Incorrecta");
            this.setState({ isValid1: true })
        }

    }





    //guardamos la fecha de autorización  al momento de subir el archivo
    public handleinputDate = (e: InputChange): void => {
        this.setState({ fechaAutorizacion: e.target.value })
        //console.log(this.state.fechaAutorizacion)

        var fecha = e.target.value.split("-")
        var fech = fecha[2] + "-" + fecha[1] + "-" + fecha[0];
        this.setState({ fechaAutoAux: fech })
        // console.log(this.state.fechaAutoAux)
        var isValid = fech.match(/^(3[01]|[12][0-9]|0?[1-9])(\/|-)(1[0-2]|0?[1-9])\2([0-9]{2})?[0-9]{2}$/);

        if (isValid) {
            // console.log("fecha Correcta.");
            this.setState({ isValid: false })
        } else {
            //console.log("Fecha Incorrecta");
            this.setState({ isValid: true })
        }


    }


    //esta funcion esta creada para mandar llamar estas funciones
    public fechAut() {
        this.postFechAuto();
        this.setState({ pdfShow: false })
        this.postFileValidacion(this.state.formData);
    }

    //obtenemos el tipo de datos y mandamos llamar a la función abrirModal para la busqueda de fiado
    public ChangeModal() {
        this.abrirModal();
        this.state.consultObject.tipoEntidad = this.state.variable.tipoEntidad
        //console.log('si entra aqui al handle ' + this.state.variable.tipoEntidad)
    }


    //funcion para abrir el modal de buscar fiados, siempre y cuando el tipo de entidad sea 1
    public abrirModal() {
        if (this.state.consultObject.tipoEntidad === '1') {
            this.setState({ isOpen: true })
            this.tipoEntidad = '1';
        }
        if (this.state.consultObject.tipoEntidad !== '1') {
            this.setState({ isOpen: false })
        }
    }

    //función para obtener lo que escribimos en nuetsra busqueda de fiado
    public onSearchItem = (search: string) => {
        //console.log(search)
        this.setState({ searchFiado: search }, () =>
            this.getConsult(this.state.searchFiado));
    }
    //función obtenemos los datos del fiado que seleccionamos 
    public onSelectItem = (itemSelected: ItemModel): void => {
        //console.log(itemSelected.id)
        var auxIdifiado = itemSelected.id
        var auxauxFiado = auxIdifiado;
        //console.log('este es el auxi' + auxIdifiado)
        this.getDatosFiado(auxauxFiado)
    }
    //para limpiar
    public onClearItem = () => {

    }

    //cerramos el modal de buscar fiados al dar clic al boton continuar
    Cerrar() {
        this.setState({ isOpen: false })
        if (this.state.mensajeConsult.length !== 0) {
            this.setState({ isClose: true })
        } else {
            this.setState({ isClose: false })
        }

    }

    //nos regresa la secuencia para el archivo
    public async getFolioSeqFormato() {
        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data

                //console.log(this.state.fechaAutoAux)
                const res = await getCreditbureau.getFolioSeqFormato(dt)
                //this.posTablaFormato();
                //console.log(res)

            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    //funcion que nos regresa las entidades
    public async getEntid() {
        try {
            const res = await getCreditbureau.getEntidades();
            //console.log(res.data)
            this.setState({ entidad: res.data })

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    public handleInpu(e: InputChange) {
        if (e.target.name === 'persona') {
            this.setState({ validador: 2 })

        } else {
            //console.log('error')
        }

    }




    //nos regresa los datos del fiado buscado y lo agregamos a la vista
    async getDatosFiado(auxauxFiado: number | null): Promise<void> {
        try {
            //console.log("Antes de realizar la consulta" + auxauxFiado)
            const res = await getCreditbureau.getConsultaidFiado(1, auxauxFiado, this.state.PERFIL.accessToken);
            this.state.consultObject.nombreAgente = this.state.PERFIL.username;

            var ob = Object.values(res.data)
            this.setState({ consultObject: ob[0] })
            var objet = Object.values(res)
            this.setState({ datoCaptura: [] })
            this.setState({ datoCaptura: Object.values(res.data) })
            //this.state.consultObject.tipoEntidad = '1'
            this.idFiado = " ";
            this.setState({ visible: false })
            if (objet[0].length !== 0) {
                if (objet[0].length === 1) {
                    if (this.state.consultObject.tipoPersona === 'Fisica') {
                        this.state.consultObject.tipoPersona = '1'
                        this.state.consultObject.tipoEntidad = '1'
                        this.state.consultObject.sexo = this.state.consultObject.sexo
                        this.state.consultObject.solicitante = this.state.PERFIL.username
                        this.state.consultObject.autoriza = this.state.PERFIL.username
                        this.state.consultObject.rolSolicitante = this.state.PERFIL.roles.toString()
                        this.state.consultObject.fechaNacimiento = this.state.consultObject.fechaNacimiento
                        this.state.consultObject.estadoRepublica = this.state.consultObject.estado!
                        this.state.consultObject.codigoBuro = this.state.estados?.codigoBuro;
                        this.state.consultObject.segundoNombre = this.state.consultObject.segundoNombre
                        this.state.consultObject.colonia = this.state.consultObject.colonia
                        this.state.consultObject.estadoDomicilio = this.state.consultObject.estadoDomicilio
                        this.state.consultObject.idFiado! = this.state.consultObject.idFiado!
                        this.state.consultObject.idCliente! = this.state.consultObject.idFiado!
                        this.state.consultObject.pais = 'MX';
                        if (this.state.consultObject.segundoNombre === undefined && this.state.consultObject.colonia === undefined && this.state.consultObject.estadoDomicilio === undefined) {
                            this.state.consultObject.segundoNombre = ''
                            this.state.consultObject.colonia = ''
                            this.state.consultObject.estadoDomicilio = ''

                        }
                        const varIdcli = this.state.consultObject.idFiado!
                        //console.log(varIdcli)
                        this.setState({ auxIdcliente: varIdcli })
                        //console.log(this.state.auxIdcliente + ' dime por favor que si lo paso')
                        //console.log(this.state.consultObject)
                        this.state.consultObject.idCliente! = this.state.auxIdcliente
                        //console.log(this.state.consultObject.idFiado!)
                        this.setState({ alerta: 2 })
                        this.getEstado()

                    }
                    if (this.state.consultObject.tipoPersona === 'Moral') {
                        console.log(this.state.consultObject.tipoPersona)
                        this.state.consultObject.tipoPersona = '2'
                        this.state.consultObject.tipoEntidad = '1'
                        this.state.consultObject.sexo = this.state.consultObject.sexo
                        this.state.consultObject.nombre = this.state.datoCaptura[0].razonSocial
                        this.state.consultObject.solicitante = this.state.PERFIL.username
                        this.state.consultObject.autoriza = this.state.PERFIL.username
                        this.state.consultObject.rolSolicitante = this.state.PERFIL.roles.toString()
                        this.state.consultObject.estadoRepublica = this.state.consultObject.estado!
                        this.state.consultObject.codigoBuro = this.state.estados?.codigoBuro;
                        this.state.consultObject.idFiado! = this.state.consultObject.idFiado!
                        this.state.consultObject.idCliente! = this.state.consultObject.idFiado!
                        this.state.consultObject.pais = 'MX';
                        this.state.consultObject.segundoNombre = this.state.consultObject.segundoNombre
                        this.state.consultObject.colonia = this.state.consultObject.colonia
                        this.state.consultObject.estadoDomicilio = this.state.consultObject.estadoDomicilio
                        if (this.state.consultObject.segundoNombre === undefined && this.state.consultObject.colonia === undefined && this.state.consultObject.estadoDomicilio === undefined) {
                            this.state.consultObject.segundoNombre = ''
                            this.state.consultObject.colonia = ''
                            this.state.consultObject.estadoDomicilio = ''

                        }
                        const varIdcli = this.state.consultObject.idFiado!
                        //console.log(varIdcli)
                        this.setState({ auxIdcliente: varIdcli })
                        this.state.consultObject.idCliente! = this.state.auxIdcliente
                        //console.log(this.state.consultObject.idFiado! + ' dime por favor que si lo paso')
                        //console.log(this.state.consultObject)
                        this.setState({ alerta: 1 })
                        this.getEstado()

                    }
                }

            } else {
                this.setState({ datoCaptura: [] })
            }



        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }




    //obtenemos la busqueda de los datos por el rfc/nombre del fiado
    async getConsult(busqueda: string): Promise<void> {
        try {
            const res = await getCreditbureau.getConsultaFiado(busqueda, 0, this.state.PERFIL.accessToken);
            this.setState({ datoCaptura: Object.values(res.data) })
            this.clientData();


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {

                this.setState({ value: busqueda })
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }


    //obtemos la lista de los fiados para mostrarla en nuestro selec
    clientData() {

        console.log("Entra a client data")

        let listaClientesAuxiliar: ItemModel[];
        listaClientesAuxiliar = []
        // console.log('entra aqui')

        for (const condition of this.state.datoCaptura) {
            //console.log('entra al for')

            listaClientesAuxiliar.push({
                id: condition.idFiado!, name: condition.rfcRazonSocial!
            })
        }


        //console.log('llega aqui en la list')
        this.setState({ itemFiados: listaClientesAuxiliar })

    }



    //obtenemos los datos formato para la tabla
    public async putDeletetablaForm() {
        try {
            await getCreditbureau.putDeletetablaForm(this.state.idFormatoDelete);
            this.getTablaForm(this.state.variable.rfc)


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    //actualizamos la fecha autorizada al editar por el idFormato
    public async putUpdatetablaForm() {

        // console.log(this.state.fechas)
        var fechas = this.state.fechas.fechaAutorizacion.split("-")
        var fechaA = fechas[2] + "-" + fechas[1] + "-" + fechas[0];
        this.state.fechas.fechaAutorizacion = fechaA

        const res = await getCreditbureau.putUpdatetablaForm(this.state.fechas.fechaAutorizacion, this.state.fechas.idFormato);
        //console.log(res)
        this.setState({ modalUpdate: false });
        this.getTablaForm(this.state.variable.rfc)
        this.postFechaAutoEdit(this.state.fechas.fechaAutorizacion, this.state.fechas.folio)
    }






    //actualizamos la fecha autorización al momento de agregar el archivo
    public async postFechAuto() {
        try {
            var fecha = this.state.fechaAutorizacion.split("-")
            var fech = fecha[2] + "-" + fecha[1] + "-" + fecha[0];
            this.setState({ fechaAutoAux: fech })
            //console.log(this.state.fechaAutoAux)
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data

                //console.log(this.state.fechaAutoAux)
                await getCreditbureau.postFechAuto(this.state.fechaAutoAux, dt)
               // this.posTablaFormato();

            }
        } catch {

        }

    }

    //agregamos los datos del archivo que subimos al s3
    public async posTablaFormato() {
        try {

            var fecha = this.state.fechaAutorizacion.split("-")
            var fech = fecha[2] + "/" + fecha[1] + "/" + fecha[0];
            let date = new Date();
            let set = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
            //console.log(this.state.fechaAutoAux)
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                //El idCarta 0 se manda por default cuando se genera un nuevo registro y se actualiza cuando se invoca al otro endpoint "putCartaUsada"
                this.setState({
                    datoForm:
                    {
                        ...this.state.datoForm,
                        fechaAutorizacion: fech,
                        fechaCarga: set,
                        nomArchivoCarta: this.state.FolioSeq.folio,
                        rfc: this.state.consultObject.rfc,
                        usuario: this.state.PERFIL.username,
                        folioConsulta: dt,
                        subirArchivo: '1',
                        idCartaUsada: '0'
                    }
                })

                //console.log(this.state.fechaAutoAux)
                this.setState({ fechaAutoAux: fech })
                await getCreditbureau.posTablaFormato(this.state.datoForm)
                this.getTablaForm(this.state.consultObject.rfc);
                this.getCredit();

            }
        } catch {

        }
    }


    //Mandamos la info si idformato es igual a 0

    public postArchivoidformato(idFormato: string) {
        console.log('entra aqui a la funcion de neviar archivo')
        console.log(idFormato)
        const status = this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => value.nombreArchivoCarta);
        const str = status[0].toString();
        //console.log(str)
        const fechaauto = this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => value.fechaAutorizacion);
        const strF = fechaauto[0].toString();
        const strFa = strF.split("-")
        const strAuto = strFa[2] + '/' + strFa[1] + '/' + strFa[0];
        //console.log(strAuto)
        const fechacarga = this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => value.fechaCarga);
        const strC = fechacarga[0].toString();
        const strCa = strC.split("-")
        const strCarga = strCa[2] + '/' + strCa[1] + '/' + strCa[0];
        //console.log(strCarga)
        console.log('Si entra cuando no tienen archivo' + idFormato)
        var storedata = localStorage.getItem('FolioConsulta');
        if (storedata == null) {
            console.log('vacio')
        } else {
            var data = storedata.replace(/['"]+/g, '')
            var dt = data
            this.setState({
                datoForm:
                {
                    ...this.state.datoForm,
                    fechaAutorizacion: strAuto,
                    fechaCarga: strCarga,
                    nomArchivoCarta: dt,
                    rfc: this.state.consultObject.rfc,
                    usuario: this.state.PERFIL.username,
                    folioConsulta: str,
                    subirArchivo: '0',
                    idCartaUsada: '1'
                }
            }, () => this.prueba()
            )
            
            //this.getTablaForm(this.state.consultObject.rfc);
            this.getregistroFormatoBuro(this.state.consultObject.idFormato!)

        }
    }

    public async prueba() {
        console.log('entra aqui para subir el postarchivo')
        const res = await getCreditbureau.posTablaFormato(this.state.datoForm)
        this.getCredit();
        const idCartaRelacionada = res.data.idFormato!
        console.log(idCartaRelacionada)
        this.cartaUsada(1, idCartaRelacionada)
        //console.log(res.data)


    }

    //obtenmos los datos de la tabla formatos
    public async getTablaForm(rfc: string) {
        this.setState({ Consul_buro: true })
        try {

            const res = await getCreditbureau.getTablaForm(rfc)
            var datoForm = res.data
            var ob = Object.values(datoForm)
            this.setState({ prueba: datoForm })
            this.setState({ tableFormatodOC: ob })
            if (ob.length === 0) {
                this.setState({ errortablaformat: true })
                this.setState({ Consul_buro: false })


            } else {
                this.setState({ Consul_buro: false })
                this.setState({ errortablaformat: false })
                const status = this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => value.nombreArchivoCarta);
                const str = status[0].toString();


                const fechaauto = this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => value.fechaAutorizacion);
                const strF = fechaauto[0].toString();


                this.setState({
                    registroFormato: {
                        nombreArchivoCarta: str,
                        fechaAutorizacion: strF,
                        rfc: this.state.registroFormato.rfc,
                        usuario: this.state.registroFormato.usuario,
                        fechaCarga: this.state.registroFormato.fechaCarga,
                        folioConsulta: this.state.registroFormato.folioConsulta
                    }
                })

                //console.log(this.state.registroFormato.nombreArchivoCarta)
            }
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    //enviamos la fecha autorización para que se actualice en el dashboard
    public async postFechAutoidFormatoO(fecha: string) {
        try {

            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                var fechaA = fecha.split("-")
                var fech = fechaA[2] + "-" + fechaA[1] + "-" + fechaA[0];

                await getCreditbureau.postFechAuto(fech, dt)
                //this.posTablaFormato();
            }

        } catch {

        }

    }

    //enviamos la fecha auto al editar
    public async postFechaAutoEdit(fecha: string, folio: string) {
        try {


            await getCreditbureau.postFechAuto(fecha, folio)
            //this.posTablaFormato();


        } catch {

        }

    }

    // obtenemos los datos que ingresamos
    public handleinputChange = async (e: InputChange,) => {
        var inputFields = this.state.consultObject;
        e.persist();
        await this.setState(preveState => ({
            consultObject: { ...this.state.consultObject, [e.target.name]: e.target.value }

        }))
        //console.log('entra al handleinputchange')
        const estado = this.state.estados?.estado;
        const codigoBuro = this.state.estados?.codigoBuro;
        const rolSolicitante = this.state.PERFIL.roles.toString();
        const solicitante = this.state.PERFIL.username;
        const razonSocial = this.state.consultObject.razonSocial
        const autoriza = this.state.PERFIL.username;
        const idFormato = this.state.consultObject.idFormato!


        this.setState({
            consultObject:
            {
                ...this.state.consultObject,
                solicitante: solicitante,
                estadoRepublica: estado,
                codigoBuro: codigoBuro,
                rolSolicitante: rolSolicitante,
                autoriza: autoriza,
                idFormato: idFormato
            }
        })
        if (this.state.consultObject.tipoPersona === '2') {
            this.setState({
                consultObject:
                {
                    ...this.state.consultObject,
                    nombre: razonSocial,

                }
            })
        }
        //this.state.consultObject.estado = '';
        if (this.state.consultObject.tipoPersona === '1') {
            if (inputFields["codigoPostal"] !== '') {
                // console.log('entra en fisicas')
                this.setState({ boton: false })
                this.setState({ alerta2: 1 })
            } else {
                this.setState({ alerta2: 2 })
                this.setState({ boton: true })
            }
        }


        if (this.state.consultObject.tipoPersona === '2') {
            if (inputFields["codigoPostal"] !== '' && inputFields["rfc"] !== '') {
                // console.log('entra en fisicas')
                this.setState({ boton: false })
                this.setState({ alerta2: 1 })
            } else {
                this.setState({ alerta2: 2 })
                this.setState({ boton: true })
            }
        }

        if (this.state.consultObject.segundoNombre === undefined && this.state.consultObject.colonia === undefined && this.state.consultObject.estadoDomicilio === undefined) {
            this.setState({
                consultObject:
                {
                    ...this.state.consultObject,
                    segundoNombre: '',
                    colonia: '',
                    estadoDomicilio: ''

                }
            })

        }
        if (this.state.consultObject.tipoEntidad !== '1') {
            this.state.consultObject.idFiado! = 0
        }

        this.state.consultObject.idFiado! = this.state.auxIdcliente
        this.state.consultObject.idCliente! = this.state.consultObject.idFiado!
        //console.log('idfiado al cargar' + this.state.consultObject.idCliente!)


        //console.log(this.state.consultObject)
        const vars = this.state.consultObject
        this.setState({ variable: vars })
        //console.log(this.state.variable)
        this.getEstado();
    }

    //Funcion para tener los datos en una variable secundaria
    public seleccion(folio: string) {
        this.setState({
            consultObject: {
                folio: this.state.consultObject.folio,
                nombre: this.state.consultObject.nombre,
                tipoEntidad: this.state.consultObject.tipoEntidad,
                tipoPersona: this.state.consultObject.tipoPersona,
                rfc: this.state.consultObject.nombre,
                estadoRepublica: this.state.estados?.estado,
                segundoNombre: this.state.consultObject.segundoNombre,
                apellidoPaterno: this.state.consultObject.apellidoPaterno,
                apellidoMaterno: this.state.consultObject.apellidoMaterno,
                fechaNacimiento: this.state.consultObject.fechaNacimiento,
                sexo: this.state.consultObject.sexo,
                codigoPostal: this.state.consultObject.codigoPostal,
                calle: this.state.consultObject.calle,
                colonia: this.state.consultObject.colonia,
                estado: this.state.consultObject.estado,
                numexterior: this.state.consultObject.numexterior,
                delegacionMunicipio: this.state.consultObject.delegacionMunicipio,
                numinterior: this.state.consultObject.numinterior,
                telefono: this.state.consultObject.telefono,
                estadoDomicilio: this.state.consultObject.estadoDomicilio,
                razonSocial: this.state.consultObject.razonSocial,
                representante: this.state.consultObject.representante,
                codigoBuro: this.state.estados?.codigoBuro,
                idFormato: this.state.consultObject.idFormato!


            }
        })


    }


    public getDatos(): void {

        var storedata = localStorage.getItem('FolioConsulta');
        if (storedata == null) {
            console.log('vacio')
        } else {
            var data = storedata.replace(/['"]+/g, '')
            this.setState({ folio: data })


        }
    }


    //Servicio de Buro para persona Fisica
    public async getfisicas() {
        this.setState({ Consul_buro: true })
        try {


            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
            } else {
                if (this.state.variable.tipoPersona === 'Fisica') {
                    var tipoConsulta = 'PF';
                    var data = storedata.replace(/['"]+/g, '')
                    var dt = data
                    if (this.state.variable.fechaNacimiento === undefined) {
                        if (this.state.consultObject.fechaNacimiento === undefined || this.state.consultObject.fechaNacimiento === 'NaNNaN' || this.state.consultObject.fechaNacimiento === 'NaN') {
                            this.state.consultObject.fechaNacimiento = "";


                        }
                    }
                    if (this.state.variable.fechaNacimiento !== undefined || this.state.variable.fechaNacimiento !== '') {
                        var fecha = this.state.variable.fechaNacimiento.split("-")
                        this.state.variable.fechaNacimiento = '';
                        this.state.variable.fechaNacimiento = fecha[2] + fecha[1] + fecha[0];
                        // this.state.variable.rfc = encodeURIComponent(this.state.consultObject.rfc);
                        this.state.variable.calle = encodeURIComponent(this.state.variable.calle);
                        this.state.variable.numexterior = encodeURIComponent(this.state.variable.numexterior);
                        this.state.variable.numinterior = encodeURIComponent(this.state.variable.numinterior);
                        // this.state.variable.calle = encodeURIComponent(this.state.variable.calle);

                        var fechasA = this.state.registroFormato.fechaAutorizacion.split("-")
                        this.state.registroFormato.fechaAutorizacion = '';
                        this.state.registroFormato.fechaAutorizacion = fechasA[2] + '-' + fechasA[1] + '-' + fechasA[0];
                        const res = await consultaBuroServices.getFisicas(this.state.variable.rfc, this.state.variable.nombre, this.state.variable.segundoNombre,
                            this.state.variable.apellidoPaterno, this.state.variable.apellidoMaterno, this.state.variable.fechaNacimiento, this.state.variable.codigoPostal, this.state.variable.calle,
                            this.state.variable.codigoBuro, this.state.variable.estadoDomicilio!, this.state.variable.delegacionMunicipio, this.state.variable.numexterior, dt, tipoConsulta, this.state.PERFIL.username, this.state.variable.tipoEntidad, this.state.registroFormato.fechaAutorizacion, this.state.variable.idCliente!
                        )
                        const mensaje = res.data.mensaje;
                        this.setState({ mensajeF: mensaje });
                        //console.log(this.state.mensajeF)
                        if (res.data.creditos == null) {
                            this.setState({ errorWarrantyDetail: true })
                            this.setState({ table: 0 })
                        } else {
                            const ob = (res.data.creditos)
                            this.setState({ datofisica: ob })
                            this.setState({ errorWarrantyDetail: false })
                            this.setState({ table: 1 })
                            this.setState({ Consul_buro: false })
                            this.getCalifBuro();
                            console.log(this.state.variable.idFormato!)
                            //if (this.state.variable.idFormato! !== '0') {
                                this.cartaUsada(1, this.state.variable.idFormato!)
                           // }


                        }
                    }
                }
            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }


    //Servicio de Buro para persona Moral
    public async getMorales() {

        this.setState({ Consul_buro: true })
        try {

            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                if (this.state.variable.tipoPersona === 'Moral') {
                    var tipoConsulta = 'PM';
                    var data = storedata.replace(/['"]+/g, '')
                    var dt = data

                    this.state.variable.rfc = encodeURIComponent(this.state.consultObject.rfc);
                    this.state.variable.nombre = encodeURIComponent(this.state.consultObject.razonSocial);
                    this.state.variable.calle = encodeURIComponent(this.state.variable.calle);
                    this.state.variable.numexterior = encodeURIComponent(this.state.variable.numexterior);
                    this.state.variable.numinterior = encodeURIComponent(this.state.variable.numinterior);
                    //this.state.variable.rfc = encodeURIComponent(this.state.consultObject.rfc);
                    //this.state.variable.nombre = encodeURIComponent(this.state.consultObject.razonSocial);
                    var fechasA = this.state.registroFormato.fechaAutorizacion.split("-")
                    this.state.registroFormato.fechaAutorizacion = '';
                    this.state.registroFormato.fechaAutorizacion = fechasA[2] + '-' + fechasA[1] + '-' + fechasA[0];
                    const res = await consultaBuroServices.getMorales(this.state.variable.rfc, this.state.variable.nombre,
                        this.state.variable.calle, this.state.variable.delegacionMunicipio,
                        this.state.variable.codigoPostal, this.state.variable.estadoDomicilio!, this.state.variable.codigoBuro, this.state.variable.numexterior, dt
                        , tipoConsulta, this.state.PERFIL.username, this.state.variable.tipoEntidad, this.state.registroFormato.fechaAutorizacion, this.state.variable.idCliente!
                    )
                    const mensaje = res.data.mensaje;
                    this.setState({ mensajeM: mensaje });

                    if (res.data.creditos == null) {
                        this.setState({ errorWarrantyDetail: true })
                        this.setState({ table: 0 })
                    } else {
                        const ob = (res.data.creditos)
                        this.setState({ datoMoral: ob })
                        this.setState({ table: 1 })
                        this.setState({ Consul_buro: false })
                        this.setState({ errorWarrantyDetail: false })
                        this.getCalifBuro();
                        console.log(this.state.variable.idFormato!)
                        //if (this.state.variable.idFormato! !== '0') {
                            this.cartaUsada(1, this.state.variable.idFormato!)
                        //}
                    }
                }

            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    //obtenemos los datos del archivo para guardarlo al s3
    public handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

        if (e.target.files![0].size >= MAXSIZEUPLOADFILE) {
            this.alertArchivo();
            const fileObj = e.target.files && e.target.files[0];
            if (!fileObj) {
                return;

            }

            this.setState({ consulta: true })
            this.setState({ show: true })
            this.setState({ pdfOpen: true })
            this.setState({ pdfShow: true })

            //console.log('fileObj is', fileObj);

            // 👇️ reset file input
            e.target.value = '';

            // 👇️ is now empty
            //console.log(e.target.files);

        } else {


            if (validFilepdf(e)) {
                var storedata = localStorage.getItem('FolioConsulta');
                if (storedata == null) {
                    console.log('vacio')
                } else {
                    var data = storedata.replace(/['"]+/g, '')
                    const dt = data;
                    const res = await getCreditbureau.getFolioSeqFormato(dt)
                    const ob = res.data
                    this.setState({ FolioSeq: ob })
                    const formDataAuxiliar = this.state.formData
                    formDataAuxiliar.set('archivo', e.target.files![0]);
                    formDataAuxiliar.set('carpeta', 'buro');
                    formDataAuxiliar.set('nombreArchivo', this.state.FolioSeq.folio + '.pdf');
                    this.setState({ formData: formDataAuxiliar })
                    this.setState({ consulta: false })
                    //console.log(e.target.files![0].arrayBuffer)
                    this.setState({ pdf: e.target.files![0].name })
                    this.setState({ show: false })
                    this.setState({ pdfShow: true })
                    var fecha = this.state.fechaAutorizacion.split("-")
                    var fech = fecha[2] + "-" + fecha[1] + "-" + fecha[0];
                    this.setState({ fechaAutoAux: fech })
                    // console.log(this.state.fechaAutoAux)
                    var prueba = (window.URL).createObjectURL(e.target.files![0])
                    //console.log(prueba)
                    this.archivo = prueba;


                }


            }
            if (this.state.formData === null) {
                //console.log('no hay archivo')
            }

        }


    }

    //Nos muestra la calificacion y fechas de la consulta Buro
    public async getCalifBuro() {

        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                const res = await getCreditbureau.getCalifBuro(dt)
                if (this.state.variable.tipoEntidad === 'Fiado') {
                    this.setState({ viewCalif: true })
                } else {
                    this.setState({ viewCalif: false })
                }
                if (res.data.calificacion !== null) {
                    const calif = res.data.calificacion
                    const fechAuto = res.data.fechaAutorizacionFormato
                    const fechConsul = res.data.fechaConsultaBuro
                    this.setState({ calif: calif })
                    this.setState({ fechaAutorizacionFormato: fechAuto })
                    this.setState({ fechaConsultaBuro: fechConsul })

                } else {
                    const calif = 'No aplica';
                    this.setState({ calif: calif })
                }
            }
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    //enviamos el archivo al s3
    public async postFileValidacion(formData: FormData) {

        try {
            await apiS3Services.postFile(formData)
            //const arrayFile = res.data
            //console.log(arrayFile);
            this.getCredit();
            this.posTablaFormato()
            //this.setState({ Consul_buro: true })

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    //Datos consulta usuarios ya cargados
    async getCredit(): Promise<void> {
        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                const res = await getCreditbureau.getConsul(dt);
                const dat = Object.values(res.data)
                if (dat.length === 0) {
                    this.setState({ condicion: false })
                } else {
                    //console.log(res.data)
                    this.setState({ condicion: true })
                    this.setState({ variable: res.data })
                    this.state.consultObject.fechaNacimiento = this.state.variable.fechaNacimiento;
                    this.getTablaForm(this.state.consultObject.rfc);
                    if (this.state.variable.idFormato!.toString() !== '0') {
                        this.getregistroFormatoBuro(this.state.variable.idFormato!);
                    }


                }
            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }


    //obtenemos los datos del cp
    public async getEstado() {
        try {
            //console.log('entra aqui')
            const res = await getCreditbureau.getestado(this.state.consultObject.codigoPostal);
            if (res.data?.estado) {
                this.setState({ estados: res.data })
                this.state.consultObject.codigoBuro = res.data?.codigoBuro
                this.state.consultObject.codigoPostal = this.state.consultObject.codigoPostal

            }

            //console.log(res.data?.codigoBuro)

            if (res.data?.codigoBuro === '') {
                //console.log('Verifica bien tu cp')
            }

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }




    //Guardamos los datos creados
    public async postDatos() {

        try {
            var fecha = this.state.consultObject.fechaNacimiento.split("-")
            this.state.consultObject.fechaNacimiento = '';
            this.state.consultObject.fechaNacimiento = fecha[2] + '/' + fecha[1] + '/' + fecha[0];
            this.state.consultObject.segundoNombre = this.state.consultObject.segundoNombre
            this.state.consultObject.idCliente! = this.state.consultObject.idFiado!
            //console.log('cuando enviamos a guadar los datos ' + this.state.consultObject.idCliente!)
            delete this.state.consultObject.rfcRazonSocial
            delete this.state.consultObject.razonSocialRfc
            delete this.state.consultObject.idFiado

            //console.log('Cuando se envia a guardar' + this.state.consultObject)
            const res = await getCreditbureau.postArchivo(this.state.consultObject);

            //console.log('antes' + res.data)
            this.alert();
            this.setState({ tipopersona: res.data.tipoPersona! })
            localStorage.setItem('FolioConsulta', JSON.stringify(res.data.folio));
            this.getCredit();
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                // console.log('datos guardados incorrectamente')
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }


    }





    //alertas
    public alert() {
        Swal.fire(
            'Datos guardados correctamente!',
            '',
            'success'
        )
    }
    public alertArchivo() {
        Swal.fire(
            'Archivo demasiado grande!',
            '',
            'warning'
        )
    }

    public alertDeleteFormat() {

        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        Swal.fire({
            title: 'Estas seguro de eliminar el archivo?',
            text: "No podras recuperarlo",
            icon: 'warning',
            confirmButtonColor: '#008040',
            cancelButtonColor: '#D32F2F',
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Eliminado!',
                    'Tu archivo a sido borrado con exito',
                    'success'
                )
                this.putDeletetablaForm();
            }

        })
    }


}



const mapStateToProps = (state: RootStore) => ({
    language: state.language
});

export default connect(mapStateToProps)(Createquery);


interface CreatequeryState {

    estadoCaptura: number;
    idWarranty: number,
    loadingWarrantyDetail: boolean,
    errorWarrantyDetail: boolean,
    select: string,
    status: number,
    validador: number,
    entidad: Entidad[],
    datovalid: string,
    consultObject: ConsultaBuro,
    estados: estado,
    valido: boolean,
    FormFisicas: FormData,
    show: boolean,
    fechaAutorizacion: string,
    datofisica: Fisicas[],
    datoMoral: Morales[],
    Loading: boolean,
    formData: FormData,
    validafile: number,
    paginacion: PaginacionModel,
    loadingConsult: boolean,
    listPaginacion: number[],
    consulta: boolean,
    folio: string,
    datoCaptura: ConsultaBuro[],
    search: string,
    tipopersona: string,
    alerta: number,
    alerta1: number,
    alerta2: number,
    boton: boolean,
    variable: ConsultaBuro,
    Consul_buro: Boolean,
    table: number,
    mensajeF: string,
    mensajeM: string,
    condicion: boolean
    pdf: string,
    PERFIL: UserModel,
    pdfOpen: boolean,
    calif: string,
    viewCalif: boolean,
    pdfShow: boolean,
    fechaAutoAux: string,
    fechaAutorizacionFormato: string,
    fechaConsultaBuro: string,
    isOpen: boolean,
    value: string,
    listFiadoOption: FiadoOption[],
    rfc: string,
    visible: boolean,
    mensajeConsult: foliosVigent[],
    isClose: boolean,
    itemFiados: ItemModel[];
    auxIdcliente: number,
    auxCp: string,
    isValid: boolean,
    datoForm: FormatDocument,
    tableFormatodOC: tableFormatDocument[],
    prueba: tableFormatDocument,
    PDFObject: PdfFile,
    fechas: fechasEdit,
    modalUpdate: boolean,
    idFormatoDelete: string,
    FolioSeq: FolioSeq,
    errortablaformat: boolean,
    registroFormato: RegistroFormato,
    isValid1: boolean,
    searchFiado: string

}

interface FiadoOption {
    razonSocial: string;
    rfc: string;
}


