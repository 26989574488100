import axios from 'axios';
import environment from '../../environments/environment'
import {LocalStorageClass} from "../../class/LocalStorageClass";
import {PaginacionModel} from "../../models/public/PaginacionModel";
import {TypeProfileModel} from "../../components/garantias/select-type-user/SelectTypeUser";
import {TypeProfileEnum} from "../../models/garantias/TypeProfileEnum";
import {FilterSearchModel} from "../../views/garantias/warranties/Warranties";
import {FiadoModel} from "../../models/garantias/FiadoModel";
import {InfoObligadoSolidario} from "../../models/garantias/InfoObligadoSolidario";
import {FiltersSearchWarantyModel} from "../../models/garantias/EstatusGarantiaEnum";
import {ListGarantiaModel} from "../../models/garantias/ListGarantiaModel";
import {ExpedienteIdetificacionModel} from "../../models/garantias/ExpedienteIdetificacionModel";
import {ExpedienteModel} from "../../models/garantias/ExpedienteModel";
import {ArchivoGarantia} from "../../models/garantias/ArchivoGarantia";

// obtener la dirección de la api y quitar las comillas del JSON
const  APIGRAL =  (environment.env == 'test')?'https://api.us.libertymutual.com/apigeneral': environment.APIGRAL
const  APIGARANTIAS = (environment.env == 'test')?'https://api.us.libertymutual.com/garantias': environment.APIGARANTIAS
const localStorageClass = new LocalStorageClass();

const headerJsonGral = {
    Accept: 'application/json',
    'X-API-KEY': (environment.env == 'test')? 'GXUmrQJ58IlOLv1TO6X7qHxNGpoNu9e5D5YT1ImRCgTMChrJ':environment.APIKEYGRAL,
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode(),
    env:  (environment.env == 'test')?'test': environment.env
}

const headerJsonGar = {
    Accept: 'application/json',
    'X-API-KEY':  (environment.env == 'test')?'ZARSi12qoxtuyUbKldqeE9UqKJspVdiiA3scTpZat64kUCtb': environment.APIKEYGARANTIAS,
    idLenguaje: localStorageClass.getLanguageId(),
    lang: localStorageClass.getLanguageCode(),
    env:  (environment.env == 'test')?'test': environment.env
}


export const getClientesBusqueda= async (paginacion: PaginacionModel, busqueda?: string) => {
    let queryParams= '';

    if (busqueda){
        queryParams += '&busqueda='+encodeURIComponent(busqueda)
    }

    if (paginacion.currentPage){
        queryParams += '&page='+ paginacion.currentPage
    }
    if (paginacion.size){
        queryParams += '&size='+ paginacion.size
    }

    return await axios.get<{
        paginacion: PaginacionModel, detalle: FiadoModel[]}>(APIGRAL+'/support/client?'+
        queryParams  , { headers: headerJsonGral });
};


export const getGarantias = async (
    idCliente: number,
    paginacion: PaginacionModel,
    busqueda?: string | null) => {
    let paginacionQueryParams= '';

    if (paginacion.currentPage){
        paginacionQueryParams += '&page='+ paginacion.currentPage
    }
    if (paginacion.size){
        paginacionQueryParams += '&size='+ paginacion.size
    }
    let queryParams= '';
    queryParams+= '&idCliente=' + idCliente;
    if (busqueda){
        queryParams += '&busqueda='+ encodeURIComponent(busqueda)
    }

    return await axios.get<{paginacion: PaginacionModel, elementos: ListGarantiaModel[]}>(
        `${APIGARANTIAS}/soporte/garantia/cliente/${idCliente}?` + paginacionQueryParams + queryParams ,
        { headers: headerJsonGar });
}

export const getExpedienteIdentificacion = async (idCliente: number) => {
    return await axios.get<ExpedienteIdetificacionModel[]>(
        `${APIGARANTIAS}/soporte/documentoCliente/archivo/${idCliente}`,
        {headers: headerJsonGar});
};



export const postExpedienteIdentificacion = async (formdata: FormData) => {
    return await axios.post<ExpedienteIdetificacionModel>(`${APIGARANTIAS}/soporte/documentoCliente/archivo` , formdata, { headers: headerJsonGar });
};

export const putExpedienteIdentificacion = async (expediente: ExpedienteIdetificacionModel) => {
    return await axios.put<ExpedienteIdetificacionModel>(`${APIGARANTIAS}/soporte/documentoCliente/archivo/info ` , expediente, { headers: headerJsonGar });
};

export const putExpedienteArchivo = async (formdata: FormData) => {
    return await axios.put<ExpedienteIdetificacionModel>(`${APIGARANTIAS}/soporte/documentoCliente/archivo` , formdata, { headers: headerJsonGar });
};

// /soporte/expediente/{idGarantia}
export const getExpediente = async (idGarantia: number) => {
    return await axios.get<ExpedienteModel[]>(`${APIGARANTIAS}/soporte/expediente/${idGarantia}`, { headers: headerJsonGar });
};
export const deleteFileExpediente = async (idDocumento: number) => {
    return await axios.delete<any>(`${APIGARANTIAS}/soporte/documentoCliente/archivo/${idDocumento}` , { headers: headerJsonGar });
};

export const postArchivoGarantia = async (formdata: FormData) => {
    return await axios.put<ArchivoGarantia>(`${APIGARANTIAS}//soporte/expediente/archivoGarantia` , formdata, { headers: headerJsonGar });
};