import { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import './SelectStyles.scss';
import { Producto } from '../../../models/compromisos/Producto';
import { connect } from 'react-redux';
import { RootStore } from '../../../Store';
import * as engagementService from '../../../services/compromisos/EngagementsService';
import { UserModel } from '../../../models/public/UserModel';
import { LocalStorageClass } from '../../../class/LocalStorageClass';




class AutoCompleteProduct extends Component<IListProps, ExpedientState> {

    public localStorageClass = new LocalStorageClass();



    constructor(props: any) {
        super(props);

        const PERFIL = this.localStorageClass.getProfile();
        //si no hay un token guardado
        if (!PERFIL.accessToken) {
            // redireccionar
            //this.props.history.push('/');
        }


        this.state = {
            openModalClose: false,
            productos: [],
            listProductOption: [],
            val: '',
            PERFIL: PERFIL,

        }
        this.handleInputChangeSelect = this.handleInputChangeSelect.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleOnMenu = this.handleOnMenu.bind(this)
    }

    componentDidMount() {
        //this.getProducto(this.state.val);
    }


    render(): JSX.Element {
        return (
            <>
                <div className="autocomplete-wrapper" >

                    <Autocomplete

                        inputProps={{
                            placeholder: "Ingresa el número o nombre del Producto",
                            style: { fontSize: 16, width: "100%", padding: 15 }
                        }}

                        value={this.state.val}
                        items={this.state.listProductOption}
                        getItemValue={item => item.idProducto + item.nombre}
                        shouldItemRender={this.renderProductName}
                        renderMenu={item => (
                            <div className="dropdown">
                                {item}
                            </div>
                        )}
                        renderItem={(item, isHighlighted) =>

                            <div className={`item ${isHighlighted ? 'selected-item' : 'title'}`} >

                                {`${item.idProducto}-- ${item.nombre} `}
                            </div>
                        }

                        onMenuVisibilityChange={this.handleOnMenu}
                        //onChange={(event, val) => this.setState({ val }) }
                        onChange={this.handleInputChange}
                        //onSelect={val => this.setState({ val }) }  
                        onSelect={this.handleInputChangeSelect}
                    />

                </div>
            </>
        );
    }


    async getProducto(busqueda: string): Promise<void> {

        const res = await engagementService.getProducto(busqueda);
        this.setState({ productos: res.data });

        this.clientData();
    }

    renderProductName(state: { nombre: string; idProducto: number; }, val: string) {

        const nombreCompleto = state.idProducto + '--' + state.nombre
        return (

            //state.nombre.toLowerCase().indexOf(val.toLowerCase()) !== -1
            (nombreCompleto.toLowerCase().indexOf(val.toLowerCase()) !== -1)

        );
    }

    clientData() {
        let listaClientesAuxiliar: ProductOption[];
        listaClientesAuxiliar = []

        for (const condition of this.state.productos) {
            listaClientesAuxiliar.push({ nombre: condition.descripcion, idProducto: condition.idProducto })
        }

        this.setState({ listProductOption: listaClientesAuxiliar })

        return [this.state.listProductOption]
    }

    handleInputChangeSelect(value: string, item: ProductOption): void {
        //const name = e.target.name;
        const id = item.idProducto;
        const idP = id.toString();
        const producto = idP + " " + item.nombre;

        this.setState({ val: item.idProducto + " " + item.nombre })
        //console.log("Selecciona producto: " + item.idProducto)

        this.getProducto(this.state.val);
        const conAlertaProduct = false

        this.props.selectedProducto(producto, conAlertaProduct);
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>, value: string): void {

        this.setState({ val: value })
        this.getProducto(this.state.val);

    }

    handleOnMenu(isOpen: boolean): void {

        if (isOpen == true) {
            console.log("Entro al menu")
            //this.getProducto('a');  
        }
    }
}

const mapStateToProps = (state: RootStore) => ({
    language: state.language
});


export default connect(mapStateToProps)(AutoCompleteProduct);


interface IListProps {

    selectedProducto: (idProducto: string, conAlertaProduct: boolean) => void;
    //language: DefaultStateI;

}

interface IListState {
    //cliente: Cliente[],
}

interface ExpedientState {

    productos: Producto[],
    listProductOption: ProductOption[],
    openModalClose?: boolean,
    val: string,
    PERFIL: UserModel
}

interface ProductOption {
    nombre: string;
    idProducto: number;
}