module.exports = {
    "env": "prod",
    "APISESSION": "https://api.us.libertymutual.com/session",
    "APIKEYSESSION": "oM4Fr5NibElI0zuNgmAnNTDtO4frLZRCkGd8AQplGMIsLASw",
    "APIGRAL": "https://api.us.libertymutual.com/apigeneral",
    "APIKEYGRAL": "GXUmrQJ58IlOLv1TO6X7qHxNGpoNu9e5D5YT1ImRCgTMChrJ",
    "APIGARANTIAS": "https://api.us.libertymutual.com/garantias",
    "APIKEYGARANTIAS": "ZARSi12qoxtuyUbKldqeE9UqKJspVdiiA3scTpZat64kUCtb",
    "APIMENSAJES": "https://api.us.libertymutual.com/messages",
    "APIKEYMENSAJES": "MY3ALCG8Q63A7belh4Sc7OByk75MYAGjHqnEeUyQgp7Iigfe",
    "APICOMPROMISO": "https://api.us.libertymutual.com/compromisos",
    "APIKEYCOMPROMISOS": "U5FeFcokAhpCBG69dAi76d9t9u4yWBNZAFNQ5TlZuB0YAER9",
    "APICREDIT": "https://api.us.libertymutual.com/lf-sgf-buro-proxy",
    "APIKEYCREDIT": "G5WVePpCJ8K0UPHAdszhrwpURPULIobE7oXWTFKn32MwwGu0",
    "APIS3": "https://api.us.libertymutual.com/s3",
    "APIKEYS3": "th5DjYPpc44bIDYhXryoaUGWL7OL4biJmN6EeApRvnkpGc8P",
    "APIPOLIZASRH": "",
    "APIRECIBOCAJA": "https://api.us.lmig.com/recibo",
    "APIKEYRECIBOCAJA": "tK8o40MEnjXVSSNEtvquW0czr173FhwgZxAMcPadbdAvlniV",
    "APIFIRMADIGITAL": "https://api.us.libertymutual.com/firma-digital",
    "APIKEYFIRMADIGITAL": "HULE6roNxA8CjnMR0YmS6CGdEbitKlRzHRIQ1MCOznU2Hglj",
    "APIBURO": "https://serf.aws.libertyfianzas.com/ServiciosBuro",
    "APIJURIDICO": "",
    "URLLOGIN": "https://serf.libertyfianzas.com/",
    "URLVIDEO": "https://serf.libertyfianzas.com/publics_files/",
    "URLSERF": "https://serf.libertyfianzas.com/",
    "URLVALFIANZA": "https://serf.libertyfianzas.com/GSRFK03P/pf_indice.html1",
    "URLSOPORTE": "https://serf.libertyfianzas.com/SoporteApps/",
    "URLRECUPERACFDI": "",
    "URLPAGOLINEA": "",
    "URLOPERPREOCUPANTE": "",
    "APIDEPOSITOGAR": "https://api-tst.us.lmig.com/recibo",
    "APIDEPOSITOGAR2": "https://test-apis.us-east-1.libertymutual.com/firma-digital"
}