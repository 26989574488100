import React, { useEffect } from 'react';
import './PipeLine.scss';
import { IconCheckmark, IconID } from "@lmig/lmds-react";
import {  GrValidate} from "react-icons/gr"; 
import { AiOutlineFileDone } from "react-icons/ai";
interface PipeLineStepsProps {
    listSteps: string[],
    currentStep: number

}

export const PipeLineIcon: React.FC<PipeLineStepsProps> = ({ listSteps, currentStep }: PipeLineStepsProps) => {

    useEffect(() => {
        //  console.log(currentStep);
        //console.log(listSteps)

        if (listSteps.length === 2) {
            document.getElementById('hr')!.style.width = "50%";
        }

    }, []);

    return (
        <>
            {listSteps.length > 0 && listSteps.length < 5 ? (
                <div className="col-md-12" id="pipeline">

                    <hr id={"hr"} />
                    <div className="row pipeline">

                        {
                            listSteps.map((i, index) =>
                            //`banner ${active ? "active" : ""}`
                            (
                                <div
                                    key={index}
                                    className={
                                        `${listSteps.length === 2 ? "col-md-6" : ""}` +
                                        `${listSteps.length === 3 ? "col-md-4" : ""}` +
                                        `${listSteps.length === 4 ? "col-md-3" : ""}`
                                        + ' text-center'}>

                                    {currentStep > index ? (
                                        <span className="badge rounded-pill bg-success text-white">
                                            <IconCheckmark color={"inverse"} size="16"></IconCheckmark>
                                        </span>

                                    ) :
                                        <span className="badge rounded-pill bg-warning text-dark">
                                            {index === 0 ? (

                                                < IconID size="24" />
                                            ) : ""}
                                            {index === 1 ? (

                                                < GrValidate  color={"inverse"} size="24" />)

                                             : ""}
                                            {index === 2 ? (
                                                < AiOutlineFileDone color={"inverse"} size="24" />) : ""}



                                        </span>
                                    }
                                    <br />
                                    <span className="item-name">
                                        {i}
                                    </span>

                                </div>
                            ))
                        }




                    </div>

                </div>
            ) :
                <>
                    {/*No se puede hacer un pipe line mayor a 4 opciones*/}
                    Error Pipeline
                </>
            }

        </>

    );
};

