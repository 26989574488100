export interface CreatequeryLanguageModel {
    credittitle:string,
    pipeline1:string,
    pipeline2:string,
    pipeline3:string,
    pipeline4:string,
    createtype:string,
    NextPage:string,
    PrevPage:string,
    Home:string,
    Create:string,
    consult:string,
    codig:string,
    obli:string
    fechaauto:string,
    guardar:string,
    alertaDatos:string,
    nombrearchivo:string,
    fechaCarga:string,
    fechaAutorizacion:string,
    user:string,
    status:String,
    acciones:string,
    calif:string
    

    //vistas
    entity:string,
    person:string,
    data:string,
    persondata:string,
    name:string,
    second:string,
    last:string,
    mother:string,
    date:string,
    sex:string,
    state:string,
    residence:string,
    town:string,
    street:string,
    number:string,
    format:string,
    downl:string,
    auto:string,
    arrastra:string,
    file:string,
    detalle:string,
    detallef:string,
    credit:string,
    grantor:string,
    saldo:string,
    amount:string,
    method:string,
    max:string,
    update:string,
    finalize:string,
    select:string
    colonia:string,
    rfc:string,
    numint:string,
    telefono:string,
    representante:string,
    razonsocial:string,
    Carga_Consult:string,
    alert_up:string,
    dataRequired:string,
    fechaInco:string,
    visualFile:string,
    cprequerido:string,
    editfecha:string
    

    //tabla fisica
    contrato:string,
    tipooto:string,
    tipocre:string,
    vgente:string,
    dia1:string,
    dia30:string,
    dia60:string,
    dia90:string,
    dia120:string,
    dia180:string,
    histo:string,
    actua:string,
    

  

}

export const CreatequeryLanguageEnglish: CreatequeryLanguageModel ={
    credittitle: 'Create Query',
    pipeline1:'Data',
    pipeline2:'Autorization',
    pipeline3:'Opinion',
    pipeline4:'Result',
    createtype:'Type',
    NextPage:'Continue',
    PrevPage:'Back',
    Home:'Home',
    Create:'Create Query',
    consult:'Consult',
    codig:'Zip code must contain 5 characters',
    obli:'Obligatory field',
    rfc:'The RFC must contain 12 to 13 characters',
    representante:'Agent',
    razonsocial:'Business Name',
    guardar:'Save',
    visualFile:'View file',
    alertaDatos:'No files found',
    nombrearchivo:'File name',
    fechaCarga:'upload date',
    fechaAutorizacion:'authorization date',
    user:'User',
    status:'Status',
    acciones:'Actions',
    calif:'Score',
    //vistas
    entity:'Entity Type',
    person:'Person Type',
    data:'General Data',
    persondata:'Personal Data',
    name:'Name',
    second:'Second Name',
    last:'Last Name',
    mother: 'Mothers Last Name',
    date:'Date of Birth',
    sex:'Sex',
    state:'State',
    residence:'Residence',
    town:'Town',
    street:'Street',
    number:'No.Ext',
    format:'Autorization Format',
    downl:'Download your format',
    auto:'Autorization',
    arrastra:'Drag and drop  file here or ',
    file:'Browse files',
    detalle:'Detail of the credits',
    credit:'Credit Type',
    grantor:'Granthor Type',
    saldo:'Current Balance',
    amount:'Amout',
    method:'Method of Pay',
    max:'Max MOROS/MOP amount date',
    update:'Update',
    finalize:'Finalize',
    select:'Select an option',
    contrato:'Contrato',
    tipooto:'Grantor Type',
    tipocre:'Credit Type',
    vgente:'Valid',
    dia1:'1-29 days',
    dia30:'30-59 days',
    dia60:'60-89 days',
    dia90:'90-119 days',
    dia120:'120-179 days',
    dia180:'180 days',
    histo:'Payment History',
    actua:'Updated in',
    detallef:'Active Credits',
    colonia:'Colonia',
    telefono:'Phone',
    numint:'No.Int',
    Carga_Consult:'Loading Information',
    alert_up:'File Uploaded Successfully',
    dataRequired:'Required data',
    fechaauto:'Authorization Date',
    fechaInco:'Incorrect date, please check',
    cprequerido:'The CP is required',
    editfecha:'Date edition'


}


export const CreatequeryLanguageSpanish: CreatequeryLanguageModel ={
    credittitle:'Crear Consulta',
    pipeline1:'Datos',
    pipeline2:'Autorización',
    pipeline3:'Dictamen',
    pipeline4:'Resultado',
    createtype:'Tipo Entidad',
    NextPage:'Continuar',
    PrevPage:'Regresar',
    Home:'Home',
    Create:'Crear Consulta',
    consult:'Consulta',
    codig:'El Codigo postal debe contener 5 caracteres',
    obli:'Campo Obligatorio',
    rfc:'El RFC debe contener de 12 a 13 caracteres',
    representante:'Representante',
    razonsocial:'Razón Social',
    guardar:'Guardar',
    visualFile:'Visualizar archivo',
    alertaDatos:'No se encontraron archivos',
    nombrearchivo:'Nombre archivo',
    fechaCarga:'Fecha carga',
    fechaAutorizacion:'Fecha autorización',
    user:'Usuario',
    status:'Estatus',
    acciones:'Acciones',
    calif:'Calificación',
    //vistas
    entity:'Tipo Entidad',
    person:'Tipo Personas',
    data:'Datos Generales',
    persondata:'Datos Personales',
    name:'Nombre',
    second:'Segundo Nombre',
    last:'Apellido Paterno',
    mother:'Apellido Materno',
    date:'Fecha de nacimiento',
    sex:'Sexo',
    state:'Estado',
    residence:'Domicilio',
    town:'Municipio',
    street:'Calle',
    number:'No.Ext',
    format:'Formato de Autorización',
    downl:'Descarga tu Formato',
    auto:'Autorización',
    arrastra:'Arrastra y suelta el archivo aquí o',
    file:'Subir Archivo',
    detalle:'Detalle de los Créditos',
    credit:'Tipo de Credito',
    grantor:'Tipo de Otorgante',
    saldo:'Saldo Actual',
    amount:'Monto',
    method:'Formato de pago en fecha de actualización',
    max:'Max MOROS/MOP fecha importe',
    update:'Actualizado al',
    finalize:'Finalizar',
    select:'Selecciona una opción',
    contrato:'Contrato',
    tipooto:'Tipo Otorgante',
    tipocre:'Tipo crédito',
    vgente:'Vigente',
    dia1:'1-29 días',
    dia30:'30-59 días',
    dia60:'60-89 días',
    dia90:'90-119 días',
    dia120:'120-179 días',
    dia180:'180 días',
    histo:'Historico de pagos',
    actua:'Actualizado en',
    detallef:'Creditos Activos',
    colonia:'Colonia',
    telefono:'Teléfono',
    numint:'No.Int',
    Carga_Consult:'Cargando Información',
    alert_up:'Archivo Cargado Correctamente',
    dataRequired:'Datos requeridos',
    fechaauto:'Fecha de Autorización',
    fechaInco:'Fecha incorrecta, verifica',
    cprequerido:'El CP es requerido',
    editfecha:'Edición de fecha'

}