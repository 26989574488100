import {ExpedienteIdentificationReducerModel} from "../reducer/ExpedienteIdentificationReducer";

export const CREATE_EXPEDIENTE = "CREATE_EXPEDIENTE";
export const MOVE_EXPEDIENTE = "MOVE_EXPEDIENTE";
export const DELETE_EXPEDIENTE = "DELETE_EXPEDIENTE";

export interface CreateExpediente {
    type: typeof CREATE_EXPEDIENTE;
    payload: ExpedienteIdentificationReducerModel;
}

export interface MoveExpediente {
    type: typeof MOVE_EXPEDIENTE;
    payload: ExpedienteIdentificationReducerModel;
}

export interface DeleteExpediente {
    type: typeof DELETE_EXPEDIENTE;
    payload: ExpedienteIdentificationReducerModel;
}

export type ExpedientIdentificationDispatchTypes =
    | CreateExpediente
    | MoveExpediente
    | DeleteExpediente;
