import React, {ChangeEvent, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {RootStore} from "../../../Store";
import * as garantiasService from "../../../services/garantias/GarantiasService";
import {AxiosError} from "axios";
import {Accordion, Card, Dropdown, ListGroup} from "react-bootstrap";
import {
    Button, IconAddItem, IconChecklist, IconCloseCircle,
    IconDocumentQuote, IconEdit, IconSeeMore, IconSuccessAlert, Link as LinkLiberty,
    Modal
} from "@lmig/lmds-react";
import './AuthorizationDictumWarranty.scss';
import {SpinnerLoading} from "../../public/spinner-loading/SpinnerLoading";
import {NegativeAlert} from "../../public/negative-alert/NegativeAlert";
import {ButtonStatusWarranty} from "../button-status-warranty/ButtonStatusWarranty";
import {DictamenGarantiaModel} from "../../../models/garantias/DictamenGarantiaModel";
import {ButtonSpinner} from "../../public/button-spinner/ButtonSpinner";

import * as engagementService from '../../../services/compromisos/EngagementsService';
import { statesQuoteDoc} from "../authorization-doc-warranty/AuthorizationDocWarranty";
import {estadoVigenciaGarantia} from "../../../models/garantias/EstadoVigenciaGarantia";
import {Link, useHistory} from "react-router-dom";
import {GarantiaCompromisoModel} from "../../../models/compromisos/GarantiaCompromisoModel";
import {openNewWindow, openNewWindowCenter, validRoles} from "../../../class/CommonClass";
import {UserModel} from "../../../models/public/UserModel";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {FormDetailEngagement} from "../../compromisos/form-detail-engagement/FormDetailEngagement";
import {CalificacionMontoModel} from "../../../models/garantias/CalificacionMontoModel";
import {CalificacionPorcentajeModel} from "../../../models/garantias/CalificacionPorcentajeModel";
import {GarantiaModel} from "../../../models/garantias/GarantiaModel";
import {PositiveAlert} from "../../public/positive-alert/PositiveAlert";
import {LoteRevisionModel} from "../../../models/garantias/LoteRevisionModel";
import {MotivoRechazoModel} from "../../../models/garantias/MotivoRechazoModel";
import {ConditionOptionModel} from "../../../models/public/ConditionOptionModel";
import  {MultiValue} from "react-select";
import {ErrorSustitucion} from "../../../models/garantias/ErrorSustitucion";
import {initialGarantiaState} from "../create-warranty/CreateWarranty";

interface AuthorizationDictumWarrantyProps {
    idWarranty: number;
    idFiado: number;
    visibleButtons: boolean;
    quoteAmount?: CalificacionMontoModel;
    quotePercent?: CalificacionPorcentajeModel;
}

export const AuthorizationDictumWarranty: React.FC<AuthorizationDictumWarrantyProps> = ({idWarranty, idFiado, visibleButtons, quoteAmount, quotePercent}:AuthorizationDictumWarrantyProps)=> {
    // redux
    const localStorageClass = new LocalStorageClass();

    const languageState = useSelector((state: RootStore) => state.language.language?.authorizationDictumWarranty);

    const history = useHistory();

    const initDictamen : DictamenGarantiaModel = {
        idGarantia: 0,
        estado:0,
        descripcionEstado: '',
        conConvenio: false,
        urlConvenio: '',
        descripcion: '',
        rubros: [],
        rechazo: [],
        estadoSustitucion: 0,
        descSustitucion: '',
    }

    const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

    const [openModalRejection, setOpenModalRejection] = useState<boolean>(false);

    const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);

    const [openModalDetailEngagments, setOpenModalDetailEngagment] = useState<boolean>(false);

    const [dictumWarranty, setDictumWarranty] = useState<DictamenGarantiaModel>(initDictamen);

    const [loadingDictumWarranty, setLoadingDictumWarranty] = useState<boolean>(true);

    const [errorDictumWarranty, setErrorDictumWarranty] = useState<boolean>(true);

    const [sendingAuthorization, setSendingAuthorization] = useState<boolean>(false);

    const [errorsendingAuthorization, setErrorSendingAuthorization] = useState<boolean>(false);

    const [listEngagments, setListEngagments] = useState<GarantiaCompromisoModel []>([]);

    const [loadingListEngagments, setLoadingListEngagments] = useState<boolean>(false);

    const [errorListEngagments, setErrorListEngagments] = useState<boolean>(false);

    const [selectedEngagement, setSelectedEngagement] = useState<number>(0);

    const [garantiaDetail, setGarantiaDetail] = useState<GarantiaModel>(initialGarantiaState);

    const [idLote, setIdLote] = useState<number>(0);

    const [newObservation, setNewObservation] = useState<string>('');

    const [listReasons, setListReasons] = useState<MotivoRechazoModel []>([]);

    const [loadingReasons, setLoadingReasons] = useState<boolean>(true);

    const [errorReaseons, setErrorReaseons] = useState<boolean>(true);

    const [listConditionalOption, setListConditionalOption] = useState<ConditionOptionModel[]>([]);

    const [selectedReasons, setSelectedReasons] = useState<MotivoRechazoModel []>([]);

    const initErrorSus = {
        showModal: false,
        msgSustitucion: ''
    };
    const [errorSusutitucion, setErrorSusutitucion] = useState<ErrorSustitucion>(initErrorSus);

    const [errorAutorizacion, setErrorAutorización] = useState<ErrorSustitucion>(initErrorSus);


    useEffect(() => {
        getDictumWarranty(idWarranty);
        getWarrantyDetail(idWarranty);
        //getEngagmentsWarranty(idFiado);
        reviewWarrantiesPrincipal(idWarranty);

    },[]);

    const getDictumWarranty =  async (idWarranty: number) => {
        setLoadingDictumWarranty(true);
        setErrorDictumWarranty(false);

        try {
            const res = await garantiasService.getDictamenGar(idWarranty);
            setLoadingDictumWarranty(false);
            setDictumWarranty(res.data)
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingDictumWarranty(false);
                setErrorDictumWarranty(true);

            }
        }

    }

    const getEngagmentsWarranty =  async (idFiado: number) => {
        setLoadingListEngagments(true);
        setErrorListEngagments(false);

        try {
            const res = await engagementService.getCompromisosGaran(idFiado);
            setLoadingListEngagments(false);
            const arrayEngagments: GarantiaCompromisoModel [] = res.data
            setListEngagments(arrayEngagments)

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                setLoadingListEngagments(false);
                setErrorListEngagments(true);

            }
        }

    }

    const getWarrantyDetail = async (idWarranty: number) => {
        try {
            const res = await garantiasService.getGarantiaDetail(idWarranty);
           setGarantiaDetail(res.data)

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    const putDictumWarranty =  async (estado: number, idGarantia: number) => {
        if (estado == estadoVigenciaGarantia.autorizada){
            setSendingAuthorization(true);
            setErrorSendingAuthorization(false);
        }
        if (estado == estadoVigenciaGarantia.rechazada){
            setSendingAuthorization(true);
            setErrorSendingAuthorization(false);
        }
        setErrorDictumWarranty(false);

        // obtener la lista de rechazo
        let listRechazo: MotivoRechazoModel[] = []
       for (const motivo of listConditionalOption){
            listRechazo.push({idMotivo: motivo.value, descripcion: motivo.label})
       }

        const auxdictum: DictamenGarantiaModel = {
            ...dictumWarranty,
            descripcion: newObservation,
            rechazo: listRechazo};

        try {
            const res = await garantiasService.putDictamenGar(idGarantia,estado, auxdictum);

            if (res.status == 202){
                setErrorSusutitucion({showModal: true, msgSustitucion: res.data.descSustitucion});
                setSendingAuthorization(false);
                setOpenModalConfirm(false);
                setOpenModalRejection(false);
            }
            if (res.status == 401){
                setErrorAutorización({showModal: true, msgSustitucion: languageState?.errAutorizacion!})
                setSendingAuthorization(false);
                setOpenModalConfirm(false);
                setOpenModalRejection(false);
            }
            else {

                if (estado == estadoVigenciaGarantia.autorizada){
                    setSendingAuthorization(false);
                    setOpenModalConfirm(false);

                }
                if (estado == estadoVigenciaGarantia.rechazada){
                    setSendingAuthorization(false);
                    setOpenModalRejection(false);
                }
                setNewObservation('');
                setDictumWarranty(res.data);
                // validar si ya no hay pendientes

                if (await reviewWarrantiesPrincipal(res.data.idGarantia) == false){
                    history.push('/garantias/revision/')
                }else {
                    history.push('/garantias/revision/fiado/'+ idFiado+'/lote/' + idLote);
                }
            }



        }
        catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                if (estado == estadoVigenciaGarantia.autorizada){
                    setSendingAuthorization(false);
                    setErrorSendingAuthorization(true);
                }
                if (estado == estadoVigenciaGarantia.rechazada){
                    setSendingAuthorization(false);
                    setErrorSendingAuthorization(true);
                }


            }
        }
    }

    //SI NO HA TERMINADO DE REVISAR TODAS LAS GARANTIAS DE UN FIADO MANDE A LA PANTALLA DE REVISION DE GARANTIAS AL LISTADO
    const reviewWarrantiesPrincipal =  async (idGarantia: number ): Promise<boolean>  => {

        try {
            const res = await garantiasService.getGarantiaLotenRevision(idGarantia);
            const loteRevision : LoteRevisionModel = res.data;
            setIdLote(loteRevision.idLote);
            if (!loteRevision.loteRevision){ // si el lote ya no esta en revision redireccionar a
              return  false; // ya no esta en revision
            }
            return  true; // si esta en revisión



        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
                return  true; // si esta en revisión
            }
            return  true; // si esta en revisión
        }
    }

    const openEngagment =  async (idEngagement: number) => {
        setSelectedEngagement(idEngagement);
        setOpenModalDetailEngagment(true);

    }

    const handleTextAreaObsChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setNewObservation(value)
     //   setDictumWarranty({...dictumWarranty, descripcion:value });

    }
    const openModalReject = async () =>{
        setOpenModalRejection(true);
        setLoadingReasons(true);
        setErrorReaseons(false);
      /*  try {
              const res = await garantiasService.getMotivosRechazoGarantia(idWarranty);
            setListReasons(res.data);
            formatList(res.data);
            setLoadingReasons(false);

          } catch (error) {
              const err = error as AxiosError
              if (err.response) {
                  console.log(err.response.status)
                  console.log(err.response.data)
                  setErrorReaseons(true);
                  setLoadingReasons(false);

              }
        }*/

    }

    const formatList = (rejectList: MotivoRechazoModel[]) => {
        let  listaCondicionalAuxiliar: ConditionOptionModel[] = [];

        for(const i of rejectList){

            listaCondicionalAuxiliar.push({
                name: '',
                value: i.idMotivo,
                label: i.descripcion,
                status: 0})
        }
        setListConditionalOption(listaCondicionalAuxiliar);
    }


    const handleInputChangeSelect = (e: MultiValue<ConditionOptionModel>) => {

        console.log(e)
        const auxselectedReasons: MotivoRechazoModel [] = []
        for (const i of e){
            auxselectedReasons.push({idMotivo: i.value, descripcion: i.label})
        }
        setSelectedReasons(auxselectedReasons);
    }

    return (
        <>
            <div id="authorization-dictum-warranty">
                {loadingDictumWarranty ? (
                        <div className="col-12">
                            <SpinnerLoading text={languageState?.loadingDictum}/>
                        </div>
                    ) :
                    (!errorDictumWarranty)?(
                            <>
                                <Card body className="col-md-12 text-center" >
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" className="dropdown-more-options float-md-right">
                                                    <span className="badge badge-pill badge-dark">{languageState?.engagments}</span>
                                                    <IconSeeMore size="16"></IconSeeMore>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {(validRoles(PERFIL.roles, localStorageClass.EJECUTIVOCUENTA) ||
                                                    validRoles(PERFIL.roles, localStorageClass.AGENTE) ||
                                                    validRoles(PERFIL.roles, localStorageClass.PROMOTOR)
                                                        ? (
                                                        <>
                                                            <Dropdown.Item
                                                                onClick={ () => {
                                                                    openNewWindowCenter(
                                                                        '/compromisos/crear-compromiso/'+ idFiado,
                                                                        1000,
                                                                        700);
                                                                }}
                                                            >
                                                                <span className="text-dark font-weight-bold">{languageState?.createCompromises}</span>

                                                            </Dropdown.Item>
                                                           {/* <Dropdown.Divider />*/}
                                                        </>
                                                    ): null)
                                                    }

                                                    {(validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS)
                                                        ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={ () => {
                                                                        openNewWindowCenter(
                                                                            '/cumplimientos/fiado/'+ idFiado,
                                                                            1000,
                                                                            700);
                                                                    }}
                                                                >
                                                                    <span className="text-dark font-weight-bold">{languageState?.fulfillments}</span>
                                                                </Dropdown.Item>
                                                            </>
                                                        ): null)
                                                    }

                                                    {/*<Dropdown.Item href="#">
                                                        <span className="text-dark font-weight-bold">{languageState?.currentCompromises} </span>
                                                    </Dropdown.Item>
                                                    {
                                                        listEngagments.map((j, index) =>
                                                            (
                                                                <Dropdown.Item
                                                                    key={index}
                                                                    onClick={() => {
                                                                        openEngagment(j.idCompromiso)
                                                                    }}
                                                                >

                                                                <span className="text-dark">{languageState?.folio}: {j.idCompromiso}<br/>
                                                                    {languageState?.type}: {j.tipoCompromiso}
                                                                </span>
                                                                    <br/>
                                                                    {languageState?.conditions}:
                                                                    <ul>
                                                                        {
                                                                            j.condiciones.map((k) =>
                                                                                (
                                                                                    <li>{k.descripcion} </li>
                                                                                ))
                                                                        }
                                                                    </ul>


                                                                </Dropdown.Item>
                                                            ))
                                                    }

                                                    {
                                                        (loadingListEngagments) ? (
                                                                <div className="col-12"><SpinnerLoading/></div>) :
                                                            (errorListEngagments) ? (
                                                                <Dropdown.Item href="#">

                                                                    <IconCloseCircle title={languageState?.errorLoadCompromises} color="negative" size="16"/>
                                                                    &nbsp;
                                                                    <span className="text-danger">{languageState?.errorLoadCompromises}</span>
                                                                </Dropdown.Item>
                                                            ) :null

                                                    }*/}

                                                </Dropdown.Menu>

                                            </Dropdown>
                                        </div>


                                    </div>

                                    <IconDocumentQuote
                                        color="gray"
                                        size="64"
                                        title={languageState?.dictum}
                                        className="d-block mx-auto"/>
                                    <h5><br/>
                                        {languageState?.label1} {garantiaDetail.descripcionClave}<br/>
                                        {languageState?.label2} {garantiaDetail.folio}{(garantiaDetail.version> 0)? '-' + garantiaDetail.version: null}<br/>
                                        {languageState?.label3}  <br/>
                                    </h5>

                                    <br/>
                                    <ButtonStatusWarranty status={dictumWarranty.estado} description={dictumWarranty.descripcionEstado!} /><br/>


                                        {(dictumWarranty.descripcion) &&
                                            <b>{languageState?.observations} :
                                                <br/>{dictumWarranty.descripcion}
                                            </b>
                                        }
                                    {/*
                                    <b>
                                        <br/> <br/> {languageState?.reasons}: <br/>
                                    </b>
                                    <ul>
                                        {dictumWarranty.rechazo.map((i, index) => (
                                            <li>
                                                {i.descripcion}
                                            </li>
                                        ))
                                        }

                                    </ul>
                                    */}


                                    <Accordion id="rubros-dictum">

                                        {dictumWarranty.rubros.map((i, index) => (
                                            <Card key={index}>

                                                <Accordion.Toggle as={Card.Header} variant="primary"  eventKey={String(index)}>
                                                    <div  className="text-left title-header">
                                                        {i.idEstado == statesQuoteDoc.pendiente?(
                                                            <IconEdit
                                                                color="gray" size="16"
                                                                title= {i.descripcionEstado}/>
                                                        ): null}
                                                        {i.idEstado == statesQuoteDoc.enCaptura?(
                                                            <IconEdit
                                                                color="gray" size="16"
                                                                title= {i.descripcionEstado}/>
                                                        ): null}
                                                        {i.idEstado == statesQuoteDoc.enRevision?(
                                                            <IconChecklist
                                                                color="caution" size="16"
                                                                title= {i.descripcionEstado}/>
                                                        ): null}
                                                        {i.idEstado == statesQuoteDoc.rechazado?(
                                                            <IconCloseCircle
                                                                color="error" size="16"
                                                                title= {i.descripcionEstado}/>
                                                        ): null}
                                                        {i.idEstado == statesQuoteDoc.aprobado?(
                                                            <IconSuccessAlert
                                                                color="success" size="16"
                                                                title= {i.descripcionEstado}/>
                                                        ): null}
                                                        &nbsp;

                                                        {i.descripcion}
                                                    </div>

                                                </Accordion.Toggle>

                                                <Accordion.Collapse eventKey={String(index)}>
                                                    <Card.Body>
                                                        {(i.detalle.length>0)?(
                                                            <ListGroup>
                                                                {i.detalle.map((j, index2) => (
                                                                    <ListGroup.Item key={index2}>
                                                                        <div className="text-left">
                                                                            {j.idEstadoDetalle == statesQuoteDoc.enCaptura?(
                                                                                <IconEdit
                                                                                    color="gray" size="16"
                                                                                    title= {j.descripcionEstado}/>
                                                                            ): null}
                                                                            {j.idEstadoDetalle == statesQuoteDoc.enRevision?(
                                                                                <IconChecklist
                                                                                    color="caution" size="16"
                                                                                    title= {j.descripcionEstado}/>
                                                                            ): null}
                                                                            {j.idEstadoDetalle == statesQuoteDoc.rechazado?(
                                                                                <IconCloseCircle
                                                                                    color="error" size="16"
                                                                                    title= {j.descripcionEstado}/>
                                                                            ): null}
                                                                            {j.idEstadoDetalle == statesQuoteDoc.aprobado?(
                                                                                <IconSuccessAlert
                                                                                    color="success" size="16"
                                                                                    title= {j.descripcionEstado}/>
                                                                            ): null}
                                                                            &nbsp;
                                                                            {j.descripcion}
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                ))}
                                                            </ListGroup>
                                                        ): <>
                                                            <PositiveAlert textAlert={languageState?.authorized!}/>
                                                        </>
                                                        }

                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                        ))}


                                    </Accordion>
                                    <br/>




                                    {(visibleButtons && validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS))? (
                                        <div className="row btns-authorize">
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-success btn-authorization"
                                                    onClick={() => {setOpenModalConfirm(true)}}>
                                                    {languageState?.authorize}
                                                </button>
                                            </div>
                                            <div className="col-md-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => {
                                                        openModalReject()
                                                    }}>
                                                    {languageState?.reject}
                                                </button>
                                            </div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-12">
                                                <hr/>
                                                <Link to={'/garantias/revision/fiado/'+ idFiado + '/lote/' + idLote} >
                                                    <LinkLiberty  variant="inline">
                                                        {languageState?.return}
                                                    </LinkLiberty>
                                                </Link>
                                            </div>
                                        </div>
                                    ) : null}
                                </Card>

                            </>
                        ):
                        <div className="col-12">
                            <NegativeAlert
                                textAlert={languageState?.errorLoadDictum!}/><br/>
                            <div className="row">
                                <div className="col-md-4"/>

                                <div className="col-md-4">
                                    <Button dynamicWidth
                                            variant="secondary"
                                            onClick={() => {
                                                // recargar dictamen
                                                getDictumWarranty(idWarranty)
                                            }}>
                                        {languageState?.reintent!}
                                    </Button>
                                </div>
                                <div className="col-md-4"/>

                            </div>

                        </div>
                }


                {/*modal Rechazar Garantía*/}
                <Modal
                    isOpen={openModalRejection}
                    size="medium"
                    modalStyle="standard"
                    title={languageState?.reject}
                    closeFunc={() => {setOpenModalRejection(false)}}
                    id="modal-rechazo">

                    <div className="row">
                        <div className="col-md-12">

                            {languageState?.areYouSureReject}<br/>
                                <label className="label-input">
                                    {languageState?.writeObservation}
                                </label>
                                <textarea
                                    onChange={handleTextAreaObsChange}
                                    className="input-sdgf">
                                        {newObservation!}
                                </textarea>
                                <br/>

                        </div>
                        <div className="col-md-6">
                            <br/>
                            <Button dynamicWidth
                                    variant="secondary"
                                    onClick={() => {setOpenModalRejection(false)}}>
                                {languageState?.cancel}
                            </Button>
                        </div>
                        <div className="col-md-6">
                            <br/>
                            {!sendingAuthorization ? (
                                    <Button dynamicWidth
                                            variant="primary"
                                            onClick={() => {
                                                putDictumWarranty(estadoVigenciaGarantia.rechazada, idWarranty)}}>
                                        {languageState?.reject}
                                    </Button>
                                ):
                                <ButtonSpinner label={languageState?.rejecting!}/>
                            }
                        </div>
                    </div>

                </Modal>
                {/*modal confirmar autorización*/}
                <Modal
                    isOpen={openModalConfirm}
                    size="medium"
                    modalStyle="standard"
                    title={languageState?.authorizeLoan}
                    closeFunc={() => {setOpenModalConfirm(false)}}
                    id="modal-comfirm">

                    <div className="row">
                        <div className="col-md-12">
                            {languageState?.areYouSureAuthorize}  <br/>

                            <label className="label-input">
                                {languageState?.writeObservation} </label>
                            <textarea
                                onChange={handleTextAreaObsChange}
                                className="input-sdgf">
                                    {newObservation!}
                            </textarea>
                            <br/>
                            {(quoteAmount?.estadoMonto != statesQuoteDoc.aprobado)?(
                                <>
                                    <br/>
                                    <NegativeAlert textAlert={languageState?.autorizeLoanAmount!} />
                                </>
                            ):null}
                            {(quotePercent?.estadoPorcentaje != statesQuoteDoc.aprobado)?(
                                <>
                                    <br/>
                                    <NegativeAlert textAlert={languageState?.autorizeLoanPercent!} />
                                </>
                            ):null}


                            {(quoteAmount?.estadoMonto == statesQuoteDoc.aprobado &&
                                quotePercent?.estadoPorcentaje == statesQuoteDoc.aprobado )?(
                                    <div className="row">
                                        <div className="col-md-6">
                                            <br/>
                                            <Button dynamicWidth
                                                    variant="secondary"
                                                    onClick={() => {setOpenModalConfirm(false)}}>
                                                {languageState?.cancel}
                                            </Button>
                                        </div>
                                        <div className="col-md-6">
                                            <br/>
                                            {!sendingAuthorization ? (

                                                    <Button
                                                        dynamicWidth
                                                        variant="primary"
                                                        onClick={() => {
                                                            putDictumWarranty(estadoVigenciaGarantia.autorizada, idWarranty)}}>
                                                        {languageState?.authorize}
                                                    </Button>
                                                ):
                                                <ButtonSpinner label={languageState?.authorizing!}/>
                                            }
                                        </div>
                                    </div>
                                ):
                                <div className="col-md-12">
                                    <br/>
                                    <Button dynamicWidth
                                            variant="secondary"
                                            onClick={() => {setOpenModalConfirm(false)}}>
                                        Ok
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                </Modal>


                {/*modal ver detalle de un compromiso*/}
                <Modal
                    isOpen={openModalDetailEngagments}
                    size="large"
                    modalStyle="standard"
                    title={'Compromiso ' + selectedEngagement}
                    closeFunc={() => {setOpenModalDetailEngagment(false)}}
                    id="modal-detail-engagements">

                    {(selectedEngagement!=0)?(
                        <FormDetailEngagement
                            idFiado={idFiado}
                            idCompromiso={selectedEngagement}
                            isModal={true}
                            clickShowModal={
                                (show: boolean) =>{
                                    setOpenModalDetailEngagment(show)
                                }
                            }
                        />
                    ): null}


                </Modal>
            </div>


            <Modal
                isOpen={errorSusutitucion.showModal}
                size="medium"
                modalStyle="standard"
                title={languageState?.errSustitution}
                closeFunc={() => setErrorSusutitucion({showModal: false, msgSustitucion: errorSusutitucion.msgSustitucion})}>
                <h5 className="text-center">
                    {errorSusutitucion.msgSustitucion}<br/>
                </h5>
                <div className="w-100 pr-5 pl-5">
                    <Button
                        className="w-100"
                        onClick={
                            () => setErrorAutorización({
                                showModal: false,
                                msgSustitucion: ''})
                        }
                        variant="primary">
                        {languageState?.close}
                    </Button>
                </div>

            </Modal>


            <Modal
                isOpen={errorAutorizacion.showModal}
                size="medium"
                modalStyle="standard"
                title={languageState?.errAutorizacion}
                closeFunc={() => setErrorAutorización({
                    showModal: false,
                    msgSustitucion: ''})}>
                <h5 className="text-center">
                    {languageState?.msgErrAutorizacion}<br/>
                </h5>
                <div className="w-100 pr-5 pl-5">
                    <Button
                        className="w-100"
                        onClick={
                            () => setErrorAutorización({
                                showModal: false,
                                msgSustitucion: ''})
                        }
                        variant="primary">
                        {languageState?.close}
                    </Button>
                </div>

            </Modal>
        </>
    );
};











