import { Button } from '@lmig/lmds-react/Button/Button';
import Link from '@lmig/lmds-react/Link/Link';
import React, { Component } from 'react'
import { Card, CardDeck, Dropdown, ModalBody, Pagination } from 'react-bootstrap';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { initPaginacion, InputChange, loadPaginationList, validFilepdf } from '../../../class/CommonClass';
import { LocalStorageClass } from '../../../class/LocalStorageClass';
import { BreadCumb } from '../../../components/public/breadcumb/BreadCumb';
import NavBar from '../../../components/public/nav-bar/NavBar';
import { TypeProfileModel } from '../../../components/garantias/select-type-user/SelectTypeUser';
import { SpinnerLoading } from '../../../components/public/spinner-loading/SpinnerLoading';
import { DefaultStateI } from '../../../reducer/LanguageReducer';
import { RootStore } from '../../../Store';
import './Creditbureau.scss'
import { HiPlus } from "react-icons/hi";
import { Alert, AlphanumericInput, Badge, GridCol, Heading, IconButton, IconEdit, IconFolder, IconSearch, IconSeeMore, IconTrash, Modal, Table, TableCell, TableRow } from '@lmig/lmds-react';
import * as getCreditbureau from "../../../services/buro-credito/CreditbureauService"
import { AxiosError } from 'axios';
import { ConsultaBuro } from '../../../models/buro-credito/ConsultaBuro';
import { Datos, fechasEdit, FolioSeq, FormatDocument, RegistroFormato, tableFormatDocument } from '../../../models/buro-credito/Datos';
import { PaginacionModel } from '../../../models/public/PaginacionModel';
import { UserModel } from '../../../models/public/UserModel';
import * as apiS3Services from "../../../services/api-s3/ApiS3Services";
import Swal from 'sweetalert2';
import { PdfFile } from '../../../models/buro-credito/PdfFile';

interface Params {
    folio: string;
}

interface BureauProps {
    language: DefaultStateI
}

class Creditbureau extends Component<BureauProps & RouteComponentProps<Params>, BureauState> {

    public localStorageClass = new LocalStorageClass();
    public folio: number;
    public archivo = "";
    public initDatos: FormatDocument = {
        fechaAutorizacion: '',
        fechaCarga: '',
        nomArchivoCarta: '',
        rfc: '',
        usuario: '',
        folioConsulta: '',
        subirArchivo: '',
        idCartaUsada: ''
    }
    public initPDF: PdfFile = {
        carpeta: '',
        nombreArchivo: ''
    }

    public initRegistroFormat: RegistroFormato = {
        fechaAutorizacion: '',
        fechaCarga: '',
        nombreArchivoCarta: '',
        rfc: '',
        usuario: '',
        folioConsulta: ''
    }

    public initDatosF: tableFormatDocument = {
        estatus: '',
        fechaAutorizacion: '',
        fechaCarga: '',
        idFormato: '',
        idStatus: '',
        nombreArchivoCarta: '',
        rfc: '',
        usuario: '',
        statusCarta: '',
        idCartaUsada: '0'
    }


    constructor(props: any) {
        super(props);
        document.title = "Buró de Crédito"



        this.state = {
            status: 0,
            listBureau: [],
            listPorcent: [],
            valida_tabla: false,
            valida_formatos: false,
            search: '',
            paginacion: initPaginacion,
            loadingConsult: true,
            listPaginacion: [],
            PDFObject: this.initPDF,
            calif: '',
            Consul_buro: false,
            errortablaformat: false,
            pdfOpen: false,
            show: true,
            idFormatoDelete: '',
            PERFIL: this.localStorageClass.getProfile(),
            filterSearch: {
                folio: ''
            },
            validador: true,
            errorTableBuro: false,
            errorFormato: false,
            tabla: 0,
            modalUpdate: false,
            modalCartas: false,
            modalFechas: false,
            validalinea: 0,
            idFormato: 0,
            tableFormatodOC: [],
            FolioSeq: {
                folio: ''
            },
            fechas: {
                fechaAutorizacion: '',
                idFormato: '',
                folio: ''
            },
            formData: new FormData(),
            pdf: '',
            fechaAutoAux: '',
            isValid1: false,
            prueba: this.initDatosF,
            rfc: '',
            datoForm: this.initDatos,
            registroFormato: this.initRegistroFormat,


        }
        //this.folio = parseInt(this.props.match.params.folio);
        this.handlenew = this.handlenew.bind(this);
        this.getCredit = this.getCredit.bind(this);
        this.process = this.process.bind(this);
        this.vigent = this.vigent.bind(this);
        this.vencid = this.vencid.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this)
        this.getCalifBuro = this.getCalifBuro.bind(this);
        this.fechAut = this.fechAut.bind(this);
        this.PdfVis = this.PdfVis.bind(this);
    }



    render() {
        return <>
         <NavBar />
            <div className="container-fluid " id="creditbureau">
               
                <div className="creditbureau-container" >
                    <BreadCumb
                        title={this.props.language.language?.creditbureau.credittitle!}
                    />



                    <CardDeck className="cards-percentage-bc">
                        {this.state.listPorcent.map((i, index) =>
                        (
                            <Card key={i.id}>
                                <Card.Body>
                                    <Card.Title>{i.nombre}</Card.Title>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <CircularProgressbar
                                                value={i.porcentaje}
                                                text={`${i.porcentaje}%`}
                                                styles={buildStyles({
                                                    // Rotation of path and trail, in number of turns (0-1)
                                                    rotation: 0.25,
                                                    // Text size
                                                    textSize: '16px',
                                                    // How long animation takes to go from one percentage to another, in seconds
                                                    pathTransitionDuration: 0.5,
                                                    // Colors
                                                    pathColor: `rgba(6, 116, 140, 0.5)`,
                                                    textColor: '#06748C',

                                                })} />
                                        </div>
                                        <div className="col-md-6 ">
                                            <Card.Text>

                                            </Card.Text>
                                        </div>

                                    </div>

                                </Card.Body>
                                <Card.Footer className="text-right bg-white">
                                    {i.id === '1' &&
                                        <Link onClick={this.process}>
                                            {this.props.language.language?.creditbureau.creditopen!}

                                        </Link>
                                    }

                                    {i.id === '2' &&
                                        <Link onClick={this.vigent}>
                                            {this.props.language.language?.creditbureau.creditopen!}

                                        </Link>
                                    }
                                    {i.id === '3' &&
                                        <Link onClick={this.vencid}>
                                            {this.props.language.language?.creditbureau.creditopen!}

                                        </Link>
                                    }

                                </Card.Footer>
                            </Card>
                        ))
                        }
                    </CardDeck>
                    <br />
                    <br />
                    <div className="container">
                        <div className="row">
                            <div className="col-8 ">
                                <AlphanumericInput
                                    id="Alphanumeric--default"
                                    name="search-agent"
                                    labelVisual={this.props.language.language?.creditbureau.creditsearch}
                                    onChange={this.handleInputChange}
                                    value={this.state.search}
                                />

                            </div>

                            <div className="col-4 ">
                                <GridCol >
                                    <Button variant="primary" onClick={this.handlenew}>
                                        <HiPlus /> {this.props.language.language?.creditbureau.creditbutton!}
                                    </Button>
                                </GridCol>
                            </div>

                        </div>

                    </div>

                </div>

                <br />
                <div className="col-md-12">
                    {this.state.errorTableBuro === true ? (
                        <div className="col-12">
                            <Alert highlightType="negative">
                                {this.props.language.language?.creditbureau.alert!}
                            </Alert>
                        </div>
                    ) :
                        <>

                            {this.state.valida_tabla === true ? (
                                <div className="col-12 col-md-12 col-sm-12" >
                                    <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                </div>
                            ) :

                                <>

                                    {this.state.tabla === 1 &&
                                        <Table id='table-credit col-6' >
                                            <thead>
                                                <TableRow >
                                                    <TableCell dataHeader={'Folio'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.creditfolio!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'Nombre'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.creditname!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'Tipo'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.credittype!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'fechaConsulta'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.creditdate!}
                                                    </TableCell>

                                                    <TableCell dataHeader={'FolioBC'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.creditbc!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'Fecha'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.creditBCalif!}
                                                    </TableCell>
                                                    <TableCell dataHeader={'FechaAutorizacion'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.creditFechAuto!}
                                                    </TableCell>

                                                    <TableCell dataHeader={'Username'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.creditUsername!}
                                                    </TableCell>

                                                    <TableCell dataHeader={'Estatus'} type="colHead" className='tb'>
                                                        {this.props.language.language?.creditbureau.creditstatus!}
                                                    </TableCell>

                                                </TableRow>
                                            </thead>

                                            <tbody>

                                                {this.state.validador === true ? (
                                                    <>
                                                        {
                                                            this.state.listBureau && this.state.listBureau.map((value: ConsultaBuro, index: number) => (

                                                                <TableRow key={index} id={value.folio} >

                                                                    <TableCell type="rowHead" >
                                                                        {value.folio}
                                                                    </TableCell>


                                                                    <TableCell>
                                                                        {value.nombre + " " + value.apellidoPaterno}
                                                                    </TableCell>



                                                                    <TableCell >
                                                                        {value.tipoEntidad}
                                                                    </TableCell>

                                                                    <TableCell >
                                                                        {value.fechaConsulta}
                                                                    </TableCell>

                                                                    <TableCell >
                                                                        {value.folioBcConsulta}
                                                                    </TableCell>
                                                                    <TableCell >
                                                                        {value.calificacionBuro}
                                                                    </TableCell>
                                                                    <TableCell >
                                                                        {value.fechaAutorizacionFormato}
                                                                    </TableCell>
                                                                    <TableCell >
                                                                        {value.solicitante}
                                                                    </TableCell>



                                                                    <TableCell dataHeader={'Estatus'} className="estatus">
                                                                        <div className="row">
                                                                            <div className="col-6">
                                                                                {value.estado === '1' ? (
                                                                                    <Badge highlightType="informative" >
                                                                                        {this.props.language.language?.creditbureau.enprocess!}
                                                                                    </Badge>
                                                                                ) : null}
                                                                                {value.estado === '2' ? (
                                                                                    <Badge highlightType="positive">
                                                                                        &nbsp;&nbsp; {this.props.language.language?.creditbureau.vigent!}&nbsp;&nbsp;
                                                                                    </Badge>
                                                                                ) : null}
                                                                                {value.estado === '3' ? (
                                                                                    <Badge highlightType="negative">
                                                                                        &nbsp;&nbsp;{this.props.language.language?.creditbureau.vencid!}&nbsp;&nbsp;
                                                                                    </Badge>
                                                                                ) : null}
                                                                                {value.estado === '5' ? (
                                                                                    <Badge highlightType="negative">
                                                                                        &nbsp;&nbsp;{this.props.language.language?.creditbureau.error!}&nbsp;&nbsp;
                                                                                    </Badge>
                                                                                ) : null}
                                                                            </div>
                                                                            <div className="col-6">
                                                                                {value.estado === '1' ? (
                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle variant="light" className="dropdown-more" id="dropdown-basic-1">
                                                                                            <IconSeeMore></IconSeeMore>
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            <Dropdown.Item onClick={() => this.handleProcess(value.folio!)} >
                                                                                                {this.props.language.language?.creditbureau.creditconsult!}
                                                                                            </Dropdown.Item>


                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                ) : null}
                                                                                {value.estado === '2' ? (
                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle variant="light" className="dropdown-more" id="dropdown-basic-1">
                                                                                            <IconSeeMore></IconSeeMore>
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            <Dropdown.Item onClick={() => this.handleConsult(value.folio!)

                                                                                            }>
                                                                                                {this.props.language.language?.creditbureau.creditconsult!}
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item onClick={() => this.handleAgregarCarta(value.folio!, value.rfc, value.idFormato!)

                                                                                            }>
                                                                                                {this.props.language.language?.creditbureau.titleCarta!}
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item onClick={() => this.handleViewCarta(value.idFormato!, value.rfc, value.folio!)

                                                                                            }>
                                                                                                {this.props.language.language?.creditbureau.titleViewCarta!}
                                                                                            </Dropdown.Item>

                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                ) : null}
                                                                                {value.estado === '3' ? (
                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle variant="light" className="dropdown-more" id="dropdown-basic-1">
                                                                                            <IconSeeMore></IconSeeMore>
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            <Dropdown.Item onClick={() => this.handleVencid(value.folio!)

                                                                                            }>
                                                                                                {this.props.language.language?.creditbureau.creditconsult!}
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item onClick={() => this.handleViewCarta(value.idFormato!, value.rfc, value.folio!)

                                                                                            }>
                                                                                                {this.props.language.language?.creditbureau.titleViewCarta!}
                                                                                            </Dropdown.Item>

                                                                                        </Dropdown.Menu>

                                                                                    </Dropdown>
                                                                                ) : null}
                                                                                {value.estado === '5' ? (
                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle variant="light" className="dropdown-more" id="dropdown-basic-1">
                                                                                            <IconSeeMore></IconSeeMore>
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            <Dropdown.Item onClick={() => this.handleError(value.folio!)

                                                                                            }>
                                                                                                {this.props.language.language?.creditbureau.creditconsult!}
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item onClick={() => this.handleViewCarta(value.idFormato!, value.rfc, value.folio!)

                                                                                            }>
                                                                                                {this.props.language.language?.creditbureau.titleViewCarta!}
                                                                                            </Dropdown.Item>

                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>



                                                                    </TableCell>

                                                                </TableRow>



                                                            ))
                                                        }


                                                    </>
                                                ) : null}


                                            </tbody>
                                        </Table>

                                    }
                                    <Modal className='Modal-fecha' isOpen={this.state.modalUpdate} size="medium"
                                        modalStyle="standard" closeFunc={() => {
                                            this.setState({ modalUpdate: false });
                                        }}>
                                        <ModalBody>

                                            <div>



                                                <div className="autocomplete-wrapper-fecha" >
                                                    <Heading tag="span" type="h3-light">{this.props.language.language?.creditbureau.titleCarta!}</Heading>
                                                    <br /><br />
                                                    <div className="container">
                                                        <div className="row">

                                                            <div className="col-12">
                                                                <div className="dropdown">
                                                                    <p>{this.props.language.language?.createquery.fechaAutorizacion!}</p>
                                                                    <input
                                                                        type='date'
                                                                        id="fechaAutorizacion"
                                                                        name='fechaAutorizacion'
                                                                        className='form-control'
                                                                        accept=".pdf                                                                                       "
                                                                        value={this.state.fechas.fechaAutorizacion}
                                                                        onChange={this.handleinputfecha}

                                                                    />
                                                                    {this.state.isValid1 ? (
                                                                        <div className="col-12">
                                                                            <Alert id='alert' highlightType="negative">
                                                                                {this.props.language.language?.createquery.fechaInco!}
                                                                            </Alert>
                                                                        </div>
                                                                    ) :
                                                                        <>
                                                                        </>}
                                                                </div>
                                                            </div>
                                                            <br /> <br /><br /><br /> <br />
                                                            <div className="col-12">
                                                                <div className="custom-input-file col-12 col-md-12 col-sm-6 ">
                                                                    <label className="custom-file-upload" >

                                                                        <IconFolder
                                                                            size="16"
                                                                            color='inverse' /> &nbsp;
                                                                        <input type="file" onChange={this.handleChange} accept='application/pdf' />
                                                                        {this.props.language.language?.creditbureau.subirCarta!}

                                                                    </label>
                                                                </div>
                                                                {(this.state.validalinea === 3) ? (
                                                                    <Alert highlightType="positive">
                                                                        {this.props.language.language?.creditbureau.archivoSelect!}
                                                                    </Alert>
                                                                ) : null}
                                                                <br />
                                                            </div>
                                                        </div>



                                                        <Button className='btn-archivo' size="small" variant="secondary" onClick={this.PdfVis} hidden={this.state.show} >
                                                            {this.props.language.language?.createquery.visualFile!}
                                                        </Button>


                                                    </div>


                                                    <br />
                                                    <div>
                                                        <Button variant="primary" size="small" id='saveandContinue' onClick={this.fechAut} >
                                                            {this.props.language.language?.creditbureau.btnSave!}
                                                        </Button>


                                                    </div>

                                                </div>




                                            </div>


                                        </ModalBody>

                                    </Modal>

                                    <Modal isOpen={this.state.pdfOpen} size="large"
                                        className='Modal-pdf'
                                        closeFunc={() => {
                                            this.setState({ pdfOpen: false });
                                        }}
                                    >

                                        <iframe title='archivo' className="iframe-pdf" src={this.archivo} allowFullScreen ></iframe>


                                    </Modal>


                                    <Modal className='Modal-fecha' isOpen={this.state.modalCartas} size="large"
                                        modalStyle="standard" closeFunc={() => {
                                            this.setState({ modalCartas: false });
                                        }}>
                                        <ModalBody>
                                            <div className="autocomplete-wrapper-fecha" >
                                                <Heading tag="span" type="h3-light">Consulta Cartas</Heading>
                                                <br /><br />
                                                {this.state.errorFormato === true ? (
                                                    <div className="col-12">
                                                        <Alert highlightType="negative">
                                                            {'No se encontraron cartas'}
                                                        </Alert>
                                                    </div>
                                                ) :
                                                    <>

                                                        {this.state.valida_formatos === true ? (
                                                            <div className="col-12 col-md-12 col-sm-12" >
                                                                <SpinnerLoading text={this.props.language.language?.createquery.Carga_Consult!} />
                                                            </div>
                                                        ) :

                                                            <>
                                                                <div className="container">
                                                                    <div className="row">


                                                                        <div className="col-md-12">
                                                                            <Table id='table-archivo' className='Tablaformatos' >
                                                                                <thead>
                                                                                    <TableRow >
                                                                                        <TableCell dataHeader={'Folio'} type="colHead" className='tb'>
                                                                                            No.
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader={'fechaCarga'} type="colHead" className='tb'>
                                                                                            {this.props.language.language?.createquery.fechaCarga!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader={'Tipo'} type="colHead" className='tb'>
                                                                                            {this.props.language.language?.createquery.fechaAutorizacion!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader={'Fecha'} type="colHead" className='tb'>
                                                                                            {this.props.language.language?.createquery.user!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader={'FolioBC'} type="colHead" className='tb'>
                                                                                            {this.props.language.language?.createquery.status!}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader={'FolioBC'} type="colHead" className='tb'>
                                                                                            {'Carta Consulta'}
                                                                                        </TableCell>
                                                                                        <TableCell dataHeader={'FolioBC'} type="colHead" className='tb'>
                                                                                            {this.props.language.language?.createquery.acciones!}
                                                                                        </TableCell>


                                                                                    </TableRow>
                                                                                </thead>

                                                                                {this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => (

                                                                                    <>
                                                                                        <tbody>

                                                                                            <TableRow >

                                                                                                <TableCell type="rowHead">
                                                                                                    {index + 1}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {value.fechaCarga}
                                                                                                </TableCell>
                                                                                                <TableCell >
                                                                                                    {value.fechaAutorizacion}
                                                                                                </TableCell>
                                                                                                <TableCell >
                                                                                                    {value.usuario}
                                                                                                </TableCell>
                                                                                                <TableCell >

                                                                                                    {value.estatus}
                                                                                                </TableCell>
                                                                                                <TableCell >
                                                                                                    {value.statusCarta}
                                                                                                </TableCell>


                                                                                                <TableCell dataHeader={'Estatus'} className="estatus">
                                                                                                    <div className="row">
                                                                                                        <div className="col">
                                                                                                            <IconButton background="transparent" onClick={() => this.handleView(value.nombreArchivoCarta)} >
                                                                                                                <IconSearch size="24" />
                                                                                                            </IconButton>
                                                                                                            {value.estatus === 'Vigente' && value.idCartaUsada === '0' ? (
                                                                                                                <>
                                                                                                                    <IconButton background="transparent" onClick={() => this.handleEdit(value.fechaAutorizacion, value.idFormato, value.nombreArchivoCarta)} >
                                                                                                                        <IconEdit size="24" />
                                                                                                                    </IconButton>
                                                                                                                    <IconButton background="transparent" onClick={() => this.handleDelete(value.idFormato)} >
                                                                                                                        <IconTrash size="24" />
                                                                                                                    </IconButton>
                                                                                                                </>
                                                                                                            ) :
                                                                                                                <>
                                                                                                                </>}
                                                                                                        </div>

                                                                                                    </div>



                                                                                                </TableCell>

                                                                                            </TableRow>





                                                                                        </tbody>
                                                                                    </>
                                                                                ))}

                                                                            </Table>
                                                                            <br />

                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </>}
                                                    </>}
                                                <br />

                                            </div>


                                        </ModalBody>

                                    </Modal>

                                    <Modal className='Modal-fecha' isOpen={this.state.modalFechas} size="medium"
                                        modalStyle="standard" closeFunc={() => {
                                            this.setState({ modalFechas: false });
                                        }}>
                                        <ModalBody>
                                            <div className="autocomplete-wrapper-fecha" >
                                                <Heading tag="span" type="h3-light">Edición de Fechas</Heading>
                                                <br /><br />
                                                <div className="container">
                                                    <div className="row">

                                                        <div className="col">
                                                            <div className="dropdown">
                                                                <p>{this.props.language.language?.createquery.fechaAutorizacion!}</p>
                                                                <input
                                                                    type='date'
                                                                    id="fechaAutorizacion"
                                                                    name='fechaAutorizacion'
                                                                    className='form-control'
                                                                    value={this.state.fechas.fechaAutorizacion}
                                                                    onChange={this.handleinputfecha}

                                                                />
                                                                {this.state.isValid1 ? (
                                                                    <div className="col-12">
                                                                        <Alert id='alert' highlightType="negative">
                                                                            {this.props.language.language?.createquery.fechaInco!}
                                                                        </Alert>
                                                                    </div>
                                                                ) :
                                                                    <>
                                                                    </>}


                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <br />
                                                <div>
                                                    <Button variant="primary" size="large" id='guadarfecha' onClick={this.putUpdatetablaForms} >
                                                        {this.props.language.language?.createquery.guardar!}
                                                    </Button>


                                                </div>
                                            </div>
                                        </ModalBody>

                                    </Modal>
                                </>}
                        </>}
                    {this.state.validador === false ? (
                        <>
                            <p>{this.props.language.language?.creditbureau.data!}</p>
                        </>) : null}

                    {/*paginacion*/}
                    < div className="row pagination-sdgf" >
                        <div className="col-md-5 show-registers">
                            {this.props.language.language?.creditbureau.creditmostrar!}&nbsp;
                            {(this.state.paginacion.currentPage * this.state.paginacion.size) - (this.state.paginacion.size - 1)}  {this.props.language.language?.creditbureau.credital!}&nbsp;
                            {(this.state.paginacion.currentPage * this.state.paginacion.size)}  {this.props.language.language?.creditbureau.creditotal!}&nbsp;
                            {this.state.paginacion.totalItems}  {this.props.language.language?.creditbureau.creditrecors!}.
                        </div>
                        <div className="col-md-7 ">
                            <Pagination className="float-md-right" >
                                <Pagination.Prev
                                    onClick={() => {
                                        this.clickGoToPage((this.state.listPaginacion[0]) - 1)

                                    }}
                                />
                                {
                                    this.state.listPaginacion.map((i, index) =>
                                    (<Pagination.Item
                                        key={i}
                                        active={i === this.state.paginacion.currentPage}
                                        onClick={() => {
                                            this.clickGoToPage(i);
                                        }}>
                                        {i}
                                    </Pagination.Item>

                                    ))
                                }
                                <Pagination.Next
                                    onClick={() => {
                                        this.clickGoToPage(this.state.listPaginacion[this.state.listPaginacion.length - 1] + 1)
                                    }}
                                />

                            </Pagination>

                        </div>

                    </div>


                </div>

            </div>
        </>

    }
    loadDashboard(tipoPerfil: TypeProfileModel) {
        throw new Error('Method not implemented.');
    }

    componentDidMount() {
        this.getCredit(this.state.search, this.state.paginacion);
        this.getPorecentaje();
        this.getCalifBuro();
    }

    //funciones de redirección
    public handlenew() {
        this.props.history.push('/buro-credito/crear-consulta');
    }

    public process() {
        this.props.history.push('/buro-credito/filtrar-consultas/en-proceso');
    }

    public vigent() {
        this.props.history.push('/buro-credito/filtrar-consultas/vigentes');
    }
    public vencid() {
        this.props.history.push('/buro-credito/filtrar-consultas/vencidas');
    }
    public handleConsult(value: string) {

        localStorage.setItem('FolioConsulta', JSON.stringify(value));
        this.props.history.push('/buro-credito/Consultar/proceso');
    }
    public handleProcess(value: string) {

        localStorage.setItem('FolioConsulta', JSON.stringify(value));
        this.props.history.push('/buro-credito/consultar/consulta-en-proceso');
    }
    public handleVencid(value: string) {


        localStorage.setItem('FolioConsulta', JSON.stringify(value));
        this.props.history.push('/buro-credito/consultar/info-vencidas');
    }
    public handleError(value: string) {


        localStorage.setItem('FolioConsulta', JSON.stringify(value));
        this.props.history.push('/buro-credito/consultar/error');
    }


    //fimcion para abrir el apartado del  pdf
    public PdfVis() {
        this.setState({ pdfOpen: true })

    }


    //funcion para visualizar el pdf y enviamos los datos para obtener la ruta
    public handleView(value: string): void {
        this.setState({ PDFObject: { carpeta: 'buro', nombreArchivo: value + `.pdf` } }, () =>
            this.postURl());
    }

    //Editamos las fecha de la tabla formatos
    public handleEdit(fechaAutorizacion: string, idFormato: string, folio: string) {
        //console.log(fechaAutorizacion)
        //console.log(fechaCarga)
        this.setState({ modalCartas: false })
        this.setState({
            fechas: {
                ...this.state.fechas,
                fechaAutorizacion: fechaAutorizacion,
                idFormato: idFormato,
                folio: folio
            }
        })
        this.setState({ modalFechas: true })


        //this.putUpdatetablaForm();
    }

    //funcion para eliminar los datos de la tabla formatos
    public handleDelete(value: string) {
        //console.log(value)
        this.setState({
            idFormatoDelete: value
        })
        this.alertDeleteFormat()

    }

    //enviamos los datos ara guardar del modal
    public fechAut() {
        this.postFechAuto();
        //this.setState({ pdfShow: false })
        this.postFileValidacion(this.state.formData);
        this.alertExito();
    }

    //función para abrir modal y obtener el rfc de la persona M/F
    public handleAgregarCarta(value: string, rfc: string, idformato: string) {
        localStorage.setItem('FolioConsulta', JSON.stringify(value));
        this.setState({ modalUpdate: true })
        this.setState({
            rfc: rfc
        })
        const formato = Number.parseInt(idformato);

        this.setState({ idFormato: formato })
    }




    //funcion para visualizar el archivo
    public handleViewCarta(idFormato: string, rfc: string, folio: string) {
        this.setState({ modalCartas: true })
        const formato = Number.parseInt(idFormato);
        this.setState({ idFormato: formato })
        //this.getregistroFormatoBuro(idFormato, rfc, folio)
        this.getTablaForm(rfc, formato);
    }

    //obtenmos los datos de la tabla formatos
    public async getTablaForm(rfc: string, idFormato: number) {
        this.setState({ Consul_buro: true })
        this.setState({ valida_formatos: true })
        try {

            const res = await getCreditbureau.getTablaFormCars(rfc, idFormato)
            var datoForm = res.data
            var ob = Object.values(datoForm)
            this.setState({ prueba: datoForm })
            this.setState({ tableFormatodOC: ob })
            if (ob.length === 0) {
                this.setState({ errortablaformat: true })
                this.setState({ Consul_buro: false })
                this.setState({ errorFormato: true })
                this.setState({ valida_formatos: true })


            } else {
                this.setState({ Consul_buro: true })
                this.setState({ errortablaformat: false })
                this.setState({ errorFormato: false })
                this.setState({ valida_formatos: false })
                const status = this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => value.nombreArchivoCarta);
                const str = status[0].toString();


                const fechaauto = this.state.tableFormatodOC.map((value: tableFormatDocument, index: number) => value.fechaAutorizacion);
                const strF = fechaauto[0].toString();


                this.setState({
                    registroFormato: {
                        nombreArchivoCarta: str,
                        fechaAutorizacion: strF,
                        rfc: this.state.registroFormato.rfc,
                        usuario: this.state.registroFormato.usuario,
                        fechaCarga: this.state.registroFormato.fechaCarga,
                        folioConsulta: this.state.registroFormato.folioConsulta
                    }
                })

                //console.log(this.state.registroFormato.nombreArchivoCarta)
            }
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                this.setState({ valida_formatos: false })
                this.setState({ errorFormato: true })
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    //obtenemos los datos formato para la tabla
    public async putDeletetablaForm() {
        try {
            await getCreditbureau.putDeletetablaForm(this.state.idFormatoDelete);
            this.getTablaForm(this.state.rfc, this.state.idFormato)


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    //actualizamos la fecha autorizada al editar por el idFormato

    public  putUpdatetablaForms = async () => {
        try {
            // console.log(this.state.fechas)
            var fechas = this.state.fechas.fechaAutorizacion.split("-")
            var fechaA = fechas[2] + "-" + fechas[1] + "-" + fechas[0];
            this.state.fechas.fechaAutorizacion = fechaA

            const res = await getCreditbureau.putUpdatetablaForm(this.state.fechas.fechaAutorizacion, this.state.fechas.idFormato);
            //console.log(res)
            this.setState({ modalUpdate: false });
            this.getTablaForm(this.state.rfc, this.state.idFormato)
            this.postFechaAutoEdit(this.state.fechas.fechaAutorizacion, this.state.fechas.folio)
            this.alertDate();
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    //enviamos la fecha auto al editar
    public async postFechaAutoEdit(fecha: string, folio: string) {
        try {


            await getCreditbureau.postFechAuto(fecha, folio)
            //this.posTablaFormato();


        } catch {

        }

    }


    //función para obetner si ya tiene un archivo 
    async getregistroFormatoBuro(idFormato: string, rfc: string, folio: string): Promise<void> {
        try {
            if (idFormato === '') {
                idFormato = '0'
                //console.log('entra aqui si es vacio')
                //console.log(idFormato)
                //console.log('entra aqui es 0')
                const acti = await getCreditbureau.getregistroActivo(folio, idFormato)
                const actiA = acti.data

                this.setState({ registroFormato: actiA })
                //console.log(this.state.registroFormato.nombreArchivoCarta)
                this.setState({
                    PDFObject: { carpeta: 'buro', nombreArchivo: this.state.registroFormato.folioConsulta + `.pdf` }
                })
                this.postURl();
            }

            const res = await getCreditbureau.getregistroFormatoBuro(idFormato);
            //console.log(res.data)
            this.setState({ registroFormato: res.data })
            if (this.state.registroFormato.folioConsulta !== '') {
                //console.log('entra qui al vacio')
                this.setState({
                    PDFObject: { carpeta: 'buro', nombreArchivo: this.state.registroFormato.folioConsulta + `.pdf` }
                })
                //     this.getDatos(this.state.registroFormato.nombreArchivoCarta);
                //     console.log('entra aqui es difente a 0')
            } else {
                this.setState({
                    PDFObject: { carpeta: 'buro', nombreArchivo: this.state.registroFormato.nombreArchivoCarta + `.pdf` }
                })
            }


            this.postURl();

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }

    }

    //traemos el archivo
    public async postURl() {
        try {
            const res = await apiS3Services.postUrl(this.state.PDFObject);
            const urldata = JSON.stringify(res.data.url)
            const comillas = urldata.replace(/['"]+/g, '')
            //console.log(comillas)
            var win = window.open(comillas, '_blank');
            win?.focus();

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    //Función que agrega el nuevo state de la fecha
    public handleinputfecha = async (e: InputChange) => {
        //var inputFields = this.state.fechas;
        e.persist();
        await this.setState(preveState => ({
            fechas: { ...this.state.fechas, [e.target.name]: e.target.value }

        }))



        var fechaA = this.state.fechas.fechaAutorizacion.split("-")
        var fech = fechaA[2] + "-" + fechaA[1] + "-" + fechaA[0];
        this.setState({ fechaAutoAux: fech })
        //console.log(this.state.fechaAutoAux)
        var isValid1 = fech.match(/^(3[01]|[12][0-9]|0?[1-9])(\/|-)(1[0-2]|0?[1-9])\2([0-9]{2})?[0-9]{2}$/);

        if (isValid1) {
            // console.log("fecha Correcta.");
            this.setState({ isValid1: false })
        } else {
            //console.log("Fecha Incorrecta");
            this.setState({ isValid1: true })
        }

    }

    //obtenemos el porcentaje de las graficas
    public async getPorecentaje() {
        try {
            const res = await getCreditbureau.getPorecentaje(this.state.PERFIL.roles.toString(), this.state.PERFIL.username);
            this.setState({ listPorcent: res.data })

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }

    }

    //Funcion para agregar el archivo  al s3 
    public handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

        if (e.target.files![0].size > 10097152) {
            this.alertArchivo();
            const fileObj = e.target.files && e.target.files[0];
            if (!fileObj) {
                return;

            }
            this.setState({ show: true })
            this.setState({ pdfOpen: true })
            this.setState({ validalinea: 0 })

            //console.log('fileObj is', fileObj);

            // 👇️ reset file input
            e.target.value = '';

            // 👇️ is now empty
            //console.log(e.target.files);

        } else {


            if (validFilepdf(e)) {
                var storedata = localStorage.getItem('FolioConsulta');
                if (storedata == null) {
                    console.log('vacio')
                } else {
                    var data = storedata.replace(/['"]+/g, '')
                    const dt = data;
                    //función de obtener la secuencia para el nombre del archivo
                    const res = await getCreditbureau.getFolioSeqFormato(dt)
                    const ob = res.data
                    this.setState({ FolioSeq: ob })
                    const formDataAuxiliar = this.state.formData
                    formDataAuxiliar.set('archivo', e.target.files![0]);
                    formDataAuxiliar.set('carpeta', 'buro');
                    formDataAuxiliar.set('nombreArchivo', this.state.FolioSeq.folio + '.pdf');
                    this.setState({ formData: formDataAuxiliar })
                    //console.log('asi se llena la formdata' + this.state.formData)
                    //console.log(e.target.files![0].arrayBuffer)
                    this.setState({ pdf: e.target.files![0].name })
                    this.setState({ validalinea: 3 })
                    this.setState({ show: false })
                    //console.log(this.state.fechaAutoAux)
                    var prueba = (window.URL).createObjectURL(e.target.files![0])
                    //console.log(prueba)
                    this.archivo = prueba;
                }

            }
        }


    }


    //obtenemos el valor para la busqueda
    public handleInputChange(e: InputChange): void {

        this.setState({
            search: e.target.value
        }, () => {
            this.getCredit(this.state.search, this.state.paginacion)
        })



    }

    //Función para subir al s3
    public async postFileValidacion(formData: FormData) {

        try {

            const res = await apiS3Services.postFile(formData)
            const arrayFile = res.data
            //console.log(arrayFile);
            // this.getCredit();
            // this.setState({ Consul_buro: true })

            const formDataAuxiliar = this.state.formData
            formDataAuxiliar.set('archivo', '');
            formDataAuxiliar.set('carpeta', '');
            formDataAuxiliar.set('nombreArchivo', '');

            this.setState({ formData: formDataAuxiliar })
            this.setState({ validalinea: 0 })
            this.setState({ show: true })


        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }

    //funcion para el envio de fecha de autorización a la hora de agregar la carta
    public async postFechAuto() {
        try {
            var fecha = this.state.fechas.fechaAutorizacion.split("-")
            var fech = fecha[2] + "-" + fecha[1] + "-" + fecha[0];
            this.setState({ fechaAutoAux: fech })
            //console.log(this.state.fechaAutoAux)
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                //console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data

                //console.log('entra en actualiza fecha' + this.state.fechaAutoAux)
                await getCreditbureau.postFechAuto(this.state.fechaAutoAux, dt)
                this.posTablaFormato();
                this.getCredit(this.state.search, this.state.paginacion);


                this.setState({
                    fechas:
                    {
                        ...this.state.fechas,
                        fechaAutorizacion: '',

                    }
                })

            }

        } catch {

        }

    }

    //Enviamos los datos para guardar y mostrar en la tabla de formatos
    public async posTablaFormato() {
        try {
            var fecha = this.state.fechas.fechaAutorizacion.split("-")
            var fech = fecha[2] + "/" + fecha[1] + "/" + fecha[0];
            let date = new Date();
            let set = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
            //console.log(this.state.fechaAutoAux)
            //console.log(this.state.rfc)
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                this.setState({
                    datoForm:
                    {
                        ...this.state.datoForm,
                        fechaAutorizacion: fech,
                        fechaCarga: set,
                        nomArchivoCarta: this.state.FolioSeq.folio,
                        rfc: this.state.rfc,
                        usuario: this.state.PERFIL.username,
                        folioConsulta: dt,
                        subirArchivo: '1',
                        idCartaUsada: '0'
                    }
                })
            }

            this.setState({ fechaAutoAux: fech })
            //console.log('entra en enviar datos a la tabla todos los datos ' + this.state.datoForm)
            //console.log('entra en enviar datos a la tabla' + this.state.fechaAutoAux)
            await getCreditbureau.posTablaFormato(this.state.datoForm)
            //this.getTablaForm(this.state.consultObject.rfc);


        } catch {

        }
    }

    //Tabla historico
    public async getCredit(search: string, paginacion: PaginacionModel) {


        try {
            this.setState({ valida_tabla: true })
            const res = await getCreditbureau.getConsulta(this.state.search, 0, paginacion);
            const response: { paginacion: PaginacionModel, consultas: ConsultaBuro[] } = res.data;
            response.paginacion.size = paginacion.size;


            this.setState({
                paginacion: response.paginacion,
                loadingConsult: false,
                listBureau: response.consultas,
                listPaginacion: loadPaginationList(res.data.paginacion),
                validador: true
            })

            if (response.consultas == null) {
                this.setState({ validador: true })
                this.setState({ errorTableBuro: true })
                this.setState({ valida_tabla: false })
                this.setState({ tabla: 0 })

            } else {
                this.setState({ valida_tabla: false })
                this.setState({ errorTableBuro: false })
                //console.log(this.state.listBureau)
                this.setState({ tabla: 1 })
            }




        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)
            }
        }
    }

    private clickGoToPage(page: number) {
        const totalPages: number = this.state.paginacion.totalPages;
        if (page >= 1 && page <= totalPages) {
            const auxPaginacion = this.state.paginacion;
            auxPaginacion.currentPage = page;
            this.setState({ paginacion: auxPaginacion })
            this.getCredit(this.state.search, this.state.paginacion);
        }



    }

    //nos regresa la calificación de buro
    public async getCalifBuro() {

        try {
            var storedata = localStorage.getItem('FolioConsulta');
            if (storedata == null) {
                console.log('vacio')
            } else {
                var data = storedata.replace(/['"]+/g, '')
                var dt = data
                const res = await getCreditbureau.getCalifBuro(dt)
                const calif = res.data.calificacion
                this.setState({ calif: calif })
            }
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                console.log(err.response.status)
                console.log(err.response.data)

            }
        }
    }



    // Alerta de archivo grande
    public alertArchivo() {
        Swal.fire(
            'Archivo demasiado grande!',
            '',
            'warning'
        )
    }
    //Alerta Guardado con exito
    public alertExito() {
        Swal.fire(
            'Guardado con exito!',
            '',
            'success'
        )
        this.setState({ modalUpdate: false })
        this.props.history.push('/buro-credito');
    }


    public alertDate() {
        Swal.fire(
            'Se cambio la fecha correctamente!',
            '',
            'success'
        )
        this.setState({ modalFechas: false })
        this.props.history.push('/buro-credito');
       
    }

    public alertDeleteFormat() {

        Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        Swal.fire({
            title: 'Estas seguro de eliminar el archivo?',
            text: "No podras recuperarlo",
            icon: 'warning',
            confirmButtonColor: '#008040',
            cancelButtonColor: '#D32F2F',
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Eliminado!',
                    'Tu archivo a sido borrado con exito',
                    'success'
                )
                this.putDeletetablaForm();
            }

        })
    }

}






function mapStateToProps(state: RootStore) {
    return ({
        language: state.language
    });
}


export default connect(mapStateToProps)(Creditbureau);

interface BureauState {
    status: number,
    listBureau: ConsultaBuro[];
    listPorcent: Datos[];
    search: string;
    paginacion: PaginacionModel,
    loadingConsult: boolean,
    listPaginacion: number[],
    filterSearch: SearchModel,
    validador: boolean,
    valida_tabla: boolean,
    errorTableBuro: boolean,
    errorFormato: boolean,
    valida_formatos: boolean,
    tabla: number,
    PERFIL: UserModel,
    calif: string
    modalUpdate: boolean,
    modalCartas: boolean,
    modalFechas: boolean,
    validalinea: number,
    FolioSeq: FolioSeq,
    formData: FormData,
    pdf: string,
    fechaAutoAux: string,
    fechas: fechasEdit,
    isValid1: boolean,
    datoForm: FormatDocument,
    rfc: string,
    PDFObject: PdfFile,
    registroFormato: RegistroFormato,
    tableFormatodOC: tableFormatDocument[],
    Consul_buro: boolean,
    prueba: tableFormatDocument,
    errortablaformat: boolean,
    idFormatoDelete: string,
    idFormato: number,
    pdfOpen: boolean,
    show: boolean
}

export interface SearchModel {
    folio: string;
}




