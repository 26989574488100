import {
    LOAD_PERMISSIONS,
  LOAD_PERMISSIONS_FAIL,
  LOAD_PERMISSIONS_SUCCESS,
  PermissionDispatchTypes,
} from "../actions/PermissionsActionTypes";
import Permission from "../models/users-management/Permission";

interface PermissionReducer {
  loading: boolean;
  permissions?: Permission[];
}

const defaultState: PermissionReducer = {
  loading: false,
};

const permissionReducer = (
  state: PermissionReducer = defaultState,
  action: PermissionDispatchTypes
): PermissionReducer => {
  switch (action.type) {
    case LOAD_PERMISSIONS_FAIL:
      return {
        loading: false,
      };
    case LOAD_PERMISSIONS:
      return {
        loading: true,
      };
    case LOAD_PERMISSIONS_SUCCESS:
      return {
        loading: false,
        permissions: action.payload,
      };
    default:
      return state;
  }
};

export default permissionReducer;
