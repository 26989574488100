import React, {ChangeEvent, useEffect, useState} from 'react';
import './DocumentoMantenimiento.scss';
import {
  IconAdd,
  IconChecklist,
  IconCheckmark, IconDownload,
  IconEdit,
  IconErrorAlert,
  IconSearch, IconThumbsDown, IconThumbsUp,
  IconTrash,
  IconUpload
} from "@lmig/lmds-react";
import {ExpedienteIdetificacionModel} from "../../../models/garantias/ExpedienteIdetificacionModel";
import {
  isAclaracionFirmasCSAM,
  MAXSIZEUPLOADFILE,
  nowDate,
  openNewWindow, validExpedienteCorreos,
  validExpedienteIden,
  validRoles
} from "../../../class/CommonClass";
import * as garantiasService from '../../../services/garantias/GarantiasService';
import {AxiosError} from "axios";
import {RootStore} from "../../../Store";
import {useSelector} from "react-redux";
import {TypeDocModel} from "../../../models/garantias/TypeDocModel";
import {statesDocIdentification} from "../../../models/garantias/statesDocIdentification";
import {Alert, Dropdown, Spinner} from "react-bootstrap";
import {LocalStorageClass} from "../../../class/LocalStorageClass";
import {UserModel} from "../../../models/public/UserModel";
import {UploadDragExpediente} from "../upload-drag-expediente/UploadDragExpediente";
import {
  AuthorizationExpedientIdentification
} from "../authorization-expedient-identification/AuthorizationExpedientIdentification";
import EditarDescripcion from "../editar-descripcion-doc-expediente/EditarDescripcion";


interface DocExpedienteIdentificacionProps {
  idPrincipal: number;
  typeDoc: TypeDocModel;
  moveExpediente:(idDestinity: number, idDocumento: number) => void;
  authorizeDoc: (idDoc: number) => void;
  rejectDoc: (idDoc: number) => void;
}


export const DocExpedienteIdentificacion: React.FC<DocExpedienteIdentificacionProps> = (
    {
      idPrincipal,
      typeDoc,
      moveExpediente,
      authorizeDoc,
      rejectDoc
    }: DocExpedienteIdentificacionProps) => {
  // redux
  const languageState = useSelector((state: RootStore) => state.language);

  const localStorageClass = new LocalStorageClass();

  const [PERFIL, setPerfil] = useState<UserModel>(localStorageClass.getProfile());

  const [excedLimitFile, setExcedLimitFile] = useState<boolean>(false);

  const [listDocuments, setListDocuments] = useState<ExpedienteIdetificacionModel []>([]);

  const [draggable, setDraggable] = useState<boolean>(true);

  const [uploading, setUploading] = useState<boolean>(false);

  const initUploading: ExpedienteIdetificacionModel = {
    idDocumento: 0,
    idTipoDocumento: typeDoc.id,
    idClave: null,
    descripcion: typeDoc.nombre,
    descripcionTipoDocumento: typeDoc.nombre,
    url:  '',
    observacion: null,
    estado:  statesDocIdentification.subiendo,
    obligatorio: null,
    detalle: 'Cargando Archivo'
  }



  const handleUpdDocument = async (data: ExpedienteIdetificacionModel, indice: number) => {
    const auxListDocuments: ExpedienteIdetificacionModel[] = [...listDocuments];
    try {
      const res = await garantiasService.putExpedienteIdentificacion(data);
      auxListDocuments[indice] = res.data;
      setListDocuments(auxListDocuments);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        console.log(err.response.status);
        console.log(err.response.data);
      }
    }
  };
  const drop = (ev: React.DragEvent<HTMLDivElement>) => {
    const idHTML = ev.dataTransfer.getData("text");
    const idDoc: number = parseInt(idHTML.substr('id-doc-'.length, idHTML.length));

  }
  const  allowDrop = (ev: React.DragEvent<HTMLDivElement>) => {
    const idDoc = (ev.target as HTMLLIElement).id;
    // si pasa por enciam de una zona que empiece con los id
    // con la nomenclatura 'type-doc-' se va actualizando el destino del
    // documento
    const idHTML = 'type-doc-';
    if (idDoc.substr(0,idHTML.length)==idHTML){
      //console.log(idDoc)
      const idType= parseInt(idDoc.substr(idHTML.length, idDoc.length));
      // setIdTypeDestinity(idType)
    }

    ev.preventDefault();

  }

  const drag = (ev: React.DragEvent<HTMLLIElement>) => {
    const idDoc = (ev.target as HTMLLIElement).id;
    ev.dataTransfer.setData("text", idDoc);
    //  console.log('drag:  id ' + idDoc )

  }

  const handleFileUploadInputDocIdentification = (e: ChangeEvent<HTMLInputElement>) => {
   // console.log(e)
    console.log(e.target.files![0])
    console.log(e.target.files![0].type);
    if (e.target.files![0].size >= MAXSIZEUPLOADFILE){
      setExcedLimitFile(true)
      e.target.value = '';// vaciar el input file
    }else {
      const formData = new FormData();
      formData.append('idCliente', String(idPrincipal));
      formData.append('idTipoDocumento', String(typeDoc.id));
      formData.append('fileName', e.target.files![0]);
      formData.append('fechaCarga', nowDate());

      if (e.target.files![0].size >= MAXSIZEUPLOADFILE){
        setExcedLimitFile(true)
        e.target.value = '';// vaciar el input file
      }else {
        e.target.value = '';// vaciar el input file
        postDocIdentification(formData);
      }


    }
  }

  const postDocIdentification = async (formData: FormData) => {

    const auxListDocuments : ExpedienteIdetificacionModel [] = [...listDocuments, initUploading]
    setListDocuments(auxListDocuments);
    setUploading(true);


    try {
      const res = await garantiasService.postExpedienteIdentificacion(formData);

      let newListDocuments : ExpedienteIdetificacionModel [] = []

      let posDel : number | null= null ;
      for (let i= 0; i < auxListDocuments.length ; i++){
        if (auxListDocuments[i].estado == statesDocIdentification.subiendo){
          posDel = i;
          break;
        }
      }

      auxListDocuments[posDel!] = res.data;

      newListDocuments = [...auxListDocuments]

      setListDocuments(newListDocuments);
      setUploading(false);

    } catch (error) {
      const err = error as AxiosError
      if (err.response) {
        console.log(err.response.status)
        console.log(err.response.data)
        setUploading(false);

      }
    }
  }

  const deleteFile = async (idDocumento: number) => {
    const auxListDocuments : ExpedienteIdetificacionModel [] = [...listDocuments]
    let indexDoc = 0;

    const newState = auxListDocuments.map((obj, index) => {
      if (obj.idDocumento === idDocumento) {
        indexDoc = index
        return {...obj,
          detalle: 'Eliminando Documento',
          url: '',
          estado: statesDocIdentification.eliminando};
      }

      return obj
    });
    setListDocuments(newState)

    if (idDocumento){
      try {
        const res = await garantiasService.deleteFileExpediente(idDocumento);
        //        console.log(auxListDocuments);

        auxListDocuments.map((j, index2) => {
          if (j.idDocumento === idDocumento){
            //          console.log(j);
            auxListDocuments.splice(index2, 1);
          }
        })

        setListDocuments(auxListDocuments);;



      } catch (error) {
        const err = error as AxiosError
        if (err.response) {
          console.log(err.response.status)
          console.log(err.response.data)
          // quitar el spiner de loading


        }
      }
    }

  }

  const handleFileUpdateDocIdentificaction = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e)
    console.log(e.target.files![0])
    //console.log(e.target.files![0])
    const indiceFile = parseInt(e.target.name);
    const formData = new FormData();
    const idDoc = listDocuments[indiceFile].idDocumento;

    if (isAclaracionFirmasCSAM(typeDoc.id)){
      if (validExpedienteCorreos(e)){
        if (idDoc){
          formData.append('idDocumento', String(idDoc));
          formData.append('idTipoDocumento', String(typeDoc.id));
          formData.append('fileName', e.target.files![0]);
          formData.append('fechaCarga', nowDate());
          e.target.value = '';// vaciar el input file
          putFile(formData, indiceFile);
        }
      }
      else if (e.target.files![0].size >= MAXSIZEUPLOADFILE){
        setExcedLimitFile(true)
        e.target.value = '';// vaciar el input file
      }
      else {
        e.target.value = '';// vaciar el input file
      }
    }
    else {
      if (validExpedienteIden(e)){
        if (idDoc){
          formData.append('idDocumento', String(idDoc));
          formData.append('idTipoDocumento', String(typeDoc.id));
          formData.append('fileName', e.target.files![0]);
          formData.append('fechaCarga', nowDate());
          e.target.value = '';// vaciar el input file
          putFile(formData, indiceFile);
        }
      }
      else if (e.target.files![0].size >= MAXSIZEUPLOADFILE){
        setExcedLimitFile(true)
        e.target.value = '';// vaciar el input file
      }
      else {
        e.target.value = '';// vaciar el input file
      }
    }

  }

  const putFile = async (formData: FormData, indiceFile: number) => {
    setUploading(true);

    const auxListDocuments : ExpedienteIdetificacionModel [] = [...listDocuments]

    auxListDocuments[indiceFile] = initUploading;

    setListDocuments(auxListDocuments)

    try {
      const res = await garantiasService.putExpedienteArchivo(formData);
      auxListDocuments[indiceFile] = res.data;
      setListDocuments(auxListDocuments)
      setUploading(false);

    } catch (error) {
      const err = error as AxiosError
      if (err.response) {
        console.log(err.response.status)
        console.log(err.response.data)
        // quitar el spiner de loading
        setUploading(false);

      }
    }
  }

  const openModalAuthorization =  (idDoc: number) => {

    if (idDoc){
      authorizeDoc(idDoc);
    }
  }

  const openModalRejection = (idDoc: number) => {
    if (idDoc){
      rejectDoc(idDoc);
    }
  }

  useEffect(() => {
    ///console.log(typeDoc.documents);
    setListDocuments(typeDoc.documents)

    if (validRoles(PERFIL.roles, localStorageClass.AUDITOR)){
      setDraggable(false);
    }
  },[typeDoc]);


  return (
      <>
        <div className="card doc-expediente-identificacion" >

          <div className="card-header">
            {typeDoc.nombre}
            <div className={"float-right expedient-files"}>

              {(uploading)?
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border"
                             role="status"
                             size="sm"
                             variant="light">

                    </Spinner>
                  </div>:
                  <>
                    {!validRoles(PERFIL.roles,localStorageClass.AUDITOR)?(
                        <>

                          <label className="custom-file-upload">

                            {isAclaracionFirmasCSAM(typeDoc.id)?
                                <input
                                    type="file"
                                    accept=".pdf,.png,.jpeg,.eml,.msg"
                                    disabled={uploading}
                                    onChange={handleFileUploadInputDocIdentification}
                                />:
                                <input
                                    type="file"
                                    accept=".pdf,.png,.jpeg"
                                    disabled={uploading}
                                    onChange={handleFileUploadInputDocIdentification}
                                />

                            }


                            <IconAdd
                                className="d-block mx-auto bg-white ico-add"
                                title={languageState.language?.expedientIdentificaction.add!}
                                size="24"
                            />

                          </label>
                        </>
                        ): null}
                  </>

              }

            </div>

          </div>

          <div className="card-body"
               id={"type-doc-" + typeDoc.id}
               onDrop={drop} onDragOver={allowDrop}>

            {(uploading)?
                <div className="d-flex justify-content-center">
                  <Spinner animation="border"
                           role="status"
                           size="sm"
                           variant="light">

                  </Spinner>
                </div>:
                <>
                  {!validRoles(PERFIL.roles,localStorageClass.AUDITOR)?(
                      <UploadDragExpediente
                          idCliente={idPrincipal}
                          tipoDocumento={typeDoc}
                          postDocIdentification={formData => {
                            postDocIdentification(formData)
                          }}
                          dropHtml={moveExpediente}

                      />): null
                  }
                </>

            }
            {(excedLimitFile)?
                <Alert variant="danger" onClose={() => setExcedLimitFile(false)} dismissible>
                  <Alert.Heading>  </Alert.Heading>
                  <p> {languageState.language?.modalFileMaxUpload.modalExcedLimitFile} {Math.round( MAXSIZEUPLOADFILE/1048576)} mb.</p>
                </Alert>:
                null
            }

            <ul className="list-group list-group-flush">
              {listDocuments.map((iDoc, index2) =>(
                  iDoc.idDocumento!=null  ? (
                      <li
                          key={index2}
                          draggable={(iDoc.idDocumento == null || draggable == false)? false: true}
                          onDragStart={drag}
                          className= {`list-group-item draggable-${(iDoc.idDocumento == null || draggable == false) ? "false" : "true"}`}
                          id={'id-doc-' + iDoc.idDocumento!}>

                        <div className="row">
                          <div className="col-sm-3 col-md-2">

                            {iDoc.estado == statesDocIdentification.enCaptura ? (
                                <>
                                  <IconEdit color="gray"
                                            size="16"
                                            title={languageState.language?.expedientIdentificaction.inCapture}/>
                                </>
                            ): null}

                            {iDoc.estado == statesDocIdentification.enRevision ? (
                                <>
                                  <IconChecklist
                                      color="caution" size="16"
                                      title={languageState.language?.expedientIdentificaction.inReview}/>
                                </>
                            ): null}

                            {iDoc.estado == statesDocIdentification.rechazado ? (
                                <>
                                  <IconErrorAlert
                                      color="error"
                                      size="16"
                                      title={languageState.language?.expedientIdentificaction.reject}/>

                                </>
                            ): null}

                            {iDoc.estado == statesDocIdentification.autorizado ? (
                                <>
                                  <IconCheckmark color="success"
                                                 size="16"
                                                 title={languageState.language?.expedientIdentificaction.authorized}
                                  />
                                  <IconCheckmark color="success"
                                                 size="16"
                                                 title={languageState.language?.expedientIdentificaction.authorized}/>
                                </>
                            ): null}

                            {iDoc.estado == statesDocIdentification.guardadoDW ? (
                                <>
                                  <IconCheckmark color="success"
                                                 size="16"
                                                 title={languageState.language?.expedientIdentificaction.savedDW}
                                  />
                                  <IconCheckmark color="success"
                                                 size="16"
                                                 title={languageState.language?.expedientIdentificaction.savedDW}
                                  />
                                </>
                            ): null}

                            {iDoc.estado == statesDocIdentification.subiendo  ? (

                                <div className="d-flex justify-content-start">
                                  <Spinner animation="border"
                                           role="status"
                                           size="sm"
                                           variant="primary">

                                  </Spinner>
                                </div>

                            ): null}


                            {iDoc.estado == statesDocIdentification.eliminando  ? (

                                <div className="d-flex justify-content-start">
                                  <Spinner animation="border"
                                           role="status"
                                           size="sm"
                                           variant="danger">

                                  </Spinner>
                                </div>

                            ): null}


                          </div>

                          <div className="col-sm-9 col-md-6 name-document">
                            {(iDoc.estado == statesDocIdentification.pendiente ||
                                iDoc.estado == statesDocIdentification.enCaptura ||
                                iDoc.estado == statesDocIdentification.autorizado ||
                                iDoc.estado == statesDocIdentification.guardadoDW
                            )?
                                <EditarDescripcion
                                    idDocument={iDoc.idDocumento!}
                                    texto={iDoc.descripcion}
                                    handleUpd={(e) => {
                                      iDoc.descripcion = e;
                                      handleUpdDocument(iDoc,index2);
                                    }}
                                />:
                                <>
                                {iDoc.descripcionTipoDocumento}
                                </>
                            }


                            <AuthorizationExpedientIdentification idDocumento={iDoc.idDocumento!}/>



                          </div>

                          <div className="col-sm-12 col-md-4">
                            <div className="row actions-expedient expedient-files">

                              <div className="col">
                                {iDoc.estado == statesDocIdentification.autorizado ||
                                iDoc.estado == statesDocIdentification.guardadoDW? (
                                        <></>
                                    ):
                                    iDoc.url? (

                                        <button className="btn btn-light">
                                          {!validRoles(PERFIL.roles,
                                              localStorageClass.AUDITOR)?(
                                              <label className="custom-file-upload">
                                                {isAclaracionFirmasCSAM(iDoc.idTipoDocumento) ?
                                                    <input
                                                        type="file"
                                                        name={String(index2)}
                                                        accept=".pdf,.png,.jpeg,.eml,.msg"
                                                        onChange={handleFileUpdateDocIdentificaction}
                                                    />:
                                                    <input
                                                        type="file"
                                                        name={String(index2)}
                                                        accept=".pdf,.png,.jpeg"
                                                        onChange={handleFileUpdateDocIdentificaction}
                                                    />

                                                }

                                                <IconUpload
                                                    color="gray"
                                                    size="16"
                                                    title={languageState.language?.expedientIdentificaction.uploadFile}/>

                                              </label>
                                          ): null}

                                        </button>
                                    ): null
                                }

                              </div>

                              <div className="col">
                                { iDoc.estado == statesDocIdentification.autorizado ||
                                iDoc.estado == statesDocIdentification.guardadoDW ||
                                iDoc.estado == statesDocIdentification.subiendo ||
                                iDoc.estado == statesDocIdentification.eliminando? (
                                    <>

                                    </>
                                ):  <button className="btn btn-light"
                                            onClick={() => {
                                              deleteFile(iDoc.idDocumento!)
                                            }}>
                                  {!validRoles(PERFIL.roles,
                                      localStorageClass.AUDITOR)?(
                                      <IconTrash
                                          color="negative"
                                          size="16"
                                          title={languageState.language?.expedientIdentificaction.delete}
                                      />  ): null}
                                </button>

                                }

                              </div>

                              {isAclaracionFirmasCSAM(iDoc.idTipoDocumento) ?
                                  <div className="col">
                                    {iDoc.url  ? (
                                            <a className="btn btn-light" href={iDoc.url}>

                                                <IconDownload
                                                    color="informative"
                                                    size="16"
                                                    title={languageState.language?.expedientIdentificaction.download}/>

                                            </a>
                                        ):
                                        null
                                    }
                                  </div>:
                                  <div className="col">
                                    {iDoc.url  ? (
                                            <>
                                              <button className="btn btn-light"
                                                      onClick={() => {
                                                        openNewWindow(iDoc.url!);
                                                      }}>
                                                <IconSearch
                                                    color="informative"
                                                    size="16"
                                                    title={languageState.language?.expedientIdentificaction.watch}/>
                                              </button>
                                            </>
                                        ):
                                        null
                                    }
                                  </div>
                              }


                              {validRoles(PERFIL.roles, localStorageClass.EJECUTIVODEGARANTIAS)? (
                                  <div className="col">
                                    {(iDoc.estado == statesDocIdentification.subiendo ||
                                        iDoc.estado == statesDocIdentification.eliminando)?
                                        null:
                                        <Dropdown>
                                          <Dropdown.Toggle variant="light" className="dropdown-more-options" >
                                            { (iDoc.estado == statesDocIdentification.enCaptura ||
                                                iDoc.estado == statesDocIdentification.enRevision) ? (
                                                <IconThumbsUp
                                                    title={languageState.language?.expedientIdentificaction.quote}
                                                    color="gray"
                                                    size="16" />
                                            ): null}
                                            { (iDoc.estado == statesDocIdentification.autorizado ||
                                                iDoc.estado == statesDocIdentification.guardadoDW) ? (
                                                <IconThumbsUp
                                                    title={languageState.language?.expedientIdentificaction.acept}
                                                    color="informative"
                                                    size="16" />
                                            ): null}
                                            { iDoc.estado == statesDocIdentification.rechazado ? (
                                                <IconThumbsDown
                                                    title={languageState.language?.expedientIdentificaction.reject}
                                                    color="negative"
                                                    size="16"/>
                                            ): null}
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>

                                            { (iDoc.estado == statesDocIdentification.enCaptura ||
                                                iDoc.estado == statesDocIdentification.enRevision) ? (
                                                <>
                                                  <Dropdown.Item
                                                      onClick={() =>{
                                                        openModalAuthorization(iDoc.idDocumento!)
                                                      }}
                                                  >
                                                    <IconThumbsUp
                                                        title={languageState.language?.expedientIdentificaction.acept}
                                                        color="informative"
                                                        size="16" />

                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                      onClick={() =>{
                                                        openModalRejection(iDoc.idDocumento!);
                                                      }}
                                                  >
                                                    <IconThumbsDown
                                                        color="negative"
                                                        size="16"
                                                        title={languageState.language?.expedientIdentificaction.reject}/>

                                                  </Dropdown.Item>
                                                </>
                                            ): null}
                                            { iDoc.estado == statesDocIdentification.autorizado ||
                                            iDoc.estado == statesDocIdentification.guardadoDW? (
                                                <>
                                                  <Dropdown.Item
                                                      onClick={() =>{
                                                        openModalRejection(iDoc.idDocumento!);
                                                      }}
                                                  >
                                                    <IconThumbsDown
                                                        color="negative"
                                                        size="16"
                                                        title={languageState.language?.expedientIdentificaction.reject}/>

                                                  </Dropdown.Item>
                                                </>
                                            ): null}
                                            { iDoc.estado == statesDocIdentification.rechazado ? (
                                                <>
                                                  <Dropdown.Item
                                                      onClick={() =>{
                                                        openModalAuthorization(iDoc.idDocumento!);
                                                      }}
                                                  >
                                                    <IconThumbsUp
                                                        color="informative"
                                                        size="16"
                                                        title={languageState.language?.expedientIdentificaction.acept}/>

                                                  </Dropdown.Item>

                                                </>
                                            ): null}

                                          </Dropdown.Menu>
                                        </Dropdown>

                                    }

                                  </div>
                              ): null}


                            </div>

                          </div>

                        </div>
                      </li>): null
              ))}

            </ul>
          </div>

        </div>












      </>
  );
};
